import { createSelector } from 'reselect';
import _get from 'lodash/get';
import getFormattedPrice from '../../helpers/getFormattedPrice';
import { convertToShortDate } from '../../helpers/dateTimeTools';
import { TMappedCatalog, TMappedPlan, TMappedProduct, TRawCatalog } from './models';

/**
 * Маппинг каталога страхования с бэка
 */
export const getMappedInsuranceCatalog = createSelector(
  (state: TRawCatalog) => state,
  (catalog: TRawCatalog): TMappedCatalog => {
    return catalog.map((product): TMappedProduct => {
      const plans = product.plans.map(
        (plan): TMappedPlan => ({
          name: _get(plan, 'name'),
          prettyName: _get(plan, 'pretty_name'),
          priceCurrency: _get(plan, 'price.currency'),
          price: _get(plan, 'price.value'),
          priceFormatted: getFormattedPrice(_get(plan, 'price.value')),
          sumInsurance: _get(plan, 'sum_insurance.value'),
          sumInsuranceCurrency: _get(plan, 'sum_insurance.currency'),
          sumInsuranceFormatted: getFormattedPrice(_get(plan, 'sum_insurance.value')),
          state: _get(plan, 'state'),
        }),
      );
      return {
        name: _get(product, 'name'),
        prettyName: _get(product, 'pretty_name'),
        type: _get(product, 'type'),
        plans,
      };
    });
  },
);

/**
 * Маппинг полиса страхования с бэка
 */

export const getMappedInsurancePolicy = createSelector(
  (state) => state,
  (policy) => {
    if (!policy) return null;
    return {
      id: _get(policy, 'id'),
      productName: _get(policy, 'product_name'),
      productPrettyName: _get(policy, 'product_pretty_name'),
      planName: _get(policy, 'plan_name'),
      planPrettyName: _get(policy, 'plan_pretty_name'),
      shippingId: _get(policy, 'shipping_id'),
      accountId: _get(policy, 'account_id'),
      state: _get(policy, 'state'),
      startDate: _get(policy, 'start_date'),
      deadlinePaymentDate: convertToShortDate(_get(policy, 'deadline_payment_date')),
      cancelDate: _get(policy, 'cancel_date'),
      insuranceEventDate: _get(policy, 'insurance_event_date'),
      price: _get(policy, 'price.value'),
      priceCurrency: _get(policy, 'price.currency'),
      priceFormatted: getFormattedPrice(_get(policy, 'price.value')),
      sumInsurance: _get(policy, 'sum_insurance.value'),
      sumInsuranceCurrency: _get(policy, 'sum_insurance.currency'),
      sumInsuranceFormatted: getFormattedPrice(_get(policy, 'sum_insurance.value')),
      cargoDescription: _get(policy, 'cargoDescription'),
      loadingDate: _get(policy, 'loading_date'),
      unloadingDate: _get(policy, 'unloading_date'),
      loadingCity: _get(policy, 'loading_city'),
      unloadingCity: _get(policy, 'unloading_city'),
      driverNames: _get(policy, 'name_drivers'),
      carNumbers: _get(policy, 'car_numbers'),
      trailerNumbers: _get(policy, 'trailer_numbers'),
    };
  },
);

/**
 * Формирование данных для покупки полиса страхования
 */
export const getInsurancePolicyShippingPayload = createSelector(
  (state) => state,
  (shipping) => {
    const lastRouteIndex = _get(shipping, 'routePoints').length - 1;
    const lastSelectedSupplyIndex = _get(shipping, 'selectedSupply').length - 1;
    return {
      shipping_id: _get(shipping, 'reservedId'),
      cargo_description: _get(shipping, 'additionalInfo.cargoDescription'),
      loading_date: _get(shipping, 'selectedSupply[0].rawDatetime'),
      unloading_date: _get(shipping, `selectedSupply[${lastSelectedSupplyIndex}]rawDatetime`),
      loading_city: _get(shipping, 'routePoints[0].location.route'),
      unloading_city: _get(shipping, `routePoints[${lastRouteIndex}].location.route`),
      name_drivers: _get(shipping, 'assignResources.drivers'),
      car_numbers: _get(shipping, 'assignResources.carsNumbers'),
      trailer_numbers: _get(shipping, 'assignResources.trailersNumbers', null),
    };
  },
);
