export const PAGE = 'page';
export const PER_PAGE = 'per_page';
export const STATUS = 'status';
export const CONTRACT = 'Contract';
export const GRAPHIC_NUMBER = 'graphic_number';
export const SORT = 'sort';
export const ORDER = 'order';

// Значения статусов
export const NEW = 'new';
export const IN_BID = 'bidding';
export const WINNER_SELECTION = 'winner_selection';
export const IN_WAY = 'in_way';
export const TRIP_WAITING = 'trip_waiting';
export const ARCHIVE = 'archive';
export const CANCELED = 'canceled';
export const DONE = 'done';

// Query for filter
export const SET_BID = 'set_bid';
export const FOLLOWING = 'is_followed';

export const ALL_STATUSES = [
  { title: 'Предстоящие', key: TRIP_WAITING },
  { title: 'Выполняются', key: IN_WAY },
  { title: 'В архиве', key: ARCHIVE },
];

// Типы графиков: гибкий и жёсткий
// пока не используется
// export const FLEXIBLE = 'flexible';
// export const RIGID = 'rigid';

// Типы ендпойнтов в зависимости от типа графика
export const IN_AUCTION = 'in_auction';
export const WON = 'won';

// Начальные значения страницы
export const INITIAL_GRAPHICS_QUERY = {
  [STATUS]: TRIP_WAITING,
  [PAGE]: 1,
  [PER_PAGE]: 20,
};
