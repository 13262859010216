import React from 'react';
import SVGIcon from './index';

const IconTimesheet = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M6 3H7V4H13V3H14V4H16V8.34141C15.6784 8.22773 15.3442 8.1407 15 8.08296V7H5V15H8.08296C8.1407 15.3442 8.22773 15.6784 8.34141 16H4V4H6V3Z" />
      <path d="M11 8.5V8.80269C10.2614 9.22996 9.6223 9.81016 9.12605 10.5H9V8.5H11Z" />
      <path d="M8 8.5V10.5H6V8.5H8Z" />
      <path d="M6 11.5V13.5H8V11.5H6Z" />
      <path d="M14.5 13.7929V11.5H13.5V14.2071L15.1464 15.8536L15.8536 15.1464L14.5 13.7929Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 19C16.7614 19 19 16.7614 19 14C19 11.2386 16.7614 9 14 9C11.2386 9 9 11.2386 9 14C9 16.7614 11.2386 19 14 19ZM14 18C16.2091 18 18 16.2091 18 14C18 11.7909 16.2091 10 14 10C11.7909 10 10 11.7909 10 14C10 16.2091 11.7909 18 14 18Z"
      />
    </SVGIcon>
  );
};

IconTimesheet.defaultProps = {
  color: 'base',
};

export default React.memo(IconTimesheet);
