import { useParams } from 'react-router-dom';

export type TAction =
  | 'edit_draft'
  | 'change_order'
  | 'create_order_based_on'
  | 'create_contract_based_on'
  | 'create_order_based_on_contract'
  | 'edit_draft_contract_order'
  | 'edit_order_from_auction';

/**
 * Получение флагов состояния формы: на основании, внесение изменений, создание контракта, заявка по контракту
 * order/:id/create_order_based_on - создание заказа на основании заказа
 * order/:id/create_contract_based_on - создание контракта на основании контракта
 * order/:id/change_order - внесение изменений в заказ (ИТ)
 * order/:id/create_order_based_on_contract - создание заказа на основании контракта
 * order/:id/edit_draft - редактирование черновика
 * order/:id/edit_draft_contract_order - редактирование черновика заказа по контракту
 */
export function useFormStates() {
  const { action } = useParams<{ action?: TAction }>();
  return {
    isCleanOrder: !action,
    isOrderChange: action === 'change_order',
    isOrderBasedOnContract: action === 'create_order_based_on_contract' || action === 'edit_draft_contract_order',
    isOrderBasedOnOrder: action === 'create_order_based_on',
    isContractBasedOnContract: action === 'create_contract_based_on',
    isDraftEditing:
      action === 'edit_draft' || action === 'edit_draft_contract_order' || action === 'edit_order_from_auction',
    isOrderFromAuction: action === 'edit_order_from_auction',
  };
}
