import React from 'react';
import classNames from 'classnames';

import Popover from '../Popover';
import { IconHelpOutline } from '../Icons';

interface HelpPopoverProps {
  text: any;
  className?: string;
  iconStyle?: any;
}

function HelpPopover({ text, className, iconStyle }: HelpPopoverProps) {
  return (
    <Popover placement="top" trigger="hover" content={text} className={classNames('tooltip', className)}>
      <IconHelpOutline width="20" height="20" className="icon" style={iconStyle} />
    </Popover>
  );
}

export default React.memo(HelpPopover);
