export const ACTIVE_POLICY_STATE = 'ACTIVE';
export const PENDING_POLICY_STATE = 'PENDING';
export const VOID_POLICY_STATE = 'VOID';
export const CANCELED_POLICY_STATE = 'CANCELED';

export const SELECT_RESOURCES_STEPS = {
  RESOURCES: 'resources',
  RESOURCES_CHANGE: 'resources-change',
  DOCUMENTS: 'documents',
  DATES: 'dates',
  INSURANCE: 'insurance',
  ATTORNEY: 'attorney',
};
