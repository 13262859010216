import React from 'react';

import Tooltip from '../../Tooltip';
import IconFlag from '../../Icons/SVGIcons/IconFlag';
import IconAuctionClosed from '../../Icons/SVGIcons/IconAuctionClosed';
import { IconFollowFlag } from '../../Icons';

interface IProps {
  isWinSelection?: boolean;
  isFollowed?: boolean;
  isBidSet?: boolean;
  isBidArchive?: boolean;
  bid?: { currentBid: string };
}

export function getGraphicStatusIcon({
  isWinSelection,
  isFollowed,
  isBidSet,
  isBidArchive,
  bid = { currentBid: '' },
}: IProps) {
  if (isWinSelection) {
    return <IconFlag className="mr-1" width="20" height="20" color="primary" />;
  }

  if (isBidSet && !isBidArchive) {
    return (
      <Tooltip
        content={
          <span>
            Ваша ставка
            <br />
            {bid?.currentBid} ₽
          </span>
        }
      >
        <IconAuctionClosed className="mr-1" width="20" height="20" color="primary" />
      </Tooltip>
    );
  }

  if (!isWinSelection && isFollowed) {
    return <IconFollowFlag className="ml-1" width="20" height="20" color="primary" />;
  }

  return <IconAuctionClosed className="mr-1" width="20" height="20" />;
}
