import React from 'react';
import SVGIcon from './index';

function IconCopy(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path fillRule="evenodd" d="M19 4H5v16h2V6h12V4zm-1 4l6 6v12H9V8h9zm-1 7h5.5L17 9.5V15z" />
    </SVGIcon>
  );
}

IconCopy.defaultProps = {
  color: 'base',
};

export default IconCopy;
