export const USER_FORM = 'userForm';

/* User main form */
export const ID = 'id';
export const LOGIN = 'login';
export const EMAIL = 'email';
export const PASSWORD_OLD = 'passwordOld';
export const PASSWORD_NEW = 'passwordNew';
export const COMPANY_ID = 'companyId';
export const FULL_NAME = 'fullName';
export const PHONE_PERSON = 'phonePerson';
export const POSITION = 'position';
export const COMMENT = 'comment';
export const ROLES = 'roles';
export const ICQ = 'icq';
export const VIBER = 'viber';
export const SKYPE = 'skype';
export const WHATS_APP = 'whats_app';
export const TELEGRAM = 'telegram';
export const CREATED_AT = 'createdAt';

/* Администратор компании-грузовладельца */
export const CARGO_OWNING_ADMIN = 'CargoOwningCompanyAdmin';
/* Администратор транспортной компании */
export const TRANSPORT_COMPANY_ADMIN = 'TransportCompanyAdmin';

/* User auction form */
export const ATTORNEY = 'powerOfAttorney';
export const WITHOUT = 'withoutPowerOfAttorney';
export const M2 = 'm2';
