import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

const SlideTemplate = ({ imageClass = '', title, text }) => {
  return (
    <div className="flex column p-relative h100">
      <div className={classNames(styles.image, imageClass)} />
      <div className={styles.textBlock}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};

export default SlideTemplate;
