import _some from 'lodash/some';

export function getPayerInfo1C(item) {
  const payerName = item.payerName;
  const paymentForm = item.paymentForm;
  const forwardRequestNumber = item.forwardRequestNumber;

  if (!_some([payerName, paymentForm, forwardRequestNumber], Boolean)) return null;
  return {
    payerName,
    paymentForm,
    forwardRequestNumber,
  };
}
