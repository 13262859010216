import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const styles = {
  text: { fontSize: 10 },
};

function IconCommentary(props: React.SVGAttributes<HTMLOrSVGElement>);
function IconCommentary(props: React.SVGAttributes<HTMLOrSVGElement> & { count: number; maxCount: number });
function IconCommentary(props) {
  const { count, maxCount, ...iconProps } = props;
  const text = count && count > maxCount ? `${maxCount}+` : count;

  let path = (
    <path
      fillRule="evenodd"
      d="M2 4H18V15H5L2 18V4ZM5 7H15V8H5V7ZM15 9H5V10H15V9ZM15 11H5V12H15V11Z"
      fill={colors[props.color!]}
    />
  );
  if (text) {
    path = (
      <>
        <path fillRule="evenodd" clipRule="evenodd" d="M1 2H19V15H12H4.74008L1 19V2Z" fill={colors[props.color!]} />
        <text textAnchor="middle" x="10" y="12" fill="white" style={styles.text}>
          {text}
        </text>
      </>
    );
  }

  return (
    <SVGIcon {...iconProps} viewBox="0 0 20 20">
      {path}
    </SVGIcon>
  );
}

IconCommentary.defaultProps = {
  color: 'base',
  maxCount: 9,
};

export default IconCommentary;
