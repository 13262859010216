import React, { useCallback, useEffect } from 'react';

import SimpleRoutePoint from '../../components/SimpleRoutePoint';
import IconDelete from '../../../../components/Icons/SVGIcons/IconDelete';

import { ROUTE_POINTS } from './index';
import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import { FieldArray } from '../../../../components/FormikFieldsV3/FieldArray';
import { useOrderContext } from '../../index';
import NotificationBar from '../../../../components/NotificationBar';
// import FieldLabel from '../../../../components/FieldsCollection/FieldLabel';
// import { Field } from '../../../../components/FormikFieldsV3/Field';
import { EXTRA_ORDERS_COUNT, EXTRA_ORDERS_LIST, EXTRA_ORDERS_LIST_POINTS } from '../ExtraOrdersBlock';
// import FormCounter from '../../../../components/FormikFieldsV3/FormCounter';
import { cloneDeep } from 'lodash';
import { ROUTE_ADDRESS_CITY } from '../../../../store/order/routes/formFields';
import { useFormikContext } from '../../../../components/FormikFieldsV3/FormikContext';
import { FORM_TYPE, FULL } from '../OrderNFormTypeBlock';
import CoverTypeNotificationBlock from '../../components/CoverTypeNotificationBlock';

const SectionPadding = (params) => {
  return <div className="pl-6 pr-6">{params.children}</div>;
};
const TableHeader = (params) => {
  const { orderNumber, orderLabel = 'Заявка' } = params;
  return (
    <header className="flex ml-6 mb-2">
      <span className="caption mr-2 width340">
        {orderNumber && (
          <b>
            {orderLabel} {orderNumber}
          </b>
        )}{' '}
        Город подачи ТС
      </span>
      <span className="width65"></span>
      <span className="caption width175 mr-2">Дата и время подачи</span>
      <span className="caption">Время ПРР</span>
    </header>
  );
};

export const ORDERS_COUNT = 'orders_count';

export function ExtraOrdersQuotesForm(params) {
  const { children } = params;
  const [formType] = useFieldValue(FORM_TYPE);
  const [routePoints] = useFieldValue(ROUTE_POINTS) ?? [];
  const [extraOrdersCount, setExtraOrdersCount] = useFieldValue(EXTRA_ORDERS_COUNT);
  const [ordersCount, setOrdersCount] = useFieldValue(ORDERS_COUNT);
  const [extraOrdersList = [], setExtraOrdersList] = useFieldValue(EXTRA_ORDERS_LIST);

  const countOrdersFromZero = formType === FULL;

  const hasWarehouses = routePoints.some((point) => !!point.warehouse_id);
  useEffect(() => {
    if (hasWarehouses) {
      setOrdersCount(1);
      setExtraOrdersCount(0);
      setExtraOrdersList([]);
    }
  }, [hasWarehouses, setOrdersCount, setExtraOrdersCount, setExtraOrdersList]);

  // const extraOrdersCountChangeHandler = useCallback(
  //   (value) => {
  //     setOrdersCount(value + 1);
  //   },
  //   [setOrdersCount],
  // );
  // const ordersCountChangeHandler = useCallback(
  //   (value) => {
  //     setExtraOrdersCount(value - 1);
  //   },
  //   [setExtraOrdersCount],
  // );

  return (
    <>
      <section className="flex column mt-5 nml-6 nmr-6 pt-5 pb-5 br-5 bg-color-lightest">
        <SectionPadding>
          <NotificationBar
            theme="white"
            text={
              <span>
                После создания каждой заявки исполнителю даётся <b>1 час</b>, чтобы забронировать её. Учитывайте это при
                выборе даты и времени подачи
              </span>
            }
          />

          {/*{!hasWarehouses &&*/}
          {/*  (countOrdersFromZero ? (*/}
          {/*    <div className="mt-5">*/}
          {/*      <FieldLabel title="Какое количество дополнительных заявок создать" />*/}
          {/*      <Field*/}
          {/*        name={EXTRA_ORDERS_COUNT}*/}
          {/*        id={EXTRA_ORDERS_COUNT}*/}
          {/*        type="number"*/}
          {/*        component={FormCounter}*/}
          {/*        className="width200"*/}
          {/*        min={0}*/}
          {/*        readOnly*/}
          {/*        onChange={extraOrdersCountChangeHandler}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  ) : (*/}
          {/*    <div className="mt-5">*/}
          {/*      <FieldLabel title="Какое количество заявок создать" />*/}
          {/*      <Field*/}
          {/*        name={ORDERS_COUNT}*/}
          {/*        id={ORDERS_COUNT}*/}
          {/*        type="number"*/}
          {/*        component={FormCounter}*/}
          {/*        className="width200"*/}
          {/*        min={1}*/}
          {/*        readOnly*/}
          {/*        onChange={ordersCountChangeHandler}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  ))}*/}
        </SectionPadding>

        {!!children ? (
          <>
            <div className="mb-5"></div>
            {/*<div className="divider mt-5 mb-5" />*/}

            {children}
          </>
        ) : (
          <div className="mb-5"></div>
        )}

        <FieldArray name={EXTRA_ORDERS_LIST}>
          {(helpers) => {
            const extraOrders = countOrdersFromZero ? extraOrdersCount : ordersCount - 1;
            const listLength = extraOrdersList?.length || 0;

            if (extraOrders > listLength) {
              helpers.push({
                [EXTRA_ORDERS_LIST_POINTS]: cloneDeep(routePoints),
              });
            } else if (extraOrders < listLength) {
              helpers.remove(listLength - 1);
            }

            return extraOrdersList.map((_, extraOrderIndex) => {
              return (
                <div key={extraOrderIndex}>
                  <div className="divider mb-5" />

                  <SectionPadding>
                    <TableHeader
                      orderLabel={countOrdersFromZero ? 'Доп. заявка' : undefined}
                      orderNumber={extraOrderIndex + (countOrdersFromZero ? 1 : 2)}
                    />
                    {routePoints.map((_, pointIndex) => {
                      return (
                        <div key={pointIndex} className="flex items-center mb-5">
                          <SimpleRoutePoint
                            key={pointIndex}
                            index={pointIndex}
                            isExtraRoutePoint={true}
                            routePointsField={`${EXTRA_ORDERS_LIST}[${extraOrderIndex}].${EXTRA_ORDERS_LIST_POINTS}`}
                          />
                        </div>
                      );
                    })}
                  </SectionPadding>
                </div>
              );
            });
          }}
        </FieldArray>
      </section>
    </>
  );
}

function RoutePoints() {
  const [routePoints] = useFieldValue(ROUTE_POINTS) ?? [];
  const { isOrderBasedOnContract } = useOrderContext();
  const [extraOrdersList = []] = useFieldValue(EXTRA_ORDERS_LIST);

  const { setFieldValue } = useFormikContext();

  const routePointsChangeHandler = useCallback(
    (value, pointIndex) => {
      extraOrdersList.forEach((_, orderIndex) => {
        setFieldValue(
          `${EXTRA_ORDERS_LIST}[${orderIndex}].${EXTRA_ORDERS_LIST_POINTS}[${pointIndex}].${ROUTE_ADDRESS_CITY}`,
          cloneDeep(value),
        );
      });
    },
    [extraOrdersList, setFieldValue],
  );

  return (
    <SectionPadding>
      <TableHeader orderNumber="1" />

      <FieldArray name={ROUTE_POINTS}>
        {(arrayHelpers) => (
          <>
            {routePoints.map((_, index) => {
              const isFirst = index === 0;
              const isLast = routePoints.length - 1 === index;

              const canDeleteRoutePoint = isOrderBasedOnContract
                ? routePoints.length > 2 && !isFirst && !isLast
                : routePoints.length > 2;
              return (
                <div key={index} className="flex items-center mb-5">
                  <SimpleRoutePoint
                    index={index}
                    isLast={isLast}
                    cityChangeHandler={(value) => routePointsChangeHandler(value, index)}
                  />
                  {canDeleteRoutePoint && (
                    <IconDelete
                      width="20"
                      height="20"
                      className="ml-1"
                      style={{ cursor: 'pointer' }}
                      onClick={() => arrayHelpers.remove(index)}
                    />
                  )}
                </div>
              );
            })}
          </>
        )}
      </FieldArray>
    </SectionPadding>
  );
}

export default function ShippingRequestSimpleRoutesBlockQuotes() {
  return (
    <>
      <ExtraOrdersQuotesForm>
        <RoutePoints />
      </ExtraOrdersQuotesForm>
      <CoverTypeNotificationBlock className="mt-5 mb-5 nml-6 nmr-6" />
    </>
  );
}
