import * as types from './actionTypes';

const initialState = {
  types: [],
  documents: [],
  unLoadedFiles: [],
  loadingTypes: [],
  filesInType: [],
};

export default function common(state = initialState, action = {}) {
  switch (action.type) {
    // Обновление списка типов документов
    case types.SET_TYPES:
      return { ...state, types: action.payload };
    // Обновление списка документов
    case types.SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    // Обновление списка документов
    case types.SET_TYPES_AND_DOCUMENTS: {
      return {
        ...state,
        types: action.payload.types,
        documents: action.payload.documents,
        filesInType: action.payload.filesInType,
      };
    }
    // Обновление списка не загруженных файлов
    case types.SET_DOCUMENT_UNLOADED_FILES: {
      return {
        ...state,
        unLoadedFiles: [...state.unLoadedFiles, ...action.payload],
      };
    }
    // Удаление не сохраненного файла из списка
    case types.DELETE_DOCUMENT_UNLOAD_FILE:
      return {
        ...state,
        unLoadedFiles: state.unLoadedFiles.filter((file) => file.id !== action.payload),
      };
    // Выставление статуса загрузки у текущего типа документа
    case types.SET_DOCUMENT_TYPE_FILES_LOADING: {
      const { typeId, status } = action.payload;
      const list = [...state.loadingTypes];
      list[typeId] = status;
      return {
        ...state,
        loadingTypes: list,
      };
    }
    default:
      return state;
  }
}
