import getFullCompanyName from '../../../helpers/getFullCompanyName';

/**
 * Получение текста для тултипа по категории НТК
 * @param partnership
 */
export function getCompanyTitle(
  partnership: { title?: string; opf?: any; active?: boolean },
  isDirectCargoOwner: boolean = false,
) {
  let title = getFullCompanyName(partnership?.title!, partnership?.opf?.name);
  const directCargoOwnerText = isDirectCargoOwner ? ', Прямой грузовладелец' : '';
  if (partnership?.active) {
    return `${title}, партнёр${directCargoOwnerText}`;
  }

  return `${title}, клиент платформы${directCargoOwnerText}`;
}
