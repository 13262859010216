type TScrollParams = { top: number; behavior: 'smooth' };

declare global {
  interface Window {
    scrollTop: number;
    scrollTo(options?: TScrollParams | undefined): () => void;
  }
}

// Является ли текущий браузер IE11 или Edge
export const isLegacyBrowser: true | RegExpMatchArray | null =
  navigator.userAgent.includes('Edge') || navigator.userAgent.match(/Trident/);

export function scrollTo(top: number = 0, el: Window | HTMLElement | Element | null = window) {
  if (el) {
    if (isLegacyBrowser || !el.scrollTo) {
      el.scrollTop = top;
    } else {
      el.scrollTo({
        top: top,
        behavior: 'smooth',
      });
    }
  }
}

// Копирование текста в буфер
export function textToClipboard(text: string): void {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
}

// Открытие ссылки в новом табе
export function openInNewTab(href: string) {
  Object.assign(document.createElement('a'), { target: '_blank', href, rel: 'noopener noreferrer' }).click();
}
