/**
 * Получение отформатированной цены
 * @param price - форматируемая цена
 * @param separator - разделитель между каждым третьим знаком
 * @returns {string}
 * @private
 */
function getFormattedPrice(price: number | string = '', separator: string = ' '): string {
  try {
    if (typeof price !== 'string') {
      price = !!price ? Math.floor(price).toString() : String(price);
    }
    return price
      ? getUnformattedPrice(price)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`)
          .replace(/\s$/g, '\u00a0')
      : '';
  } catch (e) {
    return '';
  }
}

/**
 * Получение "сырой" цены
 * @param price
 * @returns {string}
 */
export function getUnformattedPrice(price: string = ''): string {
  return String(price).replace(/\D+/g, '');
}

export default getFormattedPrice;
