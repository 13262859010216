import moment from 'moment';
import _get from 'lodash/get';
import _last from 'lodash/last';
import _keyBy from 'lodash/keyBy';
import _values from 'lodash/values';
import _isAfter from 'date-fns/isAfter';
import _parseISO from 'date-fns/parseISO';
import { defaultMemoize } from 'reselect';

import getFormattedPrice from '../../helpers/getFormattedPrice';
import {
  getCargoDimensions,
  getDateWithTime,
  getDateWithTimeRange,
  getDateWithDateRange,
  getDurationTranslation,
  getPoints,
  getDistance,
  checkCityRoute,
} from '../../helpers/mappers/shipping';

import { LOADING, PAPERWORK, UNLOADING } from './routes/formKeys';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_SHORT,
  DEFAULT_APP_TIME_ZONE,
  SHORT_DATE_FORMAT,
  SHORT_DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '../../constants';

import { getMappedShippingItem, getShippingPreviewItem } from '../suggestions/selectors';
import * as auctionFormKeys from './auction/formKeys';
import { FULL_COVER } from '../resources/formKeys';
import * as formKeys from './formalization/formKeys';
import getFormattedPhone from '../../helpers/getFormattedPhone';
import { translateCoverTypes } from '../../helpers/translateCoverTypes';
import { declinationClosingPeriod } from '../../helpers/declinationOfNumbers';
import { convertToShortDateTime } from '../../helpers/dateTimeTools';
import getTimeSteps from '../../helpers/getTimeSteps';
import getPaymentForm from '../../helpers/getPaymentForm';
import { WITH_VAT } from '../company/formKeys';
/* Types */
import { TRootState } from '../reducers';
import { TOrder, TOrderPreview, TOrderResponse, TContractExecutor } from './models';
import { TOrderTransport } from './transport/model';
import { TRoutePoint, TMappedRoutePoint } from './routes/model';
import { TMappedFormalization } from './formalization/model';
import { TFromTill, TDateTime } from '../models';
import getFullCompanyName from '../../helpers/getFullCompanyName';
import { PACKAGE_ITEMS } from './formFields';
import { INTERMEDIATE_PERIODS } from '../../containers/order-v2/_helpers/auctionConstants';
import { getLocationObject } from '../../helpers/addressMapping';
import { getSortedBodyTypes } from '../../helpers/carTypesMapping';
import { getWithoutVat, getWithVat } from '../../helpers/getPrice';
import { EOrderStatuses } from '../../constants/order';

type TStep = {
  label: number | string;
  value: number | string;
};

/**
 * Получаем human_friendly_id оформленного заказа
 * @type {function(*=): *}
 */
export const getHumanId = defaultMemoize((state: TRootState): TOrder['human_friendly_id'] =>
  _get(state, 'order.order.human_friendly_id'),
);

/**
 * Модель заказа для отображения
 * @param item
 * @returns {{bodyType: *, statusUpdatedAt: *, orderId: *, isCircular: (*|boolean), orderStatus: *, currentShippingId: *, driverContactInfo: *, points: *, createdAt: *, orderPriceWithoutVat: *, price: *, additionalInfo: {cargoCost: *, cargoTonnage: *, restrictions: *, cargoAdr: *, cargoHeight: *, cargoWidth: *, cargoVolume: *, cargoLength: *, cargoDescription: *}, orderPriceWithVat: *, tonnage: *, id: *, auctionType: *, cargoOwnerCompanyId: *, offerDeadline: *, updatedAt: *, orderPriceType: *, isAuction: boolean, routePoints: *, dateFormatted: *, volume: *, companyId: *, humanFriendlyId: *, comment: *, externalHumanFriendlyId: *, reservationDate: *}}
 */
export function getOrderPreview(item): TOrderPreview {
  const orderId = item?.id;

  /* Данные по аукциону */
  const currentAuctionStageNumber = item?.current_auction_stage_number;
  const auctionStages = item?.auction_stages || [];
  const notAutoExtendedStagesCount = auctionStages?.filter((item) => !item.auto_extended)?.length;
  const currentAuctionStageNumberToDisplay =
    notAutoExtendedStagesCount && currentAuctionStageNumber > notAutoExtendedStagesCount
      ? notAutoExtendedStagesCount
      : currentAuctionStageNumber;
  const auction = auctionStages.find(({ number }) => number === currentAuctionStageNumber);
  const multipolisInsurancePrice = item?.shipping?.shipping_request_info?.multipolis_insurance_price;

  /* Маппинг этапа торга */
  const auctionInfoMapped = (auctionStage) => {
    const auctionType = auctionStage?.auction_type;
    const priceType = auctionStage?.price_type;
    const intermediateResultsPeriod = auctionStage?.intermediate_results_period;
    const intermediateResultsPeriodString = INTERMEDIATE_PERIODS.get(intermediateResultsPeriod) || '';
    const priceWithVat = getFormattedPrice(auctionStage?.price_with_vat);
    const priceWithoutVat = getFormattedPrice(auctionStage?.price_without_vat);

    const isAuction = [
      auctionFormKeys.OPEN_AUCTION,
      auctionFormKeys.DESCENDING_PRICE_AUCTION,
      auctionFormKeys.BLIND_AUCTION,
    ].includes(auctionType);

    const price = priceWithoutVat || '0';

    return {
      addedStage: auctionStage?.added_stage,
      quota: auctionStage?.quota,
      number: auctionStage?.number,
      autoExtended: auctionStage?.auto_extended,
      auctionType,
      isAuction,
      price,
      priceType,
      priceWithVat,
      priceWithoutVat,
      intermediateResultsPeriod,
      intermediateResultsPeriodString,
      shippingPrice: multipolisInsurancePrice || item?.shipping_price || 0,
      shippingPriceTaxType: item?.shipping_price_tax_type,
      bidStep: getFormattedPrice(auctionStage?.bid_step),
      minBidWithTax: getFormattedPrice(auctionStage?.min_bid_with_vat),
      minBidWithoutTax: getFormattedPrice(auctionStage?.min_bid_without_vat),
      maxBidWithTax: getFormattedPrice(auctionStage?.max_bid_with_vat),
      maxBidWithoutTax: getFormattedPrice(auctionStage?.max_bid_without_vat),
      visibility: auctionStage?.visibility,
      companiesIds: auctionStage?.companies_ids,
      groupsIds: auctionStage?.groups_ids,
      begins: _getMskDateTime(auctionStage?.begins_at),
      ends: _getMskDateTime(auctionStage?.ends_at),
    };
  };

  /* Информация по этапам торга */
  const auctionStagesInfo = auctionStages.map(auctionInfoMapped);

  function getExpeditedPaymentFee() {
    if (item.shipping?.expedited_payment_days) {
      if (item.shipping?.shipping_request_info?.expedited_payment_available) {
        return item.shipping?.shipping_request_info?.expedited_payment_fee;
      }
    }

    if (item.winning_bid?.id) {
      if (item.winning_bid.expedited_payment_days) {
        return item?.winning_bid?.expedited_payment_fee;
      }
    }
    return 0;
  }
  const expeditedPaymentFee = getExpeditedPaymentFee();
  const expeditedPaymentDays = item.winning_bid?.id
    ? item?.winning_bid?.expedited_payment_days ?? item?.shipping?.expedited_payment_days
    : 0;

  function getAuctionBidInfo(limit: 'min_bid' | 'max_bid', taxType) {
    if (item.winning_bid?.id) {
      const resultBid = item.winning_bid[`${limit}`];
      if (resultBid) {
        if (taxType === item.winning_bid.tax_type) return resultBid;
        if (taxType === 'with_vat') return getWithVat(resultBid);
        if (taxType === 'without_vat') return getWithoutVat(resultBid);
      }
    }
    return auction?.[`${limit}_${taxType}`];
  }

  function getAuctionInitialBid(taxType) {
    if (item.winning_bid?.id) {
      const resultBid = item.winning_bid.initial_price;
      if (resultBid) {
        if (taxType === item.winning_bid.tax_type) return resultBid;
        if (taxType === 'with_vat') return getWithVat(resultBid);
        if (taxType === 'without_vat') return getWithoutVat(resultBid);
      }
    }
    return auction?.[`price_${taxType}`];
  }

  /* Информация по текущему этапу торга */
  const auctionInfo = {
    begins: _getMskDateTime(_get(auction, 'begins_at')),
    ends: _getMskDateTime(_get(auction, 'ends_at')),
    auctionType: _get(auction, 'auction_type'),
    priceType: _get(auction, 'price_type'),
    priceWithVat: getFormattedPrice(_get(auction, 'price_with_vat')),
    priceWithVatRaw: getAuctionInitialBid('with_vat'),
    priceWithoutVat: getFormattedPrice(_get(auction, 'price_without_vat')),
    priceWithoutVatRaw: getAuctionInitialBid('without_vat'),
    shippingPrice: multipolisInsurancePrice || Math.max((_get(item, 'shipping_price') ?? 0) - expeditedPaymentFee, 0),
    shippingPriceTaxType: _get(item, 'shipping_price_tax_type'),
    bidStep: getFormattedPrice(_get(auction, 'bid_step')),
    minBidWithTax: getFormattedPrice(_get(auction, 'min_bid_with_vat')),
    minBidWithTaxRaw: getAuctionBidInfo('min_bid', 'with_vat'),
    minBidWithoutTax: getFormattedPrice(_get(auction, 'min_bid_without_vat')),
    minBidWithoutTaxRaw: getAuctionBidInfo('min_bid', 'without_vat'),
    maxBidWithTax: getFormattedPrice(_get(auction, 'max_bid_with_vat')),
    maxBidWithTaxRaw: getAuctionBidInfo('max_bid', 'with_vat'),
    maxBidWithoutTax: getFormattedPrice(_get(auction, 'max_bid_without_vat')),
    maxBidWithoutTaxRaw: getAuctionBidInfo('max_bid', 'without_vat'),
    winningBidId: item?.winning_bid?.id ?? null,
    winningBidMinWorkHours: item?.winning_bid?.min_work_hours,
    winningBidWithTax: getFormattedPrice(item?.winning_bid_price_with_vat),
    winningBidWithoutTax: getFormattedPrice(item?.winning_bid_price_without_vat),
    winningBidWithTaxRaw: (item?.winning_bid_price_with_vat ?? 0) - expeditedPaymentFee,
    winningBidWithoutTaxRaw: (item?.winning_bid_price_without_vat ?? 0) - expeditedPaymentFee,
    winningBidIsExpeditedPayment: !!expeditedPaymentDays,
    winningBidExpeditedPaymentDays: expeditedPaymentDays,
    winningBidExpeditedPaymentFee: expeditedPaymentFee,
    winningBidPrice: item?.winning_bid?.value - expeditedPaymentFee,
    visibility: _get(auction, 'visibility'),
    companiesIds: _get(auction, 'companies_ids', []),
    groupsIds: _get(auction, 'groups_ids', []),
    winnerSelectionType: _get(auction, 'winner_selection_type', 'automatically'),
    isHourlyRent: auction?.payment_type === 'per_hour',
    minWorkHours: auction?.min_work_hours,
    shippingMinWorkHours: item?.shipping_min_work_hours,
    winningBid: item?.winning_bid,
    // не мапленный этап торга
    _original: auction,
  };

  /* Данные по документам */
  const docsPeriod = _get(item, 'closing_documents_period');
  const docsPeriodType = _get(item, 'closing_documents_period_type');

  const paymentPeriod = _get(item, 'payment_period');
  const paymentPeriodType = _get(item, 'payment_period_type');

  const orderRequirements = _get(item, 'resource_requirements', {});

  /* Доп. информация */
  const additionalInfo = {
    cargoAdr: _get(item, 'cargo_adr'),
    cargoDescription: _get(item, 'cargo_name'),
    cargoTonnage: _get(item, 'cargo_tonnage'),
    cargoVolume: _get(item, 'cargo_volume'),
    cargoCost: getFormattedPrice(_get(item, 'cargo_cost')),
    cargoPacking: PACKAGE_ITEMS.get(item?.cargo_packing) || '',
    cargoPlaces: getFormattedPrice(item?.cargo_places),

    dimensions: getCargoDimensions(item),
    restrictions: _getOrderRequirements(orderRequirements),
  };

  /* Исполнители заказа, указанные в момент создания */
  const executors =
    Array.isArray(item?.executors) && item?.executors.length > 0
      ? {
          companyId: item?.executors[0]?.company_id,
          price: item?.executors[0]?.price,
          shippingCount: item?.executors[0]?.shippings_count,
        }
      : null;

  let shipping: any = null;
  let driverContactInfo = null;
  let currentShippingId = null;

  if (item?.shipping) {
    shipping = getShippingPreviewItem(item.shipping);
    currentShippingId = item?.shipping?.id;
    driverContactInfo = item?.shipping?.assigned_resources?.[0]?.driver_contact_info;
  } else if (item?.shipping_request) {
    shipping = getMappedShippingItem(item?.shipping_request);
  }

  const contractsDateFrom = item?.contract_begins_at;
  const contractsDateTill = item?.contract_ends_at;

  const contractsDates =
    contractsDateFrom &&
    contractsDateTill &&
    `${moment(contractsDateFrom).format(DATE_FORMAT)} - ${moment(contractsDateTill).format(DATE_FORMAT)}`;
  const contractsDatesShort =
    contractsDateFrom &&
    contractsDateTill &&
    `${moment(contractsDateFrom).format(SHORT_DATE_FORMAT)} - ${moment(contractsDateTill).format(SHORT_DATE_FORMAT)}`;

  const routePoints = item?.route_points ?? [];
  const shippingRoutePoints = _keyBy(_get(shipping, 'routePoints', []), 'id');

  /* Данные по датам подачи */
  const carSupplyRanges = _get(item, 'route_points[0].car_supply_ranges') || [];
  const dateTimeFrom = _get(carSupplyRanges, '[0].from');
  const dateTimeTill = _get(carSupplyRanges, '[0].till');
  // @ts-ignore
  const lastDateTimeTill = _last(carSupplyRanges)?.till;
  const shippingSelectedSupply = shipping?.selectedSupply ?? [];
  const selectedDateTimeFrom = shippingSelectedSupply?.find((item) => item?.routePointId === 1)?.rawDatetime;
  /* Если выбраны даты по всем точкам - заявка перестает быть интервальной */
  const isInterval =
    shippingSelectedSupply.length !== routePoints.length
      ? moment(dateTimeFrom).format() !== moment(dateTimeTill).format() || carSupplyRanges.length > 1
      : false;

  const isAuction = [
    auctionFormKeys.OPEN_AUCTION,
    auctionFormKeys.DESCENDING_PRICE_AUCTION,
    auctionFormKeys.BLIND_AUCTION,
  ].includes(auctionInfo.auctionType);

  return {
    ...shipping,
    shipping,
    orderId,
    id: orderId,
    authorId: item?.author_id,
    auctionAutoExtendPeriod: item?.auction_auto_extend_period,
    contractId: item?.contract_id,
    contractHFId: item?.contract_hfid,
    contractShippingId: item?.contract_shipping_id,
    companyId: item?.shipping?.company_id,
    humanFriendlyId: item?.human_friendly_id,
    externalHumanFriendlyId: item?.external_human_friendly_id,
    cargoOwnerCompanyId: item?.cargo_owning_company_id,
    /* Информация по этапам торга */
    auctionInfo,
    auctionStagesInfo,
    currentAuctionStageNumber,
    currentAuctionStageNumberToDisplay,
    notAutoExtendedStagesCount,
    manualSpotWinnerPickAllowed: item?.manual_spot_winner_pick_allowed,
    auctionType: auction?.auction_type,
    orderStatus: item?.status,
    orderType: item?.type,
    payer: item?.payer,
    partnerContractType: item?.partnership_contract_type,
    executors,
    tonnage: _get(item, 'transport_tonnage'),
    volume: _get(item, 'transport_volume'),
    bodyTypes: getSortedBodyTypes(_get(item, 'transport_body_types') || []),
    offerDeadline: _get(auction, 'ends_at'),
    price:
      (auctionInfo.priceType === auctionFormKeys.TAX_WITHOUT
        ? auctionInfo.priceWithoutVat
        : auctionInfo.priceWithVat) || '0',
    documentFlowOptions: _get(item, 'document_flows'),
    agreementType: _get(item, 'agreement_type'),
    assignmentTime: _getAssignmentTime(item),
    resourceDeadlineOption: _get(item, 'resource_deadline_option'),
    idleTimeWithoutVat: _get(item, 'idle_time_cost_without_vat'),
    penaltyCancelTime: _getMskDateTime(item?.cancellation_without_penalty_before) ?? null,
    penaltyPercent: _get(item, 'cancellation_penalty_percent'),
    payerName: _get(item, 'payer_name'),
    paymentForm: getPaymentForm(item?.payment_form),
    forwardRequestNumber: _get(item, 'forward_request_number'),
    minimumPenalty: _get(item, 'minimum_cancellation_penalty_without_vat'),
    paymentProcedure: _getPaymentTranslation(_get(item, 'payment_procedure')),
    paymentProcedureRaw: item?.payment_procedure,
    paymentPeriod,
    paymentDocsPeriod: paymentPeriod && _getPeriodTranslation(paymentPeriod, paymentPeriodType),
    docsForPayment: _get(item, 'documents_for_payment'),
    closingDocs: _get(item, 'closing_documents', []),
    closingDocsPeriod: docsPeriod && _getPeriodTranslation(docsPeriod, docsPeriodType),
    closingDocsPeriodRaw: docsPeriod,
    closingDocsPeriodType: docsPeriodType,
    createdAt: moment(item?.created_at).format(SHORT_DATE_FORMAT),
    updatedAt: _getUpdatedTime(_get(item, 'updated_at')),
    statusUpdatedAt: moment(_get(item, 'status_updated_at')).format(SHORT_DATE_FORMAT),
    statusUpdatedAtFull: moment(_get(item, 'status_updated_at')).format(SHORT_DATE_TIME_FORMAT),
    winnerSelectionDate: _getHistoryStageDate(_get(item, 'status_history', []), EOrderStatuses.WINNER_SELECTION),
    reservationDate: _getHistoryStageDate(_get(item, 'status_history', []), EOrderStatuses.RESERVED),
    completedDate: _getHistoryStageDate(_get(item, 'status_history', []), EOrderStatuses.DONE),
    isAuction,
    isCircular: item?.is_route_circular || false,
    isCityRoute: checkCityRoute(item),
    isInterval,
    isWorkStatus: _getIsWorkStatus(item),
    comment: item?.comment,
    scanCopyStatus: item?.shipping?.scancopy_status ?? {},
    /* Contracts info */
    contractType: item?.contract_type,
    contractsCount: item?.shippings_count ?? null,
    contractsCountWinner: item?.shipping?.shippings_count ?? null,
    contractsDates,
    contractsDatesShort,
    contractsDateFrom,
    contractsDateTill,
    contractsPerDay: item?.max_shippings_per_day ?? null,
    contractsPerWeek: item?.max_shippings_per_week ?? null,
    /* ---------------*/
    dateFormatted:
      _getDateFormatted(selectedDateTimeFrom || dateTimeFrom, lastDateTimeTill || dateTimeTill, isInterval) ||
      undefined,
    dateTimeSplitted:
      _getDateTimeSplitted(selectedDateTimeFrom || dateTimeFrom, lastDateTimeTill || dateTimeTill, isInterval) ||
      undefined,
    points: getPoints(item),
    routePoints: getRoutePoints(routePoints, shippingSelectedSupply),
    shippingRoutePoints,
    //-----------------------------------------------------------
    currentShippingId,
    driverContactInfo,
    additionalInfo,
    // Последний запрос на смену ресурсов
    requestToChangeResources: item?.requestToChangeResources,
    requestToChangeShipping: item?.requestToChangeShipping,
    changeRequestList: item?.changeRequestList || [],
    resourceChangeRequestList: item?.resourceChangeRequestList || [],
    shippingChangeRequestList: item?.shippingChangeRequestList || [],
    partnershipContractTerms: item?.terms_by_partnership_contract ?? false,
    statusComment: item?.status_comment ?? null,
    performerCompanyInfo: item?.performerCompanyInfo || executors,
    _original: item,
  };
}

/* Сброс текущей даты к мск */
function _getMskDateTime(date) {
  if (!moment(date).isValid()) {
    return null;
  }
  return moment.utc(date).add(DEFAULT_APP_TIME_ZONE, 'h').format(DATE_TIME_FORMAT);
}

/**
 * Получаем измененную модель routePoints
 * @param routePoints
 * @returns {*}
 */
export function getModifyRoutePoints(routePoints: TRoutePoint[]): TRoutePoint[] {
  return routePoints.map((point) => {
    const modifyLocation = {
      ...point?.location,
      area: point?.location.area_with_type,
      region: point?.location.region_with_type,
      city: point?.location.city_with_type,
      locality: point?.location.settlement_with_type,
    };

    return {
      ...point,
      location: modifyLocation,
    };
  });
}

/**
 * Время на прикрепление ресурсов
 * @param shipping
 * @returns {string}
 * @private
 */
function _getAssignmentTime(shipping: TOrderResponse): string | null {
  const time = _get(shipping, 'resource_assignment_time');

  if (time) {
    let hours = Math.floor(time / 60);
    let minutes = time % 60;

    return `${hours} ч ${minutes ? minutes : '00'} мин`;
  }

  return null;
}

/* Значения статуса для Предстоящих и Выполняющихся */
function _getIsWorkStatus(item: TOrderResponse): boolean {
  const status = _getOrderStatus(item);

  return status === EOrderStatuses.TRIP_WAITING || status === EOrderStatuses.IN_TRIP;
}

/**
 * Отформатированное время
 * @param dateTimeFrom
 * @param dateTimeTill
 * @param isInterval
 * @returns {String|string}
 * @private
 */
function _getDateFormatted(
  dateTimeFrom: TFromTill['from'],
  dateTimeTill: TFromTill['till'],
  isInterval: boolean,
): string {
  if (isInterval && dateTimeFrom && dateTimeTill) {
    // FIXME: temp solution
    const dateFrom = dateTimeFrom.slice(0, 10);
    const dateTill = dateTimeTill.slice(0, 10);
    if (moment(dateFrom).isSame(moment(dateTill), 'day')) {
      return getDateWithTimeRange(dateTimeFrom, dateTimeTill);
    }
    return getDateWithDateRange(dateTimeFrom, dateTimeTill);
  }
  return getDateWithTime(dateTimeFrom);
}

/**
 * Отформатированные дата и время подачи раздельно
 * @param dateTimeFrom
 * @param dateTimeTill
 * @param isInterval
 * @returns {[String, String]}
 * @private
 */
function _getDateTimeSplitted(
  dateTimeFrom: TFromTill['from'],
  dateTimeTill: TFromTill['till'],
  isInterval: boolean,
): [string, string] {
  if (isInterval && dateTimeFrom && dateTimeTill) {
    if (moment(dateTimeFrom).isSame(moment(dateTimeTill), 'day')) {
      const formattedDateRangeFrom = moment.parseZone(dateTimeFrom).format(SHORT_DATE_FORMAT);
      const formattedTimeFrom = moment.parseZone(dateTimeFrom).format(TIME_FORMAT);
      const formattedTimeUntil = moment.parseZone(dateTimeTill).format(TIME_FORMAT);
      return [formattedDateRangeFrom, `${formattedTimeFrom} – ${formattedTimeUntil}`];
    }
    const formattedDateRangeFrom = moment.parseZone(dateTimeFrom).format(SHORT_DATE_FORMAT);
    const formattedDateRangeTill = moment.parseZone(dateTimeTill).format(SHORT_DATE_FORMAT);
    return [`${formattedDateRangeFrom} – ${formattedDateRangeTill}`, ''];
  }
  if (!dateTimeFrom) return ['', ''];
  const formattedDateAt = moment.parseZone(dateTimeFrom).format(SHORT_DATE_FORMAT); // getDateShortFormat(date);
  const formattedTimeAt = moment.parseZone(dateTimeFrom).format(TIME_FORMAT);
  return [formattedDateAt, formattedTimeAt];
}

/**
 * Количество дней отсрочки
 * @param period
 * @param periodType
 * @returns {string}
 * @private
 */
function _getPeriodTranslation(period: number, periodType: string): string {
  return `${period} ${declinationClosingPeriod(period, periodType)}`.trim();
}

/**
 * Порядок оплаты
 * @param payment
 * @returns {*|string}
 * @private
 */
function _getPaymentTranslation(payment: string): string {
  return payments.get(payment) || '';
}

/**
 * Порядок оплаты (значения)
 * @type {Map<any, any>}
 */
const payments = new Map()
  .set(formKeys.PREPAYMENT, 'Предоплата')
  .set(formKeys.PAYMENT_BY_SCANCOPY, 'Оплата по скан-копиям документов')
  .set(formKeys.PAYMENT_BY_ORIGIN_DOCS, 'Оплата по оригиналам документов')
  .set(formKeys.PAYMENT_BY_UNLOADING, 'Оплата по факту выгрузки')
  .set(formKeys.PAYMENT_BY_LOADING, 'Оплата по факту загрузки');

/**
 * Дата и время обновления
 * @param time
 * @returns {{date: *, time: *}}
 * @private
 */
function _getUpdatedTime(time: string): TDateTime {
  const updatedDate = moment(time).format(DATE_FORMAT);
  const updatedTime = moment(time).format(TIME_FORMAT);

  return { date: updatedDate, time: updatedTime };
}

/**
 * Время стадии из истории заказа
 * @param statusHistory
 * @param stageName
 * @param dateKey
 * @returns {*}
 * @private
 */
function _getHistoryStageDate(statusHistory: any, stageName: string, dateKey: string = 'created_at'): TDateTime | null {
  const filteredStages = statusHistory.filter(({ status }) => status === stageName);
  const historyStage = filteredStages.reduce((result, current) => {
    if (_isAfter(_parseISO(current?.created_at), _parseISO(result?.created_at))) {
      result = current;
    }
    return result;
  }, filteredStages[0]);
  if (historyStage) {
    return _getUpdatedTime(_get(historyStage, dateKey));
  }
  return null;
}

/**
 * Статус заказа
 * @param order
 * @returns {*}
 * @private
 */
function _getOrderStatus(order: TOrderResponse): TOrderResponse['status'] {
  return _get(order, 'status');
}

export function getMappedRequirements(requirements: TMappedFormalization): TOrderTransport | null {
  const isOwnTransport = _get(requirements, 'own_transport_only');
  const rigidBoard = _get(requirements, 'rigid_board');
  const removableUpperBeam = _get(requirements, 'removable_upper_beam');
  const removableSideRacks = _get(requirements, 'removable_side_racks');
  const numberOfBelts = parseFloat(_get(requirements, 'number_of_belts', null)) || null;
  const isDisinfected = _get(requirements, 'is_disinfected');
  const temperatureCondition = _get(requirements, 'temperature_condition')
    ? {
        min: parseFloat(_get(requirements, 'temperature_condition.min')),
        max: parseFloat(_get(requirements, 'temperature_condition.max')),
      }
    : null;
  const temperatureCheck = _get(requirements, 'temperature_check');
  const russianCitizen = _get(requirements, 'russian_citizenship');
  const medicalBook = _get(requirements, 'medical_book');
  const shippingPowerOfAttorneyOriginal = _get(requirements, 'original_shipping_power_of_attorney');
  const driverCoveralls = _get(requirements, 'coveralls');
  const timberBunks = _get(requirements, 'timber_bunks');
  const tirDocument = _get(requirements, 'tir_document');
  const cmrDocument = _get(requirements, 'cmr_document');
  const twoSided = _get(requirements, 'two_sided');
  const hydroBoard = _get(requirements, 'hydro_board');
  const manipulator = _get(requirements, 'manipulator');

  return {
    isOwnTransport,
    rigidBoard,
    removableUpperBeam,
    removableSideRacks,
    numberOfBelts,
    isDisinfected,
    temperatureCondition,
    temperatureCheck,
    russianCitizen,
    medicalBook,
    shippingPowerOfAttorneyOriginal,
    driverCoveralls,
    timberBunks,
    tirDocument,
    cmrDocument,
    twoSided,
    hydroBoard,
    manipulator,
  };
}

/**
 * Требования к ресурсам
 * @param requirements
 * @returns {null|{driverCoveralls: *, timberBunks: *, numberOfBelts: *, isDisinfected: *, temperatureCondition: *, cmrDocument: *, tirDocument: *, medicalBook: *, temperatureCheck: *, shippingPowerOfAttorneyOriginal: *, removableUpperBeam: *, isOwnTransport: *, russianCitizen: *, rigidBoard: *, removableSideRacks: *}}
 * @private
 */
function _getOrderRequirements(requirements: TMappedFormalization): TOrderTransport | null {
  const result = getMappedRequirements(requirements);

  // возвращаем null при отсутствии всех ограничений
  if (_values(result).every((restriction) => restriction !== 0 && !restriction)) return null;

  return result;
}

/**
 * Точки маршрута
 * @param routePoints
 * @param shippingSelectedSupply
 * @returns {*}
 */
export function getRoutePoints(routePoints: TRoutePoint[], shippingSelectedSupply): TMappedRoutePoint[] | any {
  const distance = getDistance(routePoints);

  return routePoints.map((point) => {
    const number = point?.number;
    const coverTypes = point?.operations_loadings;

    const contactCompanyTitle = _get(point, 'contact_company_title', null);
    const contactOpfName = _get(point, 'contact_opf_name', null);
    const contactCompanyInn = _get(point, 'contact_inn', null);
    const contactCompanyKpp = _get(point, 'contact_kpp', null);

    // Выбраны даты подачи
    const isSupplyRangeFilled = !!shippingSelectedSupply.find((supply) => supply?.routePointId === number);
    const operations = point?.operations;

    return {
      id: _get(point, 'index'),
      number,
      isSupplyRangeFilled,
      arrival: point?.car_supply_ranges.map((range) => {
        const from = range?.from;
        const until = range?.till;
        const isInterval = moment(from).format() !== moment(until).format();

        if (isInterval) return getDateWithTimeRange(from, until);

        return moment.parseZone(from).format(DATE_TIME_FORMAT);
      }),
      coverTypes: coverTypes.includes(FULL_COVER) ? ['Полная'] : coverTypes.map((cover) => translateCoverTypes(cover)),
      duration: getDurationTranslation(point?.operations_duration),
      durationInMinutes: _get(point, 'operations_duration'),
      distance,

      operations,
      loading: operations.includes(LOADING),
      unloading: operations.includes(UNLOADING),
      needDocumentWork: operations.includes(PAPERWORK),

      location: getLocationObject(point),

      contactCompanyTitle,
      contactOpfName,
      contactCompanyInn,
      // @ts-ignore
      counter_agent: point?.counter_agent,
      contacts: point?.contacts,
      contactsInfo: point?.contacts
        .map((contact) => {
          const contactPhone = contact?.phone;
          const passportIssuedAt = contact?.passport_issued_at;

          return {
            contactPerson: contact?.full_name,
            contactPhone: contactPhone && getFormattedPhone(contactPhone),
            contactExtensionNumber: contact?.extension_number,
            passportNumber: contact?.passport_number,
            passportIssuedAt: passportIssuedAt ? moment(passportIssuedAt).format(DATE_FORMAT) : null,
            passportWhoIssued: contact?.passport_who_issued,
            contactCompanyTitle,
            contactOpfName,
            contactCompanyInn,
            contactCompanyKpp,
          };
        })
        .filter(Boolean),
      additionalServices: point?.additional_services ?? null,
      _original: point,
    };
  });
}

export const getShippingEndDate = defaultMemoize((item: any): moment.Moment | undefined => {
  const routePoints = _get(item, 'routePoints');

  if (Array.isArray(routePoints) && !!routePoints.length) {
    const lastRoutePoint = _last(routePoints);
    const lastArrival: any = _last(lastRoutePoint.arrival);
    const duration = lastRoutePoint.durationInMinutes;
    return moment(lastArrival, SHORT_DATE_TIME_FORMAT).clone().add(duration, 'minutes');
  }

  return undefined;
});

export const getContractEndDateTime = defaultMemoize((item) => {
  return moment(item?.contractsDateTill);
});

export const getMinDate = defaultMemoize((shippingEndDate) => {
  return moment(shippingEndDate).clone().subtract(240, 'hours');
});

export const getMaxDate = defaultMemoize((shippingEndDate) => {
  const maxDate = moment(shippingEndDate).clone().add(240, 'hours');
  const now = moment();
  if (now.isBefore(maxDate)) {
    return now;
  }
  return maxDate;
});

export const getDatetimeErrors = defaultMemoize((date, time, minDate, maxDate) => {
  const error = {};
  if (!date) {
    error['date'] = 'Ошибка';
  }
  if (!time) {
    error['time'] = 'Ошибка';
  }
  if (date && time) {
    const dateTime = moment(`${date} ${time.label}`, `${DATE_FORMAT} ${TIME_FORMAT}`);
    if (!dateTime.isBetween(minDate, maxDate, 'ms', '[]')) {
      error['time'] = 'Ошибка';
    }
  }
  return error;
});

export const getTimeOptions = defaultMemoize(
  (date: string, minDate: moment.Moment, maxDate: moment.Moment): TStep[] => {
    const dateTime = moment(date, DATE_FORMAT);
    if (dateTime.isSame(minDate, 'day')) {
      // если выбранная дата совпадает с наименьшей возможной, то ограничиваем время с начала дня
      const minutes = moment.duration({ hours: minDate.hours(), minutes: minDate.minutes() }).asMinutes();
      return getTimeSteps(minutes);
    } else if (dateTime.isSame(maxDate, 'day')) {
      // если выбранная дата совпадает с наибольшей возможной, то ограничиваем время с конца дня
      const minutes = moment.duration({ hours: maxDate.hours(), minutes: maxDate.minutes() }).asMinutes();
      return getTimeSteps(null, false, minutes);
    } else {
      return getTimeSteps();
    }
  },
);

export const getContractExecutor = (item): TContractExecutor => {
  const { company } = item;
  const title = company?.title;
  const opf = company?.opf.name;
  const titleWithOpf = getFullCompanyName(title, opf);

  const price = item?.shipping_request_info?.price ?? 0;
  const shippingsCount = item?.shippings_count ?? 0;

  return {
    id: item?.id,
    shippingRequestId: item?.shipping_request_id,
    shippingOrderId: item?.shipping_order_id,
    createdAt: convertToShortDateTime(item?.created_at),
    updatedAt: convertToShortDateTime(item?.updated_at),

    price,
    shippingsCount,
    totalPrice: price * shippingsCount,

    ntkInfo: {
      id: company?.id,
      humanFriendlyId: company?.human_friendly_id,
      title,
      titleWithOpf,
      ntkTaxType: company?.tax_type,
      isNtkWithTax: company?.tax_type === WITH_VAT,
    },
  };
};

/**
 * Сравнение текущего времени и максимального времени отмены без штрафа
 * @param item
 */
export const isAfterPenaltyCancelTime = (item: TOrderPreview) => {
  const { penaltyCancelTime } = item;
  if (penaltyCancelTime) {
    return moment(penaltyCancelTime, DATE_TIME_FORMAT_SHORT)
      .utcOffset(DEFAULT_APP_TIME_ZONE)
      .isBefore(moment().utcOffset(DEFAULT_APP_TIME_ZONE));
  }
  return false;
};
