/* Значения строки запроса */
export const TYPE = 'type';
export const SHIPPING_HFID = 'shipping_hfid';
export const STATUS = 'status';

/* Типы запросов изменений требований */
export const CUSTOMER_REQUESTS = 'customer_requests';
/* Пока нет такого кейса */
export const NTK_SUGGESTIONS = 'ntk_suggestions';

/* Значения по умолчанию */
export const INITIAL_REQUESTS_QUERY = {
  [TYPE]: CUSTOMER_REQUESTS,
};
