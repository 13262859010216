import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconCloseLarge = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 19 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00442 12.1194L2.12093 19.0035L-0.000488281 16.8823L6.8832 9.99798L-0.000364184 3.11378L2.12106 0.992554L9.00442 7.87656L15.8878 0.992554L18.0092 3.11378L11.1256 9.99798L18.0093 16.8823L15.8879 19.0035L9.00442 12.1194Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconCloseLarge.defaultProps = {
  color: 'base',
};

export default IconCloseLarge;
