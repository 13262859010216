import React from 'react';
import SVGIcon from './index';

const IconClose = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M8.58575 9.99994L3.79285 14.7928L5.20706 16.2071L9.99997 11.4142L14.7929 16.2071L16.2071 14.7928L11.4142 9.99994L16.2071 5.20706L14.7928 3.79285L9.99997 8.58573L5.20709 3.79285L3.79288 5.20706L8.58575 9.99994Z"
        fillRule="evenodd"
      />
    </SVGIcon>
  );
};

IconClose.defaultProps = {
  color: 'base',
};

export default IconClose;
