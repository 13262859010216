import React from 'react';
import SVGIcon from './index';

const IconDoubleCheck = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M10.1683 11.4661L11.345 12.6428L18.3996 5.5881L19.5782 6.76661L11.345 14.9998L6.04168 9.69643L7.22019 8.51793L8.99084 10.2886L10.1683 11.4661ZM10.1698 9.10968L14.2969 4.98242L15.4722 6.15766L11.345 10.2848L10.1698 9.10968ZM7.81416 13.8223L6.63664 14.9998L1.33334 9.69643L2.51185 8.51793L3.68937 9.69551L3.68838 9.69643L7.81416 13.8223Z" />
    </SVGIcon>
  );
};

IconDoubleCheck.defaultProps = {
  color: 'base',
};

export default IconDoubleCheck;
