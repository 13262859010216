import React from 'react';
import SVGIcon from './index';

function IconChevron(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path fillRule="evenodd" d="M13 5.7925L8.67318 10L13 14.2075L11.668 15.5L6 10L11.668 4.5L13 5.7925Z" />
    </SVGIcon>
  );
}

IconChevron.defaultProps = {
  color: 'base',
};

export default React.memo(IconChevron);
