import _sortBy from 'lodash/sortBy';
import { WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE } from '../../../store/counterAgents/formFields';
import {
  NEW_WAREHOUSE_SLOT,
  NEW_WAREHOUSE_SLOT_DATE,
  QUOTA_TYPE,
  WITH_QUOTES,
  WAREHOUSE_ID,
  QUOTA_DISTRIBUTION,
  SUGGESTION_HOURS,
  SUGGESTION_MINUTES,
  ADDITIONAL_AUCTION,
  EXACT_ADDRESS,
  ROUTE_ADDRESS_COMMENT,
} from '../blocks/RoutesBlock';
import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import {
  ADDITIONAL_SERVICES,
  FLOOR_COUNT,
  ORDER_TYPE,
  RISE_FLOOR,
  SERVICES_IDS,
} from '../../../store/order/routes/formFields';
import { CONTRACT_WITH_QUOTES, LOADING, PAPERWORK, UNLOADING } from '../../../store/order/routes/formKeys';

import {
  SHIPPING_REQUEST,
  FORM_TYPE,
  FULL,
  SIMPLE,
  CONTRACT_BY_COUNT,
  TRANSPORT_TYPE,
  BTS,
  MKT,
} from '../blocks/OrderNFormTypeBlock';
import {
  IS_INTERVAL,
  IS_ROUTE_BLOCK_OPENED,
  ROUTE_ADDRESS_CITY,
  ROUTE_ADDRESS_HOUSE,
  ROUTE_ADDRESS_ROOM,
  ROUTE_ADDRESS_STREET,
  ROUTE_CONTRACT_BEGINS_AT,
  ROUTE_CONTRACT_ENDS_AT,
  ROUTE_CONTRACT_PERIOD,
  ROUTE_COVER_TYPE,
  ROUTE_DATES,
  ROUTE_DATE_FROM,
  ROUTE_DATE_TILL,
  ROUTE_MAX_SHIPPINGS_PER_DAY,
  ROUTE_MAX_SHIPPINGS_PER_WEEK,
  ROUTE_OPERATIONS,
  ROUTE_POINTS,
  ROUTE_PRR,
  ROUTE_PRR_MIN,
  ROUTE_RADIUS,
  ROUTE_SHIPPINGS_COUNT,
  SHOW_ADDITIONAL_SERVICES_BLOCK,
  SHOW_CONTACTS_INFO_BLOCK,
} from '../blocks/RoutesBlock';
import {
  BELTS,
  CMR_DOCUMENT,
  COVERALLS,
  IS_DISINFECTED,
  MEDICAL_BOOK,
  NUMBER_OF_BELTS,
  ORIGINAL_SHIPPING_POWER_OF_ATTORNEY,
  OWN_TRANSPORT_ONLY,
  REMOVABLE_SIDE_RACKS,
  REMOVABLE_UPPER_BEAM,
  RESOURCE_REQUIREMENTS,
  RIGID_BOARD,
  RUSSIAN_CITIZENSHIP,
  SHOW_RESOURCE_REQUIREMENTS_BLOCK,
  TEMPERATURE_CHECK,
  TEMPERATURE_CONDITION,
  TEMPERATURE_CONDITION_MAX,
  TEMPERATURE_CONDITION_MIN,
  TIMBER_BUNKS,
  TIR_DOCUMENT,
  TONNAGE_VOLUME,
  TRANSPORT_BODY_TYPES,
  TWO_SIDED,
  DISABLE_AUTO_CHANGES,
} from '../blocks/TransportBlock';
import {
  AWNING_OBJ,
  BACK_COVER_OBJ,
  FULL_COVER_OBJ,
  SIDE_COVER_OBJ,
  TOP_COVER_OBJ,
} from '../../../store/resources/bodyTypeOptions';
import {
  CARGO_COST,
  CARGO_HEIGHT,
  CARGO_LENGTH,
  CARGO_NAME,
  CARGO_PACKING,
  CARGO_PLACES,
  CARGO_TONNAGE,
  CARGO_VOLUME,
  CARGO_WIDTH,
  HAZARD_CLASS,
} from '../blocks/CargoBlock';
import { WITHOUT_HAZARD } from '../../../store/order/cargo/formKeys';
import {
  AGREEMENT_TYPE,
  CANCELLATION_PENALTY_PERCENT,
  CANCELLATION_WITHOUT_PENALTY_BEFORE,
  CLOSING_DOCUMENTS,
  CLOSING_DOCUMENTS_PERIOD,
  CLOSING_DOCUMENTS_PERIOD_TYPE,
  COMMENT,
  CONTRACT_RESOURCE_ASSIGNMENT_TIME,
  COUNT,
  DOCUMENT_FLOWS,
  DOCUMENTS_FOR_PAYMENT,
  IDLE_TIME_COST,
  MINIMUM_CANCELLATION_PENALTY,
  NAME,
  PAYMENT_PERIOD,
  PAYMENT_PERIOD_TYPE,
  PAYMENT_PROCEDURE,
  RESOURCE_ASSIGNMENT_TIME,
  SHIPPING_AVAILABLE_IN,
  TERMS_BY_PARTNERSHIP_CONTRACT,
  RESOURCE_DEADLINE_OPTION,
  FROM_RESOURCE_SUPPLY,
  PAYER,
  PAYER_INN,
  PAYER_KPP,
  PAYER_OPF_FORM,
  PAYER_TITLE,
  PAYER_IS_PRIVATE_PERSON,
  PAYER_LEGAL_ADDRESS,
  PAYER_PASSPORT_ISSUED_AT,
  PAYER_CONTACTS,
  PAYER_FULL_NAME,
  PAYER_PASSPORT_NUMBER,
  PAYER_PASSPORT_ISSUER,
} from '../blocks/DocumentsBlock';
import {
  BILL,
  CALENDAR,
  ONLINE,
  PAYMENT_BY_ORIGIN_DOCS,
  SHIPPING_AGREEMENT,
  WORKING,
} from '../../../store/order/formalization/formKeys';
import {
  AUCTION_STAGES,
  AUCTION_TYPE,
  BID_STEP,
  ENDS_AT,
  INTERMEDIATE_RESULTS_PERIOD,
  PRICE_TYPE,
  PRICE_WITHOUT_TAX,
  VISIBILITY,
  MAX_BID_WITHOUT_TAX,
  MIN_BID_WITHOUT_TAX,
  ENABLE_AUCTION_AUTO_EXTEND,
  AUTO_EXTENDED,
  MANY_EXECUTORS_ALLOWED,
  WITHOUT_PRICES,
  IS_EXECUTOR_KNOWN,
  EXECUTOR_PRICE,
  EXECUTORS,
  BEGINS_AT,
  AUCTION_FOR_ALL,
  EXECUTORS_ARRAY,
  PRICE_WITH_VAT,
  MIN_BID_WITH_VAT,
  MAX_BID_WITH_VAT,
  EXECUTOR_PRICE_WITH_VAT,
} from '../blocks/AuctionBlock';
import {
  OPEN_AUCTION,
  PARTNERS,
  TAX_BOTH,
  TAX_WITH,
  ALL_PEOPLE,
  GROUPS,
  COMPANIES,
} from '../../../store/order/auction/formKeys';
import {
  DEFAULT_APP_TIME_ZONE_NAME,
  DF_DATE_FORMAT,
  DF_DATE_TIME_PICKER_FORMAT,
  DF_TIME_DURATION,
} from '../../../constants';
import { PACKAGE_ITEMS } from '../../../store/order/cargo/formFields';
import {
  CONTACTS,
  COUNTER_AGENT,
  IS_PERSON_INDIVIDUAL,
  PASSPORT_ISSUED_AT,
  PHONE,
  NAME as CONTACT_NAME,
  PERSON_FULL_NAME,
  PASSPORT_NUMBER,
  PASSPORT_ISSUER,
  LEGAL_ENTITY_FORM,
  LEGAL_ENTITY_KPP,
  LEGAL_ENTITY_INN,
  LEGAL_ENTITY_TITLE,
} from '../../../store/counterAgents/formFields';
import _get from 'lodash/get';
import { getBuildingViewObject } from '../../../helpers/addressMapping';
import { getCityValue } from '../../../store/order/routes/selectors';
import getFullCompanyName from '../../../helpers/getFullCompanyName';
import { getAuctionStageDefaultDates, getIsAuctionForAll } from './getAuctionStageInfo';
import { EXTRA_ORDERS_COUNT } from '../blocks/ExtraOrdersBlock';
import { mapValueAsMinutes } from '../../../components/FormikFieldsV3/FormTimePicker';
import { TYPES } from '../../../features/insurance/components/ProfileFormInsurance/constants';
import { WITH_QUOTES_MAP } from '../components/ContractByCountInfo';
import { QUOTES_TYPES_MAP } from '../../../features/quotes/quotesForm';
import { EXECUTOR_IDS, TAX_TYPE } from '../../../features/quotes/ExecutorKnownWithQuotes';
import { contractAuctionStagesFilter, getAdditionalAuctionStage } from '../../../features/quotes/helpers';
import { ORDERS_COUNT } from '../blocks/RoutesBlock/ShippingRequestSimpleRoutesBlockQuotes';
import { AUCTION_FORM_FIELDS, getAuctionFormInitialValues } from '../../../features/quotes/auctionForm';
import { INSURANCE_METHOD, INSURANCE_REQUIRED, INSURANCE_SUM } from '../../../features/insurance/constants';
import { checkTonnageInterval } from '../blocks/RequiredInsuranceBlock/InsuranceBlock';
import { TGeoLocationLevel } from '../../../store/counterAgents/models';
import { getWithoutVat, getWithVat } from '../../../helpers/getPrice';
import { WITH_VAT } from '../../../store/company/formKeys';

export const defaultRoutePoint = {
  [IS_ROUTE_BLOCK_OPENED]: true,
  [ROUTE_ADDRESS_CITY]: null,
  [ROUTE_RADIUS]: '',
  [ROUTE_DATES]: [{ from: null }],
  [ROUTE_PRR]: 6,
  [ROUTE_PRR_MIN]: 0,
  [ROUTE_COVER_TYPE]: [BACK_COVER_OBJ],
  [FLOOR_COUNT]: '',
  [SHOW_CONTACTS_INFO_BLOCK]: false,
  [COUNTER_AGENT]: {
    [IS_PERSON_INDIVIDUAL]: false,

    [LEGAL_ENTITY_TITLE]: '',
    [LEGAL_ENTITY_INN]: '',
    [LEGAL_ENTITY_KPP]: '',
    [LEGAL_ENTITY_FORM]: '',

    [PERSON_FULL_NAME]: '',
    [PASSPORT_NUMBER]: '',
    [PASSPORT_ISSUER]: '',
  },
  [CONTACTS]: [
    {
      [CONTACT_NAME]: '',
      [PHONE]: '',
    },
  ],
};

export const defaultAuctionStage = {
  [AUCTION_TYPE]: OPEN_AUCTION,
  [VISIBILITY]: ALL_PEOPLE,
  [PARTNERS]: {
    [COMPANIES]: [],
    [GROUPS]: [],
  },
  [PRICE_TYPE]: TAX_BOTH,
  [INTERMEDIATE_RESULTS_PERIOD]: '0',
  [PRICE_WITHOUT_TAX]: null,
  [MIN_BID_WITHOUT_TAX]: null,
  [MAX_BID_WITHOUT_TAX]: null,
  [BID_STEP]: 500,
  [MANY_EXECUTORS_ALLOWED]: false,
  [WITHOUT_PRICES]: false,
};

export const defaultClosingDocs = [
  { [NAME]: 'ТРН', [COUNT]: 2 },
  { [NAME]: 'ТТН', [COUNT]: 2 },
  { [NAME]: 'Товарная накладная/УПД', [COUNT]: 1 },
];

/**
 * Маппим бэкендовые поля растентовки в поля формы
 * @param options
 * @returns {Array}
 * @private
 */
function getMappedLoadingTypes(options) {
  const selectedCoverTypes: any = [];
  if (options.indexOf('full') > -1) {
    selectedCoverTypes.push(FULL_COVER_OBJ);
  }
  if (options.indexOf('rear') > -1) {
    selectedCoverTypes.push(BACK_COVER_OBJ);
  }
  if (options.indexOf('lateral') > -1) {
    selectedCoverTypes.push(SIDE_COVER_OBJ);
  }
  if (options.indexOf('upper') > -1) {
    selectedCoverTypes.push(TOP_COVER_OBJ);
  }
  return selectedCoverTypes;
}

function getCounterAgent(counterAgent) {
  if (counterAgent) {
    return {
      ...counterAgent,
      [PASSPORT_ISSUED_AT]: !!counterAgent[PASSPORT_ISSUED_AT]
        ? format(new Date(counterAgent[PASSPORT_ISSUED_AT]), DF_DATE_FORMAT)
        : null,
    };
  }
  return defaultRoutePoint[COUNTER_AGENT];
}

export function getFieldDate(date: string, dateFormat = DF_DATE_TIME_PICKER_FORMAT, tz?: string) {
  const timeZone = tz || DEFAULT_APP_TIME_ZONE_NAME;
  return date ? format(utcToZonedTime(new Date(date).getTime(), timeZone), dateFormat, { timeZone }) : null;
}

function getRoutePointDates(routePointDates, pointTz, isWarehouse) {
  if (routePointDates?.length > 0) {
    if (isWarehouse) {
      return routePointDates.map((routePointDate) => ({
        [ROUTE_DATE_FROM]: getFieldDate(routePointDate.from, DF_DATE_FORMAT, pointTz),
      }));
    }
    return routePointDates.map((routePointDate) => ({
      [ROUTE_DATE_FROM]: getFieldDate(routePointDate.from, DF_DATE_TIME_PICKER_FORMAT, pointTz),
      [ROUTE_DATE_TILL]: getFieldDate(routePointDate.till, DF_DATE_TIME_PICKER_FORMAT, pointTz),
      [IS_INTERVAL]: Boolean(routePointDate.from && routePointDate.till && routePointDate.from !== routePointDate.till),
    }));
  }
  return [{ [ROUTE_DATE_FROM]: null, [ROUTE_DATE_TILL]: null, [IS_INTERVAL]: false }];
}

/**
 * при ИТ в точке без склада - даты из шипинга
 * в остальных случаях - даты из заказа
 */
function getActualDates(routePointId, original, selected, isOrderChange, isSlotChanging) {
  if (isOrderChange && !isSlotChanging) {
    const selectedDate = selected?.find((s) => s.route_point_id === routePointId);
    if (selectedDate) {
      return [{ from: selectedDate.car_supply_at, till: selectedDate.car_supply_at }];
    }
  }
  return original;
}

/**
 * @param currentOrder - точки маршрута
 * @param shouldUseRoutePointDates - следует ли наследовать данные по датам в точках маршрута
 * @param isOrderChange - изменение требований
 */
function getRoutePoints(currentOrder, shouldUseRoutePointDates, isOrderChange) {
  const { route_points: routePoints, shipping } = currentOrder;

  const selectedCarSupply = _sortBy(currentOrder?.shipping?.selected_car_supply, 'route_point_id');

  return routePoints.map((routePoint, routePointIndex) => {
    const location = routePoint?.location ?? {};
    const city = getCityValue(routePoint);
    const street = location?.street_with_type;
    const house = _get(
      getBuildingViewObject(
        location?.house, // house_number,
        '', // house_type,
        location?.block, // block,
        '', // block_type,
        '', // zip_code,
        location,
      ),
      'label',
      null,
    );
    const initAdditionalServices = routePoint.additional_services ?? [];
    const additionalServices = [...SERVICES_IDS.values()].reduce((acc, item) => {
      initAdditionalServices.find((el) => el.id === item) ? (acc[item] = true) : (acc[item] = false);
      return acc;
    }, {});
    const floorCount = initAdditionalServices.find((item) => item.id === RISE_FLOOR)?.count ?? null;

    const isSlotChanging = isOrderChange && routePoint.warehouse_id;
    const dates = getActualDates(
      routePoint.number,
      routePoint.car_supply_ranges,
      shipping?.selected_car_supply,
      isOrderChange,
      isSlotChanging,
    );

    return {
      [WAREHOUSE_ID]: routePoint.warehouse_id ?? null,
      [WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE]: shouldUseRoutePointDates
        ? {
            value: routePoint.operations_duration,
            label: format(mapValueAsMinutes(routePoint.operations_duration), DF_TIME_DURATION),
          }
        : null,
      [NEW_WAREHOUSE_SLOT_DATE]: isOrderChange
        ? getFieldDate(
            selectedCarSupply?.[routePointIndex]?.car_supply_at,
            DF_DATE_FORMAT,
            city ? city.value.timezone_name : undefined,
          )
        : null,
      [NEW_WAREHOUSE_SLOT]: isSlotChanging
        ? {
            from: selectedCarSupply[routePointIndex].car_supply_at,
            till: selectedCarSupply[routePointIndex].car_supply_to,
            gateId: selectedCarSupply[routePointIndex].gate_id,
          }
        : null,

      [IS_ROUTE_BLOCK_OPENED]: !isOrderChange,
      [ROUTE_ADDRESS_CITY]: city?.label ? city : null,
      [ROUTE_RADIUS]: routePoint.radius ?? '',
      [ROUTE_ADDRESS_STREET]: street
        ? { label: street, value: { ...location, value: street, level: TGeoLocationLevel.Street } }
        : null,
      [ROUTE_ADDRESS_HOUSE]: house
        ? { label: house, value: { ...location, fias_id: _get(routePoint, 'location.fias_id') } }
        : null,
      [ROUTE_ADDRESS_ROOM]: location?.office || '',
      [ROUTE_ADDRESS_COMMENT]: location?.comment || '',
      [EXACT_ADDRESS]:
        location?.level === TGeoLocationLevel.Point && location?.lat && location?.lng
          ? {
              coords: [location.lat, location.lng],
            }
          : null,
      [ROUTE_DATES]: shouldUseRoutePointDates
        ? getRoutePointDates(dates, city ? city.value.timezone_name : undefined, !!routePoint.warehouse_id)
        : [{ from: null }],
      [ROUTE_OPERATIONS]: routePoint.operations.filter((item) => item !== PAPERWORK),
      [ROUTE_PRR]: Math.floor(routePoint.operations_duration / 60),
      [ROUTE_PRR_MIN]: routePoint.operations_duration % 60,
      [ROUTE_COVER_TYPE]: getMappedLoadingTypes(routePoint.operations_loadings),
      [SHOW_ADDITIONAL_SERVICES_BLOCK]: initAdditionalServices.length > 0,
      [ADDITIONAL_SERVICES]: additionalServices,
      [FLOOR_COUNT]: floorCount,
      [SHOW_CONTACTS_INFO_BLOCK]: false,
      [COUNTER_AGENT]: getCounterAgent(routePoint.counter_agent),
      [CONTACTS]: routePoint.contacts,
    };
  });
}

/**
 * @param auctionStages - этапы торга
 * @param isDraftEditing - происходит ли редактирование черновика
 * @param shouldUseAuctionStages - следует ли использовать данные по этапам торга
 * @param filter - дополнительный фильтр этапов торга
 */
function getAuctionStages(auctionStages, isDraftEditing, shouldUseAuctionStages, filter = () => true) {
  if (shouldUseAuctionStages) {
    const filteredAuctionStages = auctionStages.filter(filter);
    if (filteredAuctionStages.length > 0) {
      return filteredAuctionStages
        .filter((stage) => !stage.auto_extended)
        .map((auctionStage, index) => {
          const withoutPrice = !auctionStage.price_with_vat && !auctionStage.price_without_vat;
          const defaultStageDates = getAuctionStageDefaultDates(index);
          const stageDates = isDraftEditing
            ? {
                [BEGINS_AT]: getFieldDate(auctionStage.begins_at),
                [ENDS_AT]: getFieldDate(auctionStage.ends_at),
              }
            : defaultStageDates;
          return {
            ...defaultAuctionStage,
            [AUCTION_TYPE]: auctionStage.auction_type,
            [VISIBILITY]: auctionStage.visibility,
            [PARTNERS]: {
              [GROUPS]: auctionStage?.groups_ids ?? [],
              [COMPANIES]: auctionStage?.companies_ids ?? [],
            },
            [PRICE_TYPE]: auctionStage.price_type,
            [MANY_EXECUTORS_ALLOWED]: auctionStage.many_executors_allowed,
            [BID_STEP]: auctionStage.bid_step,
            [INTERMEDIATE_RESULTS_PERIOD]: auctionStage.intermediate_results_period
              ? auctionStage.intermediate_results_period + ''
              : '0',
            [MAX_BID_WITHOUT_TAX]: auctionStage.max_bid_without_vat,
            [MIN_BID_WITHOUT_TAX]: auctionStage.min_bid_without_vat,
            [PRICE_WITHOUT_TAX]: auctionStage.price_without_vat,
            [PRICE_WITH_VAT]: getWithVat(auctionStage.price_without_vat),
            [MIN_BID_WITH_VAT]: getWithVat(auctionStage.min_bid_without_vat),
            [MAX_BID_WITH_VAT]: getWithVat(auctionStage.max_bid_without_vat),
            [WITHOUT_PRICES]: withoutPrice,
            ...stageDates,
          };
        });
    }
    return [{ ...defaultAuctionStage, ...getAuctionStageDefaultDates(0) }];
  }
  return [];
}

function getExecutors(executors, executorsCompanies, isOrderBasedOnContract, withQuotes) {
  const isExecutorKnown = executors.length > 0;

  const getFields = (isKnown, price, executors, executorsList) => {
    return {
      [IS_EXECUTOR_KNOWN]: isKnown,
      [EXECUTOR_PRICE]: price,
      [EXECUTOR_PRICE_WITH_VAT]: price && getWithVat(price),
      [EXECUTORS]: executors,
      [EXECUTOR_IDS]: executorsList ? executorsList.map((item) => item.value) : null,
      [EXECUTORS_ARRAY]: executorsList,
    };
  };

  if (isExecutorKnown) {
    if (isOrderBasedOnContract) {
      const mappedExecutors = executors.map((executor) => {
        const executorCompany = executor.company;
        let mappedExecutor = {
          id: executorCompany.id,
          titleWithOPF: getFullCompanyName(executorCompany.title, executorCompany.opf.name),
          country: executorCompany.country,
          inn: executorCompany.inn,
          kpp: executorCompany.kpp,
          rating: null,
          taxType: executorCompany.tax_type,
        };
        return {
          label: mappedExecutor?.titleWithOPF,
          value: mappedExecutor?.id,
          info: mappedExecutor,
          price:
            executor.shipping_request_info.price_tax_type === WITH_VAT
              ? getWithoutVat(executor.shipping_request_info.price)
              : executor.shipping_request_info.price,
        };
      });
      if (withQuotes) {
        return getFields(true, '', null, mappedExecutors);
      } else {
        return getFields(true, mappedExecutors[0].price, mappedExecutors[0], null);
      }
    } else {
      const mappedExecutors = executorsCompanies?.map((company) => {
        const executor = executors.find((executor) => {
          return executor.company_id === company.id;
        });
        const titleWithOpf = getFullCompanyName(company.title, company.opf.name);
        const mappedInfo = {
          id: company?.id,
          titleWithOPF: titleWithOpf,
          country: company?.country?.name,
          inn: company?.inn,
          kpp: company?.kpp,
          rating: company?.rating,
          taxType: company?.tax_type,
        };
        return {
          value: company?.id,
          label: titleWithOpf,
          price: executor?.price_tax_type === WITH_VAT ? getWithoutVat(executor?.price) : executor?.price,
          info: mappedInfo,
          ...mappedInfo,
        };
      });
      if (withQuotes) {
        return getFields(true, '', null, mappedExecutors);
      } else {
        return getFields(true, mappedExecutors?.[0]?.price, mappedExecutors?.[0], null);
      }
      // return getFields(true, price, executor, null);
    }
  } else {
    return getFields(false, '', null, []);
  }
}

// Начальные значения формы по-умолчанию
function getDefaultInitialValues(auctionAutoExtensionPeriod, companyINN) {
  return {
    [ORDER_TYPE]: SHIPPING_REQUEST,
    [FORM_TYPE]: FULL,

    [TRANSPORT_TYPE]: BTS,
    [TONNAGE_VOLUME]: { transport_tonnage: 20, transport_volume: 82 },
    [TRANSPORT_BODY_TYPES]: [AWNING_OBJ.value],
    [DISABLE_AUTO_CHANGES]: false,

    [INSURANCE_REQUIRED]: false,
    [INSURANCE_SUM]: null,
    [INSURANCE_METHOD]: null,

    [WITH_QUOTES]: WITH_QUOTES_MAP.notUse,
    [QUOTA_TYPE]: QUOTES_TYPES_MAP.VALUES,
    [QUOTA_DISTRIBUTION]: [],
    [ADDITIONAL_AUCTION]: getAuctionFormInitialValues(),
    [TAX_TYPE]: TAX_WITH,
    [SUGGESTION_HOURS]: '01',
    [SUGGESTION_MINUTES]: '00',
    [EXTRA_ORDERS_COUNT]: 0,
    [ORDERS_COUNT]: 1,

    [SHOW_RESOURCE_REQUIREMENTS_BLOCK]: false,
    [RESOURCE_REQUIREMENTS]: {
      [CMR_DOCUMENT]: false,
      [COVERALLS]: false,
      [IS_DISINFECTED]: false,
      [MEDICAL_BOOK]: false,
      [BELTS]: false,
      [NUMBER_OF_BELTS]: '',
      [ORIGINAL_SHIPPING_POWER_OF_ATTORNEY]: false,
      [OWN_TRANSPORT_ONLY]: false,
      [REMOVABLE_SIDE_RACKS]: false,
      [REMOVABLE_UPPER_BEAM]: false,
      [RIGID_BOARD]: false,
      [RUSSIAN_CITIZENSHIP]: false,
      [TEMPERATURE_CHECK]: false,
      [TEMPERATURE_CONDITION]: false,
      [TEMPERATURE_CONDITION_MIN]: '',
      [TEMPERATURE_CONDITION_MAX]: '',
      [TIMBER_BUNKS]: false,
      [TIR_DOCUMENT]: false,
      [TWO_SIDED]: false,
    },

    [ROUTE_CONTRACT_PERIOD]: '',
    [ROUTE_SHIPPINGS_COUNT]: 2,
    [ROUTE_MAX_SHIPPINGS_PER_DAY]: 2,
    [ROUTE_MAX_SHIPPINGS_PER_WEEK]: 5,

    [ROUTE_POINTS]: [
      { ...defaultRoutePoint, [ROUTE_OPERATIONS]: [LOADING] },
      { ...defaultRoutePoint, [ROUTE_OPERATIONS]: [UNLOADING] },
    ],

    [AUCTION_STAGES]: [{ ...defaultAuctionStage, ...getAuctionStageDefaultDates(0) }],
    [IS_EXECUTOR_KNOWN]: false,
    [EXECUTOR_PRICE]: '',
    [EXECUTORS]: null,
    [ENABLE_AUCTION_AUTO_EXTEND]: !!auctionAutoExtensionPeriod,
    [AUTO_EXTENDED]: auctionAutoExtensionPeriod ?? '',

    [CARGO_NAME]: null,
    [HAZARD_CLASS]: WITHOUT_HAZARD,
    [CARGO_TONNAGE]: '',
    [CARGO_PACKING]: PACKAGE_ITEMS.get('not_specified').value,

    [PAYER]: {
      [PAYER_IS_PRIVATE_PERSON]: false,
      [PAYER_INN]: companyINN || '',
      [PAYER_KPP]: '',
      [PAYER_OPF_FORM]: '',
      [PAYER_TITLE]: '',
      [PAYER_LEGAL_ADDRESS]: '',
      [PAYER_FULL_NAME]: '',
      [PAYER_PASSPORT_NUMBER]: '',
      [PAYER_PASSPORT_ISSUER]: '',
      [PAYER_PASSPORT_ISSUED_AT]: null,
    },

    [TERMS_BY_PARTNERSHIP_CONTRACT]: true,
    [AGREEMENT_TYPE]: SHIPPING_AGREEMENT,
    [RESOURCE_ASSIGNMENT_TIME]: 8,
    [RESOURCE_DEADLINE_OPTION]: FROM_RESOURCE_SUPPLY,
    [CONTRACT_RESOURCE_ASSIGNMENT_TIME]: 12, // RESOURCE_ASSIGNMENT_TIME -> 12 for contract
    [SHIPPING_AVAILABLE_IN]: 24,
    [IDLE_TIME_COST]: 500,
    [DOCUMENT_FLOWS]: [ONLINE],
    [PAYMENT_PROCEDURE]: PAYMENT_BY_ORIGIN_DOCS,
    [PAYMENT_PERIOD]: 10,
    [PAYMENT_PERIOD_TYPE]: WORKING,
    [CANCELLATION_WITHOUT_PENALTY_BEFORE]: null,
    [CANCELLATION_PENALTY_PERCENT]: 20,
    [MINIMUM_CANCELLATION_PENALTY]: 4000,
    [CLOSING_DOCUMENTS]: defaultClosingDocs,
    [CLOSING_DOCUMENTS_PERIOD]: 5,
    [CLOSING_DOCUMENTS_PERIOD_TYPE]: CALENDAR,
    [DOCUMENTS_FOR_PAYMENT]: [BILL],
  };
}

// Округление до сотых
export function roundToHundredths(value: string | number) {
  return Math.round(parseFloat(value + '') * 100) / 100;
}

export function getInitialValues(context, params) {
  const {
    currentOrder,
    storageOrderInfo,
    isDraftEditing,
    isOrderChange,
    isOrderBasedOnContract,
    isOrderBasedOnOrder,
    contractExecutorList,
    inheritInsuranceSettings,
    insuranceOption,
    insuranceSum,
    insuranceTonnage,
    executorsCompanies,
    contractExecutorPartnerList,
  } = context;
  const { auctionAutoExtensionPeriod, companyINN } = params;
  if (process.env.NODE_ENV !== 'production') {
    console.log('currentOrder', currentOrder);
  }
  const initialValues = getDefaultInitialValues(auctionAutoExtensionPeriod, companyINN);

  const initialInsuranceParams = {};
  // проверяем, подходит ли дефолтный объект под настройки страхования
  if (
    insuranceOption !== TYPES.ALLOW &&
    checkTonnageInterval(insuranceTonnage, initialValues[TONNAGE_VOLUME].transport_tonnage)
  ) {
    initialInsuranceParams[INSURANCE_METHOD] = insuranceOption;
    initialInsuranceParams[INSURANCE_REQUIRED] = true;
    // подставляем минимальную страховую сумму для REQUIRED
    const cargoCostValue = insuranceOption === TYPES.REQUIRED ? +insuranceSum : null;
    initialInsuranceParams[CARGO_COST] = cargoCostValue;
    initialInsuranceParams[INSURANCE_SUM] = cargoCostValue;
  } else {
    initialInsuranceParams[INSURANCE_METHOD] = TYPES.ALLOW;
    initialInsuranceParams[INSURANCE_REQUIRED] = false;
  }

  const defaultInitialValues = {
    ...getDefaultInitialValues(auctionAutoExtensionPeriod, companyINN),
    ...initialInsuranceParams,
  };

  if (currentOrder) {
    // флаг нужен для определения состояния чекбокса TERMS_BY_PARTNERSHIP_CONTRACT в контрактной заявке
    const isExecutorPartnerInOrderBasedOnContract = isOrderBasedOnContract
      ? !!contractExecutorPartnerList?.partnerships?.find((i) => {
          return !!contractExecutorList?.find(
            (executor) => i.invitee_company_id === executor.company_id || i.inviter_company_id,
          );
        })
      : false;
    const contractBeginsAt = isDraftEditing ? getFieldDate(currentOrder.contract_begins_at, DF_DATE_FORMAT) : null;
    const contractEndsAt = isDraftEditing ? getFieldDate(currentOrder.contract_ends_at, DF_DATE_FORMAT) : null;
    const documentsForPayment =
      currentOrder.documents_for_payment?.length > 0
        ? currentOrder.documents_for_payment
        : defaultInitialValues[DOCUMENTS_FOR_PAYMENT];
    const closingDocuments =
      currentOrder.closing_documents?.length > 0
        ? currentOrder.closing_documents
        : defaultInitialValues[CLOSING_DOCUMENTS];
    const documentFlows =
      currentOrder.document_flows?.length > 0 ? currentOrder.document_flows : defaultInitialValues[DOCUMENT_FLOWS];
    const cancellationWithoutPenaltyBefore = isDraftEditing
      ? getFieldDate(currentOrder.cancellation_without_penalty_before)
      : null;
    // quotes value
    const withQuotes = currentOrder.contract_type === CONTRACT_WITH_QUOTES;
    const auctionStagesFilter = withQuotes ? contractAuctionStagesFilter : undefined;

    const orderType =
      isOrderBasedOnContract || currentOrder.type === SHIPPING_REQUEST
        ? SHIPPING_REQUEST
        : withQuotes
        ? CONTRACT_BY_COUNT
        : currentOrder.contract_type;
    const numberOfBelts = currentOrder[RESOURCE_REQUIREMENTS][NUMBER_OF_BELTS]
      ? currentOrder[RESOURCE_REQUIREMENTS][NUMBER_OF_BELTS] + ''
      : '';

    const shippingRequestInfo = currentOrder.shipping?.shipping_request_info;
    const formType = isOrderChange ? FULL : storageOrderInfo?.[FORM_TYPE] || FULL;

    const inheritDisableAutoChanges = isDraftEditing || isOrderChange || isOrderBasedOnContract || isOrderBasedOnOrder;

    // параметры страховки
    const insuranceParams = {};
    if (inheritInsuranceSettings) {
      // Берем настройки страхования из currentOrder
      // Поля заказа должны соответствовать настройкам страхования, поэтому не проверяем тоннаж
      insuranceParams[INSURANCE_METHOD] = currentOrder[INSURANCE_METHOD];
      insuranceParams[INSURANCE_REQUIRED] =
        [TYPES.CARGO_COST, TYPES.REQUIRED].includes(currentOrder[INSURANCE_METHOD]) ||
        currentOrder.compulsory_insurance;
      insuranceParams[INSURANCE_SUM] = currentOrder[INSURANCE_SUM];
      insuranceParams[CARGO_COST] = currentOrder[CARGO_COST];
    } else {
      // создание на основании и подобное;
      // Параметры в исходном заказе могут не соответствовать текущим параметрам страхования;
      // Валидируем опции с обязательным страхованием и перезаписываем параметры, если нужно;
      if (defaultInitialValues[INSURANCE_METHOD] !== TYPES.ALLOW) {
        if (!checkTonnageInterval(insuranceTonnage, currentOrder.transport_tonnage)) {
          // тоннаж не подходит под настройку; сбрасываем опцию на ALLOW
          insuranceParams[INSURANCE_METHOD] = TYPES.ALLOW;
          insuranceParams[INSURANCE_REQUIRED] = currentOrder.compulsory_insurance;
          insuranceParams[INSURANCE_SUM] = currentOrder[INSURANCE_SUM];
          insuranceParams[CARGO_COST] = currentOrder[CARGO_COST];
        } else {
          // тоннаж подходит под настройку; проверяем минимальную страховую сумму для REQUIRED
          const cargoCostValue =
            defaultInitialValues[INSURANCE_METHOD] === TYPES.REQUIRED
              ? Math.max(currentOrder[CARGO_COST] || 0, +insuranceSum)
              : currentOrder[CARGO_COST];
          insuranceParams[INSURANCE_SUM] = cargoCostValue || null;
          insuranceParams[CARGO_COST] = cargoCostValue || null;
        }
      } else {
        // для ALLOW просто наследуем поля
        insuranceParams[INSURANCE_REQUIRED] = currentOrder.compulsory_insurance;
        insuranceParams[INSURANCE_SUM] = currentOrder[INSURANCE_SUM];
        insuranceParams[CARGO_COST] = currentOrder[CARGO_COST];
      }
    }

    // quotes
    const hasExecutors = currentOrder.executors?.length > 0;
    const quotesParams = {
      [WITH_QUOTES]: withQuotes ? WITH_QUOTES_MAP.use : WITH_QUOTES_MAP.notUse,
      [QUOTA_TYPE]: currentOrder[QUOTA_TYPE] || QUOTES_TYPES_MAP.VALUES,
    };
    if (hasExecutors) {
      const additionalAuction = getAdditionalAuctionStage(currentOrder.auction_stages);

      quotesParams[QUOTA_DISTRIBUTION] = currentOrder[QUOTA_DISTRIBUTION];
      if (additionalAuction) {
        quotesParams[AUCTION_FORM_FIELDS.WITH_AUCTION] = true;
        quotesParams[ADDITIONAL_AUCTION] = getAuctionFormInitialValues(additionalAuction);
      }
    }

    return {
      ...defaultInitialValues,
      [ORDER_TYPE]: orderType,
      [FORM_TYPE]: isOrderBasedOnContract ? SIMPLE : formType,

      [TRANSPORT_TYPE]: currentOrder.transport_tonnage > 15 ? BTS : MKT,
      [TONNAGE_VOLUME]: {
        transport_tonnage: currentOrder.transport_tonnage,
        transport_volume: currentOrder.transport_volume,
      },
      [TRANSPORT_BODY_TYPES]: currentOrder.transport_body_types,
      [DISABLE_AUTO_CHANGES]: inheritDisableAutoChanges
        ? !currentOrder.disable_auto_changes
        : defaultInitialValues[DISABLE_AUTO_CHANGES],

      [SHOW_RESOURCE_REQUIREMENTS_BLOCK]: Object.values(currentOrder[RESOURCE_REQUIREMENTS]).some((value) =>
        Boolean(value),
      ),
      [RESOURCE_REQUIREMENTS]: {
        [CMR_DOCUMENT]: currentOrder[RESOURCE_REQUIREMENTS][CMR_DOCUMENT],
        [COVERALLS]: currentOrder[RESOURCE_REQUIREMENTS][COVERALLS],
        [IS_DISINFECTED]: currentOrder[RESOURCE_REQUIREMENTS][IS_DISINFECTED],
        [MEDICAL_BOOK]: currentOrder[RESOURCE_REQUIREMENTS][MEDICAL_BOOK],
        [BELTS]: !!currentOrder[RESOURCE_REQUIREMENTS][NUMBER_OF_BELTS],
        [NUMBER_OF_BELTS]: numberOfBelts,
        [ORIGINAL_SHIPPING_POWER_OF_ATTORNEY]: currentOrder[RESOURCE_REQUIREMENTS][ORIGINAL_SHIPPING_POWER_OF_ATTORNEY],
        [OWN_TRANSPORT_ONLY]: currentOrder[RESOURCE_REQUIREMENTS][OWN_TRANSPORT_ONLY],
        [REMOVABLE_SIDE_RACKS]: currentOrder[RESOURCE_REQUIREMENTS][REMOVABLE_SIDE_RACKS],
        [REMOVABLE_UPPER_BEAM]: currentOrder[RESOURCE_REQUIREMENTS][REMOVABLE_UPPER_BEAM],
        [RIGID_BOARD]: currentOrder[RESOURCE_REQUIREMENTS][RIGID_BOARD],
        [RUSSIAN_CITIZENSHIP]: currentOrder[RESOURCE_REQUIREMENTS][RUSSIAN_CITIZENSHIP],
        [TEMPERATURE_CHECK]: currentOrder[RESOURCE_REQUIREMENTS][TEMPERATURE_CHECK],
        [TEMPERATURE_CONDITION]: !!currentOrder[RESOURCE_REQUIREMENTS][TEMPERATURE_CONDITION],
        [TEMPERATURE_CONDITION_MIN]: currentOrder[RESOURCE_REQUIREMENTS][TEMPERATURE_CONDITION]?.min ?? '',
        [TEMPERATURE_CONDITION_MAX]: currentOrder[RESOURCE_REQUIREMENTS][TEMPERATURE_CONDITION]?.max ?? '',
        [TIMBER_BUNKS]: currentOrder[RESOURCE_REQUIREMENTS][TIMBER_BUNKS],
        [TIR_DOCUMENT]: currentOrder[RESOURCE_REQUIREMENTS][TIR_DOCUMENT],
        [TWO_SIDED]: currentOrder[RESOURCE_REQUIREMENTS][TWO_SIDED],
      },

      [ROUTE_CONTRACT_PERIOD]: contractBeginsAt && contractEndsAt ? `${contractBeginsAt} - ${contractEndsAt}` : '',
      [ROUTE_CONTRACT_BEGINS_AT]: contractBeginsAt,
      [ROUTE_CONTRACT_ENDS_AT]: contractEndsAt,
      [ROUTE_SHIPPINGS_COUNT]: currentOrder.shippings_count ?? defaultInitialValues[ROUTE_SHIPPINGS_COUNT],
      [ROUTE_MAX_SHIPPINGS_PER_DAY]:
        currentOrder.max_shippings_per_day ?? defaultInitialValues[ROUTE_MAX_SHIPPINGS_PER_DAY],
      [ROUTE_MAX_SHIPPINGS_PER_WEEK]:
        currentOrder.max_shippings_per_week ?? defaultInitialValues[ROUTE_MAX_SHIPPINGS_PER_WEEK],

      [ROUTE_POINTS]: getRoutePoints(currentOrder, isDraftEditing || isOrderChange, isOrderChange),

      ...getExecutors(
        isOrderBasedOnContract ? contractExecutorList : currentOrder.executors,
        executorsCompanies,
        isOrderBasedOnContract,
        withQuotes,
      ),
      [AUCTION_STAGES]: getAuctionStages(
        currentOrder.auction_stages,
        isDraftEditing,
        !isOrderBasedOnContract && !isOrderChange,
        auctionStagesFilter,
      ),
      [AUCTION_FOR_ALL]: getIsAuctionForAll(currentOrder),
      [ENABLE_AUCTION_AUTO_EXTEND]: !!currentOrder.auction_auto_extend_period,
      [AUTO_EXTENDED]: currentOrder.auction_auto_extend_period || defaultInitialValues[AUTO_EXTENDED],

      [CARGO_NAME]: currentOrder.cargo_name
        ? { value: currentOrder.cargo_name, label: currentOrder.cargo_name }
        : defaultInitialValues[CARGO_NAME],
      [HAZARD_CLASS]: currentOrder.cargo_adr || WITHOUT_HAZARD,
      [CARGO_COST]: currentOrder.cargo_cost ?? '',
      [CARGO_HEIGHT]: currentOrder.cargo_height || '',
      [CARGO_LENGTH]: currentOrder.cargo_length || '',
      [CARGO_WIDTH]: currentOrder.cargo_width || '',
      [CARGO_PLACES]: currentOrder.cargo_places || '',
      [CARGO_TONNAGE]: currentOrder.cargo_tonnage || '',
      [CARGO_VOLUME]: currentOrder.cargo_volume || '',
      [CARGO_PACKING]: currentOrder.cargo_packing || defaultInitialValues[CARGO_PACKING],

      [PAYER]: {
        ...currentOrder?.[PAYER],
        [PAYER_CONTACTS]: currentOrder?.[PAYER]?.[PAYER_CONTACTS] || [],
        [PAYER_IS_PRIVATE_PERSON]: currentOrder?.[PAYER]?.[PAYER_IS_PRIVATE_PERSON] || false,
        [PAYER_INN]: currentOrder?.[PAYER]?.[PAYER_INN] || companyINN,
        [PAYER_KPP]: currentOrder?.[PAYER]?.[PAYER_KPP] || '',
        [PAYER_OPF_FORM]: currentOrder?.[PAYER]?.[PAYER_OPF_FORM] || '',
        [PAYER_TITLE]: currentOrder?.[PAYER]?.[PAYER_TITLE] || '',
        [PAYER_LEGAL_ADDRESS]: currentOrder?.[PAYER]?.[PAYER_LEGAL_ADDRESS] || '',
        [PAYER_PASSPORT_ISSUED_AT]: currentOrder?.[PAYER]?.[PAYER_PASSPORT_ISSUED_AT] || null,
      },

      [TERMS_BY_PARTNERSHIP_CONTRACT]:
        isOrderBasedOnContract && !isExecutorPartnerInOrderBasedOnContract && !withQuotes
          ? false
          : currentOrder.terms_by_partnership_contract,
      [AGREEMENT_TYPE]: currentOrder.agreement_type || SHIPPING_AGREEMENT,
      [CONTRACT_RESOURCE_ASSIGNMENT_TIME]:
        currentOrder.type !== SHIPPING_REQUEST && currentOrder.resource_assignment_time
          ? roundToHundredths(currentOrder.resource_assignment_time / 60)
          : defaultInitialValues[CONTRACT_RESOURCE_ASSIGNMENT_TIME],
      [RESOURCE_ASSIGNMENT_TIME]:
        (isOrderBasedOnContract || currentOrder.type === SHIPPING_REQUEST) && currentOrder.resource_assignment_time
          ? roundToHundredths(currentOrder.resource_assignment_time / 60)
          : defaultInitialValues[RESOURCE_ASSIGNMENT_TIME],
      [RESOURCE_DEADLINE_OPTION]:
        currentOrder.resource_deadline_option || defaultInitialValues[RESOURCE_DEADLINE_OPTION],
      [SHIPPING_AVAILABLE_IN]: currentOrder.shipping_available_in
        ? currentOrder.shipping_available_in / 60
        : defaultInitialValues[SHIPPING_AVAILABLE_IN],
      [IDLE_TIME_COST]: currentOrder.idle_time_cost_without_vat || defaultInitialValues[IDLE_TIME_COST],
      [DOCUMENT_FLOWS]: documentFlows,
      [DOCUMENTS_FOR_PAYMENT]: documentsForPayment,
      [PAYMENT_PROCEDURE]: currentOrder.payment_procedure || defaultInitialValues[PAYMENT_PROCEDURE],
      [PAYMENT_PERIOD]: currentOrder.payment_period || defaultInitialValues[PAYMENT_PERIOD],
      [PAYMENT_PERIOD_TYPE]: defaultInitialValues[PAYMENT_PERIOD_TYPE],
      [CANCELLATION_WITHOUT_PENALTY_BEFORE]: cancellationWithoutPenaltyBefore,
      [CANCELLATION_PENALTY_PERCENT]:
        currentOrder.cancellation_penalty_percent || defaultInitialValues[CANCELLATION_PENALTY_PERCENT],
      [MINIMUM_CANCELLATION_PENALTY]:
        currentOrder.minimum_cancellation_penalty_without_vat || defaultInitialValues[MINIMUM_CANCELLATION_PENALTY],
      [CLOSING_DOCUMENTS]: closingDocuments ?? defaultInitialValues[CLOSING_DOCUMENTS],
      [CLOSING_DOCUMENTS_PERIOD]:
        currentOrder.closing_documents_period ?? defaultInitialValues[CLOSING_DOCUMENTS_PERIOD],
      [CLOSING_DOCUMENTS_PERIOD_TYPE]:
        currentOrder.closing_documents_period_type ?? defaultInitialValues[CLOSING_DOCUMENTS_PERIOD_TYPE],

      [COMMENT]: currentOrder.comment ?? '',

      ...(isOrderChange
        ? {
            [PRICE_TYPE]: TAX_BOTH,
            [PRICE_WITHOUT_TAX]: Number(shippingRequestInfo?.price_without_vat).toString(),
            [PRICE_WITH_VAT]: getWithVat(Number(shippingRequestInfo?.price_without_vat).toString()),
          }
        : {}),

      ...insuranceParams,
      ...quotesParams,
    };
  }
  return defaultInitialValues;
}
