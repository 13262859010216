import React from 'react';
import { INSTANT } from '../../../../constants';
import { IconFlash } from '../../../../components/Icons';
import {
  ALL_PEOPLE,
  BLIND_AUCTION,
  DESCENDING_PRICE_AUCTION,
  EXCLUSIVE,
  OPEN_AUCTION,
  PARTNERS,
  TAX_BOTH,
  TAX_WITH,
  TAX_WITHOUT,
} from '../../../../store/order/auction/formKeys';
import IconAuctionOpened from '../../../../components/Icons/SVGIcons/IconAuctionOpened';
import IconAuctionClosed from '../../../../components/Icons/SVGIcons/IconAuctionClosed';
import IconAuctionDrop from '../../../../components/Icons/SVGIcons/IconAuctionDrop';

export const AUCTION_TYPES = new Map()
  .set(OPEN_AUCTION, {
    icon: <IconAuctionOpened width="20" height="20" />,
    text: 'Открытый аукцион',
  })
  .set(BLIND_AUCTION, {
    icon: <IconAuctionClosed width="20" height="20" />,
    text: 'Закрытый аукцион',
  })
  .set(DESCENDING_PRICE_AUCTION, {
    icon: <IconAuctionDrop width="20" height="20" />,
    text: 'Аукцион на понижение',
  })
  .set(INSTANT, {
    icon: <IconFlash width="20" height="20" />,
    text: 'Кто быстрее',
  });

export const VISIBILITIES = new Map()
  .set(PARTNERS, 'Все партнёры')
  .set(EXCLUSIVE, 'Выбранные партнёры')
  .set(ALL_PEOPLE, 'Все исполнители');

export const INTERMEDIATE_PERIODS = new Map()
  .set(null, 'По окончанию этапа')
  .set(10, 'Раз в 10 минут')
  .set(30, 'Раз в 30 минут')
  .set(60, 'Раз в 1 час')
  .set(180, 'Раз в 3 часа');

export const TAX_TYPES = new Map().set(TAX_WITH, 'С НДС').set(TAX_WITHOUT, 'Без НДС').set(TAX_BOTH, 'C НДС/Без НДС');

export const AUCTION_TYPE_HELPER = (
  <>
    <p className="mt-0">
      <b>Открытый аукцион</b> — вы устанавливайте ценовой диапазон и сроки проведения торгов. Победителем определяется
      участник, который первым указал самую низкую ставку в обозначенные сроки проведения торгов. Ставка лидера
      отображается для других участников в период проведения торгов
    </p>
    <p>
      <b>Закрытый аукцион</b> — вы устанавливайте ценовой диапазон и сроки проведения торгов. Победителем определяется
      участник, который первым указал самую низкую ставку в обозначенные сроки проведения торгов. Ставка лидера не
      отображается для других участников в период проведения торгов
    </p>
    <p>
      <b>Аукцион на понижение</b> — вы устанавливайте начальную и минимальную стоимость перевозки и сроки проведения
      торгов. Участники торга могут делать ставки, перебивая ставку лидера через уменьшение её стоимости. Победителем
      определяется участник, который является лидером по итогам окончания торгов
    </p>
    <p>
      <b>Кто быстрее</b> — вы устанавливаете стоимость перевозки, исполнитель может сразу бронировать заявку без торга и
      только по вашей цене. Данный вид торга является типовым и предназначен для ускоренного подбора исполнителя по
      вашей заявке
    </p>
  </>
);

export const VISIBILITY_HELPER = (
  <>
    <p className="mt-0">
      <b>Все партнёры</b> — видеть предложение и участвовать в торге могут только компании, которые являются вашими
      партнёрами
    </p>
    <p>
      <b>Выбранные партнёры</b> — видеть предложение и участвовать в торге могут только компании, которые являются
      вашими партнёрами и выбраны вами для участия
    </p>
    <p className="mb-0">
      <b>Все исполнители</b> — видеть предложение и участвовать в торге могут все исполнители
    </p>
  </>
);
