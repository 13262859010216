import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  isError?: boolean;
  type?: string | 'text';
  rightIcon?: any;
  leftIcon?: any;
}

const Input = (props: React.InputHTMLAttributes<HTMLInputElement> & IProps, ref) => {
  const { isError, className, type, maxLength = 150, rightIcon, leftIcon, ...inputProps } = props;
  const inputClassName = classNames(styles.input, {
    [styles.rightIcon]: rightIcon,
    [styles.leftIcon]: leftIcon,
    [styles.error]: isError,
  });

  return (
    <input
      {...inputProps}
      type={type}
      className={classNames(inputClassName, className, 'data-truncate')}
      ref={ref}
      maxLength={maxLength}
    />
  );
};

export default React.forwardRef(Input);
