import React, { Fragment, useMemo, useState } from 'react';
import Cell from './Cell';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { useTableContext } from '../hooks';
import _get from 'lodash/get';
import Tooltip from '../../../Tooltip';
import { SIDE_CELL_WIDTH } from '../constants';

const SideColumn = ({ Element, isHeader, type = 'left' }) => {
  const params = {
    left: {
      style: { left: '-1px' }, // -1px - избавиться от визуальных багов
    },
    right: {
      style: { right: `${SIDE_CELL_WIDTH + 1}px` }, //+ 1 - избавиться от визуальных багов
    },
  };
  const currentParams = params[type];
  return (
    <Element
      className={classNames(styles.overlayStickyPoint, isHeader ? styles.headerCell : styles.bodyCell)}
      style={{ zIndex: '1', backgroundColor: isHeader ? '#f4f7fb' : 'inherit', ...currentParams.style }}
    >
      <div className={classNames(styles.sidePaddingCell)}></div>
    </Element>
  );
};

function getBodyCellContent(columnProps, data) {
  if (!columnProps) return null;

  if (columnProps.children) {
    return typeof columnProps.children === 'function' ? columnProps.children(data) : columnProps.children;
  }
  const value = _get(data, columnProps.field);
  const cellContent = value || columnProps.placeholder;
  return columnProps.withTooltip ? (
    <Tooltip content={value} ghostTrigger triggerClassName={'d-block'}>
      <span>{cellContent}</span>
    </Tooltip>
  ) : (
    <span>{cellContent}</span>
  );
}

function getHeaderCellContent(columnProps) {
  if (!columnProps) return null;
  if (columnProps.groupProps?.showAsColumn) {
    if (columnProps.groupChildIndex === 0) {
      return <div className={styles.headersGroupCell}>{columnProps.groupProps.name}</div>;
    }
    return null;
  }
  return columnProps.header;
}

const getStickyColumnStyle = (columnProps, leftMargins, rightMargins) => {
  return {
    left: leftMargins && `${leftMargins[columnProps.id]}px`,
    right: rightMargins && `${rightMargins[columnProps.id]}px`,
  };
};

function Row(
  {
    stickyColumnsLeftOffsets,
    stickyColumnsRightOffsets,
    shadowsMap,
    isHeader = false,
    clickable = false,
    data,
    columns,
    rowItems,
    className,
    onClick: onClickHandler,
  },
  ref,
) {
  const { lockedColumnsIds, tableContainerRef } = useTableContext();
  const [isHovered, setIsHovered] = useState(false);

  const rowContext = useMemo(
    () => ({
      isRowHovered: isHovered,
    }),
    [isHovered],
  );

  if (!columns || !Array.isArray(columns)) return null;

  const _tableContainerWidth = tableContainerRef?.current?.clientWidth;
  const Element = isHeader ? 'th' : 'td';

  return (
    <tr
      ref={ref}
      className={classNames(
        styles.row,
        isHeader ? styles.headerRow : styles.bodyRow,
        { [styles.clickableRow]: clickable },
        className,
      )}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClickHandler}
    >
      <Element key={`row-layout`} className={styles.overlayStickyPoint}>
        <div className={styles.overlayPanel} style={{ width: _tableContainerWidth + 'px' }}>
          {rowItems?.map((rowItem, index) => {
            const rowChildren = rowItem.props.children
              ? typeof rowItem.props.children === 'function'
                ? rowItem.props.children(data, rowContext)
                : rowItem.props.children
              : null;
            return <div key={`row-layout-item-${index}`}>{rowChildren}</div>;
          })}
        </div>
      </Element>

      {columns.map((column, index) => {
        const colProps = column?.props;
        if (!colProps) return null;
        const cellContent = isHeader ? getHeaderCellContent(colProps) : getBodyCellContent(colProps, data);

        const isFirstColumn = !index;
        const isLastColumn = index === columns.length - 1;
        const isSticky = lockedColumnsIds?.includes(colProps.id);
        const isGroupCell = !!colProps.groupProps;
        const isFirstGroupCell = colProps.groupChildIndex === 0;
        const isLastGroupCell = colProps.groupChildIndex === colProps.groupChildrenNumber - 1;
        // крайние закрепленные столбцы, когда закрепление активно:
        const isFirstPinnedCell = isSticky && !!shadowsMap?.left && lockedColumnsIds[0] === colProps.id;
        const isFirstPinnedCellWithoutSidePadding = isFirstPinnedCell && !isFirstColumn;
        const isLastPinnedCell =
          isSticky && !!shadowsMap?.right && lockedColumnsIds[lockedColumnsIds.length - 1] === colProps.id;
        const isLastPinnedCellWithoutSidePadding = isLastPinnedCell && !isLastColumn;

        // для первой колонки группы z-index на 1 больше, чтобы при закрепе группы следующие колонки не перекрывали заголовок
        const hasHigherZIndex = colProps.groupProps?.showAsColumn && !colProps.groupChildIndex;

        const stickyCellStyle = {
          position: 'sticky',
          backgroundColor: isHeader ? '#f4f7fb' : 'inherit',
          zIndex: hasHigherZIndex ? '2' : '1',
          boxShadow:
            colProps.id === shadowsMap?.left
              ? '7px 0 10px -4px rgb(29 36 48 / 15%)'
              : colProps.id === shadowsMap?.right
              ? '-7px 0 10px -4px rgb(29 36 48 / 15%)'
              : 'unset',
          ...getStickyColumnStyle(colProps, stickyColumnsLeftOffsets, stickyColumnsRightOffsets),
        };
        const cellStyle = {
          ...(isSticky ? stickyCellStyle : {}),
          ...colProps.style,
          ...(isHeader ? colProps.headerStyle : colProps.bodyStyle),
        };

        return (
          <Fragment key={`row-column-${index}`}>
            {isFirstPinnedCellWithoutSidePadding && <SideColumn Element={Element} isHeader={isHeader} />}
            <Cell
              tableColumnId={colProps.id}
              Element={Element}
              className={classNames(styles.cell, isHeader ? styles.headerCell : styles.bodyCell, {
                [styles.first]: isFirstColumn,
                [styles.last]: isLastColumn,
                [styles.groupCell]: isGroupCell,
                [styles.firstGroupCell]: isFirstGroupCell,
                [styles.lastGroupCell]: isLastGroupCell,
                [styles.center]: colProps.center,
              })}
              style={cellStyle}
              width={colProps.width}
              externalClassName={classNames(
                colProps.className,
                isHeader ? colProps.headerClassName : colProps.bodyClassName,
              )}
              cellContentClassName={colProps.cellContentClassName}
              ellipsis={colProps.ellipsis}
            >
              {cellContent}
            </Cell>
            {isLastPinnedCellWithoutSidePadding && <SideColumn Element={Element} isHeader={isHeader} type={'right'} />}
          </Fragment>
        );
      })}
    </tr>
  );
}

export default React.forwardRef(Row);
