import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { IconClose } from '../../../components/Icons';
import Button from '../../../components/Button';
import Popup from './Popup';

export default function ReviewSuggestionModal({ onClose, onStart }) {
  return (
    <Popup
      placement={'top-end'}
      triggerClassName={styles.reviewSuggestionModalTrigger}
      className={styles.reviewSuggestionPopup}
      hideArrow
      content={
        <>
          <div>
            <div className={'flex content-between'}>
              <div className={classNames('leading-4 text-bold', styles.popupContent)}>
                Пройдите небольшой обзор платформы Traffic
              </div>
              <div onClick={onClose} className={styles.closeIcon}>
                <IconClose width={20} height={20} color={'white'} />
              </div>
            </div>
            <div className={classNames('mt-1 leading-4', styles.popupContent)}>Это займет немного времени.</div>
          </div>

          <div className={'flex content-end'}>
            <Button type={'button'} theme={'primary'} onClick={onClose}>
              Нет, спасибо
            </Button>
            <Button type={'button'} theme={'secondary'} className={'ml-2'} onClick={onStart}>
              Начать
            </Button>
          </div>
        </>
      }
    />
  );
}
