import React from 'react';
import SVGIcon from './index';

export default function IconMinus(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <rect x="5" y="9" width="10" height="2" />
    </SVGIcon>
  );
}

IconMinus.defaultProps = {
  color: 'base',
};
