import React from 'react';
import SVGIcon from './index';

const IconArrowShort = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} width="12" height="10" viewBox="0 0 12 10">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0H5V6.29846L1.7925 3L0.5 4.33198L6 10L11.5 4.33198L10.2075 3L7 6.29846V0Z"
      />
    </SVGIcon>
  );
};

IconArrowShort.defaultProps = {
  color: 'base',
};

export default IconArrowShort;
