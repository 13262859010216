import React from 'react';
import SVGIcon from './index';
import Tooltip from '../../Tooltip';

export default function IconFlag(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <Tooltip content="Проходит выбор победителя">
      <SVGIcon {...props} viewBox="0 0 20 20">
        <path d="M6 17H4V3H11L11.5 5H16V13H10.5L10 11H6V17Z" fillRule="evenodd" />
      </SVGIcon>
    </Tooltip>
  );
}

IconFlag.defaultProps = {
  color: 'base',
};
