import React from 'react';
import SVGIcon from './index';

const IconArrowCircleBold = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M11.3125 14.1429C12.8209 14.1429 14.2676 13.5559 15.3342 12.511C16.4008 11.4662 17 10.0491 17 8.57143C17 7.09379 16.4008 5.67668 15.3342 4.63183C14.2676 3.58699 12.8209 3 11.3125 3H4.74999V4.71429H11.3125C13.5 4.71429 15.25 6.42857 15.25 8.57143C15.25 10.7143 13.5 12.4286 11.3125 12.4286H6.35125L9.04625 9.78L7.8125 8.57143L3 13.2857L7.8125 18L9.055 16.7914L6.35125 14.1429H11.3125Z" />
    </SVGIcon>
  );
};

IconArrowCircleBold.defaultProps = {
  color: 'base',
};

export default IconArrowCircleBold;
