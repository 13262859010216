import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { DF_DATE_FORMAT, DF_DATE_TIME_PICKER_FORMAT } from '../../../constants';
import {
  NEW_WAREHOUSE_SLOT,
  ROUTE_ADDRESS_CITY,
  ROUTE_DATES,
  ROUTE_DATE_FROM,
  WAREHOUSE_ID,
} from '../blocks/RoutesBlock';
import { getDateWithTimezone } from './getDateWithTimezone';

function _getSelectedDate(routePoints, routePointIndex = 0, comparator, useOnlyFirstDate = false): Date | null {
  if (!routePoints?.length) return null;
  if (routePoints[routePointIndex]?.[WAREHOUSE_ID] && routePoints[routePointIndex]?.[NEW_WAREHOUSE_SLOT]?.from) {
    return new Date(routePoints[routePointIndex]?.[NEW_WAREHOUSE_SLOT]?.from);
  }
  const routeTimezone = routePoints[routePointIndex]?.[ROUTE_ADDRESS_CITY]?.value?.timezone_name ?? null;
  const hasDates = useOnlyFirstDate
    ? !!routePoints[routePointIndex]?.[ROUTE_DATES]?.[0]?.[ROUTE_DATE_FROM]
    : (routePoints[routePointIndex]?.[ROUTE_DATES] ?? []).some((date) => !!date[ROUTE_DATE_FROM]);

  if (!hasDates) return null;
  if (useOnlyFirstDate) {
    try {
      return getDateWithTimezone(
        routePoints[routePointIndex][ROUTE_DATES][0][ROUTE_DATE_FROM],
        routeTimezone,
        DF_DATE_TIME_PICKER_FORMAT,
      );
    } catch {
      return getDateWithTimezone(
        routePoints[routePointIndex][ROUTE_DATES][0][ROUTE_DATE_FROM],
        routeTimezone,
        DF_DATE_FORMAT,
      );
    }
  }

  return routePoints[routePointIndex][ROUTE_DATES].reduce((selectedDate, routeDate) => {
    if (!routeDate?.[ROUTE_DATE_FROM]) return selectedDate;

    let currentPointDate: Date | null = null;

    try {
      currentPointDate = getDateWithTimezone(routeDate?.[ROUTE_DATE_FROM], routeTimezone, DF_DATE_TIME_PICKER_FORMAT);
    } catch {
      currentPointDate = getDateWithTimezone(routeDate?.[ROUTE_DATE_FROM], routeTimezone, DF_DATE_FORMAT);
    }

    if (!selectedDate || comparator(currentPointDate, selectedDate)) {
      return currentPointDate;
    }
    return selectedDate;
  }, null);
}

/**
 * Получение времени погрузки (подачи) на точку маршрута
 * Если несколько дат на точке - берется раняя
 * @param routePoints
 * @param routePointIndex
 * @param useOnlyFirstDate
 */
export function getLoadingDatetime(routePoints, routePointIndex = 0, useOnlyFirstDate = false): Date | null {
  return _getSelectedDate(routePoints, routePointIndex, isBefore, useOnlyFirstDate);
}

/**
 * Получение максимальной даты подачи из возможных на точке
 * @param routePoints
 * @param routePointIndex
 * @param useOnlyFirstDate
 */
export function getLatestRoutePointDatetime(routePoints, routePointIndex = 0, useOnlyFirstDate = false): Date | null {
  return _getSelectedDate(routePoints, routePointIndex, isAfter, useOnlyFirstDate);
}
