import React from 'react';

import normalizeNum from '../../../helpers/normalizeNum';

import IconAwningWithTruck3 from '../../../components/Icons/SVGIcons/IconAwningWithTruck3';
import IconAwningWithTruck5 from '../../../components/Icons/SVGIcons/IconAwningWithTruck5';
import IconAwningWithTruck10 from '../../../components/Icons/SVGIcons/IconAwningWithTruck10';
import IconAwningWithTruck15 from '../../../components/Icons/SVGIcons/IconAwningWithTruck15';
import IconAwningWithTruck20 from '../../../components/Icons/SVGIcons/IconAwningWithTruck20';
import { toast } from '../../../components/Toaster';

/**
 * Нормализация поля ввода температуры
 * @param value
 * @returns {*}
 */
export function normalizeTemperature(value) {
  const startsWithSymbolReg = /^[-+]/;
  const normalizeNumOneSymbol = normalizeNum(1);

  if (startsWithSymbolReg.test(value)) {
    return `${value[0]}${normalizeNumOneSymbol(value)}`;
  }

  return normalizeNumOneSymbol(value);
}

export const bodyTypeList = [
  { value: 'awning', label: 'Тент' },
  { value: 'refrigerator', label: 'Рефрижератор' },
  { value: 'isothermal', label: 'Изотермический' },
  { value: 'full_metal', label: 'Цельнометаллический' },
  { value: 'dropside', label: 'Бортовой' },
  { value: 'timber_carrier', label: 'Лесовоз' },
  { value: 'step_deck', label: 'Автотрал' },
  { value: 'tanker', label: 'Автоцистерна' },
  { value: 'container', label: 'Контейнеровоз' },
];

export const liftingCapacityList = [
  {
    label: '20 т / 82 м³',
    value: {
      tonnage: 20,
      volume: 82,
    },
    icon: <IconAwningWithTruck20 height="25" />,
    isChecked: true,
  },
  {
    label: '10 т / 54 м³',
    value: {
      tonnage: 10,
      volume: 54,
    },
    icon: <IconAwningWithTruck10 height="25" />,
  },
  {
    label: '5 т / 36 м³',
    value: {
      tonnage: 5,
      volume: 36,
    },
    icon: <IconAwningWithTruck5 height="25" />,
  },
  {
    label: '3 т / 16 м³',
    value: {
      tonnage: 3,
      volume: 16,
    },
    icon: <IconAwningWithTruck3 height="25" />,
  },
  {
    label: '1.5 т / 9 м³',
    value: {
      tonnage: 1.5,
      volume: 9,
    },
    icon: <IconAwningWithTruck15 height="25" />,
  },
];

export function transportWarnOnChange() {
  toast.warning(
    'Изменён важный атрибут заявки',
    'Обратите внимание на значения Тип транспорта, Тип кузова, Требования к ресурсам, Тип загрузки и Стоимость груза.',
  );
}
