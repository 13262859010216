import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

enum COLORS {
  primary = 'primary',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  white = 'white',
  base = 'base',
  dark = 'dark',
  darker = 'darker',
  light = 'light',
  'stroke-primary' = 'stroke-primary',
  'stroke-base' = 'stroke-base',
  'stroke-white' = 'stroke-white',
  'stroke-success' = 'stroke-success',
  'stroke-light' = 'stroke-light',
}

const SVGIcon = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} className={classNames(styles[props.color!], props.className)}>
    {props.children}
  </svg>
);

SVGIcon.COLORS = COLORS;

export default SVGIcon;
