import React, { forwardRef, useCallback } from 'react';
import classNames from 'classnames';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker-min.module.css';
import styles from './styles.module.scss';

import InputHOC from '../inputHOC';
import Input from '../../Input';
import { useFieldValue } from '../hooks';
import { IconClear, IconDatePicker } from '../../Icons';
import { useFormikContextSelector } from '../FormikContext';
import { DF_DATE_TIME_PICKER_FORMAT } from '../../../constants';

type TTheme = 'grey' | 'white';

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isError: boolean;
  fieldName: string;
  fieldValue: string;
  theme: TTheme;
  isClearable: boolean;
}

const CustomInput = forwardRef((props: CustomInputProps, ref) => {
  const { fieldName, fieldValue, className, theme, isClearable, ...inputProps } = props;
  const hasValue = Boolean(inputProps.value);
  function handleClear() {
    // @ts-ignore
    inputProps.onChange({ target: { value: '' } });
  }
  return (
    <div className={classNames(className, 'p-relative', styles[theme], styles.wrapper)}>
      <IconDatePicker width="20" height="20" color="base" className={styles.iconCalendar} />
      <Input
        {...inputProps}
        value={fieldValue}
        ref={ref}
        className={styles.input}
        id={fieldName}
        name={fieldName}
        autoComplete="off"
        readOnly
      />
      {isClearable && hasValue && (
        <IconClear width={20} height={20} color="base" className={styles.iconClear} onClick={handleClear} />
      )}
    </div>
  );
});

const CustomContainer = (props) => {
  const { children, className } = props;
  return <section className={classNames(className, styles.rangeContainer)}>{children}</section>;
};

function FormDatepickerRange({ isClearable, ...props }) {
  const { field, isError, dateFormat, nameBegin, nameEnd, theme } = props;
  const { name, onBlur } = field;
  const [startDate, setStartDate] = useFieldValue(nameBegin);
  const [endDate, setEndDate] = useFieldValue(nameEnd);
  const [inputValue = '', setInputValue] = useFieldValue(name);
  const setFieldTouched = useFormikContextSelector((c) => c.setFieldTouched);

  const handleChange = useCallback(
    (dates) => {
      const [start, end] = dates ?? [];

      const startDate = start ? format(start, dateFormat) : null;
      const endDate = end ? format(end, dateFormat) : null;

      setStartDate(startDate);
      setEndDate(endDate);

      setInputValue([startDate, endDate].filter(Boolean).join(' - '));
      setFieldTouched(name);
    },
    [dateFormat, name, setEndDate, setFieldTouched, setInputValue, setStartDate],
  );

  const pickerStartValue = startDate ? parse(startDate, dateFormat, new Date()) : null;
  const pickerEndValue = endDate ? parse(endDate, dateFormat, new Date()) : null;

  return (
    <DatePicker
      showPopperArrow={false}
      selected={pickerStartValue}
      startDate={pickerStartValue}
      endDate={pickerEndValue}
      showTimeSelect={false}
      monthsShown={2}
      selectsRange
      customInput={
        <CustomInput
          fieldName={name}
          fieldValue={inputValue}
          isError={isError}
          theme={theme}
          isClearable={isClearable}
        />
      }
      {...props}
      onChange={handleChange}
      onBlur={onBlur}
      shouldCloseOnSelect={false}
      calendarContainer={CustomContainer}
    />
  );
}

FormDatepickerRange.defaultProps = {
  placeholderText: 'дд.мм.гггг  чч:мм',
  dateFormat: DF_DATE_TIME_PICKER_FORMAT,
};

export default InputHOC(FormDatepickerRange);
