import { defaultMemoize } from 'reselect';

/**
 * Парсинг дробного числа
 */
const toFloatNumber = defaultMemoize<(string: string, floatLength?: number) => string | number>(
  (string, floatLength = 1) => {
    if (!string) return '';
    const [regularPart, floatPart]: string[] = string.split(',');
    if (floatPart) return `${regularPart}.${floatPart.slice(0, floatLength)}`;
    return Number.parseFloat(regularPart);
  },
);

export default toFloatNumber;
