import * as statusKeys from './partners/statusKeys';

export const PAGE = 'page';
export const GROUP = 'groups_ids';
export const QUERY = 'q';
export const STATUS = 'status';
export const PER_PAGE = 'per_page';

export const ACTIVE_REQUESTED = [statusKeys.ACTIVE, statusKeys.REQUESTED];
export const BLACKLISTED = [statusKeys.BLACKLISTED];

export const ALL_STATUSES = [
  { title: 'Партнеры', value: ACTIVE_REQUESTED },
  { title: 'Чёрный список', value: BLACKLISTED },
];

export const INITIAL_REQUESTS_QUERY = {
  [PAGE]: 1,
  [PER_PAGE]: 20,
  [STATUS]: ACTIVE_REQUESTED,
};

export const CARRIERS_QUERY = {
  [PAGE]: 1,
  [PER_PAGE]: 20,
};

export const BLACKLIST_QUERY = {
  [PAGE]: 1,
  [PER_PAGE]: 20,
  [STATUS]: BLACKLISTED,
};

export const GROUPS_QUERY = {
  [PAGE]: 1,
  [PER_PAGE]: 20,
};

export const ACTIVE_CONTRACTS_QUERY = {
  [PAGE]: 1,
  [PER_PAGE]: 20,
  [STATUS]: statusKeys.ACTIVE_CONTRACTS,
};

export const ARCHIVED_CONTRACTS_QUERY = {
  [PAGE]: 1,
  [PER_PAGE]: 20,
  [STATUS]: statusKeys.ARCHIVED_CONTRACTS,
};
