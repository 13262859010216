import React, { useCallback } from 'react';
import Dropdown from '../../../Dropdown/Dropdown';
import { IconLockOutline, IconSettings, IconUnlockOutline } from '../../../Icons';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import { useTableContext, useTableMatomoEvent } from '../hooks';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import IconDragNDrop from '../../../Icons/SVGIcons/IconDragNDrop';
import Checkbox from '../../../Checkbox';
import Tooltip from '../../../Tooltip';
import { OPEN_TABLE_COLUMNS_SETTINGS_POPOVER } from '../../../../features/matomo-analytics/eventNameConstants';

const TOTAL_HEIGHT = 300;
const DROPDOWN_HEADER_HEIGHT = 60;
const DROPDOWN_FOOTER_HEIGHT = 30;

const DROPDOWN_CONTENT_HEIGHT = TOTAL_HEIGHT - DROPDOWN_HEADER_HEIGHT - 1 - DROPDOWN_FOOTER_HEIGHT;

function IconButton({ Icon, color = 'primary', disabled, style, onClick: onClickHandler }) {
  return (
    <div
      className={classNames(styles.listItemControlIcon, { [styles.listItemControlIconDisabled]: disabled })}
      onClick={onClickHandler}
    >
      <Icon width={20} hight={20} style={style} color={disabled ? 'base' : color} />
    </div>
  );
}

function getStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.001s`,
  };
}

function List({ items, ...itemParams }) {
  return (
    <Droppable droppableId={'main'}>
      {(dndProvided) => (
        <div className={'custom-scrollbar'} style={{ height: `${DROPDOWN_CONTENT_HEIGHT}px`, overflowY: 'auto' }}>
          <div ref={dndProvided.innerRef} {...dndProvided.droppableProps} className={classNames('pb-2 flex-1')}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getStyle(provided.draggableProps.style, snapshot)}
                    className={snapshot.isDragging ? 'bg-color-lighter' : ''}
                  >
                    <ListItem key={index} item={item} dndProvided={provided} {...itemParams} />
                  </div>
                )}
              </Draggable>
            ))}
            {dndProvided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
}
function ListItem({ item, dndProvided, onLock, onSelect }) {
  const { pinnedItemsIds, visibleItemsIds } = useTableContext();
  const selected = visibleItemsIds?.includes(item?.id);
  const pinned = pinnedItemsIds?.includes(item?.id);

  return (
    <div className={classNames(styles.listItem)}>
      <div className={classNames(styles.listItemContent, { [styles.listItemDisabled]: item.required })}>
        <div {...dndProvided.dragHandleProps} className={'flex ml-2'}>
          <IconDragNDrop width="20" height="20" />
        </div>
        <Checkbox
          className={'ml-1'}
          checked={selected}
          disabled={item.required}
          onChange={() => {}}
          onClick={() => {
            if (item.required) return;
            onSelect(item, !selected);
          }}
        />
        <div className={'ml-2'}>{item.label}</div>
      </div>

      <div className={styles.listItemControlsContainer}>
        {pinned ? (
          <Tooltip content={'Столбец закреплён'}>
            <IconButton Icon={IconLockOutline} color={'primary'} onClick={() => onLock(item, false)} />
          </Tooltip>
        ) : (
          <Tooltip content={'Столбец не закреплён'}>
            <IconButton Icon={IconUnlockOutline} color={'base'} onClick={() => onLock(item, true)} />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

function ColumnsSelector({ selectableItems, showMarker, onChange: onChangeHandler, onSelect, onLocked, onReset }) {
  const sendOpenSelectorEvent = useTableMatomoEvent(OPEN_TABLE_COLUMNS_SETTINGS_POPOVER);

  const onDragEndHandler = useCallback(
    (result) => {
      if (!result.destination?.droppableId) return;
      const id = result.draggableId;
      onChangeHandler(id, result.destination.index);
    },
    [onChangeHandler],
  );

  return (
    <Dropdown
      triggerContent={
        <div onClick={() => sendOpenSelectorEvent()}>
          <IconSettings width={20} height={20} color={'primary'} />
          {showMarker && <div className={styles.marker} />}
        </div>
      }
      triggerClassName={'flex p-relative'}
      className={styles.columnsSelector}
      dropdownClassName={styles.dropdown}
      dropdownMaxHeight={`${TOTAL_HEIGHT}px`}
      scrollable={false}
    >
      <DragDropContext onDragEnd={onDragEndHandler}>
        <div style={{ maxHeight: 'inherit' }}>
          <div style={{ height: `${DROPDOWN_HEADER_HEIGHT}px` }} className={styles.dropdownHeader}>
            <div>Настройка отображения колонок</div>
            <div className={'fs-12 leading-3 color-dark'}>
              Выбирайте, какую информацию по заявке отображать в списке
            </div>
          </div>

          <List id={'active'} items={selectableItems} onLock={onLocked} onSelect={onSelect} />

          <div className={styles.divider} />

          <div style={{ height: `${DROPDOWN_FOOTER_HEIGHT}px` }}>
            <div className={'link pt-1 pb-1 pl-3'} onClick={onReset}>
              Настроить по умолчанию
            </div>
          </div>
        </div>
      </DragDropContext>
    </Dropdown>
  );
}

export default ColumnsSelector;
