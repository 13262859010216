export const DATES_FORM = 'ShippingSelectDates';
export const RESOURCES_FROM = 'ShippingSelectResources';
export const DATES_FIELDS = 'dates';
export const CAR_FIELD = 'car';
export const TRAILER_FIELD = 'trailer';
export const DRIVER_FIELD = 'driver';
export const PHONE_FIELD = 'phone';
export const INN_FIELD = 'inn';
export const DATE_FIELD = 'date';
export const TIME_FIELD = 'time';
export const DOCUMENT_FLOW = 'documentFlow';
export const PASSPORT_INFO_FIELD = 'passportInfo';
export const PASSPORT_SUBDIVISION_CODE_FIELD = 'subdivisionCode';
export const PASSPORT_WHO_ISSUED_FIELD = 'whoIssued';
