import React from 'react';
import SVGIcon from './index';

export default function IconAwningWithTruck15(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 50 25">
      <path d="m40.5 18a3.5 3.5 0 1 0 3.5 3.5 3.47 3.47 0 0 0 -3.5-3.5zm0 5a1.5 1.5 0 1 1 1.5-1.5 1.49 1.49 0 0 1 -1.5 1.5zm-28-5a3.5 3.5 0 1 0 3.5 3.5 3.47 3.47 0 0 0 -3.5-3.5zm0 5a1.5 1.5 0 1 1 1.5-1.5 1.49 1.49 0 0 1 -1.5 1.5zm35.86-9.93-5.76-3.87-10-7.51a8.91 8.91 0 0 0 -5.3-1.69h-24a3.4 3.4 0 0 0 -3.3 3.4v15.3a3.4 3.4 0 0 0 3.4 3.3h4.5l.1-.89a4.53 4.53 0 0 1 9 0l.09.89h18.81l.09-.89a4.53 4.53 0 0 1 9 0l.09.89h2.8a2.1 2.1 0 0 0 2.12-2.1v-2.8c0-1.44 0-2.94-1.64-4.03zm-7.88-3-.85.85c-1.37-.13-5.43-.54-7.8-1a1.87 1.87 0 0 1 -1.83-1.62v-2.3h5zm7.52 9.83a.11.11 0 0 1 -.1.1h-1.06a6.52 6.52 0 0 0 -12.68 0h-15.32a6.52 6.52 0 0 0 -12.68 0h-2.76a1.42 1.42 0 0 1 -1.4-1.3v-15.3a1.42 1.42 0 0 1 1.3-1.4h24a6.92 6.92 0 0 1 4.1 1.3l.93.7h-2.33a1.93 1.93 0 0 0 -2 2v2.3a3.83 3.83 0 0 0 3.53 3.7c2.91.5 8.16 1 8.38 1h.47l1.74-1.75 5.13 3.44c.72.48.75.95.75 2.37z" />
    </SVGIcon>
  );
}

IconAwningWithTruck15.defaultProps = {
  color: 'base',
};
