import { useEffect } from 'react';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

import { useOrderContext } from '../index';
import { useDeepMemoValue } from '../../../hooks/useDeepMemo';
import { useFieldValue } from '../../../components/FormikFieldsV3/hooks';
import { getDateString } from '../../../helpers/getDateString';
import { getDateWithTimezone, replaceTimezone } from '../helpers/getDateWithTimezone';
import { toast } from '../../../components/Toaster';
import { ROUTE_ADDRESS_CITY, ROUTE_POINTS } from '../blocks/RoutesBlock';
import { DEFAULT_APP_TIME_ZONE_NAME, DF_DATE_TIME_PICKER_FORMAT, DF_DATE_FORMAT } from '../../../constants';

/**
 * Получение границ контракта
 */
export function getContractDateLimit(routePoints, contractBeginsAt, contractEndsAt) {
  const lastPointIndex = routePoints.length - 1;
  const firstPointTimezoneName =
    routePoints[0]?.[ROUTE_ADDRESS_CITY]?.value?.timezone_name || DEFAULT_APP_TIME_ZONE_NAME;
  const lastPointTimezoneName =
    routePoints[lastPointIndex]?.[ROUTE_ADDRESS_CITY]?.value?.timezone_name || DEFAULT_APP_TIME_ZONE_NAME;

  if (!contractBeginsAt || !contractEndsAt || !firstPointTimezoneName || !lastPointTimezoneName) {
    return { beginsAt: null, endsAt: null };
  }

  return {
    beginsAt: startOfDay(replaceTimezone(contractBeginsAt, firstPointTimezoneName)),
    endsAt: endOfDay(replaceTimezone(contractEndsAt, lastPointTimezoneName)),
  };
}

/**
 * Валидация дат разовой заявки в рамках контракта
 * При ошибке показать тостер
 */
export function useRouteDateValidationByContract(dateFieldName: string, routePointIndex: number) {
  const { isOrderBasedOnContract, currentOrder } = useOrderContext();
  const [routePoints] = useFieldValue(ROUTE_POINTS);
  const [routeDate] = useFieldValue(dateFieldName);
  const [city] = useFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_CITY}`);
  const timezoneName = city?.value?.timezone_name || DEFAULT_APP_TIME_ZONE_NAME;
  const { beginsAt, endsAt } = useDeepMemoValue(
    getContractDateLimit(routePoints, currentOrder?.contract_begins_at, currentOrder?.contract_ends_at),
  );

  useEffect(() => {
    if (routePointIndex === 0 && isOrderBasedOnContract && routeDate && beginsAt && endsAt) {
      let selectedDate;
      try {
        selectedDate = getDateWithTimezone(routeDate, timezoneName, DF_DATE_TIME_PICKER_FORMAT);
      } catch {
        selectedDate = getDateWithTimezone(routeDate, timezoneName, DF_DATE_FORMAT);
      }
      if (isBefore(selectedDate, beginsAt)) {
        toast.error(`Дата не может быть раньше даты начала контракта ${getDateString(beginsAt.toISOString())}г.`);
      }
      if (isAfter(selectedDate, endsAt)) {
        toast.error(
          `Дата подачи не может быть позже даты окончания действия контракта ${getDateString(endsAt.toISOString())}г.`,
        );
      }
    }
  }, [beginsAt, endsAt, isOrderBasedOnContract, routeDate, routePointIndex, timezoneName]);
}
