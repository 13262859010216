import React from 'react';
import SVGIcon from './index';

const IconWarehouse = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M3.88941 5.71024C5.92289 4.79909 7.96804 3.91502 9.99958 3C11.9922 3.87246 13.9849 4.74686 15.9717 5.63673C16.5705 5.8708 17.0157 6.46276 16.9923 7.11469C17.0079 10.4091 16.9943 13.7055 16.9982 17C12.3324 17 7.66671 17 3.00098 17C3.00487 13.7075 2.9932 10.415 3.00681 7.12243C2.98737 6.53047 3.34313 5.94818 3.88941 5.71024ZM8.44433 7.71438C8.44433 8.74547 8.44433 9.7785 8.44433 10.8096C9.48051 10.8096 10.5186 10.8096 11.5548 10.8096C11.5548 9.7785 11.5548 8.74547 11.5548 7.71438C10.5186 7.71438 9.48051 7.71438 8.44433 7.71438ZM6.11147 12.3572C6.11147 13.3883 6.11147 14.4213 6.11147 15.4524C7.14765 15.4524 8.18577 15.4524 9.22196 15.4524C9.22196 14.4213 9.22196 13.3883 9.22196 12.3572C8.18577 12.3572 7.14765 12.3572 6.11147 12.3572ZM10.7772 12.3572C10.7772 13.3883 10.7772 14.4213 10.7772 15.4524C11.8134 15.4524 12.8515 15.4524 13.8877 15.4524C13.8877 14.4213 13.8877 13.3883 13.8877 12.3572C12.8515 12.3572 11.8134 12.3572 10.7772 12.3572Z"
        // fill="#ECF1F9"
      />
    </SVGIcon>
  );
};

IconWarehouse.defaultProps = {
  color: 'base',
};

export default IconWarehouse;
