import React from 'react';
import SVGIcon from './index';
import useMultiColorsIcons from '../useMultiColorIcons';

const IconCircleSmall = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  const { mainStroke, altFill } = useMultiColorsIcons(props.color);
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <rect x="6" y="7" width="8" height="8" rx="4" className={mainStroke} strokeWidth="2" />
      <rect x="7" y="8" width="6" height="6" rx="3" className={altFill} />
    </SVGIcon>
  );
};

IconCircleSmall.defaultProps = {
  color: 'baseTheme',
};

export default IconCircleSmall;
