import { useCallback, useState } from 'react';
import { useUserContext } from '../contexts/userContext';

function useStorage(storage) {
  const { userId } = useUserContext();
  const getKey = useCallback(
    (key) => {
      return `${key}-${userId}`;
    },
    [userId],
  );

  const get = useCallback(
    (key) => {
      const string = storage.getItem(getKey(key));
      if (string === 'undefined') return undefined;
      return string && JSON.parse(string);
    },
    [storage, getKey],
  );

  const set = useCallback(
    (key, value) => {
      const string = JSON.stringify(value);
      if (string === 'undefined') return;
      return storage.setItem(getKey(key), string);
    },
    [storage, getKey],
  );

  const remove = useCallback(
    (keyOrKeys) => {
      if (!Array.isArray(keyOrKeys)) {
        storage.removeItem(getKey(keyOrKeys));
      } else {
        keyOrKeys.forEach((key) => {
          storage.removeItem(getKey(key));
        });
      }
    },
    [storage, getKey],
  );

  return {
    get,
    set,
    remove,
  };
}
function useSavingToStorage(storageHook, key) {
  const { get, set } = storageHook();
  const [storageValue] = useState(get(key));
  const [currentValue, setCurrentValue] = useState(get(key));

  return {
    storageValue,
    currentValue: currentValue,
    onChange: (value) => {
      set(key, value);
      setCurrentValue(value);
    },
  };
}

export function useLocalStorage() {
  return useStorage(localStorage);
}

export function useSavingToLocalStorage(key) {
  return useSavingToStorage(useLocalStorage, key);
}

export function useSessionStorage() {
  return useStorage(sessionStorage);
}

export function useSavingToSessionStorage(key) {
  return useSavingToStorage(useSessionStorage, key);
}
