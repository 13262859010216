import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import { getIn } from 'formik';
import * as Yup from 'yup';
import {
  MAX_CARGO_HEIGHT,
  MAX_CARGO_LENGTH,
  MAX_CARGO_WEIGHT,
  MAX_CARGO_WIDTH,
  MAX_VOLUME_VALUE,
  MIN_CARGO_DIMENSIONS,
} from '../_helpers';
import { DEFAULT_APP_TIME_ZONE_NAME, DF_DATE_FORMAT, DF_DATE_TIME_PICKER_FORMAT, INSTANT } from '../../../constants';
import getFormattedPrice from '../../../helpers/getFormattedPrice';
import {
  CONTACTS,
  COUNTER_AGENT,
  IS_PERSON_INDIVIDUAL,
  LEGAL_ENTITY_FORM,
  LEGAL_ENTITY_INN,
  LEGAL_ENTITY_TITLE,
  NAME,
  NAME as CONTACT_NAME,
  PASSPORT_ISSUER,
  PASSPORT_NUMBER,
  PERSON_FULL_NAME,
  PHONE,
} from '../../../store/counterAgents/formFields';
import {
  COMPANIES,
  DESCENDING_PRICE_AUCTION,
  EXCLUSIVE,
  GROUPS,
  TAX_WITH,
  TAX_WITHOUT,
} from '../../../store/order/auction/formKeys';
import { PREPAYMENT } from '../../../store/order/formalization/formKeys';
import { ADDITIONAL_SERVICES, FLOOR_COUNT, RISE_FLOOR } from '../../../store/order/routes/formFields';
import { UNLIMITED } from '../../billing/constants';
import { TYPES } from '../../../features/insurance/components/ProfileFormInsurance/constants';
import { MESSAGES } from '../../profile/company/ProfileFormSettings/validation';
import {
  AUCTION_STAGES,
  AUCTION_TYPE,
  AUTO_EXTENDED,
  BEGINS_AT,
  ENABLE_AUCTION_AUTO_EXTEND,
  ENDS_AT,
  EXECUTORS,
  EXECUTOR_PRICE,
  IS_EXECUTOR_KNOWN,
  MAX_BID_WITHOUT_TAX,
  MIN_BID_WITHOUT_TAX,
  PARTNERS,
  PRICE_TYPE,
  PRICE_WITHOUT_TAX,
  VISIBILITY,
  WITHOUT_PRICES,
} from '../blocks/AuctionBlock';
import {
  CARGO_COST,
  CARGO_HEIGHT,
  CARGO_LENGTH,
  CARGO_NAME,
  CARGO_TONNAGE,
  CARGO_VOLUME,
  CARGO_WIDTH,
} from '../blocks/CargoBlock';
import {
  CANCELLATION_PENALTY_PERCENT,
  CANCELLATION_WITHOUT_PENALTY_BEFORE,
  CONTRACT_RESOURCE_ASSIGNMENT_TIME,
  MINIMUM_CANCELLATION_PENALTY,
  PAYER,
  PAYER_IS_PRIVATE_PERSON,
  PAYER_FULL_NAME,
  PAYER_INN,
  PAYER_OPF_FORM,
  PAYER_PASSPORT_ISSUED_AT,
  PAYER_PASSPORT_ISSUER,
  PAYER_PASSPORT_NUMBER,
  PAYER_TITLE,
  PAYMENT_PERIOD,
  PAYMENT_PROCEDURE,
  RESOURCE_ASSIGNMENT_TIME,
  SHIPPING_AVAILABLE_IN,
  TERMS_BY_PARTNERSHIP_CONTRACT,
  PAYER_CONTACTS,
} from '../blocks/DocumentsBlock';
import { EXTRA_ORDERS_COUNT, EXTRA_ORDERS_LIST, EXTRA_ORDERS_LIST_POINTS } from '../blocks/ExtraOrdersBlock';
import { FORM_TYPE, FULL, ORDER_TYPE, SHIPPING_REQUEST, SIMPLE } from '../blocks/OrderNFormTypeBlock';
import {
  IS_INTERVAL,
  QUOTA_DISTRIBUTION,
  ROUTE_ADDRESS_CITY,
  ROUTE_CONTRACT_PERIOD,
  ROUTE_COVER_TYPE,
  ROUTE_DATES,
  ROUTE_DATE_FROM,
  ROUTE_DATE_TILL,
  ROUTE_MAX_SHIPPINGS_PER_DAY,
  ROUTE_MAX_SHIPPINGS_PER_WEEK,
  ROUTE_POINTS,
  ROUTE_PRR,
  ROUTE_PRR_MIN,
  ROUTE_SHIPPINGS_COUNT,
  SHOW_CONTACTS_INFO_BLOCK,
  WITH_QUOTES,
  ADDITIONAL_AUCTION,
} from '../blocks/RoutesBlock';
import {
  BELTS,
  NUMBER_OF_BELTS,
  RESOURCE_REQUIREMENTS,
  SHOW_RESOURCE_REQUIREMENTS_BLOCK,
  TEMPERATURE_CONDITION,
  TEMPERATURE_CONDITION_MAX,
  TEMPERATURE_CONDITION_MIN,
  TONNAGE_VOLUME,
  TRANSPORT_BODY_TYPES,
} from '../blocks/TransportBlock';
import { getContractDateLimit } from '../hooks/useRouteDateVlidationByContract';
import { WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE } from '../../../store/counterAgents/formFields';
import { NEW_WAREHOUSE_SLOT_DATE, WAREHOUSE_ID } from '../blocks/RoutesBlock';
import { getDateWithTimezone } from './getDateWithTimezone';
import { getLatestRoutePointDatetime, getLoadingDatetime } from './getLoadingDatetime';
import { WITH_QUOTES_MAP } from '../components/ContractByCountInfo';
import { EXECUTOR_IDS, TAX_TYPE } from '../../../features/quotes/ExecutorKnownWithQuotes';
import {
  getAdditionalAuctionValidation,
  getQuotaDistributionValidation,
} from '../../../features/quotes/getValidationSchema';
import { INSURANCE_METHOD, INSURANCE_REQUIRED } from '../../../features/insurance/constants';

const FAKE_ERROR = ' ';
export const REQUIRED_FIELD = 'Обязательное поле';
const MIN_PRICE = 'Минимальное значение 0';
const MAX_PRICE = 'Максимальное значение 900 000 000';

function getMSKDatetime(this: any, value, originalValue) {
  // if (this.isType(value)) return value; // TODO ломает логику расчета дат для этапа торга, выяснить необходимоть данной проверки
  if (originalValue) {
    try {
      return getDateWithTimezone(originalValue, DEFAULT_APP_TIME_ZONE_NAME, DF_DATE_TIME_PICKER_FORMAT);
    } catch {
      return getDateWithTimezone(originalValue, DEFAULT_APP_TIME_ZONE_NAME, DF_DATE_FORMAT);
    }
  }
  return null;
}

function validateCancellationWithoutPenaltyBefore(value: Date | null, schema, context) {
  const { parent, from } = context;
  if (parent && value) {
    const routePoints = getIn(from, `[0].value[${ROUTE_POINTS}]`);
    const isSimpleForm = getIn(from, `[0].value[${FORM_TYPE}]`) === SIMPLE;
    const auctionStages = getIn(parent, AUCTION_STAGES) ?? [];
    const auctionStageEndsAt = isSimpleForm
      ? auctionStages[0][ENDS_AT]
      : auctionStages[auctionStages.length - 1][ENDS_AT];
    const loadingDatetime = getLoadingDatetime(routePoints, 0, isSimpleForm);
    const cancellationMinDate = auctionStageEndsAt ? addHours(auctionStageEndsAt, 1) : null;

    // Максильное - начальное значение "Дата/время прибытия" (интервала) по маршруту
    // Минимальное - дата окончания торга +1 час
    if (cancellationMinDate && loadingDatetime) {
      return schema
        .min(cancellationMinDate, 'Не менее даты окончания торга +1 час')
        .max(loadingDatetime, 'Не более даты прибытия в первую точку');
    }
    if (cancellationMinDate && !loadingDatetime) {
      return schema.min(cancellationMinDate, 'Не менее даты окончания торга +1 час');
    }
    if (!cancellationMinDate && loadingDatetime) {
      return schema.max(loadingDatetime, 'Не более даты прибытия в первую точку');
    }
  }
  return schema;
}

function validateRoutePointsDates(isOrderChange) {
  return function (this: any) {
    const { options, from, createError } = this;
    const { index, path } = options;

    if (index > 0) {
      const routePoints = getIn(from, `[1].value[${ROUTE_POINTS}]`);
      const isSimpleForm = getIn(from, `[1].value[${FORM_TYPE}]`) === SIMPLE;

      const latestRoutePointDatetime = getLatestRoutePointDatetime(routePoints, index, isSimpleForm);
      const latestPrevRoutePointDatetime = getLatestRoutePointDatetime(routePoints, index - 1, isSimpleForm);

      if (latestRoutePointDatetime && latestPrevRoutePointDatetime) {
        if (
          isBefore(latestRoutePointDatetime, latestPrevRoutePointDatetime) ||
          isEqual(latestRoutePointDatetime, latestPrevRoutePointDatetime)
        ) {
          const errorPath =
            !isSimpleForm && routePoints[index][WAREHOUSE_ID] && isOrderChange
              ? `${ROUTE_POINTS}[${index}].${NEW_WAREHOUSE_SLOT_DATE}`
              : `${path}[0].${ROUTE_DATE_FROM}`;
          return createError({
            path: errorPath,
            message: 'Измените дату на более позднюю',
          });
        }
      }
    }
    return true;
  };
}

function validateExtraOrdersRoutePointsDates(this: any) {
  const { options, from, createError } = this;
  const { index } = options;

  const routePoints = getIn(from, `[1].value[${ROUTE_POINTS}]`);
  const extraOrderRoutePointsDates = getIn(from, `[0].value[${EXTRA_ORDERS_LIST_POINTS}]`) ?? [];

  const extraOrderRoutePoints = routePoints.map((point, pointIndex) => ({
    ...point,
    [ROUTE_DATES]: extraOrderRoutePointsDates[pointIndex]
      ? [extraOrderRoutePointsDates[pointIndex]]
      : point[ROUTE_DATES],
  }));

  const errors = extraOrderRoutePoints
    .map((_, pointIndex) => {
      const latestRoutePointDatetime = getLatestRoutePointDatetime(extraOrderRoutePoints, pointIndex, true);
      const latestPrevRoutePointDatetime = getLatestRoutePointDatetime(extraOrderRoutePoints, pointIndex - 1, true);

      if (latestRoutePointDatetime && latestPrevRoutePointDatetime) {
        if (
          isBefore(latestRoutePointDatetime, latestPrevRoutePointDatetime) ||
          isEqual(latestRoutePointDatetime, latestPrevRoutePointDatetime)
        ) {
          return new Yup.ValidationError(
            'Измените дату на более позднюю',
            extraOrderRoutePoints[pointIndex][ROUTE_DATES][0][ROUTE_DATE_FROM],
            `${EXTRA_ORDERS_LIST}[${index}].${EXTRA_ORDERS_LIST_POINTS}[${pointIndex}].${ROUTE_DATE_FROM}`,
          );
        }
      }
      return null;
    })
    .filter(Boolean);

  if (errors.length !== 0) {
    return createError({ message: () => errors });
  }
  return true;
}

function validateContractDates(isOrderBasedOnContract, currentOrder) {
  return function (this: any) {
    const { originalValue, from, options, createError } = this;
    const { path, index } = options;
    const formType = getIn(from, `[2].value[${FORM_TYPE}]`);
    const routePointTimezoneName = getIn(from, `[1].value[${ROUTE_ADDRESS_CITY}].value.timezone_name`);

    const regexp = /route_points\[(\d+)\]\.route_dates\[\d+\]\.from/;
    const routePointIndex = Number(path.match(regexp)?.[1] ?? 0);

    // Валидируем только даты подачи в первой точке
    // Даты в остальных точках могут выходить за пределы контракты
    if (routePointIndex > 0) return true;

    // Для упрощенной формы валидируем только первую дату
    if (formType === SIMPLE && index > 0) return true;

    if (isOrderBasedOnContract && originalValue && routePointTimezoneName) {
      const routePoints = getIn(from, `[2].value[${ROUTE_POINTS}]`);
      const { beginsAt, endsAt } = getContractDateLimit(
        routePoints,
        currentOrder?.contract_begins_at,
        currentOrder?.contract_ends_at,
      );
      let routePointDatetime;
      try {
        routePointDatetime = getDateWithTimezone(originalValue, routePointTimezoneName, DF_DATE_TIME_PICKER_FORMAT);
      } catch {
        routePointDatetime = getDateWithTimezone(originalValue, routePointTimezoneName, DF_DATE_FORMAT);
      }

      if (beginsAt && endsAt) {
        if (isBefore(routePointDatetime, beginsAt) || isAfter(routePointDatetime, endsAt)) {
          return createError({ path, message: `Некорректное значение` });
        }
      }
    }
    return true;
  };
}

function validateRoutePointDateTill(this: any, value) {
  const { options, from, createError } = this;
  const { path } = options;
  const isInterval = !!getIn(from, `[0].value.[${IS_INTERVAL}]`);
  const routeDateFrom = getIn(from, `[0].value.[${ROUTE_DATE_FROM}]`);
  const isFullForm = getIn(from, `[1].value[${FORM_TYPE}]`) === FULL;

  if (isFullForm && isInterval) {
    if (!value) {
      return createError({ path, message: REQUIRED_FIELD });
    }
    if (!!routeDateFrom && isBefore(value, routeDateFrom)) {
      return createError({ path, message: 'Не раньше начала интервала' });
    }
  }
  return true;
}

/**
 * Валидатор для времени окончания этапа торга
 * @param value
 */
function validateAuctionStageEndsAt(this: any, value) {
  const { options, from, createError } = this;
  const { path, index, parent } = options;

  if (!!value) {
    // Не ранее текущей даты (Для первого этапа торга)
    if (index === 0) {
      // Для дев сред валидация не менее текущего, для боевой не менее текущего + 30 минут
      if (process.env.REACT_APP_STAGE_ENDS_AT_CUSTOM_VALIDATION === 'enabled') {
        if (isBefore(value, new Date())) {
          return createError({ path: `${path}`, message: 'Не ранее текущего времени' });
        }
      } else {
        if (isBefore(value, addMinutes(new Date(), 30))) {
          return createError({ path: `${path}`, message: 'Не ранее текущего времени' });
        }
      }
    } else {
      if (isBefore(value, parent[BEGINS_AT])) {
        return createError({ path: `${path}`, message: 'Не ранее даты окончания предыдущего этапа' });
      }
    }

    const routePoints = getIn(from, `[1].value[${ROUTE_POINTS}]`);
    const isSimpleForm = getIn(from, `[1].value[${FORM_TYPE}]`) === SIMPLE;
    const loadingDatetime = getLoadingDatetime(routePoints, 0, isSimpleForm);
    if (loadingDatetime && isBefore(loadingDatetime, value)) {
      return createError({ path: `${path}`, message: 'Не позднее даты подачи в первую точку' });
    }
  }
  return true;
}

function validatePartners(this: any, value) {
  const { options, createError } = this;
  const { path } = options;

  if (!(value?.[COMPANIES]?.length > 0 || value?.[GROUPS]?.length > 0)) {
    return createError({
      message: () => [
        new Yup.ValidationError('Выберите хотя бы одну группу или компанию', null, `${path}.${GROUPS}`),
        new Yup.ValidationError('Выберите хотя бы одну группу или компанию', null, `${path}.${COMPANIES}`),
      ],
    });
  }
  return true;
}

const priceSchema = Yup.number()
  .when([WITHOUT_PRICES], {
    is: false,
    then: Yup.number().min(0, MIN_PRICE).max(900_000_000, MAX_PRICE).required(REQUIRED_FIELD).nullable(),
  })
  .nullable();

const minBidsWithTaxSchema = Yup.number()
  .when([WITHOUT_PRICES, AUCTION_TYPE, PRICE_TYPE], {
    is: (withoutPrices, auctionType, priceType) =>
      !withoutPrices && auctionType !== INSTANT && priceType !== TAX_WITHOUT,
    then: Yup.number().min(0, MIN_PRICE).max(900_000_000, MAX_PRICE).required(REQUIRED_FIELD).nullable(),
  })
  .nullable();

const minBidsWithoutTaxSchema = Yup.number()
  .when([WITHOUT_PRICES, AUCTION_TYPE, PRICE_TYPE], {
    is: (withoutPrices, auctionType, priceType) => !withoutPrices && auctionType !== INSTANT && priceType !== TAX_WITH,
    then: Yup.number().min(0, MIN_PRICE).max(900_000_000, MAX_PRICE).required(REQUIRED_FIELD).nullable(),
  })
  .nullable();

const maxBidsWithTaxSchema = Yup.number()
  .when([WITHOUT_PRICES, AUCTION_TYPE, PRICE_TYPE], {
    is: (withoutPrices, auctionType, priceType) =>
      !withoutPrices &&
      auctionType !== INSTANT &&
      auctionType !== DESCENDING_PRICE_AUCTION &&
      priceType !== TAX_WITHOUT,
    then: Yup.number().min(0, MIN_PRICE).max(900_000_000, MAX_PRICE).required(REQUIRED_FIELD).nullable(),
  })
  .nullable();

const maxBidsWithoutTaxSchema = Yup.number()
  .when([WITHOUT_PRICES, AUCTION_TYPE, PRICE_TYPE], {
    is: (withoutPrices, auctionType, priceType) =>
      !withoutPrices && auctionType !== INSTANT && auctionType !== DESCENDING_PRICE_AUCTION && priceType !== TAX_WITH,
    then: Yup.number().min(0, MIN_PRICE).max(900_000_000, MAX_PRICE).required(REQUIRED_FIELD).nullable(),
  })
  .nullable();

export function getValidationSchema(context, params) {
  const { balanceCreateContract, insuranceSum } = context;
  const formattedInsuranceSum = insuranceSum ? +insuranceSum : 1;
  const { isOrderBasedOnContract, isOrderChange, currentOrder, expeditedPaymentUserBid, isExpeditedPayment } = params;
  const maxShippingCount = balanceCreateContract > UNLIMITED ? balanceCreateContract : 999999;
  return Yup.object().shape({
    [TONNAGE_VOLUME]: Yup.object().required(REQUIRED_FIELD).nullable(),
    [TRANSPORT_BODY_TYPES]: Yup.array().min(1, REQUIRED_FIELD),
    [RESOURCE_REQUIREMENTS]: Yup.object().when([FORM_TYPE, SHOW_RESOURCE_REQUIREMENTS_BLOCK], {
      is: (formType, showBlock) => formType === FULL && showBlock,
      then: Yup.object().shape({
        [NUMBER_OF_BELTS]: Yup.number().when([BELTS], {
          is: true,
          then: Yup.number().required(REQUIRED_FIELD).min(1, 'Не менее 1шт').max(100, 'Не более 100шт'),
        }),
        [TEMPERATURE_CONDITION_MIN]: Yup.number().when([TEMPERATURE_CONDITION], {
          is: true,
          then: Yup.number().required(REQUIRED_FIELD),
        }),
        [TEMPERATURE_CONDITION_MAX]: Yup.number().when([TEMPERATURE_CONDITION], {
          is: true,
          then: Yup.number().required(REQUIRED_FIELD),
        }),
      }),
    }),

    [ROUTE_CONTRACT_PERIOD]: Yup.string().when([ORDER_TYPE], {
      is: (orderType) => orderType !== SHIPPING_REQUEST,
      then: Yup.string().required(REQUIRED_FIELD),
    }),
    [ROUTE_SHIPPINGS_COUNT]: Yup.number()
      .when([ORDER_TYPE], {
        is: (orderType) => orderType !== SHIPPING_REQUEST,
        then: Yup.number()
          .required(REQUIRED_FIELD)
          .min(2, 'Не менее 2')
          .max(maxShippingCount, `Не более ${maxShippingCount}`)
          .nullable(),
      })
      .nullable(),
    [ROUTE_MAX_SHIPPINGS_PER_DAY]: Yup.number()
      .when([WITH_QUOTES], {
        is: WITH_QUOTES_MAP.notUse,
        then: (schema) =>
          schema
            // @ts-ignore
            .when([ORDER_TYPE, ROUTE_SHIPPINGS_COUNT, WITH_QUOTES], (orderType, shippingsCount, withQuotes, schema) =>
              orderType !== SHIPPING_REQUEST && !!shippingsCount
                ? schema.max(shippingsCount, 'Не более объёма')
                : schema,
            )
            .nullable(),
      })
      .nullable(),
    [ROUTE_MAX_SHIPPINGS_PER_WEEK]: Yup.number()
      .when([WITH_QUOTES], {
        is: WITH_QUOTES_MAP.notUse,
        then: (schema) =>
          schema
            // @ts-ignore
            .when([ORDER_TYPE, ROUTE_SHIPPINGS_COUNT], (orderType, shippingsCount, schema) =>
              orderType !== SHIPPING_REQUEST && !!shippingsCount
                ? schema.max(shippingsCount, 'Не более объёма')
                : schema,
            )
            .nullable(),
      })
      .nullable(),
    [ROUTE_POINTS]: Yup.array()
      .of(
        Yup.object().shape({
          [ROUTE_ADDRESS_CITY]: Yup.object().required(REQUIRED_FIELD).nullable(),
          [ROUTE_COVER_TYPE]: Yup.array().min(1, REQUIRED_FIELD),
        }),
      )
      .when([ORDER_TYPE], {
        is: SHIPPING_REQUEST,
        then: Yup.array().of(
          Yup.object().shape({
            [WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE]: Yup.object()
              .when([WAREHOUSE_ID], {
                is: (value) => !!value,
                then: Yup.object().required(REQUIRED_FIELD).nullable(),
              })
              .nullable(),
          }),
        ),
      })
      .when([FORM_TYPE], {
        is: (formType) => !isOrderBasedOnContract && formType === SIMPLE,
        then: Yup.array().of(
          Yup.object().shape({
            [ROUTE_PRR]: Yup.number().min(1, 'Минимум 1 час').required(REQUIRED_FIELD),
          }),
        ),
      })
      .when([FORM_TYPE], {
        is: (formType) => isOrderBasedOnContract || formType === FULL,
        then: Yup.array().of(
          Yup.object().shape({
            [ROUTE_PRR]: Yup.number().required(REQUIRED_FIELD),
            [ROUTE_PRR_MIN]: Yup.number()
              .required(REQUIRED_FIELD)
              .when([ROUTE_PRR], {
                is: (routePrr) => !routePrr,
                then: Yup.number().min(10, 'Минимум 10 минут').nullable(),
              }),
          }),
        ),
      })
      .when([FORM_TYPE], {
        is: FULL,
        then: Yup.array().of(
          Yup.object().shape({
            [FLOOR_COUNT]: Yup.number()
              .when([ADDITIONAL_SERVICES], {
                is: (additionalServices) => !!additionalServices?.[RISE_FLOOR],
                then: Yup.number().required(REQUIRED_FIELD).nullable(),
              })
              .nullable(),
            [COUNTER_AGENT]: Yup.object()
              .when([SHOW_CONTACTS_INFO_BLOCK], {
                is: true,
                then: Yup.object().shape({
                  /* Физ лицо */
                  [PERSON_FULL_NAME]: Yup.string().when([IS_PERSON_INDIVIDUAL], {
                    is: true,
                    then: Yup.string().required(REQUIRED_FIELD).nullable(),
                    otherwise: Yup.string().nullable(),
                  }),
                  [PASSPORT_NUMBER]: Yup.string().when([IS_PERSON_INDIVIDUAL], {
                    is: true,
                    then: Yup.string().required(REQUIRED_FIELD).nullable(),
                    otherwise: Yup.string().nullable(),
                  }),
                  [PASSPORT_ISSUER]: Yup.string().when([IS_PERSON_INDIVIDUAL], {
                    is: true,
                    then: Yup.string().required(REQUIRED_FIELD).nullable(),
                    otherwise: Yup.string().nullable(),
                  }),

                  /* Юр лицо */
                  [LEGAL_ENTITY_TITLE]: Yup.string().when([IS_PERSON_INDIVIDUAL], {
                    is: (isPersonIndividual) => !isPersonIndividual,
                    then: Yup.string().required(REQUIRED_FIELD).nullable(),
                    otherwise: Yup.string().nullable(),
                  }),
                  [LEGAL_ENTITY_INN]: Yup.string().when([IS_PERSON_INDIVIDUAL], {
                    is: (isPersonIndividual) => !isPersonIndividual,
                    then: Yup.string()
                      .required(REQUIRED_FIELD)
                      .matches(/^((\d){10}|(\d){12})$/, 'Некорректный ИНН')
                      .nullable(),
                    otherwise: Yup.string().nullable(),
                  }),
                  [LEGAL_ENTITY_FORM]: Yup.string().when([IS_PERSON_INDIVIDUAL], {
                    is: (isPersonIndividual) => !isPersonIndividual,
                    then: Yup.string().required(REQUIRED_FIELD).nullable(),
                    otherwise: Yup.string().nullable(),
                  }),
                }),
              })
              .nullable(),
            [CONTACTS]: Yup.array().when([SHOW_CONTACTS_INFO_BLOCK], {
              is: true,
              then: Yup.array().of(
                Yup.object().shape(
                  {
                    [CONTACT_NAME]: Yup.string()
                      .when([PHONE], {
                        is: (phone) => !!phone,
                        then: Yup.string().required(REQUIRED_FIELD).nullable(),
                      })
                      .nullable(),
                    [PHONE]: Yup.string().when([CONTACT_NAME], {
                      is: (name) => !!name,
                      then: Yup.string().required(REQUIRED_FIELD).length(12, 'Некорректный формат').nullable(),
                    }),
                  },
                  [[CONTACT_NAME, PHONE]],
                ),
              ),
            }),
          }),
        ),
      })
      .when([ORDER_TYPE], {
        is: SHIPPING_REQUEST,
        then: Yup.array().of(
          Yup.object().shape({
            [ROUTE_DATES]: Yup.array()
              .of(
                Yup.object().shape({
                  [ROUTE_DATE_FROM]: Yup.date()
                    .transform(getMSKDatetime)
                    .required(REQUIRED_FIELD)
                    .test(`is-point-date-in-contract`, '', validateContractDates(isOrderBasedOnContract, currentOrder))
                    .nullable(),
                  [ROUTE_DATE_TILL]: Yup.date()
                    .transform(getMSKDatetime)
                    .test(`is-date-till-valid`, '', validateRoutePointDateTill)
                    .nullable(),
                }),
              )
              .test(`is-route-dates-valid`, '', validateRoutePointsDates(isOrderChange)),
          }),
        ),
      }),
    [AUCTION_STAGES]: Yup.array().when([IS_EXECUTOR_KNOWN], {
      is: (isExecutorKnown) => !isExecutorKnown && !isOrderChange,
      then: Yup.array().of(
        Yup.object().shape({
          [PARTNERS]: Yup.object()
            .when([VISIBILITY], {
              is: (visibility) => visibility === EXCLUSIVE,
              then: Yup.object()
                .shape({
                  [GROUPS]: Yup.array().of(Yup.string()).nullable(),
                  [COMPANIES]: Yup.array().of(Yup.string()).nullable(),
                })
                .test(`is-partners-valid`, REQUIRED_FIELD, validatePartners)
                .nullable(),
            })
            .nullable(),
          [VISIBILITY]: Yup.string().required(REQUIRED_FIELD),
          [PRICE_WITHOUT_TAX]: priceSchema,
          [MIN_BID_WITHOUT_TAX]: minBidsWithoutTaxSchema
            .when([PRICE_WITHOUT_TAX], (value, schema) => schema.max(value, 'Не более начальной ставки').nullable())
            .nullable(),
          [MAX_BID_WITHOUT_TAX]: maxBidsWithoutTaxSchema
            .when([PRICE_WITHOUT_TAX], (value, schema) => schema.min(value, 'Не менее начальной ставки').nullable())
            .nullable(),
          [BEGINS_AT]: Yup.date().transform(getMSKDatetime).nullable(),
          [ENDS_AT]: Yup.date()
            .transform(getMSKDatetime)
            .when([BEGINS_AT], (beginsAt, schema) => {
              if (beginsAt) {
                // ХХХ: эта логика переехала в validateAuctionStageEndsAt
                // if same -> не ранее текущей даты (Для первого этапа торга)
                // if (isSameSecond(beginsAt, getHalfHourRounded(new Date()))) {
                //   return schema
                //     .required(REQUIRED_FIELD)
                //     .min(addMinutes(beginsAt, 30), 'Не ранее текущего времени2')
                //     .test(`is-auction-stage-ends-at-valid`, '', validateAuctionStageEndsAt);
                // }
                return (
                  schema
                    .required(REQUIRED_FIELD)
                    // .min(beginsAt, 'Не ранее даты окончания предыдущего этапа')
                    .test(`is-auction-stage-ends-at-valid`, '', validateAuctionStageEndsAt)
                );
              }
              return schema;
            })
            .nullable(),
        }),
      ),
    }),
    [AUTO_EXTENDED]: Yup.number()
      .when([ENABLE_AUCTION_AUTO_EXTEND], {
        is: (value) => !!value,
        then: Yup.number().required(REQUIRED_FIELD).min(1, 'Не менее 1 часа').max(24, 'Не более 24 часов').nullable(),
      })
      .nullable(),
    [EXECUTORS]: Yup.object()
      .when([IS_EXECUTOR_KNOWN, WITH_QUOTES], {
        is: (isKnown, withQuotes) => isKnown && withQuotes !== WITH_QUOTES_MAP.use,
        then: Yup.object().required(REQUIRED_FIELD).nullable(),
      })
      .nullable(),
    [EXECUTOR_IDS]: Yup.array()
      .when([IS_EXECUTOR_KNOWN, WITH_QUOTES], {
        // for EXECUTORS_ARRAY
        is: (isKnown, withQuotes) => isKnown && withQuotes === WITH_QUOTES_MAP.use,
        then: Yup.array().required(REQUIRED_FIELD).min(1, REQUIRED_FIELD).of(Yup.string()).nullable(),
      })
      .nullable(),
    [EXECUTOR_PRICE]: Yup.number()
      .when([IS_EXECUTOR_KNOWN, WITH_QUOTES], {
        is: (isKnown, withQuotes) => isKnown && withQuotes !== WITH_QUOTES_MAP.use,
        then: Yup.number().required(REQUIRED_FIELD).min(1, 'Не менее 1 рубля').nullable(),
      })
      .nullable(),
    [TAX_TYPE]: Yup.string()
      .when([IS_EXECUTOR_KNOWN, WITH_QUOTES], {
        is: (isKnown, withQuotes) => isKnown && withQuotes === WITH_QUOTES_MAP.use,
        then: Yup.string().required(REQUIRED_FIELD).nullable(),
      })
      .nullable(),
    [QUOTA_DISTRIBUTION]: Yup.array()
      .when([ORDER_TYPE, IS_EXECUTOR_KNOWN, WITH_QUOTES], {
        is: (orderType, isKnown, withQuotes) =>
          orderType !== SHIPPING_REQUEST && isKnown && withQuotes === WITH_QUOTES_MAP.use,
        then: getQuotaDistributionValidation({ requireMessage: REQUIRED_FIELD }),
      })
      .nullable(),
    [ADDITIONAL_AUCTION]: Yup.object()
      .when([ORDER_TYPE, IS_EXECUTOR_KNOWN, WITH_QUOTES], {
        is: (orderType, isKnown, withQuotes) =>
          orderType !== SHIPPING_REQUEST && isKnown && withQuotes === WITH_QUOTES_MAP.use,
        then: getAdditionalAuctionValidation({ requireMessage: REQUIRED_FIELD }),
      })
      .nullable(),
    /* Поля стоимости для ИТ */
    [PRICE_WITHOUT_TAX]: Yup.number()
      .when([], {
        is: () => isOrderChange,
        then: Yup.number()
          .required(REQUIRED_FIELD)
          .test({
            name: `is-date-till-valid`,
            message: 'Указанная стоимость слишком мала',
            test: () => (isExpeditedPayment ? (expeditedPaymentUserBid ?? 0) > 0 : true),
          })
          .nullable(),
      })
      .nullable(),

    [RESOURCE_ASSIGNMENT_TIME]: Yup.number()
      .when([ORDER_TYPE], {
        is: (orderType) => orderType === SHIPPING_REQUEST,
        then: Yup.number()
          .required(REQUIRED_FIELD)
          .min(0.02, 'Минимальное значение 1 минута')
          .max(2400, 'Максимальное значение 2400 часов')
          .nullable(),
      })
      .nullable(),
    [CONTRACT_RESOURCE_ASSIGNMENT_TIME]: Yup.number()
      .when([ORDER_TYPE], {
        is: (orderType) => orderType !== SHIPPING_REQUEST,
        then: Yup.number()
          .required(REQUIRED_FIELD)
          .min(0.02, 'Минимальное значение 1 минута')
          .max(2400, 'Максимальное значение 2400 часов')
          .nullable(),
      })
      .nullable(),
    [SHIPPING_AVAILABLE_IN]: Yup.number()
      .min(1, 'Минимально значение 1 час')
      .when([ORDER_TYPE], {
        is: (orderType) => orderType !== SHIPPING_REQUEST,
        then: Yup.number().required(REQUIRED_FIELD),
      })
      .nullable(),

    [CARGO_NAME]: Yup.object().required('Введите наименование груза').nullable(),
    [CARGO_TONNAGE]: Yup.number()
      .required('Введите вес')
      .max(MAX_CARGO_WEIGHT, `Максимальный вес ${MAX_CARGO_WEIGHT} т`)
      .nullable(),
    [CARGO_VOLUME]: Yup.number().max(MAX_VOLUME_VALUE, 'Максимальный объем 150 м\u00B3').nullable(),
    [CARGO_LENGTH]: Yup.number()
      .min(MIN_CARGO_DIMENSIONS, `Мин. длина ${MIN_CARGO_DIMENSIONS} м`)
      .max(MAX_CARGO_LENGTH, `Макс. длина ${MAX_CARGO_LENGTH} м`)
      .nullable(),
    [CARGO_HEIGHT]: Yup.number()
      .min(MIN_CARGO_DIMENSIONS, `Мин. высота ${MIN_CARGO_DIMENSIONS} м`)
      .max(MAX_CARGO_HEIGHT, `Макс. высота ${MAX_CARGO_HEIGHT} м`)
      .nullable(),
    [CARGO_WIDTH]: Yup.number()
      .min(MIN_CARGO_DIMENSIONS, `Мин. ширина ${MIN_CARGO_DIMENSIONS} м`)
      .max(MAX_CARGO_WIDTH, `Макс. ширина ${MAX_CARGO_WIDTH} м`)
      .nullable(),

    [PAYER]: Yup.object().shape({
      [PAYER_TITLE]: Yup.string().when([PAYER_IS_PRIVATE_PERSON], {
        is: (isPersonPrivatePerson) => !isPersonPrivatePerson,
        then: Yup.string().required(REQUIRED_FIELD).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      [PAYER_INN]: Yup.string().when([PAYER_IS_PRIVATE_PERSON], {
        is: (isPersonPrivatePerson) => !isPersonPrivatePerson,
        then: Yup.string()
          .required(REQUIRED_FIELD)
          .matches(/^((\d){10}|(\d){12})$/, 'Некорректный ИНН')
          .nullable(),
        otherwise: Yup.string()
          .required(REQUIRED_FIELD)
          .matches(/^(\d){12}$/, 'Некорректный ИНН')
          .nullable(),
      }),
      [PAYER_OPF_FORM]: Yup.string().when([PAYER_IS_PRIVATE_PERSON], {
        is: (isPersonPrivatePerson) => !isPersonPrivatePerson,
        then: Yup.string().required(REQUIRED_FIELD).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      [PAYER_FULL_NAME]: Yup.string().when([PAYER_IS_PRIVATE_PERSON], {
        is: (isPersonPrivatePerson) => isPersonPrivatePerson,
        then: Yup.string().required(REQUIRED_FIELD).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      [PAYER_PASSPORT_NUMBER]: Yup.string().when([PAYER_IS_PRIVATE_PERSON], {
        is: true,
        then: Yup.string().required(REQUIRED_FIELD).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      [PAYER_PASSPORT_ISSUER]: Yup.string().when([PAYER_IS_PRIVATE_PERSON], {
        is: true,
        then: Yup.string().required(REQUIRED_FIELD).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      [PAYER_PASSPORT_ISSUED_AT]: Yup.string().when([PAYER_IS_PRIVATE_PERSON], {
        is: true,
        then: Yup.string().required(REQUIRED_FIELD).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      [PAYER_CONTACTS]: Yup.array().of(
        Yup.object().shape({
          [NAME]: Yup.string().required(REQUIRED_FIELD).nullable(),
          [PHONE]: Yup.string().required(REQUIRED_FIELD).length(12, 'Некорректный формат').nullable(),
        }),
      ),
    }),

    [CANCELLATION_WITHOUT_PENALTY_BEFORE]: Yup.date()
      .transform(getMSKDatetime)
      .when([TERMS_BY_PARTNERSHIP_CONTRACT, ORDER_TYPE], {
        is: (byTerms, orderType) =>
          !byTerms && orderType === SHIPPING_REQUEST && !isOrderBasedOnContract && !isOrderChange,
        then: Yup.date()
          .transform(getMSKDatetime)
          .required(REQUIRED_FIELD)
          .nullable()
          // @ts-ignore
          .when(CANCELLATION_WITHOUT_PENALTY_BEFORE, validateCancellationWithoutPenaltyBefore),
      })
      .nullable(),
    [CANCELLATION_PENALTY_PERCENT]: Yup.number()
      .when([TERMS_BY_PARTNERSHIP_CONTRACT, ORDER_TYPE], {
        is: (byTerms, orderType) => !byTerms && orderType === SHIPPING_REQUEST,
        then: Yup.number().required(REQUIRED_FIELD),
      })
      .nullable(),
    [MINIMUM_CANCELLATION_PENALTY]: Yup.number()
      .when([TERMS_BY_PARTNERSHIP_CONTRACT, ORDER_TYPE], {
        is: (byTerms, orderType) => !byTerms && orderType === SHIPPING_REQUEST,
        then: Yup.number().required(REQUIRED_FIELD),
      })
      .nullable(),
    [PAYMENT_PERIOD]: Yup.number()
      .when([TERMS_BY_PARTNERSHIP_CONTRACT, PAYMENT_PROCEDURE], {
        is: (byTerms, procedure) => !byTerms && procedure !== PREPAYMENT,
        then: Yup.number().required(REQUIRED_FIELD).min(1, 'Минимум 1').max(90, 'Максимум 90'),
      })
      .nullable(),
    [EXTRA_ORDERS_LIST]: Yup.array().when([EXTRA_ORDERS_COUNT, ORDER_TYPE], {
      is: (count, orderType) => count > 0 && orderType === SHIPPING_REQUEST && !isOrderChange,
      then: Yup.array()
        .of(
          Yup.object()
            .shape({
              [EXTRA_ORDERS_LIST_POINTS]: Yup.array()
                .of(
                  Yup.object().shape({
                    [ROUTE_DATE_FROM]: Yup.date()
                      .transform(getMSKDatetime)
                      .required(FAKE_ERROR)
                      // .test(`is-point-date-in-contract`, '', validateExtraOrdersDates(isOrderBasedOnContract, currentOrder))
                      .nullable(),
                    [ROUTE_DATE_TILL]: Yup.date()
                      .transform(getMSKDatetime)
                      .required(FAKE_ERROR)
                      .test(`is-date-till-valid`, '', validateRoutePointDateTill)
                      .nullable(),
                  }),
                )
                .test(`is-route-dates-valid`, '', validateExtraOrdersRoutePointsDates)
                .nullable(),
            })
            .when([WITH_QUOTES], {
              is: WITH_QUOTES_MAP.notUse,
              then: (schema) => schema,
              otherwise: (schema) =>
                Yup.array()
                  .of(
                    Yup.object().shape({
                      [ROUTE_DATES]: schema,
                    }),
                  )
                  .nullable(),
            }),
        )
        .nullable(),
    }),
    [CARGO_COST]: Yup.number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .when([INSURANCE_REQUIRED], {
        is: (isRequired) => isRequired,
        then: (schema) =>
          schema
            .required(REQUIRED_FIELD)
            .max(50000000, MESSAGES.MAX_INSURANCE_SUM)
            .when([INSURANCE_METHOD], {
              is: (method) => method === TYPES.REQUIRED,
              then: (schema) =>
                schema.min(formattedInsuranceSum, `Не может быть меньше ${getFormattedPrice(formattedInsuranceSum)} ₽`),
              otherwise: (schema) => schema.min(1, MESSAGES.GR_ONE),
            }),
      }),
    // [INSURANCE_SUM]: Yup.number()
    //   .nullable()
    //   .when([INSURANCE_REQUIRED], {
    //     is: (isRequired, method) => isRequired,
    //     then: (schema) => schema.required(REQUIRED_FIELD)
    //   }),
  });
}
