import React from 'react';
import SVGIcon from './index';

function IconGraphics(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M8 10.5V8.5H6V10.5H8Z" />
      <path d="M6 13.5V11.5H8V13.5H6Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6 3H7V4H13V3H14V4H16V16H4V4H6V3ZM15 7H5V15H15V7Z" />
      <path d="M14 10.5V8.5H12V10.5H14Z" />
      <path d="M11 10.5V8.5H9V10.5H11Z" />
    </SVGIcon>
  );
}

IconGraphics.defaultProps = {
  color: 'base',
};

export default React.memo(IconGraphics);
