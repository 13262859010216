import http from './config/webConfig';
import _get from 'lodash/get';
import pageListMapper from '../helpers/mappers/pageListMapper';

export async function getShippingReserved(params = {}) {
  const url = '/shippings';
  return http.get(url, { params, cache: false }).then(pageListMapper);
}

/**
 * Получение перевозки по ID
 * @param shippingId
 * @returns {Promise<*>}
 */
export async function getShipping(shippingId) {
  const url = `/shippings/${shippingId}`;
  return http.get(url, { cache: false }).then((response) => response.data);
}

/**
 * Обновление перевозки по ID
 * Несмотря на то что PUT, работает как PATCH
 * @param shippingId
 * @param data
 * @returns {Promise<*>}
 */
export async function updateShipping(shippingId, data) {
  const url = `/shippings/${shippingId}`;
  return http.put(url, data, { cache: false }).then((response) => response.data);
}

/**
 * Получение расчетного времени оплаты
 * @param shippingId
 * @returns {Promise<*>}
 */
export async function getSuggestedPaymentDate(shippingId) {
  const url = `/shippings/${shippingId}/suggested_payment_date`;
  return http.get(url, { cache: false }).then((response) => response.data);
}

/**
 * Установить время подачи автомобиля для точки
 * @param shippingId
 * @param routePointId
 * @param supplyAt
 * @returns {Promise<*>}
 */
export async function setDate(shippingId, routePointId, data) {
  if (data.isWarehouse) {
    const url = `/shippings/${shippingId}/reserve_gate`;
    return http.post(
      url,
      { from: data.from, till: data.till, route_point_id: routePointId, gate_id: data.gateId },
      { cache: false },
    );
  }

  const url = `/shippings/${shippingId}/route_points/${routePointId}/set_car_supply_time`;
  return http.post(url, { car_supply_at: data.supplyAt }, { cache: false });
}

/**
 * Установить вермя подачи автомобиля для точки
 * из списка списком
 * @param shippingId
 * @param dates
 * @returns {Promise<*>}
 */
export async function setDates(shippingId, dates) {
  // return Promise.all(
  //   dates.map((date) => {
  //     setDate(shippingId, date.routePointId, date.supplyAt);
  //   })
  // )
  //   .then((response) => response.data)
  //   .catch((e) => e);
  // TODO: бэк не может обрать паралельные потоки
  let response;
  for (let i = 0; i < dates.length; i++) {
    response = await setDate(shippingId, dates[i].routePointId, dates[i]);
  }
  return _get(response, 'data');
}

/**
 * Сохранение ресурсов на сегменты
 * @param shippingId
 * @param params
 * @returns {Promise<any[]>}
 */
export async function setResourcesForShipping(shippingId, params) {
  const url = `/shippings/${shippingId}/assign_resources`;
  return http.post(url, params, { cache: false }).then((response) => response.data);
}

/**
 * отмена бронирования заявки
 * @param shippingId
 * @param params
 * @returns {Promise<*>}
 */
export async function cancelOfferShipping(shippingId, params = {}) {
  const url = `/shippings/${shippingId}/cancel`;
  return http.post(url, params, { cache: false }).then((response) => response.data);
}

/**
 * Получение транспортной накладной
 * @param shippingId
 * @returns {Promise<*>}
 */
export async function getTransportFiles(shippingId) {
  const url = `/shippings/${shippingId}/documents`;
  return http.get(url, { cache: false }).then((response) => response.data);
}

/**
 * Получение печатной формы
 * @param shippingId
 * @returns {Promise<*>}
 */
export async function getPrintableForm(shippingId) {
  const url = `/shippings/${shippingId}/scancopies/template`;
  return http.get(url, { cache: false }).then((response) => response.data);
}

/**
 * Отправка скан-копии на согласование
 * @param shippingId
 * @param scanCopyId
 * @returns {Promise<*>}
 */
export async function sendDocumentToReview(shippingId, scanCopyId) {
  const url = `/shippings/${shippingId}/scancopies/${scanCopyId}/send_to_review`;
  return http.post(url, {}, { cache: false });
}

/**
 * Подключить/отключить ускоренную оплату
 * @param shippingId
 * @param payload
 * @returns {Promise<*>}
 */
export async function setExpeditedPayment(shippingId, payload) {
  const url = `/shippings/${shippingId}/expedited_payment`;
  return http.put(url, payload, { cache: false }).then((response) => response.data);
}

/**
 * Скачать отчет по произведённым автодоставкам за выбранный период
 * @param params
 * @returns {Promise<*>}
 */
export async function getDeliveryReport(params) {
  const url = '/shippings/delivery_report';
  return http.get(url, { params, responseType: 'blob', cache: false }).then((response) => response.data);
}

export async function getAvailableIntervals(shippingId, params) {
  const url = `/shippings/${shippingId}/free_intervals`;
  return http.get(url, { params }).then((response) => response.data);
}
