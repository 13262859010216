import React from 'react';
import SVGIcon from './index';

function IconFastPay(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM7.2749 5.00389C7.68378 5.00895 8.09288 5.00615 8.50204 5.00335C9.2121 4.99848 9.92233 4.99362 10.6319 5.02984C11.5468 5.07135 12.4791 5.64047 12.81 6.60226C13.2094 7.74568 12.9993 9.28351 11.9875 9.97026C11.2548 10.4818 10.3854 10.4691 9.53688 10.4568H9.53686C9.28343 10.4531 9.03186 10.4495 8.78635 10.4598V10.4599C8.78635 10.7176 8.78635 10.9736 8.78476 11.2313C9.02342 11.2321 9.26207 11.2318 9.50073 11.2316H9.50113C9.81919 11.2313 10.1373 11.2311 10.4553 11.233V12.0945C10.1371 12.0965 9.81946 12.0962 9.50171 12.0959H9.50168C9.26336 12.0957 9.025 12.0955 8.78635 12.0962C8.78317 12.7294 8.78476 13.3607 8.78794 13.9921C8.2836 14.0025 7.77925 14.0008 7.27331 13.9973C7.27262 13.7262 7.27282 13.4551 7.27301 13.1838V13.1833C7.27327 12.8213 7.27353 12.459 7.27171 12.0962H6.0005V11.2313H7.27331V10.4598C6.8501 10.4581 6.42689 10.4581 6.00527 10.4598C5.99891 10.0256 5.99891 9.59142 6.00209 9.1555H7.27331C7.27399 8.56242 7.27379 7.96933 7.2736 7.37624C7.27334 6.58546 7.27308 5.79467 7.2749 5.00389ZM8.77999 9.14858C8.95267 9.14809 9.12561 9.15144 9.29863 9.1548H9.29863C9.72819 9.16313 10.1582 9.17147 10.5858 9.12091C11.8331 8.93581 11.8236 6.56247 10.6017 6.34105C10.2143 6.29315 9.82368 6.29978 9.43313 6.3064C9.21732 6.31007 9.00153 6.31373 8.78635 6.30819C8.78334 6.75638 8.78354 7.20457 8.78374 7.65294V7.65297C8.78396 8.1512 8.78418 8.64965 8.77999 9.14858ZM13 13.5L11 13.75L13 10V12.5L15 12.25L13 16V13.5Z"
      />
    </SVGIcon>
  );
}

IconFastPay.defaultProps = {
  color: 'base',
};

export default React.memo(IconFastPay);
