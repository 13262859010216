import classNames from 'classnames';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';

import { IconClose } from '../../Icons';
import ModalWrapper from '../ModalWrapper';

enum BG_COLORS {
  WHITE = 'white',
  DARKER = 'darker',
}
type TBgColor = BG_COLORS.WHITE | BG_COLORS.DARKER;

const BG_COLORS_PARAMS = {
  [BG_COLORS.WHITE]: styles.bgWhite,
  [BG_COLORS.DARKER]: styles.bgDarker,
};
interface ConfirmProps {
  title: string | React.ReactNode;
  text?: string | React.ReactNode;
  children: React.ReactNode;
  onClose?: () => void;
  textAlign: 'center' | 'left';
  showCloseIcon: boolean;
  bgColor: TBgColor;
}

function ModalConfirm({ title, text, children, onClose, textAlign, showCloseIcon, bgColor }: ConfirmProps) {
  useEffect(() => {
    document.body.style.cssText = 'overflow-y: hidden;';

    return () => {
      document.body.style.cssText = '';
    };
  });

  const childrenStyle: string = React.Children.count(children) > 1 ? 'space-between' : 'centered';

  return (
    <ModalWrapper onClose={onClose} disableClosing>
      <div className={classNames(styles.wrapper, BG_COLORS_PARAMS[bgColor])}>
        {showCloseIcon && (
          <IconClose width="30" height="30" color="base" className={styles.closeIcon} onClick={onClose} />
        )}
        <div className={styles.container}>
          <h3 className="mt-0 mb-7 text-align-center">{title}</h3>
          {Boolean(text) && (
            <div
              className={classNames(styles.text, {
                [styles[textAlign]]: textAlign,
              })}
            >
              {text}
            </div>
          )}
          <div
            className={classNames(styles.actions, {
              [styles[childrenStyle]]: childrenStyle,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

ModalConfirm.defaultProps = {
  showCloseIcon: true,
  textAlign: 'center',
  bgColor: BG_COLORS.WHITE,
};

ModalConfirm.bgColors = BG_COLORS;

export default ModalConfirm;
