import React from 'react';
import SVGIcon from './index';

function IconPaycheck(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H1V17H19V3ZM8 7H3V9H8V7ZM3 11H7V13H3V11ZM11 13V15H12.822V13H14.7033V12H12.822V10.4454H13.6161C14.4631 10.4454 15.1309 10.3151 15.6195 10.0546C16.1163 9.79412 16.4706 9.44958 16.6824 9.02101C16.8941 8.59244 17 8.13445 17 7.64706C17 6.82353 16.7394 6.17647 16.2182 5.70588C15.7051 5.23529 14.8866 5 13.7627 5H11V7V8.88235H10V10.4454H11V12H10V13H11ZM13.4328 8.88235H12.822V6.56303H13.6649C14.6422 6.56303 15.1309 6.94538 15.1309 7.71008C15.1309 8.12185 14.9761 8.42017 14.6667 8.60504C14.3653 8.78992 13.954 8.88235 13.4328 8.88235Z"
      />
    </SVGIcon>
  );
}

IconPaycheck.defaultProps = { color: 'base' };

export default React.memo(IconPaycheck);
