import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

function IconSwapHoriz(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4L18 7.5L14 11V8.25H9V6.75H14V4ZM6 10L2 13L6 16V13.75H11V12.25H6V10Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
}

IconSwapHoriz.defaultProps = {
  color: 'base',
};

export default IconSwapHoriz;
