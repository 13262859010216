export const MAX_VOLUME_VALUE = 170;
export const MAX_CARGO_WEIGHT = 60;

export const MIN_CARGO_DIMENSIONS = 0.5;

export const MAX_CARGO_LENGTH = 17;
export const MAX_CARGO_WIDTH = 2.75;
export const MAX_CARGO_HEIGHT = 3;

/* Пока не используем */
// export const MIN_CARGO_WEIGHT = 11;
