import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

function IconShieldLock(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 14 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.33334C2.8 1.44444 7 0.5 7 0.5C7 0.5 11.2 1.44444 14 3.33334V8.05556C14 11.8333 10.7333 16.0833 7 17.5C3.26667 16.0833 8.08597e-05 11.8333 8.08597e-05 8.05556L0 3.33334ZM3.5 11.8C3.5 12.462 4.038 13 4.7 13H9.3C9.962 13 10.5 12.462 10.5 11.8V8.2C10.5 7.606 10.067 7.112 9.5 7.017V6.5C9.5 5.12 8.38 4 7 4C5.62 4 4.5 5.12 4.5 6.5V7.017C3.933 7.112 3.5 7.606 3.5 8.2V11.8ZM8.49999 6.476V7L5.49999 7.024V6.5C5.49999 5.5 6.299 5 7 5C7.701 5 8.49999 5.5 8.49999 6.476Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
}

IconShieldLock.defaultProps = {
  color: 'base',
};

export default React.memo(IconShieldLock);
