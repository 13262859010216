import React from 'react';
import SVGIcon from './index';

export default function IconAwningWithTruck20(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 75 25">
      <path d="m70 3.31-11-1.56v16.25h-1v-18h-58v20h3v3h3.34a3.5 3.5 0 0 0 6.32 0h1.7a3.55 3.55 0 0 0 3.14 2 3.44 3.44 0 0 0 3.16-2h1.7a3.55 3.55 0 0 0 3.14 2 3.44 3.44 0 0 0 3.16-2h3.34v-3h13v3h3.34a3.5 3.5 0 0 0 6.32 0h7.68a3.5 3.5 0 0 0 6.32 0h4.34a2 2 0 0 0 2-2v-11.2c0-2.59-2.21-6.06-5-6.49zm-63.95 17.69h-1.05v-1h1.34a3.39 3.39 0 0 0 -.29 1zm3.45 2a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1 -1.5 1.5zm4.55-2h-1.05a3.39 3.39 0 0 0 -.29-1h1.68a3.39 3.39 0 0 0 -.34 1zm3.45 2a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1 -1.5 1.5zm4.55-2h-1.05a3.39 3.39 0 0 0 -.29-1h1.68a3.39 3.39 0 0 0 -.34 1zm3.45 2a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1 -1.5 1.5zm4.5-2h-1a3.39 3.39 0 0 0 -.29-1h1.29zm18 0h-1v-1h1.34a3.39 3.39 0 0 0 -.29 1zm3.45 2a1.5 1.5 0 1 1 1.55-1.5 1.5 1.5 0 0 1 -1.5 1.5zm-49.45-5v-16h54v16zm60.05 3h-7.05a3.39 3.39 0 0 0 -.29-1h7.68a3.39 3.39 0 0 0 -.34 1zm3.45 2a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1 -1.5 1.5zm7.5-2h-4a3.45 3.45 0 0 0 -3.45-3h-4.55v-13.95l8.75 1.24c1 .16 2.17 1.37 2.79 2.71h-8.64v3.1a3.68 3.68 0 0 0 2.86 3.77l6.24 1.52zm0-6.67-5.73-1.39a1.69 1.69 0 0 1 -1.37-1.84v-1.1h7.1z" />
    </SVGIcon>
  );
}

IconAwningWithTruck20.defaultProps = {
  color: 'base',
};
