import React from 'react';
import SVGIcon from './index';

const IconRating = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path
        fillRule="evenodd"
        d="M15 20.27L21.18 24l-1.64-7.03L25 12.24l-7.19-.61L15 5l-2.81 6.63-7.19.61 5.46 4.73L8.82 24z"
      />
    </SVGIcon>
  );
};

export default IconRating;
