/**
 * Вспомогательная функция для проверки значения
 * по маске
 *
 * Пропатченная версия библиотеки - https://github.com/vanilla-masker/vanilla-masker
 *
 * @param {String} value
 * @param {String} pattern
 */
export default function filterByMask(value: string, pattern: string): string {
  const DIGIT = '9',
    ALL = 'A',
    ALPHABET_LATIN = 'S',
    ALPHABET_RU = 'R',
    ALPHABET_BY = 'B',
    ALPHABET_ALL = 'D',
    LETTER_N = 'N';

  const patternChars = pattern.replace(/\W/g, '');
  let output = pattern.split('');
  let values = value.toString().replace(/[^0-9A-Za-zА-Яа-я]/g, '');

  let charsValues = values.replace(/\W/g, '');
  let i,
    index = 0,
    outputLength = output.length;

  for (i = 0; i < outputLength; i++) {
    if (index >= values.length) {
      if (patternChars.length === charsValues.length) {
        return output.join('');
      } else {
        break;
      }
    } else {
      if (
        (output[i] === DIGIT && values[index].match(/[0-9]/)) ||
        (output[i] === ALL && values[index].match(/[0-9A-Za-zА-Яа-я]/)) ||
        (output[i] === ALPHABET_LATIN && values[index].match(/[A-Za-z]/)) ||
        (output[i] === ALPHABET_RU && values[index].match(/[А-Яа-я]/)) ||
        (output[i] === ALPHABET_BY && values[index].match(/[ABCHKMPabchkmp]/)) ||
        (output[i] === ALPHABET_ALL && values[index].match(/[A-Za-zА-Яа-я]/)) ||
        (output[i] === LETTER_N && values[index].match(/[Nn]/))
      ) {
        output[i] = values[index++];
      } else if (
        output[i] === DIGIT ||
        output[i] === ALL ||
        output[i] === ALPHABET_LATIN ||
        output[i] === ALPHABET_RU ||
        output[i] === ALPHABET_BY ||
        output[i] === ALPHABET_ALL ||
        output[i] === LETTER_N
      ) {
        return output.slice(0, i).join('');
      }
    }
  }
  return output.join('').substr(0, i);
}
