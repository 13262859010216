import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import Button from '../../../../components/Button';
import NotificationBar from '../../../../components/NotificationBar';

import { useOrderContext } from '../../index';
import { useUserContext } from '../../../../contexts/userContext';

function OrderTypeNotification() {
  const { ability } = useUserContext();
  const { canCreateInstant, canCreateAuction, canCreateContract, isOrderChange, isOrderBasedOnContract } =
    useOrderContext();

  const showNotification = (!canCreateInstant && !canCreateAuction) || !canCreateContract;

  if (!showNotification || isOrderChange || isOrderBasedOnContract) return null;

  return (
    <NotificationBar
      theme="danger"
      className={styles.notificationBar}
      header={`Доступен только вид заказа с типом «${canCreateContract ? 'Объёмный контракт' : 'Разовая заявка'}»`}
      text={
        ability.can('edit', 'billing')
          ? `Пополните транзакции, чтобы иметь возможность создавать «${
              canCreateContract ? 'Разовые заявки' : 'Объёмные контракты'
            }».`
          : `Для заказа с видом «${
              canCreateContract ? 'Разовые заявки' : 'Объёмные контракты'
            }» недостаточно транзакций. 
Обратитесь к ответственному лицу вашей компании, чтобы пополнить транзакции.`
      }
    >
      {ability.can('edit', 'billing') && (
        <Button theme="primary" size="medium" component={<Link to="/billing" />} className={styles.button}>
          Пополнить
        </Button>
      )}
    </NotificationBar>
  );
}

export default OrderTypeNotification;
