import React from 'react';
import SVGIcon from './index';

function IconArrow(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M0.728617 18.3475L9.57373 0.980258C9.75935 0.615795 10.2802 0.61624 10.4652 0.981019L19.2817 18.3654L10.4977 15.1408C10.164 15.0183 9.79757 15.0183 9.46385 15.1408L0.728617 18.3475Z"
        fill="#0B5EDA"
        stroke="white"
      />
    </SVGIcon>
  );
}

IconArrow.defaultProps = {
  color: 'base',
};

export default IconArrow;
