import { EOrderStatuses } from '../../constants/order';
import { EOrdersTabs } from '../order-list/constants';

export enum EContractsTabs {
  PENDING = 'pending',
  WINNER_SELECTION = 'winner_selection',
  IN_WORK = 'in_work',
  ARCHIVE = 'archive',
  DRAFT = 'draft',
}

const getOrderPath = (key: EContractsTabs): string => `/contracts/${key}`;

type TTabParams = {
  key: EContractsTabs;
  label: string;
  path: string;
};

export const TABS: TTabParams[] = [
  {
    key: EContractsTabs.PENDING,
    label: 'В торге',
    path: getOrderPath(EContractsTabs.PENDING),
  },
  {
    key: EContractsTabs.WINNER_SELECTION,
    label: 'Выбор победителя',
    path: getOrderPath(EContractsTabs.WINNER_SELECTION),
  },
  {
    key: EContractsTabs.IN_WORK,
    label: 'На исполнении',
    path: getOrderPath(EContractsTabs.IN_WORK),
  },
  {
    key: EContractsTabs.ARCHIVE,
    label: 'В архиве',
    path: getOrderPath(EContractsTabs.ARCHIVE),
  },
  {
    key: EContractsTabs.DRAFT,
    label: 'Черновики',
    path: getOrderPath(EContractsTabs.DRAFT),
  },
];

type TTabsParamsMap = {
  [key in EContractsTabs]: TTabParams;
};
export const TABS_MAP = TABS.reduce((acc, tabParams) => {
  return { ...acc, [tabParams.key]: tabParams };
}, {} as TTabsParamsMap);

type TOrderStatusesTree = { [key in EContractsTabs]: EOrderStatuses[] };

export const CONTRACTS_STATUSES_TREE: TOrderStatusesTree = {
  [EContractsTabs.PENDING]: [
    EOrderStatuses.AUCTION_WAIT,
    EOrderStatuses.IN_AUCTION,
    EOrderStatuses.RESERVED,
    EOrderStatuses.APPROVAL,

    EOrderStatuses.EXPIRED,
  ],
  [EContractsTabs.WINNER_SELECTION]: [EOrderStatuses.WINNER_SELECTION],
  [EContractsTabs.IN_WORK]: [EOrderStatuses.TRIP_WAITING, EOrderStatuses.IN_TRIP],
  [EContractsTabs.ARCHIVE]: [
    EOrderStatuses.DONE,
    EOrderStatuses.CANCELED_BY_CARGO_OWNER,
    EOrderStatuses.CANCELED_BY_NTK,
    EOrderStatuses.FAILED_BY_CARGO_OWNER,
    EOrderStatuses.FAILED_BY_NTK,
    EOrderStatuses.EXPIRED,
  ],
  [EContractsTabs.DRAFT]: [EOrderStatuses.DRAFT],
};

type TContractStatusesToTabTree = { [key in EOrderStatuses]?: EContractsTabs[] };
export const CONTRACT_STATUS_TO_TAB_MAP: TContractStatusesToTabTree = {
  // in work
  [EOrderStatuses.TRIP_WAITING]: [EContractsTabs.IN_WORK],
  [EOrderStatuses.IN_TRIP]: [EContractsTabs.IN_WORK],
  // winner selection
  [EOrderStatuses.WINNER_SELECTION]: [EContractsTabs.WINNER_SELECTION],
  // draft
  [EOrderStatuses.DRAFT]: [EContractsTabs.DRAFT],
  // pending
  [EOrderStatuses.AUCTION_WAIT]: [EContractsTabs.PENDING],
  [EOrderStatuses.IN_AUCTION]: [EContractsTabs.PENDING],
  [EOrderStatuses.RESERVED]: [EContractsTabs.PENDING],
  [EOrderStatuses.APPROVAL]: [EContractsTabs.PENDING],
  // archive
  [EOrderStatuses.DONE]: [EContractsTabs.ARCHIVE],
  [EOrderStatuses.CANCELED_BY_CARGO_OWNER]: [EContractsTabs.ARCHIVE],
  [EOrderStatuses.FAILED_BY_CARGO_OWNER]: [EContractsTabs.ARCHIVE],
  [EOrderStatuses.CANCELED_BY_NTK]: [EContractsTabs.ARCHIVE],
  [EOrderStatuses.FAILED_BY_NTK]: [EContractsTabs.ARCHIVE],
  // pending or archive
  [EOrderStatuses.EXPIRED]: [EContractsTabs.PENDING, EContractsTabs.ARCHIVE],
};
