import React from 'react';
import SVGIcon from './index';

function IconAddress(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1C6.5 1 4.75 3.541 4.75 6.25C4.75 10.975 10 15 10 15C10 15 15.25 10.975 15.25 6.25C15.25 3.541 13.5 1 10 1ZM10 8C9.034 8 8.25 7.216 8.25 6.25C8.25 5.284 9.034 4.5 10 4.5C10.966 4.5 11.75 5.284 11.75 6.25C11.75 7.216 10.966 8 10 8ZM6.60246 13.0318C6.89138 13.3834 7.17989 13.7073 7.4556 14H5.46587L4.32951 16.5H15.6705L14.5341 14H12.5444C12.8201 13.7073 13.1086 13.3834 13.3975 13.0318C13.5368 12.8624 13.6775 12.6849 13.8179 12.5H15.5L18 18H2L4.5 12.5H6.18214C6.32247 12.6849 6.46316 12.8624 6.60246 13.0318Z"
      />
    </SVGIcon>
  );
}

IconAddress.defaultProps = {
  color: 'base',
};

export default React.memo(IconAddress);
