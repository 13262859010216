import React from 'react';
import SVGIcon from './index';

export default function IconInfoOutline(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 45 45">
      <path
        fillRule="evenodd"
        d="M21 21h3v9h-3v-9zm0-6h3v3h-3v-3zm1.485-7.5c-8.28 0-14.985 6.72-14.985 15 0 8.28 6.705 15 14.985 15 8.295 0 15.015-6.72 15.015-15 0-8.28-6.72-15-15.015-15zm.015 27c-6.63 0-12-5.37-12-12s5.37-12 12-12 12 5.37 12 12-5.37 12-12 12z"
      />
    </SVGIcon>
  );
}
