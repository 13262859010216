import React, { lazy, Suspense } from 'react';

import { FORM_TYPE, FULL, SIMPLE } from '../OrderNFormTypeBlock';
import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import ContentLoader from '../../../../components/ContentLoader';
import { useOrderContext } from '../../index';
import { getHashString } from '../../helpers/getHashString';

export const CARGO_NAME = 'cargo_name';
export const CARGO_TONNAGE = 'cargo_tonnage';
export const CARGO_VOLUME = 'cargo_volume';
export const HAZARD_CLASS = 'cargo_adr';
export const CARGO_PACKING = 'cargo_packing';
export const CARGO_COST = 'cargo_cost';
export const CARGO_PLACES = 'cargo_places';
export const CARGO_LENGTH = 'cargo_length';
export const CARGO_WIDTH = 'cargo_width';
export const CARGO_HEIGHT = 'cargo_height';

const ShippingRequestSimpleCargoBlock = lazy(() => import('./ShippingRequestSimpleCargoBlock'));
const ShippingRequestFullCargoBlock = lazy(() => import('./ShippingRequestFullCargoBlock'));

function getBlockComponent(hashString) {
  switch (hashString) {
    // упрощенка + НЕ создание заявки по контракту
    case getHashString({ isOrderBasedOnContract: false, formType: SIMPLE }):
      // case getHashString({ isOrderBasedOnContract: true, formType: SIMPLE}):
      return ShippingRequestSimpleCargoBlock;
    // полная
    case getHashString({ isOrderBasedOnContract: false, formType: FULL }):
    case getHashString({ isOrderBasedOnContract: true, formType: FULL }):
      return ShippingRequestFullCargoBlock;
    default:
      return null;
  }
}

/**
 * Блок груза
 * Включает поля Наименование груза, Объем, Тоннаж, Класс опасности, Стоимость
 * @constructor
 */
export function CargoBlock() {
  const { isOrderBasedOnContract } = useOrderContext();
  const [formType] = useFieldValue(FORM_TYPE);
  const hashString = getHashString({ isOrderBasedOnContract, formType });
  const Component = getBlockComponent(hashString);
  return <Suspense fallback={<ContentLoader withBackground />}>{Component ? <Component /> : null}</Suspense>;
}
