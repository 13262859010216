import React from 'react';
import SVGIcon from './index';

function IconFlash(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 11H7L10 5V9H13L10 15V11Z"
      />
    </SVGIcon>
  );
}

IconFlash.defaultProps = {
  color: 'base',
};

export default React.memo(IconFlash);
