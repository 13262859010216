import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { ru } from 'date-fns/locale';

import { DF_DATE_FORMAT, DF_SHORT_DAY_FORMAT } from '../constants';

function getFormattedDateString(date: Date | string | number, formatString: string, timeZone?: string) {
  if (typeof date === 'number' && timeZone) {
    return format(utcToZonedTime(date, timeZone), formatString, { locale: ru, timeZone });
  }
  return format(date, formatString, { locale: ru, timeZone });
}

/**
 * Формитируем строку с бекенда в свой формат
 * @param date - строка с бекенда
 * @param withDay - требуется ли до даты добавить день, н-р Чт Пт Ср
 * @param formatString - формат даты
 */
function getDateString(date: string, withDay: boolean = false, formatString: string = DF_DATE_FORMAT) {
  return getFormattedDateString(new Date(date), withDay ? `${DF_SHORT_DAY_FORMAT} ${formatString}` : formatString);
}

export { getFormattedDateString, getDateString };
