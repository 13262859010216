import React, { lazy, ReactElement, Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UserProvider } from '../contexts/userContext';
import { MatomoProvider } from '../features/matomo-analytics/context';
import { OnboardingProvider } from '../features/onboarding/hoc';

const App = lazy(() => import('./App'));

const queryClient = new QueryClient();

function Root({ store, history }): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <UserProvider>
          <ConnectedRouter history={history}>
            <MatomoProvider>
              <OnboardingProvider>
                <Suspense fallback="">
                  <App />
                </Suspense>
              </OnboardingProvider>
            </MatomoProvider>
          </ConnectedRouter>
        </UserProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default Root;
