import { useHistory, useLocation } from 'react-router-dom';
import React, { createContext, Fragment, useContext } from 'react';

import styles from './styles.module.scss';

import errorsMapping from '../../errors';
import { toast } from '../../components/Toaster';
import withBilling from '../billing/withBilling';
import { getExtraOrders, getFormData } from './helpers/getFormData';
import { useFormStates } from './hooks/useFormStates';
import { useUserContext } from '../../contexts/userContext';
import { useUploadedFiles } from './hooks/useUploadedFiles';
import { getInitialValues } from './helpers/getInitialValues';
import { createStorageRecord } from '../../services/commonService';
import { getValidationSchema } from './helpers/getValidationSchema';
import { useInitialDataFetching } from './hooks/useInitialDataFetching';
import { useDeepMemo, useDeepMemoValue } from '../../hooks/useDeepMemo';
import {
  getAuctionAutoExtensionPeriod,
  getInsuranceOption,
  getInsuranceSum,
  getInsuranceTonnage,
  getCompanyINN,
} from '../../store/company/selectors';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { useOrderCreatingPermissions } from './hooks/useOrderCreatingPermissions';
import { _getCreatedToaster, attachFilesToResource } from '../../store/order/actions';
import { changeOrder, publishDraft, saveOrder, updateDraft } from '../../services/orders/ordersService';

import { AsideBlock } from './blocks/AsideBlock';
import { CargoBlock } from './blocks/CargoBlock';
import { RoutesBlock } from './blocks/RoutesBlock';
import { AuctionBlock } from './blocks/AuctionBlock';
import { ActionsBlock } from './blocks/ActionsBlock';
import { OrderFormDevTools } from './blocks/_DevTools';
import { TransportBlock } from './blocks/TransportBlock';
import { DocumentsBlock } from './blocks/DocumentsBlock';
import { RequiredInsuranceBlock } from './blocks/RequiredInsuranceBlock';
import { InsuranceBannerBlock } from './blocks/InsuranceBannerBlock';

import ContentLoader from '../../components/ContentLoader';
import { PageHeaderBlock } from './blocks/PageHeaderBlock';
import { ExtraOrdersBlock } from './blocks/ExtraOrdersBlock';
import { Form, Formik } from '../../components/FormikFieldsV3/Formik';
import ForbiddenOrderCreating from './components/ForbiddenOrderCreating';
import { FORM_TYPE, ORDER_TYPE, OrderNFormTypeBlock, SHIPPING_REQUEST } from './blocks/OrderNFormTypeBlock';
import { changeRequestConfirmEvent } from '../../store/googleAnalytics/events';
import { useRequestExpeditedPayment } from '../../features/expedited-payment/hooks/useRequestExpeditedPayment';
import { ExpeditedPaymentContextProvider } from '../../features/expedited-payment/contexts/expeditedPaymentContext';
import { declinationOfCreateF, declinationOfOrders } from '../../helpers/declinationOfNumbers';
import { promiseRateLimit } from '../../helpers/promiseRateLimit';
import { getUrlString } from '../../helpers/getUrlString';
import { cloneDeep } from 'lodash';
import { useQuery } from 'react-query';
import { searchCompanyByIds } from '../../services/companyService';
import { usePartnerships } from '../../hooks/useCurrentCompanyPartnerships';
import { WITH_VAT } from '../../store/company/formKeys';
import { getWithoutVat } from '../../helpers/getPrice';
import { EOrdersTabs, ORDER_STATUS_TO_TAB_MAP } from '../order-list/constants';
import { ACTIVE_ITEM_QUERY_KEY } from '../../services/_constants';

// Получение ссылки на заказ
export function getOrderLink(currentOrder) {
  return getUrlString(`order-list/${ORDER_STATUS_TO_TAB_MAP[currentOrder.orderStatus]?.[0] || EOrdersTabs.PENDING}`, {
    [ACTIVE_ITEM_QUERY_KEY]: currentOrder.id,
  });
}

export const OrderContext = createContext<any>(null);
export function useOrderContext() {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error('Cannot use `useOrderContext` outside of a OrderContext.Provider');
  }
  return context;
}

function OrderScreen() {
  const auctionAutoExtensionPeriod = useShallowEqualSelector(getAuctionAutoExtensionPeriod);
  const companyINN = useShallowEqualSelector(getCompanyINN);
  const history = useHistory();
  const location = useLocation();
  const queryString = location.search;
  const { userInfo } = useUserContext();
  const { canCreateOrder, hasSubscription, ...creatingPermissions } = useOrderCreatingPermissions();

  const states = useFormStates();
  const { isLoading, ...catalogData } = useInitialDataFetching(states.isOrderBasedOnContract, states.isDraftEditing);
  const files = useUploadedFiles();
  const [fileList] = files;

  const executorIds = catalogData.currentOrder?.executors?.map((item) => item.company_id);
  // Получаем компании по id исполнителей
  const { data: executorsCompanies } = useQuery(['order-form', executorIds], () => searchCompanyByIds(executorIds), {
    enabled: !!executorIds?.length,
  });

  const contractExecutorIds = catalogData.contractExecutorList?.map((item) => (item as any).company_id);
  // Получаем список партнёрств с компаниями-исполнителями по контракту
  // Используем, чтобы понимать, является ли исполнитель контракта партнёром
  const { data: contractExecutorPartnerList } = usePartnerships(contractExecutorIds, []);

  const expeditedPaymentDays =
    catalogData.currentOrder?.winning_bid?.expedited_payment_days ||
    catalogData.currentOrder?.shipping?.expedited_payment_days;
  const isExpeditedPayment = expeditedPaymentDays > 1;
  const getPrice = (price, taxType) => {
    if (!price) return;
    if (taxType === WITH_VAT) return getWithoutVat(price);
    return price;
  };
  const currentOrder = catalogData.currentOrder;
  const initialBid =
    getPrice(currentOrder?.shipping_price, currentOrder?.shipping_price_tax_type) ??
    getPrice(currentOrder?.winning_bid?.value, currentOrder?.winning_bid?.tax_type);
  const expeditedPaymentData = useRequestExpeditedPayment({
    id: catalogData.currentOrder?.shipping?.shipping_request_id,
    isExpeditedPaymentAvailable: isExpeditedPayment,
    initialExpeditedPayment: isExpeditedPayment,
    initialExpeditedPaymentDays: expeditedPaymentDays,
    initialBid: initialBid,
    initialExpeditedPaymentUserBid:
      catalogData.currentOrder?.winning_bid?.value -
      (catalogData.currentOrder?.winning_bid?.expedited_payment_fee ?? 0),
    shouldExpeditedPaymentCalculate: states.isOrderChange && !!expeditedPaymentDays,
    shouldTurnOffExpeditedPaymentOnDisable: false,
  });

  const insuranceContextValues = {
    inheritInsuranceSettings: states.isOrderChange || states.isOrderBasedOnContract,
    insuranceOption: useShallowEqualSelector(getInsuranceOption),
    insuranceSum: useShallowEqualSelector(getInsuranceSum),
    insuranceTonnage: useShallowEqualSelector(getInsuranceTonnage),
  };

  const contextValue = useDeepMemoValue({
    ...catalogData,
    ...creatingPermissions,
    ...states,
    ...insuranceContextValues,
    executorsCompanies,
    contractExecutorPartnerList,
  });
  const initialValues = useDeepMemo(
    () => getInitialValues(contextValue, { auctionAutoExtensionPeriod, companyINN }),
    [contextValue, auctionAutoExtensionPeriod],
  );
  const orderContextValue = useDeepMemoValue({ files, initialValues: cloneDeep(initialValues), ...contextValue });
  const validationSchema = useDeepMemoValue(
    getValidationSchema(contextValue, {
      isOrderBasedOnContract: states.isOrderBasedOnContract,
      currentOrder: catalogData.currentOrder,
      isOrderChange: states.isOrderChange,
      isExpeditedPayment: expeditedPaymentData.isExpeditedPayment,
      expeditedPaymentUserBid: expeditedPaymentData.expeditedPaymentUserBid,
    }),
  );

  if (isLoading) {
    return <ContentLoader withBackground />;
  }

  if (!canCreateOrder && !states.isOrderBasedOnContract && !states.isOrderChange) {
    return <ForbiddenOrderCreating hasSubscription={hasSubscription} />;
  }

  async function handleSubmit(values, { setSubmitting }) {
    let isSuccess = true;
    const orderList = [values, ...getExtraOrders(values)];

    const promises = orderList.map((v) => () => makeOrderRequest(v, orderList.length > 1));
    const results = await promiseRateLimit(promises, 2);

    if (orderList.length > 1) {
      const lastIndex = (values?.route_points?.length ?? 1) - 1;
      const cityFrom = values?.route_points?.[0]?.route_address_city.label;
      const cityTo = values?.route_points?.[lastIndex]?.route_address_city.label;
      const successOrders = results.reduce((acc, item) => {
        if (item.status === 'fulfilled') acc += 1;
        return acc;
      }, 0);
      toast.success(
        `${successOrders} ${declinationOfOrders(successOrders)} ${cityFrom} – ${cityTo} ${declinationOfCreateF(
          successOrders,
        )}`,
      );
    }

    async function makeOrderRequest(orderValues, isMultipleOrders) {
      try {
        let response: any;

        const payload = getFormData(orderValues, {
          userInfo,
          serviceList: catalogData.serviceList,
          currentOrder: catalogData.currentOrder,
          isOrderBasedOnContract: states.isOrderBasedOnContract,
          isDraftEditing: states.isDraftEditing,
          isOrderChange: states.isOrderChange,
          isPublishing: true,
          globalInsuranceSettings: insuranceContextValues,
        });
        if (states.isDraftEditing && catalogData.currentOrder?.id) {
          await updateDraft(catalogData.currentOrder?.id, payload);
          response = await publishDraft(catalogData.currentOrder?.id);
        } else if (states.isOrderChange) {
          response = await changeOrder(catalogData.currentOrder.shipping_id, payload);
          changeRequestConfirmEvent(catalogData.currentOrder?.id);
        } else {
          response = await saveOrder(payload);
        }

        if (!states.isOrderChange && response?.id) {
          await createStorageRecord(`order:${response.id}`, { [FORM_TYPE]: orderValues[FORM_TYPE] });
        }

        /* Прикрепление документов */
        if (!states.isOrderChange && fileList?.length > 0 && response?.id) {
          await attachFilesToResource(fileList, response.id);
        }

        if (states.isOrderChange) {
          toast.info(`Изменения в заявку № ${catalogData.currentOrder.human_friendly_id}\x0d\x0aвнесены`);
        } else {
          if (!isMultipleOrders)
            if (states.isOrderFromAuction) toast.success('Заявка изменена');
            else await _getCreatedToaster(response);
        }
      } catch (error) {
        if (isMultipleOrders) {
          return Promise.reject(error);
        } else {
          isSuccess = false;
          //@ts-ignore
          const errorObj = errorsMapping.get(error?.response?.data?.error_description);
          if (errorObj) {
            toast.error(errorObj.title, errorObj.message);
          } else {
            await toast.errorAsync(error);
          }
        }
      }
    }

    setSubmitting(false);

    if (isSuccess) {
      if (states.isOrderChange || states.isOrderFromAuction) {
        history.push(`/order-list${queryString}`);
      } else {
        history.push(values[ORDER_TYPE] === SHIPPING_REQUEST ? '/order-list' : '/contracts');
      }
    }
  }

  return (
    <OrderContext.Provider value={orderContextValue}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        // validateOnChange={false} // Render optimization
      >
        <Fragment>
          <PageHeaderBlock />
          <ExpeditedPaymentContextProvider value={expeditedPaymentData}>
            <section className={styles.wrapper}>
              <Form className={styles.section}>
                <OrderNFormTypeBlock />
                <TransportBlock />
                <RequiredInsuranceBlock />
                <RoutesBlock />
                <AuctionBlock />
                <CargoBlock />
                <DocumentsBlock />
                <ExtraOrdersBlock />
                <InsuranceBannerBlock />
                <ActionsBlock />
              </Form>
              <aside className={styles.aside}>
                <AsideBlock />
              </aside>
            </section>
          </ExpeditedPaymentContextProvider>
          <OrderFormDevTools />
        </Fragment>
      </Formik>
    </OrderContext.Provider>
  );
}

export default withBilling(OrderScreen, { needCatalog: false });
