import React from 'react';
import SVGIcon from './index';

const IconGavel = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7782 2.00002L8.65686 4.12134L12.1924 7.65687L14.3137 5.53555L10.7782 2.00002ZM7.94975 4.82838L5.82843 6.94971L14.8794 16.0007L17.0007 13.8793L7.94975 4.82838ZM3 9.77807L5.12132 7.65675L8.65685 11.1923L6.53553 13.3136L3 9.77807ZM9 16H2V18H9V16Z"
      />
    </SVGIcon>
  );
};

IconGavel.defaultProps = {
  color: 'base',
};

export default React.memo(IconGavel);
