import moment from 'moment';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _cloneDeep from 'lodash/cloneDeep';
import _transform from 'lodash/transform';
import { getFormValues } from 'redux-form';
import { createSelector, defaultMemoize } from 'reselect';

import * as pageSelectors from '../page/selectors';
import { DATE_FORMAT, DEFAULT_APP_TIME_ZONE, SHORT_DATE_FORMAT, TIME_FORMAT, DATE_TIME_FORMAT } from '../../constants';
import * as constants from './constants';
import * as queryKeys from './queryKeys';
import { LORRY } from '../resources/formKeys';
import { HAZARD_CLASSES, PACKAGE_ITEMS } from '../order/cargo/formFields';
import {
  CAR_FIELD,
  DATE_FIELD,
  DRIVER_FIELD,
  PHONE_FIELD,
  RESOURCES_FROM,
  TIME_FIELD,
  TRAILER_FIELD,
} from '../suggestions/reserved/formFields';
import { getMappedCar, getMappedDriver, getMappedTrailer } from '../resources/selectors';
import { getMappedShippingItem } from '../suggestions/selectors';
import { createDateTime } from '../../helpers/dateTimeTools';
import { getCargoDimensions } from '../../helpers/mappers/shipping';
import getFormattedPrice from '../../helpers/getFormattedPrice';
import { getMappedRequirements, getModifyRoutePoints, getRoutePoints } from '../order/selectors';
import { APPEND, CHANGE, REMOVED } from './constants';
import { TYPES } from '../../components/DataListCollections/_helpers';
import { transportRequirements } from '../resources/constants';
import { WITH_VAT, WITHOUT_VAT } from '../company/formKeys';
import getFormattedPhone from '../../helpers/getFormattedPhone';
import { getPayer, getContacts } from '../../components/DataDetailCollections/_helpers';
import { getTaxTypeLabel } from '../../hooks/usePriceTaxType';

/**
 * Сравнение двух объектов при помощи lodash
 * @param  {Object} result   Объект для сравнения
 * @param  {Object} original Объект, с которым сравниваем
 * @return {Object}           Возвращает новый объект/массив с изменениями
 */
export function getDifferenceObj(result, original) {
  return _transform(result, (resulted, value, key) => {
    if (!_isEqual(value, original[key])) {
      resulted[key] = _isObject(value) && _isObject(original[key]) ? getDifferenceObj(value, original[key]) : value;
    }
  });
}

/**
 * ПОлучаем разницу входных и высчитанных данных
 * @param {Object} initialData    Объект, который сравниваем
 * @param {Object} calculatedData Объект, с которым сравниваем
 * @param {Boolean} isReversed    Поле - развернуты ли входные данные
 * @returns {Object}              Возвращает новый объект с изменениями
 */
export function getVersionObjDiff(initialData, calculatedData, isReversed = false) {
  // TODO: нужен путь до конечного значения (a.b.c)
  const paths = Object.keys(
    Object.entries(calculatedData).reduce((acc, key) => {
      acc.push(key);
      return acc;
    }, []),
  );
  const initialValue = {};

  return paths.reduce((result, curr) => {
    const oldValue = _get(initialData, curr);
    const newValue = _get(calculatedData, curr);

    _set(result, curr, {
      oldValue: isReversed ? newValue : oldValue,
      newValue: isReversed ? oldValue : newValue,
    });

    return result;
  }, initialValue);
}

/**
 * Преобразование объекта в массив
 * @param object {Object}
 * @returns {Array}
 * @private
 */
function _getArray(object) {
  return Object.values(object).reduce((acc, val) => acc.concat(val), []);
}

/**
 * Восстанавливаем длину конечного массива calc с учетом пустых элементов в diff
 * @param diff {Array}  Начальный массив с изменениями маршрута
 * @param calc {Array}  Вычисленный массив значений
 * @returns {Array}     Массив, заполненный undefined вместо пустых эл-тов
 * @private
 */
function _getFilledDiffArray(diff, calc) {
  [...diff.values()].forEach((value, index) => {
    if (!value) {
      calc.splice(index, 0, undefined);
    }
  });

  return calc;
}

/**
 * Сравниваем `routePoints` (с удаленной точкой и без)
 * @param {Array} original Исходный routePoints
 * @param {Array} result   Результат routePoints
 * @returns {Array}
 */
export function getRoutePointsDiff(original, result) {
  if (original.length > result.length) {
    const differenceArray = getDifferenceObj(original, result);

    const calculatedArray = _getArray(getVersionObjDiff(result, differenceArray, true));

    return _getFilledDiffArray(differenceArray, calculatedArray);
  }

  const differenceArray = getDifferenceObj(result, original);

  const calculatedArray = _getArray(getVersionObjDiff(original, differenceArray));

  return _getFilledDiffArray(differenceArray, calculatedArray);
}

/**
 * Получение данных об Изменении требований
 */
export function getRequirementsChangeItem(item) {
  const result = _get(item, 'result_request_info');
  const original = _get(item, 'original_request_info');

  const mappedResultShippingItem = getMappedShippingItem(result);
  const mappedOriginalShippingItem = getMappedShippingItem(original);

  mappedResultShippingItem.routePoints.forEach((point) => {
    delete point.supplyRanges;
  });
  mappedOriginalShippingItem.routePoints.forEach((point) => {
    delete point.supplyRanges;
  });

  const routePointsResult = mappedResultShippingItem.routePoints;
  const routePointsOriginal = mappedOriginalShippingItem.routePoints;

  const mappedDiffShippingItem = getDifferenceObj(mappedResultShippingItem, mappedOriginalShippingItem);

  const mappedDiffVersionShippingItem = getVersionObjDiff(mappedOriginalShippingItem, mappedDiffShippingItem);

  return {
    result: mappedResultShippingItem,
    original: mappedOriginalShippingItem,
    diffVersion: mappedDiffVersionShippingItem,
    routePointsDiff: getRoutePointsDiff(routePointsOriginal, routePointsResult),
    id: _get(item, 'id'),
    listId: _get(item, 'id'),
    shippingId: _get(item, 'shipping_id'),
    shippingHFID: _get(item, 'shipping_hfid'),
    /* Заменить на объект, когда доделает бэк */
    status: _get(item, 'status'),
    statusComment: _get(item, 'status_comment'),
    /*-------------------------------------------*/
    reviewDeadline: _get(item, 'review_deadline'),
    isRequirementsChangesExpired: moment.utc().isAfter(moment.utc(_get(item, 'review_deadline'))),
    createdAtShot: moment(_get(item, 'created_at')).format(SHORT_DATE_FORMAT),
    createdAt: moment(_get(item, 'created_at')).format(DATE_FORMAT),
  };
}

/**
 * Получение query параметров для запроса
 */
export const getRequirementsChangeParams = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) => {
    // при статусе 'неактуально' делаем запрос с дополнительным фильтром REVOKED
    if (query && query[queryKeys.STATUS] && query[queryKeys.STATUS].includes(constants.INACTIVE)) {
      return {
        ...query,
        [queryKeys.STATUS]: [...query[queryKeys.STATUS], constants.REVOKED],
      };
    }

    return { ...query };
  },
);

/**
 * Перевод статуса изменений
 * @param status
 * @returns {*}
 */
export function getStatusTranslation(status) {
  switch (status) {
    case constants.PENDING_REVIEW:
      return 'На согласовании';
    case constants.REJECTED:
      return 'Отклонено';
    case constants.REVOKED:
      return 'Неактуально';
    case constants.ACCEPTED:
      return 'Согласовано';
    case constants.INACTIVE:
      return 'Неактуально';
    default:
      return status;
  }
}

// ============ Смена ресурсов в перевозке ============ //

/**
 * Получение таймзоны из первой точки маршрута
 */
export const getTimeZoneFromFirstPoint = defaultMemoize(
  (state) =>
    _get(state, 'suggestionsReserved.activeItem.shipping_request_info.route_points[0].location.local_time_zone') ||
    DEFAULT_APP_TIME_ZONE,
);

export const isFirstPointWarehouse = defaultMemoize(
  (state) => !!_get(state, 'suggestionsReserved.activeItem.shipping_request_info.route_points[0].warehouse_id'),
);

/**
 * Получение времени подачи машину в первую точку
 */
export const getCarSupplyAt = defaultMemoize((state) =>
  _get(state, 'suggestionsReserved.activeItem.selected_car_supply[0].car_supply_at'),
);

/**
 * Получение назначенных ресурсов
 */
export const getRawShipping = defaultMemoize((state) => _get(state, 'suggestionsReserved.activeItem'));

/**
 * Маппинг полей формы для бэка
 */
export const getMappedRequirementsChangeForm = createSelector(
  (state) => getFormValues(RESOURCES_FROM)(state),
  (state) => _get(state, 'suggestionsReserved.activeItem.assigned_resources[0]'),
  getCarSupplyAt,
  getTimeZoneFromFirstPoint,
  isFirstPointWarehouse,
  (values, previousValues, previousCarSupplyAt, previousTimeZoneAtFirstPoint, isFirstPointWarehouse) => {
    const carId = _get(values[CAR_FIELD], 'value.id');
    const hasTrailer = _get(values[CAR_FIELD], 'value.transportType') !== LORRY;
    const trailerId = _get(values[TRAILER_FIELD], 'value.id');
    const driverIds = (values[DRIVER_FIELD] || []).reduce((acc, item) => {
      const driverId = _get(item, 'value.id');
      if (driverId) acc.push(driverId);
      return acc;
    }, []);
    const phone = values[PHONE_FIELD];
    const resourcesObj = {
      ...previousValues,
      car_id: carId || previousValues['car_id'],
      drivers_ids: driverIds || previousValues['drivers_ids'],
      trailer_id: trailerId || previousValues['trailer_id'],
      driver_contact_info: phone || previousValues['driver_contact_info'],
    };
    if (!hasTrailer) {
      delete resourcesObj['trailer_id'];
    }

    if (isFirstPointWarehouse) {
      const slot = _get(values, `${TIME_FIELD}`);
      return {
        assigned_resources: [{ ...resourcesObj }],
        gate_id: slot.gateId,
        from: slot.from,
        till: slot.till,
      };
    }

    const date = _get(values, DATE_FIELD);
    const time = _get(values, `${TIME_FIELD}.label`);
    const carSupplyAt = createDateTime(date, time, previousTimeZoneAtFirstPoint, DATE_FORMAT, TIME_FORMAT).format();
    return {
      assigned_resources: [{ ...resourcesObj }],
      car_supply_at: date && time ? carSupplyAt : previousCarSupplyAt,
    };
  },
);

/**
 * Получаем значения требований для ТС и водителя
 */
export function getRequiredResources(restrictions) {
  const arrDriver = [
    'russianCitizen',
    'driverCoveralls',
    'noCriminalRecords',
    'medicalBook',
    'shippingPowerOfAttorneyOriginal',
  ];

  const arrVehicle = [
    'cmrDocument,',
    'isDisinfected',
    'removableUpperBeam',
    'removableSideRacks',
    'rigidBoard',
    'numberOfBelts',
    'isDisinfected',
    'temperatureCheck',
    'temperatureCondition',
    'isOwnTransport',
    'timberBunks',
    'tirDocument',
  ];

  const driver = _pick(restrictions, arrDriver);
  const vehicle = _pick(restrictions, arrVehicle);

  return {
    driver,
    vehicle,
  };
}

/**
 * Маппинг объекта request_info
 */
export const getMappedRequestInfo = defaultMemoize((item, context) => {
  const { isExpeditedPayment } = context;
  const assignedResources = item?.assigned_resource_objects;
  const orderRequirements = item?.resource_requirements ?? {};
  const routePoints = item?.route_points ?? [];
  const phone = item?.assigned_resources?.[0]?.driver_contact_info.replace(/[^+0-9]/gi, '');
  const expeditedPaymentFee = item?.expedited_payment_fee ?? 0;

  let priceWithVat = item?.price_with_vat;
  let priceWithoutVat = item?.price_without_vat;

  if (isExpeditedPayment) {
    priceWithVat -= expeditedPaymentFee;
    priceWithoutVat -= expeditedPaymentFee;
  }

  const carSupplyAt = item?.car_supply_at;
  const formattedCarSupplyAt = carSupplyAt ? moment.parseZone(carSupplyAt).format(DATE_TIME_FORMAT) : null;

  return {
    carSupplyAt,
    car: getMappedCar(assignedResources?.cars?.[0]),
    trailer: getMappedTrailer(assignedResources?.trailers?.[0]),
    drivers: _get(assignedResources, 'drivers', []).map(getMappedDriver),
    phone,
    payer: item?.payer,

    cargoAdr: item?.cargo_adr,
    cargoDescription: item?.cargo_name,
    cargoTonnage: item?.cargo_tonnage,
    cargoVolume: item?.cargo_volume,
    cargoCost: getFormattedPrice(item?.cargo_cost),
    cargoPacking: PACKAGE_ITEMS.get(item?.cargo_packing) || '',
    cargoPlaces: item?.cargo_places,

    dimensions: getCargoDimensions(item),
    restrictions: getRequiredResources(getMappedRequirements(orderRequirements)),

    minWorkHours: item?.min_work_hours,
    priceType: item?.price_type,
    priceTaxType: item?.price_tax_type,
    priceWithVat: getFormattedPrice(priceWithVat),
    priceWithoutVat: getFormattedPrice(priceWithoutVat),
    totalPriceWithVat: item?.min_work_hours ? getFormattedPrice(priceWithVat * item?.min_work_hours) : null,
    totalPriceWithoutVat: item?.min_work_hours ? getFormattedPrice(priceWithoutVat * item?.min_work_hours) : null,

    tonnage: item?.transport_tonnage,
    volume: item?.transport_volume,
    bodyTypes: item?.transport_body_types,

    comment: item?.comment,

    routePoints: getRoutePoints(getModifyRoutePoints(routePoints), [])
      .map((point) => {
        const warehouseInfo = item?.warehouse_info?.find((w) => w.route_point_id === point.number);
        if (warehouseInfo) {
          return { ...point, warehouseInfo };
        }
        return point;
      })
      .map((point, pointIndex) => ({ ...point, carSupplyAt: pointIndex === 0 ? formattedCarSupplyAt : null })),
    warehouseInfo: item?.warehouse_info,
  };
});

/**
 * Получаем разницу входных и высчитанных данных
 * @param {Object} original    Объект, который сравниваем
 * @param {Object} result Объект, с которым сравниваем
 * @returns {Object} Возвращает новый объект с изменениями [from -> to]
 */
export function getObjectsDiff(original = {}, result = {}) {
  function isNotNullOrUndefined(value) {
    return typeof value !== 'undefined' && value !== null;
  }
  function isNotEmpty(value) {
    if (_isObject(value) || Array.isArray(value)) return !_isEmpty(value);
    if (typeof value === 'number') return true; // хак для 0
    return !!value;
  }
  const diffKeys = Object.keys({ ...result, ...original }).filter(
    (i) =>
      // Проверяем на нулы и индефайнеды
      (isNotNullOrUndefined(original?.[i]) || isNotNullOrUndefined(result?.[i])) &&
      // Дополнительно откидываем пустые объекты
      (isNotEmpty(original?.[i]) || isNotEmpty(result?.[i])) &&
      !_isEqual(result?.[i], original?.[i]),
  );

  return diffKeys.reduce((acc, key) => {
    acc[key] = {
      from: original?.[key],
      to: result?.[key],
    };
    return acc;
  }, {});
}

/**
 * Сравниваем `routePoints` (с удаленной точкой и без)
 * @param {Array} original Исходный routePoints
 * @param {Array} result   Результат routePoints
 * @returns {Array}
 */
export function _getRoutePointsDiff(original, result) {
  function cloneAndCleanRoutePoint(routePoint = []) {
    // Клонируем чтобы не мутировать исходные объекты
    // Удаляем number и id т.к. точка могла сдвинуться, но не измениться
    return _cloneDeep(routePoint).map((p) => {
      delete p.number;
      delete p.id;
      delete p._original;
      return p;
    });
  }
  const clonedOriginal = cloneAndCleanRoutePoint(original);
  const clonedResult = cloneAndCleanRoutePoint(result);

  // Отдельно сравниваем первую и последнюю точки
  const originalFirstPoint = clonedOriginal.shift();
  const originalLastPoint = clonedOriginal.pop();

  const resultFirstPoint = clonedResult.shift();
  const resultLastPoint = clonedResult.pop();

  const firstPointDiffObj = getObjectsDiff(originalFirstPoint, resultFirstPoint);
  const lastPointDiffObj = getObjectsDiff(originalLastPoint, resultLastPoint);

  // Находим средние точки и маппися по ним, находя разницу
  let middlePoints = clonedOriginal;
  if (clonedResult.length > clonedOriginal.length) {
    middlePoints = clonedResult;
  }
  const middlePointsDiff = middlePoints.reduce((acc, item, index) => {
    const pointDiff = getObjectsDiff(clonedOriginal?.[index] ?? {}, clonedResult?.[index] ?? {});
    acc.push(pointDiff);
    return acc;
  }, []);

  // Получаем итоговую разницу по точкам + заменяем пустые объекты на null
  // для более удобной проверки на существование
  const resultDiff = [firstPointDiffObj, ...middlePointsDiff, lastPointDiffObj].map((p) => (_isEmpty(p) ? null : p));

  // Если есть изменение хоть в одной точке
  if (resultDiff.some(Boolean)) return resultDiff;
  return null;
}

const DASH = '\u2014';
/**
 * Возращает список требований к ресурсу (через запятую)
 * @type {function(*, *): string | string}
 * @return string
 */
const getRestrictions = defaultMemoize(
  (request, type) =>
    Object.entries(request.restrictions?.[type] ?? {})
      .filter((item) => !!item[1])
      .map((item) => {
        if (item[0] === 'temperatureCondition') {
          return `${transportRequirements.get(item[0])} от ${item[1].min}\u00B0C до ${item[1].max}\u00B0C`;
        }
        if (item[0] === 'numberOfBelts') {
          return `${transportRequirements.get(item[0])} ${item[1]} шт.`;
        }
        return transportRequirements.get(item[0]);
      })
      .join?.(', ') || DASH,
);

/**
 * Маппинг объекта заявки на замену ресурсов
 */
export const getMappedRequestRequirementsChange = defaultMemoize((item, isHourlyRent, isExpeditedPayment) => {
  const requestId = item?.id;
  const shippingId = item?.shipping_id;
  const sender = item?.sender_id;
  const status = item?.status;
  const comment = item?.status_comment;
  const createdAt = moment(item.created_at).utcOffset(DEFAULT_APP_TIME_ZONE);
  const updatedAt = moment(item.updated_at).utcOffset(DEFAULT_APP_TIME_ZONE);

  const originalRequest = getMappedRequestInfo(item?.original_request_info, {
    isExpeditedPayment,
  });
  const resultRequest = getMappedRequestInfo(item?.result_request_info, { isExpeditedPayment });

  const routePointsOriginal = originalRequest?.routePoints;
  const routePointsResult = resultRequest?.routePoints;

  delete originalRequest.routePoints;
  delete resultRequest.routePoints;

  const diff = getObjectsDiff(originalRequest, resultRequest);
  const routePointsDiff = _getRoutePointsDiff(routePointsOriginal, routePointsResult);

  // Собираем новый объект с изменениями для более "чистого" рендера
  // Избегаем всех вычислений в самом компоненте
  const calculatedDiff = {};
  // Тоннаж и объём
  if (!!diff.tonnage || !!diff.volume) {
    calculatedDiff['tonnageVolume'] = {
      from: `${originalRequest.tonnage} т / ${originalRequest.volume} м\u00B3`,
      to: `${resultRequest.tonnage} т / ${resultRequest.volume} м\u00B3`,
    };
  }

  // Тип кузова
  if (!!diff.bodyTypes) {
    calculatedDiff['bodyTypes'] = {
      from: originalRequest.bodyTypes.map((type) => TYPES.get(type)).join(', '),
      to: resultRequest.bodyTypes.map((type) => TYPES.get(type)).join(', '),
    };
  }

  // Требования к ТС
  if (!_isEqual(originalRequest?.restrictions?.vehicle, resultRequest?.restrictions?.vehicle)) {
    calculatedDiff['vehicleRestrictions'] = {
      from: getRestrictions(originalRequest, 'vehicle'),
      to: getRestrictions(resultRequest, 'vehicle'),
    };
  }

  // Требования к водителю
  if (!_isEqual(originalRequest?.restrictions?.driver, resultRequest?.restrictions?.driver)) {
    calculatedDiff['driverRestrictions'] = {
      from: getRestrictions(originalRequest, 'driver'),
      to: getRestrictions(resultRequest, 'driver'),
    };
  }

  // Стоимость перевозки
  if (!!diff.priceWithVat || !!diff.priceWithoutVat || !!diff.minWorkHours) {
    const fromTaxType = originalRequest.priceTaxType ?? WITHOUT_VAT;
    const fromPrice = fromTaxType === WITH_VAT ? originalRequest.priceWithVat : originalRequest.priceWithoutVat;
    const fromTotal =
      fromTaxType === WITH_VAT ? originalRequest.totalPriceWithVat : originalRequest.totalPriceWithoutVat;

    const toTaxType = resultRequest.priceTaxType ?? WITHOUT_VAT;
    const toPrice = toTaxType === WITH_VAT ? resultRequest.priceWithVat : resultRequest.priceWithoutVat;
    const toTotal = toTaxType === WITH_VAT ? resultRequest.totalPriceWithVat : resultRequest.totalPriceWithoutVat;

    calculatedDiff['price'] = {
      from: isHourlyRent
        ? `${fromPrice} ₽/ч ${getTaxTypeLabel(fromTaxType)} (итого ${fromTotal} ₽)`
        : `${fromPrice} ₽ ${getTaxTypeLabel(fromTaxType)}`,
      to: isHourlyRent
        ? `${toPrice} ₽/ч ${getTaxTypeLabel(toTaxType)} (итого ${toTotal} ₽)`
        : `${toPrice} ₽ ${getTaxTypeLabel(toTaxType)}`,
    };
  }

  // Наименование груза
  if (!!diff.cargoDescription) {
    calculatedDiff['cargoDescription'] = {
      from: originalRequest.cargoDescription,
      to: resultRequest.cargoDescription,
    };
  }

  // Класс опасности
  if (!!diff.cargoAdr) {
    calculatedDiff['cargoAdr'] = {
      from: HAZARD_CLASSES.get(originalRequest.cargoAdr) || 'Не опасный',
      to: HAZARD_CLASSES.get(resultRequest.cargoAdr) || 'Не опасный',
    };
  }

  // Объявленная стоимость (ценность) груза
  if (diff.cargoCost) {
    calculatedDiff['cargoCost'] = {
      from: originalRequest.cargoCost ? originalRequest.cargoCost + ' ₽' : DASH,
      to: resultRequest.cargoCost ? resultRequest.cargoCost + ' ₽' : DASH,
    };
  }

  // Плательщик
  if (diff.payer) {
    calculatedDiff['payer'] = {
      from: originalRequest.payer ? getPayer(originalRequest.payer) : DASH,
      to: resultRequest.payer ? getPayer(resultRequest.payer) : DASH,
    };
  }
  if (!_isEqual(diff.payer?.from?.contacts, diff.payer?.to?.contacts)) {
    calculatedDiff['payerContacts'] = {
      from: originalRequest.payer?.contacts?.length > 0 ? getContacts(originalRequest.payer?.contacts) : DASH,
      to: resultRequest.payer?.contacts?.length > 0 ? getContacts(resultRequest.payer?.contacts) : DASH,
    };
  }

  // Вес и объём груза
  if (!!diff.cargoTonnage || !!diff.cargoVolume) {
    calculatedDiff['cargoTonnageVolume'] = {
      from: `${originalRequest.cargoTonnage} т ${
        originalRequest.cargoVolume ? `/ ${originalRequest.cargoVolume} м\u00B3` : ''
      }`,
      to: `${resultRequest.cargoTonnage} т ${
        resultRequest.cargoVolume ? `/ ${resultRequest.cargoVolume} м\u00B3` : ''
      }`,
    };
  }

  // Габариты
  if (!!diff.dimensions) {
    calculatedDiff['dimensions'] = {
      from: originalRequest.dimensions || DASH,
      to: resultRequest.dimensions || DASH,
    };
  }

  // Упаковка груза
  if (!!diff.cargoPacking) {
    calculatedDiff['cargoPacking'] = {
      from: originalRequest.cargoPacking?.label || DASH,
      to: resultRequest.cargoPacking?.label || DASH,
    };
  }

  // Количество грузомест
  if (!!diff.cargoPlaces) {
    calculatedDiff['cargoPlaces'] = {
      from: originalRequest.cargoPlaces || DASH,
      to: resultRequest.cargoPlaces || DASH,
    };
  }

  // Комментарий
  if (!!diff.comment) {
    calculatedDiff['comment'] = {
      from: originalRequest.comment || DASH,
      to: resultRequest.comment || DASH,
    };
  }

  // Минимальное количество часов
  if (!!diff.minWorkHours || !!diff.priceWithVat || !!diff.priceWithoutVat) {
    calculatedDiff['minWorkHours'] = {
      from: originalRequest.minWorkHours || DASH,
      to: resultRequest.minWorkHours || DASH,
    };
  }

  // Водитель
  if (diff.drivers) {
    calculatedDiff['drivers'] = {
      from: originalRequest.drivers || DASH,
      to: resultRequest.drivers || DASH,
    };
  }

  // Водитель телефон
  if (diff.phone) {
    calculatedDiff['phone'] = {
      from: originalRequest.phone ? getFormattedPhone(originalRequest.phone) : DASH,
      to: resultRequest.phone ? getFormattedPhone(resultRequest.phone) : DASH,
    };
  }

  // Автомашина
  if (diff.car) {
    calculatedDiff['car'] = {
      from: originalRequest.car || DASH,
      to: resultRequest.car || DASH,
    };
  }

  // Прицеп
  if (diff.trailer) {
    calculatedDiff['trailer'] = {
      from: originalRequest.trailer || DASH,
      to: resultRequest.trailer || DASH,
    };
  }

  return {
    requestId,
    shippingId,
    sender,
    status,
    comment,
    createdAt,
    updatedAt,
    originalRequest,
    routePointsOriginal,
    resultRequest,
    routePointsResult,
    routePointsDiff,
    calculatedDiff,
    diff,
  };
});

/**
 * Вернет тип изменения по точке
 * @type {Function}
 * @return CHANGE|APPEND|REMOVED|null
 */
export const getPointChangeType = defaultMemoize((pointDiff) => {
  // Точка добавилась
  if (!pointDiff?.location?.from?.fiasId && !!pointDiff?.location?.to?.fiasId) return APPEND;

  // Точка удалилась
  if (!pointDiff?.location?.to?.fiasId && !!pointDiff?.location?.from?.fiasId) return REMOVED;

  // Точка изменилась
  if (pointDiff?.location?.from?.fiasId !== pointDiff?.location?.to?.fiasId) return CHANGE;

  // Точка не менялась
  return null;
});
