import * as suggestionsReservedService from '../../../services/suggestionsReservedService';
import * as resourcesService from '../../../services/resourcesService';
import { push as routerPush } from 'connected-react-router';
import * as actionTypes from './actionTypes';
import * as suggestionsSelectorsReserved from './selectors';
import { change } from 'redux-form';
import errorsMapping from '../../../errors';
import { RESOURCES_FROM, PHONE_FIELD, INN_FIELD } from './formFields';
import { createDateTime } from '../../../helpers/dateTimeTools';
import { DATE_FORMAT, TIME_FORMAT } from '../../../constants';
import { toast } from '../../../components/Toaster';
import { extendItemsWithPartnership, extendItemWithPartnership } from '../../partners/actions';
import { extendItemWithRequestToRequirementsChange } from '../../requirements-change/actions';
import { addResourceSuccessEvent } from '../../googleAnalytics/events';
import { getCompanyID } from '../../company/selectors';
import * as chatService from '../../../services/chatService';
import * as chatActionTypes from '../../chat/actionTypes';
import { getRawQuery } from '../../page/selectors';

/**
 * Запрос на получение списка заявок
 * @returns {function(*, *): Promise<any>}
 */
export function getShippingReserved(isLoadingMore = false) {
  return (dispatch, getState) => {
    const params = suggestionsSelectorsReserved.getContractsRequestParams(getState());
    return suggestionsReservedService
      .getShippingReserved(params)
      .then((payload) => extendItemsWithPartnership(payload, 'shipping_request_info.cargo_owning_company_id'))
      .then((payload) => {
        dispatch({ type: isLoadingMore ? actionTypes.LOAD_MORE_ITEMS : actionTypes.INIT_ITEMS, payload });
        return payload;
      })
      .then((data) => {
        const joinedOrderIdList = data?.items?.map((item) => item.shipping_request_id).join(',');
        if (joinedOrderIdList) {
          return chatService.getChatsByEntityId(joinedOrderIdList).then((payload) => {
            dispatch({ type: chatActionTypes.SET_CHATS_FOR_ENTITY_ID, payload: payload?.chats ?? [] });
          });
        }
      });
  };
}

/**
 * Запрос на получение заявки
 */
export function getShippingById(shippingId) {
  return (dispatch, getState) => {
    const companyId = getCompanyID(getState());
    return suggestionsReservedService
      .getShipping(shippingId)
      .then((shipping) => extendItemWithRequestToRequirementsChange(shipping, undefined, companyId, false))
      .then((shipping) => extendItemWithPartnership(shipping, 'shipping_request_info.cargo_owning_company_id'))
      .then((shipping) => {
        dispatch({
          type: actionTypes.CURRENT_SET,
          payload: shipping,
        });
        return shipping;
      });
  };
}

/**
 * Сохранение дат
 * @param shippingId
 * @param routePointData
 * @return {function(*, *): Promise<any>}
 */
export function setDates(shippingId, routePointData) {
  return (dispatch, getState) => {
    const values = suggestionsSelectorsReserved.getShippingDatesFromForm(getState());
    const queryString = getRawQuery(getState());
    const dates = routePointData.map((point) => {
      if (point.isWarehouse) {
        return {
          isWarehouse: point.isWarehouse,
          routePointId: point.id,
          ...values[`${point.id}-time`],
        };
      }

      const supplyAt = createDateTime(
        values[`${point.id}-date`],
        values[`${point.id}-time`].value,
        point.timeShift,
        DATE_FORMAT,
        TIME_FORMAT,
      ).format();
      return {
        routePointId: point.id,
        supplyAt,
      };
    });

    return suggestionsReservedService
      .setDates(shippingId, dates)
      .then((data) => {
        dispatch({
          type: actionTypes.CURRENT_SET,
          payload: data,
        });
        toast.success('Даты назначены');
        dispatch(getShippingById(shippingId));
        dispatch(routerPush(`/shippings/${shippingId}/resources${queryString}`));
      })
      .catch((error) => toast.errorAsync(error));
  };
}

/**
 * Сохранение ресурсов
 */
export function setResources(id, values, canBuyInsurance, isThirdCategory) {
  return (dispatch, getState) => {
    const mappedValues = suggestionsSelectorsReserved.getShippingResourcesFromForm(values);
    const queryString = getRawQuery(getState());
    return suggestionsReservedService
      .setResourcesForShipping(id, mappedValues)
      .then((shipping) => {
        dispatch({
          type: actionTypes.CURRENT_SET, // TODO Необходимо смержить со старым объектом а не просто заменить, т.к. есть теряются некоторые данные
          payload: shipping,
        });
        dispatch(getShippingById(id)); // TODO поэтому перезапрашиваем данные по новой в фоне
        isThirdCategory ? toast.success('Ресурсы назначены') : toast.success('Ресурсы назначены, заявка подписана');
        canBuyInsurance
          ? dispatch(routerPush(`/shippings/${id}/insurance${queryString}`))
          : dispatch(routerPush(`/shippings${queryString}`));
      })
      .then(() => addResourceSuccessEvent(id))
      .catch((error) => {
        const errorObj = errorsMapping.get(error?.response?.data?.error);
        if (!!errorObj) {
          toast.error(errorObj.title, error?.response?.data?.error_description);
          return Promise.reject();
        } else {
          toast.errorAsync(error);
        }
      });
  };
}

export function sendDocumentToReview(shippingId, scanCopyId) {
  return (dispatch) => {
    return suggestionsReservedService
      .sendDocumentToReview(shippingId, scanCopyId)
      .then((data) => {
        toast.success('Скан-копия отправлена на согласование');
        dispatch(getShippingById(shippingId));
        return data;
      })
      .catch(toast.errorAsync);
  };
}

/**
 * Получение списка автомашин по перевозки
 * @param payload
 * @returns {function(): Promise<*>}
 */
export function getCarsForShipping(payload) {
  return () => {
    return resourcesService.getCarsForShipping(payload);
  };
}

/**
 * Получение списка водителелей для перевозки
 * @param payload
 * @returns {function(): Promise<*>}
 */
export function getDriversForShipping(payload) {
  return () => {
    return resourcesService.getDriversForShipping(payload);
  };
}

/**
 * получение списка прицпов для перевозки
 * @param payload
 * @returns {function(): Promise<*>}
 */
export function getTrailersForShipping(payload) {
  return () => {
    return resourcesService.getTrailersForShipping(payload);
  };
}

/**
 * отмена бронирования заявки
 * @param shippingId
 * @param params
 * @returns {Function}
 */
export function cancelOfferShipping(shippingId, params) {
  return () => {
    return suggestionsReservedService
      .cancelOfferShipping(shippingId, params)
      .then(() => {
        toast.success('Бронь отменена');
      })
      .catch((error) => toast.errorAsync(error));
  };
}

/**
 * Загрузка печатной формы
 * @param shippingId
 * @returns {Function}
 */
export function getPrintableForm(shippingId) {
  return (dispatch) => {
    return suggestionsReservedService.getPrintableForm(shippingId).then((data) => {
      dispatch({ type: actionTypes.UPDATE_PRINTABLE_FORM, payload: data });
    });
  };
}

/**
 * Загрузка траспортной накладой
 * @param shippingId
 * @returns {Function}
 */
export function getTransportFiles(shippingId) {
  return (dispatch) => {
    return suggestionsReservedService.getTransportFiles(shippingId).then((data) => {
      dispatch({ type: actionTypes.UPDATE_TRANSPORT_FILES, payload: data });
    });
  };
}

export function setPhone(phone) {
  return (dispatch) => {
    dispatch(change(RESOURCES_FROM, PHONE_FIELD, phone));
  };
}

export function setINN(inn, index) {
  return (dispatch) => {
    dispatch(change(RESOURCES_FROM, `${INN_FIELD}[${index}]`, inn));
  };
}

/**
 *
 * @returns {{type: string}}
 */
export function resetReducer() {
  return {
    type: actionTypes.RESET,
  };
}

/**
 * Подключить/отключить ускоренную оплату
 * @param shippingId
 * @param isExpeditedPayment
 * @returns {function(): Promise<*>}
 */
export function setExpeditedPayment(shippingId, isExpeditedPayment) {
  return () => {
    return suggestionsReservedService
      .setExpeditedPayment(shippingId, {
        expedited_payment: isExpeditedPayment,
      })
      .catch((error) => toast.errorAsync(error));
  };
}
