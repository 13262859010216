import http from './config/webConfig';
import pageListMapper from '../helpers/mappers/pageListMapper';

/**
 * Получить список изменений требований
 * @param params = {}
 * @return {Promise<any>}
 */
export async function getRequirementsChanges(params) {
  const url = `/requirements_changes`;
  return http.get(url, { params, cache: false, requestId: url }).then(pageListMapper);
}

/**
 * Принять изменение требований
 * @param requirementsChangeId {String} - идентификатор изменений требований
 * @returns {Promise<*>}
 */
export async function acceptRequirementsChange(requirementsChangeId) {
  const url = `/requirements_changes/${requirementsChangeId}/accept`;
  return http.post(url, {}, { cache: false });
}

/**
 * Отклонить изменение требований
 * @param requirementsChangeId {String} - идентификатор изменений требований
 * @returns {Promise<*>}
 */
export async function cancelRequirementsChange(requirementsChangeId) {
  const url = `/requirements_changes/${requirementsChangeId}/reject`;
  return http.post(url, {}, { cache: false });
}

// ============ Смена ресурсов в перевозке ============ //

/**
 * Создание запроса на изменение требований
 * @param shippingId {String}
 * @param params
 * @returns {Promise<void>}
 */
export async function createRequestChangeRequirements(shippingId, params) {
  const url = `/shippings/${shippingId}/change_requests`;
  return http.post(url, params, { cache: false }).then((response) => response.data);
}

/**
 * Получение запроса на смену ресурсов
 * @param shippingId
 * @returns {Promise<{}|*>}
 */
export async function getRequestsChangeRequirements(shippingId) {
  const url = `/shippings/${shippingId}/change_requests`;
  return http.get(url, { cache: false }).then((response) => response.data);
}

/**
 * Согласование запроса на смену ресурсов
 * @param shippingId
 * @param requestId
 * @returns {Promise<{}|*>}
 */
export async function acceptRequestsChangeRequirements(shippingId, requestId) {
  const url = `/shippings/${shippingId}/change_requests/${requestId}/accept`;
  return http.post(url, {}, { cache: false }).then((response) => response.data);
}

/**
 * Отклонение запроса на смену ресурсов
 * @param shippingId
 * @param requestId
 * @param comment
 * @returns {Promise<{}|*>}
 */
export async function rejectRequestsChangeRequirements(shippingId, requestId, comment) {
  const url = `/shippings/${shippingId}/change_requests/${requestId}/reject`;
  return http.post(url, { comment }, { cache: false }).then((response) => response.data);
}
