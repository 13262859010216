import React, { useCallback } from 'react';
import classNames from 'classnames';
import { FieldProps } from 'formik';

import styles from './styles.module.scss';
import Button from '../../Button';
import { IconAdd, IconMinus } from '../../Icons';
import { useLongPress } from '../../../hooks/useLongPress';
import { useFormikContextSelector } from '../FormikContext';

interface IProps {
  min: number;
  max: number;
  row: number;
  step: number | string;
  name: string;
  readOnly: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: any;
}

function FormCounter(props: IProps & FieldProps) {
  const { disabled, readOnly, row, step, min, max, field, className, onChange: onChangeHandler } = props;
  const { name, value: fieldValue } = field;
  const _setFieldValue = useFormikContextSelector((c) => c.setFieldValue);

  const decreaseLongPress = useLongPress(decrease, { clickHandler: decrease });
  const increaseLongPress = useLongPress(increase, { clickHandler: increase });

  const setFieldValue = useCallback(
    (value) => {
      _setFieldValue(name, value, true);
      onChangeHandler && onChangeHandler(value);
    },
    [_setFieldValue, name, onChangeHandler],
  );

  function getValue() {
    return row ? row : parseFloat(fieldValue);
  }

  function decrease() {
    const value = getValue();

    if (value === min) return;
    setFieldValue(value - Number(step));
  }

  function increase() {
    const value = getValue();

    if (value === max) return;
    setFieldValue(value + Number(step));
  }

  function onChange(event) {
    saveNewValue(event, false);
  }

  function onBlur(event) {
    saveNewValue(event);
  }

  function saveNewValue(event, checkValue = true) {
    let value = getValue();

    if (isNaN(value) && checkValue) {
      value = 0;
    }

    if (!isNaN(value) && checkValue && value > max) {
      setFieldValue(max);
    } else if (!isNaN(value) && checkValue && value < min) {
      setFieldValue(min);
    } else {
      if (isNaN(value)) {
        setFieldValue(0);
      } else {
        setFieldValue(value);
      }
    }
  }

  return (
    <div className={classNames(styles.wrapper, className)}>
      <Button
        type="button"
        theme="counter"
        disabled={disabled || getValue() === min}
        className={classNames(styles.buttonCounter, styles.buttonCounterLeft)}
        {...decreaseLongPress}
      >
        <IconMinus width="20" height="20" color="base" />
      </Button>

      <input
        {...field}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        className={styles.input}
      />

      <Button
        type="button"
        theme="counter"
        disabled={disabled || getValue() === max}
        className={classNames(styles.buttonCounter, styles.buttonCounterRight)}
        {...increaseLongPress}
      >
        <IconAdd width="20" height="20" />
      </Button>
    </div>
  );
}

FormCounter.defaultProps = {
  fieldType: 'number',
  step: 1,
  initial: 0,
  readOnly: false,
  row: undefined,
};

export default FormCounter;
