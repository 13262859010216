import styles from './styles.module.scss';
import { IconChevron } from '../Icons';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';

type ContextValueType = boolean;

type ContextSetValueType = Dispatch<SetStateAction<boolean>>;

const CollapseValueContext = createContext<ContextValueType>(false);
export function useCollapseValueContext() {
  return useContext(CollapseValueContext);
}

const CollapseSetValueContext = createContext<ContextSetValueType>(() => false);
export function useCollapseSetValueContext() {
  return useContext(CollapseSetValueContext);
}

type CollapseProps = {
  open?: boolean;
  children: ReactNode;
  onClick?: (boolean) => void;
};

function Collapse(props: CollapseProps) {
  const { open: propsOpen, children, onClick } = props;
  const [open, setOpen] = useState(propsOpen ?? false);

  useEffect(() => {
    if (propsOpen || !!onClick) {
      setOpen(propsOpen!);
    }
  }, [onClick, propsOpen]);

  const handleClick = onClick || setOpen;

  return (
    <CollapseValueContext.Provider value={open}>
      <CollapseSetValueContext.Provider value={handleClick}>{children}</CollapseSetValueContext.Provider>
    </CollapseValueContext.Provider>
  );
}

type TriggerProps = {
  closeEl?: string | ReactNode;
  openEl?: string | ReactNode;
  chevronPosition?: 'left' | 'right';
  className?: string;
  disabled?: boolean;
};
function CollapseTrigger(props: TriggerProps) {
  const { closeEl = 'Скрыть', openEl = 'Показать', chevronPosition = 'right', disabled = false, className } = props;
  const open = useCollapseValueContext();
  const setOpen = useCollapseSetValueContext();
  const handleClick = useCallback(() => setOpen((prev) => !prev), [setOpen]);

  return (
    <button type="button" disabled={disabled} onClick={handleClick} className={classNames(styles.button, className)}>
      {chevronPosition === 'left' && (
        <IconChevron
          height={20}
          width={20}
          className={classNames(styles.leftIcon, open ? styles.chevronUp : styles.chevronDown)}
        />
      )}
      <span>{open ? closeEl : openEl}</span>
      {chevronPosition === 'right' && (
        <IconChevron
          height={20}
          width={20}
          className={classNames(
            styles.rightIcon,
            open ? styles.chevronUp : styles.chevronDown,
            disabled && styles.iconDisabled,
          )}
        />
      )}
    </button>
  );
}

function CollapseCustomTrigger(props) {
  const { disabled, children } = props;

  const open = useCollapseValueContext();
  const setOpen = useCollapseSetValueContext();
  const handleClick = useCallback(() => setOpen((prev) => !prev), [setOpen]);

  if (typeof children === 'function') {
    return children({ open, handleClick, disabled });
  }

  return React.cloneElement(children, {
    onClick: handleClick,
    open,
    disabled,
  });
}

type ContentProps = {
  children: ReactNode;
};
function CollapseContent(props: ContentProps) {
  const { children } = props;
  const open = useCollapseValueContext();
  return (
    <AnimateHeight duration={200} height={open ? 'auto' : 0}>
      {children}
    </AnimateHeight>
  );
}

Collapse.Trigger = CollapseTrigger;
Collapse.CustomTrigger = CollapseCustomTrigger;
Collapse.Content = CollapseContent;
export default Collapse;
