import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconArrowClockBold = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6487 11L10.9537 13.7182L12.1875 15L17 10L12.1875 5L10.945 6.28182L13.6487 9H10.945C11.075 9.33333 11.301 10.2 10.945 11L13.6487 11ZM10 10C10 11.6569 8.65685 13 7 13C5.34315 13 4 11.6569 4 10C4 8.51353 5.08114 7.27954 6.5 7.0415V10V10.5H7H9V9.50003H7.5V7.0415C8.91886 7.27954 10 8.51353 10 10Z"
      />
    </SVGIcon>
  );
};

IconArrowClockBold.defaultProps = {
  color: 'base',
};

export default IconArrowClockBold;
