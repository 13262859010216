if (!String.prototype.repeat) {
  // eslint-disable-next-line
  String.prototype.repeat = function (count) {
    if (this === null) {
      throw new TypeError("can't convert " + this + ' to object');
    }
    let str = '' + this;
    count = +count;

    if (count < 0) {
      throw new RangeError('repeat count must be non-negative');
    }
    if (count === Infinity) {
      throw new RangeError('repeat count must be less than infinity');
    }
    count = Math.floor(count);
    if (str.length === 0 || count === 0) {
      return '';
    }

    if (str.length * count >= 1 << 28) {
      throw new RangeError('repeat count must not overflow maximum string size');
    }
    let rpt = '';
    for (let i = 0; i < count; i++) {
      rpt += str;
    }
    return rpt;
  };
}
