function getTonnageVolume(tonnage: number, volume: number): string {
  if (tonnage === 21 && volume === 33) {
    return '20 футов';
  } else if (tonnage === 26 && volume === 67) {
    return '40 футов';
  } else {
    return `${tonnage} т / ${volume} м\u00B3`;
  }
}

export default getTonnageVolume;
