import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CATEGORY_3 } from '../../../constants';
import {
  ALL_REQUESTS,
  ALL_SCANCOPY_STATUSES,
  APPROVAL_WAITING,
  DISTANCE_REQUEST_TYPE,
  SCANCOPY_STATUS,
  TRIP_WAITING,
} from '../../../store/suggestions/queryKeys';
import { INSURANCE_OPTIONS } from '../constants';
import { ACTIVE_POLICY_STATE, PENDING_POLICY_STATE, VOID_POLICY_STATE } from '../../../containers/shippings/constants';
import { getRelevantTabForStatus } from '../../../store/documents/helpers';
import { getUrlString } from '../../../helpers/getUrlString';
import { getMappedInsurancePolicy } from '../../../containers/shippings/selectors';

import { getPolicyByShippingId, setOwnPolicy } from '../api/insuranceService';
import {
  useInsuranceInfo,
  useMultipolicyEnvParams,
  useMultipolicyNotificationsV2,
  useMultipolicyOrdered,
} from './hooks';
import { parseMultipolicyFiles, showServiceDeniedError } from '../helpers';

/**
 * Переменные среды мультиполиса
 * @return {{enabled: boolean}}
 */
export function useAlfaEnvParams() {
  return {
    enabled: process.env.REACT_APP_ENABLE_ALFA_INSURANCE === 'enabled',
  };
}

/**
 * запросы по страхованию разными способами
 * @param mappedShipping
 * @return {{selectMyInsurance: (function(): Promise<*>), redirectToShippings: ((function(): void)|*), rejectMyInsurance: (function(): Promise<*>), redirectToShipping: ((function(): void)|*), shippingsBackUrl: string, shippingBackUrl: string}}
 */
export function useInsuranceRequests(mappedShipping) {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const isThirdCategory = mappedShipping?.category === CATEGORY_3;

  const shippingTabStatus = useMemo(() => {
    if (!mappedShipping) return;
    if (isThirdCategory) {
      return getRelevantTabForStatus(mappedShipping.shippingStatus);
    } else {
      return mappedShipping.shippingStatus === APPROVAL_WAITING
        ? TRIP_WAITING
        : getRelevantTabForStatus(mappedShipping.shippingStatus);
    }
  }, [isThirdCategory, mappedShipping]);

  const shippingsBackUrl = useMemo(() => {
    return getUrlString(
      'shippings',
      { status: shippingTabStatus, [DISTANCE_REQUEST_TYPE]: ALL_REQUESTS, [SCANCOPY_STATUS]: [ALL_SCANCOPY_STATUSES] },
      { isList: true, arrayFormat: 'bracket' },
    );
  }, [shippingTabStatus]);
  const shippingBackUrl = useMemo(() => {
    return getUrlString(
      'shippings',
      {
        active_item: mappedShipping?._original.id,
        status: shippingTabStatus,
        [DISTANCE_REQUEST_TYPE]: ALL_REQUESTS,
        [SCANCOPY_STATUS]: [ALL_SCANCOPY_STATUSES],
      },
      { isList: true, arrayFormat: 'bracket' },
    );
  }, [mappedShipping, shippingTabStatus]);

  const redirectToShippings = useCallback(() => {
    history.push(shippingsBackUrl);
  }, [shippingsBackUrl, history]);
  const redirectToShipping = useCallback(() => {
    history.push(shippingBackUrl);
  }, [shippingBackUrl, history]);

  const selectMyInsurance = useCallback(() => {
    setIsLoading(true);
    return setOwnPolicy(mappedShipping.reservedId)
      .catch(showServiceDeniedError)
      .finally(() => setIsLoading(false));
  }, [mappedShipping]);

  const rejectMyInsurance = useCallback(() => {
    setIsLoading(true);
    return setOwnPolicy(mappedShipping.reservedId, false)
      .catch(showServiceDeniedError)
      .finally(() => setIsLoading(false));
  }, [mappedShipping]);

  return {
    isLoading,
    // к деталке
    redirectToShipping,
    shippingBackUrl,
    // к реестру
    redirectToShippings,
    shippingsBackUrl,
    // своя страховка
    selectMyInsurance,
    rejectMyInsurance,
  };
}

/**
 * определение способа страхования перевозки; получение страховок
 * @param mappedShipping
 * @return {{isLoading: boolean, isRefetching: boolean, annulledInsuranceOption: unknown, [INSURANCE_OPTIONS.MULTIPOLICY]: {isLoading: boolean, docs: *, hasInsurance: boolean, canCancel: boolean}, insuranceOption: unknown, refetch: ((function(): void)|*), [INSURANCE_OPTIONS.ALFA]: {isLoading: boolean, activeInsurance: undefined, annulledInsurance: undefined, isCatalogEmpty: boolean}}}
 */
export function useShippingInsurance(mappedShipping) {
  const [isAlfaLoading, setIsAlfaLoading] = useState(false);
  const [insurance, setInsurance] = useState();
  const [annulledInsurance, setAnnulledInsurance] = useState();
  const [isRefetching, setIsRefetching] = useState(false);

  const { enabled: multipolicyAvailable } = useMultipolicyEnvParams();
  const { hasOwnInsurance } = useInsuranceInfo(mappedShipping?._original);

  const getAlfaInsurance = useCallback(() => {
    return getPolicyByShippingId(mappedShipping.reservedId)
      .then((policies) => {
        const activePolicy = policies.find((policy) =>
          [ACTIVE_POLICY_STATE, PENDING_POLICY_STATE].includes(policy.state),
        );
        const annulledInsurance = policies.find((policy) => policy.state === VOID_POLICY_STATE);

        setInsurance(getMappedInsurancePolicy(activePolicy));
        setAnnulledInsurance(getMappedInsurancePolicy(annulledInsurance));
      })
      .catch(showServiceDeniedError);
  }, [mappedShipping.reservedId]);

  useEffect(() => {
    if (mappedShipping) {
      setIsAlfaLoading(true);
      getAlfaInsurance().finally(() => setIsAlfaLoading(false));
    }
  }, [mappedShipping, getAlfaInsurance]);

  const {
    isLoading: isMultipolicyLoading,
    hasInsurance,
    canCancelInsurance,
    multipolicyDocs,
    refetch: refetchMultipolicy,
  } = useMultipolicyOrdered(multipolicyAvailable ? mappedShipping?._original : null);
  const { isInsuranceActive, isInsuranceFailed, isInsuranceRequested, isInsuranceRevoked } =
    parseMultipolicyFiles(multipolicyDocs);
  const showMultipolicyBlock = isInsuranceActive || isInsuranceRequested || isInsuranceFailed;
  const hasAnnulledMultipolicy = isInsuranceRevoked;

  const isLoading = isAlfaLoading || isMultipolicyLoading;

  const insuranceOption = useMemo(() => {
    if (isLoading) return null;

    if (insurance) return INSURANCE_OPTIONS.ALFA;

    if (showMultipolicyBlock) return INSURANCE_OPTIONS.MULTIPOLICY;

    if (hasOwnInsurance) return INSURANCE_OPTIONS.OWN;

    return null;
  }, [isLoading, hasOwnInsurance, insurance, showMultipolicyBlock]);

  const annulledInsuranceOption = useMemo(() => {
    if (isLoading) return null;

    if (annulledInsurance) return INSURANCE_OPTIONS.ALFA;

    if (hasAnnulledMultipolicy) return INSURANCE_OPTIONS.MULTIPOLICY;

    return null;
  }, [isLoading, annulledInsurance, hasAnnulledMultipolicy]);

  const refetch = useCallback(() => {
    setIsRefetching(true);
    Promise.all([getAlfaInsurance(), refetchMultipolicy()]).finally(() => setIsRefetching(false));
  }, [getAlfaInsurance, refetchMultipolicy]);

  return {
    isLoading,
    isRefetching,
    insuranceOption,
    annulledInsuranceOption,
    refetch,
    [INSURANCE_OPTIONS.ALFA]: {
      isLoading: isAlfaLoading,
      activeInsurance: insurance,
      isCatalogEmpty: true,
      annulledInsurance: annulledInsurance,
    },
    [INSURANCE_OPTIONS.MULTIPOLICY]: {
      isLoading: isMultipolicyLoading,
      hasInsurance: hasInsurance,
      docs: multipolicyDocs,
      canCancel: canCancelInsurance,
    },
  };
}

/**
 * Проверка доступности страховок.
 * @param mappedShipping
 * @param newDepartureDateTime
 * @return {{isLoading: boolean, hasAvailableInsurance: (*|boolean), [INSURANCE_OPTIONS.MULTIPOLICY]: {isLoading: boolean, price: *, available: boolean, notifications: {insurancePrice: (JSX.Element|null), cargoWarning: (JSX.Element|null), notAvailable: (JSX.Element|null)}}, [INSURANCE_OPTIONS.ALFA]: {isLoading: boolean, catalog: *[], available: (*|boolean)}}}
 */
export function useInsurancesAvailability(mappedShipping, newDepartureDateTime = null) {
  const originalShipping = mappedShipping?._original;

  // мультиполис
  const {
    isValidating,
    available,
    validationPassed,
    notAvailableNotification,
    cargoWarnNotification,
    insurancePriceNotification,
    insurancePrice,
  } = useMultipolicyNotificationsV2(originalShipping, newDepartureDateTime, null);
  const multipolicyAvailable = available && validationPassed;

  return {
    isLoading: isValidating,
    hasAvailableInsurance: multipolicyAvailable,
    [INSURANCE_OPTIONS.ALFA]: {
      isLoading: false,
      available: false,
      catalog: [],
    },
    [INSURANCE_OPTIONS.MULTIPOLICY]: {
      isLoading: isValidating,
      available: multipolicyAvailable,
      price: insurancePrice,
      notifications: {
        notAvailable: notAvailableNotification,
        cargoWarning: cargoWarnNotification,
        insurancePrice: insurancePriceNotification,
      },
    },
  };
}
