import { SERVICE_DESCRIPTIONS, DESCRIPTION_MASK } from '../../../store/order/formFields';

export function getServiceDescriptions(id: string, count: number) {
  let detail = ``;
  for (let service of SERVICE_DESCRIPTIONS.keys()) {
    if (id === service) {
      detail = SERVICE_DESCRIPTIONS.get(service).replace(DESCRIPTION_MASK, count);
    }
  }

  return detail;
}
