import * as types from './actionTypes';
import { SET_NOTIFICATIONS_ACCOUNT, SET_PREVIOUS_URL, SET_TELEGRAM_BOT_INFO } from './actionTypes';
import produce from 'immer';

const initialState = {
  typeModal: localStorage.getItem('notification-service-is-open') || null,
  prevUrl: localStorage.getItem('notification-service-previous-url') || null,
  notificationsAccount: null,
  telegramBotInfo: null,
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case types.SET_OPEN:
      draft.typeModal = action.payload;
      localStorage.setItem('notification-service-is-open', action.payload + '');
      break;

    case SET_PREVIOUS_URL:
      draft.prevUrl = action.payload;
      localStorage.setItem('notification-service-previous-url', action.payload ?? '');
      break;

    case SET_NOTIFICATIONS_ACCOUNT:
      draft.notificationsAccount = action.payload;
      break;

    case SET_TELEGRAM_BOT_INFO:
      draft.telegramBotInfo = action.payload;
  }
}, initialState);

export default reducer;
