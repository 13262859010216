import React from 'react';
import Tooltip from '../../Tooltip';
import { FULL_COVER, BACK_COVER, SIDE_COVER, TOP_COVER } from '../../../store/resources/formKeys';

export const TYPES = new Map()
  .set(FULL_COVER, 'Полная')
  .set(BACK_COVER, 'Задняя')
  .set(SIDE_COVER, 'Боковая')
  .set(TOP_COVER, 'Верхняя');

interface TypeProps {
  loadingTypes: string[];
}

export function getVehicleLoadingType({ loadingTypes }: TypeProps) {
  const typesLength = loadingTypes?.length;
  const types = loadingTypes.map((type) => TYPES.get(type)).join(', ');

  return (
    <span className="flex column">
      <span>
        {TYPES.get(loadingTypes?.[0]) || 'Полная'}
        {typesLength > 1 && (
          <Tooltip content={types}>
            <span className="caption color-primary ml-1">+{typesLength - 1}</span>
          </Tooltip>
        )}
      </span>
    </span>
  );
}

export function getVehicleLoadingTypeString({ loadingTypes }: TypeProps) {
  return loadingTypes.map((type) => TYPES.get(type)).join(', ');
}
