import React from 'react';
import SVGIcon from './index';

function IconClaims(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4H11.937C11.715 3.13739 10.9319 2.5 10 2.5C9.06808 2.5 8.28503 3.13739 8.06301 4H4V17H16V4ZM9.5 4.5C9.5 4.22386 9.72386 4 10 4C10.2761 4 10.5 4.22386 10.5 4.5C10.5 4.77614 10.2761 5 10 5C9.72386 5 9.5 4.77614 9.5 4.5ZM9.5 7V12H10.5V7H9.5ZM9.5 13V14H10.5V13H9.5Z"
      />
    </SVGIcon>
  );
}

IconClaims.defaultProps = { color: 'white' };

export default React.memo(IconClaims);
