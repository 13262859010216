export const PROFILE_FORM_MAIN_INFO = 'profileFormMainInfo';
export const CARS_NUMBER = 'carsNumber';
export const CARS_RENT_NUMBER = 'carsRentNumber';
export const ACTION_TYPE = 'actionType';
export const FIO_DIRECTOR = 'fioDirector';
export const FIO_ACCOUNTANT = 'fioAccountant';
export const BASIS_FOR_SIGN = 'basisForSign';
export const PHONE_ACCOUNTANT = 'phoneAccountant';
export const PHONE_COMPANY = 'phoneCompany';
export const TAX_TYPE = 'taxType';
export const WEB_SITE = 'webSite';
export const ADDITIONAL_INFO = 'additionalInfo';
export const FIO_TRUSTED = 'fioTrusted';
export const TRUSTED_POSITION = 'trustedPosition';
export const PHONE_TRUSTED = 'phoneTrusted';
export const ATTORNEY = 'powerOfAttorney';

export const PROFILE_FORM_COMPANY_REQUISITES = 'ProfileFormCompanyRequisites';
export const COUNTRY = 'country';
export const INN = 'inn';
export const KPP = 'kpp';
export const COMPANY = 'company';
export const ADDRESS_LEGAL_CITY = 'addressLegalCity';
export const ADDRESS_LEGAL_STREET = 'addressLegalStreet';
export const ADDRESS_LEGAL_BUILDING = 'addressLegalBuilding';
export const ADDRESS_LEGAL_ROOM = 'addressLegalRoom';
export const ADDRESS_LEGAL_INDEX = 'addressLegalIndex';
export const SAME_ADDRESS = 'sameAddress';
export const ADDRESS_ACTUAL_CITY = 'addressActualCity';
export const ADDRESS_ACTUAL_STREET = 'addressActualStreet';
export const ADDRESS_ACTUAL_BUILDING = 'addressActualBuilding';
export const ADDRESS_ACTUAL_ROOM = 'addressActualRoom';
export const ADDRESS_ACTUAL_INDEX = 'addressActualIndex';
export const DATE = 'date';

export const OKVED = 'okved';
export const PAYMENT_ACCOUNT = 'paymentAccount';
export const BANK = 'bankName';
export const CORRESPONDENT_ACCOUNT = 'correspondentAccount';
export const BIK = 'bik';
export const OGRN = 'ogrn';

/* Company auction form */
export const MANUALLY = 'manually';
export const AUTO_EXTENSION = 'autoExtension';
export const EXTEND_FOR = 'extendFor';

/* Carrier notification form */
export const INFO = 'info';
export const START_DATE = 'startDate';
export const START_DATE_FROM = 'startDateFrom';
export const CONFIRM_NOTIFICATION = 'confirmNotification';

// partnership form:
export const PARTNERSHIPS_ALLOWED = 'partnerships_allowed';
