/* Внутренний статус - данные не сохранены на BackEnd */
export const NONE = 'none';
/* Системные статусы - данные уже сохранены на BackEnd  */
export const NOT_APPROVED = 'not_approved';
export const REVIEW = 'review';
export const APPROVED = 'approved';
export const NEED_FIX_BY_USER = 'need_fix_by_user';
export const REJECTED = 'rejected';
export const ACCESS_SUSPENDED = 'access_suspended';
export const IN_PROGRESS_BY_MANAGER = 'in_progress_by_manager';
/* для статуса "Не допущен" */
export const NOT_ALLOWED = 'not_allowed';
/* для статуса "Нет доверенности" */
export const NOT_ATTORNEY = 'not_attorney';

// статусы доверенностей
export const NEW = 'new';
export const ACTIVE = 'active';
export const EXPIRED = 'expired';
