import { useEffect } from 'react';

import { CONTRACT_BY_COUNT } from '../blocks/OrderNFormTypeBlock';
import { volumeContractEvent } from '../../../store/googleAnalytics/events';

export function useOrderTypeEffect(orderType) {
  /* GAnalytics */
  useEffect(() => {
    if (orderType === CONTRACT_BY_COUNT) {
      volumeContractEvent();
    }
  }, [orderType]);
}
