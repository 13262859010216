export const INIT_DRIVERS = 'resources.INIT_DRIVERS';
export const LOAD_MORE_DRIVERS = 'resources.LOAD_MORE_DRIVERS';
export const UPDATE_DRIVER = 'resources.UPDATE_DRIVER';
export const RECOGNIZE_DRIVER_DOCS = 'resources.RECOGNIZE_DRIVER_DOCS';
export const DELETE_RECOGNIZED_DRIVER_DOCS = 'resources.DELETE_RECOGNIZED_DRIVER_DOCS';
export const CLEAR_RECOGNIZED_DRIVER_DOCS = 'resources.CLEAR_RECOGNIZED_DRIVER_DOCS';

export const INIT_CARS = 'resources.INIT_CARS';
export const LOAD_MORE_CARS = 'resources.LOAD_MORE_CARS';
export const UPDATE_CAR = 'resources.UPDATE_CAR';
export const RECOGNIZE_CAR_DOCS = 'resources.RECOGNIZE_CAR_DOCS';
export const DELETE_RECOGNIZED_CAR_DOCS = 'resources.DELETE_RECOGNIZED_CAR_DOCS';
export const CLEAR_RECOGNIZED_CAR_DOCS = 'resources.CLEAR_RECOGNIZED_CAR_DOCS';

export const INIT_TRAILERS = 'resources.INIT_TRAILERS';
export const LOAD_MORE_TRAILERS = 'resources.LOAD_MORE_TRAILERS';
export const UPDATE_TRAILER = 'resources.UPDATE_TRAILER';
export const RECOGNIZE_TRAILER_DOCS = 'resources.RECOGNIZE_TRAILER_DOCS';
export const DELETE_RECOGNIZED_TRAILER_DOCS = 'resources.DELETE_RECOGNIZED_TRAILER_DOCS';
export const CLEAR_RECOGNIZED_TRAILER_DOCS = 'resources.CLEAR_RECOGNIZED_TRAILER_DOCS';

export const UPDATE_AVAILABLE_LIFTING = 'resources.UPDATE_AVAILABLE_LIFTING';
export const UPDATE_AVAILABLE_BRANDS = 'resources.UPDATE_AVAILABLE_BRANDS';

export const SET_ACTIVE_ITEM = 'resources.SET_ACTIVE_ITEM';

export const CLEAR_RESOURCES = 'resources.CLEAR_RESOURCES';
export const CLEAR_RECOGNIZED_DOCS = 'resources.CLEAR_RECOGNIZED_DOCS';
