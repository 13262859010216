import { useEffect } from 'react';

import { useOrderContext } from '../index';
import { CONTRACT_BY_COUNT, ORDER_TYPE, SHIPPING_REQUEST } from '../blocks/OrderNFormTypeBlock';
import { useFormikContextSelector } from '../../../components/FormikFieldsV3/FormikContext';

export function useOrderTypeUnavailableEffect() {
  const { isOrderBasedOnContract, isOrderChange, canCreateInstant, canCreateAuction, canCreateContract } =
    useOrderContext();
  const setFieldValue = useFormikContextSelector((c) => c.setFieldValue);

  useEffect(() => {
    if (!isOrderBasedOnContract && !isOrderChange) {
      if (!canCreateInstant && !canCreateAuction) {
        setFieldValue(ORDER_TYPE, CONTRACT_BY_COUNT);
      }
      if (!canCreateContract) {
        setFieldValue(ORDER_TYPE, SHIPPING_REQUEST);
      }
    }
  }, [canCreateAuction, canCreateInstant, canCreateContract, setFieldValue, isOrderBasedOnContract, isOrderChange]);
}
