import * as types from './actionTypes';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

const initialState = {
  loading: true,
  loaded: false,
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    case types.UPDATE_LOADING:
      draft.loading = action.loading;
      break;
    case types.UPDATE_LOADED:
      draft.loaded = action.loaded;
      break;
    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
