/**
 * --- переходы на страницу ---
 */
export const OPEN_USER_PROFILE_PAGE = 'OpenUserProfilePage';
export const OPEN_NOTIFICATIONS_PAGE = 'OpenNotificationsPage';
export const OPEN_NOTIFICATIONS_SETTINGS_PAGE = 'OpenNotificationSettingsPage';
export const OPEN_USERS_AND_ROLES_SETTINGS_PAGE = 'OpenUsersAndRolesSettingsPage';

/**
 * --- открытие модалок/поповеров ---
 */
// хэдер
export const SHOW_NOTIFICATIONS_POPOVER = 'ShowNotificationsPopover';
export const SHOW_HEADER_PROFILE_POPOVER = 'ShowHeaderProfilePopover';
// остальное
export const VIEW_SUGGESTION = 'ViewSuggestion';
export const OPEN_SAVED_SEARCHES = 'OpenSavedSearches';
export const SHOW_POINT_ON_MAP_MODAL = 'ShowPointOnMapModal';

/**
 * --- нажатия на кнопки/чекбоксы/свичи и т.д (локальные действия) ---
 */
// деталка предложения
export const RESERVE_SUGGESTION = 'ReserveSuggestion';
export const MAKE_SUGGESTION_BID = 'MakeBidOnSuggestion';

// деталка заявки
export const EDIT_ORDER_IN_AUCTION = 'EditOrderInAuction';
export const CLICK_INCREASE_BID_BUTTON_1_OR_2_CATEGORY = 'ClickIncreaseBidButton1Or2Category';

// деталка перевозки
export const ADD_SUITABLE_RESOURCES = 'AddSuitableResources';

// настройка уведомлений
export const SUCCESSFUL_CHANGE_NOTIFICATION_SETTINGS = 'SuccessfulChangeNotificationSettings';
export const UNSUCCESSFUL_CHANGE_NOTIFICATION_SETTINGS = 'UnsuccessfulChangeNotificationSettings';
export const UNSUBSCRIBE_FROM_NOTIFICATIONS = 'UnsubsribeFromNotifications';
export const REMOVE_NOTIFICATION_CHANNEL = 'RemoveNotificationChannel';

// уведомления:
export const READ_ALL_NOTICES = 'ReadAllNotices';
export const READ_NOTICE = 'ReadNotice';

// форма водителя:
export const ENABLE_MANUAL_ADDRESS_INPUT = 'EnableManualAddressInput';
export const DISABLE_MANUAL_ADDRESS_INPUT = 'DisableManualAddressInput';

// Массовое добавление заявок через excel
export const UPLOAD_ORDERS_FROM_EXCEL = 'UploadOrdersFromExcel';

// Модалка указания точки на карте
export const ADD_POINT_COORDINATES_TO_ORDER = 'AddPointCoordinatesToOrder';

// Массовая рассылка
export const CREATE_MASS_SENDING = 'CreateMassSending';
export const SHOW_MASS_SENDING_FULL_TEXT = 'ShowMassSendingFullText';

// Отчет проверки ресурсов
export const SEND_REPORT = 'SendResourcesReport';
/**
 * --- действия с общими компонентами ---
 */
// aside
export const COLLAPSE_ASIDE_CLICK_HANDLER = 'CollapseAsideClickHandler';

// таблица:
export const OPEN_TABLE_COLUMNS_SETTINGS_POPOVER = 'OpenTableColumnsSettingsPopover';
export const CHANGE_TABLE_COLUMN_VISIBILITY_STATE = 'ChangeTableColumnVisibilityState';
export const CHANGE_TABLE_COLUMN_PINNED_STATE = 'ChangeTableColumnPinnedState';
export const CHANGE_TABLE_COLUMN_POSITION = 'ChangeTableColumPosition';
export const RESET_TABLE_COLUMNS_SETTINGS = 'ResetTableColumnsSettings';
export const USED_TABLE_HORIZONTAL_SCROLL = 'UsedTableHorizontalScroll';
