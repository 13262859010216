import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';
import classnames from 'classnames';
import Autosuggest from 'react-autosuggest';

import Input from '../../Input';
import { IconChevron } from '../../Icons';
import { Common, Multi } from '../OptionLists';
import { declinationOfCompanies, declinationOfPartners } from '../../../helpers/declinationOfNumbers';

/**
 * Маппинг для выбора функции склонения
 */
const multiSuggestionValueMapping = new Map()
  .set('partners', declinationOfPartners)
  .set('companies', declinationOfCompanies);

/**
 * Получение значения input
 * @param suggestion
 * @returns {string}
 */
function getSuggestionValue(suggestion) {
  return suggestion ? suggestion.label : '';
}

/**
 * Получение значения input при мульти выборе
 * @param selectedSuggestions
 * @param allSuggestions
 * @param multiSuggestionValueText
 * @returns {string|*}
 */
function getMultiSuggestionValue(selectedSuggestions, allSuggestions, multiSuggestionValueText) {
  if (selectedSuggestions.length === 0) {
    return '';
  }

  if (selectedSuggestions.length === 1) {
    return selectedSuggestions[0].label;
  }

  const customValue = multiSuggestionValueMapping.get(multiSuggestionValueText)?.(selectedSuggestions.length);
  if (multiSuggestionValueText && customValue) {
    return `Выбрано ${selectedSuggestions.length} ${customValue}`;
  }

  return `Выбрано ${selectedSuggestions.length} из ${allSuggestions.length}`;
}

function getDefaultSuggestionsValue(props) {
  if (!props.value) {
    return props.isMulti ? [] : null;
  }

  return props.value;
}

function filterOptions(value) {
  return function (option) {
    return option.label.toLowerCase().includes(value.toLowerCase());
  };
}

/**
 * Получение state для компонента
 * @param props
 * @returns {{suggestion: (Array|null|*), inputValue: (string|*), suggestions: *}}
 */
function getState(props) {
  const suggestion = getDefaultSuggestionsValue(props);
  const suggestions = props.options;
  const inputValue = props.isMulti
    ? getMultiSuggestionValue(suggestion, suggestions, props.multiSuggestionValueText)
    : getSuggestionValue(suggestion);

  return {
    inputValue,
    suggestions,
    suggestion,
  };
}

function stopEvent(event) {
  // предотвращаем submit формы (при выборе через enter)
  // предотвращаем повторный фокус input (при выботе через клик)
  event.preventDefault();
  event.stopPropagation();
}

/* TODO избавиться после перехода на версию 3 фсз */
function getTimeScrollValue(options) {
  const defaultIndex = options?.findIndex((item) => item.label === '09:00');
  return defaultIndex > 0 ? defaultIndex * 30 + 5 : 0;
}

function SuggestionsContainer(props) {
  const { containerProps, children } = props;
  return <div {...containerProps}>{children}</div>;
}
class SuggestionsContainerWithScroll extends Component {
  #alreadyOpened = false;
  componentDidMount() {
    const node = ReactDOM.findDOMNode(this);
    const scrollValue = getTimeScrollValue(this.props.options);
    if (!this.#alreadyOpened) {
      node.scrollTop = scrollValue;
      this.#alreadyOpened = true;
    }
  }
  render() {
    return <SuggestionsContainer {...this.props} />;
  }
}

const renderCustomSuggestionsContainer = (isRouteTime, options) => (props) => {
  const { children } = props;

  return (
    <>
      {children && isRouteTime ? (
        <SuggestionsContainerWithScroll {...props} options={options} />
      ) : (
        <SuggestionsContainer {...props} />
      )}
    </>
  );
};
/* ------ */

function renderSuggestionIcon(inputProps) {
  if (inputProps.icon) {
    return React.cloneElement(inputProps.icon, {
      className: classnames(inputProps.icon.props?.className, styles.icon, styles.leadingIcon),
    });
  }
  return null;
}

class SimpleDropdown extends Component {
  state = getState(this.props);

  /**
   *  Меняем state если меняются props
   */
  static getDerivedStateFromProps(props, state) {
    if (!_isEqual(props.value, state.suggestion)) {
      return getState(props);
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /* Необходимо для обновление списка предложений если пришли новые опшны */
    if (!_isEqual(prevProps.options, this.props.options)) {
      this.setState({
        ...getState(this.props),
      });
    }
  }

  /**
   * Событие при изменении input
   * @param event
   * @param newValue
   */
  onChange = (event, { newValue }) => {
    const { onChange } = this.props;

    this.setState({
      inputValue: newValue,
    });

    if (onChange) {
      onChange();
    }
  };

  /**
   * События при снятии фокуса с input
   */
  onBlur = () => {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur();
    }
  };

  /**
   * Получение значений выпадающего списка
   * @param value
   */
  onSuggestionsFetchRequested = ({ value }) => {
    const { options, isSearchable } = this.props;

    if (value && isSearchable) {
      this.setState({
        suggestions: options.filter(filterOptions(value)),
      });
    } else {
      this.setState({
        suggestions: options,
      });
    }
  };

  /**
   * Получение значений выпадающего списка при мульти выборе
   */
  onMultiSuggestionsFetchRequested = () => {
    const { options } = this.props;

    this.setState({
      suggestions: options,
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  /**
   * Событие при выборе
   */
  onSuggestionSelected = (event, { suggestion }) => {
    const { onSelect } = this.props;
    stopEvent(event);
    this.setState({ suggestion }, () => onSelect(suggestion));
  };

  /**
   * Событие при мульти выборе
   */
  onMultiSuggestionSelected = (event, suggestionObject) => {
    const { onSelect } = this.props;
    const { suggestion } = this.state;
    const newSuggestion = suggestionObject.suggestion;

    const newSuggestionArray = suggestion.some((item) => _isEqual(item, newSuggestion))
      ? suggestion.filter((item) => !_isEqual(item, newSuggestion))
      : suggestion.concat(newSuggestion);

    stopEvent(event);

    this.setState(
      {
        suggestion: newSuggestionArray,
      },
      () => onSelect(newSuggestionArray),
    );
  };

  /**
   * Запись ссылки на input ref
   */
  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  renderOption = (option) => {
    return this.props.renderOption(option, this.state.inputValue);
  };

  render() {
    const {
      placeholder,
      isSearchable,
      showChevron,
      className,
      isMulti,
      disabled,
      input,
      renderSuggestionsContainer,
      multiSuggestionValueText,
    } = this.props;

    const { inputValue, suggestion, suggestions } = this.state;
    const isRouteTime = !!input?.name?.match(/route_time|TIME|time/g);
    const inputProps = {
      ...this.props,
      placeholder,
      value: inputValue,
      disabled,
      onChange: this.onChange,
      onBlur: this.onBlur,
      className: classnames(styles.input, className),
      // если мультивыбор, то input только на чтение
      readOnly: isMulti ? true : !isSearchable,
      icon: suggestion?.icon ?? null,
    };

    delete inputProps.onSelect;
    delete inputProps.isSearchable;
    delete inputProps.showChevron;
    delete inputProps.options;
    delete inputProps.renderOption;
    delete inputProps.isMulti;
    delete inputProps.renderSuggestionsContainer;
    delete inputProps.multiSuggestionValueText;

    const componentProps = {
      suggestions,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      inputProps,
      // показываем выпадающий список при пустом input
      shouldRenderSuggestions: () => true,
      focusInputOnSuggestionClick: false,
      ref: this.storeInputReference,
      renderInputComponent: (inputProps) => {
        return (
          <div className={styles.inputWrapper}>
            {renderSuggestionIcon(inputProps)}
            <Input {...inputProps} ref={inputProps.ref} />
            {showChevron && (
              <IconChevron
                width="20"
                height="20"
                onClick={() => this.input.focus()}
                className={classnames(styles.icon, styles.chevronIcon)}
              />
            )}
          </div>
        );
      },
      renderSuggestionsContainer,
    };

    return isMulti ? (
      <Autosuggest
        {...componentProps}
        renderSuggestion={(suggestionItem) => (
          <Multi label={suggestionItem.label} isChecked={suggestion.some((item) => _isEqual(item, suggestionItem))} />
        )}
        onSuggestionsFetchRequested={this.onMultiSuggestionsFetchRequested}
        onSuggestionSelected={this.onMultiSuggestionSelected}
        getSuggestionValue={() => getMultiSuggestionValue(suggestion, suggestions, multiSuggestionValueText)}
        shouldKeepSuggestionsOnSelect={() => true}
        focusInputOnSuggestionClick
      />
    ) : (
      <Autosuggest
        {...componentProps}
        renderSuggestion={this.renderOption}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestionsContainer={renderCustomSuggestionsContainer(isRouteTime, this.props?.options)}
      />
    );
  }
}

SimpleDropdown.defaultProps = {
  showChevron: true,
  isSearchable: true,
  isMulti: false,
  renderOption: Common,
  multiSuggestionValueText: undefined,
};

SimpleDropdown.propTypes = {
  // показывать ли шеврон в инпуте
  showChevron: PropTypes.bool,
  // callback при выборе значения
  onSelect: PropTypes.func.isRequired,
  // массив значений выпадающего списка
  options: PropTypes.array.isRequired,
  // выбранное значение
  // для одного значения объект
  // для мульти значений массив объектов
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  // вид выпадающего списка
  renderOption: PropTypes.func,
  // поиск по значениям выпадающего списка
  isSearchable: PropTypes.bool,
  // разрешает выбор нескольких значений
  isMulti: PropTypes.bool,

  // input props
  // кастомное значение, которое будет использоваться в инпуте (для множественного выбора)
  multiSuggestionValueText: PropTypes.string,
  // callback на изменение input
  onChange: PropTypes.func,
  // callback на снятие фокуса с input
  onBlur: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default SimpleDropdown;
