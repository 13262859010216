import React from 'react';
import SVGIcon from './index';

export default function IconAttentionOutline(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 45 45">
      <path
        fillRule="evenodd"
        d="M24 24h-3v-9h3v9zm0 6h-3v-3h3v3zm-1.485 7.5c8.28 0 14.985-6.72 14.985-15 0-8.28-6.705-15-14.985-15C14.22 7.5 7.5 14.22 7.5 22.5c0 8.28 6.72 15 15.015 15zm-.015-27c6.63 0 12 5.37 12 12s-5.37 12-12 12-12-5.37-12-12 5.37-12 12-12z"
      />
    </SVGIcon>
  );
}
