import parse from 'date-fns/parse';
import format from 'date-fns/format';
import formatTZ from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { toDate } from 'date-fns-tz';
import { DASHBOARD_DATE_TIME_FORMAT } from '../../../constants';
import { ruLocaleRelated } from '../../../constants/date-fns-ru-locale';

/**
 * Приведение значения из пикера к объекту даты со смещением по часовому поясу
 */
function getDateWithTimezone(dateString: Date, timeZone: string, sourceFormat?: string): Date;
function getDateWithTimezone(dateString: string, timeZone: string, sourceFormat: string): Date;
function getDateWithTimezone(dateString, timeZone, sourceFormat) {
  let parsedDate = dateString;
  if (
    !(dateString instanceof Date) &&
    !(typeof dateString === 'object' && Object.prototype.toString.call(dateString) === '[object Date]')
  ) {
    parsedDate = parse(dateString, sourceFormat, new Date());
  }
  const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");
  return toDate(formattedDate, { timeZone });
}

const _mutantFn = (data) => data;

function getZonedDatetime(datetime: string | Date, timeZone): Date {
  const isoString = new Date(datetime).toISOString();
  const zonedDate = utcToZonedTime(isoString, timeZone);
  return zonedDate;
}

function getZonedDateString(date, timeZone, format = DASHBOARD_DATE_TIME_FORMAT, mutantFn = _mutantFn) {
  const zonedDate = getZonedDatetime(date, timeZone);
  return formatTZ(mutantFn(zonedDate), format, { timeZone, locale: ruLocaleRelated });
}

/**
 * Замена таймзоны
 * Принимаются только даты в UTC (+00:00)
 * @param dateString
 * @param timeZone
 */
function replaceTimezone(dateString: string, timeZone: string) {
  return toDate(dateString, { timeZone });
}

export { getDateWithTimezone, getZonedDatetime, getZonedDateString, replaceTimezone };
