import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';

import VehicleIcon from '../../VehicleIcon';
import {
  AWNING_URL,
  DROPSIDE_URL,
  FULL_METAL_URL,
  ISOTHERMAL_URL,
  LORRY_URL,
  REFRIGERATOR_URL,
  TANKER_URL,
  TIMBER_CARRIER_URL,
  STEP_DECK_URL,
} from '../../../helpers/carTypesMapping';

const VEHICLES_TYPES = new Map()
  .set(AWNING_URL, 'Тент')
  .set(REFRIGERATOR_URL, 'Рефрижератор')
  .set(ISOTHERMAL_URL, 'Изотермический')
  .set(FULL_METAL_URL, 'Цельнометаллический')
  .set(DROPSIDE_URL, 'Бортовой')
  .set(TIMBER_CARRIER_URL, 'Лесовоз')
  .set(TANKER_URL, 'Автоцистерна')
  .set(STEP_DECK_URL, 'Автотрал');

export default function Vehicle(suggestion) {
  const bodyType =
    suggestion.value.bodyType && suggestion.value.bodyType.value
      ? suggestion.value.bodyType.value
      : suggestion.value.bodyType;
  const iconComponent = VehicleIcon({
    bodyType,
    vehicleType: suggestion.value.transportType,
    tonnage: suggestion.value.tonnage,
  });
  const icon = iconComponent?.props?.children?.[0] || React.Children.toArray(iconComponent)?.[0];
  const bodyTypeText = VEHICLES_TYPES.get(bodyType) || '';

  return (
    <div className={classnames(styles.suggestion, styles.vehicleSuggestion)}>
      <div className="flex column">
        <div>
          <span className={styles.title}>{suggestion.value.number}</span>
          <span className={styles.subtitle}>{suggestion.value.brand}</span>
        </div>
        {suggestion.value.transportType !== LORRY_URL && bodyTypeText && (
          <span className="caption color-base">{bodyTypeText}</span>
        )}
      </div>
      <span className={styles.iconWrapper}>{React.cloneElement(icon)}</span>
    </div>
  );
}
