import React from 'react';
import SVGIcon from './index';

const styles = {
  text: { fontSize: 10 },
};

function IconCommentaryUnread(props: React.SVGAttributes<HTMLOrSVGElement>);
function IconCommentaryUnread(props: React.SVGAttributes<HTMLOrSVGElement> & { count: number; maxCount: number });
function IconCommentaryUnread(props) {
  const { count, maxCount } = props;
  const text = count && count > maxCount ? `${maxCount}+` : count;

  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 2H20V15H16H4H0V2Z" fill="#0B5EDA" />
      <path d="M20 15L16 18V15H20Z" fill="#0048B3" />
      {!!text && (
        <text textAnchor="middle" x="10" y="12" fill="white" style={styles.text}>
          {text}
        </text>
      )}
    </SVGIcon>
  );
}

IconCommentaryUnread.defaultProps = {
  color: 'base',
  maxCount: 9,
};

export default IconCommentaryUnread;
