export const LOADING = 'loading';
export const UNLOADING = 'unloading';
export const PAPERWORK = 'paperwork';
// export const LOADING_UNLOADING = 'loading_unloading';

export type TTaskOnRoutePoint = typeof LOADING | typeof UNLOADING | typeof PAPERWORK;
/*   | typeof LOADING_UNLOADING */

/* Типы заказа */
export const SHIPPING_REQUEST = 'shipping_request';
export const CONTRACT = 'contract';
export const DELIVERY = 'delivery';
export const CITY_DELIVERY = 'city_delivery';

/* Типы партнерского контракта */
export const ALL = 'all';
export const BTS = 'bts';
export const MKT = 'mkt';

/* Вид графикового заказа */
export const CONTRACT_BY_COUNT = 'by_shippings_count';
export const CONTRACT_BY_SCHEDULE = 'by_schedule';
export const CONTRACT_WITH_QUOTES = 'by_quota';
