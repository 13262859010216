import React from 'react';
import SVGIcon from './index';

const IconEyeOff = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M2.92871 2.92883L1.86805 3.98949L3.95648 6.07793C2.65173 7.1027 1.62395 8.45186 1 10C2.41545 13.512 5.90909 16 10 16C11.1627 16 12.2771 15.799 13.3093 15.4307L16.0104 18.1318L17.071 17.0711L2.92871 2.92883ZM6.31675 8.43819C6.11283 8.91811 6 9.44596 6 10C6 12.208 7.792 14 10 14C10.554 14 11.0819 13.8872 11.5618 13.6832L9.87472 11.9962C8.86811 11.9343 8.0657 11.1319 8.00384 10.1253L6.31675 8.43819Z"
      />
      <path d="M10.125 8.00382L11.9962 9.87496C11.9344 8.86816 11.1318 8.06558 10.125 8.00382Z" />
      <path d="M14 10C14 10.5541 13.8871 11.082 13.6832 11.562L16.0434 13.9222C17.3482 12.8974 18.376 11.5482 19 10C17.5845 6.488 14.0909 4 10 4C8.83727 4 7.72279 4.20099 6.69056 4.56935L8.43803 6.31682C8.91799 6.11285 9.4459 6 10 6C12.208 6 14 7.792 14 10Z" />
    </SVGIcon>
  );
};

IconEyeOff.defaultProps = { color: 'base' };

export default IconEyeOff;
