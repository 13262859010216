import http from './config/webConfig';

/**
 * Получение списка тарифов и услуг
 * @returns {Promise<*>}
 */
export async function getCatalog() {
  const url = '/billing/catalog';
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получение списка подписок
 * @returns {Promise<*>}
 */
export async function getSubscriptions() {
  const url = '/billing/subscriptions';
  return http.get(url, { cache: false }).then((response) => response.data);
}

/**
 * Получение подписи по id
 * @param subscriptionsId
 * @returns {Promise<*>}
 */
export async function getSubscription(subscriptionsId) {
  const url = `/billing/subscriptions/${subscriptionsId}`;
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Активировать подписку
 * @returns {Promise<*>}
 */
export async function setSubscription(name, mode = null) {
  const url = '/billing/subscriptions';
  return http
    .post(url, { plan_name: name }, { cache: false, requestId: url, params: { mode } })
    .then((response) => response.data);
}

/**
 * Отключить подписку
 * @returns {Promise<*>}
 */
export async function cancelSubscription(subscriptionId) {
  const url = `/billing/subscriptions/${subscriptionId}`;
  return http.delete(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Проверить возможность списания кол-ва ресурса по подписке
 * @param unit - ресурс
 * @param amount - кол-во для списания, по умолчанию - 1 единица
 * @returns {Promise<*>}
 */
export async function checkSubscription(unit, amount = 1) {
  const url = '/billing/usages/check';
  return http.post(url, { unit, amount }, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получение статуса компании в биллинге
 * @returns {Promise<void>}
 */
export async function getCompanyBillingState() {
  const url = `/billing/account`;
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}
