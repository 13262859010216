export const COUNTER_AGENT = 'counter_agent';
export const WORKING_HOURS = 'working_hours';
export const CITY = 'city';
export const STREET = 'street';
export const HOUSE = 'house';
export const OFFICE = 'office';
export const CONTACTS = 'contacts';
export const NAME = 'full_name';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const EXTENSION_NUMBER = 'extension_number';
export const WAREHOUSE_NAME = 'warehouse_name';
export const WAREHOUSE_SCHEDULES = 'warehouse_schedules';
export const WAREHOUSE_SCHEDULES_DAY = 'day';
export const WAREHOUSE_SCHEDULES_WEEKEND = 'weekend';
export const IS_WAREHOUSE = 'is_warehouse';
export const WAREHOUSE_GATES = 'warehouse_gates';
export const WAREHOUSE_GATE_NAME = 'name';
export const WAREHOUSE_GATE_SCHEDULES = 'gate_schedules';
export const WAREHOUSE_GATE_SCHEDULES_DAY = 'day';
export const WAREHOUSE_GATE_SCHEDULES_FROM = 'from';
export const WAREHOUSE_GATE_SCHEDULES_TILL = 'till';
export const WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE = 'slot_size';
export const WAREHOUSE_GATE_SCHEDULES_PICKUP = 'pickup';
export const WAREHOUSE_GATE_SCHEDULES_PICKUP_FROM = 'pickup_from';
export const WAREHOUSE_GATE_SCHEDULES_PICKUP_TILL = 'pickup_till';
export const WAREHOUSE_GATE_SCHEDULES_WEEKEND = 'weekend';

export const GATE_ID_LIST = 'gate_id';

/**
 * служебные переменные
 */
export const HAS_SCHEDULE = 'has_schedule';
export const PICKUP_HOURS = 'pickup_hours';
export const WEEKDAY = 'weekday';
export const IS_WHOLE_DAY = 'is_whole_day';
export const PERIOD = 'period';

export const IS_PERSON_INDIVIDUAL = 'private_person';
export const PERSON_FULL_NAME = 'private_person_full_name';
export const PASSPORT_TYPE = 'private_person_passport_type';
export const PASSPORT_NUMBER = 'private_person_passport_number';
export const PASSPORT_ISSUER = 'private_person_passport_issuer';
export const PASSPORT_ISSUED_AT = 'private_person_passport_issued_at';

export const LEGAL_ENTITY_TITLE = 'legal_entity_title';
export const LEGAL_ENTITY_FULL_TITLE = 'legal_entity_full_title';
export const LEGAL_ENTITY_INN = 'legal_entity_inn';
export const LEGAL_ENTITY_KPP = 'legal_entity_kpp';
export const LEGAL_ENTITY_FORM = 'legal_entity_opf_name';

/**
 * Типы паспортов
 */
export const PASSPORT = 'passport';
export const DRIVER_LICENCE = 'driver_license';
export const INTERNATIONAL_PASSPORT = 'international_passport';
export const MILITARY_IDENTITY = 'military_identity';
export const USSR_PASSPORT = 'ussr_passport';
export const IDENTITY_KAZAKHSTAN = 'identity_kazakhstan';
export const FOREIGN_CITIZEN_PASSPORT = 'foreign_citizen_passport';
export const SAILOR_PASSPORT = 'sailor_passport';
export const REFUGE_REQUEST_CERTIFICATE = 'refuge_request_certificate';
export const SERVICE_PASSPORT = 'service_passport';
export const DIPLOMATIC_PASSPORT = 'diplomatic_passport';
export const RESIDENCE_REQUEST = 'residence_request';
export const TEMPORARY_REFUGE_CERTIFICATE = 'temporary_refuge_certificate';
export const FOREIGN_DRIVER_LICENSE = 'foreign_driver_license';
