import { createSelector, defaultMemoize } from 'reselect';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants';
import { WORK_RELATIONSHIP_DOC_TYPE } from '../company/constants';

const getTypeList = (state, entity) => {
  return get(state, `documents.${entity}.types`, []);
};

const getDocumentList = (state, entity) => {
  return get(state, `documents.${entity}.documents`, []);
};

const getUnLoadedFiles = (state, entity) => {
  return get(state, `documents.${entity}.unLoadedFiles`, []);
};

const getLoadingTypes = (state, entity) => {
  return get(state, `documents.${entity}.loadingTypes`, []);
};

export const getFilesInType = (state, entity) => {
  return get(state, `documents.${entity}.filesInType`, []);
};

export const getCompanyDocumentList = defaultMemoize((state) => state?.documents?.company?.documents ?? []);

/**
 * Получить список типов документов
 */
export const getTypes = createSelector([getTypeList], (types) => types);

/**
 * Получить список документов
 */
export const getDocuments = createSelector([getDocumentList], (doc) => doc);

/**
 * Получить список файлов для
 * документа
 */
export const getFilesByDocumentId = createSelector([getDocumentList], (documents) =>
  memoize((documentId) => {
    const res = documents.filter((doc) => doc.id === documentId).map((doc) => [...doc.items]);
    return res.length > 0 ? res[0] : [];
  }),
);

/**
 * Получить документы по типу
 */
export const getDocumentsByTypeId = createSelector([getDocumentList], (documents) =>
  memoize((document_type_id) => documents.filter((doc) => doc.document_type_id === document_type_id)),
);

/**
 * Получить срок действия по файлу РСВ
 */
export const getWorkRelationshipFileInfo = createSelector(getCompanyDocumentList, (documentList) => {
  return documentList.find((doc) => doc.typeId === WORK_RELATIONSHIP_DOC_TYPE)?.files?.[0]?.insurancePremiumsInfo;
});

/**
 * Получить id файла РСВ
 */
export const getWorkRelationshipFileId = createSelector(getCompanyDocumentList, (documentList) => {
  return documentList.find((doc) => doc.typeId === WORK_RELATIONSHIP_DOC_TYPE)?.files?.[0]?.id;
});

/**
 * Получить список не загруженных файлов
 * для типа или документа
 */
export const getUnLoadedFilesTypeIdOrDocumentId = createSelector([getUnLoadedFiles], (files) =>
  memoize((typeId, documentId) => files.filter((file) => file.typeId === typeId || file.documentId === documentId)),
);

/**
 * Получить статус загрузки для текущего типа документов
 */
export const getLoadingByTypeId = createSelector([getLoadingTypes], (loadings) =>
  memoize((typeId) => {
    if (loadings[typeId]) {
      return loadings[typeId];
    }
    return false;
  }),
);

/**
 * Агрегирование данных о документах,
 * файлах и типах документов
 */
export const typeDocumentsMap = createSelector(
  [getTypeList, getDocuments, getLoadingTypes, getUnLoadedFiles],
  (types, documents, loadings, errorFiles) => {
    return types.map((type) => {
      const info = {
        key: type.id,
        typeId: type.id,
        updatedAt: type.updatedAt ? moment(type.updatedAt).format(DATE_FORMAT) : undefined,
        isActiveScanCopy: type.isActiveScanCopy ? type.isActiveScanCopy : false,
        isAccepted: type.isAccepted ? type.isAccepted : false,
        typeDescription: type.description,
        typeName: type.name,
        documentId: null,
        loading: !!loadings[type.id],
        required: type.required,
        files: [],
        readOnly: type.readOnly,
        errorFiles: errorFiles.filter((file) => file.typeId === type.id),
      };
      const doc = documents.filter((document) => document.typeId === type.id);
      if (doc.length > 0) {
        info.documentId = doc[0].id ? doc[0].id : 0;
        info.files = doc[0].files ? doc[0].files : [];
      }
      return info;
    });
  },
);

export const getItemsForPrintableForm = defaultMemoize((shippingId, humanFriendlyId = 'doc') => [
  {
    name: 'Печатная форма заявки',
    typeId: 1,
    files: [
      {
        isSecure: true,
        fileName: `Печатная форма заявки №${humanFriendlyId}.pdf`,
        id: shippingId,
        singedUrl: `/shippings/${shippingId}/scancopies/template`,
      },
    ],
  },
]);

/**
 * Проверка: наличия хотя бы одного файла
 * в каждом из типов документов
 */
export const isTypesReady = createSelector([getTypeList, getFilesInType], (types, files) =>
  types
    .filter((type) => !type.readOnly)
    .filter((type) => type.required)
    .every((type) => files[type.id]),
);

/**
 * Проверка: наличия хотя бы одного файла
 * в каждом из типов документов
 * с учетом дополнительных фильтров
 */
export const isTypesReadyByCustomFilter = createSelector([typeDocumentsMap, getFilesInType], (types, files) => {
  if (!types.length) return () => false;
  return memoize((filterCallback) =>
    types
      .filter((type) => !type.readOnly)
      .filter((type) => type.required)
      .filter(filterCallback)
      .every((type) => files[type.typeId]),
  );
});

export const isScanReady = createSelector([getTypeList, getFilesInType], (types, files) => {
  const scanCopy = types.find((type) => type.isActiveScanCopy);
  return !!(scanCopy && scanCopy.id && files[scanCopy.id] && parseInt(files[scanCopy.id]) > 0);
});

export const activeScanCopy = createSelector([getDocuments], (documents) =>
  documents.find((document) => document.status === 'new'),
);

export const activeOnlineScanCopy = createSelector([getDocuments], (documents) => {
  const doc = documents.find((document) => document.status === 'new');
  if (doc) {
    return doc;
  }
  return documents.find((document) => document.status === 'accepted');
});
