import qs from 'query-string';

type TBase = 'order-list' | 'contracts' | 'shippings' | 'claims' | 'counter-agents/locations' | 'scheduler' | string;

type TParams = { isList?: boolean } & qs.StringifyOptions;

export function getUrlString(url: TBase, query: Record<string, any> = {}, params?: TParams): string {
  if (params?.isList) {
    if (!query['page']) query['page'] = 1;
    if (!query['per_page']) query['per_page'] = 20;
  }

  return '/' + qs.stringifyUrl({ url, query }, params);
}
