import { createSelector, defaultMemoize } from 'reselect';
import _get from 'lodash/get';
import _values from 'lodash/values';
import _isEqual from 'lodash/isEqual';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_SHORT,
  DEFAULT_APP_TIME_ZONE,
  INSTANT,
  SHORT_DATE_FORMAT,
  TIME_FORMAT,
} from '../../constants';
import moment from 'moment/moment';
import * as pageSelectors from '../page/selectors';
import * as graphicsSelectors from '../graphics/selectors';
import * as queryKeys from './queryKeys';
import { ALL_SCANCOPIES_OBJ, NEED_TO_SIGN_SCANCOPIES_OBJ } from './queryKeys';
import {
  AWNING_OBJ,
  BOARD_OBJ,
  CONTAINER_OBJ,
  FULL_METAL_OBJ,
  ISOTHERMAL_OBJ,
  REFRIGERATOR_OBJ,
  TANKER_OBJ,
  TIMBER_CARRIER_OBJ,
} from '../resources/bodyTypeOptions';
import getFormattedPhone from '../../helpers/getFormattedPhone';
import getFormattedPrice from '../../helpers/getFormattedPrice';

import {
  checkCityRoute,
  getCargoDimensions,
  getClientCategoryTranslation,
  getDateFormatted,
  getDateWithTime,
  getDistanceBySegments,
  getMaxBid,
  getMinBid,
  getMinTonnageVolume,
  getPoints,
  getRestrictions,
  getRoutePoints,
  getSupplyDateForShippingList,
  getSupplyDateForSuggestionList,
  isItemWithStatus,
} from '../../helpers/mappers/shipping';
import { getSortedBodyTypes, LORRY_URL, TRUCK_URL } from '../../helpers/carTypesMapping';
import * as formKeys from '../order/formalization/formKeys';
import * as auctionFormKeys from '../order/auction/formKeys';
import { PACKAGE_ITEMS } from '../order/cargo/formFields';
import { declinationClosingPeriod } from '../../helpers/declinationOfNumbers';
import { CAR_FIELD, DRIVER_FIELD, PHONE_FIELD, TRAILER_FIELD } from './reserved/formFields';
import getPaymentForm from '../../helpers/getPaymentForm';
import { CITY_DELIVERY, DELIVERY } from '../order/routes/formKeys';

export const getSuggestions = defaultMemoize((store) => _get(store, 'items', []));

/**
 * Получение данных о забронированной перевозке
 */
export const getShippingPreviewItem = defaultMemoize((item) => {
  const shippingRequestInfo = _get(item, 'shipping_request_info');
  const selectedCarSupply = _get(item, 'selected_car_supply', []);
  const mappedShippingItem = getMappedShippingItem(shippingRequestInfo, selectedCarSupply);

  const reservedId = _get(item, 'id');
  const cargoOwnerId = _get(shippingRequestInfo, 'cargo_owning_company_id');
  const managerInfo = item?.contact;
  const changeRequestsAllowed = shippingRequestInfo?.change_requests_allowed;

  const isResourcesWaiting = isItemWithStatus(item, queryKeys.RESOURCES_WAITING);
  const isApprovalWaiting = isItemWithStatus(item, queryKeys.APPROVAL_WAITING);
  const isTripWaiting = isItemWithStatus(item, queryKeys.TRIP_WAITING);
  const isInWay = isItemWithStatus(item, queryKeys.IN_WAY);
  const isStatusDone = isItemWithStatus(item, queryKeys.DONE);
  const isStatusCanceled = isItemWithStatus(item, queryKeys.CANCELED);
  const isStatusCanceledByCO = isItemWithStatus(item, queryKeys.CANCELED_BY_CARGO_OWNER);
  const isStatusCanceledByNTK = isItemWithStatus(item, queryKeys.CANCELED_BY_NTK);
  const isStatusFailedByCO = isItemWithStatus(item, queryKeys.FAILED_BY_CARGO_OWNER);
  const isStatusFailedByNTK = isItemWithStatus(item, queryKeys.FAILED_BY_NTK);

  const isWorkStatus = isTripWaiting || isInWay;
  const isNeedAttorney = shippingRequestInfo?.attorney_requirement !== null;
  const hasAttorney = item?.attorney_signed_key !== null;

  const selectedSupply = _getSelectedSupply(selectedCarSupply, _get(shippingRequestInfo, 'route_points', []));
  const selectedSupplyDate = item?.selected_car_supply?.[0]?.car_supply_at;
  const selectedDateFormatted = getDateWithTime(selectedSupplyDate);

  /* Период по документам */
  const docsPeriod = _get(shippingRequestInfo, 'closing_documents_period');
  const docsPeriodType = _get(shippingRequestInfo, 'closing_documents_period_type', null);

  // Период оплаты в днях
  const paymentPeriod = _get(shippingRequestInfo, 'payment_period');
  const paymentPeriodType = _get(shippingRequestInfo, 'payment_period_type');

  /* Получаем значение времени меньше 1 часа для неназначенных ресурсов (по мск) */
  const isResourceDeadlineWarning =
    moment.utc(_get(item, 'resource_assignment_deadline')).diff(moment.utc(), 'hours') < 1;
  const scanCopyDeadline = _get(item, 'scancopy_attachment_deadline');
  /* Окончание времени на прикрепление (подписание) */
  const isScanCopyDead = moment.utc(scanCopyDeadline).diff(moment.utc(), 'm') <= 0;
  /* Время обновления статуса */
  const scanCopyStatusUpdatedAt = moment
    .utc(_get(item, 'status_updated_at'))
    .add(DEFAULT_APP_TIME_ZONE, 'h')
    .format(DATE_TIME_FORMAT);

  // Переобределение даты для списка
  // если дата уже выбрана
  if (mappedShippingItem.isInterval) {
    let firstPointId = selectedSupply.find((supply) => supply?.routePointId === 1);
    const firstSelectedDate = firstPointId?.dateTime;

    if (firstSelectedDate) {
      mappedShippingItem.dateFormatted = getDateWithTime(firstSelectedDate);
    }
  }

  //---------------------------------

  // получение ссылки на график
  const graphicLink = graphicsSelectors.getGraphicUrl(_get(item, 'contract') || null);

  //-----------------------------------------
  // TODO: если заявка графиковая, то снятие флага о аукционе
  //  (убрать, когда у бека будет доработка)
  let auctionType = mappedShippingItem.auctionType;

  if (_get(item, 'contract_id')) {
    auctionType = INSTANT;
  }
  //-----------------------------------------

  return {
    ...mappedShippingItem,
    humanFriendlyId: _get(item, 'human_friendly_id'),
    reservedId,
    // для забронированных listId равен id перевозки
    listId: _get(item, 'id'),
    cargoOwnerId,
    shippingRequestId: _get(item, 'shipping_request_id'),
    graphicLink,
    graphicId: _get(item, 'contract_id'),
    graphicHumanFriendlyId: _get(item, 'contract_hfid'),

    isExpeditedPayment: !!_get(item, 'expedited_payment_days'),
    expeditedPaymentDays: _get(item, 'expedited_payment_days'),

    dateFormatted: mappedShippingItem.dateFormatted ? mappedShippingItem.dateFormatted : selectedDateFormatted,
    docsForPayment: _get(item, 'shipping_request_info.documents_for_payment', []),
    closingDocs: _get(item, 'shipping_request_info.closing_documents', []),
    closingDocsPeriod: _getPeriodTranslation(docsPeriod, docsPeriodType),
    closingDocsPeriodRaw: docsPeriod,
    closingDocsPeriodType: docsPeriodType,
    closingDocsStatus: item?.closing_status ?? null,

    auctionType,

    completedAt: _get(item, 'completed_at'),
    documentFlow: _get(item, 'document_flow'),
    assignmentTime: _getAssignmentTime(shippingRequestInfo),
    idleTimeWithoutVat: _get(shippingRequestInfo, 'idle_time_cost_without_vat'),
    penaltyCancelTime: _get(shippingRequestInfo, 'cancellation_without_penalty_before')
      ? moment(_get(shippingRequestInfo, 'cancellation_without_penalty_before')).format(DATE_TIME_FORMAT_SHORT)
      : null,
    minimumPenalty: _get(shippingRequestInfo, 'minimum_cancellation_penalty_without_vat'),
    payerName: _get(shippingRequestInfo, 'payer_name'),
    paymentForm: getPaymentForm(shippingRequestInfo?.payment_form),
    forwardRequestNumber: _get(shippingRequestInfo, 'forward_request_number'),
    paymentProcedure: _getPaymentTranslation(_get(shippingRequestInfo, 'payment_procedure')),
    paymentDocsPeriod: paymentPeriod && _getPeriodTranslation(paymentPeriod, paymentPeriodType),

    isScanCopyError: _getIsScanCopyError(item),
    isScanCopyDead,
    scanCopyStatus: _get(item, 'scancopy_status'),
    scanCopyStatusComment: _get(item, 'scancopy_status_comment'),
    scanCopyDeadline,
    scanCopyStatusUpdatedAt,

    isStatusDone,
    isStatusCanceled,
    isStatusCanceledByCO,
    isStatusCanceledByNTK,
    isStatusFailedByCO,
    isStatusFailedByNTK,
    isTripWaiting,
    isInWay,
    isResourcesWaiting,
    isApprovalWaiting,
    isWorkStatus,
    isNeedAttorney,

    hasAttorney,
    managerInfo,

    isReservationRequest: _getIsReservationRequest(item),

    assignResources: getAssignResources(item),

    selectedSupply,
    // переопределяем время прибытия
    routePoints: _mergeSelectedTimeWithPointTime(selectedSupply, mappedShippingItem.routePoints),

    resourceDeadline: _get(item, 'resource_assignment_deadline'),
    requirementsChangesDeadline: _get(item, 'requirements_change_deadline'),
    isRequirementsChangesExpired: moment.utc().isAfter(moment.utc(_get(item, 'requirements_change_deadline'))),
    resourceDeadlineExpired: moment.utc(_get(item, 'resource_assignment_deadline')),
    isResourceDeadlineWarning,
    partnership: _get(item, 'partnership'),

    // Разрешена ли смена ресурсов
    changeRequestsAllowed,
    // Последний запрос на смену ресурсов
    requestToChangeResources: item?.requestToChangeResources,
    // Статус последнего запроса на изменение требований
    shippingChangeRequestStatus: item?.change_request_status,
    // Статус последнего запроса на изменение ресурсов
    resourceChangeRequestStatus: item?.change_resources_request_status,

    changeRequestList: item?.changeRequestList || [],
    resourceChangeRequestList: item?.resourceChangeRequestList || [],
    shippingChangeRequestList: item?.shippingChangeRequestList || [],

    // Информация о контракте, частью которого является перевозка
    contractShippingId: item?.contract_shipping_id ?? null,
    shippingOrderContractId: item?.shipping_order_contract_id ?? null,
    shippingOrderContractHFId: item?.shipping_order_contract_hfid ?? null,

    shippingStatus: item?.status?.code_name,
    statusComment: item?.status?.comment ?? null,

    plannedPaymentDate: item.planned_payment_date,
    actualPaymentDate: item.actual_payment_date,
    serviceRealizationNumber: item?.service_realization_number,

    closingTtdDocumentsAcceptedAt: item.closing_ttd_documents_accepted_at,
    closingTtdDocumentsRejectedAt: item.closing_ttd_documents_rejected_at,
    closingTtdDocumentsRejectionComment: item.closing_ttd_documents_rejection_comment,

    closingTtdForwarderDocumentsAcceptedAt: item.closing_ttd_forwarder_documents_accepted_at,
    closingTtdForwarderDocumentsRejectedAt: item.closing_ttd_forwarder_documents_rejected_at,
    closingTtdForwarderDocumentsRejectionComment: item.closing_ttd_forwarder_documents_rejection_comment,

    closingBdDocumentsAcceptedAt: item.closing_bd_documents_accepted_at,
    closingBdDocumentsRejectedAt: item.closing_bd_documents_rejected_at,
    closingBdDocumentsRejectionComment: item.closing_bd_documents_rejection_comment,

    finalPrice: item?.final_price,

    _original: item,
  };
});

/* Перевод с учетом кол-ва */
function _getPeriodTranslation(period, periodType) {
  return `${period ? period : ''} ${declinationClosingPeriod(period, periodType)}`.trim();
}

function _getPaymentTranslation(payment) {
  return payments.get(payment) || '';
}

function _getAssignmentTime(shipping) {
  const time = _get(shipping, 'resource_assignment_time', null);
  if (time) {
    let hours = Math.floor(time / 60);
    let minutes = time % 60;

    return `${hours} ч ${minutes ? minutes : '00'} мин`;
  }
}

const payments = new Map()
  .set(formKeys.PREPAYMENT, 'Предоплата')
  .set(formKeys.PAYMENT_BY_SCANCOPY, 'Оплата по скан-копиям документов')
  .set(formKeys.PAYMENT_BY_ORIGIN_DOCS, 'Оплата по оригиналам документов')
  .set(formKeys.PAYMENT_BY_UNLOADING, 'Оплата по факту выгрузки')
  .set(formKeys.PAYMENT_BY_LOADING, 'Оплата по факту загрузки');

/**
 * Получаем объект route points с выбранным значением времени
 * @param selectedSupply
 * @param routePoints
 * @returns {*}
 * @private
 */
function _mergeSelectedTimeWithPointTime(selectedSupply, routePoints) {
  return routePoints.map((point) => {
    const isTimeAssignedPoint = selectedSupply.find((item) => item?.routePointId === point.id);
    return {
      ...point,
      arrival: isTimeAssignedPoint ? [`${isTimeAssignedPoint.date} ${isTimeAssignedPoint.time}`] : point.arrival,
    };
  });
}

/**
 *  получение данных о статусе сканкопии
 */
const _getIsScanCopyError = defaultMemoize((item) => {
  const scanCopyStatus = _get(item, 'scancopy_status');

  return (
    (isItemWithStatus(item, queryKeys.TRIP_WAITING) ||
      isItemWithStatus(item, queryKeys.IN_WAY) ||
      isItemWithStatus(item, queryKeys.DONE)) &&
    (scanCopyStatus === queryKeys.NOT_ATTACHED ||
      scanCopyStatus === queryKeys.NEED_RESIGN ||
      scanCopyStatus === queryKeys.REJECTED)
  );
});

/**
 *  получение прикрепленных ресурсов
 */
export const getAssignResources = defaultMemoize((item) => {
  const assignedResources = _get(item, 'assigned_resource_objects', null);
  const drivers = _get(assignedResources, 'drivers', []);
  const cars = _get(assignedResources, 'cars', []);
  const trailers = _get(assignedResources, 'trailers', []);
  const isAssignedResources =
    assignedResources && _values(assignedResources).some((resource) => resource && resource.length > 0);

  // проверка приходят ли данные об assigned resources
  if (!item || !isAssignedResources) return null;

  return {
    carsNumbers: cars.map((car) => _get(car, 'car_number', '')),
    car: _get(assignedResources, 'cars[0]')
      ? `${_get(assignedResources, 'cars[0].car_number')} ${_get(assignedResources, 'cars[0].brand')}`
      : null,
    carBodyType: _get(assignedResources, 'cars[0].lifting_capacity.transport_body_type'),
    trailersNumbers: trailers.map((trailer) => _get(trailer, 'trailer_number', '')),
    trailer: _get(assignedResources, 'trailers[0]')
      ? `${_get(assignedResources, 'trailers[0].trailer_number')} ${_get(assignedResources, 'trailers[0].brand')}`
      : null,
    trailerBodyType: _get(assignedResources, 'trailers[0].lifting_capacity.transport_body_type'),
    drivers: drivers.map((driver) => _get(driver, 'full_name')),
    driverPhone: getFormattedPhone(`${_get(item, 'assigned_resources[0].driver_contact_info')}`),
  };
});

/**
 * Получение данных о перевозке
 */
export function getMappedShippingItem(item, selectedCarSupply = []) {
  const shippingId = item?.id ?? null;
  const price = getFormattedPrice(item?.price || '');
  const expeditedPaymentPrice = (item?.price ?? 0) - (item?.expedited_payment_fee ?? 0);
  const dateFormatted = getDateFormatted(item);
  const supplyDateForSuggestionList = getSupplyDateForSuggestionList(item);
  const supplyDateForShippingList = getSupplyDateForShippingList(item, selectedCarSupply);
  const { tonnage, volume } = getMinTonnageVolume(item);
  const currentBid = (item?.bids || []).find((bid) => bid?.status === 'current');
  const previousBid = item?.bids?.reduce((acc, curr) => {
    if (!acc || moment(curr?.updated_at).isSameOrAfter(acc?.updated_at)) {
      acc = curr;
    }
    return acc;
  }, null);

  const auctionType = item?.auction_type ?? null;

  /* Данные по документам */
  const docsPeriod = item?.closing_documents_period ?? null;
  const docsPeriodType = item?.closing_documents_period_type ?? null;

  const paymentPeriod = item?.payment_period ?? null;
  const paymentPeriodType = item?.payment_period_type ?? null;

  const documentFlow = item?.document_flow ?? null;

  const requirementsChange = item?.tc_requirements_changes;

  const isAuction = [
    auctionFormKeys.OPEN_AUCTION,
    auctionFormKeys.DESCENDING_PRICE_AUCTION,
    auctionFormKeys.BLIND_AUCTION,
  ].includes(auctionType);

  let contractParams = {};
  const isContractType = item?.type === 'contract';
  const contractsDateFrom = item?.contract_begins_at ?? null;
  const contractsDateTill = item?.contract_ends_at ?? null;
  const contractsDates =
    contractsDateFrom && contractsDateTill
      ? `${moment(contractsDateFrom).format(SHORT_DATE_FORMAT)} - ${moment(contractsDateTill).format(
          SHORT_DATE_FORMAT,
        )}`
      : '—';
  if (isContractType) {
    contractParams = {
      contractId: item?.contract_id ?? null,
      contractHFId: item?.contract_hfid ?? null,
      contractType: item?.contract_type ?? null,
      contractsCount: item?.shippings_count ?? null,
      contractsPerDay: item?.max_shippings_per_day ?? null,
      contractsPerWeek: item?.max_shippings_per_week ?? null,
      manyExecutorsAllowed: item?.many_executors_allowed ?? false,
      shippingAvailableIn: item?.shipping_available_in ?? null,
      contractsDateFrom,
      contractsDateTill,
      contractsDates,
    };
  }

  // FIXME временно выключает для этих типов заказа
  const isExpeditedPaymentAvailable = [CITY_DELIVERY, DELIVERY].includes(item?.type)
    ? false
    : item?.expedited_payment_available ?? false;

  return {
    shippingId,
    id: shippingId,
    shippingOrderId: item?.shipping_order_id ?? null,
    listId: getShippingListId(item),
    humanFriendlyId: item?.human_friendly_id ?? null,
    externalHumanFriendlyId: item?.external_human_friendly_id ?? null,
    cargoOwnerCompanyId: item?.cargo_owning_company_id,
    partnerContractType: item?.partnership_contract_type,

    points: getPoints(item),
    routePoints: getRoutePoints(item),

    tonnage,
    volume,
    bodyTypes: _getMappedBodyType(item),
    vehicleType: item?.resource_requirements?.transport_type,
    offerDeadline: item?.offer_deadline ?? null,
    rawPrice: item?.price ?? null,
    price,
    multipolisInsurancePrice: item?.multipolis_insurance_price,
    expeditedPaymentPrice,
    maxExpeditedPaymentDays: item?.max_expedited_payment_days,
    expeditedPaymentFee: item?.expedited_payment_fee,
    auctionType,
    dateFormatted,
    isDirectCargoOwner: item?.direct_cargo_owner || false,
    supplyDateForSuggestionList,
    supplyDateForShippingList,
    distance: getDistanceBySegments(item?.route_segments ?? []),

    orderType: item?.type ?? '',

    isHourlyRent: item?.payment_type === 'per_hour',
    minWorkHours: item?.min_work_hours ?? 0,

    isAuction,
    isCircular: item?.circular_route,
    isCityRoute: checkCityRoute(item),
    isInterval: item?.is_interval,
    isReservationRequest: _getIsReservationRequest(item),
    isRequirementsChange: Array.isArray(requirementsChange) && requirementsChange.length > 0,
    isChangeRequestLimit: Array.isArray(requirementsChange) && requirementsChange.length >= 3,
    isSuggestionChangeWarn: Array.isArray(requirementsChange) && requirementsChange.length === 2,
    isExpeditedPaymentAvailable,
    isNeedAttorney: item?.attorney_requirement !== null,

    category: item?.client_category,
    intermediateResultsAt: item?.target?.expires_at ?? item?.intermediate_results_at,

    bid: {
      currentBidId: currentBid?.id,
      isWinning: item?.is_bid_winning,
      isExpeditedPayment: Boolean(currentBid?.expedited_payment_days),
      expeditedPaymentDays: currentBid?.expedited_payment_days ?? null,

      step: item?.bid_step,
      currentBid: getFormattedPrice(currentBid?.value),
      currentBidRaw: (currentBid?.value ?? 0) - (currentBid?.expedited_payment_fee ?? 0),
      currentShippingsCount: currentBid?.shippings_count ?? null,
      currentMinWorkHours: currentBid?.min_work_hours ?? null,
      initialBid: item?.initial_bid,
      winningBid: item?.winning_bid,

      max: item?.max_bid,
      min: item?.min_bid,
      maxCount: getMaxBid(item, item?.max_bid),
      minCount: getMinBid(item, item?.min_bid),

      // TODO remove useless logic
      // expressMax: item?.expedited_payment_max_bid,
      // expressMin: item?.expedited_payment_min_bid,
      // expressInitialBid: item?.expedited_payment_initial_bid,
      // expressMaxCount: getMaxBid(item, item?.expedited_payment_max_bid, true),
      // expressMinCount: getMinBid(item, item?.expedited_payment_min_bid, true),
      expeditedPaymentWinningBid: item?.expedited_payment_winning_bid,
      lastArchiveBid: previousBid?.status === 'cancelled' ? previousBid?.value : null,

      _original: currentBid,
    },
    rebidding: item?.rebidding ?? false,

    additionalInfo: {
      category: getClientCategoryTranslation(item?.client_category),
      cargoDescription: item?.cargo_description,
      cargoTonnage: item?.cargo_tonnage,
      cargoVolume: item?.cargo_volume,
      cargoAdr: item?.cargo_adr,
      cargoCost: getFormattedPrice(item?.cargo_cost),
      cargoPacking: PACKAGE_ITEMS.get(item?.cargo_packing) || '',
      cargoPlaces: item?.cargo_places,
      comment: item?.comment ?? null,
      dimensions: getCargoDimensions(item),
      restrictions: getRestrictions(item),
    },

    idleTimeWithoutVat: item?.idle_time_cost_without_vat,
    penaltyCancelTime: item?.cancellation_without_penalty_before
      ? moment(item?.cancellation_without_penalty_before).format(DATE_TIME_FORMAT_SHORT)
      : null,

    penaltyPercent: item?.cancellation_penalty_percent,
    minimumPenalty: item?.minimum_cancellation_penalty_without_vat,
    payerName: item?.payer_name,
    paymentForm: getPaymentForm(item?.payment_form),
    forwardRequestNumber: item?.forward_request_number,
    paymentProcedure: _getPaymentTranslation(item?.payment_procedure),
    paymentDocsPeriod: paymentPeriod && _getPeriodTranslation(paymentPeriod, paymentPeriodType),
    assignmentTime: _getAssignmentTime(item),
    resourceDeadlineOption: _get(item, 'resource_deadline_option'),
    documentFlowOptions: item?.document_flow,
    documentFlow,
    agreementType: item?.agreement_type,
    docsForPayment: item?.documents_for_payment,
    closingDocs: item?.closing_documents ?? [],
    closingDocsPeriod: _getPeriodTranslation(docsPeriod, docsPeriodType),
    closingDocsPeriodRaw: docsPeriod,
    closingDocsPeriodType: docsPeriodType,
    payer: item?.payer,

    createdAt: moment(item?.created_at).format(SHORT_DATE_FORMAT),

    partnership: item?.partnership,
    contact: item?.contact,
    partnershipContractTerms: item?.terms_by_partnership_contract ?? false,
    partnerTariff: item?.partner_tariff,
    isContractType,
    isWinSelection: item?.status?.code_name === queryKeys.WINNER_SELECTION,
    withDynamicPricing: item?.dynamic_pricing ?? false,
    ...contractParams,

    _original: item,
  };
}

/**
 *  Получение объектов прикрепленных ресурсов для заполнения формы
 */
export const getAssignResourcesObj = defaultMemoize((item) => {
  const assignedResources = _get(item, 'assigned_resource_objects', null);
  const drivers = _get(assignedResources, 'drivers', []);
  const isAssignedResources =
    assignedResources && _values(assignedResources).some((resource) => resource && resource.length > 0);

  // проверка приходят ли данные об assigned resources
  if (!item || !isAssignedResources) return null;

  const car = _get(assignedResources, 'cars[0]')
    ? {
        value: {
          id: _get(assignedResources, 'cars[0].id'),
          number: _get(assignedResources, 'cars[0].car_number'),
          transportType: _get(assignedResources, 'cars[0].kind') === 'ResourceCarTruck' ? TRUCK_URL : LORRY_URL,
          vehicleDimensions: assignedResources?.cars?.[0]?.dimensions_vehicle,
        },
        label: `${_get(assignedResources, 'cars[0].car_number')} ${_get(assignedResources, 'cars[0].brand')}`,
      }
    : null;

  const trailer = _get(assignedResources, 'trailers[0]')
    ? {
        value: {
          id: _get(assignedResources, 'trailers[0].id'),
          number: _get(assignedResources, 'trailers[0].trailer_number'),
          vehicleDimensions: assignedResources?.trailers?.[0]?.dimensions_vehicle,
        },
        label: `${_get(assignedResources, 'trailers[0].trailer_number')} ${_get(
          assignedResources,
          'trailers[0].brand',
        )}`,
      }
    : null;

  const driver = (driver) => {
    return {
      value: {
        id: _get(driver, 'id'),
        phone: _get(driver, 'driver_secret_info.phones[0]'),
        inn: _get(driver, 'driver_secret_info.inn'),
        isNeedPassportSubdivisionCode:
          _get(driver, 'driver_secret_info.common.country_id') === 1 &&
          !_get(driver, 'driver_secret_info.doc.subdivision_code'),
      },
      label: _get(driver, 'full_name'),
    };
  };

  return {
    [CAR_FIELD]: car,
    [TRAILER_FIELD]: trailer,
    [DRIVER_FIELD]: drivers.map(driver),
    // [PHONE_FIELD]: getFormattedPhone(`${_get(item, 'assigned_resources[0].driver_contact_info')}`),
    [PHONE_FIELD]: item?.assigned_resources?.[0]?.driver_contact_info,
  };
});

/**
 * Получение данных о забронированной заявке
 */
export const getShippingItem = defaultMemoize((item) => {
  const isTripWaiting = isItemWithStatus(item, queryKeys.TRIP_WAITING);
  const isInWay = isItemWithStatus(item, queryKeys.IN_WAY);
  const isWorkStatus = isTripWaiting || isInWay;
  return {
    ...getMappedShippingItem(_get(item, 'shipping_request_info')),
    reservedId: _get(item, 'id'),
    cargoOwnerId: _get(item, 'shipping_request_info.cargo_owning_company_id'),
    shippingCompanyId: _get(item, 'company_id'),
    documentFlow: _get(item, 'document_flow'),
    isScanCopyError: _getIsScanCopyError(item),
    isExpeditedPayment: _get(item, 'expedited_payment') || false,
    scanCopyStatus: _get(item, 'scancopy_status'),
    scanCopyStatusComment: _get(item, 'scancopy_status_comment'),
    completedAt: _get(item, 'completed_at'),
    isTripWaiting,
    isInWay,
    isWorkStatus,
    isStatusCanceled: isItemWithStatus(item, queryKeys.CANCELED),
    assignResources: getAssignResources(item),
    assignResourcesObj: getAssignResourcesObj(item),
    resourceDeadline: _get(item, 'resource_assignment_deadline'),
    scanCopyDeadline: _get(item, 'scancopy_attachment_deadline'),
    selectedSupply: _getSelectedSupply(
      _get(item, 'selected_car_supply', []),
      _get(item, 'shipping_request_info.route_points', []),
    ),
    partnership: _get(item, 'partnership'),
    expeditedPaymentDays: item?.expedited_payment_days,
    maxExpeditedPaymentDays: item?.shipping_request_info?.max_expedited_payment_days ?? 2,
    isExpeditedPaymentAvailable: item?.shipping_request_info?.expedited_payment_available ?? false,
    finalPrice: item?.final_price,
    shippingStatus: item?.status?.code_name,
    _original: item,
  };
});

export const getMappedShipping = createSelector(
  (state) => state.suggestionsReserved.activeItem,
  (shipping) => getShippingItem(shipping),
);

/**
 * Генерация listId для корректного отображения списка
 * есть кейс когда приходят заявки с одинаковым id, но разной датой подачи
 */
export const getShippingListId = defaultMemoize((item) => {
  const id = _get(item, 'id');
  const supplyAt = _get(item, 'car_supply_at');

  if (id && supplyAt) {
    return `${id}_${supplyAt}`;
  }

  return null;
});

function _getMappedBodyType(item) {
  const bodyTypes = _get(item, 'resource_requirements.transport_body_type', []);
  return getSortedBodyTypes(bodyTypes);
}

function _getSelectedSupply(items, points) {
  const selected = items.reduce((acc, item) => {
    acc[_get(item, 'route_point_id')] = {
      routePointId: _get(item, 'route_point_id'),
      rawDatetime: _get(item, 'car_supply_at'),
      date: moment.parseZone(_get(item, 'car_supply_at')).format(DATE_FORMAT),
      time: moment.parseZone(_get(item, 'car_supply_at')).format(TIME_FORMAT),
      dateTime: moment.parseZone(_get(item, 'car_supply_at')),
    };
    return acc;
  }, {});
  const setted = points
    .filter((point) => point.car_supply_at)
    .reduce((acc, point) => {
      acc[_get(point, 'id')] = {
        routePointId: _get(point, 'id'),
        rawDatetime: _get(point, 'car_supply_at'),
        date: moment.parseZone(_get(point, 'car_supply_at')).format(DATE_FORMAT),
        time: moment.parseZone(_get(point, 'car_supply_at')).format(TIME_FORMAT),
        dateTime: moment.parseZone(_get(point, 'car_supply_at')),
      };
      return acc;
    }, {});

  return points.reduce((acc, point) => {
    const pointId = _get(point, 'id');
    const pointObj = selected[pointId] || setted[pointId];
    if (pointObj) acc.push(pointObj);
    return acc;
  }, []);
}

/**
 * Маппинг значений из query в объеты для мультиселекта
 */
export const getSelectedBodyTypeValues = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) => {
    const selectedBodyTypes = query[queryKeys.BODY_TYPE] || [];
    return [
      AWNING_OBJ,
      REFRIGERATOR_OBJ,
      ISOTHERMAL_OBJ,
      FULL_METAL_OBJ,
      BOARD_OBJ,
      TANKER_OBJ,
      CONTAINER_OBJ,
      TIMBER_CARRIER_OBJ,
    ].filter((bodyType) => selectedBodyTypes.includes(_get(bodyType, 'value')));
  },
);

/**
 * Маппинг значения типа заявок из query в значения для фильтра
 */
export const getDistanceRequestTypeFilterValues = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) => query[queryKeys.DISTANCE_REQUEST_TYPE] || queryKeys.ALL_REQUESTS,
);

/**
 * Маппинг значений быстрых фильтров из query в объекты для мультиселекта
 */
export const getSelectedFastFiltersValues = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) => {
    return [
      queryKeys.CLOSING_DOCUMENT_ERRORS,
      queryKeys.ASSIGN_RESOURCES,
      queryKeys.AGREE_ON_CHANGE_REQUEST,
      queryKeys.NEED_TO_SIGN_SCANCOPY,
      queryKeys.NEED_OF_ATTORNEY,
    ].filter((f) => !!query[f]);
  },
);

/**
 * Получение печатной формы заявки
 */
export const getPrintableForm = createSelector(
  (store) => _get(store, 'suggestionsReserved.printableForm'),
  (printableForm) => (fileName) =>
    printableForm
      ? [
          {
            typeId: 1,
            name: '',
            files: [
              {
                id: 1,
                fileName: `Печатная форма заявки №${fileName}.pdf`,
                singedUrl: _get(printableForm, 'signed_url'),
              },
            ],
          },
        ]
      : [],
);

/**
 * Получения списка траспортных накладных
 */
export const getTransportFiles = createSelector(
  (store) => _get(store, 'suggestionsReserved.transportFiles.items', []),
  (transportFiles) =>
    transportFiles.map((transportFile) => ({
      typeId: _get(transportFile, 'id'),
      name: _get(transportFile, 'comment'),
      files: _get(transportFile, 'files', []).map((file) => ({
        id: _get(file, 'id'),
        fileName: _get(file, 'file_name'),
        singedUrl: _get(file, 'singed_url'),
      })),
    })),
);

/**
 * Получение значения, что заявка на бронь ТС
 * @param item
 * @returns {boolean}
 */
function _getIsReservationRequest(item) {
  const kind = 'ReservationRequest';
  const offer = 'ReservationOffer';
  return (
    _get(item, 'kind') === kind || _get(item, 'kind') === offer || _get(item, 'shipping_request_info.kind') === kind
  );
}

/**
 * Получение значения об активности фильтров
 */
export const getIsFilterActive = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) =>
    [
      query[queryKeys.HUMAN_FRIENDLY_ID],
      query[queryKeys.FROM_LOCATION_LABEL],
      query[queryKeys.FROM_RADIUS],
      query[queryKeys.DIRECTION_LOCATION_LABEL],
      query[queryKeys.DIRECTION_RADIUS],
      query[queryKeys.DATE_FROM],
      query[queryKeys.DATE_UNTIL],
      query[queryKeys.CIRCULAR_ROUTE],
      query[queryKeys.BODY_TYPE],
      query[queryKeys.MAX_TONNAGE],
      query[queryKeys.SET_BID],
    ].some(Boolean),
);

export const getSuggestionRequestList = defaultMemoize((state) => state?.suggestionsRequests?.items);
