import React, { InputHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form';
import styles from './Switcher.module.scss';

type TSize = 'tiny' | 'small' | 'medium' | 'large';
type IDataItem = { key: string; label: string | ReactNode; disabled?: boolean; comment?: string };

export interface TSwitcherProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'checked'> {
  name: string;
  data: IDataItem[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: TSize;
  labelClassName?: string;
  checked?: string;
  inputProps?: Record<string, any>;
}

/**
 * Redux-form switcher // needs to refactor with BaseSwitcher
 * @param param0
 * @returns
 */
const Switcher = ({ className, id = '', name, data, disabled, onChange }: TSwitcherProps): React.ReactElement => (
  <div className={classNames(styles.switch, className)}>
    {data.map((item) => {
      const elementId = `${id}${item.key}`;
      return (
        <React.Fragment key={elementId}>
          <Field
            id={elementId}
            type="radio"
            name={name}
            component="input"
            value={item.key || ''}
            disabled={disabled || item.disabled}
            onChange={onChange}
          />
          <label htmlFor={elementId}>{item.label}</label>
        </React.Fragment>
      );
    })}
  </div>
);

export function BaseSwitcher(props: TSwitcherProps): React.ReactElement {
  const {
    className,
    checked,
    labelClassName,
    id = '',
    name,
    data,
    disabled,
    onChange,
    size = 'medium',
    inputProps,
  } = props;
  return (
    <div className={classNames(styles.switch, [styles[size]], className)}>
      {data.map((item) => {
        const elementId = `${id}_${name}_${item.key}`;
        return (
          <React.Fragment key={elementId}>
            <input
              id={elementId}
              type="radio"
              name={name}
              value={item.key || ''}
              disabled={disabled || item.disabled}
              onChange={onChange}
              checked={item.key === checked}
              {...inputProps}
            />
            <label htmlFor={elementId} className={labelClassName}>
              {item.label}
            </label>
            {item.comment && item.key === checked && <div className={styles.comment}>{item.comment}</div>}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default Switcher;
