import _get from 'lodash/get';

/**
 * Маппинг ответа доп. информации
 * @param {object} response
 * @returns {{items: *, totalItems: *, currentPage: *}}
 */
function pageListMapper(response: {}): {
  items: {}[];
  totalItems: number;
  currentPage: number;
  totalPages: number;
  resultsPerPage: number;
} {
  const items = _get(response, 'data.items');
  const totalItems = _get(response, 'data.page_info.total_results');
  const currentPage = _get(response, 'data.page_info.current_page');
  const resultsPerPage = _get(response, 'data.page_info.results_per_page');
  const totalPages = Math.ceil(totalItems / resultsPerPage);

  return {
    items,
    totalItems,
    currentPage,
    totalPages,
    resultsPerPage,
  };
}

export default pageListMapper;
