// Общие
export const COMMON = 'common';
export const PROFILE_NOTIFICATIONS_SETTINGS = '/profile/notifications/settings';
export const PROFILE_NOTIFICATIONS_CHANNELS = '/profile/notifications/channels';

// Перевозчик
export const SUGGESTION_REQUESTS = '/suggestions/requests';
export const SUGGESTION_VOLUME_GRAPHICS = '/suggestions/contracts';
export const SUGGESTION_CHANGE = '/suggestion-change';
export const SHIPPINGS_AT_CLEARANCE = '/shippings?status=at_clearance';
export const SHIPPINGS_TRIP_WAITING = '/shippings?status=trip_waiting';
export const SHIPPINGS_IN_WAY = '/shippings?status=in_way';
export const SHIPPINGS_COMPLETED = '/shippings?status=completed';
export const SHIPPING_SELECT_RESOURCES = '/shipping/:id/resources';
export const DRIVER_FORM = '/resources/driver/form';
export const NOTIFICATIONS_PAGE = '/notifications';

// ГВ
export const ORDERS_PENDING = '/order-list?status=pending';
export const ORDERS_IN_WORK = '/order-list?status=in_work';
export const ORDERS_ARCHIVE = '/order-list?status=archive';
export const ORDERS_DRAFT = '/order-list?status=draft';
export const ORDERS_UPLOADING = '/orders-uploading';
export const ORDER = '/order';
export const MASS_SENDING = '/mass-sending';
export const ANALYTICS_REPORTS = '/analytics/reports';
