import React from 'react';
import SVGIcon from './index';

import Tooltip from '../../Tooltip';

const IconInterval = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <Tooltip content="Интервальная заявка">
      <SVGIcon {...props} viewBox="0 0 20 20">
        <g>
          {' '}
          <path d="m12.357 5.59039c-.7252-.3876-1.5347-.59039-2.357-.59039v5l-3.53553 3.5355c.58142.5815 1.29726 1.0105 2.08411 1.2492s1.62042.2796 2.42692.1192c.8064-.1604 1.5609-.5172 2.1965-1.0388.6356-.5217 1.1327-1.192 1.4474-1.9517.3147-.7596.4371-1.5852.3565-2.40349s-.3617-1.60408-.8186-2.28776c-.4568-.68368-1.0752-1.24415-1.8003-1.63176z" />
          <path
            clipRule="evenodd"
            d="m18 10c0 4.4183-3.5817 8-8 8-4.41828 0-8-3.5817-8-8 0-4.41828 3.58172-8 8-8 4.4183 0 8 3.58172 8 8zm-1.5 0c0 3.5899-2.9101 6.5-6.5 6.5-3.58985 0-6.5-2.9101-6.5-6.5 0-3.58985 2.91015-6.5 6.5-6.5 3.5899 0 6.5 2.91015 6.5 6.5z"
            fillRule="evenodd"
          />
        </g>
      </SVGIcon>
    </Tooltip>
  );
};

IconInterval.defaultProps = {
  color: 'base',
};

export default IconInterval;
