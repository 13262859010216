import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { toast } from '../../../components/Toaster';

import { getStorageRecord } from '../../../services/commonService';
import { TAdditionalService } from '../../../store/order/routes/model';
import { useGetPartnerList, useGetPartnersGroupList } from '../../../hooks/useGetPartners';
import { getAdditionalServices, getContractExecutors, getOrder } from '../../../services/orders/ordersService';
import { getAllLiftingCapacity, getCargoList as getCargoListService } from '../../../services/catalogService';

/**
 * Загрузка справочников и данных по заказу
 */
export function useInitialDataFetching(isOrderBasedOnContract: boolean, isDraftEditing: boolean) {
  const history = useHistory();
  const { id: orderId } = useParams<{ id?: string }>();
  const [isLoading, setLoading] = useState(true);
  const [capacityList, setCapacityList] = useState([]);
  const [serviceList, setServiceList] = useState<TAdditionalService[]>([]);
  const [cargoList, setCargoList] = useState([]);
  const [partnerGroupList, setPartnerGroupList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  /* Контракт или разовый заказ */
  const [currentOrder, setCurrentOrder] = useState<any>(null);
  /* Информация по заказу из хранилища */
  const [storageOrderInfo, setStorageOrderInfo] = useState<any>(null);
  const [contractExecutorList, setContractExecutorList] = useState([]);

  /* Получение справочника грузоподъемностей  */
  const getCapacityList = useCallback(() => getAllLiftingCapacity().then(setCapacityList), []);
  /* Получение справочника дополнительных услуг  */
  const getServiceList = useCallback(() => getAdditionalServices().then(setServiceList), []);
  /* Получение справочника грузов */
  const getCargoList = useCallback(() => getCargoListService().then(setCargoList), []);
  /* Получение списка партнерских групп */
  const getPartnersGroupsList = useGetPartnersGroupList();
  /* Получение списка партнеров */
  const getPartnersList = useGetPartnerList();

  const getContractExecutorList = useCallback(
    (contractId) =>
      getContractExecutors(contractId)
        .then((response) => response?.items ?? [])
        .then(setContractExecutorList),
    [],
  );

  const getOrderObj = useCallback(
    () =>
      getOrder(orderId)
        .then((order) => {
          setCurrentOrder(order);
          return order;
        })
        .then(async (order) => {
          if (isOrderBasedOnContract) {
            await getContractExecutorList(isDraftEditing ? order.contract_id : orderId);
          }
        })
        .catch(() => {
          toast.error('Информация по заказу недоступна');
          history.push('/order');
        }),
    [getContractExecutorList, history, isDraftEditing, isOrderBasedOnContract, orderId],
  );

  const getOrderStorageData = useCallback(
    () =>
      getStorageRecord(`order:${orderId}`)
        .then(setStorageOrderInfo)
        .catch(() => Promise.resolve()),
    [orderId],
  );

  useEffect(() => {
    const promises: Promise<any>[] = [];

    promises.push(getCapacityList());
    promises.push(getServiceList());
    promises.push(getCargoList());
    promises.push(getPartnersGroupsList().then(setPartnerGroupList));
    promises.push(getPartnersList({ per_page: 1 }).then(setPartnerList)); // TODO проверка на существование партнеров, не грузить весь список

    if (orderId) {
      promises.push(getOrderObj());
      promises.push(getOrderStorageData());
    }

    setLoading(true);
    Promise.allSettled(promises)
      .then((results) => {
        results.forEach((result) => {
          if (result.status === 'rejected') {
            console.error('rejected', result);
            toast.errorAsync(result.reason).catch(Promise.resolve);
          }
        });
      })
      .finally(() => setLoading(false));
  }, [
    getCapacityList,
    getCargoList,
    getContractExecutorList,
    getOrderObj,
    getOrderStorageData,
    getPartnersGroupsList,
    getPartnersList,
    getServiceList,
    isOrderBasedOnContract,
    orderId,
  ]);

  return {
    isLoading,
    capacityList,
    serviceList,
    cargoList,
    currentOrder,
    storageOrderInfo,
    partnerGroupList,
    partnerList,
    contractExecutorList,
  };
}
