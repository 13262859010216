// @ts-ignore typescript не видит импорт из axios, пришлось заигнорить
import { CancelToken } from 'axios';

import http, { synapseHttp } from './config/webConfig';
import { TChatsRes, TChatStore } from '../store/chat/models';
import { TMassSendingItem, TMassSendingItemReq } from '../store/mass-sending/models';
import { getAccessToken } from './authTokenService';

const { default: axios } = require('axios');

export const CHAT_ACCESS_TOKEN = 'tp.chat.accessToken';
export const CHAT_DEVICE_ID = 'device_id';

async function getLoginToken(): Promise<string | null> {
  try {
    const token = getAccessToken();
    const loginTokenRequestURL = `${process.env.REACT_APP_SYNAPSE_URL}/_matrix/client/r0/login`;
    const loginTokenResponse = await synapseHttp
      .post(
        loginTokenRequestURL,
        { type: 'm.login', token },
        {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      )
      .then((res) => res.data);

    return loginTokenResponse.token;
  } catch (e) {
    console.error("Error. Can't receive chat access token");
    return null;
  }
}

export async function saveChatAccessToken(): Promise<void> {
  const token = await loginToChats();
  if (token) {
    localStorage.setItem(CHAT_ACCESS_TOKEN, token);
  }
}

export async function getChatAccessToken(): Promise<string | null> {
  const token = localStorage.getItem(CHAT_ACCESS_TOKEN);
  if (!token) {
    await saveChatAccessToken();
  }
  return localStorage.getItem(CHAT_ACCESS_TOKEN);
}

export async function loginToChats(): Promise<string | null> {
  try {
    const token = getAccessToken();
    const deviceId = localStorage.getItem(CHAT_DEVICE_ID) || undefined;
    const responseAccessToken = await synapseHttp.post(
      `${process.env.REACT_APP_SYNAPSE_URL}/_matrix/client/r0/login`,
      { type: 'org.matrix.login.jwt', token, [CHAT_DEVICE_ID]: deviceId },
      {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
      },
    );

    localStorage.setItem(CHAT_ACCESS_TOKEN, responseAccessToken.data.access_token);
    localStorage.setItem(CHAT_DEVICE_ID, responseAccessToken.data.device_id);

    return responseAccessToken.data.access_token;
  } catch (e) {
    console.log("Error. Can't login into chats");
    return null;
  }
}

export async function logoutFromChat(): Promise<void> {
  const token = localStorage.getItem(CHAT_ACCESS_TOKEN);
  const url = `${process.env.REACT_APP_SYNAPSE_URL}/_matrix/client/r0/logout`;
  if (token) {
    await axios.post(url, null, {
      headers: { authorization: `Bearer ${token}` },
    });
  }
  await axios.get(process.env.REACT_APP_OAUTH_URL + '/revoke', { withCredentials: true, cache: false });
  localStorage.removeItem(CHAT_ACCESS_TOKEN);
  localStorage.removeItem(CHAT_DEVICE_ID);
}

export async function openChats(hash = ''): Promise<void> {
  const loginToken = await getLoginToken();
  const chatsWindow = window.open('about:blank', 'chats');
  if (chatsWindow) {
    // @ts-ignore
    chatsWindow.location = `${process.env.REACT_APP_CHAT_URL}/?loginToken=${loginToken}${hash}`;
    chatsWindow.focus();
  }
}

export async function getChatURL(hash = ''): Promise<string> {
  const loginToken = await getLoginToken();
  return `${process.env.REACT_APP_CHAT_URL}/?loginToken=${loginToken}${hash}`;
}

let cancelToken;
export async function getChatInfo(since: TChatStore['_syncSince']): Promise<any> {
  const token = await getChatAccessToken();

  if (token) {
    if (cancelToken) {
      cancelToken.cancel('Operation sync canceled due to new request.');
    }

    cancelToken = CancelToken.source();

    const url = `${process.env.REACT_APP_SYNAPSE_URL}/_matrix/client/r0/sync`;

    try {
      const state = await axios.get(url, {
        params: { access_token: token, since },
        cache: false,
        cancelToken: cancelToken.token,
      });

      return {
        rooms: state.data?.rooms ?? {},
        syncSince: state?.data?.next_batch ?? null,
      };
    } catch {
      localStorage.removeItem(CHAT_ACCESS_TOKEN);
      await saveChatAccessToken();
    }
  }

  return {
    rooms: {},
    syncSince: null,
  };
}

export async function getMassSendingItems(params = {}): Promise<TMassSendingItem[]> {
  const url = '/chats/broadcast';
  return http.get(url, { params, requestId: url }).then((res) => res.data.broadcasts);
}

export async function createMassSendingItem(data: Partial<TMassSendingItemReq>): Promise<TMassSendingItem> {
  const url = '/chats/broadcast';
  return http.post(url, data, { requestId: url }).then((res) => res.data);
}

export async function getChatsByEntityId(id: string | undefined = undefined): Promise<TChatsRes> {
  const url = '/chats';
  const params = { entity_id: id };
  return http.get(url, { params, requestId: url }).then((res) => res.data);
}
