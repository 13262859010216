import React from 'react';
import SVGIcon from './index';

function IconReply(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M8.33333 7.08333V3.75L2.5 9.58333L8.33333 15.4167V12C12.5 12 15.4167 13.3333 17.5 16.25C16.6667 12.0833 14.1667 7.91667 8.33333 7.08333Z"
      />
    </SVGIcon>
  );
}

IconReply.defaultProps = {
  color: 'base',
};

export default React.memo(IconReply);
