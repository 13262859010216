import React from 'react';
import SVGIcon from './index';

export default function IconUser(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 6.50001C13.5 8.43376 11.9337 10 10 10C8.06626 10 6.50002 8.43376 6.50002 6.50001C6.50002 4.56625 8.06626 3 10 3C11.9337 3 13.5 4.56625 13.5 6.50001ZM3 15.25C3 12.9225 7.66372 11.75 9.99996 11.75C12.3362 11.75 16.9999 12.9225 16.9999 15.25V17H3V15.25Z"
      />
    </SVGIcon>
  );
}

IconUser.defaultProps = {
  color: 'base',
};
