import TagManager from 'react-gtm-module';
import { A_CARGO_OWNING_COMPANY, A_EXTERNAL_ONLY, A_TRANSPORT_COMPANY, A_WITH_PLATFORMS } from '../../constants';
import { getAllowCompanyCategories } from '../company/selectors';
import { store } from '../store';
import { getRoleIsCargoOwner, getUserInfo } from '../user/selectors';

export type SendEvent =
  | {
      (label?: string): void;
    }
  | Function;

function getUserDataForGTM() {
  const state = store.getState();
  const isCargoOwningUser = getRoleIsCargoOwner(state);
  const tcCategories = getAllowCompanyCategories(state);
  const userInfo = getUserInfo(state);
  if (userInfo?.companyId && userInfo?.id && tcCategories) {
    const companyType = isCargoOwningUser ? A_CARGO_OWNING_COMPANY : A_TRANSPORT_COMPANY;
    const ntkCategories = tcCategories && tcCategories.length ? tcCategories : null;
    let ntkAccess;
    if (ntkCategories) {
      ntkAccess = ntkCategories.length > 1 ? A_WITH_PLATFORMS : A_EXTERNAL_ONLY;
    }
    return {
      event: 'gtm.customevent',
      userId: userInfo.companyId,
      dimension1: userInfo.companyId,
      dimension2: companyType,
      dimension3: ntkAccess,
      dimension4: userInfo.id,
      transport: 'beacon',
    };
  }
  return {
    event: 'gtm.customevent',
    userId: 'unregistered',
    dimension1: 'unregistered',
    transport: 'beacon',
  };
}

export function eventCreator(category: string, action: string, goal?: string): SendEvent {
  if (process.env.REACT_APP_GMT_ID) {
    return function (label?: string): void {
      try {
        const userData = getUserDataForGTM();
        TagManager.dataLayer({
          dataLayer: {
            category,
            action,
            label,
            ...userData,
          },
        });
      } catch (e) {
        console.error('Analytics error: ', e);
      }
    };
  } else {
    return () => {};
  }
}
