import { SYMBOLS } from '../../../constants';

export function TableColumn({
  // body cells props:
  field = '',
  placeholder = '',
  bodyClassName = '',
  bodyStyle = null,
  center = false,
  withTooltip = false,

  // header cells props:
  header = null,
  headerStyle = null,
  headerClassName = '',

  // common fields:
  ellipsis = true,
  className = '',
  cellContentClassName = '',
  style = null,
  width = '',
  children = null,

  // column selector fields:
  id = '',
  name = '', // можно не заполнять, если header - строка
  required = false,
  pinned = false,
  optional = false,
}) {
  return null;
}

TableColumn.defaultProps = {
  ellipsis: true,
  placeholder: SYMBOLS.LONG_DASH,
};

export function TableColumnsGroup({
  children,
  showAsColumn = false,
  // column selector fields:
  id = '',
  name = '',
  required = false,
  pinned = false,
  optional = false,
}) {
  return null;
}

export function TableRowOverlay({ children }) {
  return null;
}
