import React from 'react';
import styles from '../styles.module.scss';
import { Link } from 'react-router-dom';

import Button from '../../Button';
import TimeLeft from '../../TimeLeft';
import IconDriver from '../../Icons/SVGIcons/IconDriver';
import { getOrderScanCopyStatus } from './getOrderScanCopyStatus';
import { getAuctionTypeIcon } from './getAuctionTypeIcon';
import { DataItemProps } from '../models';
import { CONTRACT } from '../../../store/order/routes/formKeys';
import { EOrderDocsProcessStatuses, EOrderStatuses } from '../../../constants/order';

export function getOrderAction({
  orderId,
  contractId,
  auctionInfo,
  orderStatus,
  offerDeadline,
  resourceDeadline,
  scanCopyStatus,
  isScanCopyError,
  orderType,
  currentAuctionStageNumber,
  auctionStagesInfo,
  queryString,
}: Partial<DataItemProps>) {
  const auctionType = auctionInfo?.auctionType;
  const hasWinningBid = !!auctionInfo?.winningBidId;
  const isContractType = orderType === CONTRACT;
  const currentAuctionStage =
    auctionStagesInfo && auctionStagesInfo.find((stage) => stage.number === currentAuctionStageNumber);
  const isCurrentAuctionStageAutoExtended = currentAuctionStage?.autoExtended;

  const draftLink = contractId ? `/order/${orderId}/edit_draft_contract_order` : `/order/${orderId}/edit_draft`;

  const ORDER_ACTIONS = new Map()
    .set(
      EOrderStatuses.DRAFT,
      <Button
        theme="secondary"
        size="small"
        className={styles.cta}
        component={<Link to={draftLink} />}
        onClick={(e) => e.stopPropagation()}
      >
        Редактировать
      </Button>,
    )
    .set(
      EOrderStatuses.RESERVED,
      <div className="flex items-center tooltip-icon-height">
        <IconDriver width="20" height="20" color="base" />
        <span className="color-dark ml-1">Ожидание ресурсов</span>
      </div>,
    )
    .set(
      EOrderStatuses.AUCTION_WAIT,
      <div className="flex items-center tooltip-icon-height">{getAuctionTypeIcon({ auctionType })} &ndash;</div>,
    )
    .set(
      EOrderStatuses.IN_AUCTION,
      <div className="flex items-center tooltip-icon-height">
        {getAuctionTypeIcon({
          auctionType,
          hasWinningBid,
          isContractType,
        })}
        <div className="mr-1" />
        <div>
          <TimeLeft title={''} time={offerDeadline} withTimerIcon={false} />
          {isCurrentAuctionStageAutoExtended && <div className="caption">Автопродление</div>}
        </div>
      </div>,
    )
    .set(
      EOrderStatuses.WINNER_SELECTION,
      <Button
        theme="secondary"
        size="small"
        className={styles.cta}
        component={<Link to={`/contracts/${orderId}/bids`} />}
        onClick={(e) => e.stopPropagation()}
      >
        Выбрать
      </Button>,
    )
    .set(
      EOrderStatuses.APPROVAL,
      <Button
        theme="secondary"
        className={styles.cta}
        component={<Link to={`/shipping-confirm/${orderId}/confirm?${queryString}`} />}
        onClick={(e) => e.stopPropagation()}
      >
        Утвердить перевозку
      </Button>,
    )
    .set(
      EOrderStatuses.EXPIRED,
      /* TODO: возможна такая реализация */
      // <Button
      //   component={<Link to={`/order/${orderId}`} />}
      //   theme="secondary"
      //   className={styles.cta}
      // >
      //   На основании
      // </Button>,
      <div className="flex items-center tooltip-icon-height">
        {getAuctionTypeIcon({ auctionType })}
        <div className="mr-1" />
        <TimeLeft title="" time={resourceDeadline ?? '2019-01-10 07:00:00'} withTimerIcon={false} />
      </div>,
    )
    .set(
      EOrderStatuses.TRIP_WAITING,
      <div className="flex items-center">
        {scanCopyStatus === EOrderDocsProcessStatuses.DOC_OWNER_SIGNING ? (
          <Button
            className="width100p"
            theme="secondary"
            component={<Link to={`shipping-confirm/${orderId}/sign`} />}
            onClick={(e) => e.stopPropagation()}
          >
            Подписать
          </Button>
        ) : (
          getOrderScanCopyStatus({ scanCopyStatus, isScanCopyError })
        )}
      </div>,
    )
    .set(
      EOrderStatuses.IN_TRIP,
      <div className="flex items-center">
        {scanCopyStatus === EOrderDocsProcessStatuses.DOC_OWNER_SIGNING ? (
          <Button
            className="width100p"
            theme="secondary"
            component={<Link to={`shipping-confirm/${orderId}/sign`} />}
            onClick={(e) => e.stopPropagation()}
          >
            Подписать
          </Button>
        ) : (
          getOrderScanCopyStatus({ scanCopyStatus, isScanCopyError })
        )}
      </div>,
    );

  return (
    ORDER_ACTIONS.get(orderStatus) || (
      <div className="flex items-center tooltip-icon-height">
        {getAuctionTypeIcon({ auctionType })}
        <div className="mr-1" />
        <TimeLeft title={''} time={resourceDeadline ?? '2010-10-10 00:00:00'} withTimerIcon={false} />
      </div>
    )
  );
}
