import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

function IconScanCopy(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M6 5L11 5L12.5 6.5L14 8V11H6V5Z" fill={colors[props.color!]} />
      <rect x="6" y="13" width="8" height="2" fill={colors[props.color!]} />
      <rect x="4" y="10" width="12" height="2" fill={colors[props.stroke!]} />
      <path d="M6 2H2V6" stroke={colors[props.stroke!]} strokeWidth="2" />
      <path d="M18 6L18 2L14 2" stroke={colors[props.stroke!]} strokeWidth="2" />
      <path d="M2 14L2 18L6 18" stroke={colors[props.stroke!]} strokeWidth="2" />
      <path d="M14 18L18 18L18 14" stroke={colors[props.stroke!]} strokeWidth="2" />
    </SVGIcon>
  );
}

IconScanCopy.defaultProps = { color: 'light', stroke: 'base' };

export default React.memo(IconScanCopy);
