import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconArrowFilled = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C5.584 18 2 14.416 2 10C2 5.584 5.584 2 10 2C14.416 2 18 5.584 18 10C18 14.416 14.416 18 10 18ZM11 5.7925L7.70154 9L16 9L16 11L7.70154 11L11 14.2075L9.66802 15.5L4 10L9.66802 4.5L11 5.7925Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconArrowFilled.defaultProps = {
  color: 'base',
};

export default IconArrowFilled;
