import React from 'react';
import { toast as toastify } from 'react-toastify';
import ToasterChildren from './ToasterChildren';

const success = (title, message?) => {
  toastify(<ToasterChildren title={title} message={message} type="success" />, {
    className: 'toast success',
  });
};

const error = (title, message?) => {
  toastify(<ToasterChildren title={title} message={message} type="error" />, {
    className: 'toast error',
  });
};

const warning = (title, message?) => {
  toastify(<ToasterChildren title={title} message={message} type="warning" />, {
    className: 'toast warning',
  });
};

const info = (title, message?) => {
  toastify(<ToasterChildren title={title} message={message} type="info" />, {
    className: 'toast info',
  });
};

const errorAsync = (errorData) => {
  const errorCode = errorData?.response?.status;
  const errorDescription =
    errorData?.response?.data?.error_description ||
    errorData?.response?.data?.description ||
    errorData?.response?.data?.detail;

  if (errorCode && errorDescription) {
    if (errorCode === 400) {
      error('Произошла ошибка', errorDescription);
    } else {
      error(errorCode, errorDescription);
    }
  }

  // throw new Error(errorData);
  // TODO: хак для нового вебпака
  return Promise.reject(errorData);
};

export const toast = {
  success,
  error,
  warning,
  info,
  errorAsync,
};
