import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconChecked = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconChecked.defaultProps = { color: 'base' };

export default IconChecked;
