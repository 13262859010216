import React from 'react';
import SVGIcon from './index';

const IconMail = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 4H18V16H2V4ZM10 11L17 7V6L10 10L3 6V7L10 11Z" fill="white" />
    </SVGIcon>
  );
};

IconMail.defaultProps = {
  color: 'base',
};

export default IconMail;
