import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

function IconMegaphone(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M4 8H2V12H4V8Z" fill={colors[props.color!]} />
      <path d="M5 8V12L7 12.7273V14L8 16H10V13.8182L16 16V17H18V3H16V4L5 8Z" fill={colors[props.color!]} />
    </SVGIcon>
  );
}

IconMegaphone.defaultProps = {
  color: 'base',
};

export default React.memo(IconMegaphone);
