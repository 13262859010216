import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface ToggleProps {
  disabled?: boolean;
  checked?: boolean;
  onChange: (boolean) => void;
  onClick?: (event) => void;
  theme?: string;
  ['data-test']?: string;
}

const Toggle = (props: ToggleProps) => {
  return (
    <label
      className={classNames(styles.toggle, {
        [styles.checked]: props.checked,
        [styles.disabled]: props.disabled,
        [styles[props.theme!]]: props.theme,
      })}
    >
      <input type="checkbox" {...props} />
      <span className={styles.checkmark} {...props} />
    </label>
  );
};

export default Toggle;
