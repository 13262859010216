import React from 'react';
import SVGIcon from './index';

const IconSavedFilters = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M2.5 2H18.5V4H2.5V2Z" fill="white" />
      <path d="M2.5 7H18.5V9H2.5V7Z" fill="white" />
      <path d="M2.5 12H10.5L8.5 14H2.5V12Z" fill="white" />
      <path
        d="M15 11L16.1681 13.9197L19.5 14.0557L16.89 15.9962L17.7812 19L15 17.2797L12.2188 19L13.11 15.9962L10.5 14.0557L13.8319 13.9197L15 11Z"
        fill="white"
      />
    </SVGIcon>
  );
};

IconSavedFilters.defaultProps = {
  color: 'base',
};

export default IconSavedFilters;
