import {
  BACK_COVER_TRANSLATION,
  FULL_COVER_TRANSLATION,
  SIDE_COVER_TRANSLATION,
  TOP_COVER_TRANSLATION,
} from '../store/resources/bodyTypeOptions';

/**
 * переводим на русский типы растентовки
 * @returns {string}
 * @private
 * @param coverType
 */
export const translateCoverTypes = (coverType: any): string =>
  ({
    full: FULL_COVER_TRANSLATION,
    rear: BACK_COVER_TRANSLATION,
    lateral: SIDE_COVER_TRANSLATION,
    upper: TOP_COVER_TRANSLATION,
  }[coverType]);
