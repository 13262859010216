import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconLight = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5L11 5V2H9V5ZM19 10H16V12H19V10ZM1 12V10H4L4 12H1ZM4.05026 3.63602L2.63605 5.05023L4.75737 7.17155L6.17158 5.75734L4.05026 3.63602ZM15.9498 3.63605L17.364 5.05026L15.2427 7.17158L13.8285 5.75737L15.9498 3.63605ZM10 8C8.34315 8 7 9.34315 7 11C7 12.6569 8.34315 14 10 14C11.6569 14 13 12.6569 13 11C13 9.34315 11.6569 8 10 8ZM10 6C7.23858 6 5 8.23858 5 11C5 13.0503 6.2341 14.8124 8 15.584L8 18H12V15.584C13.7659 14.8124 15 13.0503 15 11C15 8.23858 12.7614 6 10 6Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconLight.defaultProps = {
  color: 'base',
};

export default React.memo(IconLight);
