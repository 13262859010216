import { Countries } from '../containers/resources/forms/driver/constants';
import filterByMask from './filterByMask';

export const russiaPassportMask: string = '9999 999999';
export const belarusPassportMask: string = 'SS 9999999';
export const kazakhstanPassportMask: string = 'N 99999999';
export const tajikistanPassportMask: string = '999999999';
export const biometricPassportMask: string = 'SS9999999';

export const getPassportName = (countryId) => {
  switch (countryId) {
    default:
      return 'Паспорт';
    case 5:
    case 6:
      return 'Номер документа';
  }
};
export const getPassportPlaceholder = (countryId) => {
  switch (countryId) {
    default:
      return 'Серия и номер';
    case 3:
      return 'N';
    case 5:
    case 6:
      return 'Укажите';
  }
};

export const getPassportMask = (countryId: number): string => {
  switch (countryId) {
    case 3:
      return kazakhstanPassportMask;
    case 2:
      return belarusPassportMask;
    case 1:
      return russiaPassportMask;
    default:
      return '';
  }
};

export const getBiometricPassportMask = (countryId): string => {
  switch (countryId) {
    case 10:
      return tajikistanPassportMask;
    default:
      return biometricPassportMask;
  }
};

export const getBiometricPassportLabelHelp = (countryId) => {
  switch (countryId) {
    case 10:
      return '9 цифр';
    default:
      return '2 буквы латинского алфавита и 7 цифр';
  }
};

export const getPassportLabelHelp = (countryId) => {
  switch (countryId) {
    case 2:
    default:
      return null;
  }
};

/**
 * Получение id страны
 * @param country
 * @returns {number}
 * @private
 */
export const getCountryId = (country: Countries[0] | Countries[1]): number => {
  switch (country) {
    case Countries.KZ:
      return 3;
    case Countries.BY:
      return 2;
    case Countries.RU:
    default:
      return 1;
  }
};

export default function passportFormat(value: string, countryId: number): string {
  switch (countryId) {
    case 3:
      // 3 - Казахстан
      return filterByMask(value, kazakhstanPassportMask);
    // 2 - Беларусь
    case 2:
      // Ограничение возможных символов
      return filterByMask(value, belarusPassportMask);
    // 1 - Россия
    case 1:
      return filterByMask(value, russiaPassportMask);
    default:
      return value;
  }
}

export function biometricPassportFormat(value: string, countryId: number): string {
  switch (countryId) {
    case 10:
      return filterByMask(value, tajikistanPassportMask);
    default:
      return filterByMask(value, biometricPassportMask);
  }
}

export const getPassportWho = new Map()
  .set(1, 'Кем выдан')
  .set(2, 'Орган, выдавший паспорт')
  .set(3, 'Кем выдан')
  .set(4, 'Кем выдан')
  .set(5, 'Кем выдан')
  .set(6, 'Кем выдан');

export const getPassportIssuerPlaceholder = (countryId: number) => {
  switch (countryId) {
    default:
    case 1:
      return 'Наименование гос. органа';
    case 2:
    case 3:
    case 4:
      return 'Орган, выдавший паспорт';
    case 5:
    case 6:
      return 'Укажите';
  }
};

export const BY_PASSPORT_STARTS_WITH = new Set(['AB', 'BM', 'HB', 'KH', 'MP', 'MC', 'KB', 'PP', 'BM']);

export const isValidBYPassport = (passport: string): boolean => {
  return BY_PASSPORT_STARTS_WITH.has(passport.substr(0, 2));
};
