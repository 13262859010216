export const WITHOUT_HAZARD = 'withOutHazard';
export const WITHOUT_HAZARD_OBJ = { value: WITHOUT_HAZARD, label: 'Нет' };
export const HAZARD_CLASS_LIST = [
  WITHOUT_HAZARD_OBJ,
  { label: '1 класс', value: '1' },
  { label: '2 класс', value: '2' },
  { label: '3 класс', value: '3' },
  { label: '4 класс', value: '4' },
  { label: '5 класс', value: '5' },
  { label: '6 класс', value: '6' },
  { label: '7 класс', value: '7' },
  { label: '8 класс', value: '8' },
  { label: '9 класс', value: '9' },
];
