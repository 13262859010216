import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconAssessment = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M17 3H3V17H17V3ZM14 10H12V14H14V10ZM6 9H8V14H6V9ZM11 6H9V14H11V6Z"
        fill={colors[props.color!]}
        fillRule="evenodd"
      />
    </SVGIcon>
  );
};

export default IconAssessment;
