import React from 'react';
import SVGIcon from './index';

const IconExclamation = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <SVGIcon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 2C8.29052 2 7.28164 2.85888 7.05001 4H3V17H16V4H11.95C11.7184 2.85888 10.7095 2 9.5 2ZM9.5 6C10.3284 6 11 5.32843 11 4.5C11 3.67157 10.3284 3 9.5 3C8.67157 3 8 3.67157 8 4.5C8 5.32843 8.67157 6 9.5 6ZM10 8V13H9V8H10ZM10 15V14H9V15H10Z"
    />
  </SVGIcon>
);

IconExclamation.defaultProps = {
  color: 'base',
};

export default IconExclamation;
