import { useQuery } from 'react-query';
import { useAppSelector } from './useShallowEqualSelector';
import { getCompanyID } from '../store/company/selectors';
import { getCompaniesAndGroupsByIds } from '../services/partnersService';
import { getMappedPartnerItem } from '../store/partners/partners/selectors';
import { ACTIVE } from '../store/partners/partners/statusKeys';

export const EMPTY_PARTNERSHIPS_RESPONSE = {
  partnerships: [],
  groups: [],
};

/**
 * Получение партнёрств и групп
 * @param companyIds
 * @param groupsIds
 */
export function usePartnerships(companyIds: string[], groupsIds: string[]) {
  const result = useQuery(
    [`partnerships`, companyIds, groupsIds],
    () => getCompaniesAndGroupsByIds(companyIds, groupsIds),
    {
      enabled: !!companyIds.length || !!groupsIds.length,
    },
  );
  return result;
}

/**
 * Получение массива с партнёрами для текущей компании
 * @param companyIdList
 */
export function useCurrentCompanyPartnerships(companyIdList: string[]) {
  const companyId = useAppSelector(getCompanyID);
  const { data: partnerships = [] } = useQuery(
    [`partnerships`, companyIdList, []],
    () => getCompaniesAndGroupsByIds(companyIdList, []),
    {
      enabled: !!companyIdList.length,
      select: (data) => {
        const partnerships = data.partnerships.map((item) => getMappedPartnerItem(item, companyId));
        return partnerships;
      },
    },
  );
  return partnerships;
}

/**
 * id текущей компании
 */
export function useCurrentCompanyId() {
  return useAppSelector(getCompanyID);
}

/**
 * Данный id является id текущей компании
 * @param companyId
 */
export function useIsCurrentCompany(companyId: string) {
  return companyId === useCurrentCompanyId();
}

/**
 * Является ли компания с данным id партнёром для текущей компании
 * @param companyId
 */
export function useIsPartner(companyId: string) {
  const params = companyId ? [companyId] : [];
  const currentCompanyPartnerships = useCurrentCompanyPartnerships(params);
  return !!currentCompanyPartnerships?.find(
    (partnership) => partnership.companyId === companyId && partnership.partnershipStatus === ACTIVE,
  );
}
