import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import chat from './chat/reducer';
import notices from './notices/reducer';
import massSending from './mass-sending/reducer';
import page from './page/reducer';
import registration from './registration/reducer';
import user from './user/reducer';
import company from './company/reducer';
import auth from './auth/reducer';
import resources from './resources/reducer';
import documents from './documents';
import suggestionsRequests from './suggestions/requests/reducer';
import suggestionsReserved from './suggestions/reserved/reducer';
import suggestionsTargets from './suggestions/targets/reducer';
import graphics from './graphics/reducer';
import claims from './claims/reducer';
import requirementsChange from './requirements-change/reducer';
import partnerCarriers from './partners/carriers/reducer';
import partners from './partners/partners/reducer';
import partnersGroups from './partners/groups/reducer';
import notificationsSettings from './notifications-settings/reducer';
import counterAgents from './counterAgents/reducer';
import locations from './counterAgents/locationsReducer';

const rootReducer = (history) =>
  combineReducers({
    page,
    form: formReducer,
    router: connectRouter(history),
    registration,
    user,
    company,
    resources,
    auth,
    documents,
    suggestionsRequests,
    suggestionsReserved,
    suggestionsTargets,
    graphics,
    claims,
    requirementsChange,
    partners,
    partnersGroups,
    partnerCarriers,
    notificationsSettings,
    massSending,
    notices,
    chat,
    counterAgents,
    locations,
  });

export default rootReducer;

export type TRootState = ReturnType<typeof rootReducer>;
