import _isEqual from 'lodash/isEqual';
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import * as types from './actionTypes';
import { history } from '../configureStore';

import * as userSelectors from './selectors';
import { toast } from '../../components/Toaster';
import { EMAIL, PASSWORD_NEW } from './formFields';
import * as companyAction from '../company/actions';
import { changePassword } from '../../services/authService';
import * as profileService from '../../services/profileService';
import * as authTokenService from '../../services/authTokenService';
import * as companyDocumentsActions from '../../store/documents/actions';
import * as authService from '../../services/authService';

/**
 * Аутентификация пользователя
 */
export function authenticateUser() {
  return (dispatch) => {
    return Promise.allSettled([
      profileService.getUserProfile().then(([userData, companyData]) => {
        Sentry.setUser({ id: userData.id });
        Sentry.setExtra('roles', userData.roles);
        dispatch({
          type: types.UPDATE_USER_DATA,
          userData,
        });
        Cookies.set('UID', companyData.id, { expires: 365 });
        dispatch(companyAction.updateCompany(companyData));
        return [userData, companyData];
      }),
      dispatch(companyDocumentsActions.getTypesAndDocuments('company')),
    ]).catch(() => {
      dispatch(deauthorizeUser());
    });
  };
}

export function updateProfile(values, initialValues, setStatus) {
  return (dispatch) => {
    const [
      fullName,
      oldPassword,
      newPassword,
      email,
      position,
      comment,
      phone,
      icq,
      viber,
      skype,
      whats_app,
      telegram,
    ] = userSelectors.getUserProfileUpdateData(values);

    let hasChanges = !_isEqual(initialValues, values);
    let actions = [];
    let isPasswordChange = false;

    if (hasChanges) {
      actions.push(
        dispatch(
          updateUserProfile({
            setStatus,
            fullName,
            email,
            position,
            comment,
            phone,
            icq,
            viber,
            skype,
            whats_app,
            telegram,
          }),
        ),
      );
    }

    if (!!oldPassword && !!newPassword) {
      actions.push(
        changePassword({ oldPassword, newPassword })
          .then((data) => {
            isPasswordChange = true;
            return data;
          })
          .catch((error) => {
            const errorCode = error?.response.status;
            const errorDescription = error?.response.data.error_description;
            let errors = {};

            if (errorCode === 400) {
              errors[PASSWORD_NEW] = errorDescription;
            }
            setStatus(errors);
            return Promise.reject(errorCode);
          }),
      );
    }

    if (actions.length === 0) return Promise.resolve();

    return (
      Promise.all(actions)
        .then(() => {
          if (isPasswordChange) {
            dispatch(deauthorizeUser(isPasswordChange));
          }
          !isPasswordChange && toast.success('Профиль успешно обновлен');
        })
        // ignore errors with non 200 response code
        .catch(() => Promise.resolve())
    );
  };
}

export function updateUserProfile({ setStatus, ...rest }) {
  return (dispatch) => {
    return profileService
      .updateUserProfile({ ...rest })
      .then(([userData]) => {
        dispatch({
          type: types.UPDATE_USER_DATA,
          userData,
        });
        // пока не нужно обновлять компанию при обновлении пользователя
        //dispatch(companyAction.updateCompany(companyData));
      })
      .catch((error) => {
        const errorCode = error?.response.status;
        let errors = {};

        if (errorCode === 400) {
          errors[EMAIL] = 'Такой email уже зарегистрирован';
        }
        setStatus(errors);
        return Promise.reject(errorCode);
      });
  };
}

export function deauthorizeUser(isPasswordChange) {
  function backUrlAction() {
    if (process.env.NODE_ENV === 'production') {
      if (isPasswordChange) {
        history.push('/authorize?onchange');
      } else {
        window.location.href = process.env.REACT_APP_LOGOUT_URL;
      }
    } else {
      history.push(isPasswordChange ? '/authorize?onchange' : '/');
    }
  }

  return (dispatch) => {
    authService.logout().finally(() => {
      authTokenService.clearToken();
      dispatch({
        type: types.CLEAR_USER_DATA,
      });
      dispatch(companyAction.clearCompany());
      backUrlAction();
    });
  };
}
