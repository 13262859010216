import { BusySlotStatus } from '../../containers/scheduler/store/reducer';
type TStringOrNull = string | null;
type TNumberOrNull = number | null;

export type TCounterAgent = {
  readonly created_at: string;
  readonly human_friendly_id: string;
  readonly id: string;
  readonly legal_entity_inn: TStringOrNull;
  readonly legal_entity_kpp: TStringOrNull;
  readonly legal_entity_opf_name: TStringOrNull;
  readonly legal_entity_title: TStringOrNull;
  readonly private_person: boolean;
  readonly private_person_full_name: TStringOrNull;
  readonly private_person_passport_issued_at: TStringOrNull;
  readonly private_person_passport_issuer: TStringOrNull;
  readonly private_person_passport_number: TStringOrNull;
  readonly formatted_passport_issued_at?: TStringOrNull;
};

export type TContact = {
  readonly created_at: string;
  readonly email: TStringOrNull;
  readonly extension_number: TStringOrNull;
  readonly full_name: string;
  readonly id: string;
  readonly phone: string;
};

export enum TGeoLocationLevel {
  Point = 'point',
  House = 'house',
  Street = 'street',
  City = 'city',
}

export type TGeoLocation = {
  readonly area: TStringOrNull;
  readonly area_fias_id: TStringOrNull;
  readonly area_kladr_id: TStringOrNull;
  readonly area_type: TStringOrNull;
  readonly area_with_type: TStringOrNull;
  readonly city: TStringOrNull;
  readonly city_fias_id: TStringOrNull;
  readonly city_kladr_id: TStringOrNull;
  readonly city_type: TStringOrNull;
  readonly city_with_type: TStringOrNull;
  readonly country: TStringOrNull;
  readonly country_iso_code: TStringOrNull;
  readonly federal_district: TStringOrNull;
  readonly fias_id: TStringOrNull;
  readonly house: TStringOrNull;
  readonly house_fias_id: TStringOrNull;
  readonly house_kladr_id: TStringOrNull;
  readonly kladr_id: TStringOrNull;
  readonly lat: TNumberOrNull;
  readonly level: TGeoLocationLevel;
  readonly lng: TNumberOrNull;
  readonly office: TStringOrNull;
  readonly region: TStringOrNull;
  readonly region_fias_id: TStringOrNull;
  readonly region_iso_code: TStringOrNull;
  readonly region_kladr_id: TStringOrNull;
  readonly region_type: TStringOrNull;
  readonly region_with_type: TStringOrNull;
  readonly settlement: TStringOrNull;
  readonly settlement_fias_id: TStringOrNull;
  readonly settlement_kladr_id: TStringOrNull;
  readonly settlement_type: TStringOrNull;
  readonly settlement_with_type: TStringOrNull;
  readonly street: TStringOrNull;
  readonly street_fias_id: TStringOrNull;
  readonly street_kladr_id: TStringOrNull;
  readonly street_type: TStringOrNull;
  readonly street_with_type: TStringOrNull;
  readonly timezone_name: TStringOrNull;
  readonly timezone_offset: number; // Deprecated. Use ONLY for unbreaking changes
  readonly zip_code: TStringOrNull;
  readonly locality: string | null;
  readonly locality_with_type: string | null;
  readonly comment: string | null;
};

export type TLocation = {
  readonly contacts: TContact[];
  readonly contacts_list: string[];
  readonly counter_agent: TCounterAgent;
  readonly counter_agent_string?: TStringOrNull;
  readonly title?: TStringOrNull;
  readonly created_at: string;
  readonly geo_location: TGeoLocation;
  readonly location_string?: string;
  readonly human_friendly_id: string;
  readonly id: string;
  readonly working_hours: TStringOrNull;

  readonly is_warehouse: boolean;
  readonly warehouse_name: TStringOrNull;
  readonly warehouse_schedules: TWarehouseSchedule[];
  readonly warehouse_gates: TWarehouseGate[];
};

export type TWarehouseSchedule = {
  readonly day: number;
  readonly from: TStringOrNull;
  readonly till: TStringOrNull;
  readonly weekend: boolean;
};

export type TWarehouseGate = {
  id: string;
  name: string;
  gate_schedules: TGateSchedule[];
};

export type TGateSchedule = {
  day: number;
  from: TStringOrNull;
  till: TStringOrNull;
  slot_size: TNumberOrNull;
  pickup: boolean;
  pickup_from: TStringOrNull;
  pickup_till: TStringOrNull; // 10:00
  weekend: boolean;
};

/**
 * Фактическое расписание по воротам на день
 */
export type TGateDaySchedule = {
  gate_id: string;
  name: string;
  gate_schedules: TGateSchedule[];
  busy_slots: TBusySlot[];
};

export type TBusySlot = {
  id: string;
  from: string;
  till: string;
  car_brand: string;
  car_number: string;
  shipping_id: string;
  driver_full_name: string | null;
  driver_phone: string | null;
  executor: string | null;
  executor_inn: string | null;
  trailer_number: string | null;
  status: BusySlotStatus;
  parent_slot_id: string | null;
  change_request_id: string | null;
  change_resources_request_id: string | null;
  shipping_status: string;
};

export type TExtendedBusySlot = TBusySlot & {
  gate_id: string;
  gate_index: number;
  start: number;
  end: number;
  style: any;
  parent_slot: TExtendedBusySlot | null;
  child_slot: TExtendedBusySlot | null;

  /* ИТ */
  // изменилось время
  time_changed: boolean;
  // изменились ресурсы
  resources_changed: boolean;
  // есть ли доступ к измененным ресурсам
  has_resources_changes: boolean;
};

export type TAvailableMatrix = Array<Array<{ disabled: boolean; available: boolean; slotId: string | null }>>;

export type TUpdatedBusySlot = {
  busy_slot_id: string;
  to_gate_id: string;
  from: string;
  till: string;
};

export type TPickup = {
  id: string;
  email: string | null;
  phone: string | null;
  car_brand: string | null;
  car_number: string | null;
  trailer_brand: string | null;
  trailer_number: string | null;
  confirmed_at: string | null;
  dates: string[];
  driver_full_name: string | null;
  driver_phone: string | null;
  geo_location: Record<string, any>;
  hf_number: string;
  pickup_from: string | null;
  pickup_till: string | null;
  gate_id: string | null;
  deleted_at: string | null;
};

export type TPickupInterval = {
  gate_id: string;
  date: string;
  pickup_free_intervals: Record<'pickup_from' | 'pickup_till', string>[];
};
