import React, { useEffect, useState } from 'react';
import Popover from '../../../components/Popover';
import styles from './styles.module.scss';
import classNames from 'classnames';

export default function Popup({ content, children, timeout, className, ...rest }) {
  const [showPopup, setShowPopup] = useState(!timeout);

  useEffect(() => {
    if (!timeout) return;

    setTimeout(() => {
      setShowPopup(true);
    }, timeout);
  }, [timeout]);

  if (!showPopup) return ({ children }) => <>{children}</>;

  return (
    <Popover
      trigger={null}
      defaultTooltipShown={true}
      className={classNames(styles.popup, className)}
      withoutInline={true}
      {...rest}
      content={content}
    >
      {children}
    </Popover>
  );
}
