import http from './config/webConfig';

import pageListMapper from '../helpers/mappers/pageListMapper';

/**
 * Получение полного списка заявок
 * @param params
 * @param cancelToken
 * @returns {Promise<*>}
 */
export async function getAllShippings(params, cancelToken) {
  const url = '/request_view';
  return http.get(url, { params, cache: false, requestId: url, cancelToken: cancelToken?.token }).then(pageListMapper);
}

/**
 * Получение полного списка заявок для перевозчиков, еще не прошедших проверку
 * @param params
 * @param cancelToken
 * @returns {Promise<*>}
 */
export async function getAllShippingsForUnapprovedCompany(params, cancelToken) {
  const url = '/shipping_requests/preliminary';
  return http.get(url, { params, cache: false, requestId: url, cancelToken: cancelToken?.token }).then(pageListMapper);
}

/**
 * Получение полного списка исполнителей по заявкам
 * @param params
 * @returns {Promise<*>}
 */
export async function getSuggestionsCargoOwningCompanies(params) {
  const url = '/request_view/cargo_owning_companies';
  return http.get(url, { params }).then((r) => r.data.items);
}

/**
 * Получение заявки по id
 * @param id
 * @returns {Promise<*>}
 */
export async function getShipping(id) {
  const url = `/request_view/${id}`;
  let { data } = await http.get(url, { cache: false, requestI: url });
  return data;
}

/**
 * Бронирование заявки
 * @param shippingId {String} идетификатор заявки
 * @param expeditedPaymentDays {String | null} количество дней отсрочки для УО
 * @returns {Promise<*>}
 */
export async function reserveShipping(shippingId, expeditedPaymentDays = null) {
  const url = `/shipping_requests/${shippingId}/reserve`;
  return http.post(url, { expedited_payment_days: expeditedPaymentDays }, { cache: false }).then(_mapReservation);
}

/**
 * Забронировать заявку на бронь ТС
 * @param reserveId
 * @returns {Promise<*>}
 */
export async function reserveReservationRequest(reserveId) {
  const url = `/reservation_requests/${reserveId}/reserve`;
  return http.post(url, null, { cache: false }).then(_mapReservation);
}

/**
 * Получить информацию по заявке по идентификатору
 * @param shippingId {String}
 * @return {Promise<*>}
 */
export async function shippingRequestById(shippingId) {
  const url = `shipping_requests/${shippingId}`;
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Сделать ставку на заявку по идентификатору
 * @param shippingId {String}
 * @param bid {Number}
 * @param expeditedPaymentDays {String|null}
 * @param count {Number|null}
 * @param hours {number|null} - часы для почасовой оплаты
 * @return {Promise<*>}
 */
export async function setBidForShippingById(shippingId, bid, expeditedPaymentDays = null, count = null, hours = null) {
  const url = `/shipping_requests/${shippingId}/bids`;
  return http
    .post(
      url,
      { value: bid, shippings_count: count, expedited_payment_days: expeditedPaymentDays, min_work_hours: hours },
      { cache: false, requestId: url },
    )
    .then((response) => response.data);
}

/**
 * Выйти из торга по
 * @param shippingId
 * @param bidId
 * @return {Promise<response.data | {access_token, refresh_token, token_type, expires_in} | *>}
 */
export async function exitFromShippingAuction(shippingId, bidId) {
  const url = `/shipping_requests/${shippingId}/bids/${bidId}/archive`;
  const { data } = await http.put(url, {}, { cache: false, requestId: url });
  return data;
}

function _mapReservation({ data }) {
  return {
    id: data?.id,
    resourceDeadline: data?.resource_assignment_deadline,
  };
}

/**
 * Получить цену по предложению для ускоренной оплаты
 * @param shippingId
 * @param userBid
 * @param days
 * @param cancelToken
 * @return {Promise<response.data>}
 */
export async function getShippingRequestExpeditedPaymentPrice(shippingId, userBid, days, cancelToken = undefined) {
  const url = `/shipping_requests/${shippingId}/expedited_payment_price`;
  const params = { expedited_payment_days: days, price: userBid };
  return http
    .get(url, { params, cache: false, requestId: url, cancelToken: cancelToken?.token })
    .then((response) => response.data);
}

/**
 * Получение перевозки после которой идет указанное предложение
 * @param suggestionId
 * @returns {Promise<*>}
 */
export async function getShippingForSuggestion(suggestionId) {
  const url = `/request_view/${suggestionId}/before_shippings`;
  const params = { page: 1, per_page: 1 };
  return http.get(url, { params, cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получение следующих рейсов для предложения\перевозки
 * @param suggestionId
 * @param params
 * @returns {Promise<*>}
 */
export async function getSuggestionsForSuggestion(suggestionId, params = { page: 1, per_page: 1 }) {
  const url = `/request_view/${suggestionId}/next`;
  return http.get(url, { params, cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Обновить счетчик просмотров заявки
 * @param suggestionId
 * @returns {Promise<*>}
 */
export async function updateViewsCounter(suggestionId) {
  const url = `/request_view/${suggestionId}/viewed`;
  return http.post(url, {}, { cache: false, requestId: url }).then((response) => response.data);
}
