import React, { useMemo } from 'react';
import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import { ROUTE_POINTS } from '../../blocks/RoutesBlock';
import { TRANSPORT_BODY_TYPES } from '../../blocks/TransportBlock';
import { AWNING, BACK_COVER, FULL_METAL, ISOTHERMAL, REFRIGERATOR } from '../../../../store/resources/formKeys';
import NotificationBar, { TTheme } from '../../../../components/NotificationBar';
import { TYPES_FULL } from '../../../../components/DataListCollections/_helpers';

interface Props {
  className?: string;
  theme?: TTheme;
}

const CONDITION_BODY_TYPES = [AWNING, ISOTHERMAL, REFRIGERATOR, FULL_METAL];

function getSeparator(separator, itemsLength, index) {
  let _separator = '';
  if (index > 0) {
    const isLast = itemsLength - 1 === index;

    if (isLast) {
      _separator = ` ${separator} `;
    } else {
      _separator = ', ';
    }
  }

  return _separator;
}

function getBodyTypesText(bodyTypes) {
  const types = bodyTypes.map((key) => TYPES_FULL.get(key));
  return (
    <>
      {types.map((label, index) => {
        const separator = getSeparator('и', bodyTypes.length, index);
        return (
          <>
            {separator}«<span className="bold">{label}</span>»
          </>
        );
      })}
    </>
  );
}

function getOtherBodyTypesText(bodyTypes) {
  const types = bodyTypes.map((key) => TYPES_FULL.get(key));
  return (
    <>
      {types.map((label, index) => {
        const separator = getSeparator('или', bodyTypes.length, index);
        return (
          <>
            {separator}«{label}»
          </>
        );
      })}
    </>
  );
}

export function CoverTypeNotificationBlock({ className = '', theme = 'default' }: Props) {
  const [routePoints] = useFieldValue(ROUTE_POINTS) ?? [];
  const [bodyTypes] = useFieldValue(TRANSPORT_BODY_TYPES) ?? [];

  const showNotificationBar = useMemo(() => {
    if (!bodyTypes?.length) return false;
    // выбраны все типы из условия
    if (!CONDITION_BODY_TYPES.filter((type) => !bodyTypes.includes(type)).length) return false;
    // обязательно выбран тент
    const hasAwning = bodyTypes.includes(AWNING);

    const isRearOnly = routePoints?.every(
      (point) => point.route_cover_type?.length === 1 && point.route_cover_type[0]?.value === BACK_COVER,
    );
    return hasAwning && isRearOnly;
  }, [routePoints, bodyTypes]);

  if (!showNotificationBar) {
    return null;
  }

  const selectedConditionBodyTypes = CONDITION_BODY_TYPES.filter((type) => bodyTypes.includes(type));
  const selectedTypeText = selectedConditionBodyTypes.length > 1 ? 'Выбраны типы кузова' : 'Выбран тип кузова';
  const bodyTypesText = getBodyTypesText(selectedConditionBodyTypes);

  const notSelectedBodyTypes = CONDITION_BODY_TYPES.filter((type) => !bodyTypes.includes(type));
  const otherBodyTypesText = getOtherBodyTypesText(notSelectedBodyTypes);

  return (
    <NotificationBar
      theme={theme}
      className={className}
      text={
        <span>
          {selectedTypeText} {bodyTypesText}, загрузка/выгрузка только «<span className="bold">Задняя</span>». Обратите
          внимание, в таком случае может быть назначено транспортное средство с типом кузова {otherBodyTypesText}. Это
          не повлияет на качество и условия перевозки.
        </span>
      }
    />
  );
}
