import React from 'react';
import SVGIcon from './index';

const IconCancelCircle = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path
        fillRule="evenodd"
        d="M15 5C9.47 5 5 9.47 5 15s4.47 10 10 10 10-4.47 10-10S20.53 5 15 5zm5 13.59L18.59 20 15 16.41 11.41 20 10 18.59 13.59 15 10 11.41 11.41 10 15 13.59 18.59 10 20 11.41 16.41 15 20 18.59z"
      />
    </SVGIcon>
  );
};

IconCancelCircle.defaultProps = {
  color: 'base',
};

export default IconCancelCircle;
