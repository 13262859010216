type TPasswordValidationResult = {
  hasValidSymbols: boolean;
  hasValidLength: boolean;
  hasUpperCase: boolean;
  hasNumber: boolean;
};

const passwordValidation = (password: string = ''): Array<any> => {
  const hasValidSymbols: boolean = !!password && !/[^a-zA-Z0-9]/.test(password);
  const hasValidLength: boolean = !!password && password.replace(/\d/g, '').length >= 5;
  const hasUpperCase: boolean = /[A-Z]/.test(password);
  const hasNumber: boolean = /\d/.test(password);
  const isValid: boolean = hasValidSymbols && hasValidLength && hasUpperCase && hasNumber;

  return [
    {
      hasValidSymbols,
      hasValidLength,
      hasUpperCase,
      hasNumber,
    } as TPasswordValidationResult,
    isValid,
  ];
};

export default passwordValidation;
