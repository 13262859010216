import React from 'react';
import SVGIcon from './index';

function IconPhone(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1H16V19H4V1ZM9 16H11V18H9V16ZM14 3H6V15H14V3Z"
        fill={props.color}
      />
    </SVGIcon>
  );
}

IconPhone.defaultProps = {
  color: 'base',
};

export default React.memo(IconPhone);
