import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useMemo } from 'react';

export type TAvailableQueryValues = string | (string | null)[] | null;
export type TAvailableQueryObject = { [key: string]: TAvailableQueryValues };
type TRouteQueryMethods = {
  queryObj: TAvailableQueryObject; // queryString.ParsedQuery;
  get: (key: string) => TAvailableQueryValues;
  add: (params: TAvailableQueryObject) => TAvailableQueryObject;
  set: (params: TAvailableQueryObject) => void;
  remove: (keys: string[]) => void;
  reset: () => void;
};

/**
 * Работа с query
 * @return {{add: ((function({key: string}=): void)|*), set: ((function({key: string}=): void)|*), get: (function(string=): Array<string | null> | string), reset: ((function(): void)|*), remove: ((function([]=): void)|*)}}
 */
export function useRouterQuery(): TRouteQueryMethods {
  const history = useHistory();
  const search = window.location.search;

  const queryObj = useMemo(() => queryString.parse(search), [search]);

  /**
   * Получить значение параметра
   * @param key
   */
  const get: TRouteQueryMethods['get'] = (key) => {
    return queryString.parse(window.location.search)[key];
  };

  /**
   * Добавить параметр(ы) к текущим параметрам
   * @type {(function({ key: string }): void)|*}
   */
  const add: TRouteQueryMethods['add'] = (params) => {
    const newParams = {
      ...queryString.parse(window.location.search),
      ...params,
    };
    const _search = queryString.stringify(newParams);
    history.replace({ search: _search });
    return newParams;
  };

  /**
   * Установить параметры взамен текущих
   * @param params
   */
  const set: TRouteQueryMethods['set'] = (params) => {
    const search = queryString.stringify(params);
    history.replace({ search });
  };

  /**
   * Удалить параметры из query по ключам
   * @param keys
   */
  const remove: TRouteQueryMethods['remove'] = (keys) => {
    if (!keys?.length) return;
    const _search = queryString.parse(window.location.search);
    keys.forEach((key) => {
      delete _search[key];
    });
    const cleanSearch = queryString.stringify(_search);
    history.replace({ search: cleanSearch });
    return _search;
  };

  /**
   * Удалить все параметры
   */
  const reset: TRouteQueryMethods['reset'] = () => {
    history.replace({ search: '' });
  };

  return {
    queryObj,
    get,
    add,
    set,
    remove,
    reset,
  };
}
