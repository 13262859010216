import React, { useEffect } from 'react';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { useFormikContextSelector } from './FormikContext';

function useField<Val = any>(name: string): [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>] {
  const registerField = useFormikContextSelector((c) => c.registerField);
  const unregisterField = useFormikContextSelector((c) => c.unregisterField);
  const getFieldProps = useFormikContextSelector((c) => c.getFieldProps);
  const getFieldMeta = useFormikContextSelector((c) => c.getFieldMeta);
  const getFieldHelpers = useFormikContextSelector((c) => c.getFieldHelpers);

  useEffect(() => {
    if (name) {
      registerField(name, {
        validate: () => {},
      });
    }
    return () => {
      if (name) {
        unregisterField(name);
      }
    };
  }, [registerField, unregisterField, name]);

  return [getFieldProps(name), getFieldMeta(name), getFieldHelpers(name)];
}

function Field({
  validate,
  name,
  render,
  children,
  as: is, // `as` is reserved in typescript lol
  component,
  ...props
}: any) {
  const registerField = useFormikContextSelector((c) => c.registerField);
  const unregisterField = useFormikContextSelector((c) => c.unregisterField);
  const getFieldProps = useFormikContextSelector((c) => c.getFieldProps);

  useEffect(() => {
    registerField(name, {
      validate: validate,
    });
    return () => {
      unregisterField(name);
    };
  }, [registerField, unregisterField, name, validate]);

  const field = getFieldProps({ name, ...props });

  if (component) {
    // This behavior is backwards compat with earlier Formik 0.9 to 1.x
    if (typeof component === 'string') {
      const { innerRef, ...rest } = props;
      return React.createElement(component, { ref: innerRef, ...field, ...rest }, children);
    }
    // We don't pass `meta` for backwards compat
    return React.createElement(component, { field, form: null, ...props }, children);
  }

  // default to input here so we can check for both `as` and `children` above
  const asElement = is || 'input';

  if (typeof asElement === 'string') {
    const { innerRef, ...rest } = props;
    return React.createElement(asElement, { ref: innerRef, ...field, ...rest }, children);
  }

  return React.createElement(asElement, { ...field, ...props }, children);
}

const MemoField = React.memo(Field);

export { useField, MemoField as Field };
