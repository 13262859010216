import { getMappedCarTypes, getMappedTrailerTypes, TBodyType } from '../carTypesMapping';
import _get from 'lodash/get';
import { TBodyTypeItem } from '../../store/resources/bodyTypeOptions';
import { TOwningType } from '../../store/resources/formKeys';

interface IDimensionsVehicle {
  length: number | null;
  height: number | null;
  width: number | null;
}

interface IAuto {
  id: string;
  brand: string;
  volume: number;
  tonnage: number;
  kindOfCar: string;
  bodyType: TBodyType | TBodyTypeItem | null;
  transportType: string;
  number?: string;
  hasDimensionsVehicle: boolean;
  owningType: TOwningType;
  owningDocNumber: string | null;
  owningDocFrom: string | null;
  owningDocTill: string | null;
}
interface ICar extends IAuto {
  car_number: string;
}
interface ITrailer extends IAuto {
  trailer_number: string;
}

/**
 * Получаем список автомашин для autocomplete
 * @param car
 * @returns {{options: *}}
 */
export function getCarsOptions(car: ICar): {
  value: { number: string };
  label: string;
} {
  return {
    value: {
      number: car?.car_number,
      ..._getMappedResources(car),
    },
    label: `${_get(car, 'car_number')} ${_get(car, 'brand')}`,
  };
}

/**
 * Получаем список прицепов для autocomplete
 * @param trailer
 * @returns {{options: *}}
 */
export function getTrailersOptions(trailer: ITrailer): {
  value: { number: string };
  label: string;
} {
  return {
    value: {
      number: trailer?.trailer_number,
      ..._getMappedResources(trailer),
    },
    label: `${_get(trailer, 'trailer_number')} ${_get(trailer, 'brand')}`,
  };
}

/**
 * Получаем список водителей для autocomplete
 * @param driver
 * @returns {{options: *}}
 */
export function getDriversOptions(driver: { full_name: string; driver_secret_info: {} }): {
  label: string;
  value: {
    id: string;
    fullName: string;
    birthDate: string;
    phone: string;
    inn: string;
    isNeedPassportSubdivisionCode: boolean;
  };
} {
  const fullName: string = _get(driver, 'full_name');

  return {
    label: fullName,
    value: {
      id: _get(driver, 'id'),
      fullName,
      birthDate: _get(driver, 'driver_secret_info.common.birth_date'),
      phone: _get(driver, 'driver_secret_info.phones[0]', ''),
      inn: _get(driver, 'driver_secret_info.inn', ''),
      isNeedPassportSubdivisionCode:
        _get(driver, 'driver_secret_info.common.country_id') === 1 &&
        !_get(driver, 'driver_secret_info.doc.subdivision_code'),
    },
  };
}

/**
 * Получение ресурсов авто и прицепов для перевозки
 * @param item
 * @returns {{id: *, brand: *, bodyType: *, tonnage: *, volume: *, transportType: *}}
 * @private
 */
function _getMappedResources(item: {}): IAuto {
  const kindOfCar: string = _get(item, 'kind');
  const dimensions: IDimensionsVehicle = _get(item, 'dimensions_vehicle');
  const hasDimensionsVehicle = !!dimensions?.length && !!dimensions?.height && !!dimensions?.width;

  return {
    id: _get(item, 'id'),
    brand: _get(item, 'brand'),
    volume: _get(item, 'lifting_capacity.volume', null),
    tonnage: _get(item, 'lifting_capacity.tonnage', null),
    kindOfCar,
    bodyType:
      _get(getMappedCarTypes(kindOfCar), 'bodyType', null) || _get(getMappedTrailerTypes(kindOfCar), 'bodyType', null),
    transportType:
      _get(getMappedCarTypes(kindOfCar), 'vehicleType', null) ||
      _get(getMappedTrailerTypes(kindOfCar), 'lifting_capacity.transport_type', null),
    hasDimensionsVehicle,
    owningType: _get(item, 'owning_type'),
    owningDocNumber: _get(item, 'owning_doc_number'),
    owningDocFrom: _get(item, 'owning_doc_from'),
    owningDocTill: _get(item, 'owning_doc_till'),
  };
}
