import classNames from 'classnames';
import React, { useCallback, useEffect, useState, createContext } from 'react';

export const RowContext = createContext({ span: 0 });

const Row = (props) => {
  const { span, className, children } = props;
  const [margin, setMargin] = useState(0);

  useEffect(() => {
    if (!span || isNaN(+span)) {
      setMargin(0);
    } else {
      setMargin(span);
    }
  }, [span]);

  const getRowStyle = useCallback(() => {
    return {
      marginLeft: -margin / 2 + 'px',
      marginRight: -margin / 2 + 'px',
    };
  }, [margin]);

  return (
    <RowContext.Provider value={{ span: margin }}>
      <div className={classNames('flex', className)} style={getRowStyle()}>
        {children}
      </div>
    </RowContext.Provider>
  );
};

export default Row;
