import React from 'react';
import SVGIcon from './index';

export default function IconCheckFilled(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M2.00006 10C2.00006 5.584 5.58406 2 10.0001 2C14.4161 2 18.0001 5.584 18.0001 10C18.0001 14.416 14.4161 18 10.0001 18C5.58406 18 2.00006 14.416 2.00006 10ZM8.00006 12.17L14.5901 5.58L16.0001 7L8.00006 15L4.00006 11L5.41006 9.59L8.00006 12.17Z"
      />
    </SVGIcon>
  );
}

IconCheckFilled.defaultProps = { color: 'base' };
