import React, { useRef, useCallback } from 'react';
import {
  AUCTION_STAGES,
  BID_STEP,
  MAX_BID_WITH_VAT,
  MAX_BID_WITHOUT_TAX,
  MIN_BID_WITH_VAT,
  MIN_BID_WITHOUT_TAX,
  PRICE_WITH_VAT,
  PRICE_WITHOUT_TAX,
} from '../blocks/AuctionBlock';
import { toast } from '../../../components/Toaster';
import { useFieldValue } from '../../../components/FormikFieldsV3/hooks';
import { getPriceValue, getWithoutVat, getWithVat } from '../../../helpers/getPrice';
import { useFormikContextSelector } from '../../../components/FormikFieldsV3/FormikContext';
import calculateMinBidConsideringStep from '../_helpers/calculateMinBidConsideringStep';
import calculateMaxBidConsideringStep from '../_helpers/calculateMaxBidConsideringStep';

export function usePricesRecalculation(auctionStageIndex, fieldsPrefix = '', toNumber = false) {
  const _setFieldValue = useFormikContextSelector((c) => c.setFieldValue);
  const setFieldValue = toNumber ? (field, value) => _setFieldValue(field, Number(value)) : _setFieldValue;

  const fieldName = fieldsPrefix || `${AUCTION_STAGES}[${auctionStageIndex}]`;
  const [bidStep] = useFieldValue(`${fieldName}.${BID_STEP}`);
  const [priceWithoutTax] = useFieldValue(`${fieldName}.${PRICE_WITHOUT_TAX}`);
  const [maxBidWithoutTax] = useFieldValue(`${fieldName}.${MAX_BID_WITHOUT_TAX}`);
  const [minBidWithoutTax] = useFieldValue(`${fieldName}.${MIN_BID_WITHOUT_TAX}`);

  const [maxBidWithTax] = useFieldValue(`${fieldName}.${MAX_BID_WITH_VAT}`);
  const [minBidWithTax] = useFieldValue(`${fieldName}.${MIN_BID_WITH_VAT}`);

  const mutablePrice = useRef(priceWithoutTax ?? 0);
  const mutableMaxBidWithoutTax = useRef(maxBidWithoutTax ?? 0);
  const mutableMinBidWithoutTax = useRef(minBidWithoutTax ?? 0);

  /* Хэндлер на изменение стоимости без НДС */
  const handleChangePriceWithoutTax = useCallback(
    (value) => {
      setFieldValue(`${fieldName}.${PRICE_WITHOUT_TAX}`, getPriceValue(value));

      /* Расчитываем новые значения в соответствии с текущим шагом ставки */
      const minBidWithoutTax = calculateMinBidConsideringStep(value, bidStep);
      const maxBidWithoutTax = calculateMaxBidConsideringStep(value, bidStep);
      /* Меняем цены для аукциона, т.к. при переключении на аукцион поля должны быть заполнены */
      setFieldValue(`${fieldName}.${MIN_BID_WITHOUT_TAX}`, getPriceValue(minBidWithoutTax));
      setFieldValue(`${fieldName}.${MAX_BID_WITHOUT_TAX}`, getPriceValue(maxBidWithoutTax));

      setFieldValue(`${fieldName}.${PRICE_WITH_VAT}`, getPriceValue(getWithVat(value)));
      setFieldValue(`${fieldName}.${MIN_BID_WITH_VAT}`, getPriceValue(getWithVat(minBidWithoutTax)));
      setFieldValue(`${fieldName}.${MAX_BID_WITH_VAT}`, getPriceValue(getWithVat(maxBidWithoutTax)));
    },
    [bidStep, fieldName, setFieldValue],
  );

  /* Хэндлер на изменение стоимости с НДС */
  const handleChangePriceWithTax = useCallback(
    (value) => {
      setFieldValue(`${fieldName}.${PRICE_WITH_VAT}`, getPriceValue(value));

      const priceWithoutTax = getWithoutVat(value);
      setFieldValue(`${fieldName}.${PRICE_WITHOUT_TAX}`, getPriceValue(priceWithoutTax));

      /* Расчитываем новые значения в соответствии с текущим шагом ставки */
      const minBidWithoutTax = calculateMinBidConsideringStep(priceWithoutTax, bidStep);
      const maxBidWithoutTax = calculateMaxBidConsideringStep(priceWithoutTax, bidStep);

      /* Меняем цены для аукциона,т.к. при переключении на аукцион поля должны быть заполнены */
      setFieldValue(`${fieldName}.${MIN_BID_WITHOUT_TAX}`, getPriceValue(minBidWithoutTax));
      setFieldValue(`${fieldName}.${MAX_BID_WITHOUT_TAX}`, getPriceValue(maxBidWithoutTax));

      setFieldValue(`${fieldName}.${MIN_BID_WITH_VAT}`, getPriceValue(getWithVat(minBidWithoutTax)));
      setFieldValue(`${fieldName}.${MAX_BID_WITH_VAT}`, getPriceValue(getWithVat(maxBidWithoutTax)));
    },
    [bidStep, fieldName, setFieldValue],
  );

  /* Хэндлер на изменение максимальной ставки без НДС */
  const handleChangeMaxBidWithoutTax = useCallback(
    (value) => {
      setFieldValue(`${fieldName}.${MAX_BID_WITHOUT_TAX}`, getPriceValue(value));
      setFieldValue(`${fieldName}.${MAX_BID_WITH_VAT}`, getPriceValue(getWithVat(value)));
    },
    [fieldName, setFieldValue],
  );

  const handleChangeMaxBidWithTax = useCallback(
    (value) => {
      setFieldValue(`${fieldName}.${MAX_BID_WITH_VAT}`, getPriceValue(value));
      setFieldValue(`${fieldName}.${MAX_BID_WITHOUT_TAX}`, getPriceValue(getWithoutVat(value)));
    },
    [fieldName, setFieldValue],
  );

  /* Хэндлер на изменение минимальной ставки без НДС */
  const handleChangeMinBidWithoutTax = useCallback(
    (value) => {
      setFieldValue(`${fieldName}.${MIN_BID_WITHOUT_TAX}`, getPriceValue(value));
      setFieldValue(`${fieldName}.${MIN_BID_WITH_VAT}`, getPriceValue(getWithVat(value)));
    },
    [fieldName, setFieldValue],
  );

  const handleChangeMinBidWithTax = useCallback(
    (value) => {
      setFieldValue(`${fieldName}.${MIN_BID_WITH_VAT}`, getPriceValue(value));
      setFieldValue(`${fieldName}.${MIN_BID_WITHOUT_TAX}`, getPriceValue(getWithoutVat(value)));
    },
    [fieldName, setFieldValue],
  );

  /* Хэндлер на приведение максимальной ставки без НДС к шагу при блюре поля */
  const handleBlurMaxBidWithoutTax = useCallback(() => {
    if (!maxBidWithoutTax) {
      return;
    }
    const newMax = calculateMaxBidConsideringStep(priceWithoutTax, +bidStep, maxBidWithoutTax);
    if (!!newMax && +maxBidWithoutTax !== +newMax) {
      setFieldValue(`${fieldName}.${MAX_BID_WITHOUT_TAX}`, getPriceValue(newMax));
      toast.warning('Значения ставок скорректированы для кратности Шагу ставки');
    }
  }, [maxBidWithoutTax, priceWithoutTax, bidStep, setFieldValue, fieldName]);

  const handleBlurMaxBidWithTax = useCallback(() => {
    if (!maxBidWithTax) {
      return;
    }
    const newMaxWithoutVat = calculateMaxBidConsideringStep(priceWithoutTax, +bidStep, getWithoutVat(maxBidWithTax));
    if (!!newMaxWithoutVat && +maxBidWithoutTax !== +newMaxWithoutVat) {
      setFieldValue(`${fieldName}.${MAX_BID_WITHOUT_TAX}`, getPriceValue(newMaxWithoutVat));
      setFieldValue(`${fieldName}.${MAX_BID_WITH_VAT}`, getPriceValue(getWithVat(newMaxWithoutVat)));
      toast.warning('Значения ставок скорректированы для кратности Шагу ставки');
    }
  }, [maxBidWithTax, priceWithoutTax, bidStep, maxBidWithoutTax, setFieldValue, fieldName]);

  /* Хэндлер на приведение минимальной ставки без НДС к шагу при блюре поля */
  const handleBlurMinBidWithoutTax = useCallback(() => {
    if (!minBidWithoutTax) {
      return;
    }
    const newMin = calculateMinBidConsideringStep(priceWithoutTax, +bidStep, minBidWithoutTax);
    if (!!newMin && +minBidWithoutTax !== +newMin) {
      setFieldValue(`${fieldName}.${MIN_BID_WITHOUT_TAX}`, getPriceValue(newMin));
      toast.warning('Значения ставок скорректированы для кратности Шагу ставки');
    }
  }, [minBidWithoutTax, priceWithoutTax, bidStep, setFieldValue, fieldName]);

  const handleBlurMinBidWithTax = useCallback(() => {
    if (!minBidWithTax) {
      return;
    }
    const newMinWithoutVat = calculateMinBidConsideringStep(priceWithoutTax, +bidStep, getWithoutVat(minBidWithTax));
    if (!!newMinWithoutVat && +minBidWithoutTax !== +newMinWithoutVat) {
      setFieldValue(`${fieldName}.${MIN_BID_WITHOUT_TAX}`, getPriceValue(newMinWithoutVat));
      setFieldValue(`${fieldName}.${MIN_BID_WITH_VAT}`, getPriceValue(getWithVat(newMinWithoutVat)));
      toast.warning('Значения ставок скорректированы для кратности Шагу ставки');
    }
  }, [minBidWithTax, priceWithoutTax, bidStep, minBidWithoutTax, setFieldValue, fieldName]);

  const handleChangeBidStep = useCallback(
    (newBid) => {
      const newMin = calculateMinBidConsideringStep(mutablePrice.current, +newBid, mutableMinBidWithoutTax.current);
      const newMax = calculateMaxBidConsideringStep(mutablePrice.current, +newBid, mutableMaxBidWithoutTax.current);
      const changeFields = (value, field) => {
        setFieldValue(`${fieldName}.${field}`, value);
      };
      const shouldChangeMinFields =
        !!mutableMinBidWithoutTax.current && !!newMin && +mutableMinBidWithoutTax.current !== +newMin;
      const shouldChangeMaxFields =
        !!mutableMaxBidWithoutTax.current && !!newMax && +mutableMaxBidWithoutTax.current !== +newMax;
      if (shouldChangeMinFields) {
        changeFields(newMin, MIN_BID_WITHOUT_TAX);
        changeFields(getWithVat(newMin), MIN_BID_WITH_VAT);
      }
      if (shouldChangeMaxFields) {
        changeFields(newMax, MAX_BID_WITHOUT_TAX);
        changeFields(getWithVat(newMax), MAX_BID_WITH_VAT);
      }
      if (shouldChangeMinFields || shouldChangeMaxFields) {
        toast.warning('Значения ставок скорректированы для кратности Шагу ставки');
      }
    },
    [fieldName, setFieldValue],
  );

  const handleChangeAuctionType = useCallback(() => {
    const newMin = calculateMinBidConsideringStep(mutablePrice.current, +bidStep);
    const newMax = calculateMaxBidConsideringStep(mutablePrice.current, +bidStep);

    const changeFields = (value, field) => {
      setFieldValue(`${fieldName}.${field}`, value);
    };
    const shouldChangeMinFields =
      !!mutableMinBidWithoutTax.current && !!newMin && +mutableMinBidWithoutTax.current !== +newMin;
    const shouldChangeMaxFields =
      !!mutableMaxBidWithoutTax.current && !!newMax && +mutableMaxBidWithoutTax.current !== +newMax;
    if (shouldChangeMinFields) {
      changeFields(newMin, MIN_BID_WITHOUT_TAX);
      changeFields(getWithVat(newMin), MIN_BID_WITH_VAT);
    }
    if (shouldChangeMaxFields) {
      changeFields(newMax, MAX_BID_WITHOUT_TAX);
      changeFields(getWithVat(newMax), MAX_BID_WITH_VAT);
    }
    if (shouldChangeMinFields || shouldChangeMaxFields) {
      toast.warning('Значения ставок скорректированы для кратности Шагу ставки');
    }
  }, [bidStep, fieldName, setFieldValue]);

  // /* Хэндлер на изменение стоимости доп. услуг без НДС */ // TODO с контрактами
  // const handleChangeAdditionalServicesPriceWithoutTax = useCallback((value) => {
  //   setFieldValue(`${fieldName}.${PRICE_ADDITIONAL_SERVICES_WITH}`, getPriceValue(getWithVat(value)));
  // }, [fieldName, setFieldValue])
  //
  // /* Хэндлер на изменение стоимости доп. услуг с НДС */
  // const handleChangeAdditionalServicesPriceWithTax = useCallback((value) => {
  //   setFieldValue(`${fieldName}.${PRICE_ADDITIONAL_SERVICES}`, getPriceValue(getWithoutVat(value)));
  // }, [fieldName, setFieldValue])

  /* Эффект на обновление цены в рефе при изменении цены в форме */
  React.useEffect(() => {
    mutablePrice.current = priceWithoutTax || 0;
  }, [priceWithoutTax]);

  /* Эффект на обновление максимальной ставки без НДС в рефе при изменении значения в форме */
  React.useEffect(() => {
    mutableMaxBidWithoutTax.current = maxBidWithoutTax || 0;
  }, [maxBidWithoutTax]);

  /* Эффект на обновление максимальной ставки без НДС в рефе при изменении значения в форме */
  React.useEffect(() => {
    mutableMinBidWithoutTax.current = minBidWithoutTax || 0;
  }, [minBidWithoutTax]);

  return {
    // without vat
    handleChangePriceWithoutTax,
    handleChangeMaxBidWithoutTax,
    handleChangeMinBidWithoutTax,
    handleBlurMaxBidWithoutTax,
    handleBlurMinBidWithoutTax,
    // with vat
    handleChangePriceWithTax,
    handleChangeMinBidWithTax,
    handleChangeMaxBidWithTax,
    handleBlurMinBidWithTax,
    handleBlurMaxBidWithTax,
    // other
    handleChangeBidStep,
    handleChangeAuctionType,
  };
}
