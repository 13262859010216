import React from 'react';
import styles from './styles.module.scss';

type TProps = {
  direction: 'top' | 'bottom';
};

const IconTriangle: React.FunctionComponent<TProps> = (props) => {
  return <div className={styles[`triangle_${props.direction}`]} />;
};

export default IconTriangle;
