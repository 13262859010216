/* Шаги для формы ресурсов */
export const FORM_STEP = 'form';
export const DOCUMENTS_STEP = 'documents';
export const VALIDATION_RESULTS_STEP = 'validationResults';
/* Шаги для массовой загрузки ресурсов */
export const TEMPLATE_STEP = 'example';
export const UPLOAD_STEP = 'upload';
export const VALIDATION_STEP = 'validation';

export const DRIVERS = 'drivers';
export const CARS = 'cars';
export const TRAILERS = 'trailers';

export const CAR = 'car';
export const PHONE = 'phone';
export const DRIVER = 'driver';
export const TRAILER = 'trailer';
export enum RESOURCE_ENTITY_TYPE {
  DRIVER = 'driver',
  CAR = 'car',
  TRAILER = 'trailer',
}

// типы ТС
export const LORRY = 'lorry';
export const TRUCK = 'truck';

// типы кузова ТС
export const AWNING = 'awning';
export type TAWNING = typeof AWNING;
export const ISOTHERMAL = 'isothermal';
export type TISOTHERMAL = typeof ISOTHERMAL;
export const REFRIGERATOR = 'refrigerator';
export type TREFRIGERATOR = typeof REFRIGERATOR;
export const FULL_METAL = 'full_metal';
export type TFULL_METAL = typeof FULL_METAL;
export const DROPSIDE = 'dropside';
export type TDROPSIDE = typeof DROPSIDE;
export const TANKER = 'tanker';
export type TTANKER = typeof TANKER;
export const CONTAINER = 'container';
export type TCONTAINER = typeof CONTAINER;
export const TIMBER_CARRIER = 'timber_carrier';
export type TTIMBER_CARRIER = typeof TIMBER_CARRIER;
export const STEP_DECK = 'step_deck';
export type TSTEP_DECK = typeof STEP_DECK;
export type TCarBodyType =
  | TAWNING
  | TISOTHERMAL
  | TREFRIGERATOR
  | TFULL_METAL
  | TDROPSIDE
  | TTANKER
  | TCONTAINER
  | TTIMBER_CARRIER
  | TSTEP_DECK;

export const VIN = 'vin';
export const CHASSIS = 'chassis';
export type TIdentifyNumber = typeof VIN | typeof CHASSIS;

export const TONNAGE = 'tonnage';
export const VOLUME = 'volume';

export const OWN = 'own';
export const RENT = 'rent';
export const JOINT_OWNERSHIP = 'joint_ownership';
export const LEASING = 'leasing';
export const GRATUITOUS_USE = 'gratuitous_use';
export const OWNED_TYPES = [OWN, JOINT_OWNERSHIP];
export const RENTED_TYPES = [RENT, LEASING, GRATUITOUS_USE];
export type TOwningType = typeof OWN | typeof RENT | typeof JOINT_OWNERSHIP | typeof LEASING | typeof GRATUITOUS_USE;

// типы растентовки
export const FULL_COVER = 'full';
export type TFULL_COVER = typeof FULL_COVER;
export const BACK_COVER = 'rear';
export type TBACK_COVER = typeof BACK_COVER;
export const SIDE_COVER = 'lateral';
export type TSIDE_COVER = typeof SIDE_COVER;
export const TOP_COVER = 'upper';
export type TTOP_COVER = typeof TOP_COVER;

export type TCoverType = TFULL_COVER | TBACK_COVER | TSIDE_COVER | TTOP_COVER;

export enum EXTRA_NUMBER_TYPE {
  BODY_NUMBER = 'BODY_NUMBER',
  CHASSIS_NUMBER = 'CHASSIS_NUMBER',
}

export enum OWNER_TYPE {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum DOC_RECOGNITION_STATUS {
  NO_DOCUMENT = 'no_document',
  RECOGNIZED = 'recognized',
  PARTLY_RECOGNIZED = 'partly_recognized',
  UNRECOGNIZED = 'unrecognized',
}

export enum ALLOWED_DRIVER_LICENSE_CATEGORY {
  B = 'B',
  B1 = 'B1',
  C = 'C',
  C1 = 'C1',
  D = 'D',
  D1 = 'D1',
  BE = 'BE',
  CE = 'CE',
  C1E = 'C1E',
  DE = 'DE',
  D1E = 'D1E',
}

export enum RESOURCE_DOC_TYPE {
  VEHICLE_REGISTRATION_CERTIFICATE = 'VEHICLE_REGISTRATION_CERTIFICATE',
  OWNERSHIP_DOCUMENT = 'OWNERSHIP_DOCUMENT',
  DRIVER_LICENCE = 'DRIVER_LICENCE',
  PASSPORT_MAIN_PAGE = 'PASSPORT_MAIN_PAGE',
  PASSPORT_REGISTRATION_PAGE = 'PASSPORT_REGISTRATION_PAGE',
  CIVIL_CONTRACT = 'CIVIL_CONTRACT',
}

export const DRIVER_RECOGNIZABLE_DOCS_TYPES = [
  RESOURCE_DOC_TYPE.DRIVER_LICENCE,
  RESOURCE_DOC_TYPE.PASSPORT_MAIN_PAGE,
  RESOURCE_DOC_TYPE.PASSPORT_REGISTRATION_PAGE,
];

export const VEHICLE_RECOGNIZABLE_DOCS_TYPES = [RESOURCE_DOC_TYPE.VEHICLE_REGISTRATION_CERTIFICATE];
