import * as types from './actionTypes';

// Начальное состояние store для заявок на перевозку
const initialState = {
  items: [],
  totalItems: 0,
  activeItemId: null,
  currentPage: 0,
  current: null,
};

export default function reduce(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    // Сохранения списка запросов на перевозку в store
    case types.INIT_REQUIREMENTS_CHANGE:
      return {
        ...state,
        ...payload,
      };
    case types.UPDATE_REQUIREMENTS_CHANGE:
      return {
        ...state,
        items: [...state.items, ...payload.items],
        currentPage: payload.currentPage,
        totalItems: payload.totalItems,
      };
    // Сохранение текущего запроса на перевозку в store
    case types.SET_REQUIREMENTS_CHANGE_CURRENT:
      return {
        ...state,
        current: payload,
      };
    case types.ACTIVE_ITEM_ID_SET:
      return {
        ...state,
        activeItemId: payload,
      };
    default:
      return state;
  }
}
