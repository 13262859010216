import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { TableContext } from './Table';
import { useLocalStorage } from '../../../hooks/useStorages';
import _ from 'lodash';
import { SIDE_CELL_WIDTH } from './constants';
import { useMatomoEvent } from '../../../features/matomo-analytics/hooks';
import { TABLE } from '../../../features/matomo-analytics/eventActionConstants';

const SETTINGS_FIELDS = {
  PINNED: 'pinned',
  VISIBLE: 'visible',
};
export function useSavingTableSettings(tableKey) {
  const { get: getFormLS, set: setToLS, remove: resetLS } = useLocalStorage();

  const get = () => {
    if (!tableKey) return;
    const value = getFormLS(tableKey);
    return value && JSON.parse(value);
  };

  const getActiveColumns = (field) => {
    const storageSettings = get();
    if (!storageSettings) return;

    return Object.keys(storageSettings).filter((id) => storageSettings[id]?.[field] === true);
  };

  const getMergedColumnSettings = (columnIdOrIds, settings) => {
    const storageSettings = get() || {};

    const updateColumnSettings = (id) => {
      storageSettings[id] = {
        // settings fields:
        [SETTINGS_FIELDS.PINNED]: settings?.[SETTINGS_FIELDS.PINNED] ?? storageSettings?.[id]?.[SETTINGS_FIELDS.PINNED],
        [SETTINGS_FIELDS.VISIBLE]:
          settings?.[SETTINGS_FIELDS.VISIBLE] ?? storageSettings?.[id]?.[SETTINGS_FIELDS.VISIBLE],
      };
    };

    if (Array.isArray(columnIdOrIds)) {
      columnIdOrIds.forEach(updateColumnSettings);
    } else {
      updateColumnSettings(columnIdOrIds);
    }

    return storageSettings;
  };

  const save = (columnIdOrIds, settings) => {
    if (!tableKey) return;
    const string = JSON.stringify(getMergedColumnSettings(columnIdOrIds, settings));
    setToLS(tableKey, string);
  };

  const columnsOrder = get() ? Object.keys(get()) : null;

  const setColumnsOrder = (ids) => {
    const storageSettings = get() || {};

    const newSettingsOrder = ids.reduce((acc, id) => {
      acc[id] = storageSettings[id] || {};
      return acc;
    }, {});
    const string = JSON.stringify(newSettingsOrder);
    setToLS(tableKey, string);
  };

  const reset = () => {
    if (!tableKey) return;
    resetLS(tableKey);
  };

  return {
    fields: SETTINGS_FIELDS,
    save,
    getActiveColumns,
    columnsOrder,
    setColumnsOrder,

    get,
    reset,
  };
}

export function useTableContext() {
  const context = useContext(TableContext);
  return { ...context };
}

function calcWidthMap(columns, rowRef) {
  if (!rowRef?.current?.children || !columns.length) return {};
  const res = {};
  columns.forEach((col) => {
    const el = Array.from(rowRef.current.children).find((el) => {
      const childColumnId = el.attributes.getNamedItem('table-column-id')?.value;
      return childColumnId === col.props.id;
    });
    if (!el) return;
    res[col.props.id] = el.clientWidth;
  });
  return res;
}
function calcOffsetsMap(columns, columnsWidthMap) {
  if (!columns || !Object.keys(columnsWidthMap).length) return;
  let summaryOffset = 0;
  const res = {};
  columns.forEach((col) => {
    res[col.props.id] = summaryOffset;
    summaryOffset += columnsWidthMap[col.props.id];
  });
  return res;
}
function calcStickyColumnsOffset(columnsIds, columnsWidthMap, initialOffset) {
  if (!Object.keys(columnsWidthMap).length) return;
  let summaryLeftMargin = initialOffset;
  const res = {};
  columnsIds &&
    columnsIds.forEach((id) => {
      res[id] = summaryLeftMargin;
      summaryLeftMargin += columnsWidthMap[id];
    });
  return res;
}

export function useStickyColumns(
  isScrollVisible,
  visibleColumns,
  lockedColumnsIds,
  tableContainerRef,
  tableRef,
  rowRef,
) {
  const [stickyColumnsLeftOffsets, setStickyColumnsLeftOffsets] = useState({});
  const [stickyColumnsRightOffsets, setStickyColumnsRightOffsets] = useState({});

  const widthMap = useRef({});
  const leftStickyColumnsOffsets = useRef({});
  const rightStickyColumnsOffsets = useRef({});
  const leftOffsets = useRef({});
  const rightOffsets = useRef({});

  const [shadowsMap, setShadowsMap] = useState();
  const leftColumnWithShadowIdRef = useRef();
  const rightColumnWithShadowIdRef = useRef();

  const offsetsRef = useRef({ left: 0, right: 0 });
  const interval = useRef();

  const updateShadowsMap = useCallback(() => {
    if (!leftOffsets.current || !rightOffsets.current) return;

    const getLastStickyColumnId = (columnsInRightOrder, stickyColumnsOffsets, offsets, tableOffset) => {
      let lastStickyColumnId = null;
      columnsInRightOrder.find((col) => {
        const id = col.props.id;
        const totalStickyColumnsOffset = !lastStickyColumnId
          ? 0
          : stickyColumnsOffsets[lastStickyColumnId] + widthMap.current?.[lastStickyColumnId];
        let hiddenWidth = tableOffset + totalStickyColumnsOffset;

        if (hiddenWidth > offsets?.[id]) {
          if (lockedColumnsIds?.includes(id)) {
            lastStickyColumnId = id.toString();
            hiddenWidth += widthMap.current?.[id];
          }
        }
        return hiddenWidth <= offsets?.[id];
      });
      return lastStickyColumnId;
    };

    widthMap.current = calcWidthMap(visibleColumns, rowRef);
    leftOffsets.current = calcOffsetsMap(visibleColumns, widthMap.current);
    rightOffsets.current = calcOffsetsMap(visibleColumns.slice().reverse(), widthMap.current);

    // если первая колонка закреплена, то ей не нужен дополнительный отступ
    const initialLeftOffset = lockedColumnsIds?.length
      ? visibleColumns?.[0]?.props.id === lockedColumnsIds?.[0]
        ? 0
        : SIDE_CELL_WIDTH
      : 0;
    const _leftStickyColumnsOffsets = calcStickyColumnsOffset(lockedColumnsIds, widthMap.current, initialLeftOffset);

    const initialRightOffset = lockedColumnsIds?.length
      ? visibleColumns?.[visibleColumns.length - 1]?.props.id === lockedColumnsIds?.[lockedColumnsIds?.length - 1]
        ? 0
        : SIDE_CELL_WIDTH
      : 0;
    const _rightStickyColumnsOffsets = calcStickyColumnsOffset(
      lockedColumnsIds.slice().reverse(),
      widthMap.current,
      initialRightOffset,
    );

    if (!_.isEqual(_leftStickyColumnsOffsets, leftStickyColumnsOffsets.current)) {
      leftStickyColumnsOffsets.current = _leftStickyColumnsOffsets;
      setTimeout(() => setStickyColumnsLeftOffsets(_leftStickyColumnsOffsets), 0);
    }

    if (!_.isEqual(_rightStickyColumnsOffsets, rightStickyColumnsOffsets.current)) {
      rightStickyColumnsOffsets.current = _rightStickyColumnsOffsets;
      setTimeout(() => setStickyColumnsRightOffsets(_rightStickyColumnsOffsets), 0);
    }

    const _leftColumnWithShadowId = getLastStickyColumnId(
      visibleColumns,
      leftStickyColumnsOffsets.current,
      leftOffsets.current,
      offsetsRef.current.left,
    );
    const _rightColumnWithShadowId = getLastStickyColumnId(
      visibleColumns.slice().reverse(),
      rightStickyColumnsOffsets.current,
      rightOffsets.current,
      offsetsRef.current.right,
    );

    if (
      _leftColumnWithShadowId !== leftColumnWithShadowIdRef.current ||
      _rightColumnWithShadowId !== rightColumnWithShadowIdRef.current
    ) {
      leftColumnWithShadowIdRef.current = _leftColumnWithShadowId;
      rightColumnWithShadowIdRef.current = _rightColumnWithShadowId;
      setTimeout(
        () =>
          setShadowsMap({
            left: _leftColumnWithShadowId,
            right: _rightColumnWithShadowId,
          }),
        0,
      );
    }
  }, [visibleColumns, rowRef, lockedColumnsIds]);

  useEffect(() => {
    updateShadowsMap();
  }, [updateShadowsMap]);

  const setOffsetRef = useCallback(
    (leftOffset = 0) => {
      const container = tableContainerRef?.current;
      const table = tableRef?.current;
      const rightOffset = container ? table.clientWidth - container.clientWidth - leftOffset : 0;
      offsetsRef.current = { left: leftOffset, right: rightOffset };
    },
    [tableContainerRef, tableRef],
  );

  useEffect(() => {
    if (isScrollVisible) {
      if (interval.current) clearInterval(interval.current);
      interval.current = setInterval(() => updateShadowsMap(), 17);
    } else {
      if (interval.current) clearInterval(interval.current);
      setOffsetRef();
      setTimeout(() => updateShadowsMap(), 20);
    }
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [isScrollVisible, tableContainerRef, tableRef, setOffsetRef, updateShadowsMap]);

  const scrollHandler = useCallback(
    (e) => {
      const leftOffset = Math.round(e.target.scrollLeft);
      setOffsetRef(leftOffset);
    },
    [setOffsetRef],
  );

  useEffect(() => {
    const el = tableContainerRef.current;
    el.addEventListener('scroll', scrollHandler, { passive: true });
    setOffsetRef(el.scrollLeft);
    return () => {
      interval.current && clearInterval(interval.current);
      el.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler, setOffsetRef, tableContainerRef]);

  return {
    stickyColumnsLeftOffsets,
    stickyColumnsRightOffsets,
    shadowsMap,
    redraw: updateShadowsMap,
  };
}

export function useTableMatomoEvent(eventName, _matomoEventsCategory = '') {
  const { matomoEventsCategory } = useTableContext();
  const category = matomoEventsCategory || _matomoEventsCategory;
  const sendEvent = useMatomoEvent(category, TABLE, eventName);

  return category ? sendEvent : () => {};
}
