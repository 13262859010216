import React from 'react';

import { IconAssignmentCheck, IconExclamation, IconTimer } from '../../Icons';
import * as queryKeys from '../../../store/suggestions/queryKeys';

const DOC_STATUSES = new Map()
  .set('documents_accepted', <IconAssignmentCheck width="20" height="20" color="success" />)
  .set('document_errors', <IconExclamation width="20" height="20" color="danger" />)
  .set('documents_late', <IconTimer width="20" height="20" color="danger" />);

/**
 * Статус документов для ЗАВЕРШЕННЫХ перевозок
 * @param item
 */
export function getDocumentsStatusIcon(item) {
  const { closingDocsStatus, scanCopyStatus } = item;
  if (scanCopyStatus === queryKeys.NEED_RESIGN) {
    return <IconExclamation width="20" height="20" color="danger" />;
  }
  return DOC_STATUSES.get(closingDocsStatus) ?? null;
}
