enum Documents {
  passport = 'passport',
  identification_card = 'identification_card',
  refugee_certificate = 'refugee_certificate',
  biometric_passport = 'biometric_passport',
}

const Documents_ = new Map()
  .set(Documents.passport, 'Паспорт')
  .set(Documents.identification_card, 'ID-карта')
  .set(Documents.refugee_certificate, 'Удостоверение беженца')
  .set(Documents.biometric_passport, 'Биометрический паспорт');

const DOCUMENTS = [
  { value: Documents.passport, label: Documents_.get(Documents.passport) },
  { value: Documents.biometric_passport, label: Documents_.get(Documents.biometric_passport) },
  { value: Documents.identification_card, label: Documents_.get(Documents.identification_card) },
  { value: Documents.refugee_certificate, label: Documents_.get(Documents.refugee_certificate) },
];

enum Countries {
  RU = 'Россия',
  BY = 'Беларусь',
  KZ = 'Казахстан',
  KG = 'Киргизия',
  UZ = 'Узбекистан',
  LV = 'Латвия',
  TJ = 'Таджикистан',
}

const Countries_ = new Map()
  .set(1, 'Российская Федерация')
  .set(2, 'Республика Беларусь')
  .set(3, 'Республика Казахстан')
  .set(4, 'Украина')
  .set(5, 'ЛНР')
  .set(6, 'ДНР')
  .set(7, 'Киргизия')
  .set(8, 'Узбекистан')
  .set(9, 'Латвия')
  .set(10, 'Таджикистан');

const COUNTRIES = Array.from(Countries_).map(([value, label]) => ({ value, label }));

export { Documents, Documents_, DOCUMENTS, Countries, Countries_, COUNTRIES };
