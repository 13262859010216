import toFloatNumber from '../../helpers/toFloatNumber';

/**
 * Нормализация максимального значения тоннажа
 * @param value
 * @returns {number}
 * @private
 */
export const maxTonnageNormalize = (value) => {
  if (value < 0) return 0;
  if (value > 50) return 50;

  return toFloatNumber(value);
};

/**
 * Нормализация значений радиуса
 * @param value
 * @returns {number}
 * @private
 */
export const radiusNormalize = (value) => {
  if (value < 0) return 0;
  if (value > 7000) return 7000;

  return Number.parseInt(value, 10) || null;
};

/**
 * Маппинг ключа сортировки
 */
export const getSortMappedKey = (sortKey, isWithTax) => {
  const keysToMap = ['price', 'cost_per_km', 'cost_per_h', 'total_cost'];
  if (!keysToMap.includes(sortKey)) return sortKey;

  return isWithTax ? `${sortKey}_with_vat` : `${sortKey}_without_vat`;
};
