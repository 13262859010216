import parse from 'date-fns/parse';
import format from 'date-fns-tz/format';
import addHours from 'date-fns/addHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';

import { getDateWithTimezone } from './getDateWithTimezone';
import {
  AUCTION_STAGES,
  BEGINS_AT,
  DEFAULT_AUCTION_STAGE_DURATION,
  ENDS_AT,
  IS_EXECUTOR_KNOWN,
  VISIBILITY,
} from '../blocks/AuctionBlock';
import { DEFAULT_APP_TIME_ZONE_NAME, DF_DATE_TIME_PICKER_FORMAT } from '../../../constants';
import { getHalfHourRounded } from './getHalfHourRounded';
import { ALL_PEOPLE } from '../../../store/order/auction/formKeys';
import { AGREEMENT_TYPE, TERMS_BY_PARTNERSHIP_CONTRACT } from '../blocks/DocumentsBlock';

/**
 * Получить продолжительность этапа торга
 * @param auctionStageBeginsAt
 * @param auctionStageEndsAt
 */
export function getAuctionStageInfo(auctionStageBeginsAt, auctionStageEndsAt): number {
  const parsedBeginsAt = auctionStageBeginsAt
    ? parse(auctionStageBeginsAt, DF_DATE_TIME_PICKER_FORMAT, new Date())
    : new Date();
  const parsedEndsAt = auctionStageEndsAt
    ? parse(auctionStageEndsAt, DF_DATE_TIME_PICKER_FORMAT, new Date())
    : new Date();
  return Math.max(differenceInMinutes(parsedEndsAt, parsedBeginsAt), 0);
}

/**
 * Получение дат для этапа торга (по-умолчанию)
 * на основании значения из поля окончание торга в предыдущем этапе
 * @param prevAuctionStageEndsAt
 */
export function getAuctionStageDates(prevAuctionStageEndsAt) {
  if (prevAuctionStageEndsAt) {
    const beginsAtDatetime = getDateWithTimezone(
      prevAuctionStageEndsAt,
      DEFAULT_APP_TIME_ZONE_NAME,
      DF_DATE_TIME_PICKER_FORMAT,
    );
    const formattedBeginsAt = format(beginsAtDatetime, DF_DATE_TIME_PICKER_FORMAT, {
      timeZone: DEFAULT_APP_TIME_ZONE_NAME,
    });
    const formattedEndsAt = format(
      addHours(beginsAtDatetime, DEFAULT_AUCTION_STAGE_DURATION),
      DF_DATE_TIME_PICKER_FORMAT,
      {
        timeZone: DEFAULT_APP_TIME_ZONE_NAME,
      },
    );

    return { [BEGINS_AT]: formattedBeginsAt, [ENDS_AT]: formattedEndsAt };
  }
  return { [BEGINS_AT]: null, [ENDS_AT]: null };
}

/**
 * Получение дат для этапа торга (по-умолчанию)
 * на основании индекса текущего этапа торга
 * @param auctionStageIndex
 */
export function getAuctionStageDefaultDates(auctionStageIndex) {
  let beginsAt = format(
    getHalfHourRounded(addHours(new Date(), auctionStageIndex * DEFAULT_AUCTION_STAGE_DURATION)),
    DF_DATE_TIME_PICKER_FORMAT,
    {
      timeZone: DEFAULT_APP_TIME_ZONE_NAME,
    },
  );
  if (auctionStageIndex === 0) {
    beginsAt = format(
      addHours(new Date(), auctionStageIndex * DEFAULT_AUCTION_STAGE_DURATION),
      DF_DATE_TIME_PICKER_FORMAT,
      {
        timeZone: DEFAULT_APP_TIME_ZONE_NAME,
      },
    );
  }
  return {
    [BEGINS_AT]: beginsAt,
    [ENDS_AT]: format(
      getHalfHourRounded(
        addHours(new Date(), auctionStageIndex * DEFAULT_AUCTION_STAGE_DURATION + DEFAULT_AUCTION_STAGE_DURATION),
      ),
      DF_DATE_TIME_PICKER_FORMAT,
      {
        timeZone: DEFAULT_APP_TIME_ZONE_NAME,
      },
    ),
  };
}

export function getIsAuctionForAll(order) {
  if (order[IS_EXECUTOR_KNOWN]) return false;
  if (order[AUCTION_STAGES]?.length) return !!order[AUCTION_STAGES].find((stage) => stage[VISIBILITY] === ALL_PEOPLE);
  if (order[AGREEMENT_TYPE] && order[TERMS_BY_PARTNERSHIP_CONTRACT]) return true;
  return false;
}
