import React from 'react';
import SVGIcon from './index';

function IconUserManual(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 17V3H6.5C8 3 9.5 4 10 5C10.5 4 12 3 13.5 3H18V17L11.7324 17C11.3866 17.5978 10.7403 18 10 18C9.25972 18 8.61337 17.5978 8.26756 17L2 17ZM15 8V6L13 6V8H15ZM15 14V9H13V14H15Z"
      />
    </SVGIcon>
  );
}

IconUserManual.defaultProps = {
  color: 'primary',
};

export default React.memo(IconUserManual);
