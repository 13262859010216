import React from 'react';
import SVGIcon from './index';

function IconTools(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path fillRule="evenodd" d="M10.937 3H15v4h-5v9H3V3h4.063a2 2 0 013.874 0zM8.5 3.5a.5.5 0 111 0 .5.5 0 01-1 0z" />
      <path
        fillRule="evenodd"
        d="M11 8h7v10h-7V8zm1 1h5v2h-5V9zm0 3h1v1h-1v-1zm3 0h-1v1h1v-1zm-3 2h1v1h-1v-1zm3 0h-1v1h1v-1zm-3 2h1v1h-1v-1zm3 0h-1v1h1v-1zm1-2h1v3h-1v-3zm1-2h-1v1h1v-1z"
      />
    </SVGIcon>
  );
}

IconTools.defaultProps = {
  color: 'base',
};

export default React.memo(IconTools);
