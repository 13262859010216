import React from 'react';
import SVGIcon from './index';

export default function IconAttentionFilled(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <rect x="0" y="0" width="20" height="20" rx="10" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9 6V11H11V6H9ZM9 12V14H11V12H9Z"
      />
    </SVGIcon>
  );
}
