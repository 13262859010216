import classNames from 'classnames';
import React, { Fragment } from 'react';

import styles from './styles.module.scss';

import { useOrderContext } from '../../index';
import {
  CONTRACT_BY_COUNT,
  CONTRACT_BY_SCHEDULE,
  ORDER_TYPE,
  SHIPPING_REQUEST,
} from '../../blocks/OrderNFormTypeBlock';

import { Field } from '../../../../components/FormikFieldsV3/Field';
import FieldLabel from '../../../../components/FieldsCollection/FieldLabel';
import IconShipping from '../../../../components/Icons/SVGIcons/IconShipping';
import IconGraphics from '../../../../components/Icons/SVGIcons/IconGraphics';
import IconTimesheet from '../../../../components/Icons/SVGIcons/IconTimesheet';

function OrderType() {
  const { canCreateInstant, canCreateAuction, canCreateContract } = useOrderContext();

  const ORDER_TYPES = [
    {
      value: SHIPPING_REQUEST,
      icon: <IconShipping width="20" height="20" />,
      title: 'Разовая заявка',
      text: 'одна перевозка с точными датами подачи ТС',
      disabled: !canCreateInstant && !canCreateAuction,
    },
    {
      value: CONTRACT_BY_COUNT,
      icon: <IconGraphics width="20" height="20" />,
      title: 'Объёмный контракт',
      text: 'несколько перевозок по одному маршруту и условиям за указанный период времени',
      disabled: !canCreateContract,
    },
    {
      value: CONTRACT_BY_SCHEDULE,
      icon: <IconTimesheet width="20" height="20" />,
      title: 'Контракт по расписанию',
      text: 'несколько перевозок по одному маршруту и условиям с точными датами подачи ТС',
      disabled: true,
    },
  ];

  return (
    <Fragment>
      <FieldLabel title="Вид заказа" />
      <div role="group" className="flex content-between mb-5">
        {ORDER_TYPES.map((type) => (
          <React.Fragment key={type.value}>
            <Field
              id={type.value}
              type="radio"
              name={ORDER_TYPE}
              value={type.value}
              disabled={type.disabled}
              className={classNames('visually-hidden', styles.input)}
            />
            <label
              htmlFor={type.value}
              style={{
                width: `${100 / ORDER_TYPES.length}%`,
              }}
              className={styles.label}
            >
              <span className="mr-2">{type.icon}</span>
              <span>
                <h6 className={classNames(styles.title)}>{type.title}</h6>
                <p className={classNames(styles.text, 'fs-12 leading-3')}>{type.text}</p>
              </span>
            </label>
          </React.Fragment>
        ))}
      </div>
    </Fragment>
  );
}

export default OrderType;
