import { useCallback, useMemo, useState } from 'react';

import useDidMount from './useDidMount';
import { toast } from '../components/Toaster';
import { useUserContext } from '../contexts/userContext';
import { ACTIVE } from '../store/partners/partners/statusKeys';
import { PER_PAGE, STATUS } from '../store/partners/queryKeys';
import { getMappedPartnerItem } from '../store/partners/partners/selectors';
import {
  getCompaniesAndGroupsByIds,
  getPartnersGroupsList as getPartnersGroupsListService,
  getPartnersList as getPartnersListService,
} from '../services/partnersService';
import { useQuery } from 'react-query';

/* Получение списка партнеров */
export function useGetPartnerList() {
  const { companyId } = useUserContext();
  return useCallback(
    (params = {}) =>
      getPartnersListService({ [PER_PAGE]: 99999, [STATUS]: [ACTIVE], ...params }, true)
        .then((data) => data.items.map((item) => getMappedPartnerItem(item, companyId)))
        .catch(toast.errorAsync),
    [companyId],
  );
}

/* Получение списка партнеров */
export function useGetPartnerList_() {
  const { companyId } = useUserContext();
  return useCallback(
    (params = {}) =>
      getPartnersListService({ [PER_PAGE]: 99999, [STATUS]: [ACTIVE], ...params }, true)
        .then((data) => ({ ...data, items: data.items.map((item) => getMappedPartnerItem(item, companyId)) }))
        .catch(toast.errorAsync),
    [companyId],
  );
}

/* Получение списка партнерских групп */
export function useGetPartnersGroupList(requestParams = {}) {
  return useCallback(() => getPartnersGroupsListService(requestParams, true).catch(toast.errorAsync), []);
}

/**
 * Получение списка всех партнеров и партнерских групп
 */
export function useGetPartners() {
  const [partnerGroupList, setPartnerGroupList] = useState<any>([]);
  const [partnerList, setPartnerList] = useState<any>([]);

  /* Получение списка партнерских групп */
  const getPartnersGroupsList = useGetPartnersGroupList();
  /* Получение списка партнеров */
  const getPartnersList = useGetPartnerList();

  useDidMount(() => {
    getPartnersGroupsList().then(setPartnerGroupList);
    getPartnersList().then(setPartnerList);
  });

  return { partnerList, partnerGroupList };
}

export function useGetPartnersGroups(requestParams = {}) {
  const [partnerGroupList, setPartnerGroupList] = useState<any>([]);

  /* Получение списка партнерских групп */
  const getPartnersGroupsList = useGetPartnersGroupList(requestParams);

  useDidMount(() => {
    getPartnersGroupsList().then(setPartnerGroupList);
  });

  return { partnerGroupList };
}

export function useGetPartnershipsWithCompanies(companiesIds: string[]) {
  const { isLoading, data } = useQuery(
    `GetPartnershipsWithCompanies-${companiesIds.join('-')}`,
    () => getCompaniesAndGroupsByIds(companiesIds, []),
    {
      enabled: !!companiesIds.length,
      refetchOnWindowFocus: false,
    },
  );

  const partnerships = useMemo(() => {
    return companiesIds.reduce((acc, id) => {
      console.log(acc);
      return {
        ...acc,
        [id]:
          data?.partnerships?.find((partnership) => {
            return partnership?.invitee_company_id === id || partnership?.inviter_company_id === id;
          }) || null,
      };
    }, {});
  }, [companiesIds, data]);

  return {
    isLoading,
    data,
    partnerships,
  };
}
