import { useFieldValue } from '../../../components/FormikFieldsV3/hooks';
import { WITH_QUOTES } from '../blocks/RoutesBlock';
import { WITH_QUOTES_MAP } from '../components/ContractByCountInfo';

const useQuotes = () => {
  const [quotesKey] = useFieldValue(WITH_QUOTES);
  const withQuotes = quotesKey === WITH_QUOTES_MAP.use;
  return {
    withQuotes,
  };
};

export default useQuotes;
