import React from 'react';
import SVGIcon from './index';

function IconPartners(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 5V12H15.5V10L11 7L9.50004 7.5L8.50001 6L12 4L14 5.33333L17 5ZM3 12V5H7L6 6L9 10L11 9L14 11V12L10 16L4 12H3Z"
      />
    </SVGIcon>
  );
}

IconPartners.defaultProps = { color: 'white' };

export default React.memo(IconPartners);
