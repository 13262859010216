import { useQuery } from 'react-query';
import { getAssignedResourcesByShippingId } from '../services/orders/ordersService';
import { getMappedCar, getMappedDriver, getMappedTrailer } from '../store/resources/selectors';
import { getCarBackendType, getTrailerBackendType } from '../helpers/carTypesMapping';
import { REFRIGERATOR } from '../store/resources/formKeys';

export interface IResources {
  cars: Array<any> | null;
  trailers: Array<any> | null;
  drivers: Array<any> | null;
}

function _checkItem(items) {
  if (Array.isArray(items) && items.length > 0) return items;
  return null;
}

/**
 * Получение назначенных на перевозку ресурсов
 * @param currentShippingId
 * @param useQueryOptions
 * @returns
 */
export function useGetOrderAssignedResources(currentShippingId?: string, useQueryOptions = {}): Partial<IResources> {
  const { data: resources = {} } = useQuery<Partial<IResources>>(
    ['shipping', currentShippingId, 'assigned-resources'],
    () => getAssignedResourcesByShippingId(currentShippingId),
    {
      enabled: !!currentShippingId,
      select: (data) => ({
        cars: _checkItem(data.cars?.map(getMappedCar)),
        trailers: _checkItem(data.trailers?.map(getMappedTrailer)),
        drivers: _checkItem(data.drivers?.map(getMappedDriver)),
      }),
      ...useQueryOptions,
    },
  );
  return resources;
}

export function useShippingResourcesInfo(shippingId, useQueryOptions = {}) {
  const { isLoading, error, data, refetch } = useQuery<Partial<IResources>>(
    ['shipping', shippingId, 'assigned-resources-v2'],
    () => getAssignedResourcesByShippingId(shippingId),
    {
      enabled: !!shippingId,
      select: (data) => ({
        cars: _checkItem(data.cars?.map(getMappedCar)),
        trailers: _checkItem(data.trailers?.map(getMappedTrailer)),
        drivers: _checkItem(data.drivers?.map(getMappedDriver)),
      }),
      retryOnMount: false,
      retry: false,
      refetchOnWindowFocus: false,
      ...useQueryOptions,
    },
  );

  const withRef =
    data?.trailers?.[0].kind === getTrailerBackendType(REFRIGERATOR) ||
    data?.cars?.[0].kind === getCarBackendType(REFRIGERATOR, true);

  return {
    isLoading: isLoading,
    isFailed: !!error,
    resources: data,
    refetch,
    withRef,
  };
}
