import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useFieldValue } from './hooks';
import { useCallback, useEffect, useRef } from 'react';
import { useFormikContextSelector } from './FormikContext';

function handlePush(list, data) {
  const clonedList = cloneDeep(list);
  clonedList.push(data);
  return clonedList;
}

function handleRemove(list, index) {
  const clonedList = cloneDeep(list);
  clonedList.splice(index, 1);
  return clonedList;
}

function handleInsert(list, index, data) {
  const clonedList = cloneDeep(list);
  clonedList.splice(index, 0, data);
  return clonedList;
}

export function FieldArray(props) {
  const { name, children } = props;
  const [value = [], setFieldValue] = useFieldValue(name);
  const refValue = useRef(value);
  const validateForm = useFormikContextSelector((c) => c.validateForm);
  const values = useFormikContextSelector((c) => c.values);

  const push = useCallback(
    (data) => {
      setFieldValue(handlePush(value, data));
    },
    [setFieldValue, value],
  );

  const remove = useCallback(
    (index) => {
      setFieldValue(handleRemove(value, index));
    },
    [setFieldValue, value],
  );

  const insert = useCallback(
    (index, data) => {
      setFieldValue(handleInsert(value, index, data));
    },
    [setFieldValue, value],
  );

  const isValueChanged = !isEqual(refValue.current, value);
  useEffect(() => {
    if (isValueChanged) {
      validateForm(values).then(() => {
        refValue.current = value;
      });
    }
  }, [isValueChanged, name, validateForm, value, values]);

  return children({ push, remove, insert });
}
