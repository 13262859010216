import * as formKeys from '../store/resources/formKeys';
import * as formFields from '../store/resources/formFields';
import * as bodyTypeOptions from '../store/resources/bodyTypeOptions';
import concatUrl from './concatUrl';

const ResourceCarTruck = 'ResourceCarTruck';
const ResourceCarLorryAwning = 'ResourceCarLorryAwning';
const ResourceCarLorryRefrigerator = 'ResourceCarLorryRefrigerator';
const ResourceCarLorryIsothermal = 'ResourceCarLorryIsothermal';
const ResourceCarLorryFullMetal = 'ResourceCarLorryFullMetal';
const ResourceCarLorryDropside = 'ResourceCarLorryDropside';
const ResourceCarLorryTanker = 'ResourceCarLorryTanker';
const ResourceCarLorryTimberCarrier = 'ResourceCarLorryTimberCarrier';

const ResourceTrailerAwning = 'ResourceTrailerAwning';
const ResourceTrailerRefrigerator = 'ResourceTrailerRefrigerator';
const ResourceTrailerIsothermal = 'ResourceTrailerIsothermal';
const ResourceTrailerFullMetal = 'ResourceTrailerFullMetal';
const ResourceTrailerDropside = 'ResourceTrailerDropside';
const ResourceTrailerTanker = 'ResourceTrailerTanker';
const ResourceTrailerContainer = 'ResourceTrailerContainer';
const ResourceTrailerTimberCarrier = 'ResourceTrailerTimberCarrier';
const ResourceTrailerStepDeck = 'ResourceTrailerStepDeck';
/**
 * Типы грузовиков
 */
export const TRUCK_URL = 'truck';
export const LORRY_URL = 'lorry';
export const TRAILER_URL = 'trailer';

export type TVehicleTypes = typeof TRUCK_URL | typeof LORRY_URL;
export type TTransportTypes = TVehicleTypes | typeof TRAILER_URL;

/**
 * Типы кузова
 */
export const AWNING_URL = 'awning';
export const FULL_METAL_URL = 'full_metal';
export const ISOTHERMAL_URL = 'isothermal';
export const REFRIGERATOR_URL = 'refrigerator';
export const DROPSIDE_URL = 'dropside';
export const TIMBER_CARRIER_URL = 'timber_carrier';
export const STEP_DECK_URL = 'step_deck';
export const TANKER_URL = 'tanker';
export const CONTAINER_URL = 'container';

export type TBodyType =
  | typeof AWNING_URL
  | typeof FULL_METAL_URL
  | typeof ISOTHERMAL_URL
  | typeof REFRIGERATOR_URL
  | typeof DROPSIDE_URL
  | typeof TIMBER_CARRIER_URL
  | typeof STEP_DECK_URL
  | typeof TANKER_URL
  | typeof CONTAINER_URL;

/**
 * Развесовка типов кузова для сортировки
 */
export const TYPES_WEIGHTS = {
  // Тент
  [AWNING_URL]: 1,
  // Рефрижератор
  [REFRIGERATOR_URL]: 2,
  // Изотермический
  [ISOTHERMAL_URL]: 3,
  // Цельнометаллический
  [FULL_METAL_URL]: 4,
  // Бортовой
  [DROPSIDE_URL]: 5,
  // Автоцистерна
  [TANKER_URL]: 6,
  // Контейнеровоз
  [CONTAINER_URL]: 7,
  // Лесовоз
  [TIMBER_CARRIER_URL]: 8,
  // Автотрал
  [STEP_DECK_URL]: 9,
};

/**
 * Сортировка типов кузова по весам
 * @param types
 */
export function getSortedBodyTypes(types: TBodyType[]) {
  const typesCopy = types.slice();
  typesCopy.sort((a, b) => TYPES_WEIGHTS[a] - TYPES_WEIGHTS[b]);
  return typesCopy;
}

/**
 * Маппинг бэковых строковых типов автомобилей
 * @param type
 * @returns {{}}
 */
export const getMappedCarTypes = (type) => {
  switch (type) {
    case ResourceCarTruck:
      return {
        [formFields.VEHICLE_TYPE]: formKeys.TRUCK,
      };
    case ResourceCarLorryAwning:
      return {
        [formFields.VEHICLE_TYPE]: formKeys.LORRY,
        [formFields.BODY_TYPE]: bodyTypeOptions.AWNING_OBJ,
      };
    case ResourceCarLorryRefrigerator:
      return {
        [formFields.VEHICLE_TYPE]: formKeys.LORRY,
        [formFields.BODY_TYPE]: bodyTypeOptions.REFRIGERATOR_OBJ,
      };
    case ResourceCarLorryIsothermal:
      return {
        [formFields.VEHICLE_TYPE]: formKeys.LORRY,
        [formFields.BODY_TYPE]: bodyTypeOptions.ISOTHERMAL_OBJ,
      };
    case ResourceCarLorryFullMetal:
      return {
        [formFields.VEHICLE_TYPE]: formKeys.LORRY,
        [formFields.BODY_TYPE]: bodyTypeOptions.FULL_METAL_OBJ,
      };
    case ResourceCarLorryDropside:
      return {
        [formFields.VEHICLE_TYPE]: formKeys.LORRY,
        [formFields.BODY_TYPE]: bodyTypeOptions.BOARD_OBJ,
      };
    case ResourceCarLorryTanker:
      return {
        [formFields.VEHICLE_TYPE]: formKeys.LORRY,
        [formFields.BODY_TYPE]: bodyTypeOptions.TANKER_OBJ,
      };
    case ResourceCarLorryTimberCarrier:
      return {
        [formFields.VEHICLE_TYPE]: formKeys.LORRY,
        [formFields.BODY_TYPE]: bodyTypeOptions.TIMBER_CARRIER_OBJ,
      };
    default:
      return {};
  }
};

export const getLabelForTrailerType = (type) => {
  switch (type) {
    case ResourceTrailerAwning:
      return bodyTypeOptions.AWNING_OBJ.label;
    case ResourceTrailerRefrigerator:
      return bodyTypeOptions.REFRIGERATOR_OBJ.label;
    case ResourceTrailerIsothermal:
      return bodyTypeOptions.ISOTHERMAL_OBJ.label;
    case ResourceTrailerFullMetal:
      return bodyTypeOptions.FULL_METAL_OBJ.label;
    case ResourceTrailerDropside:
      return bodyTypeOptions.BOARD_OBJ.label;
    case ResourceTrailerTanker:
      return bodyTypeOptions.TANKER_OBJ.label;
    case ResourceTrailerContainer:
      return bodyTypeOptions.CONTAINER_OBJ.label;
    case ResourceTrailerTimberCarrier:
      return bodyTypeOptions.TIMBER_CARRIER_OBJ.label;
    case ResourceTrailerStepDeck:
      return bodyTypeOptions.STEP_DECK_OBJ.label;
    default:
      return '';
  }
};

export const getMappedTrailerTypesObj = (type) => {
  switch (type) {
    case ResourceTrailerAwning:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.AWNING_OBJ,
      };
    case ResourceTrailerRefrigerator:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.REFRIGERATOR_OBJ,
      };
    case ResourceTrailerIsothermal:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.ISOTHERMAL_OBJ,
      };
    case ResourceTrailerFullMetal:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.FULL_METAL_OBJ,
      };
    case ResourceTrailerDropside:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.BOARD_OBJ,
      };
    case ResourceTrailerTanker:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.TANKER_OBJ,
      };
    case ResourceTrailerContainer:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.CONTAINER_OBJ,
      };
    case ResourceTrailerTimberCarrier:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.TIMBER_CARRIER_OBJ,
      };
    case ResourceTrailerStepDeck:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.STEP_DECK_OBJ,
      };
    default:
      return {};
  }
};

export const getMappedTrailerTypes = (type) => {
  switch (type) {
    case ResourceTrailerAwning:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.AWNING_OBJ.value,
      };
    case ResourceTrailerRefrigerator:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.REFRIGERATOR_OBJ.value,
      };
    case ResourceTrailerIsothermal:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.ISOTHERMAL_OBJ.value,
      };
    case ResourceTrailerFullMetal:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.FULL_METAL_OBJ.value,
      };
    case ResourceTrailerDropside:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.BOARD_OBJ.value,
      };
    case ResourceTrailerTanker:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.TANKER_OBJ.value,
      };
    case ResourceTrailerContainer:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.CONTAINER_OBJ.value,
      };
    case ResourceTrailerTimberCarrier:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.TIMBER_CARRIER_OBJ.value,
      };
    case ResourceTrailerStepDeck:
      return {
        [formFields.BODY_TYPE]: bodyTypeOptions.STEP_DECK_OBJ.value,
      };
    default:
      return {};
  }
};

export const getTypeUrl = (type) => {
  switch (type) {
    case ResourceCarTruck:
      return concatUrl(TRUCK_URL);
    case ResourceCarLorryAwning:
      return concatUrl(LORRY_URL, AWNING_URL);
    case ResourceCarLorryRefrigerator:
      return concatUrl(LORRY_URL, REFRIGERATOR_URL);
    case ResourceCarLorryIsothermal:
      return concatUrl(LORRY_URL, ISOTHERMAL_URL);
    case ResourceCarLorryFullMetal:
      return concatUrl(LORRY_URL, FULL_METAL_URL);
    case ResourceCarLorryDropside:
      return concatUrl(LORRY_URL, DROPSIDE_URL);
    case ResourceCarLorryTanker:
      return concatUrl(LORRY_URL, TANKER_URL);
    case ResourceCarLorryTimberCarrier:
      return concatUrl(LORRY_URL, TIMBER_CARRIER_URL);
    case ResourceTrailerAwning:
      return concatUrl(AWNING_URL);
    case ResourceTrailerRefrigerator:
      return concatUrl(REFRIGERATOR_URL);
    case ResourceTrailerIsothermal:
      return concatUrl(ISOTHERMAL_URL);
    case ResourceTrailerFullMetal:
      return concatUrl(FULL_METAL_URL);
    case ResourceTrailerDropside:
      return concatUrl(DROPSIDE_URL);
    case ResourceTrailerTanker:
      return concatUrl(TANKER_URL);
    case ResourceTrailerContainer:
      return concatUrl(CONTAINER_URL);
    case ResourceTrailerTimberCarrier:
      return concatUrl(TIMBER_CARRIER_URL);
    case ResourceTrailerStepDeck:
      return concatUrl(STEP_DECK_URL);
    default:
      return '';
  }
};

export const getCarBackendType = (bodyType, isLorry) => {
  if (!isLorry) return ResourceCarTruck;

  switch (bodyType) {
    case formKeys.AWNING:
      return ResourceCarLorryAwning;
    case formKeys.ISOTHERMAL:
      return ResourceCarLorryIsothermal;
    case formKeys.REFRIGERATOR:
      return ResourceCarLorryRefrigerator;
    case formKeys.FULL_METAL:
      return ResourceCarLorryFullMetal;
    case formKeys.DROPSIDE:
      return ResourceCarLorryDropside;
    case formKeys.TANKER:
      return ResourceCarLorryTanker;
    case formKeys.TIMBER_CARRIER:
      return ResourceCarLorryTimberCarrier;
    case formKeys.STEP_DECK:
      return ResourceCarLorryTimberCarrier;
    default:
      return '';
  }
};

export const getTrailerBackendType = (bodyType) => {
  switch (bodyType) {
    case formKeys.AWNING:
      return ResourceTrailerAwning;
    case formKeys.ISOTHERMAL:
      return ResourceTrailerIsothermal;
    case formKeys.REFRIGERATOR:
      return ResourceTrailerRefrigerator;
    case formKeys.FULL_METAL:
      return ResourceTrailerFullMetal;
    case formKeys.DROPSIDE:
      return ResourceTrailerDropside;
    case formKeys.TANKER:
      return ResourceTrailerTanker;
    case formKeys.CONTAINER:
      return ResourceTrailerContainer;
    case formKeys.TIMBER_CARRIER:
      return ResourceTrailerTimberCarrier;
    case formKeys.STEP_DECK:
      return ResourceTrailerStepDeck;
    default:
      return '';
  }
};

export const getBodyTypeTranslation = (bodyType) => {
  switch (bodyType) {
    case formKeys.ISOTHERMAL:
      return bodyTypeOptions.ISOTHERMAL_OBJ.label;
    case formKeys.REFRIGERATOR:
      return bodyTypeOptions.REFRIGERATOR_OBJ.label;
    case formKeys.FULL_METAL:
      return bodyTypeOptions.FULL_METAL_OBJ.label;
    case formKeys.DROPSIDE:
      return bodyTypeOptions.BOARD_OBJ.label;
    case formKeys.AWNING:
      return bodyTypeOptions.AWNING_OBJ.label;
    case formKeys.TANKER:
      return bodyTypeOptions.TANKER_OBJ.label;
    case formKeys.CONTAINER:
      return bodyTypeOptions.CONTAINER_OBJ.label;
    case formKeys.TIMBER_CARRIER:
      return bodyTypeOptions.TIMBER_CARRIER_OBJ.label;
    case formKeys.STEP_DECK:
      return bodyTypeOptions.STEP_DECK_OBJ.label;
    default:
      return '';
  }
};

/**
 * Маппинг распознанного типа транспортного средства
 */
export const getRecognizedVehicleType = (vehicleType: string): string => {
  return vehicleType
    ?.split(' ')
    ?.map((item) => item.toLowerCase())
    .includes('тягач')
    ? formKeys.TRUCK
    : formKeys.LORRY;
};

/**
 * Маппинг распознанного типа грузовика
 */
export const getRecognizedLorryType = (vehicleType: string) => {
  switch (vehicleType) {
    case bodyTypeOptions.REFRIGERATOR_OBJ.label:
      return bodyTypeOptions.REFRIGERATOR_OBJ.value;
    case bodyTypeOptions.ISOTHERMAL_OBJ.label:
      return bodyTypeOptions.ISOTHERMAL_OBJ.value;
    case bodyTypeOptions.FULL_METAL_OBJ.label:
      return bodyTypeOptions.FULL_METAL_OBJ.value;
    case bodyTypeOptions.BOARD_OBJ.label:
      return bodyTypeOptions.BOARD_OBJ.value;
    case bodyTypeOptions.TIMBER_CARRIER_OBJ.label:
      return bodyTypeOptions.TIMBER_CARRIER_OBJ.value;
    case bodyTypeOptions.TANKER_OBJ.label:
      return bodyTypeOptions.TANKER_OBJ.value;
    case bodyTypeOptions.AWNING_OBJ.label:
    default:
      return bodyTypeOptions.AWNING_OBJ.value;
  }
};
