import React from 'react';
import SVGIcon from './index';

const IconFollowFlag = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 0H0V16L6 11L12 16V0Z" />
    </SVGIcon>
  );
};

export default IconFollowFlag;
