import React from 'react';
import { TComponentPropsDefaultsMap, TComponentPropsTypesMap, THandbookFC } from './types';

export function ComponentsHandbookHOC<T>(
  WrappedComponent: (props: T) => JSX.Element,
  propsTypes: TComponentPropsTypesMap<T>,
  defaultProps: TComponentPropsDefaultsMap<T>,
): THandbookFC<T> {
  WrappedComponent['propsTypes'] = propsTypes as TComponentPropsTypesMap<T>;
  WrappedComponent['defaultProps'] = defaultProps as TComponentPropsDefaultsMap<T>;
  return WrappedComponent as THandbookFC<T>;
}
