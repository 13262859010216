import moment from 'moment';
import { Base64 } from 'js-base64';
import * as authService from './authService';
import { CHAT_ACCESS_TOKEN, CHAT_DEVICE_ID } from './chatService';
import { CHAT_ROOMS, CHAT_SYNC_SINCE } from '../store/chat/reducer';

export const ACCESS_TOKEN = 'tp.accessToken';
export const REFRESH_TOKEN = 'tp.refreshToken';
export const SCOPE = 'tp.scope';
export const TIME_STAMP = 'tp.timeStamp';

type TToken = {
  sub: string;
  aud: string;
  nbf: number;
  role: string;
  company_id: string;
  user_id: string;
  azp: string;
  scope: string;
  iss: string;
  exp: number;
  iat: number;
  jti: string;
};

export async function saveToken(data): Promise<void> {
  const timeStamp: number = moment().add(data?.expires_in, 'seconds').valueOf();

  localStorage.setItem(ACCESS_TOKEN, data?.access_token);
  localStorage.setItem(REFRESH_TOKEN, data?.refresh_token);
  localStorage.setItem(SCOPE, data?.scope);
  // храним слепок в миллисекундах
  localStorage.setItem(TIME_STAMP, String(timeStamp));
}

export async function getToken(): Promise<any> {
  const currentTime: number = moment().valueOf();
  const timeStamp: number = Number(localStorage.getItem(TIME_STAMP));

  // запрашиваем новый токен, если истек старый
  if (currentTime > timeStamp) {
    // Try для обработки ошибок при обновлении токена
    // Ошибки вызывают логаут даже при наличии валидного токена
    try {
      const refreshToken = getRefreshToken();
      if (refreshToken) await authService.authorization_DEPRECATED({ refresh_token: refreshToken });
    } catch {}
  }
  return getAccessToken();
}

export function getAccessToken(): string | null {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  return accessToken && accessToken !== 'undefined' ? accessToken : null;
}

export function getScope(): string[] {
  const scope = localStorage.getItem(SCOPE);
  return scope && scope !== 'undefined' ? scope.split(' ') ?? [] : [];
}

export function clearToken(): void {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(SCOPE);
  localStorage.removeItem(TIME_STAMP);

  localStorage.removeItem(CHAT_ACCESS_TOKEN);
  localStorage.removeItem(CHAT_DEVICE_ID);

  localStorage.removeItem(CHAT_ROOMS);
  localStorage.removeItem(CHAT_SYNC_SINCE);

  localStorage.removeItem('notification-service-is-open');
  localStorage.removeItem('notification-service-previous-url');
}

export function getDecodeToken(token: string): TToken {
  // токен состоит из header.payload.signature
  const [, payload]: string[] = token.split('.');
  const decodeToken: string = Base64.decode(payload);

  return JSON.parse(decodeToken);
}

export function getRefreshToken(): string | null {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return refreshToken && refreshToken !== 'undefined' ? refreshToken : null;
}
