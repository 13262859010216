import React from 'react';
import InputMask from 'react-input-mask';

import Input from '../../Input';
import InputHOC from '../inputHOC';
import { FieldProps } from 'formik';

/**
 * формат знаков для react-input-mask
 * по аналогии с filterByMask
 * @type {{A: string, R: string, S: string, D: string, '9': string}}
 */
const formatChars = {
  1: '[01]',
  2: '[0-2]',
  3: '[0-3]',
  5: '[0-5]',
  9: '[0-9]',
  A: '[0-9A-Za-zА-Яа-я]',
  S: '[A-Za-z]',
  R: '[А-Яа-я]',
  D: '[A-Za-zА-Яа-я]',
};

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  mask: string;
  maskChar?: string;
  labelDisable?: boolean;
}

const FormInput = (props: IProps & FieldProps) => {
  const acceptedProps: any = { ...props, ...props.field };
  delete acceptedProps.form;
  delete acceptedProps.field;
  delete acceptedProps.mask;
  delete acceptedProps.maskChar;

  return (
    <InputMask
      {...props.field}
      mask={props.mask}
      maskChar={props.maskChar || ''}
      type={props.type}
      placeholder={props.placeholder}
      className={props.className}
      formatChars={formatChars}
      onFocus={props.onFocus}
      onChange={props.onChange}
      onBlur={props.onBlur}
      disabled={props.disabled}
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          {...acceptedProps}
          onFocus={props.onFocus}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
        />
      )}
    </InputMask>
  );
};

export default InputHOC(FormInput);
