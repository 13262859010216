import { freezingAnalyze } from '../../../helpers/freezeCheck';
import {
  ACCESS_SUSPENDED,
  ACTIVE,
  APPROVED,
  EXPIRED,
  NEED_FIX_BY_USER,
  NOT_ALLOWED,
  NOT_ATTORNEY,
  REVIEW,
} from '../../../store/resources/statusKeys';
import { CATEGORY_1, CATEGORY_2 } from '../../../constants';
import { CATEGORY_DELLIN_MKT } from '../../../store/resources/queryKeys';

export function getDriverStatusByCategory(item, categories, category) {
  const { status, prohibitedCategories, attorneys, freezing } = item;

  const isCompanyAccess = categories.includes(category);
  const freezeInfo = freezingAnalyze(freezing);
  const freezingMap = new Map().set(CATEGORY_1, freezeInfo.k1).set(CATEGORY_2, freezeInfo.k2);

  /* Нет доступа ни к одной категории */
  if (!isCompanyAccess) {
    return {
      theme: 'danger',
      status,
    };
  }

  /* Доступ компании есть*/
  if (status === ACCESS_SUSPENDED) {
    return {
      theme: 'danger',
      status,
    };
  }

  if (status === REVIEW) {
    return {
      theme: 'warning',
      status,
    };
  }

  if (status === APPROVED) {
    if (prohibitedCategories.includes(category)) {
      return {
        theme: 'danger',
        status: NOT_ALLOWED,
      };
    }

    // Проверяем для первой категории наличие доверок для БТС или МКТ
    if (
      (category === CATEGORY_1 && !attorneys[category] && !attorneys[CATEGORY_DELLIN_MKT]) ||
      (category === CATEGORY_2 && !attorneys[category])
    ) {
      return { theme: 'danger', status: NOT_ATTORNEY };
    }

    // Проверяем для первой категории наличие доверок для БТС или МКТ
    if (
      (category === CATEGORY_1 &&
        ((attorneys[category] && attorneys[category].status === ACTIVE) ||
          (attorneys[CATEGORY_DELLIN_MKT] && attorneys[CATEGORY_DELLIN_MKT].status === ACTIVE))) ||
      (category === CATEGORY_2 && attorneys[category] && attorneys[category].status === ACTIVE)
    ) {
      if (freezingMap.get(category).status) {
        return { theme: 'warning', status };
      }
      return { theme: 'success', status };

      // TODO: странная логика, не понятно, как это должно работать
      // if (freezeInfo.k1.status && freezeInfo.k2.status) {
      //   // Доступ к К1 и К2
      //   return { theme: 'warning', status };
      // } else if (
      //   // Доступ к одной из категорий
      //   !freezeInfo.k1.status ||
      //   !freezeInfo.k2.status
      // ) {
      //   return {
      //     theme: 'success',
      //     status,
      //   };
      // } else if (freezeInfo.k1.status) {
      //   // Доступ к К1
      //   return { theme: 'warning', status };
      // } else if (freezeInfo.k2.status) {
      //   // Доступ к К2
      //   return { theme: 'warning', status };
      // } else {
      //   // Полный доступ
      //   return { theme: 'success', status };
      // }
    }

    if (attorneys[category] && attorneys[category].status === EXPIRED) {
      return { theme: 'danger', status: ACCESS_SUSPENDED };
    }
  }

  if (status === NEED_FIX_BY_USER) {
    return { theme: 'danger', status };
  }

  return {
    theme: 'default',
    status,
  };
}
