import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { config } from './popups/config';
import { useHistory } from 'react-router-dom';
import GreetingModal from './GreetingModal/greetingModal';
import ReviewSuggestionModal from './popups/ReviewSuggestionModal';
import ReviewSuggestionAdditionalModal from './popups/ReviewSuggestionAdditionalModal';
import PlatformPartnersModal from './suggestPlatformPartners/PlatformPartnersModal';
import { useUserContext } from '../../contexts/userContext';
import { useRouterQuery } from '../../hooks/useRouterQuery.ts';
import { useLocalStorage, useSavingToLocalStorage } from '../../hooks/useStorages';

const OnboardingContext = createContext({
  // shownPopupId: '',
  // shownPopupIndex: null,
  // shownPopupConfig: null,
  // totalPopups: null,
  // showPrevPopupHandler: () => {},
  // showNextPopupHandler: () => {},
  // closePopupHandler: () => {},
});

/**
 * контекст онбординга
 * @return {{ shownPopupId, shownPopupIndex, shownPopupConfig, totalPopups, showPrevPopupHandler,
 *  showNextPopupHandler, closePopupHandler, startReview,
 *  onOnboardingVisited, onCompanyFormFinished, }}
 */
export function useOnboardingContext() {
  const context = useContext(OnboardingContext);

  if (context === undefined) {
    throw new Error('OnboardingProvider не найден');
  }

  return context;
}

const ONBOARDING_HOC_LOCALSTORAGE_KEYS = {
  REVIEW: 'onboarding_hoc_showReviewSuggestionModal',
  REVIEW_ADDITIONAL: 'onboarding_hoc_showReviewSuggestionAdditionalModal',
  POPUP_ID: 'onboarding_hoc_shownPopupId',
};

export function OnboardingProvider({ children }) {
  const history = useHistory();
  const { isCargoOwningUser } = useUserContext();
  const { get: getQueryValue, remove: removeQueryValues } = useRouterQuery();
  const { remove } = useLocalStorage();
  const clearLocalStorage = useCallback(
    () =>
      remove([
        ONBOARDING_HOC_LOCALSTORAGE_KEYS.REVIEW,
        ONBOARDING_HOC_LOCALSTORAGE_KEYS.REVIEW_ADDITIONAL,
        ONBOARDING_HOC_LOCALSTORAGE_KEYS.POPUP_ID,
      ]),
    [remove],
  );

  // Приветственные баннеры:
  const [showGreetingModal, setShowGreetingModal] = useState(false);

  // Партнеры платформы:
  const [showPartnersModal, setShowPartnersModal] = useState(false);

  // Предложение провести обзор:
  const { storageValue: _showReviewSuggestionModal, onChange: showReviewSuggestionChangeHandler } =
    useSavingToLocalStorage(ONBOARDING_HOC_LOCALSTORAGE_KEYS.REVIEW);
  const {
    storageValue: _showReviewSuggestionAdditionalModal,
    onChange: showReviewSuggestionAdditionalModalChangeHandler,
  } = useSavingToLocalStorage(ONBOARDING_HOC_LOCALSTORAGE_KEYS.REVIEW_ADDITIONAL);
  const [showReviewSuggestionModal, setShowReviewSuggestionModal] = useState(_showReviewSuggestionModal ?? false);
  const [showReviewSuggestionAdditionalModal, setShowReviewSuggestionAdditionalModal] = useState(
    _showReviewSuggestionAdditionalModal ?? false,
  );
  const saveShowReviewSuggestionModalValue = useCallback(
    (value) => {
      setShowReviewSuggestionModal(value);
      showReviewSuggestionChangeHandler(value);
    },
    [showReviewSuggestionChangeHandler],
  );
  const saveReviewSuggestionAdditionalModalValue = useCallback(
    (value) => {
      setShowReviewSuggestionAdditionalModal(value);
      showReviewSuggestionAdditionalModalChangeHandler(value);
    },
    [showReviewSuggestionAdditionalModalChangeHandler],
  );

  const closeReviewModalHandler = useCallback(() => {
    saveShowReviewSuggestionModalValue(false);
    saveReviewSuggestionAdditionalModalValue(true);
  }, [saveShowReviewSuggestionModalValue, saveReviewSuggestionAdditionalModalValue]);

  const closeReviewAdditionalModalHandler = useCallback(() => {
    saveReviewSuggestionAdditionalModalValue(false);
    clearLocalStorage();
  }, [saveReviewSuggestionAdditionalModalValue, clearLocalStorage]);

  // Попапы с описанием функционала
  const { storageValue: _shownPopupId, onChange: shownPopupIdChangeHandler } = useSavingToLocalStorage(
    ONBOARDING_HOC_LOCALSTORAGE_KEYS.POPUP_ID,
  );

  const [shownPopupId, setShownPopupId] = useState(_shownPopupId || '');
  const saveShownPopupId = useCallback(
    (value) => {
      setShownPopupId(value);
      shownPopupIdChangeHandler(value);
    },
    [shownPopupIdChangeHandler],
  );
  const shownPopupIndex = config?.findIndex((params) => params.id === shownPopupId);
  const shownPopupConfig = shownPopupIndex >= 0 ? config?.[shownPopupIndex] : null;
  const totalPopups = config?.length;

  const closePopup = useCallback(() => {
    saveShownPopupId('');
    clearLocalStorage();
  }, [saveShownPopupId, clearLocalStorage]);

  const showPrevPopup = useCallback(() => {
    if (shownPopupIndex > 0) {
      const prevConfig = config[shownPopupIndex - 1];
      saveShownPopupId(prevConfig.id);
      if (prevConfig.action) prevConfig.action(history);
    }
  }, [shownPopupIndex, saveShownPopupId, history]);

  const showNextPopup = useCallback(() => {
    if (shownPopupIndex + 1 < totalPopups) {
      const nextConfig = config[shownPopupIndex + 1];
      saveShownPopupId(nextConfig.id);
      if (nextConfig.action) nextConfig.action(history);
    } else {
      closePopup();
    }
  }, [shownPopupIndex, totalPopups, saveShownPopupId, history, closePopup]);

  const startReviewHandler = useCallback(() => {
    saveShowReviewSuggestionModalValue(false);
    if (config[0]) {
      saveShownPopupId(config[0].id);
      config[0].action && config[0].action(history);
    }
  }, [saveShowReviewSuggestionModalValue, saveShownPopupId, history]);
  // Старт обзора из контекста
  const startReview = useCallback(() => {
    saveShowReviewSuggestionModalValue(false);
    saveReviewSuggestionAdditionalModalValue(false);
    if (config[0]) {
      saveShownPopupId(config[0].id);
      config[0].action && config[0].action(history);
    }
  }, [saveShowReviewSuggestionModalValue, saveReviewSuggestionAdditionalModalValue, saveShownPopupId, history]);

  // этапы онбординга:
  const onOnboardingVisited = useCallback(() => {
    const isRedirectFromRegistration = getQueryValue('flow') === 'registration';
    if (!isCargoOwningUser && isRedirectFromRegistration) {
      removeQueryValues(['flow']);
      setTimeout(() => setShowGreetingModal(true), 100);
    }
  }, [getQueryValue, removeQueryValues, isCargoOwningUser]);

  const onGreetingModalClosed = useCallback(() => {
    setShowGreetingModal(false);
  }, []);

  const onCompanyFormFinished = useCallback(() => {
    history.replace('/suggestions');
    if (!isCargoOwningUser) {
      setTimeout(() => {
        setShowPartnersModal(true);
      }, 100);
    }
  }, [history, isCargoOwningUser]);

  const onPlatformPartnersBannerClosed = useCallback(() => {
    setShowPartnersModal(false);
    setTimeout(() => {
      saveShowReviewSuggestionModalValue(true);
    }, 100);
  }, [saveShowReviewSuggestionModalValue]);
  const onPlatformPartnersBannerAccept = useCallback(() => {
    setShowPartnersModal(false);
    history.push('/partners');
    setTimeout(() => {
      saveShowReviewSuggestionModalValue(true);
    }, 100);
  }, [history, saveShowReviewSuggestionModalValue]);

  const context = useMemo(() => {
    return {
      // popups
      shownPopupId: shownPopupId,
      shownPopupIndex: shownPopupIndex,
      shownPopupConfig: shownPopupConfig,
      totalPopups: totalPopups,
      showPrevPopupHandler: showPrevPopup,
      showNextPopupHandler: showNextPopup,
      closePopupHandler: closePopup,
      // review
      startReview: startReview,
      // этапы онбординга
      onOnboardingVisited,
      onCompanyFormFinished,
    };
  }, [
    shownPopupId,
    shownPopupIndex,
    shownPopupConfig,
    totalPopups,
    showPrevPopup,
    showNextPopup,
    closePopup,
    // review
    startReview,
    // этапы онбординга
    onOnboardingVisited,
    onCompanyFormFinished,
  ]);

  return (
    <OnboardingContext.Provider value={context}>
      {children}
      {showGreetingModal && <GreetingModal onClose={onGreetingModalClosed} />}
      {showReviewSuggestionModal && (
        <ReviewSuggestionModal onClose={closeReviewModalHandler} onStart={startReviewHandler} />
      )}
      {showReviewSuggestionAdditionalModal && (
        <ReviewSuggestionAdditionalModal onClose={closeReviewAdditionalModalHandler} />
      )}
      {showPartnersModal && (
        <PlatformPartnersModal onClose={onPlatformPartnersBannerClosed} onAccept={onPlatformPartnersBannerAccept} />
      )}
    </OnboardingContext.Provider>
  );
}
