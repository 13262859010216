import _get from 'lodash/get';
import _mapKeys from 'lodash/mapKeys';
import _groupBy from 'lodash/groupBy';
import _values from 'lodash/values';
import moment from 'moment';
import { createSelector, defaultMemoize } from 'reselect';
import { formValueSelector } from 'redux-form';
import {
  SHORT_DATE_FORMAT,
  SHORT_DATE_TIME_FORMAT,
  TIME_FORMAT,
  WEEK_ORDER_ARRAY,
  DATE_FORMAT,
  DEFAULT_ORDER_VALUE,
} from '../../constants';
import * as shippingMappers from '../../helpers/mappers/shipping';
import { getQuery } from '../page/selectors';
import * as companySelectors from '../company/selectors';
import * as queryKeys from './queryKeys';
import * as suggestionsQueryKeys from '../suggestions/queryKeys';
import * as formFields from './formFields';
import queryString from 'query-string';
import { DOCUMENTS } from '../../containers/graphics/components/item/stepsConst';
import { getShortAddress } from '../../helpers/addressMapping';
import { getSortedBodyTypes } from '../../helpers/carTypesMapping';
import { getSortMappedKey } from '../suggestions/helpers';

const formSelector = formValueSelector(formFields.BIT_FORM);

/**
 * Получение объекта текущего элемента
 */
export const getCurrentItem = createSelector(
  (state) => _get(state, 'graphics.activeItem'),
  (item) => getGraphicItem(item),
);

/**
 * Маппинг данных по графику
 * @param {*} item
 */
export function getGraphicItem(item) {
  const graphicId = _get(item, 'id');

  const graphicDateStart = moment.utc(_get(item, 'realization_start_at')).format(DATE_FORMAT);
  const graphicDateEnd = moment.utc(_get(item, 'realization_end_at')).format(DATE_FORMAT);

  const { tonnage, volume } = shippingMappers.getMinTonnageVolume(item);
  const price = _get(item, 'price') ? parseFloat(_get(item, 'price')) : null;
  const shippingCount = _get(item, 'route.shippings_count') || 0;

  const bids = _get(item, 'bids') || [];
  const currentBid = bids.find((bid) => bid.status === 'current');

  const archiveBid = bids.every((bid) => bid.status === 'archived');

  // const totalFormattedPrice = currentBid
  //   ? getFormattedPrice(getFormattedPrice(_get(currentBid, 'value') * shippingCount))
  //   : getFormattedPrice(price * shippingCount);

  let totalFormattedPrice = null;
  if (currentBid) {
    totalFormattedPrice = _get(currentBid, 'value') * shippingCount;
  } else if (price) {
    totalFormattedPrice = price * shippingCount;
  }

  return {
    graphicId,
    listId: graphicId,
    category: _get(item, 'client_category'),
    documentFlow: _get(item, 'document_flow'),
    humanFriendlyId: _get(item, 'human_friendly_id'),
    graphicHumanFriendlyId: _get(item, 'human_friendly_id'),
    graphicType: _get(item, 'type'),
    points: shippingMappers.getPoints(item),
    partnership: item?.partnership,
    shippingCount,
    price: currentBid ? _get(currentBid, 'value') : price,
    // price: currentBid ? getFormattedPrice(_get(currentBid, 'value')) : getFormattedPrice(price),
    totalPrice: totalFormattedPrice,
    tonnage,
    volume,
    bodyTypes: getSortedBodyTypes(_get(item, 'resource_requirements.transport_body_type') ?? []),
    vehicleType: _get(item, 'resource_requirements.transport_type'),
    createdAt: moment(_get(item, 'created_at')).format(SHORT_DATE_FORMAT),
    updatedAt: moment.utc(_get(item, 'updated_at')).format(SHORT_DATE_TIME_FORMAT),
    graphicDateStart,
    graphicDateEnd,
    graphicBiddingEnd: _get(item, 'bidding_end_at'),
    offerDeadline: _get(item, 'bidding_end_at'),
    scanCopyDeadline: _get(item, 'scancopy_attachment_deadline'),
    scanCopyStatus: _get(item, 'scancopy_status'),
    scanCopyComment: _get(item, 'scancopy_status_comment'),
    isCircular: _get(item, 'route.is_circular'),
    isTripWaiting: shippingMappers.isItemWithStatus(item, 'realization_pending'),
    isScanCopyError: _getIsScanCopyError(item),
    isGraphic: _getIsGraphic(item),
    isFollowed: _get(item, 'is_followed'),
    isBidSet: bids.length > 0,
    isBidArchive: !!archiveBid,
    isWinSelection: shippingMappers.isItemWithStatus(item, 'winner_selection'),
    isWinnerBid: !!_get(item, 'winner_bid'),
    // Архивные статусы
    isStatusDone: shippingMappers.isItemWithStatus(item, 'completed'),
    isStatusCanceled: shippingMappers.isItemWithStatus(item, 'canceled'),

    trips: _getGraphicsTrips(item),
    distance: shippingMappers.getDistanceBySegments(_get(item, 'route.route_segments', [])),

    bid: {
      currentBidId: _get(currentBid, 'id'),
      initialBid: price,
      max: _get(item, 'max_bid'),
      min: 0,
      winningBid: _get(item, 'winner_bid.value') || null,
      currentBid: currentBid ? _get(currentBid, 'value') : price,
      isSet: !!_get(currentBid, 'value'),
      shiftMaxInPercent: 10,
      isWinning: !!_get(item, 'winner_bid'),
      step: _get(item, 'rate_step'),
    },

    winnerBid: {
      id: _get(item, 'winner_bid.id'),
      createdAt: _get(item, 'winner_bid.created_at'),
      status: _get(item, 'winner_bid.status'),
      taxType: _get(item, 'winner_bid.tax_type'),
      value: _get(item, 'winner_bid.value') ? _get(item, 'winner_bid.value') : null,
    },
    additionalInfo: {
      price: price,
      cargoDescription: _get(item, 'cargo.description'),
      cargoTonnage: _get(item, 'cargo.tonnage'),
      cargoVolume: _get(item, 'cargo.volume'),
      cargoCost: _get(item, 'cargo.cost'),
      restrictions: shippingMappers.getRestrictions(item),
      comment: _get(item, 'comment', null),
    },

    _original: item,
  };
}

/**
 * Получаем значение графиковая ли перевозка
 * @param item
 * @returns {*}
 * @private
 */
export const _getIsGraphic = defaultMemoize((item) => _get(item, 'kind') === queryKeys.CONTRACT);

/**
 * Получение таблицы перевозок графика
 * @param item
 * @returns {Object}
 * @private
 */
export function _getGraphicsTrips(item) {
  // список всех точек на маршруте
  const routePoints = _get(item, 'route.route_points', []);

  // список всех сегментов на маршруте
  const routeSegments = _get(item, 'route.route_segments', []);

  // рейсы в рамках одной иттерации
  const trips = _get(item, 'route.schedule[0].trips', []);

  // группируем расписание по индексу рейса
  const groupedTrips = _getGroupedTrips(trips);

  // маппинг рейсов
  const mappedTrips = groupedTrips.map((trips) => {
    // дата начала рейса
    const tripStartDate = _get(trips, `[0].arrival_date`);

    return trips.map((trip, index) => {
      // id точки
      const pointId = _get(trip, 'point_id');

      // время прибытия в точку
      const arrivalDate = _get(trip, 'arrival_date');

      // время отправления из точки
      const departureDate = _get(trip, 'departure_date');

      // типы растентовки
      const { coverTypes, coverTypesByWorkTypes } = shippingMappers.getLoadingFields(routeSegments, index, routePoints);

      return {
        schedule: _getRouteSchedule(arrivalDate, departureDate, tripStartDate),
        wayDuration:
          index !== 0 ? shippingMappers.getDurationTranslation(_getWayDurationInMinutes(routeSegments, index)) : null,
        location: _getPointLocationObject(pointId, routePoints),
        coverTypes,
        coverTypesByWorkTypes,
        needDocumentWork: routePoints?.[index]?.need_document_work,
      };
    });
  });

  // меняем ключи массива на дни недели начала рейса
  return _mapKeys(mappedTrips, (trips, index) => {
    // дата начала рейса
    const tripStartDate = _get(groupedTrips, `[${index}][0].arrival_date`);
    // день недели начала рейса
    return WEEK_ORDER_ARRAY[_getDayNumberFromDate(tripStartDate)];
  });
}

/**
 * Разбитие объекта trips на группы в зависимости от индекса поездки
 * и сортировка по возрастанию даты подачи машины
 * @param trips
 * @returns {any[]}
 * @private
 */
function _getGroupedTrips(trips) {
  return _values(_groupBy(trips, (trip) => _get(trip, 'index'))).map((trips) =>
    trips.sort((tripA, tripB) => moment.utc(_get(tripA, 'arrival_date')) - moment.utc(_get(tripB, 'arrival_date'))),
  );
}

/**
 * Продолжительность рейса в минутах
 * @param segments
 * @param index
 * @returns {number} - минуты
 * @private
 */
function _getWayDurationInMinutes(segments, index) {
  return _get(segments, `[${index - 1}].duration`);
}

/**
 * Получение данных по точкам маршрута
 * @param {*} pointId
 * @param {*} routePoints
 */
function _getPointLocationObject(pointId, routePoints) {
  const exactPoint = routePoints.find((point) => _get(point, 'id') === pointId);

  return {
    region: _get(exactPoint, 'location.region'),
    city: _get(exactPoint, 'location.city'),
    fullAddress: _get(exactPoint, 'location.full_string_address').replace(/ ,/g, ''),
    shortAddress: getShortAddress(_get(exactPoint, 'location')),
  };
}

/**
 * Получение номера дня в неделе
 * @param date
 * @returns {number}
 * @private
 */
function _getDayNumberFromDate(date) {
  return moment(date).parseZone().weekday();
}

/**
 * Получение номера недели в году
 * @param date
 * @returns {number}
 * @private
 */
function _getWeekNumberFromDate(date) {
  return moment(date).parseZone().week();
}

/**
 * Формирование таблицы расписания
 * @param arrivalDate
 * @param departureDate
 * @param tripsStartDate
 * @returns {{}[]}
 * @private
 */
export function _getRouteSchedule(arrivalDate, departureDate, tripsStartDate) {
  // кол-во дней в неделе
  const weekDayLength = 7;

  // кол-во недель в году
  const weeksPerYear = 52;

  // кол-во недель в расписании
  const totalScheduleWeeks = 2;

  // максимальное кол-во дней для одной поездки
  const maxScheduleDaysNumber = weekDayLength * totalScheduleWeeks;

  // заготовка пустого расписания
  const schedule = Array(maxScheduleDaysNumber).fill(undefined).map(Object);

  const tripsStartDayNumber = _getDayNumberFromDate(tripsStartDate);
  const tripsStartWeekNumber = _getWeekNumberFromDate(tripsStartDate);

  return [{ arrivalDate }, { departureDate }].reduce((result, current) => {
    const date = Object.values(current)[0];
    const key = Object.keys(current)[0];

    if (date && moment(date).isValid()) {
      const dayNumber = _getDayNumberFromDate(date);
      const weekNumber = _getWeekNumberFromDate(date);

      // смещение недели отправления
      const weekShift =
        weekNumber >= tripsStartWeekNumber
          ? (weekNumber - tripsStartWeekNumber) * weekDayLength
          : // если неделя начала рейса больше дня прибытия/отправления
            // значит, что прибытие/отправление происходит на следующий год
            // и производится поправка константой
            (weekNumber + weeksPerYear - tripsStartWeekNumber) * weekDayLength;

      // фактическое положение дня отправления в расписании
      const scheduleDayNumber = dayNumber - tripsStartDayNumber + weekShift;

      // назначение времени прибытия
      if (schedule[scheduleDayNumber]) {
        schedule[scheduleDayNumber][key] = moment.parseZone(date).format(TIME_FORMAT);
      }
    }

    return schedule;
  }, schedule);
}

/**
 * Параметры для запроса списка графиков
 */
export const getGraphicsStatusRequestParams = createSelector(
  (state) => getQuery(state),
  (state) => companySelectors.isWithTax(state),
  (query, isWithTax) => {
    const requestStatus = {
      [queryKeys.NEW]: ['bidding', 'winner_selection'],
      [queryKeys.IN_BID]: ['bidding'],
      [queryKeys.WINNER_SELECTION]: ['winner_selection'],
      [queryKeys.TRIP_WAITING]: ['realization_pending'],
      [queryKeys.IN_WAY]: ['realization'],
      [queryKeys.ARCHIVE]: ['canceled', 'completed'],
    };

    const sort = query[queryKeys.SORT] ? getSortMappedKey(query[queryKeys.SORT], isWithTax) : null;

    return {
      sort,
      order: query[queryKeys.ORDER] ?? DEFAULT_ORDER_VALUE,

      status: requestStatus[query[queryKeys.STATUS]],
      human_friendly_id: query[queryKeys.GRAPHIC_NUMBER],
      page: +query[queryKeys.PAGE],
      per_page: query[queryKeys.PER_PAGE],

      // фильтр по графикам (активная ставка и слежение)
      set_bid: query[queryKeys.SET_BID],
      is_followed: query[queryKeys.FOLLOWING],

      // параметры из фильтра предложений
      realization_start_at_from: query[suggestionsQueryKeys.DATE_RANGE_START_FROM],
      realization_start_at_until: query[suggestionsQueryKeys.DATE_RANGE_START_UNTIL],
      realization_end_at_from: query[suggestionsQueryKeys.DATE_RANGE_END_FROM],
      realization_end_at_until: query[suggestionsQueryKeys.DATE_RANGE_END_UNTIL],
      from_location_id: query[suggestionsQueryKeys.FROM_LOCATION_ID],
      from_radius: query[suggestionsQueryKeys.FROM_RADIUS],
      direction_location_id: query[suggestionsQueryKeys.DIRECTION_LOCATION_ID],
      direction_radius: query[suggestionsQueryKeys.DIRECTION_RADIUS],
    };
  },
);

export const getGraphicsUrlType = createSelector(
  (state) => getQuery(state),
  (query) => {
    const urlType = {
      [queryKeys.IN_BID]: queryKeys.IN_AUCTION,
      [queryKeys.WINNER_SELECTION]: queryKeys.IN_AUCTION,
      [queryKeys.TRIP_WAITING]: queryKeys.WON,
      [queryKeys.IN_WAY]: queryKeys.WON,
      [queryKeys.ARCHIVE]: queryKeys.WON,
    };

    return urlType[query[queryKeys.STATUS]] || queryKeys.IN_AUCTION;
  },
);

/**
 * Формирование ulr на графики в зависимости от статуса и hfid графика
 */
export const getGraphicUrl = createSelector(
  (graphic) => _get(graphic, 'status.code_name'),
  (graphic) => _get(graphic, 'human_friendly_id'),
  (status, humanFriendlyId) => {
    if (!status || !humanFriendlyId) return null;

    const params = {
      [queryKeys.STATUS]: _getUrlTypeFromGraphicStatus(status),
      [queryKeys.GRAPHIC_NUMBER]: humanFriendlyId,
    };

    const stringifyParams = queryString.stringify(params, {
      arrayFormat: 'bracket',
    });

    return `/graphics?${stringifyParams}`;
  },
);

/**
 * Получение статуса UI от статуса графика
 * @param status
 * @returns {*}
 * @private
 */
function _getUrlTypeFromGraphicStatus(status) {
  switch (status) {
    case 'bidding':
      return queryKeys.IN_BID;
    case 'winner_selection':
      return queryKeys.WINNER_SELECTION;
    case 'realization_pending':
      return queryKeys.TRIP_WAITING;
    case 'realization':
      return queryKeys.IN_WAY;
    case 'canceled':
    case 'completed':
      return queryKeys.ARCHIVE;
    default:
      return null;
  }
}

/**
 * Получение значения об активности фильтров
 */
export const getIsFilterActive = createSelector(
  (state) => getQuery(state),
  (query) =>
    [
      query[queryKeys.GRAPHIC_NUMBER],
      query[suggestionsQueryKeys.FROM_LOCATION_ID],
      query[suggestionsQueryKeys.FROM_LOCATION_LABEL],
      query[suggestionsQueryKeys.FROM_RADIUS],
      query[suggestionsQueryKeys.DIRECTION_LOCATION_ID],
      query[suggestionsQueryKeys.DIRECTION_LOCATION_LABEL],
      query[suggestionsQueryKeys.DIRECTION_RADIUS],
      query[suggestionsQueryKeys.DATE_RANGE_START_FROM],
      query[suggestionsQueryKeys.DATE_RANGE_START_UNTIL],
      query[suggestionsQueryKeys.DATE_RANGE_END_FROM],
      query[suggestionsQueryKeys.DATE_RANGE_END_UNTIL],
      query[queryKeys.SET_BID],
      query[queryKeys.FOLLOWING],
    ].some(Boolean),
);

/**
 * Получение значения ставки из формы
 */
export const getBitFromForm = defaultMemoize((state) => formSelector(state, formFields.BIT_FIELD));

/**
 * Получение url параметров страницы отдельного графика
 * @type {function(*=): {id: *, step: *}}
 */
export const getGraphicsItemPageParams = createSelector(
  (props) => _get(props, 'match'),
  (match) => {
    return {
      id: _get(match, 'params.id'),
      step: _get(match, 'params.step') || DOCUMENTS,
    };
  },
);

/**
 *  получение данных о статусе сканкопии
 */
const _getIsScanCopyError = defaultMemoize((item) => {
  const scanCopyStatus = _get(item, 'scancopy_status');

  return (
    scanCopyStatus === suggestionsQueryKeys.NOT_ATTACHED ||
    scanCopyStatus === suggestionsQueryKeys.NEED_RESIGN ||
    scanCopyStatus === suggestionsQueryKeys.EXCEEDED_DEADLINE
    // && shippingMappers.isItemWithStatus(item, 'realization_pending')
  );
});

/**
 * Получение печатной формы заявки
 */
export const getGraphicPrintableForm = createSelector(
  (store) => _get(store, 'contracts.printableForm'),
  (printableForm) => (fileName) =>
    printableForm
      ? [
          {
            typeId: 1,
            name: 'Печатная форма заявки',
            files: [
              {
                id: 1,
                fileName: fileName ? `Печатная форма заявки №${fileName}.pdf` : 'Печатная форма заявки.pdf',
                singedUrl: _get(printableForm, 'signed_url'),
              },
            ],
          },
        ]
      : [],
);
