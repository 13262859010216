// общие компоненты
export const ASIDE_PANEL = 'AsidePanel';
export const HEADER = 'Header';
export const TABLE = 'Table';
export const UPLOAD = 'Upload';

// формы
export const SUBSCRIPTIONS_FORM = 'SubscriptionsForm';
export const CHANNELS_FORM = 'ChannelsForm';
export const DRIVER_FORM = 'DriverForm';
export const SUGGESTION_CHANGE_FORM = 'SuggestionChangeForm';
export const SELECT_RESOURCES_FORM = 'SelectResourcesForm';
export const MASS_SENDING_FORM = 'MassSendingForm';
export const RESOURCE_VALIDATION_FORM = 'ResourceValidationForm';

// модалки
export const SUGGESTION_DETAIL = 'SuggestionDetail';
export const SHIPPING_DETAIL = 'ShippingDetail';
export const ORDER_DETAIL = 'OrderDetail';
export const POINT_ON_MAP_MODAL = 'PointOnMapModal';
export const HEADER_NOTIFICATIONS_POPOVER = 'HeaderNotificationsPopover';

// таблицы и фильтры
export const NOTICES_LIST = 'NoticesList';
export const NOTICES_FILTERS_PANEL = 'NoticesFiltersPanel';
