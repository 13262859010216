import * as types from './actionTypes';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

export const initialState = {
  loading: false,
  title: 'Главная страница',
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    case types.SET_LOADING:
      draft.loading = action.payload;
      break;

    case types.SET_TITLE:
      draft.title = action.payload;

    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
