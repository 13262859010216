import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IBoxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  display?: 'indeterminate';
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ children, display, className, ...props }: IBoxProps) => {
  return (
    <label className={classNames(styles.checkbox, { [styles[display!]]: !!display }, className)}>
      <input type="checkbox" {...props} className="visually-hidden" />
      <span className={styles.checkmark} {...props} />
    </label>
  );
};

Checkbox.defaultProps = {
  type: 'checkbox',
};

export default React.memo(Checkbox);
