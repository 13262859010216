import { WEEK_ORDER_ARRAY } from '../../../constants';

/**
 * Получаем массив дней за две недели с выбранного дня
 * @param {string} activeDay - выбранный день
 * @returns {*[]} массив дней недели
 */
export function getShiftedWeeks(activeDay) {
  const copiedWeek = [...WEEK_ORDER_ARRAY];
  const index = copiedWeek.indexOf(activeDay);
  const shiftedWeek = [...copiedWeek.splice(index, copiedWeek.length), ...copiedWeek.splice(0, index)];

  return [...shiftedWeek, ...shiftedWeek];
}
