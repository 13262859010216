import produce from 'immer';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import { searchCompanyByIds } from '../../services/companyService';
import { getCompaniesAndGroupsByIds, getMatchingTariff } from '../../services/partnersService';

/**
 * Из списка items по пути cargoOwningCompanyIdPath получить ид
 * Вернуть список уникальных
 * @param items
 * @param cargoOwningCompanyIdPath
 * @returns {any[]}
 * @private
 */
function _getCargoOwningCompaniesIds(items, cargoOwningCompanyIdPath) {
  return [
    ...new Set(
      items.reduce((acc, item) => {
        const companyId = _get(item, cargoOwningCompanyIdPath);
        if (companyId) {
          acc.push(companyId);
        }
        return acc;
      }, []),
    ),
  ];
}

/**
 * Расширение items в payload партнерской информацией
 * @param payload
 * @param cargoOwningCompanyIdPath
 * @returns {Promise<{items: *}>|*}
 */
export async function extendItemsWithPartnership(payload, cargoOwningCompanyIdPath = 'cargo_owning_company_id') {
  const cargoOwningCompaniesIds = _getCargoOwningCompaniesIds(payload.items, cargoOwningCompanyIdPath);

  if (!cargoOwningCompaniesIds.some(Boolean) || _isEmpty(cargoOwningCompaniesIds)) {
    return Promise.resolve(payload);
  }
  try {
    const pr1 = getCompaniesAndGroupsByIds(cargoOwningCompaniesIds, []);
    const pr2 = searchCompanyByIds(cargoOwningCompaniesIds);

    return Promise.all([pr1, pr2]).then(([res1, companies]) => {
      const activePartners = res1.partnerships.filter((p) => p.status === 'active');
      // Хэш-таблица -> obj.id: obj
      const partnershipsInfo = companies.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
      const items = _cloneDeep(payload.items).reduce((acc, item) => {
        const companyId = _get(item, cargoOwningCompanyIdPath);
        const active = activePartners.reduce((acc, item) => {
          if (item?.inviter_company_id === companyId) {
            acc[item?.inviter_company_id] = item;
          } else {
            acc[item?.invitee_company_id] = item;
          }
          return acc;
        }, {});
        // Если компания из списка есть в хэш-таблице
        if (partnershipsInfo[companyId]) {
          // Мутируем исходный объект
          item['partnership'] = {
            ...partnershipsInfo[companyId],
            partnershipId: active[companyId]?.id,
            active: !!active[companyId],
          };
        }
        acc.push(item);
        return acc;
      }, []);

      return { ...payload, items };
    });
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

/**
 * Расширение item партнерской информацией
 * @param item
 * @param cargoOwningCompanyIdPath
 */
export async function extendItemWithPartnership(item, cargoOwningCompanyIdPath = 'cargo_owning_company_id') {
  const cargoOwningCompanyId = _get(item, cargoOwningCompanyIdPath);

  if (!cargoOwningCompanyId) return item;

  const { partnerships } = await getCompaniesAndGroupsByIds([cargoOwningCompanyId], []);

  return searchCompanyByIds([cargoOwningCompanyId]).then((companies) => {
    const companyInfo = _get(companies, `[0]`, {});
    const partnerInfo = _get(partnerships, `[0]`, {});
    return produce(item, (draftState) => {
      if (companyInfo) {
        draftState['partnership'] = {
          ...companyInfo,
          partnershipId: partnerInfo?.id,
          active: partnerInfo?.status === 'active',
        };
      }
    });
  });
}

export async function extendItemsWithPartnerTariff(data) {
  try {
    const { items } = data;
    const suggestionIds = items.map((i) => i.id);
    if (_isEmpty(suggestionIds)) {
      return Promise.resolve(data);
    }
    const tariffs = await getMatchingTariff(suggestionIds);
    const extendedItems = items.map((item) => {
      return { ...item, partner_tariff: tariffs?.[item.id] ?? null };
    });
    return Promise.resolve({ ...data, items: extendedItems });
  } catch (e) {
    return Promise.resolve(data);
  }
}

export async function extendItemWithPartnerTariff(item) {
  try {
    if (item.id) {
      const tariffs = await getMatchingTariff([item.id]);
      return Promise.resolve({ ...item, partner_tariff: tariffs?.[item.id] ?? null });
    }
  } catch (e) {
    return Promise.resolve(item);
  }
}
