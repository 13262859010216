import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export default function IconBlock({
  Icon,
  className = '',
  iconClassName = '',
  contentContainerClassName = '',
  iconStyle = {},
  children,
}) {
  return (
    <div className={classNames(styles.iconBlock, className)}>
      <div className={classNames(styles.iconCol, iconClassName)} style={iconStyle}>
        {Icon}
      </div>

      <div className={classNames('flex column', contentContainerClassName)}>{children}</div>
    </div>
  );
}
