import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type TThemes =
  | 'default'
  | 'danger'
  | 'warning'
  | 'warning-dark'
  | 'traffic-blue'
  | 'success'
  | 'primary'
  | 'counter'
  | 'counter-white'
  | 'new'
  | 'base'
  | 'light'
  | 'lighter'
  | 'lightest'
  | 'direct-cargo-owner';
type TType = 'text' | 'counter';

interface IBadgeProps {
  type: TType;
  text: number | string | React.ReactNode;
  maxCount?: number;
  theme: TThemes;
}

function Badge(props: IBadgeProps & React.BaseHTMLAttributes<HTMLSpanElement>) {
  const { text, maxCount, type, theme, className, ...rest } = props;
  const THEMES = new Map()
    .set('default', `bg-color-${theme} color-${theme}`)
    .set('danger', `bg-color-${theme}-light color-${theme}-dark`)
    .set('warning', `bg-color-${theme}-light color-${theme}-dark`)
    .set('light', `bg-color-light color-dark`)
    .set('base', `bg-color-base color-lightest`)
    .set('lightest', `bg-color-${theme} color-dark`)
    .set('lighter', `bg-color-${theme} color-dark`)
    .set('warning-dark', `bg-color-${theme} color-white`)
    .set('traffic-blue', `bg-color-traffic-blue color-white`)
    .set('success', `bg-color-${theme}-light color-${theme}-dark`)
    .set('primary', `bg-color-${theme}-light color-${theme}-dark`)
    .set('counter', classNames(`bg-color-primary color-white`, styles.counter))
    .set('counter-white', classNames(`bg-color-white color-primary`, styles.counter))
    .set('new', `bg-color-danger color-white`)
    .set('direct-cargo-owner', styles.directCargoOwner);

  const value = type === 'counter' && maxCount && text! > maxCount ? `${maxCount}+` : text;

  return (
    <span
      className={classNames(THEMES.get(theme), { badge: theme !== 'new', badgeNew: theme === 'new' }, className)}
      {...rest}
    >
      {value}
    </span>
  );
}

Badge.defaultProps = {
  type: 'text',
  maxCount: 99,
};

export default React.memo(Badge);
