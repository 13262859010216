import React, { useCallback } from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconClose from '../../Icons/SVGIcons/IconClose';
import ModalWrapper from '../ModalWrapper';

function Modal(props) {
  const { isCloseOutOfModal, disableClosing, children, onClose, className, portalNode, style } = props;

  const handleOnClose = useCallback(() => {
    if (!disableClosing) {
      onClose();
    }
  }, [disableClosing, onClose]);

  return (
    <ModalWrapper onClose={handleOnClose} disableClosing portalNode={portalNode}>
      <div className={styles.background} onClick={handleOnClose} />
      <div className={classnames(styles.modal, className)} style={style}>
        {!disableClosing && (
          <IconClose
            width="30"
            height="30"
            color={isCloseOutOfModal ? 'white' : 'gray'}
            onClick={handleOnClose}
            className={isCloseOutOfModal ? styles.iconCloseOut : styles.iconClose}
          />
        )}
        {children}
      </div>
    </ModalWrapper>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  isCloseOutOfModal: PropTypes.bool,
  disableClosing: PropTypes.bool,
  portalNode: PropTypes.any,
};

Modal.defaultProps = {
  isCloseOutOfModal: false,
  disableClosing: false,
};

export default Modal;
