import React from 'react';

import SVGIcon from './index';

export default function IconDirectory(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 30 25">
      <path d="m8 13h4v1h-4zm0 3h6.5v1h-6.5zm0-6h4v1h-4zm0-3h4v1h-4zm0-3h6v1h-6zm15.9 9.49a6 6 0 0 0 1.1-3.49 5.9 5.9 0 0 0 -4-5.66v-4.34h-17v4h-4v21h17v-4h4v-5.34a6 6 0 0 0 1.49-.76l5.8 5.81 1.42-1.42zm-8.9 9.51h-13v-17h2v15h11zm4-4h-13v-17h13v2a5.94 5.94 0 0 0 -6 5.88v.12a5.94 5.94 0 0 0 5.88 6h.12zm0-5a4 4 0 1 1 4-4 4 4 0 0 1 -4 4z" />
    </SVGIcon>
  );
}
