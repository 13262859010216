import React from 'react';
import SVGIcon from './index';

const IconMailFilled = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM14 6C14.5523 6 15 6.44772 15 7V13C15 13.5523 14.5523 14 14 14H6C5.44772 14 5 13.5523 5 13V7C5 6.44772 5.44772 6 6 6H14ZM5.576 7.735C5.72236 7.50083 6.03083 7.42965 6.265 7.576L9.735 9.74475C9.89714 9.84609 10.1029 9.84609 10.265 9.74475L13.735 7.576C13.9692 7.42965 14.2776 7.50083 14.424 7.735C14.5704 7.96917 14.4992 8.27764 14.265 8.424L10.795 10.5927C10.3086 10.8968 9.69141 10.8968 9.205 10.5928L5.735 8.424C5.50083 8.27764 5.42965 7.96917 5.576 7.735Z"
      />
    </SVGIcon>
  );
};

IconMailFilled.defaultProps = {
  color: 'base',
};

export default IconMailFilled;
