import _uniq from 'lodash/uniq';
import { DEFAULT_APP_TIME_ZONE } from '../constants';

type TResult = {
  label?: string;
  value: Record<string, any> | any;
  type?: string;
};

type TLocation =
  | {
      country: string;
      region_with_type: string | null;
      area_with_type: string | null;
      city_with_type: string | null;
      settlement_type: string | null;
      settlement_with_type: string | null;
      street_with_type: string | null;
      street_type: string | null;
      house: string | number | null;
      office: string | number | null;
      label: string;
      timezone_name: string;
      /* deprecated */
      region: string | null;
      area: string | null;
      city: string | null;
      settlement: string | null;
      street: string | null;
      locality: string | null;
      locality_with_type: string | null;
      fraction: string | null;
      housing: string | null;
      structure: string | null;
      literature: string | null;
      time_zone: string | null;
    }
  | undefined;

export function getFullAddressObject(location: TLocation, reverse = false, biggestUnit = 6): TResult {
  const filteredData = [
    ...new Set([
      biggestUnit >= 0 ? location?.house : null, // 0
      biggestUnit >= 0 ? location?.street_with_type : null, // 0
      biggestUnit >= 0 ? location?.locality : null, // 0
      biggestUnit >= 1 ? location?.locality_with_type : null, // 1
      biggestUnit >= 2 ? location?.settlement_with_type : null, // 2
      biggestUnit >= 3 ? location?.city_with_type : null, // 3
      biggestUnit >= 4 ? location?.area_with_type : null, // 4
      biggestUnit >= 5 ? location?.region_with_type : null, // 5
      biggestUnit >= 6 ? location?.country : null, // 6
    ]),
  ].filter(Boolean);
  const result = reverse ? filteredData.reverse() : filteredData;
  const label = result.join(', ');
  const timezone = location?.time_zone ?? DEFAULT_APP_TIME_ZONE; // for un-breaking location migration

  return {
    label,
    value: {
      label,
      ...location,
      timezone,
    },
  };
}

export function getCityViewObject(location: TLocation, reverse = false, biggestUnit = 6, shortValue = false): TResult {
  const filteredData = [
    ...new Set([
      biggestUnit >= 0 ? location?.locality : null, // 0
      biggestUnit >= 1 ? location?.locality_with_type : null, // 1
      biggestUnit >= 2 ? location?.settlement_with_type : null, // 2
      biggestUnit >= 3 ? location?.city_with_type : null, // 3
      biggestUnit >= 4 ? location?.area_with_type : null, // 4
      biggestUnit >= 5 ? location?.region_with_type : null, // 5
      biggestUnit >= 6 ? location?.country : null, // 6
    ]),
  ].filter(Boolean);
  const result = reverse ? filteredData.reverse() : filteredData;
  const label = result.join(', ');
  const timezone = location?.time_zone ?? DEFAULT_APP_TIME_ZONE; // for un-breaking location migration

  if (shortValue) {
    return {
      label,
      value: label,
    };
  }

  return {
    label,
    value: {
      label,
      ...location,
      timezone,
    },
  };
}

export function getStreetViewObject(location: TLocation, shortValue = false): TResult | null {
  if (!location) {
    return null;
  }
  let label = location?.street_with_type || `${location?.street_type} ${location?.street}`;

  if (shortValue) {
    return {
      label,
      value: label,
    };
  }

  return {
    label,
    value: {
      ...location,
      label,
    },
  };
}

export const getAddressViewObject = (address: string, type: string, item: TLocation): TResult | null => {
  if (!address && !type) return null;
  return {
    label: getAddressLabel(address, type),
    value: {
      ...item,
      label: address,
      type,
    },
  };
};

export function getHouseViewObject(location, shortValue = false) {
  if (!location) {
    return null;
  }
  let label = location?.house;

  if (shortValue) {
    return {
      label,
      value: label,
    };
  }

  return {
    label,
    value: {
      ...location,
      label,
    },
  };
}

export const getBuildingViewObject = (
  houseNumber: string,
  houseType: string,
  block: string,
  blockType: string,
  index: string,
  item: TLocation,
): TResult | null => {
  if (!houseNumber && !houseType) return null;
  return {
    label: getHouseLabel(houseNumber, houseType, block, blockType),
    value: {
      ...item,
      label: houseNumber,
      type: houseType,
      block,
      blockType,
      index,
    },
  };
};

/**
 * Извлечение имени населенного пункта
 * @param location
 */
export const getLocationName = (location: TLocation): string | null | undefined => {
  return (
    location?.locality ||
    location?.locality_with_type ||
    location?.settlement_with_type ||
    location?.city_with_type ||
    /* TODO: убрать после миграции адресов на новую модель */
    location?.locality ||
    location?.settlement ||
    location?.city ||
    location?.region
  );
};

export function getLocationObject(point) {
  return {
    route: getLocationName(point?.location),
    area: point?.location?.area_with_type ?? null,
    region: point.location?.region_with_type || point.location?.region,
    fullAddress: getExactAddress(point?.location),
    radius: point?.radius ?? null,
    fiasId: point?.location?.fias_id,
    pointName: getFullPointName(point?.location),
    houseAddress: getExactAddress(point?.location),
    timeShift: point?.location?.time_zone || point?.location?.local_time_zone,
    timeZoneName: point?.location?.timezone_name,
    lat: point?.location?.lat,
    lng: point?.location?.lng,
    level: point?.location?.level,
  };
}

export const getAddressLabel = (address: string, type: string, withOutSpace?: boolean): string => {
  if (address && type) return `${type}.${withOutSpace ? '' : ' '}${address}`;
  if (address) return address;
  return '';
};

export const getHouseLabel = (houseNumber: string, houseType: string, block: string, blockType: string): string => {
  if (!houseNumber && !houseType) return '';
  const label = getAddressLabel(houseNumber, houseType, true);

  if (block) {
    const blockTypeTranslate = _getBlockTypeTranslation(blockType);
    const blockLabel = getAddressLabel(block, blockTypeTranslate, true);
    return `${label} ${blockLabel}`;
  }

  return label;
};

function _getBlockTypeTranslation(type: string): string {
  switch (type) {
    case 'сооружение':
      return 'с';
    default:
      return type;
  }
}

/**
 * Адрес точки маршрута в карточке графика
 * @param location {Object}
 */
export const getShortAddress = (location: TLocation): string => {
  const region = location?.region ? `${location.region}, ` : ``;
  const city = location?.city ? `${location.city}, ` : ``;
  const street = location?.street ? `${location.street}, ` : ``;
  const house = location?.house ? `${location.house}, ` : ``;
  const office = location?.office ? `оф.${location.office}` : ``;

  return (region + city + street + house + office).replace(/, $/, ``);
};

/** Получаем полное название населенного пункта
 * @param location
 * @returns {string}
 * @private
 */
export function getFullPointName(location: TLocation): string {
  const region = location?.region_with_type || location?.region;
  const area = location?.area_with_type || location?.area;
  const city = location?.city_with_type || location?.city;
  const locality = location?.locality_with_type || location?.locality;
  const settlement = location?.settlement_with_type || location?.settlement;

  return _uniq([locality, settlement, city, area, region]).filter(Boolean).join(', ');
}

/**
 * Получаем полное название адреса в нас. пункте
 * @param location
 * @returns {string}
 * @private
 */
export function getExactAddress(location: TLocation): string {
  let house = String(location?.house).startsWith('д')
    ? location?.house
    : location?.house
    ? `д ${location?.house}`
    : null;

  let street = location?.street_with_type || location?.street;
  let office = location?.office && `кв/оф ${location.office}`;
  // Temp (deprecated) now in house field
  // убрать после миграции адресов
  let fraction = location?.fraction && `/${location.fraction}`;
  let housing = location?.housing && `к ${location.housing}`;
  let structure = location?.structure && `стр ${location.structure}`;
  let literature = location?.literature && `лит ${location.literature}`;
  //--------------------------
  let fullAddress = _uniq([street, fraction ? `${house}${fraction}` : house, housing, structure, literature, office]);

  return fullAddress.filter(Boolean).join(', ');
}
