import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import TooltipTrigger from 'react-popper-tooltip';

interface PopperProps {
  content: React.ReactNode;
  children: React.ReactNode;
  hideArrow?: boolean;
  className?: string;
  withoutInline?: boolean;
  triggerClassName?: string;
  // не создавать новый элемент для тригера
  ghostTrigger?: boolean;
  interiorTrigger?: boolean;
  [key: string]: any;
}

const Trigger =
  (children: React.ReactNode, withoutInline, triggerClassName) =>
  ({ triggerRef, getTriggerProps }) =>
    (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: classNames(triggerClassName, {
            'flex-inline': !withoutInline,
          }),
        })}
      >
        {children}
      </span>
    );

const GhostTrigger =
  (children: React.ReactNode, triggerClassName) =>
  ({ triggerRef, getTriggerProps }) =>
    // @ts-ignore
    React.cloneElement(children, {
      ...getTriggerProps({
        ref: triggerRef,
        className: classNames(triggerClassName),
      }),
    });

const InteriorTrigger =
  (children: Function, withoutInline, triggerClassName) =>
  ({ triggerRef, getTriggerProps }) => {
    const trigger = (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: classNames(styles.interiorTrigger, triggerClassName, {
            'flex-inline': !withoutInline,
          }),
        })}
      ></span>
    );
    return <div>{children(trigger)}</div>;
  };

const Tooltip =
  (content: React.ReactNode, hideArrow?: boolean, className?: string) =>
  ({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) =>
    (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: classNames(styles.popper, className),
        })}
      >
        {!hideArrow && (
          <span
            {...getArrowProps({
              ref: arrowRef,
              'data-placement': placement,
              className: styles.popperArrow,
            })}
          />
        )}
        {content}
      </div>
    );

const Popover = memo(
  ({
    children,
    content,
    hideArrow,
    className,
    withoutInline,
    triggerClassName,
    modifiers = [],
    ghostTrigger,
    interiorTrigger,
    ...props
  }: PopperProps) => {
    if (!content) {
      // @ts-ignore
      if (interiorTrigger) return <>{children(null)}</>;
      return <>{children}</>;
    }
    return (
      <TooltipTrigger
        {...props}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              padding: 15,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
          ...modifiers,
        ]}
        tooltip={Tooltip(content, hideArrow, className)}
      >
        {interiorTrigger
          ? // @ts-ignore
            InteriorTrigger(children, withoutInline, triggerClassName)
          : ghostTrigger
          ? GhostTrigger(children, triggerClassName)
          : Trigger(children, withoutInline, triggerClassName)}
      </TooltipTrigger>
    );
  },
);

export default Popover;
