import React from 'react';

import Badge from '../../Badge';
import * as statusKeys from '../../../store/resources/statusKeys';

/**
 * Получить текст для статуса
 **/
const statusText = new Map()
  .set(statusKeys.NOT_APPROVED, 'На заполнении')
  .set(statusKeys.APPROVED, 'Допущен')
  .set(statusKeys.REJECTED, 'Заблокирован')
  .set(statusKeys.REVIEW, 'На проверке')
  .set(statusKeys.ACCESS_SUSPENDED, 'Приостановлен')
  .set(statusKeys.NOT_ALLOWED, 'Не допущен')
  .set(statusKeys.NOT_ATTORNEY, 'Нет доверенности')
  .set(statusKeys.NEED_FIX_BY_USER, 'На доработке');

export function getDriverStatus({ status, theme }) {
  return <Badge text={statusText.get(status) || status} theme={theme} />;
}
