import moment from 'moment';

import { CATEGORY_1, CATEGORY_2, SHORT_DATE_FORMAT } from '../constants';
import { APPROVED } from '../store/resources/statusKeys';

type TFreezing = { code_name: string; end_time: moment.Moment }[];
type TInfo = { title: string; text: string };

export function freezingAnalyze(freezing: TFreezing = []): {
  k1: {
    status: boolean;
    date: boolean;
  };
  k2: {
    status: boolean;
    date: boolean;
  };
} {
  // Результирующий объект
  const result = {
    k1: {
      status: false,
      date: false,
    },
    k2: {
      status: false,
      date: false,
    },
  };
  // Если массив с заморозкой не пустой
  if (freezing && freezing.length && freezing.length > 0) {
    freezing.forEach((item) => {
      let key: boolean | string = false;
      // Получение категории
      if (item?.code_name === CATEGORY_1) {
        key = 'k1';
      } else if (item?.code_name === CATEGORY_2) {
        key = 'k2';
      }
      // Заполнение данных, если найдена категория
      if (key) {
        // Получение текущей даты
        let date: moment.Moment = moment(item?.end_time);
        result[key].date = date.format(SHORT_DATE_FORMAT);
        // Если дата из прошлого, то меняется статус заморозки
        result[key].status = date.isAfter(moment());
      }
    });
  }
  return result;
}

/**
 * Получить текст для текущей заморозки для автомашин и прицепов
 */
export function getFreezeNotification(freezing: TFreezing = [], categoryStatus: {} = {}): TInfo {
  const freezeInfo = freezingAnalyze(freezing);

  // Проверка исключения у категории
  if (
    freezeInfo?.k1?.status &&
    freezeInfo?.k2?.status &&
    categoryStatus[CATEGORY_1]?.status === APPROVED &&
    categoryStatus[CATEGORY_2]?.status === APPROVED
  ) {
    return {
      title: 'Допущен (находится на временной заморозке)',
      text: `Ресурс прошёл проверку и может исполнять заявки от ООО «Деловые Линии» с ${freezeInfo?.k1?.date},  от ООО «ДЛ-Транс» с ${freezeInfo?.k2?.date}.`,
    };
  }

  //----------------------------------------------------
  if (
    freezeInfo?.k1?.status &&
    categoryStatus[CATEGORY_1]?.status === APPROVED &&
    categoryStatus[CATEGORY_2]?.status === APPROVED
  ) {
    return {
      title: 'Допущен (находится на временной заморозке)',
      text: `Ресурс прошел проверку и может исполнять заявки ООО «ДЛ-Транс». Доступ к заявкам ООО «Деловые Линии» будет доступен с ${freezeInfo?.k1?.date}.`,
    };
  }
  if (
    freezeInfo?.k2?.status &&
    categoryStatus[CATEGORY_1]?.status === APPROVED &&
    categoryStatus[CATEGORY_2]?.status === APPROVED
  ) {
    return {
      title: 'Допущен (находится на временной заморозке)',
      text: `Ресурс прошел проверку и может исполнять заявки ООО «Деловые Линии». Доступ к заявкам ООО «ДЛ-Транс» будет доступен с ${freezeInfo?.k2?.date}.`,
    };
  }
  //----------------------------------------------------

  if (freezeInfo?.k1?.status && categoryStatus[CATEGORY_1]?.status === APPROVED) {
    return {
      title: 'Допущен до заявок ООО «Деловые Линии» (находится на временной заморозке)',
      text: `Ресурс прошел проверку и может исполнять заявки ООО «Деловые Линии» ${
        freezeInfo?.k1?.date ? `c ${freezeInfo?.k1?.date}` : ''
      }.`,
    };
  }

  if (freezeInfo?.k2?.status && categoryStatus[CATEGORY_2]?.status === APPROVED) {
    return {
      title: 'Допущен до заявок ООО «ДЛ-Транс» (находится на временной заморозке)',
      text: `Ресурс прошел проверку и может исполнять заявки ООО «ДЛ-Транс» ${
        freezeInfo?.k2?.date ? `c ${freezeInfo?.k2?.date}` : ''
      }.`,
    };
  }

  return {
    title: '',
    text: '',
  };
}

/**
 * Получить текст для текущей заморозки для автомашин и прицепов
 */
export function getFreezeNotificationForVehicle(freezing: TFreezing = []): TInfo {
  const freezeInfo = freezingAnalyze(freezing);
  if (freezeInfo?.k1?.status || freezeInfo?.k2?.status) {
    return {
      title: 'Допущен (находится на временной заморозке)',
      text: `Ресурс прошёл проверку и может исполнять заявки клиентов с ${
        freezeInfo?.k1?.status ? freezeInfo?.k1?.date : freezeInfo?.k2?.date
      }.`,
    };
  }

  return {
    title: '',
    text: '',
  };
}
