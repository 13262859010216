import React, { Dispatch, useCallback, SetStateAction, useState, createContext } from 'react';
import _get from 'lodash/get';
import { useQuery } from 'react-query';

import LocationsListModal from '../../../components/ModalCollections/LocationsListModal';
import { getLocations, pickLocation } from '../../../services/counterAgentsService';
import { TLocation } from '../../../store/counterAgents/models';
import { ROUTE_ADDRESS_CITY, ROUTE_POINTS, ROUTE_RADIUS } from '../blocks/RoutesBlock';
import { useOrderContext } from '../index';
import { useFieldValue } from '../../../components/FormikFieldsV3/hooks';
import { EOrderStatuses } from '../../../constants/order';

export type TSavedLocationsContext = {
  hasAvailableLocations: boolean;
  showModal: (data?: any) => void;
  setShowingModal: Dispatch<SetStateAction<boolean>>;
  selectedLocation: TLocation | null;
  setSelectedLocation: Dispatch<SetStateAction<TLocation | null>>;
  fieldName: string;
  cityFiasId: string | null;
};

export const SavedLocationsContext = createContext<TSavedLocationsContext>({
  hasAvailableLocations: false,
  showModal: () => {},
  setShowingModal: () => {},
  selectedLocation: null,
  setSelectedLocation: () => {},
  fieldName: '',
  cityFiasId: null,
});

export function withSavedLocations(WrappedComponent) {
  return function (props) {
    const { index, routePointsField = ROUTE_POINTS, isOrderChangeWithWarehouse } = props;
    const { isOrderChange, isOrderBasedOnOrder, isContractBasedOnContract, currentOrder } = useOrderContext();
    const [isShowingModal, setShowingModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<TLocation | null>(null);

    const [routePoint] = useFieldValue(`${routePointsField}[${index}]`);

    // Значение города из формы создания заявки по контракту
    const orderBasedPointCityAddress =
      !isOrderChange &&
      !isOrderBasedOnOrder &&
      !isContractBasedOnContract &&
      currentOrder &&
      currentOrder.status !== EOrderStatuses.DRAFT
        ? _get(routePoint, `[${ROUTE_ADDRESS_CITY}].value`)
        : null;
    const orderBasedPointFiasId =
      orderBasedPointCityAddress?.settlement_fias_id || orderBasedPointCityAddress?.city_fias_id;
    // Значение города для формы утверждения
    const orderConfirmPointFiasId = _get(routePoint, `city_fias_id`);

    const initialValuesCityFiasId = orderConfirmPointFiasId || orderBasedPointFiasId || null;

    // Радиус
    const radius = _get(routePoint, `[${ROUTE_RADIUS}]`);

    const requestParams = {
      fias_id: initialValuesCityFiasId,
      radius: radius || undefined,
    };

    // Проверка доступности сохраненных адресов для фиас ид
    const { data } = useQuery(['address', { per_page: 1, ...requestParams }], () =>
      getLocations({ per_page: 1, ...requestParams }, false),
    );
    const hasAvailableLocations = !!data?.totalItems;

    const showModal = useCallback(() => setShowingModal(true), []);
    const hideModal = useCallback(() => setShowingModal(false), []);

    const onSelectLocation = useCallback((item) => {
      setSelectedLocation(item);
      setShowingModal(false);
      setTimeout(() => pickLocation(item.id), 0);
    }, []);

    return (
      <SavedLocationsContext.Provider
        value={{
          hasAvailableLocations,
          showModal,
          setShowingModal,
          selectedLocation,
          setSelectedLocation,
          fieldName: `${routePointsField}[${index}]`,
          cityFiasId: initialValuesCityFiasId,
        }}
      >
        <WrappedComponent {...props} />
        {isShowingModal && (
          <LocationsListModal
            onClose={hideModal}
            onSelect={onSelectLocation}
            queryParams={requestParams}
            isOrderChangeWithWarehouse={isOrderChangeWithWarehouse}
          />
        )}
      </SavedLocationsContext.Provider>
    );
  };
}
