import React from 'react';
//import _get from 'lodash/get';

import styles from './styles.module.scss';

export default function Location(suggestion) {
  // TODO: надо менять структуру ответа
  // разные ответы от dadata и 1С
  //const name = _get(suggestion, 'value.???')
  //const region = _get(suggestion, 'value.???')
  return <div className={styles.suggestion}>{suggestion.label}</div>;
}
