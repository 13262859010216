function calculateMinBidConsideringStep(bid: number, step?: number, limit: number | null = null) {
  const min = Math.round(bid * 0.4);

  if (!step) return min;

  const minLimit = limit || Math.round(bid * 0.4 - step!);

  let currentValue = Number(bid);
  const minArr = [currentValue];

  if (limit) {
    if (bid - limit <= step) {
      return +bid;
    }
    while (currentValue - minLimit > 0) {
      currentValue -= step!;
      minArr.push(currentValue);
    }
    return Math.abs(limit - minArr[minArr.length - 1]) < Math.abs(limit - minArr[minArr.length - 2])
      ? minArr[minArr.length - 1]
      : minArr[minArr.length - 2];
  } else {
    while (currentValue - minLimit > 0) {
      minArr.push(currentValue);
      currentValue -= step!;
    }
    return Math.abs(min - minArr[minArr.length - 1]) < Math.abs(min - minArr[minArr.length - 2])
      ? minArr[minArr.length - 1]
      : minArr[minArr.length - 2];
  }
}

export default calculateMinBidConsideringStep;
