import React from 'react';

import Tooltip from '../../Tooltip';
import { IconFlash, IconFlag, IconSector } from '../../Icons';
import * as formKeys from '../../../store/order/auction/formKeys';
import { INSTANT } from '../../../constants';
import IconAuctionOpened from '../../Icons/SVGIcons/IconAuctionOpened';
import IconAuctionClosed from '../../Icons/SVGIcons/IconAuctionClosed';
import IconAuctionDrop from '../../Icons/SVGIcons/IconAuctionDrop';
import { BLIND_AUCTION } from '../../../store/order/auction/formKeys';
import getFormattedPrice from '../../../helpers/getFormattedPrice';

const AUCTION_HELPERS = new Map()
  .set(INSTANT, {
    icon: <IconFlash width="20" height="20" />,
    type: 'Кто быстрее',
  })
  .set(formKeys.OPEN_AUCTION, {
    icon: <IconAuctionOpened width="20" height="20" />,
    type: 'Открытый аукцион',
  })
  .set(formKeys.BLIND_AUCTION, {
    icon: <IconAuctionClosed width="20" height="20" />,
    type: 'Закрытый аукцион',
  })
  .set(formKeys.DESCENDING_PRICE_AUCTION, {
    icon: <IconAuctionDrop width="20" height="20" />,
    type: 'Аукцион на понижение',
  });

interface AuctionTypeProps {
  auctionType?: string;
  bid?: { currentBid: string; isWinning: boolean; winningBid: string; isExpeditedPayment: boolean; _original: any };
  isContractType?: boolean;
  isWinSelection?: boolean;
  hasWinningBid?: boolean;
  rawPrice?: string | number;
  _original?: any;
}

/**
 * Получение иконки о типе торга
 * @returns {*}
 * @param item
 */
export function getAuctionTypeIcon(item: AuctionTypeProps): JSX.Element {
  const { auctionType, bid, isContractType, isWinSelection, hasWinningBid, _original } = item;
  const isAuction = [formKeys.BLIND_AUCTION, formKeys.OPEN_AUCTION, formKeys.DESCENDING_PRICE_AUCTION].includes(
    auctionType || '',
  );

  if (!isContractType && _original?.quota) {
    return (
      <Tooltip content={'Заявка по объёмному контракту через квотирование'}>
        <IconSector width="20" height="20" />
      </Tooltip>
    );
  }

  const currentBid =
    (item.bid?._original?.value ?? 0) -
      (item.bid?._original?.expedited_payment_days ? item.bid?._original?.expedited_payment_fee ?? 0 : 0) ||
    item?.rawPrice;
  const isBlindAuction = auctionType === formKeys.BLIND_AUCTION;
  if (isAuction) {
    if (isContractType && isWinSelection) {
      return <IconFlag width="20" height="20" color="primary" />;
    }
    if (bid?.currentBid) {
      return (
        <Tooltip
          content={
            <span>
              Ваша ставка {!isBlindAuction && (bid?.isWinning ? '(лидирует)' : '(не лидирует)')}
              <br /> {getFormattedPrice(currentBid)} ₽ {!!bid?.isExpeditedPayment && 'с ускоренной оплатой'}
            </span>
          }
        >
          {React.cloneElement(AUCTION_HELPERS.get(auctionType)?.icon, {
            color: !isBlindAuction ? (bid?.isWinning ? 'success' : 'danger') : 'primary',
          })}
        </Tooltip>
      );
    }
    return (
      <Tooltip content={`Тип торга: ${AUCTION_HELPERS.get(auctionType)?.type}`}>
        {React.cloneElement(AUCTION_HELPERS.get(auctionType)?.icon, {
          // hasWinningBid используется только для разовых заявок ГВ
          ...(hasWinningBid ? { color: 'primary' } : {}),
        })}
      </Tooltip>
    );
  }

  return (
    <Tooltip content="Тип торга: Кто быстрее">
      <IconFlash height="20" width="20" />
    </Tooltip>
  );
}

/**
 * Получение форматированной цены ставки
 * @param item
 */
export function getCurrentPriceForSuggestion(item) {
  const { auctionType, bid, price, rawPrice } = item;
  let currentPrice;

  if (auctionType === BLIND_AUCTION) {
    currentPrice = getFormattedPrice(bid?.currentBidRaw || bid?.initialBid || rawPrice);
  } else {
    currentPrice =
      (bid?.isExpeditedPayment
        ? getFormattedPrice(bid?.expeditedPaymentWinningBid)
        : getFormattedPrice(bid?.winningBid || bid?.initialBid)) || price;
  }

  return currentPrice;
}
