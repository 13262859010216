import React, { lazy, Suspense } from 'react';

import ContentLoader from '../../../../components/ContentLoader';
import { useUserContext } from '../../../../contexts/userContext';
import { useOrderContext } from '../../index';

const MultipolisBannerBlock = lazy(() => import('./MultipolisBannerBlock'));

function getBlockComponent(hashString) {
  switch (hashString) {
    default:
      return MultipolisBannerBlock;
  }
}

export function InsuranceBannerBlock() {
  const { isMultipolicyAvailable } = useUserContext();
  const { isOrderChange } = useOrderContext();
  if (!isMultipolicyAvailable || isOrderChange) return null;

  const Component = getBlockComponent('');
  return <Suspense fallback={<ContentLoader withBackground />}>{Component ? <Component /> : null}</Suspense>;
}
