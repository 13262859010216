import { useQuery } from 'react-query';
import { TListState } from '../../store/models';
import { TLocation } from '../../store/counterAgents/models';
import { getLocations } from '../counterAgentsService';
import { UseQueryOptions } from 'react-query';

function useLocations(
  params: Record<string, any>,
  options?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>,
) {
  const _params = { page: 1, per_page: 20, ...params };
  return useQuery<TListState<TLocation>>(['address', _params], () => getLocations(_params), options);
}

export { useLocations };
