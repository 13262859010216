import React from 'react';
import SVGIcon from './index';

const IconClear = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        pointerEvents="visible"
        d="M10 8.94736L7.05268 6L6 7.05268L8.94736 10L6 12.9474L7.05268 14.0001L10 11.0527L12.9473 14L14 12.9473L11.0527 10L14 7.05274L12.9473 6.00006L10 8.94736Z"
      />
    </SVGIcon>
  );
};

IconClear.defaultProps = {
  color: 'base',
};

export default IconClear;
