import React from 'react';
import { FormikConfig, FormikValues, useFormik } from 'formik';

import { useFormikContextSelector, FormikContext } from './FormikContext';

export function Form<Values = any>(props) {
  const handleReset = useFormikContextSelector<Values, any>((c) => c.handleReset);
  const handleSubmit = useFormikContextSelector<Values, any>((c) => c.handleSubmit);
  return <form onReset={handleReset} onSubmit={handleSubmit} {...props} />;
}

export function Formik<Values extends FormikValues = FormikValues, ExtraProps = {}>(
  props: FormikConfig<Values> & ExtraProps,
) {
  const { children } = props;
  const formikBag = useFormik<Values>(props);
  return <FormikContext.Provider value={formikBag}>{children}</FormikContext.Provider>;
}
