import { roundToHundredths } from '../../../containers/order-v2/helpers/getInitialValues';
import { BLIND_AUCTION } from '../../../store/order/auction/formKeys';
import { useUserContext } from '../../../contexts/userContext';
import { WITH_TAX } from '../../../store/company/formKeys';

/**
 * Расчет актуальных значений руб/км и руб/ч, если есть ставка
 * @param item
 */
export function getPricesPerKmAndHour(item) {
  const { auctionType, bid, _original, isHourlyRent } = item;
  let currentBid;
  let minWorkHours;
  if (auctionType === BLIND_AUCTION && bid?.currentBidRaw) {
    currentBid = bid.currentBidRaw;
    minWorkHours = bid.currentMinWorkHours || _original.min_work_hours;
  } else if (bid?.isExpeditedPayment && bid?.expeditedPaymentWinningBid) {
    currentBid = bid.expeditedPaymentWinningBid;
  } else if (bid?.winningBid) {
    currentBid = bid.winningBid;
  }

  const costPerKm =
    currentBid && _original?.route_distance && isHourlyRent && minWorkHours
      ? roundToHundredths(((currentBid * minWorkHours) / _original.route_distance) * 1000)
      : currentBid && _original?.route_distance
      ? roundToHundredths((currentBid / _original.route_distance) * 1000)
      : _original?.cost_per_km;
  const costPerHour =
    currentBid && _original?.execution_time
      ? roundToHundredths((currentBid / _original.execution_time) * 60)
      : _original?.cost_per_hour;

  return [costPerKm, costPerHour];
}

/**
 * Получение руб/км и руб/ч для перевоза из оригинала shipping_request
 * @param shipping_request_original
 */
export function usePricesPerKmAndHourByTaxType(shipping_request_original) {
  const { companyTaxType: taxType } = useUserContext();
  return getPricesPerKmAndHourByTaxType(shipping_request_original, taxType);
}
/**
 * Получение руб/км и руб/ч для перевоза из оригинала shipping_request
 * @param shipping_request_original
 * @param taxType
 */
export function getPricesPerKmAndHourByTaxType(shipping_request_original, taxType) {
  const getPrices = (costPerKm, costPerHour) => ({ costPerKm, costPerHour });

  // Используем только БЕЗ НДС
  // if (taxType === WITH_TAX) {
  //   return getPrices(shipping_request_original.cost_per_km_with_vat, shipping_request_original.cost_per_hour_with_vat);
  // } else {
  return getPrices(
    shipping_request_original.cost_per_km_without_vat,
    shipping_request_original.cost_per_hour_without_vat,
  );
  // }
}
