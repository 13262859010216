import React from 'react';
import SVGIcon from './index';

const IconTelegramFilled = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.3186 7.64224C9.44148 8.00959 7.69471 8.76677 5.0708 9.90629C4.65098 10.0787 4.42607 10.2437 4.40358 10.4086C4.3661 10.7235 4.81591 10.8209 5.37818 11.0008C5.84298 11.1508 6.45773 11.3232 6.78009 11.3307C7.07247 11.3382 7.39484 11.2182 7.75469 10.9709C10.2062 9.31404 11.4656 8.47439 11.5481 8.4594C11.6081 8.4444 11.683 8.42941 11.7355 8.47439C11.788 8.51937 11.788 8.60934 11.7805 8.63183C11.7355 8.81925 9.43399 10.9184 9.29904 11.0533C8.78925 11.5781 8.212 11.9005 9.10412 12.4852C9.8763 12.995 10.3186 13.3174 11.1133 13.8347C11.6156 14.1645 12.0129 14.5544 12.5377 14.5094C12.7776 14.4869 13.025 14.262 13.1524 13.5873C13.4523 11.9979 14.0371 8.54186 14.172 7.11746C14.187 6.99751 14.172 6.84007 14.157 6.7651C14.142 6.69014 14.1195 6.59268 14.0296 6.51771C13.9171 6.42774 13.7522 6.41275 13.6772 6.41275C13.3399 6.42025 12.8151 6.60017 10.3186 7.64224Z"
      />
    </SVGIcon>
  );
};

IconTelegramFilled.defaultProps = {
  color: 'base',
};

export default IconTelegramFilled;
