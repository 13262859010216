import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import styles from './styles.module.scss';
import { IconDot } from '../../Icons';

const RadioLabel = ({ value, title, queryKey, onClick, withNotification, disabled }) => {
  const shareProps = {
    type: 'radio',
    name: 'type',
    value: value,
    className: 'visually-hidden',
  };
  const isActive = isEqual(value, queryKey);

  return isActive ? (
    <label key={value} className={classnames(styles.radioLabel, styles.active)} aria-selected="true" role="tab">
      {title}
      <input {...shareProps} defaultChecked />
      {withNotification && <IconDot width={6} height={6} color="warning" className={styles.icon} />}
    </label>
  ) : (
    <label key={value} className={styles.radioLabel} role="tab">
      {title}
      <input {...shareProps} onClick={onClick} disabled={disabled} />
      {withNotification && <IconDot width={6} height={6} color="warning" className={styles.icon} />}
    </label>
  );
};

RadioLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  queryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClick: PropTypes.func,
  withNotification: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RadioLabel;
