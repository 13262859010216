import React from 'react';
import SVGIcon from './index';

const IconInfo = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M9 8V6h2v2H9zM9 14V9h2v5H9z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-1.5 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
      />
    </SVGIcon>
  );
};

IconInfo.defaultProps = {
  color: 'base',
};

export default React.memo(IconInfo);
