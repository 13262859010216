import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

import IconLoading from '../Icons/CSSIcons/IconLoading/IconLoading';

type TSize = 'small' | 'medium' | 'large';
export type TTheme = 'primary' | 'danger' | 'secondary' | 'text' | 'light' | 'outline' | 'counter' | 'trafficRed';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: TSize;
  theme?: TTheme;
  isLoading?: boolean;
  icon?: React.ReactNode;
  // тэг или компонент на основе которого будет создана кнопка
  component?: React.ReactNode;
}

const Button = (props: IButtonProps) => {
  const btnClassName = classNames(
    styles.btn,
    {
      [styles[props.theme!]]: props.theme,
      [styles[props.size!]]: props.size,
      [styles.loading]: props.isLoading,
    },
    props.className,
  );
  const acceptedProps = { ...props };

  delete acceptedProps.theme;
  delete acceptedProps.size;
  delete acceptedProps.icon;
  delete acceptedProps.isLoading;
  delete acceptedProps.component;

  // Для случая, когда компонент задизейблен и не имеет такого стейта (линк)
  const component: React.ReactNode = props.disabled ? 'button' : props.component;

  const method: any = typeof component === 'string' ? React.createElement : React.cloneElement;

  return method(
    component,
    {
      ...acceptedProps,
      className: btnClassName,
      disabled: props.disabled || props.isLoading,
    },
    props.isLoading ? <IconLoading width={30} height={30} /> : props.children,
  );
};

Button.defaultProps = {
  theme: 'primary',
  component: 'button',
  size: 'small',
};

export function ButtonGroup(props) {
  return <section className={styles.buttonGroup}>{props.children}</section>;
}

export default Button;
