import * as resourcesKeys from '../../resources/formKeys';
import * as bodyTypes from '../../resources/bodyTypeOptions';

export const RF_ONLY = {
  label: 'Нужны данные по всей РФ',
  labelHelp:
    'Диапазон рыночных цен на перевозки грузов по России. При выборе этого параметра блок направлений будет скрыт',
};

export const CITY = 'CITY';
export const COUNTRY = 'COUNTRY';
export const REGION = 'REGION';
export const FEDERAL_DISTRICT = 'FEDERAL_DISTRICT';

export const DIRECTION_TYPES = [
  { label: 'Населённый пункт', key: CITY },
  { label: 'Субъект РФ', key: REGION },
  { label: 'Округ', key: FEDERAL_DISTRICT },
];

export const BODY_TYPES = {
  [resourcesKeys.AWNING]: bodyTypes.AWNING_OBJ,
  [resourcesKeys.ISOTHERMAL]: bodyTypes.ISOTHERMAL_OBJ,
  [resourcesKeys.REFRIGERATOR]: bodyTypes.REFRIGERATOR_OBJ,
};
