import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconCash = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path
        d="M15.75 9.75C15.75 11.4069 14.4069 12.75 12.75 12.75C11.0931 12.75 9.75 11.4069 9.75 9.75C9.75 8.09315 11.0931 6.75 12.75 6.75C14.4069 6.75 15.75 8.09315 15.75 9.75Z"
        fill={colors[props.color!]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 3V16.5H24V3H1.5ZM6 5.25H19.5C19.5 6.49264 20.5074 7.5 21.75 7.5V12C20.5074 12 19.5 13.0074 19.5 14.25H6C6 13.0074 4.99264 12 3.75 12V7.5C4.99264 7.5 6 6.49264 6 5.25Z"
        fill={colors[props.color!]}
      />
      <path
        d="M6 27V18.75H8.25V22.5C9.49264 22.5 10.5 23.5074 10.5 24.75H24C24 23.5074 25.0074 22.5 26.25 22.5V13.5H28.5V27H6Z"
        fill={colors[props.color!]}
      />
      <path
        d="M14.25 20.25C14.25 19.7036 14.3961 19.1913 14.6513 18.75H19.8487C20.1039 19.1913 20.25 19.7036 20.25 20.25C20.25 21.9069 18.9069 23.25 17.25 23.25C15.5931 23.25 14.25 21.9069 14.25 20.25Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconCash.defaultProps = { color: 'base' };

export default IconCash;
