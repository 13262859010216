import React from 'react';
import SVGIcon from './index';

export default function IconHelpFilled(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M10.001 18.334C8.8623 18.334 7.78597 18.1153 6.77197 17.678C5.75797 17.2406 4.87264 16.647 4.11597 15.897C3.35864 15.147 2.7613 14.265 2.32397 13.251C1.88664 12.237 1.66797 11.1536 1.66797 10.001C1.66797 8.8483 1.88664 7.76497 2.32397 6.75097C2.7613 5.73697 3.35864 4.85497 4.11597 4.10497C4.87264 3.35497 5.75797 2.7613 6.77197 2.32397C7.78597 1.88664 8.8623 1.66797 10.001 1.66797C11.1676 1.66797 12.258 1.88664 13.272 2.32397C14.286 2.7613 15.168 3.35497 15.918 4.10497C16.668 4.85497 17.2583 5.73697 17.689 6.75097C18.119 7.76497 18.334 8.8483 18.334 10.001C18.334 11.1536 18.119 12.237 17.689 13.251C17.2583 14.265 16.668 15.147 15.918 15.897C15.168 16.647 14.286 17.2406 13.272 17.678C12.258 18.1153 11.1676 18.334 10.001 18.334ZM9.16797 11.772H10.793C10.793 11.2993 10.845 10.9346 10.949 10.678C11.053 10.4213 11.348 10.0566 11.834 9.58397C12.1813 9.23664 12.4593 8.89997 12.668 8.57397C12.876 8.2473 12.98 7.85497 12.98 7.39697C12.98 6.61897 12.6986 6.02164 12.136 5.60497C11.574 5.1883 10.897 4.97997 10.105 4.97997C9.29964 4.97997 8.6433 5.1883 8.13597 5.60497C7.6293 6.02164 7.27864 6.52864 7.08397 7.12597L8.54297 7.70897C8.6123 7.47297 8.75797 7.20564 8.97997 6.90697C9.20197 6.6083 9.56297 6.45897 10.063 6.45897C10.4936 6.45897 10.8203 6.57364 11.043 6.80297C11.265 7.0323 11.376 7.28563 11.376 7.56297C11.376 7.82697 11.2893 8.08397 11.116 8.33397C10.942 8.58397 10.737 8.8063 10.501 9.00097C9.87564 9.5423 9.4973 9.95197 9.36597 10.23C9.23397 10.508 9.16797 11.022 9.16797 11.772ZM9.95897 15.022C10.265 15.022 10.522 14.9176 10.73 14.709C10.9386 14.501 11.043 14.2443 11.043 13.939C11.043 13.633 10.9386 13.376 10.73 13.168C10.522 12.9593 10.265 12.855 9.95897 12.855C9.65364 12.855 9.39697 12.9593 9.18897 13.168C8.9803 13.376 8.87597 13.633 8.87597 13.939C8.87597 14.2443 8.9803 14.501 9.18897 14.709C9.39697 14.9176 9.65364 15.022 9.95897 15.022Z" />
    </SVGIcon>
  );
}

IconHelpFilled.defaultProps = {
  color: 'base',
};
