import React from 'react';
import classnames from 'classnames';
import styles from '../styles.module.scss';

function Cell({
  tableColumnId,
  Element,
  className,
  externalClassName,
  cellContentClassName,
  style,
  width,
  ellipsis,
  children,
}) {
  return (
    <Element
      table-column-id={tableColumnId}
      className={classnames(className, externalClassName)}
      style={{ ...style, width: width, minWidth: width }}
    >
      <div className={classnames(cellContentClassName, { [styles.cellEllipsis]: ellipsis })}>{children}</div>
    </Element>
  );
}

export default Cell;
