import React, { InputHTMLAttributes, ReactNode, useCallback, useMemo, useState } from 'react';
import InputHOC from '../inputHOC';
import Popover from '../../Popover';
import { IconChevron } from '../../Icons';
import styles from './FormTimePicker.module.scss';
import classNames from 'classnames';
import set from 'date-fns/set';
import format from 'date-fns/format';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import startOfDay from 'date-fns/startOfDay';
import { useFieldValue } from '../hooks';
import { TIME_FORMAT } from '../../../constants';
import { FieldProps } from 'formik';

const HOURS = 'hours';
type THOURS = typeof HOURS;
const MINUTES = 'minutes';
type TMINUTES = typeof MINUTES;
type TTIME_TYPES = THOURS | TMINUTES;

function generateOptions(length, disabledOptions, hideDisabledOptions, step = 1) {
  const arr: Array<number> = [];
  for (let value = 0; value < length; value += step) {
    if (!disabledOptions || disabledOptions.indexOf(value) < 0 || !hideDisabledOptions) {
      arr.push(value);
    }
  }
  return arr;
}

function formatOption(option, disabledOptions) {
  const label = option < 10 ? `0${option}` : `${option}`;
  const disabled = disabledOptions && disabledOptions.indexOf(option) >= 0;

  return {
    label,
    value: option,
    disabled,
  };
}

function defaultValueMapper(v: string | Date): Date | string {
  if (typeof v === 'string') {
    const splitedValue = v.split(':');
    return set(new Date(), { hours: Number(splitedValue[0]), minutes: Number(splitedValue[1]) });
  }
  return format(v, TIME_FORMAT);
}

export function mapValueAsMinutes(v: number | Date): Date | number {
  if (typeof v === 'number') {
    const hours = Math.floor(v / 60);
    const minutes = v % 60;
    return set(new Date(), { hours: Number(hours), minutes: Number(minutes) });
  }

  return getHours(v) * 60 + getMinutes(v);
}

function onTimeChange(type: TTIME_TYPES, value: Date = startOfDay(new Date()), newValue, onChange) {
  let formattedValue;
  if (type === HOURS) {
    formattedValue = setHours(value, newValue);
  } else if (type === MINUTES) {
    formattedValue = setMinutes(value, newValue);
  }
  onChange(formattedValue);
}

function getHoursValue(value, defaultValue) {
  if (value) {
    return getHours(value);
  } else if (defaultValue) {
    return getHours(defaultValue);
  }
  return 0;
}

function getMinutesValue(value, defaultValue) {
  if (value) {
    return getMinutes(value);
  } else if (defaultValue) {
    return getMinutes(defaultValue);
  }
  return 0;
}

function TimePickerDropdown(
  value,
  defaultValue,
  handleChange,
  disabledHourOptions,
  disabledMinuteOptions,
  hideDisabledOptions,
  minuteStep,
  hourStep,
  header,
  footer,
) {
  const hourValue = getHoursValue(value, defaultValue);
  const minuteValue = getMinutesValue(value, defaultValue);

  const disabledHourList = disabledHourOptions?.();
  const disabledMinuteList = disabledMinuteOptions?.(hourValue);

  const hourOptions = generateOptions(24, disabledHourList, hideDisabledOptions, hourStep);
  const minuteOptions = generateOptions(60, disabledMinuteList, hideDisabledOptions, minuteStep);

  const hourList = hourOptions.map((option) => formatOption(option, disabledHourList));
  const minuteList = minuteOptions.map((option) => formatOption(option, disabledMinuteList));

  return (
    <div className={styles.dropdown}>
      {!!header && <div className={classNames('caption', styles.dropdownHeader)}>{header}</div>}
      <div className={styles.dropdownBody}>
        <div className={styles.column}>
          {hourList.map((item) => (
            <span
              key={item.label}
              onClick={() => onTimeChange(HOURS, value, item.value, handleChange)}
              className={classNames('cursor-pointer', { [styles.selected]: hourValue === item.value })}
            >
              {item.label}
            </span>
          ))}
        </div>
        <div className={styles.column}>
          {minuteList.map((item) => (
            <span
              key={item.label}
              onClick={() => onTimeChange(MINUTES, value, item.value, handleChange)}
              className={classNames('cursor-pointer', { [styles.selected]: minuteValue === item.value })}
            >
              {item.label}
            </span>
          ))}
        </div>
      </div>
      {!!footer && <div className={classNames('caption', styles.dropdownFooter)}>{footer}</div>}
    </div>
  );
}

type TProps<T> = FieldProps & {
  isError: boolean;
  className: string;
  inputClassName?: string;
  defaultOpenValue: T;
  hideDisabledOptions: boolean;
  timeFormat: string;
  disabledMinutes?: () => Array<number>;
  disabledHours?: () => Array<number>;
  customValueMapper?: ((value: T) => Date) | ((value: Date) => T);
  minuteStep?: number;
  hourStep?: number;
  header?: ReactNode | string;
  footer?: ReactNode | string;
  popoverProps: Record<string, any>;
};

function FormTimePicker<T>(props: TProps<T>) {
  const {
    isError,
    className,
    defaultOpenValue,
    customValueMapper,
    form,
    field,
    disabledHours,
    disabledMinutes,
    hideDisabledOptions,
    minuteStep,
    hourStep,
    timeFormat,
    header,
    footer,
    inputClassName,
    popoverProps,
    ...restProps
  } = props;
  const inputProps: InputHTMLAttributes<HTMLInputElement> = { ...restProps, ...field };
  const [fieldValue, setFieldValue] = useFieldValue(field.name);
  const [isOpened, setIsOpened] = useState(false);

  const valueMapper: any = useMemo(() => customValueMapper || defaultValueMapper, [customValueMapper]);

  const handleChange = useCallback(
    (newValue): void => setFieldValue(valueMapper(newValue)),
    [setFieldValue, valueMapper],
  );

  const value = fieldValue ? valueMapper(fieldValue) : undefined;
  const defaultValue = defaultOpenValue ? valueMapper(defaultOpenValue) : undefined;
  const formattedValue = value ? format(value, timeFormat) : '';

  const handleClick = useCallback(() => setIsOpened((isOpened) => !isOpened), []);

  const PopoverContent = useMemo(
    () =>
      TimePickerDropdown(
        value,
        defaultValue,
        handleChange,
        disabledHours,
        disabledMinutes,
        hideDisabledOptions,
        minuteStep,
        hourStep,
        header,
        footer,
      ),
    [
      value,
      defaultValue,
      handleChange,
      disabledHours,
      disabledMinutes,
      hideDisabledOptions,
      hourStep,
      minuteStep,
      header,
      footer,
    ],
  );
  return (
    <Popover
      trigger="click"
      placement="bottom-start"
      content={inputProps.disabled ? null : PopoverContent}
      hideArrow={true}
      className={styles.popoverClassName}
      {...popoverProps}
    >
      <span onClick={handleClick}>
        <input
          type="text"
          placeholder="Временной слот"
          className={classNames(styles.input, inputClassName, { [styles.inputError]: isError })}
          readOnly
          {...inputProps}
          value={formattedValue}
        />
        <IconChevron
          className={classNames(styles.chevron, { [styles.chevronUp]: isOpened && !inputProps.disabled })}
          width="20"
          height="20"
        />
      </span>
    </Popover>
  );
}

export default InputHOC(FormTimePicker);
