import * as formKeys from '../../../store/order/routes/formKeys';

export const getMappedWorkTypes = [
  { value: formKeys.LOADING, label: 'Загрузка' },
  { value: formKeys.UNLOADING, label: 'Выгрузка' },
  // {
  //   value: formKeys.PAPERWORK,
  //   label: 'Оформление документов',
  // },
];
