import _clone from 'lodash/clone';

export function replaceNullToEmptyString<T>(obj: T): T {
  const copiedObj = _clone(obj);

  Object.keys(obj).forEach((key) => {
    if (copiedObj[key] === null) {
      copiedObj[key] = '';
    }
  });
  return copiedObj;
}

export function replaceEmptyStringToNull<T>(obj: T): T {
  const copiedObj = _clone(obj);

  Object.keys(obj).forEach((key) => {
    if (copiedObj[key] === '') {
      copiedObj[key] = null;
    }
  });
  return copiedObj;
}
