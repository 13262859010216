// eslint-disable-next-line no-prototype-builtins
if (!Promise.hasOwnProperty('allSettled')) {
  Promise.allSettled = (promises) =>
    Promise.all(
      promises.map((promise) =>
        promise
          .then((value) => ({
            status: 'fulfilled',
            value,
          }))
          .catch((reason) => ({
            status: 'rejected',
            reason,
          })),
      ),
    );
}
