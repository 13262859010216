import React from 'react';
import SVGIcon from './index';

const IconLink = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M6 5H9V7H6C4.34315 7 3 8.34315 3 10C3 11.6569 4.34315 13 6 13H9V15H6C3.23858 15 1 12.7614 1 10C1 7.23858 3.23858 5 6 5Z" />
      <path d="M14 13H11V15H14C16.7614 15 19 12.7614 19 10C19 7.23858 16.7614 5 14 5H11V7H14C15.6569 7 17 8.34315 17 10C17 11.6569 15.6569 13 14 13Z" />
      <path d="M13 9H7V11H13V9Z" />
    </SVGIcon>
  );
};

IconLink.defaultProps = {
  color: 'base',
};

export default React.memo(IconLink);
