import React from 'react';
import SVGIcon from './index';

const IconTimer = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M12.5 1V2.5L7.5 2.5V1H12.5ZM16.1624 5.89826C17.3101 7.28301 18 9.06094 18 11C18 15.4183 14.4183 19 10 19C5.58172 19 2 15.4183 2 11C2 6.58172 5.58172 3 10 3C11.9391 3 13.717 3.68988 15.1018 4.83759L16.4697 3.46967L17.5304 4.53033L16.1624 5.89826ZM10.75 12V7H9.25V12H10.75ZM16.5 11C16.5 14.5899 13.5899 17.5 10 17.5C6.41015 17.5 3.5 14.5899 3.5 11C3.5 7.41015 6.41015 4.5 10 4.5C13.5899 4.5 16.5 7.41015 16.5 11Z"
      />
    </SVGIcon>
  );
};

IconTimer.defaultProps = {
  color: 'base',
};

export default React.memo(IconTimer);
