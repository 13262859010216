import { createSelector, defaultMemoize } from 'reselect';
import _get from 'lodash/get';
import queryString from 'query-string';
import { getIsAuthenticated } from '../user/selectors';
import { AUTH_URL } from '../../constants';

export const getLoading = createSelector(
  (state) => _get(state, 'page.loading', false),
  (loading) => loading,
);

export const getTitle = createSelector(
  (state) => _get(state, 'page.title', ''),
  (title) => title,
);

export const getCurrentPagePath = defaultMemoize((state) => _get(state, 'router.location.pathname', ''));

export const getIsPromoPage = createSelector(
  (state) => _get(state, 'router.location.pathname'),
  (state) => getIsAuthenticated(state),
  (path, isAuthenticated) => {
    return (path === '/' && !isAuthenticated) || path === '/integration'; //TODO
  },
);

export const getIsLoginPage = createSelector(
  (state) => _get(state, 'routing.location.pathname'),
  (path) => path === AUTH_URL,
);

/**
 * Получение "сырого" значения строки поиска url
 */
export const getRawQuery = defaultMemoize((state) => _get(state, 'router.location.search'));

/**
 * Маппинг строки поиска из url
 */
export const getQuery = createSelector(
  (state) => getRawQuery(state),
  (query) => queryString.parse(query, { arrayFormat: 'bracket' }),
);
