import moment from 'moment';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import { createSelector, defaultMemoize } from 'reselect';

import getFormattedPrice from '../../helpers/getFormattedPrice';
import { ACTIVE, STANDALONE, BASE, BLOCKED, PENDING, PERIODS, UNLIMITED } from './constants';

/**
 * Маппинг поле с бэка для плана
 */
export const getMappedPlan = createSelector(
  (state) => state,
  (plan) => {
    const { limits } = plan;
    const services = [];
    const servicesObj = limits.reduce((acc, { unit, unit_name, max, description }) => {
      const count = max === UNLIMITED ? UNLIMITED : max;
      services.push({ unit, prettyName: unit_name, count, description });
      acc[unit] = count;
      return acc;
    }, {});
    const name = plan?.name ?? '';
    const isTrial = name.startsWith('COPS'); // FTLNTK-11052 для триального пакета
    const price = plan?.price?.value ?? 0;
    const oldPrice = isTrial ? 500 : price; // 500 для триального пакета
    return {
      name,
      state: _get(plan, 'state'),
      prettyName: _get(plan, 'pretty_name'),
      price,
      oldPrice,
      period: PERIODS[_get(plan, 'billing_period')],
      isTrial,
      priority: _get(plan, 'priority'),
      onceActivated: _get(plan, 'once_activated'),
      services,
      servicesObj,
    };
  },
);

/**
 * Маппинг полей с бэка для тарифов
 */
export const getMappedTariffs = createSelector(
  (state) => _find(state, (item) => item.type === BASE),
  ({ plans = [] } = {}) => {
    return plans.map(getMappedPlan).sort((a, b) => a.oldPrice - b.oldPrice);
  },
);

/**
 * Маппинг поле бэка для плана в аддонах
 */
export const getMappedAddonPlan = createSelector(
  (state) => state,
  (plan) => {
    const count = _get(plan, 'limits[0].max', 0);
    const price = _get(plan, 'price.value', 0);
    return {
      name: _get(plan, 'name'),
      prettyName: _get(plan, 'pretty_name'),
      formattedString: `за ${getFormattedPrice(price)} ₽`,
      count,
      price,
    };
  },
);

/**
 * Маппинг поле бэка для аддонов
 */
export const getMappedAddons = createSelector(
  (state) => state?.filter((item) => item.type === STANDALONE),
  (addons) => {
    return addons.map(({ pretty_name, plans }) => ({
      name: pretty_name,
      description: _get(plans, '[0].description'),
      unit: _get(plans, '[0].limits[0].unit'),
      options: _sortBy(plans, (plan) => _get(plan, 'limits[0].max', 0)).map(getMappedAddonPlan),
    }));
  },
);

/**
 * Маппинг полей с бэка для подписки
 */
export const getMappedSubscription = createSelector(
  (state) => state,
  (subscription) => {
    const { limits, usage } = subscription;

    const services = [];

    // Получение остатков услуг по подписке
    const balances = {};
    limits.forEach((service) => {
      balances[service.unit] = service.max === UNLIMITED ? UNLIMITED : (balances[service.unit] || 0) + service.max;
      services.push({ unit: service.unit, prettyName: service['unit_name'] });
    });

    usage.forEach((service) => {
      if (balances[service.unit] === UNLIMITED) {
        // оставить безлимит
      } else if (service.amount < balances[service.unit]) {
        balances[service.unit] -= service.amount;
        return;
      } else {
        balances[service.unit] = 0;
      }
    });

    // Формируем список услуг с остаточными балансами
    services.map((service) => ({
      ...service,
      balance: balances[service.unit],
    }));

    const bStartDate = _get(subscription, 'billing_start_date');
    const bEndDate = _get(subscription, 'billing_end_date');
    const chargeDate = _get(subscription, 'charged_through_date');
    const cancelledDate = _get(subscription, 'cancelled_date');

    const planName = subscription.plan_name;
    const isTrial = planName.startsWith('COPS'); // FTLNTK-11052 для триального пакета
    const price = subscription?.price?.value ?? 0;

    return {
      id: subscription.id,
      category: _get(subscription, 'category'),
      productName: _get(subscription, 'product_name'),
      state: subscription.state,
      planName,
      price,
      isTrial,
      billingPeriod: _get(subscription, 'billing_period'),
      billingStartDate: bStartDate ? moment(bStartDate) : null,
      billingEndDate: bEndDate ? moment(bEndDate) : null,
      chargedThroughDate: chargeDate ? moment(chargeDate) : null,
      cancelledDate: cancelledDate ? moment(cancelledDate) : null,
      balances,
      services,
    };
  },
);

/**
 * Проверка на актуальность подписки (для фильтра)
 */
export const isActualSubscription = createSelector(
  (state) => state,
  (subscription) => {
    if (subscription.state === PENDING && !subscription.isTrial) {
      // Если предстоящая подписка отменена
      return !subscription.cancelledDate;
    }
    return true;
  },
);

/**
 * Получение информации по тарифу по planName
 */
export const getTariffInfo = createSelector(
  (state) => state,
  (_, props) => props,
  (tariffs, planName) => {
    return tariffs.find((tariff) => planName === tariff.name);
  },
);

/**
 * Получение списка остатков по услугам
 */
export const getServicesBalance = createSelector(
  (state) => state,
  (subscriptions) => {
    const servicesBalance = {};
    subscriptions.forEach((subscription) => {
      if (subscription.state === ACTIVE) {
        new Map(Object.entries(subscription.balances)).forEach((v, k) => {
          servicesBalance[k] =
            servicesBalance[k] === UNLIMITED || v === UNLIMITED ? UNLIMITED : (servicesBalance[k] || 0) + v;
        });
      }
    });
    return servicesBalance;
  },
);

/**
 * Получение доступного остатка услуги по имени юнита
 */
export const getServiceBalance = createSelector(
  getServicesBalance,
  (_, props) => props,
  (balances, unit) => balances?.[unit] ?? 0,
);

/**
 * Получаем список сервисов с остаточными балансами со всех подписок
 */
export const getServicesList = createSelector(
  (state) => state,
  getServicesBalance,
  (subscriptions, servicesBalances) => {
    const servicesList = new Map();
    subscriptions.forEach((subscription) => {
      if (subscription.state === ACTIVE) {
        subscription.services.forEach((service) => {
          servicesList.set(service.unit, {
            ...service,
            balance: servicesBalances[service.unit],
          });
        });
      }
    });

    const unlimitedServicesList = [...servicesList.values()].filter((service) => {
      return service.balance === UNLIMITED;
    });

    return [...[...servicesList.values()].filter((s) => s.balance > 0), ...unlimitedServicesList];
  },
);

/**
 * Проверка на наличие активной подписки
 */
export const hasActiveSubscription = createSelector(
  (state) => state,
  (subscriptions) => Boolean(subscriptions.find(({ state, category }) => state === ACTIVE && category === BASE)),
);

/**
 * Проверка на наличие активной ТРИАЛЬНОЙ подписки
 */
export const hasActiveTrialSubscription = createSelector(
  (state) => state,
  (subscriptions) =>
    Boolean(subscriptions.find(({ state, category, isTrial }) => state === ACTIVE && category === BASE && isTrial)),
);

/**
 * Проверка была ли использована демо подписка
 * (наличие в списке subscriptions)
 */
export const isTrialSubscriptionUsed = createSelector(
  (state) => state,
  (subscriptions) => Boolean(subscriptions.find(({ category, isTrial }) => category === BASE && isTrial)),
);

/**
 * Проверка на наличие неоплаченной подписки
 */
export const hasBlockedSubscription = createSelector(
  (state) => state,
  (subscriptions) =>
    Boolean(
      subscriptions.find(
        // ({ state, category }) => state === BLOCKED && category === BASE,
        ({ state }) => state === BLOCKED,
      ),
    ),
);

/**
 * Проверка на наличие подписки в ожидании
 */
export const hasPendingSubscription = createSelector(
  (state) => state,
  (subscriptions) => Boolean(subscriptions.find(({ state, category }) => state === PENDING && category === BASE)),
);

/**
 * Получение подписки, которая кончается позже всего
 */
export const getLatestEndActiveSubscription = createSelector(
  (state) => getMappedTariffsWithPriority(state),
  (_, props) => props,
  (tariffsObj, subscriptions) => {
    const filteredSubscriptions = subscriptions.filter(({ state, category }) => state === ACTIVE && category === BASE);
    filteredSubscriptions.sort(
      (a, b) =>
        moment(b.chargedThroughDate) - moment(a.chargedThroughDate) ||
        tariffsObj[b.planName]?.priority - tariffsObj[a.planName]?.priority,
    );
    return filteredSubscriptions?.[0];
  },
);

/**
 * Получение запланированной подписки, которая кончается позже всего
 */
export const getLatestEndPendingSubscription = createSelector(
  (state) => getMappedTariffsWithPriority(state),
  (_, props) => props,
  (tariffsObj, subscriptions) => {
    const filteredSubscriptions = subscriptions.filter(({ state, category }) => state === PENDING && category === BASE);
    filteredSubscriptions.sort(
      (a, b) =>
        moment(a.chargedThroughDate) - moment(b.chargedThroughDate) ||
        tariffsObj[a.planName]?.priority - tariffsObj[b.planName]?.priority,
    );
    return filteredSubscriptions?.[0];
  },
);

/**
 * Получение подписок по тарифам
 */
export const getSubscriptionsForTariffs = createSelector(
  (state) => state,
  (_, props) => props,
  (subscriptions, tariffs) => {
    return tariffs.reduce((acc, tariff) => {
      acc[tariff.name] = getSubscriptionsByPlanName(subscriptions, tariff.name);
      return acc;
    }, {});
  },
);

/**
 * Получение подписки по названию тарифа
 */
export const getSubscriptionsByPlanName = createSelector(
  (state) => state,
  (_, props) => props,
  (subscriptions, planName) => {
    return subscriptions.filter((subscription) => subscription.planName === planName);
  },
);

/**
 * Получение последней подписки по названию тарифа
 */
export const getLatestSubscriptionByPlanName = createSelector(getSubscriptionsByPlanName, (subscriptions) => {
  return _sortBy(subscriptions, 'billingStartDate').reverse()[0];
});

/**
 * Получение дат окончания или начала действия тарифов
 */
export const getTariffsDates = createSelector(
  (state) => state,
  (_, props) => props,
  (tariffs, subscriptions) => {
    return tariffs.reduce((acc, tariff) => {
      const subscription = subscriptions.find(
        ({ state, category, planName }) =>
          [ACTIVE, BLOCKED].includes(state) && category === BASE && planName === tariff.name,
      );
      const pendingSubscription = subscriptions.find(
        ({ state, category, planName }) => [PENDING].includes(state) && category === BASE && planName === tariff.name,
      );
      acc[tariff.name] = {
        from: _get(subscription, 'billingStartDate'),
        to: _get(subscription, 'billingEndDate'),
        cancel: _get(subscription, 'cancelledDate'),
        nextBill: _get(subscription, 'chargedThroughDate'),
        pendingFrom: _get(pendingSubscription, 'billingStartDate'),
      };
      return acc;
    }, {});
  },
);

/**
 * Получаем объект тарифов c дополнительными полями
 */
export const getMappedTariffsWithPriority = createSelector(
  (state) => state,
  (tariffs) => {
    return tariffs.reduce((acc, item) => {
      acc[item.name] = {
        priority: item.priority,
        onceActivated: item.onceActivated,
      };
      return acc;
    }, {});
  },
);

/**
 * Проверка на возможность подключения тарифа с текущего периода
 */
export const canActivatePlanFromCurrentPeriod = createSelector(
  (state) => getMappedTariffsWithPriority(state),
  (state, props) => getLatestEndActiveSubscription(state, props),
  (_, props) => hasActiveTrialSubscription(props),
  (_, __, props) => props,
  (tariffsObj, latestSubscription, hasActiveTrial, priority) => {
    // При активной триальной подписке можно подключать пакеты только со следующего периода
    if (hasActiveTrial) return false;
    if (!latestSubscription) return true;
    return tariffsObj[latestSubscription?.planName]?.priority < priority;
  },
);

/**
 * Проверка на возможность активации Стартового
 */
export const canActivateTrialPackage = createSelector(
  (state) => state,
  (state) => getMappedTariffsWithPriority(state),
  (_, props) => props,
  (tariffs, tariffsObj, subscriptions) => {
    // Если дата старта и отмены совпадают - подписка была в пендинге и после отменена
    return !subscriptions.find(
      (s) =>
        s.category === BASE && tariffsObj[s.planName]?.onceActivated && !s.billingStartDate.isSame(s.cancelledDate),
    );
  },
);

/**
 * Получить дату действия тарифа, если активация с текущего периода
 */
export const getPeriodFromCurrent = defaultMemoize((period) => {
  return {
    from: moment(),
    to: moment().add(period, 'days'),
  };
});

/**
 * Получить дату действия тарифа, если активация со следующего периода
 */
export const getPeriodFromNext = createSelector(
  (state, props) => getLatestEndActiveSubscription(state, props),
  (_, __, props) => props,
  (latestSubscription, period) => {
    return {
      from: moment(latestSubscription.chargedThroughDate).clone(),
      to: moment(latestSubscription.chargedThroughDate).clone().add(period, 'days'),
    };
  },
);
