import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import addYears from 'date-fns/addYears';

import { Field } from '../../../../components/FormikFieldsV3/Field';
import FormStepper from '../../../../components/FormikFieldsV3/FormStepper';
import styles from './styles.module.scss';
import { UNLIMITED } from '../../../billing/constants';
import FormInput from '../../../../components/FormikFieldsV3/FormInput';
import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import { useOrderContext } from '../../index';
import FormDatepickerRange from '../../../../components/FormikFieldsV3/FormDatepickerRangeV2';
import FieldLabel from '../../../../components/FieldsCollection/FieldLabel';
import {
  ROUTE_CONTRACT_BEGINS_AT,
  ROUTE_CONTRACT_ENDS_AT,
  ROUTE_CONTRACT_PERIOD,
  ROUTE_MAX_SHIPPINGS_PER_DAY,
  ROUTE_MAX_SHIPPINGS_PER_WEEK,
  ROUTE_SHIPPINGS_COUNT,
  WITH_QUOTES,
} from '../../blocks/RoutesBlock';
import { DF_DATE_FORMAT } from '../../../../constants';
import FormSwitcher from '../../../../components/FormikFieldsV3/FormSwitcher';
import { IconInfo } from '../../../../components/Icons';
import useQuotes from '../../hooks/useQuotes';
import classnames from 'classnames';

export const WITH_QUOTES_MAP = {
  use: 'true',
  notUse: 'false',
};
export const WITH_QUOTES_OPTIONS = [
  { label: 'Гарантия', key: WITH_QUOTES_MAP.notUse },
  { label: 'Квота', key: WITH_QUOTES_MAP.use },
];

export function ContractByCountInfo() {
  const { balanceCreateContract } = useOrderContext();
  const [contractBeginsAt] = useFieldValue(ROUTE_CONTRACT_BEGINS_AT);
  const { withQuotes } = useQuotes();

  const maxDate = contractBeginsAt ? addYears(new Date(), 10) : addYears(new Date(), 1);
  const maxShippingCount = balanceCreateContract > UNLIMITED ? balanceCreateContract : 999999;

  return (
    <Fragment>
      <section className="flex column mt-5 width240">
        <FieldLabel
          title="Период исполнения перевозок"
          help="Период времени, в течение которого должны состояться все перевозки по контракту"
        />
        <Field
          name={ROUTE_CONTRACT_PERIOD}
          nameBegin={ROUTE_CONTRACT_BEGINS_AT}
          nameEnd={ROUTE_CONTRACT_ENDS_AT}
          component={FormDatepickerRange}
          placeholderText="Дата начала – дата окончания"
          dateFormat={DF_DATE_FORMAT}
          labelDisable
          className={styles.dateRange}
          minDate={new Date()}
          maxDate={maxDate}
        />
      </section>
      <section className="mt-5 nml-6 nmr-6 pl-6 pr-6 pt-5 pb-8 br-5 bg-color-lightest">
        <div className="mb-4">
          <FieldLabel title="Обязательства по исполнению контракта" />
          <FormSwitcher id={WITH_QUOTES} name={WITH_QUOTES} data={WITH_QUOTES_OPTIONS} />
          <span className="caption">
            {!withQuotes
              ? 'Исполнитель по контракту получит весь объём заявок для исполнения'
              : 'Вы задаёте объём перевозок, который будет распределяться по заданной вами квоте между исполнителями'}
          </span>
        </div>

        <div className="flex">
          <Field
            name={ROUTE_SHIPPINGS_COUNT}
            component={FormStepper}
            className={classnames('bg-color-white', styles.stepper)}
            additional=""
            label="Плановый объём перевозок"
            min={2}
            max={maxShippingCount}
            placeholder="0"
            readOnly={false}
            step={1}
            comment={
              balanceCreateContract > UNLIMITED && (
                <>
                  Доступно <span className="color-darker">{balanceCreateContract}</span>.{' '}
                  <Link to="/billing" target="_blank" rel="noOpener noReferrer" className="link">
                    Подключить ещё
                  </Link>
                </>
              )
            }
          />
          {!withQuotes ? (
            <div className="flex items-center color-dark mt-4 ml-4">
              <span>Не более</span>
              <span className="ml-2 mr-2">
                <Field
                  name={ROUTE_MAX_SHIPPINGS_PER_DAY}
                  component={FormInput}
                  className={classnames('bg-color-white', styles.input)}
                  labelDisable
                  placeholder="0"
                />
              </span>
              <span>перевозок в день. Не более</span>
              <span className="ml-2 mr-2">
                <Field
                  name={ROUTE_MAX_SHIPPINGS_PER_WEEK}
                  component={FormInput}
                  className={classnames('bg-color-white', styles.input)}
                  labelDisable
                  placeholder="0"
                />
              </span>
              <span>перевозок в неделю</span>
            </div>
          ) : (
            <div className="flex items-center pt-3 ml-5">
              <IconInfo width={20} height={20} color="primary" />
              <div className="width340 ml-1 fs-12 leading-3 color-base">
                Укажите плановый объём перевозок по квотированию. Закладывайте на подбор исполнителей <b>6 часов</b>
              </div>
            </div>
          )}
        </div>
      </section>
    </Fragment>
  );
}
