import React, { lazy, Suspense } from 'react';
import ContentLoader from '../../../../components/ContentLoader';
import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import { getHashString } from '../../helpers/getHashString';
import { useOrderContext } from '../../index';
import { ORDER_TYPE, SHIPPING_REQUEST } from '../OrderNFormTypeBlock';
import { ROUTE_POINTS } from '../RoutesBlock';
import useQuotes from '../../hooks/useQuotes';

export const EXTRA_ORDERS_COUNT = 'extra_orders_count';
export const EXTRA_ORDERS_LIST = 'extra_orders_list';
export const EXTRA_ORDERS_LIST_POINTS = 'extra_orders_list_points';

const ShippingRequestExtraOrdersBlock = lazy(() => import('./ExtraOrdersBlock'));
// const ShippingRequestExtraOrdersBlockQuotes = lazy(() => import('./ExtraOrdersBlockQuotes'));

function getBlockComponent(hashString) {
  switch (hashString) {
    case getHashString({
      // isOrderBasedOnContract: false,
      withQuotes: false,
      isOrderChange: false,
      // formType: FULL,
      orderType: SHIPPING_REQUEST,
      hasWarehouses: false,
    }):
      return ShippingRequestExtraOrdersBlock;
    // case getHashString({
    //   withQuotes: true,
    //   isOrderChange: false,
    //   formType: FULL,
    //   orderType: SHIPPING_REQUEST,
    //   hasWarehouses: false,
    // }):
    //   return ShippingRequestExtraOrdersBlockQuotes;
    default:
      return null;
  }
}

/**
 * Создание множества заказов из одного
 */
export function ExtraOrdersBlock() {
  const { isOrderChange } = useOrderContext();
  const { withQuotes } = useQuotes();
  const [orderType] = useFieldValue(ORDER_TYPE);
  const [routePoints] = useFieldValue(ROUTE_POINTS);
  const hasWarehouses = routePoints.some((point) => !!point.warehouse_id);
  // const [formType] = useFieldValue(FORM_TYPE);
  // const hashString = getHashString({ isOrderBasedOnContract, orderType, formType, isOrderChange, hasWarehouses });
  const params = { orderType, isOrderChange, hasWarehouses, withQuotes };
  // if (withQuotes) {
  //   params['withQuotes'] = withQuotes;
  //   params['formType'] = formType;
  // }
  const hashString = getHashString(params);
  const Component: any = getBlockComponent(hashString);
  return <Suspense fallback={<ContentLoader withBackground />}>{Component ? <Component /> : null}</Suspense>;
}
