import React, { useCallback } from 'react';

import Input from '../../Input';

import InputHOC from '../inputHOC';
import normalizeNum from '../../../helpers/normalizeNum';
import getFormattedPrice, { getUnformattedPrice } from '../../../helpers/getFormattedPrice';
import { useFormikContextSelector } from '../FormikContext';

function FormPriceInput(props) {
  const { field, form, type, onChangeCallback, ...inputProps } = props;
  const { name, value } = field;
  const setFieldValue = useFormikContextSelector((c) => c.setFieldValue);

  const handleChange = useCallback(
    (e) => {
      const unFormattedPrice = getUnformattedPrice(e.target.value);
      const normalizedPrice = normalizeNum(2, 9)(unFormattedPrice);
      const convertedPrice = type === 'number' && normalizedPrice ? +normalizedPrice : normalizedPrice;
      setFieldValue(name, convertedPrice);
      onChangeCallback?.(convertedPrice);
    },
    [name, type, onChangeCallback, setFieldValue],
  );

  const formattedValue = getFormattedPrice(value);

  return <Input {...field} {...inputProps} type="text" onChange={handleChange} value={formattedValue} />;
}

export default InputHOC(FormPriceInput);
