import React from 'react';
import { getIn } from 'formik';

import Stepper from '../../Stepper';
import { useFieldTouched } from '../hooks';
import { useFormikContextSelector } from '../FormikContext';

function FormStepper(props) {
  const { field, form, comment, ...stepperProps } = props;
  const { name, value: fieldValue } = field;
  const setFieldValue = useFormikContextSelector((c) => c.setFieldValue);
  const [touched, setTouched] = useFieldTouched(name);
  const fieldError = useFormikContextSelector((c) => getIn(c.errors, name)) ?? '';

  const booleanError = typeof fieldError === 'boolean';
  const shouldRenderError = touched && !!fieldError;

  return (
    <Stepper
      {...stepperProps}
      name={name}
      value={fieldValue}
      onChange={(v) => setFieldValue(name, v, true)}
      onBlur={setTouched}
      isError={shouldRenderError}
      booleanError={booleanError}
      comment={fieldError || comment}
    />
  );
}

FormStepper.defaultProps = {
  additional: 'ч',
  step: 1,
  readOnly: true,
};

export default FormStepper;
