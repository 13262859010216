import React from 'react';
import SVGIcon from './index';

const IconEye = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M10 4C5.90909 4 2.41545 6.488 1 10C2.41545 13.512 5.90909 16 10 16C14.0909 16 17.5845 13.512 19 10C17.5845 6.488 14.0909 4 10 4ZM10 14C7.792 14 6 12.208 6 10C6 7.792 7.792 6 10 6C12.208 6 14 7.792 14 10C14 12.208 12.208 14 10 14ZM8 10C8 8.89333 8.89333 8 10 8C11.1067 8 12 8.89333 12 10C12 11.1067 11.1067 12 10 12C8.89333 12 8 11.1067 8 10Z"
        fillRule="evenodd"
        pointerEvents="none"
      />
    </SVGIcon>
  );
};

IconEye.defaultProps = {
  color: 'base',
};

export default React.memo(IconEye);
