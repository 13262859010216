import React from 'react';
import styles from './styles.module.scss';

import { IconAlertCircle, IconInfo, IconCheckCircle } from '../../Icons';

const iconTypes = new Map()
  .set('success', <IconCheckCircle className={styles.icon} width="20" height="20" color="success" />)
  .set('error', <IconAlertCircle className={styles.icon} width="20" height="20" color="danger" />)
  .set('warning', <IconAlertCircle className={styles.icon} width="20" height="20" color="warning" />)
  .set('info', <IconInfo className={styles.icon} width="20" height="20" />);

interface IProps {
  title?: string | number | React.ReactNode | null;
  message?: string | React.ReactNode | {} | null;
  type: 'success' | 'error' | 'info' | 'warning';
}

function NotificationChildren({ title, type, message }: IProps) {
  return (
    <div className="flex items-center mt-2 mb-2" style={{ width: 380 }}>
      {iconTypes.get(type) || ''}
      <div className="ml-2 mr-6">
        {title && message && (
          <>
            <h5 className="subtitle mt-0 mb-0 break-word" style={{ width: 310 }}>
              {title}
            </h5>
            <p className="color-dark mt-1 mb-0 break-word" style={{ width: 310 }}>
              {message}
            </p>
          </>
        )}
        {title && !message && (
          <p className="color-darker mt-0 mb-0 break-word" style={{ width: 310 }}>
            {title}
          </p>
        )}
      </div>
    </div>
  );
}

export default NotificationChildren;
