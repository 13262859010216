import moment from 'moment';
import _omit from 'lodash/omit';
import { defaultMemoize } from 'reselect';

import {
  IS_PERSON_INDIVIDUAL,
  PERSON_FULL_NAME,
  PASSPORT_NUMBER,
  PASSPORT_ISSUER,
  PASSPORT_ISSUED_AT,
  LEGAL_ENTITY_TITLE,
  LEGAL_ENTITY_INN,
  LEGAL_ENTITY_KPP,
  LEGAL_ENTITY_FORM,
  CITY,
  STREET,
  HOUSE,
  OFFICE,
  CONTACTS,
  COUNTER_AGENT,
  WORKING_HOURS,
  NAME,
  EMAIL,
  PHONE,
  EXTENSION_NUMBER,
  IS_WAREHOUSE,
  HAS_SCHEDULE,
  WAREHOUSE_SCHEDULES,
  WAREHOUSE_SCHEDULES_DAY,
  WEEKDAY,
  WAREHOUSE_GATES,
  WAREHOUSE_GATE_SCHEDULES_DAY,
  WAREHOUSE_GATE_NAME,
  IS_WHOLE_DAY,
  WAREHOUSE_GATE_SCHEDULES,
  WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE,
  WAREHOUSE_GATE_SCHEDULES_PICKUP,
  PICKUP_HOURS,
  WAREHOUSE_NAME,
  WAREHOUSE_SCHEDULES_WEEKEND,
  WAREHOUSE_GATE_SCHEDULES_WEEKEND,
  LEGAL_ENTITY_FULL_TITLE,
} from './formFields';
import getFullCompanyName from '../../helpers/getFullCompanyName';
import * as companySelectors from '../company/selectors';
import { DATE_FORMAT } from '../../constants';
import getFormattedPhone from '../../helpers/getFormattedPhone';
import {
  TCounterAgent,
  TGeoLocation,
  TGeoLocationLevel,
  TLocation,
  TWarehouseGate,
  TWarehouseSchedule,
} from './models';
import { getFullNameWithInitials } from '../../helpers/getFullNameWithInitials';
import { replaceEmptyStringToNull, replaceNullToEmptyString } from '../../helpers/replaceEmptyString';
import { getIn } from 'formik';
import { getCityViewObject } from '../../helpers/addressMapping';
import { EXACT_ADDRESS, ROUTE_ADDRESS_COMMENT } from '../../containers/order-v2/blocks/RoutesBlock';

/**
 * Получение данных для бэка по контрагентам (очистка от ненужных полей)
 */
export const getCounterAgentMappedData = defaultMemoize((values) => {
  const preparedValues = values[LEGAL_ENTITY_KPP]
    ? { ...values, [LEGAL_ENTITY_KPP]: values[LEGAL_ENTITY_KPP]?.value ?? values[LEGAL_ENTITY_KPP] }
    : values;
  // Очищаем для компании
  let omittedFields = [PERSON_FULL_NAME, PASSPORT_NUMBER, PASSPORT_ISSUER, PASSPORT_ISSUED_AT];
  if (preparedValues[IS_PERSON_INDIVIDUAL]) {
    // Очищаем для физика
    omittedFields = [LEGAL_ENTITY_TITLE, LEGAL_ENTITY_INN, LEGAL_ENTITY_KPP];
  }
  return replaceEmptyStringToNull(_omit(preparedValues, [...omittedFields, 'human_friendly_id']));
});

/**
 * Маппинг контраагентов для автосаджеста
 */
export const getMappedCounterAgent = defaultMemoize((item: TCounterAgent) => {
  return {
    label: item[IS_PERSON_INDIVIDUAL]
      ? item[PERSON_FULL_NAME]
      : getFullCompanyName(item[LEGAL_ENTITY_TITLE]!, item[LEGAL_ENTITY_FORM]!),
    value: item,
  };
});

/**
 * Получение данных для бэка по адресам
 */
export const getLocationMappedData = defaultMemoize((values, isEditingForm) => {
  const cityValue = companySelectors.getValue(values)(CITY);
  const streetValue = companySelectors.getValue(values)(STREET);
  const houseValue = companySelectors.getValue(values)(HOUSE);
  const houseLabel = companySelectors.getLabel(values)(HOUSE);
  const fias_id = houseValue?.fias_id || streetValue?.fias_id || cityValue?.fias_id;

  const counterAgent = isEditingForm ? { counter_agent_id: values[COUNTER_AGENT].value.id } : {};
  const hasSchedule = getIn(values, HAS_SCHEDULE);
  const isWarehouse = values[IS_WAREHOUSE] === 'true';

  const exactAddress = values?.[EXACT_ADDRESS];
  const point = exactAddress?.address
    ? {
        level: TGeoLocationLevel.Point,
        lat: exactAddress.coords[0],
        lng: exactAddress.coords[1],
      }
    : {};

  return {
    fias_id,
    [HOUSE]: houseLabel,
    [OFFICE]: values[OFFICE],
    [WORKING_HOURS]: values[WORKING_HOURS] || '',
    [CONTACTS]: values[CONTACTS],
    ...counterAgent,
    ...point,
    comment: values[ROUTE_ADDRESS_COMMENT] || null,
    [WAREHOUSE_NAME]: values[WAREHOUSE_NAME],
    [IS_WAREHOUSE]: isWarehouse,
    ...(hasSchedule ? { [WAREHOUSE_SCHEDULES]: getWarehouseSchedule(values) } : {}),
    ...(hasSchedule && isWarehouse ? { [WAREHOUSE_GATES]: getGatesSchedule(values) } : {}),
  };
});

const ISODayList = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const getWarehouseSchedule = defaultMemoize((values): TWarehouseSchedule[] => {
  return values[WAREHOUSE_SCHEDULES].map((schedule) => {
    const isWeekend = !schedule[WEEKDAY];
    if (isWeekend) {
      return {
        [WAREHOUSE_SCHEDULES_DAY]: ISODayList[schedule[WAREHOUSE_SCHEDULES_DAY]],
        [WAREHOUSE_SCHEDULES_WEEKEND]: isWeekend,
        from: null,
        till: null,
      };
    }
    const parsedResult =
      schedule[WORKING_HOURS].match(/^с (([01][0-9]|2[0-3]):[0-5][0-9]) {2}до (([01][0-9]|2[0-3]):[0-5][0-9])$/) ?? [];
    const from = parsedResult[1];
    const till = parsedResult[3];

    return {
      [WAREHOUSE_SCHEDULES_DAY]: ISODayList[schedule[WAREHOUSE_SCHEDULES_DAY]],
      [WAREHOUSE_SCHEDULES_WEEKEND]: isWeekend,
      from,
      till,
    };
  });
});

const getGatesSchedule = defaultMemoize((values): TWarehouseGate[] => {
  return values[WAREHOUSE_GATES].map((gate) => {
    return {
      ...(gate.id ? { id: gate.id } : {}),
      [WAREHOUSE_GATE_NAME]: gate[WAREHOUSE_GATE_NAME],
      [WAREHOUSE_GATE_SCHEDULES]: gate[WAREHOUSE_GATE_SCHEDULES].map((schedule) => {
        const isWeekend = !schedule[WEEKDAY];
        if (isWeekend) {
          return {
            [WAREHOUSE_GATE_SCHEDULES_WEEKEND]: isWeekend,
            [WAREHOUSE_GATE_SCHEDULES_DAY]: ISODayList[schedule[WAREHOUSE_GATE_SCHEDULES_DAY]],
            from: null,
            till: null,
            [WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE]: null,
            [WAREHOUSE_GATE_SCHEDULES_PICKUP]: false,
            pickup_from: null,
            pickup_till: null,
          };
        }

        const parsedResult =
          schedule[WORKING_HOURS].match(/^с (([01][0-9]|2[0-3]):[0-5][0-9]) {2}до (([01][0-9]|2[0-3]):[0-5][0-9])$/) ??
          [];
        const from = parsedResult[1];
        const till = parsedResult[3];

        const isPickup = schedule[WAREHOUSE_GATE_SCHEDULES_PICKUP];

        const pickupParsedResult =
          schedule[PICKUP_HOURS].match(/^с (([01][0-9]|2[0-3]):[0-5][0-9]) {2}до (([01][0-9]|2[0-3]):[0-5][0-9])$/) ??
          [];
        const pickupFrom = pickupParsedResult[1];
        const pickupTill = pickupParsedResult[3];

        return {
          [WAREHOUSE_GATE_SCHEDULES_WEEKEND]: isWeekend,
          [WAREHOUSE_GATE_SCHEDULES_DAY]: ISODayList[schedule[WAREHOUSE_GATE_SCHEDULES_DAY]],
          from,
          till,
          [WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE]: +schedule[WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE],
          [WAREHOUSE_GATE_SCHEDULES_PICKUP]: isPickup,
          ...(isPickup ? { pickup_from: pickupFrom, pickup_till: pickupTill } : {}),
        };
      }),
    };
  });
});

/**
 * Обработка объекта бэка по адресу для заполнения полей формы
 */
export const getParsedLocationObject = defaultMemoize((location: TGeoLocation) => {
  const mappedLocation = { ...location, timezone: location.timezone_offset };
  const region = mappedLocation?.region_with_type;

  const cityWithType = [
    ...new Set([
      mappedLocation?.locality,
      mappedLocation?.locality_with_type,
      mappedLocation?.settlement_with_type,
      mappedLocation?.city_with_type,
      mappedLocation?.area_with_type,
      mappedLocation?.region_with_type,
      mappedLocation?.country,
    ]),
  ]
    .filter(Boolean)
    .join(', ');
  const cityFiasId = mappedLocation?.settlement_fias_id || mappedLocation?.city_fias_id;
  const cityKladrId = mappedLocation?.settlement_kladr_id || mappedLocation?.city_kladr_id;
  const city = cityWithType
    ? {
        label: cityWithType,
        value: {
          ...mappedLocation,
          level: TGeoLocationLevel.City,
          fias_id: cityFiasId,
          kladr_id: cityKladrId,
        },
      }
    : null;
  const street = mappedLocation?.street_with_type
    ? {
        label: mappedLocation?.street_with_type,
        value: {
          ...mappedLocation,
          level: TGeoLocationLevel.Street,
          fias_id: mappedLocation.street_fias_id,
          kladr_id: mappedLocation.street_kladr_id,
        },
      }
    : null;
  const house = mappedLocation?.house
    ? {
        label: mappedLocation?.house,
        value: {
          ...mappedLocation,
          fias_id: mappedLocation.house_fias_id,
          kladr_id: mappedLocation.house_kladr_id,
        },
      }
    : null;
  const office = mappedLocation?.office || '';
  return {
    region,
    [CITY]: city,
    [STREET]: street,
    [HOUSE]: house,
    [OFFICE]: office,
    [ROUTE_ADDRESS_COMMENT]: mappedLocation?.comment,
    [EXACT_ADDRESS]:
      mappedLocation?.level === TGeoLocationLevel.Point && mappedLocation.lat && mappedLocation.lng
        ? {
            coords: [mappedLocation.lat, mappedLocation.lng],
          }
        : null,
  };
});

const defaultSchedule = {
  [WAREHOUSE_SCHEDULES]: Array.from(Array(7)).map((_, dayIndex) => ({
    [WAREHOUSE_SCHEDULES_DAY]: dayIndex,
    [WEEKDAY]: true,
    [WORKING_HOURS]: '',
  })),
  [WAREHOUSE_GATES]: [
    {
      [WAREHOUSE_GATE_NAME]: '',
      [IS_WHOLE_DAY]: false,
      [WAREHOUSE_GATE_SCHEDULES]: Array.from(Array(7)).map((_, dayIndex) => ({
        [WEEKDAY]: true,
        [WORKING_HOURS]: '',
        [WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE]: '',
        [WAREHOUSE_GATE_SCHEDULES_PICKUP]: false,
        [PICKUP_HOURS]: '',
        [WAREHOUSE_GATE_SCHEDULES_DAY]: dayIndex,
      })),
    },
  ],
};

/**
 * Получение мапленного объекта бека для формы
 */
export const getLocationFormInitialValues = defaultMemoize((item?: TLocation, isWarehouses?: boolean) => {
  if (!item) {
    return {
      [WAREHOUSE_NAME]: '',
      [COUNTER_AGENT]: null,
      [WORKING_HOURS]: '',
      [CITY]: null,
      [STREET]: null,
      [HOUSE]: null,
      [OFFICE]: '',
      [EXACT_ADDRESS]: null,
      [IS_WAREHOUSE]: String(isWarehouses),
      [HAS_SCHEDULE]: false,
      [CONTACTS]: [],
      ...defaultSchedule,
    };
  }
  // TODO заменить адреса на общие хелперы после переезда на новые адреса
  const location = getParsedLocationObject(item.geo_location);
  const hasSchedule = item?.warehouse_schedules?.some((schedule) => !schedule.weekend);
  return {
    [WAREHOUSE_NAME]: item?.[WAREHOUSE_NAME],
    [COUNTER_AGENT]: getMappedCounterAgent(item.counter_agent),
    [WORKING_HOURS]: item?.[WORKING_HOURS] || '',
    ...location,
    [CONTACTS]: item?.contacts?.map(replaceNullToEmptyString) || [],

    [IS_WAREHOUSE]: item?.[IS_WAREHOUSE] ? String(item?.[IS_WAREHOUSE]) : 'false',
    [HAS_SCHEDULE]: hasSchedule,

    ...(hasSchedule ? getMappedSchedule(item) : defaultSchedule),
  };
});

const getMappedSchedule = defaultMemoize((item) => {
  const isWarehouse = item?.[IS_WAREHOUSE];
  return {
    [WAREHOUSE_SCHEDULES]: item[WAREHOUSE_SCHEDULES].map((schedule, dayIndex) => {
      const isWeekend = schedule[WAREHOUSE_SCHEDULES_WEEKEND];
      return {
        [WAREHOUSE_SCHEDULES_DAY]: dayIndex,
        [WEEKDAY]: !isWeekend,
        [WORKING_HOURS]: isWeekend ? '' : `с ${schedule.from}  до ${schedule.till}`,
      };
    }),
    [WAREHOUSE_GATES]: isWarehouse
      ? item[WAREHOUSE_GATES].map((gate) => {
          return {
            id: gate.id,
            [WAREHOUSE_GATE_NAME]: gate[WAREHOUSE_GATE_NAME],
            [IS_WHOLE_DAY]: false,
            [WAREHOUSE_GATE_SCHEDULES]: gate[WAREHOUSE_GATE_SCHEDULES].map((schedule, dayIndex) => {
              const isWeekend = schedule[WAREHOUSE_GATE_SCHEDULES_WEEKEND];
              const isPickup = schedule[WAREHOUSE_GATE_SCHEDULES_PICKUP];
              return {
                [WAREHOUSE_GATE_SCHEDULES_DAY]: dayIndex,
                [WEEKDAY]: !isWeekend,
                [WORKING_HOURS]: isWeekend ? '' : `с ${schedule.from}  до ${schedule.till}`,
                [WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE]: schedule[WAREHOUSE_GATE_SCHEDULES_SLOT_SIZE],
                [WAREHOUSE_GATE_SCHEDULES_PICKUP]: isPickup,
                [PICKUP_HOURS]: isPickup ? `с ${schedule.pickup_from}  до ${schedule.pickup_till}` : '',
              };
            }),
          };
        })
      : defaultSchedule[WAREHOUSE_GATES],
  };
});

/**
 * Получение мапленного объекта бека для формы контрагента
 */
export const getCounterAgentFormInitialValues = defaultMemoize((item: TCounterAgent | null) => {
  if (!item) {
    return {
      [IS_PERSON_INDIVIDUAL]: false,
      [LEGAL_ENTITY_FULL_TITLE]: '',
      [LEGAL_ENTITY_TITLE]: '',
      [LEGAL_ENTITY_INN]: '',
      [LEGAL_ENTITY_KPP]: '',
      [LEGAL_ENTITY_FORM]: '',
    };
  }
  return replaceNullToEmptyString<
    Omit<TCounterAgent, 'legal_entity_kpp'> & {
      [LEGAL_ENTITY_FULL_TITLE]: string;
      [LEGAL_ENTITY_KPP]: { value: string | null; label: string | null };
    }
  >({
    ...item,
    [LEGAL_ENTITY_KPP]: { value: item[LEGAL_ENTITY_KPP], label: item[LEGAL_ENTITY_KPP] },
    [LEGAL_ENTITY_FULL_TITLE]: !item[IS_PERSON_INDIVIDUAL]
      ? getFullCompanyName(item[LEGAL_ENTITY_TITLE]!, item[LEGAL_ENTITY_FORM]!)
      : '',
    [PASSPORT_ISSUED_AT]: item[PASSPORT_ISSUED_AT] ? moment(item[PASSPORT_ISSUED_AT]).format(DATE_FORMAT) : null,
  });
});

/**
 * Маппинг полей контрагента для реестра
 */
export const getMappedCounterAgentForList = defaultMemoize((item: TCounterAgent) => {
  return {
    ...item,
    title: item[IS_PERSON_INDIVIDUAL]
      ? `Контрагент № ${item.human_friendly_id}`
      : `Компания № ${item.human_friendly_id}`,
    description: item[IS_PERSON_INDIVIDUAL]
      ? item[PERSON_FULL_NAME]
      : getFullCompanyName(item[LEGAL_ENTITY_TITLE]!, item[LEGAL_ENTITY_FORM]!),
    number: item[IS_PERSON_INDIVIDUAL] ? item[PASSPORT_NUMBER] : item[LEGAL_ENTITY_INN],
    second_number: item[IS_PERSON_INDIVIDUAL] ? '' : item[LEGAL_ENTITY_KPP],
    formatted_passport_issued_at: item[PASSPORT_ISSUED_AT]
      ? moment(item[PASSPORT_ISSUED_AT]).format(DATE_FORMAT)
      : null,
  };
});

/**
 * Маппинг полей адреса для реестра
 */
export const getMappedLocationForList = defaultMemoize((item: TLocation): TLocation => {
  const location = getParsedLocationObject(item.geo_location);
  const city = getCityViewObject(item.geo_location as any, true, 5)?.label;

  return {
    ...item,
    counter_agent: {
      ...item.counter_agent,
      formatted_passport_issued_at: item[COUNTER_AGENT][PASSPORT_ISSUED_AT]
        ? moment(item[COUNTER_AGENT][PASSPORT_ISSUED_AT]).format(DATE_FORMAT)
        : null,
    },
    title: item[IS_WAREHOUSE] ? `Склад № ${item.human_friendly_id}` : `Место № ${item.human_friendly_id}`,
    counter_agent_string: item[COUNTER_AGENT][IS_PERSON_INDIVIDUAL]
      ? item[COUNTER_AGENT][PERSON_FULL_NAME]
      : getFullCompanyName(item[COUNTER_AGENT][LEGAL_ENTITY_TITLE]!, item[COUNTER_AGENT][LEGAL_ENTITY_FORM]!),
    location_string: [
      city,
      location?.[STREET]?.label,
      location?.[HOUSE]?.label,
      location?.office ? `кв/оф ${location.office}` : null,
    ]
      .filter(Boolean)
      .join(', '),
    contacts: item[CONTACTS],
    contacts_list: item[CONTACTS].map((contact) =>
      [
        getFullNameWithInitials(contact[NAME]),
        contact[EMAIL],
        getFormattedPhone(contact[PHONE]),
        contact[EXTENSION_NUMBER] ? `доб. ${contact[EXTENSION_NUMBER]}` : null,
      ]
        .filter(Boolean)
        .join(', '),
    ),
  };
});
