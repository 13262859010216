import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment/moment';
import classNames from 'classnames';

import Button from '../../../components/Button';
import { toast } from '../../../components/Toaster';
import NotificationBar from '../../../components/NotificationBar';
import { IconArrowCircle } from '../../../components/Icons';
import IconLoading from '../../../components/Icons/CSSIcons/IconLoading/IconLoading';

import {
  COMPANY_MULTIPOLICY_STATUSES,
  INSURANCE_SUM,
  POLICY_EMAIL,
  SHIPPING_VALIDATION_STATUSES,
  VALIDATIONS_MAP,
  VALIDATIONS_STATUSES,
} from '../constants';
import { SHORT_DATE_FORMAT } from '../../../constants';
import { useUserContext } from '../../../contexts/userContext';

import {
  getMultipolicyAccessStatus,
  getMultipolicyDraft,
  getMultipolicyFiles,
  getMultipolicyValidations,
  getMultipolicyValidationsWithPrice,
  orderMultipolicy,
  rejectMultipolicy,
} from '../api/multipolicyApi';
import { useShippingResourcesInfo } from '../../../hooks/useGetOrderAssignedResources';
import { isDLT, parseMultipolicyFiles } from '../helpers';
import INSURANCE_DOCS from '../components/TermsDocsLinks';

const COMPANY_TYPES = {
  CARGO_OWNER: 'SENDER',
  SHIPPER: 'CARRIER',
};

/**
 * Переменные среды мультиполиса
 * @return {{enabled: boolean}}
 */
export function useMultipolicyEnvParams() {
  return {
    enabled: process.env.REACT_APP_ENABLE_MULTIPOLICY_INSURANCE === 'enabled',
  };
}

/**
 * Данные из перевозки, используемые в страховании
 * @param originalShipping
 * @return {{insurancePrice: number, insuranceRequired: boolean, hasOwnInsurance: boolean}}
 */
export function useInsuranceInfo(originalShipping) {
  const isInsuranceRequired = originalShipping?.compulsory_insurance === true;
  const insurancePrice = originalShipping?.[INSURANCE_SUM] ? +originalShipping?.[INSURANCE_SUM] : null;
  const hasOwnInsurance = originalShipping?.external_insurance === true;

  return {
    insuranceRequired: isInsuranceRequired,
    insurancePrice: insurancePrice,
    hasOwnInsurance: hasOwnInsurance,
  };
}

/**
 * Запросы, отправляемые после авторизации
 * @param userId
 * @return {{inBlackList: boolean}}
 */
export function useMultipolicyInitialRequests(userId) {
  const { enabled } = useMultipolicyEnvParams();

  // проверка на запрет заказа мультиполиса
  const { data } = useQuery([`company-multipolicy-status`], () => getMultipolicyAccessStatus(), {
    enabled: enabled && !!userId,
    refetchOnWindowFocus: false,
    retry: false,
    retryOnMount: false,
  });

  return {
    inBlackList: data !== COMPANY_MULTIPOLICY_STATUSES.PERMITTED,
  };
}

/**
 * Валидация перевозки на возможность застраховать
 * @param originalShipping
 * @param newDepartureAt
 * @param withPrice
 * @return {{blacklisted: boolean, validationResult: unknown, insurancePrice: *, isRefetching: boolean, available: boolean, notValid: boolean, disabled: boolean, validationPassed: boolean, failed: boolean, hasWarning: boolean, refetch: () => Promise, isValidating: boolean}}
 */
export function useMultipolicyValidations(originalShipping, newDepartureAt, withPrice = false) {
  const { enabled } = useMultipolicyEnvParams();

  const { isMultipolicyAvailable, isCargoOwningUser } = useUserContext();
  const params = originalShipping && {
    shippingId: originalShipping?.id,
    companyType: isCargoOwningUser ? COMPANY_TYPES.CARGO_OWNER : COMPANY_TYPES.SHIPPER,
    firstPointSelectedCarSupply: newDepartureAt,
  };

  const request = withPrice ? getMultipolicyValidationsWithPrice : getMultipolicyValidations;

  const {
    isLoading: isValidating,
    data,
    error,
    refetch,
    isRefetching,
  } = useQuery(
    `insurance-validate-shipping-${originalShipping?.id}-${newDepartureAt}`,
    () =>
      request(params).catch((error) => {
        toast.error('Ошибка', 'Произошла ошибка валидации заявки');
        throw error;
      }),
    {
      enabled: enabled && !!originalShipping && isMultipolicyAvailable,
      cacheTime: 0,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const validations = withPrice ? data?.validations : data;

  const notValid = !!validations
    ? !!validations.find((item) => item.status === SHIPPING_VALIDATION_STATUSES.INVALID)
    : null;

  const hasWarning = !!validations
    ? !!validations.find((item) => item.status === SHIPPING_VALIDATION_STATUSES.WARNING)
    : null;

  const insurancePrice = data?.insurance?.length
    ? data.insurance.reduce((acc, insurance) => acc + insurance.insurancePrice, 0)
    : null;

  return {
    disabled: !enabled,
    blacklisted: !isMultipolicyAvailable,
    available: enabled && isMultipolicyAvailable,
    isValidating: isValidating,
    failed: !!error,
    validationPassed: !isValidating && !error && !notValid,
    validationResult: validations,
    hasWarning,
    notValid,
    insurancePrice,
    refetch,
    isRefetching,
  };
}

/**
 * валидации + цена страховки + нотификации
 * @param originalShipping
 * @param newDepartureAt
 * @param transportCompanyInfo
 * @return {{cargoWarnNotification: (JSX.Element|null), insurancePrice: *, validationFailed: boolean, available: boolean, validationPassed: boolean, refetch: (function(): Promise), notAvailableNotification: (JSX.Element|null), isValidating: boolean, insurancePriceNotification: (JSX.Element|null)}}
 */
export function useMultipolicyNotificationsV2(originalShipping, newDepartureAt = null, transportCompanyInfo = null) {
  const { isCargoOwningUser } = useUserContext();
  const {
    available,
    blacklisted,
    isValidating,
    failed,
    validationPassed,
    hasWarning,
    notValid,
    validationResult,
    insurancePrice,
    refetch,
    isRefetching,
  } = useMultipolicyValidations(originalShipping, newDepartureAt, true);

  // warnings:
  const cargoWarning = (
    <NotificationBar
      theme={'warning'}
      text={
        <span>
          При наступлении страхового случая невозможно будет осуществить выплаты по некоторым типам товаров из выбранной
          вами категории груза.{' '}
          {isCargoOwningUser ? (
            isDLT(transportCompanyInfo?.id) ? (
              <INSURANCE_DOCS.RulesForCoWithDlTrans text="Полисные условия" />
            ) : (
              <INSURANCE_DOCS.RulesForCo text="Полисные условия" />
            )
          ) : (
            <INSURANCE_DOCS.RulesForTc text="Полисные условия" />
          )}
        </span>
      }
    />
  );

  const insurancePriceWarning = (
    <NotificationBar theme={'warning'} text={'Не удалось рассчитать стоимость «Мультиполиса» по заявке.'}>
      <Button type={'button'} theme={'text'} onClick={refetch}>
        {isRefetching ? (
          <IconLoading width={20} height={20} color={'primary'} />
        ) : (
          <IconArrowCircle width={20} height={20} color={'primary'} />
        )}
        <span className={classNames('ml-1', isRefetching ? 'color-base' : 'color-primary')}>
          Повторить расчёт стоимости
        </span>
      </Button>
    </NotificationBar>
  );

  // errors:
  const labelParams = useMemo(
    () => ({
      isCargoOwningUser,
      transportCompanyId: transportCompanyInfo?.id,
    }),
    [isCargoOwningUser, transportCompanyInfo],
  );
  const errorsList = useMemo(
    () =>
      validationResult
        ?.filter((validation) => validation.status === VALIDATIONS_STATUSES.INVALID)
        .map((validation) => {
          return VALIDATIONS_MAP[validation.type]?.getLabel(validation, labelParams) || '';
        }) || [],
    [validationResult, labelParams],
  );
  const readableErrorsList = errorsList.filter(Boolean);
  const errorNotification = (
    <NotificationBar
      theme={'danger'}
      text={
        <span className={'ml-1 d-block'}>
          {!readableErrorsList.length && <span>Нельзя подключить мультиполис</span>}
          {readableErrorsList.length === 1 && <span>Нельзя подключить, потому что {errorsList[0]}</span>}
          {readableErrorsList.length > 1 && (
            <>
              <span className={'d-block'}>Нельзя подключить, потому что сработало нескольких условий:</span>
              <ul className={'pl-5'} style={{ listStyleType: 'disc' }}>
                {readableErrorsList.map((error) => (
                  <li>{error}</li>
                ))}
              </ul>
            </>
          )}
        </span>
      }
    />
  );

  const blackListNotification = blacklisted ? (
    <NotificationBar theme={'danger'} text={'Для данной заявки нельзя подключить «Мультиполис».'} />
  ) : null;

  return {
    isValidating: isValidating,
    validationFailed: failed,
    validationPassed: validationPassed,
    available: available,
    cargoWarnNotification: available && validationPassed && hasWarning ? cargoWarning : null,
    notAvailableNotification:
      !isValidating && (notValid || blacklisted) ? blackListNotification || errorNotification : null,
    insurancePriceNotification: available && validationPassed && !insurancePrice ? insurancePriceWarning : null,
    insurancePrice: insurancePrice,
    refetch: refetch,
  };
}

/**
 * Емайл и период выпускаемого мультиполиса
 * @return {{periodStartDate: string, email: (*|null), periodEndDate: string}}
 */
export function useMultipolicyInfo() {
  const { userInfo } = useUserContext();
  const periodStartDate = moment().format(SHORT_DATE_FORMAT);
  const periodEndDate = moment().add(45, 'days').format(SHORT_DATE_FORMAT);

  return {
    email: userInfo?.email || null,
    periodStartDate,
    periodEndDate,
  };
}

/**
 * Получение примера заявления на страхование
 * @param shippingId
 * @return {{isLoading: boolean, showMultipolicyDraft: () => Promise}}
 */
export function useMultipolicyDraft(shippingId) {
  const [isLoading, setIsLoading] = useState(false);

  const showDraft = useCallback(() => {
    setIsLoading(true);
    return getMultipolicyDraft(shippingId)
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .finally(() => setIsLoading(false));
  }, [shippingId]);

  return {
    isLoading,
    showMultipolicyDraft: showDraft,
  };
}

/**
 * Проверка, нужно ли включать рефрижераторные риски в МП
 * @param originalShipping
 * @param transportCompanyInfo
 * @return {{withRefRisk: (false|*|boolean)}}
 */
export function useMultipolicyRefRisk(originalShipping, transportCompanyInfo) {
  const { withRef } = useShippingResourcesInfo(originalShipping?.id);

  const withRefRisk = originalShipping && transportCompanyInfo ? withRef && isDLT(transportCompanyInfo?.id) : null;

  return {
    withRefRisk: withRefRisk,
  };
}

/**
 * Получение из перевозки времени, от которого проверяется возможность заказать МП
 * @param mappedShipping
 * @return {{startDateTime: *}}
 */
export function useShippingStartDateTime(mappedShipping) {
  const firstPoint = mappedShipping?.selectedSupply.find((point) => point?.routePointId === 1);
  const startDateTime = firstPoint?.rawDatetime || null;

  return {
    startDateTime,
  };
}

/**
 * Заказ мультиполиса
 * @param originalShipping
 * @param newDepartureAt
 * @param resources: { carId: string, trailerId: string }
 * @return {{isLoading: boolean, request: (function({refRisk?: boolean, email?: string}=): *), multipolicyFormChangeHandler: (value: unknown) => void}}
 */
export function useMultipolicyRequest(originalShipping, newDepartureAt = null, resources = null) {
  const [isLoading, setIsLoading] = useState(false);
  const [policyFormValues, setPolicyFormValues] = useState(null);

  const payload = originalShipping && {
    shippingId: originalShipping.id,
    firstPointSelectedCarSupply: newDepartureAt,
    carId: resources?.carId,
    trailerId: resources?.trailerId,
    driversIds: resources?.driversIds,
  };
  const request = (params) => {
    payload['email'] = params?.email || policyFormValues?.[POLICY_EMAIL];
    // payload['hasRefRisk'] = params?.refRisk;

    setIsLoading(true);
    return orderMultipolicy(payload).finally(() => setIsLoading(false));
  };

  return {
    isLoading: isLoading,
    request,
    multipolicyFormChangeHandler: setPolicyFormValues,
  };
}

/**
 * отмена мультиполиса
 * @param originalShipping
 * @return {{isLoading: boolean, request: (function(): *)}}
 */

export function useMultipolicyRejectRequest(originalShipping) {
  const [isLoading, setIsLoading] = useState(false);

  const request = useCallback(() => {
    setIsLoading(true);
    return rejectMultipolicy(originalShipping?.id).finally(() => setIsLoading(false));
  }, [originalShipping]);

  return {
    isLoading,
    request,
  };
}

/**
 * Получение документов по мультиполису (проверка на наличие и активность страховки)
 * @param originalShipping
 * @return {{isLoading: boolean, hasActiveInsurance: boolean, multipolicyDocs: unknown, isRefetching: boolean, refetch: () => Promise, hasInsurance: (boolean), canCancelInsurance: boolean}}
 */
export function useMultipolicyOrdered(originalShipping) {
  const {
    isLoading,
    data: multipolicyDocs,
    isRefetching,
    refetch,
  } = useQuery(`multipolicy-files-${originalShipping?.id}`, () => getMultipolicyFiles(originalShipping?.id), {
    enabled: !!originalShipping,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
    retryOnMount: false,
  });

  const { hasInsurance, isInsuranceActive, timeIsOver } = parseMultipolicyFiles(multipolicyDocs);

  return {
    isLoading,
    hasInsurance: hasInsurance,
    hasActiveInsurance: isInsuranceActive || false,
    canCancelInsurance: !(timeIsOver ?? true),
    multipolicyDocs,
    isRefetching,
    refetch,
  };
}
