// статусы изменений требований
export const PENDING_REVIEW = 'pending_review';
export const ACCEPTED = 'accepted';
export const AUTO_ACCEPTED = 'auto_accepted';
export const REJECTED = 'rejected';
export const CANCELED = 'canceled';

export const INACTIVE = 'inactive';
export const REVOKED = 'revoked';

// Варианты изменения точки
export const APPEND = 'append';
export const REMOVED = 'removed';
export const CHANGE = 'change';

export const POINT_CHANGE_TYPES = new Map()
  .set(APPEND, 'Добавлена точка маршрута')
  .set(REMOVED, 'Удалена точка маршрута')
  .set(CHANGE, 'Точка маршрута')
  .set(null, 'Точка маршрута');
