import React from 'react';
import SVGIcon from './index';

function IconEditPencil(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8554 7.24264L17.2697 5.82843L14.4412 3L13.027 4.41421L15.8554 7.24264ZM15.1451 7.95294L12.3167 5.12451L3.41481 14.0264L3.35303 16.9166L6.24323 16.8549L15.1451 7.95294Z"
      />
    </SVGIcon>
  );
}

IconEditPencil.defaultProps = {
  color: 'base',
};

export default IconEditPencil;
