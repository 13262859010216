import React from 'react';
import SVGIcon from './index';

function IconCompany(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 3H8L7 4V5H2V11H8V9H12V11H18V5H13V4L12 3ZM12 4V5H8V4H12Z" />
      <path d="M8 12V13H12V12H17V17H3V12H8Z" />
    </SVGIcon>
  );
}

IconCompany.defaultProps = { color: 'white' };

export default React.memo(IconCompany);
