import { LEN_OBL_FIAS_ID, MSK_FIAS_ID, MSK_OBL_FIAS_ID, SPB_FIAS_ID } from '../constants';
/**
 * Требования к отображению адресов населенных пунктов
 * https://twiki.bia-tech.ru/pages/viewpage.action?pageId=190290997#id-Правилаотображенияинформации-Адреса
 */

export const LOCATION_FORMAT_UI_PLACEMENT_LIST = 'list';
export const LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP = 'tooltip';
export const LOCATION_FORMAT_UI_PLACEMENT_DETAILS = 'details';
export const LOCATION_FORMAT_UI_PLACEMENT_PFZ = 'pfz';

export const LOCATION_LEVEL_REGION = 'region';
export type ILevelRegion = typeof LOCATION_LEVEL_REGION;
export const LOCATION_LEVEL_AREA = 'area';
export type ILevelArea = typeof LOCATION_LEVEL_AREA;
export const LOCATION_LEVEL_CITY = 'city';
export type ILevelCity = typeof LOCATION_LEVEL_CITY;
export const LOCATION_LEVEL_SETTLEMENT = 'settlement';
export type ILevelSettlement = typeof LOCATION_LEVEL_SETTLEMENT;
export const LOCATION_LEVEL_STREET = 'street';
export type ILevelStreet = typeof LOCATION_LEVEL_STREET;
export const LOCATION_LEVEL_HOUSE = 'house';
export type ILevelHouse = typeof LOCATION_LEVEL_HOUSE;

export type ILevel = ILevelRegion | ILevelArea | ILevelCity | ILevelSettlement | ILevelStreet | ILevelHouse;

export type ILocation = {
  fias_id: string;
  level: ILevel;
  lat: number;
  lng: number;
  zip_code: string;
  timezone_name: string;
  country: string;
  country_iso_code: string;
  federal_district: string;
  region: string;
  region_iso_code: string;
  region_type: string;
  region_with_type: string;
  region_fias_id: string;
  area: string;
  area_type: string;
  area_with_type: string;
  area_fias_id: string;
  city: string;
  city_type: string;
  city_with_type: string;
  city_fias_id: string;
  settlement: string;
  settlement_type: string;
  settlement_with_type: string;
  settlement_fias_id: string;
  street: string;
  street_type: string;
  street_with_type: string;
  street_fias_id: string;
  house: string;
  office: string;
};

function fixAbbreviation(abbreviation: string): string {
  // будем пополнять руками или позже сделаем что-бы с бека тип приходил уже с правильным сокращением
  const exceptions = ['снт', 'пгт', 'гск', 'мкр', 'край'];
  const hasSymbol = /[-|\\/]/gi.test(abbreviation) || exceptions.includes(abbreviation);
  return `${abbreviation}${!hasSymbol ? '.' : ''}`;
}

// Добавляет тип к адресу согласно требования
function getLocationWithType(location: ILocation, level: ILevel): string | null {
  const item: string = location[level];
  const itemType: string = location[`${level}_type`];

  if (!item || !itemType) {
    return null;
  }

  const typeAbbreviation = fixAbbreviation(itemType);

  switch (level) {
    case LOCATION_LEVEL_SETTLEMENT:
    case LOCATION_LEVEL_CITY:
      return `${typeAbbreviation}\u00a0${item}`;
    case LOCATION_LEVEL_REGION:
    case LOCATION_LEVEL_AREA:
      return `${item}\u00a0${typeAbbreviation}`;
    default:
      return item;
  }
}

type ILocationsVariants = {
  list: string | null;
  tooltip: string | null;
  details: string;
  pfz: string | null;
};

export function getLocationWithRadius(str: string, radius?: number): string {
  const radiusFormatted = radius ? `\u00a0+${radius}\u00a0км` : '';

  return `${str}${radiusFormatted}`;
}

export function getLocationVariants(location: ILocation, emptyPlaceholder: string = '—'): ILocationsVariants {
  const defaultData = {
    [LOCATION_FORMAT_UI_PLACEMENT_LIST]: emptyPlaceholder,
    [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: null,
    [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: emptyPlaceholder,
    [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: emptyPlaceholder,
  };

  if (!location) {
    return defaultData;
  }

  const zip = location.zip_code;
  const settlement = getLocationWithType(location, LOCATION_LEVEL_SETTLEMENT);
  const city = getLocationWithType(location, LOCATION_LEVEL_CITY);
  const region = getLocationWithType(location, LOCATION_LEVEL_REGION);
  const area = getLocationWithType(location, LOCATION_LEVEL_AREA);

  // Номера кейсов из требований, подробнее смотри по ссылке в начале файла,
  // знаком "?" вопрос обозначены еще не утвержденные кейсы, но которые тоже как-то нужно обрабатывать
  // №1
  if (region && area && settlement && !city) {
    return {
      [LOCATION_FORMAT_UI_PLACEMENT_LIST]: settlement,
      [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: `${area}, ${region}`,
      [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: `${settlement}, ${area}, ${region}`,
      [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: `${zip}, ${region}, ${area}, ${settlement}`,
    };
  }
  // №2
  if (region && !area && !settlement && city && location.region !== location.city) {
    return {
      [LOCATION_FORMAT_UI_PLACEMENT_LIST]: city,
      [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: region,
      [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: `${city}, ${region}`,
      [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: `${zip}, ${city}, ${region}`,
    };
  }
  // №3 (города федерального значения)
  if (region && !area && !settlement && city && location.region === location.city) {
    return {
      [LOCATION_FORMAT_UI_PLACEMENT_LIST]: city,
      [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: null,
      [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: city,
      [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: `${zip}, ${city}`,
    };
  }
  // №4
  if (region && !area && settlement && city) {
    return {
      [LOCATION_FORMAT_UI_PLACEMENT_LIST]: settlement,
      [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: `${city}, ${region}`,
      [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: `${settlement}, ${city}, ${region}`,
      [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: `${zip}, ${region}, ${city}, ${settlement}`,
    };
  }
  // №5
  if (region && !area && !settlement && !city) {
    return {
      [LOCATION_FORMAT_UI_PLACEMENT_LIST]: region,
      [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: null,
      [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: region,
      [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: null,
    };
  }
  // №6?
  if (region && area && !settlement && city) {
    return {
      [LOCATION_FORMAT_UI_PLACEMENT_LIST]: city,
      [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: `${area}, ${region}`,
      [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: `${city}, ${area}, ${region}`,
      [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: `${zip}, ${region}, ${area}, ${city}`,
    };
  }
  // №7?
  if (region && area && settlement && city) {
    return {
      [LOCATION_FORMAT_UI_PLACEMENT_LIST]: settlement,
      [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: `${city}, ${area}, ${region}`,
      [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: `${settlement}, ${city}, ${area}, ${region}`,
      [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: `${zip}, ${region}, ${area}, ${city}, ${settlement}`,
    };
  }

  return defaultData;
}

export function getLocationVariantsForTariffs(location: ILocation, emptyPlaceholder: string = '—'): ILocationsVariants {
  const saintPetersburgRegionFiasIds = [SPB_FIAS_ID, LEN_OBL_FIAS_ID];
  const moscowRegionFiasIds = [MSK_FIAS_ID, MSK_OBL_FIAS_ID];
  const defaultData = {
    [LOCATION_FORMAT_UI_PLACEMENT_LIST]: emptyPlaceholder,
    [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: null,
    [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: emptyPlaceholder,
    [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: emptyPlaceholder,
  };

  if (!location) {
    return defaultData;
  }

  const fiasId = location.fias_id;
  const zip = location.zip_code;
  const settlement = getLocationWithType(location, LOCATION_LEVEL_SETTLEMENT);
  const city = getLocationWithType(location, LOCATION_LEVEL_CITY);
  const region = getLocationWithType(location, LOCATION_LEVEL_REGION);
  const area = getLocationWithType(location, LOCATION_LEVEL_AREA);

  if (saintPetersburgRegionFiasIds.includes(fiasId)) {
    return {
      [LOCATION_FORMAT_UI_PLACEMENT_LIST]: 'г. Санкт-Петербург и Лен. обл.',
      [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: null,
      [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: 'г. Санкт-Петербург и Лен. обл.',
      [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: null,
    };
  }

  if (moscowRegionFiasIds.includes(fiasId)) {
    return {
      [LOCATION_FORMAT_UI_PLACEMENT_LIST]: 'г. Москва и Московская обл.',
      [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: null,
      [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: 'г. Москва и Московская обл.',
      [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: null,
    };
  }

  // города федерального значения
  if (region && !area && !settlement && city && location.region === location.city) {
    return {
      [LOCATION_FORMAT_UI_PLACEMENT_LIST]: city,
      [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: null,
      [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: city,
      [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: `${zip}, ${city}`,
    };
  }

  // регионы
  if (region && !area && !settlement && !city) {
    return {
      [LOCATION_FORMAT_UI_PLACEMENT_LIST]: region,
      [LOCATION_FORMAT_UI_PLACEMENT_TOOLTIP]: null,
      [LOCATION_FORMAT_UI_PLACEMENT_DETAILS]: region,
      [LOCATION_FORMAT_UI_PLACEMENT_PFZ]: null,
    };
  }
  return defaultData;
}
