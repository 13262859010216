import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import _debounce from 'lodash/debounce';

import Input from '../../Input';
import { Common } from '../OptionLists';
import { IconClear, IconSearch, IconInfo } from '../../Icons';
import IconLoading from '../../Icons/CSSIcons/IconLoading/IconLoading';

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const ENTER_KEY_CODE = 13;

class SimpleAutocomplete extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    renderOption: PropTypes.func,
    value: PropTypes.object,
    minSymbolsForSearch: PropTypes.number,
    withIcon: PropTypes.bool,
    helpSnippet: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    helpSnippetIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

    // input props
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onBlur: PropTypes.func,
    clearOnBlur: PropTypes.bool,
    onClear: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // оборачиваем событие запроса
    this.debouncedLoadSuggestions = _debounce(this.onSuggestionsFetchRequested, 300);
  }

  state = {
    // значение input
    value: this.props.value?.value || '',
    // текущее выбранное предложение
    suggestion: null,
    // список найденных предложений
    suggestions: [],
    // идет запрос за списком
    isLoading: false,
  };

  /**
   *  Меняем state если меняются props
   */
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.suggestion) {
      return {
        value: props.value?.label ?? '',
        suggestion: props.value || null,
      };
    }

    return null;
  }

  /**
   * Переменная для понимания, что идет печать в input
   * нужна для очистки input, если не было выбранно значение из списка
   */
  isTyping = false;

  /**
   * События при изменении input
   */
  onChange = (event, { newValue }) => {
    const { clearOnBlur, onSelect } = this.props;
    const { suggestions } = this.state;
    this.isTyping = true;
    this.setState({
      value: newValue,
    });

    // Если разрешен свободный ввод и список подсказок уже пуст -> отправлять значения в форму
    if (!clearOnBlur && !suggestions.length && !!newValue) {
      this.onSuggestionSelected(event, {
        suggestion: { value: newValue, label: newValue },
      });
      onSelect({ value: newValue, label: newValue });
    }
  };

  /**
   * События при снятии фокуса с input
   */
  onBlur = () => {
    const { value, suggestion } = this.state;
    const { onBlur, clearOnBlur, onSelect } = this.props;

    if (clearOnBlur) {
      if (value && this.isTyping) {
        this.setState({ value: '' });
        if (suggestion && this.isTyping) this.onSuggestionSelected(null, { suggestion: null });
      }

      if (onBlur) onBlur();
    } else {
      // Если разрешен свободный ввод -> отправить значение в форму
      this.onSuggestionSelected(null, { suggestion: { value, label: value } });
      onSelect({ value, label: value });
    }
  };

  /**
   * Выбор первого элемента при нажатии Enter
   * @param event
   */
  onKeyDown = (event) => {
    const { suggestions } = this.state;

    if (event.keyCode === ENTER_KEY_CODE && suggestions.length) {
      this.onSuggestionSelected(event, { suggestion: suggestions[0] });
    }
  };

  /**
   * Событие при очистке выбранного значения
   */
  onClearClick = (event) => {
    const { onClear } = this.props;
    this.setState({ value: '' });
    this.onSuggestionSelected(event, { suggestion: null });
    onClear && onClear();
  };

  /**
   * Событие при поиске
   */
  onSuggestionsFetchRequested = ({ value }) => {
    const { onSearch } = this.props;

    this.setState({ isLoading: true });

    // onSearch должен возвращать Promise
    onSearch(value)
      .then((suggestions) => {
        this.setState({ suggestions });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  /**
   * Событие при очистке списка
   */
  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  /**
   * Событие при выборе
   */
  onSuggestionSelected = (event, { suggestion }) => {
    this.isTyping = false;
    const { onSelect } = this.props;
    this.setState({ suggestion });
    onSelect(suggestion);
  };

  shouldRenderSuggestions = (value) => {
    const { minSymbolsForSearch } = this.props;
    return value.trim().length >= minSymbolsForSearch;
  };

  renderInputIcon = () => {
    const { suggestion, isLoading } = this.state;
    const { disabled, withIcon, isClearable } = this.props;

    if (isClearable && suggestion && !disabled) {
      return (
        <IconClear
          width="20"
          height="20"
          onClick={this.onClearClick}
          className={classNames(styles.icon, styles.clear)}
        />
      );
    }

    if (isLoading) {
      return <IconLoading width={30} height={30} className={classNames(styles.icon, styles.loaderIcon)} />;
    }

    if (withIcon) {
      return <IconSearch width="20" height="20" className={styles.icon} />;
    }

    return null;
  };

  render() {
    const { placeholder, disabled, className, renderOption, helpSnippet, helpSnippetIcon, helpSnippetClassname } =
      this.props;

    const { value, suggestions } = this.state;

    const inputProps = {
      ...this.props,
      placeholder,
      value,
      disabled,
      onChange: this.onChange,
      onBlur: this.onBlur,
      className: classNames(styles.input, className),
      onKeyDown: this.onKeyDown,
    };

    const inputIcon = this.renderInputIcon();

    delete inputProps.onSelect;
    delete inputProps.onSearch;
    delete inputProps.onClear;
    delete inputProps.renderOption;
    delete inputProps.minSymbolsForSearch;
    delete inputProps.withIcon;
    delete inputProps.helpSnippet;
    delete inputProps.helpSnippetIcon;
    delete inputProps.helpSnippetClassname;
    delete inputProps.clearOnBlur;
    delete inputProps.isClearable;

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.debouncedLoadSuggestions}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderOption}
        onSuggestionSelected={this.onSuggestionSelected}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        inputProps={inputProps}
        renderSuggestionsContainer={({ containerProps, children }) => (
          <div {...containerProps}>
            {!!helpSnippet && (
              <div
                className={classNames(`react-autosuggest__suggestions-container--help-snippet`, helpSnippetClassname)}
              >
                {helpSnippetIcon ? (
                  React.createElement(helpSnippetIcon, {
                    width: '20px',
                    height: '20px',
                    color: 'dark',
                    className: 'no-shrink react-autosuggest__suggestions-container--help-snippet-icon',
                  })
                ) : (
                  <IconInfo
                    width="20"
                    height="20"
                    color="dark"
                    className="no-shrink react-autosuggest__suggestions-container--help-snippet-icon"
                  />
                )}
                <span>{helpSnippet}</span>
              </div>
            )}
            {children}
          </div>
        )}
        renderInputComponent={(inputProps) => {
          return (
            <div className={styles.inputWrapper}>
              <Input {...inputProps} ref={inputProps.ref} rightIcon={!!inputIcon} />
              {inputIcon}
            </div>
          );
        }}
      />
    );
  }
}

SimpleAutocomplete.defaultProps = {
  value: null,
  minSymbolsForSearch: 1,
  renderOption: Common,
  withIcon: true,
  clearOnBlur: true,
  isClearable: true,
};

export default SimpleAutocomplete;
