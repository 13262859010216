import * as formKeys from './formKeys';

export const transportRequirements = new Map()
  // Vehicle requirements
  .set('isOwnTransport', 'Только ТС в собственности')
  .set('cmrDocument', 'Документы CMR')
  .set('tirDocument', 'Документы TIR')
  .set('rigidBoard', 'Жёсткий борт')
  .set('removableUpperBeam', 'Съёмная верхняя балка')
  .set('removableSideRacks', 'Съёмные боковые стойки')
  .set('temperatureCheck', 'Температурный чек')
  .set('temperatureCondition', 'Температурный режим: ')
  .set('numberOfBelts', 'Количество ремней: ')
  .set('isDisinfected', 'Необходимость в санитарной обработке')
  .set('timberBunks', 'Коники')

  // Driver requirements
  .set('russianCitizen', 'Гражданство РФ')
  .set('shippingPowerOfAttorneyOriginal', 'Наличие оригинала доверенности')
  .set('driverCoveralls', 'Спец. одежда')
  .set('medicalBook', 'Наличие мед.книжки')
  .set('noCriminalRecords', 'Не судимый');

export const owningTypes = [
  { value: formKeys.OWN, label: 'Собственность' },
  { value: formKeys.RENT, label: 'Аренда' },
  { value: formKeys.JOINT_OWNERSHIP, label: 'Общая собственность супругов' },
  { value: formKeys.LEASING, label: 'Лизинг' },
  { value: formKeys.GRATUITOUS_USE, label: 'Договор ссуды' },
];

export enum RESOURCE_FORM_ACTION {
  EDIT = 'edit',
  CREATE_BASED_ON = 'create_based_on',
}

export enum VEHICLE_REPORT_QUERY_TYPE {
  VIN = 'VIN',
  BODY = 'BODY',
  CHASSIS = 'CHASSIS',
}

export enum VEHICLE_REPORT_STATUS {
  OK = 'OK',
  NOT_READY = 'NOT_READY',
  ERROR = 'ERROR',
}
