import produce from 'immer';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import { push as routerPush } from 'connected-react-router';
import * as types from './actionTypes';
import * as requirementsChangeSelectors from './selectors';
import * as requirementsChangeService from '../../services/requirementsChangeService';
import { toast } from '../../components/Toaster';
import { submitChangeRequestEvent, rejectChangeRequestEvent } from '../googleAnalytics/events';
import errorsMapping from '../../errors';
import { getRawQuery } from '../page/selectors';

/**
 * Получить список изменений требований
 * @param page {number}
 * @returns {function(*, *): Promise<any>}
 */
export function getRequirementsChanges(page = 1) {
  return (dispatch, getState) => {
    const requirementsChangeParams = requirementsChangeSelectors.getRequirementsChangeParams(getState());

    return requirementsChangeService
      .getRequirementsChanges({ ...requirementsChangeParams, page })
      .then((data) => {
        if (page === 1) {
          dispatch({ type: types.INIT_REQUIREMENTS_CHANGE, payload: data });
        } else {
          dispatch({ type: types.UPDATE_REQUIREMENTS_CHANGE, payload: data });
        }

        return data;
      })
      .catch((error) => toast.errorAsync(error));
  };
}

/**
 * Принять Изменение требований
 */
export function acceptRequirementsChange(id) {
  return () => {
    return requirementsChangeService
      .acceptRequirementsChange(id)
      .then(() => {
        toast.success('Согласование изменений принято');
      })
      .then(() => submitChangeRequestEvent(id))
      .catch((error) => {
        const errorObj = errorsMapping.get(error?.response?.data?.error_code);
        if (errorObj) {
          toast.error(errorObj.title, errorObj.message);
        } else {
          toast.errorAsync(error);
        }
      });
  };
}

/**
 * Отклонить Изменение требований
 * @param id {string}
 * @returns {function(*): Promise<any>}
 */
export function cancelRequirementsChange(id) {
  return () => {
    return requirementsChangeService
      .cancelRequirementsChange(id)
      .then(() => {
        toast.success('Согласование изменений отклонено');
      })
      .then(() => rejectChangeRequestEvent(id))
      .catch((error) => {
        const errorObj = errorsMapping.get(error?.response?.data?.error_code);
        if (errorObj) {
          toast.error(errorObj.title, errorObj.message);
        } else {
          toast.errorAsync(error);
        }
      });
  };
}

/**
 * Устанавливаем первый элемент активным
 * @returns {Function}
 */
export function setFirstItem() {
  return (dispatch, getState) => {
    const firstItemId = _get(getState(), 'requirementsChange.items[0].id');

    if (firstItemId) {
      dispatch(setActiveItem(firstItemId));
    }
  };
}

/**
 * Назначаем выбранный элемент активным
 * @param id
 * @returns {Function}
 */
export function setActiveItem(id) {
  return (dispatch) => {
    dispatch({ type: types.ACTIVE_ITEM_ID_SET, payload: id });
  };
}

/**
 * Запрос следующей страницы
 * @returns {Function}
 */
export function getNextPageRequests(page) {
  return (dispatch) => {
    dispatch(getRequirementsChanges(page + 1));
  };
}

// ============ Смена ресурсов в перевозке ============ //
/**
 * Запрос на создание заявки на смену ресурсов
 * @param id
 */
export function createRequestChangeRequirements(id) {
  return (dispatch, getState) => {
    const queryString = getRawQuery(getState());
    const params = requirementsChangeSelectors.getMappedRequirementsChangeForm(getState());
    // const activeItemStatus = getState().suggestionsReserved?.activeItem?.status?.code_name;
    return requirementsChangeService
      .createRequestChangeRequirements(id, params)
      .then(() => {
        toast.success('Заявка на изменение ресурсов отправлена');
        dispatch(
          // routerPush(
          //   `/shippings?active_item=${id}&page=1&per_page=20&scancopy_status[]=all&${STATUS}=${getRelevantTabForStatus(
          //     activeItemStatus,
          //   )}`,
          routerPush(`/shippings${queryString}`),
        );
      })
      .catch((errorData) => {
        toast.errorAsync(errorData);
      });
  };
}

/**
 * Расширение item информацией о заявках на смену требований
 * @param item {Object}
 * @param shippingIdPath
 * @param companyId {string}
 * @param isCargoOwningUser {Boolean}
 * @returns {Promise<T>}
 */
export function extendItemWithRequestToRequirementsChange(item, shippingIdPath = 'id', companyId, isCargoOwningUser) {
  const shippingId = _get(item, shippingIdPath);
  if (!shippingId) return Promise.resolve(item);
  const isHourlyRent = item?.shipping_request_info?.payment_type === 'per_hour';
  const isExpeditedPayment = !!item?.shipping?.expedited_payment_days || !!item?.expedited_payment_days;
  return requirementsChangeService.getRequestsChangeRequirements(shippingId).then((requestList = []) => {
    const sortedRequestList = _sortBy(requestList, ['created_at'])
      .reverse()
      .map((request) =>
        requirementsChangeSelectors.getMappedRequestRequirementsChange(request, isHourlyRent, isExpeditedPayment),
      );
    // Список заявок на замену ресурсов от НТК
    const changeResourcesRequestList = [];
    // Список заявок на изменение заявки от ГВ
    const changeShippingRequestList = [];

    // Общий список
    const changeRequestList = [];

    sortedRequestList.forEach((item) => {
      changeRequestList.push(item);

      if (isCargoOwningUser) {
        if (item.sender === companyId) {
          changeShippingRequestList.push(item);
        } else {
          changeResourcesRequestList.push(item);
        }
      } else {
        if (item.sender === companyId) {
          changeResourcesRequestList.push(item);
        } else {
          changeShippingRequestList.push(item);
        }
      }
    });

    // Получаем последний запрос из списка на замену ресурсов (последняя дата создания)
    const requestToChangeResources = changeResourcesRequestList?.[0];
    const requestToChangeShipping = changeShippingRequestList?.[0];
    return produce(item, (draftState) => {
      if (requestToChangeResources) {
        draftState['requestToChangeResources'] = requestToChangeResources;
      }
      if (requestToChangeShipping) {
        draftState['requestToChangeShipping'] = requestToChangeShipping;
      }
      draftState['shippingChangeRequestList'] = changeShippingRequestList;
      draftState['resourceChangeRequestList'] = changeResourcesRequestList;

      draftState['changeRequestList'] = changeRequestList;
    });
  });
}
