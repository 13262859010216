import React from 'react';
import SVGIcon from './index';

function IconLocalShipping(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M2 4H14V7H16L18 10V14H16C16 15.1046 15.1046 16 14 16C12.8954 16 12 15.1046 12 14H8C8 15.1046 7.10457 16 6 16C4.89543 16 4 15.1046 4 14H2V4ZM6 15C6.55228 15 7 14.5523 7 14C7 13.4477 6.55228 13 6 13C5.44772 13 5 13.4477 5 14C5 14.5523 5.44772 15 6 15ZM15 14C15 14.5523 14.5523 15 14 15C13.4477 15 13 14.5523 13 14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14ZM15.5 8H14V10H17L15.5 8Z"
      />
    </SVGIcon>
  );
}

IconLocalShipping.defaultProps = {
  color: 'base',
};

export default React.memo(IconLocalShipping);
