import React from 'react';
import SVGIcon from './index';

const IconCargoOwner = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 60 30">
      <path d="M34 9V7H26V9H34Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 30V12L30 0L60 12V30H0ZM47 28V13H13V28H2V13.3541L30 2.15407L58 13.3541V28H47ZM31 28H27V22H18V28H15V15H45V28H43V18H31V28ZM25 26V28H20V26H25ZM25 25V24H20V25H25ZM40.293 28L37 24.707L33.707 28H40.293ZM33.7072 20L37 23.2928L40.2928 20H33.7072ZM41 20.707L37.7071 23.9999L41 27.2928V20.707ZM33 27.2928L36.2929 23.9999L33 20.707V27.2928Z"
      />
    </SVGIcon>
  );
};

IconCargoOwner.defaultProps = {
  color: 'base',
};

export default IconCargoOwner;
