import React from 'react';
import SVGIcon from './index';

const IconExcelUpload = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5L10 3V17L2 15V5ZM3.64648 7H5.1276L5.89746 8.7943C5.95996 8.94094 6.00701 9.11099 6.05534 9.3038H6.0765C6.104 9.18795 6.15997 9.01095 6.24414 8.78006L7.10189 7H8.4528L6.83984 9.9731L8.5 13H7.05957L6.13184 11.0443C6.09684 10.9754 6.05533 10.8368 6.02116 10.644H6.00814C5.9873 10.7363 5.94531 10.8746 5.88281 11.0601L4.94694 13H3.5L5.21712 9.99684L3.64648 7Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 10.5556L12.7 12H11V11H13V9H11V8H13V6H11V5H17V12.7778L15.4 11H16V9H14V10.5556ZM16 6H14V8H16V6Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5 12L12 15H14V18H15V15H17L14.5 12Z" />
    </SVGIcon>
  );
};

export default IconExcelUpload;
