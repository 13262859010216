import React from 'react';
import styles from './styles.module.scss';

import IconTimer from '../Icons/SVGIcons/IconTimer';
import TimerWrapper from '../TimerWrapper';

interface TimeLeftProps {
  title: string;
  time?: string;
  withTimerIcon?: boolean;
  minutesToEnd?: number;
  caption?: string | null;
}

function diffToString(diff) {
  if (diff.secondsIsOver) {
    return '00 м 00 с';
  }
  if (diff.minutesIsOver || diff.hoursIsOver) {
    return `${diff.minutes} м ${diff.seconds} с`;
  }
  if (diff.daysIsOver) {
    return `${diff.hours} ч ${diff.minutes} м`;
  }
  return `${diff.days} д ${diff.hours} ч`;
}

function TimeLeft({ title, time, withTimerIcon = true, minutesToEnd = 60, caption }: TimeLeftProps) {
  return (
    <div className={styles.wrapper}>
      {withTimerIcon && <IconTimer width="20" height="20" className="mr-1 no-shrink" />}
      <div className="flex wrap">
        {title && (
          <span className="color-dark mr-1">
            {title}
            {caption ? '' : ':'}
          </span>
        )}
        <div className="flex">
          {caption && <span className="color-dark mr-1">{caption}</span>}
          <TimerWrapper time={time}>
            {(diff) => (
              <span className={diff.totalMinutes < minutesToEnd ? styles.warning : styles.normal}>
                {diffToString(diff)}
              </span>
            )}
          </TimerWrapper>
        </div>
      </div>
    </div>
  );
}

export default TimeLeft;
