import React from 'react';
import SVGIcon from './index';

const IconCheckCircle: React.FunctionComponent<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM16.5 10C16.5 13.5899 13.5899 16.5 10 16.5C6.41015 16.5 3.5 13.5899 3.5 10C3.5 6.41015 6.41015 3.5 10 3.5C13.5899 3.5 16.5 6.41015 16.5 10Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3965 7L8.63043 11.7791L6.60348 9.75447L5.5 10.861L8.63043 14L14.5 8.11435L13.3965 7Z"
      />
    </SVGIcon>
  );
};

IconCheckCircle.defaultProps = {
  color: 'success',
};

export default React.memo(IconCheckCircle);
