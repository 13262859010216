import React from 'react';
import SVGIcon from './index';
import styles from './styles.module.scss';

const IconCheckedRadio = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="7.5" fill="white" className={styles[`stroke-${props.color!}`]} />
      <circle cx="8" cy="8" r="4" />
    </SVGIcon>
  );
};

IconCheckedRadio.defaultProps = {
  color: 'primary',
};

export default IconCheckedRadio;
