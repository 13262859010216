import { TOption } from '../store/models';

export enum EOrderStatuses {
  DRAFT = 'draft', // Заказ заполняется грузовладельцем, виден только ему. Заявки на основании заказа нет

  AUCTION_WAIT = 'auction_waiting', // В ожидании торга. Заказ опубликован грузовладельцем в торг
  IN_AUCTION = 'in_auction', // В торге. Заказ торгуется
  EXPIRED = 'expired', // Неотторгован (нет исполнителя). Заявку по заказу никто не забронировал, время торга истекло

  RESERVED = 'reserved', // Забронирован. Заявка на основании заказа забронирована. Создана перевозка, ресурсы не назначены
  RESOURCES_WAITING = 'resources_waiting', // Ожидание назначения ресурсов на перевозку
  APPROVAL = 'approval_waiting', // Утверждение перевозки (на утверждении). Перевозчик назначил ресурсы на перевозку
  TRIP_WAITING = 'trip_waiting', // Ожидание перевозки (предстоящий). Перевозка утверждена, дата подачи в первую точку маршрута не наступила
  IN_TRIP = 'in_trip', // На исполнении (выполняется). Перевозка утверждена, дата подачи в первую точку маршрута наступила
  WINNER_SELECTION = 'winner_selection', // Выбор победителя. Завершился аукцион по заявке на основании заказа, грузовладелец выбирает победителя
  DONE = 'completed', // Выполнен (завершён). Грузовладелец успешно завершил перевозку
  CANCELED_BY_NTK = 'canceled_by_transport_company', // Отменён по вине перевочика
  CANCELED_BY_CARGO_OWNER = 'canceled_by_cargo_owning_company', // Отменён по вине грузовладельца
  FAILED_BY_NTK = 'failed_by_transport_company', // Срыв по вине перевозчика
  FAILED_BY_CARGO_OWNER = 'failed_by_cargo_owning_company', // Срыв по вине грузовладельца

  /* Неиспользуемые статусы */
  // ACCEPTED = 'accepted', // заказ принят в обработку. Ожидается создание заявки
  // UNPROCESSABLE ='unprocessable', //Грузовладелец опубликовал заказ в торг, но на основании заказа невозможно создать заявку
}

export enum EOrderTypes {
  SHIPPING_REQUEST = 'shipping_request',
  CONTRACT = 'contract',
  DELIVERY = 'delivery',
  CITY_DELIVERY = 'city_delivery',
}

export enum EContractTypes {
  CONTRACT_BY_COUNT = 'by_shippings_count',
  CONTRACT_BY_SCHEDULE = 'by_schedule',
  CONTRACT_WITH_QUOTES = 'by_quota',
}
export enum EOrderDocsProcessStatuses {
  DOC_OWNER_SIGNING = 'cargo_owner_signing', // документ находится на подписании у грузовладельца
  DOC_NOT_ATTACHED = 'not_attached', // тебуется подписание документа со стороны транспортной компании
  DOC_ACCEPTED = 'accepted', // подписанный транспортной компанией документ принят грузовладельцем
  DOC_IN_PROGRESS = 'in_progress', // подписанный транспортной компанией документ находится на проверке у грузовладельца
  NEED_RESIGN = 'need_resign', // требуется переподписание документа со стороны транспортной компании
}

type TOrdersDocsProcessStatusesMap = { [status in EOrderDocsProcessStatuses]: TOption<EOrderDocsProcessStatuses> };

export const ORDER_DOCS_PROCESS_STATUSES: TOrdersDocsProcessStatusesMap = {
  [EOrderDocsProcessStatuses.DOC_OWNER_SIGNING]: {
    value: EOrderDocsProcessStatuses.DOC_OWNER_SIGNING,
    label: 'Док-ты на подписи',
  },
  [EOrderDocsProcessStatuses.DOC_NOT_ATTACHED]: {
    value: EOrderDocsProcessStatuses.DOC_NOT_ATTACHED,
    label: 'Док-ты на подписи',
  },
  [EOrderDocsProcessStatuses.DOC_ACCEPTED]: {
    value: EOrderDocsProcessStatuses.DOC_ACCEPTED,
    label: 'Док-ты оформлены',
  },
  [EOrderDocsProcessStatuses.DOC_IN_PROGRESS]: {
    value: EOrderDocsProcessStatuses.DOC_IN_PROGRESS,
    label: 'Док-ты оформлены',
  },
  [EOrderDocsProcessStatuses.NEED_RESIGN]: {
    value: EOrderDocsProcessStatuses.NEED_RESIGN,
    label: 'Требуют подписи',
  },
};

const COMMON_STATUSES = {
  [EOrderStatuses.AUCTION_WAIT]: {
    value: EOrderStatuses.AUCTION_WAIT,
    label: 'Ожидание торга',
  },
  [EOrderStatuses.IN_AUCTION]: {
    value: EOrderStatuses.IN_AUCTION,
    label: 'В торге',
  },
  [EOrderStatuses.RESOURCES_WAITING]: {
    value: EOrderStatuses.RESOURCES_WAITING,
    label: 'Ожидание ресурсов',
  },
  [EOrderStatuses.APPROVAL]: {
    value: EOrderStatuses.APPROVAL,
    label: 'На утверждении',
  },
  [EOrderStatuses.WINNER_SELECTION]: {
    value: EOrderStatuses.WINNER_SELECTION,
    label: 'Выбор победителя',
  },
  [EOrderStatuses.IN_TRIP]: {
    value: EOrderStatuses.IN_TRIP,
    label: 'На исполнении',
  },
  [EOrderStatuses.FAILED_BY_CARGO_OWNER]: {
    value: EOrderStatuses.FAILED_BY_CARGO_OWNER,
    label: 'Срыв',
  },
  [EOrderStatuses.FAILED_BY_NTK]: {
    value: EOrderStatuses.FAILED_BY_NTK,
    label: 'Срыв исполнителем',
  },
  [EOrderStatuses.EXPIRED]: {
    value: EOrderStatuses.EXPIRED,
    label: 'Нет исполнителя',
  },
  [EOrderStatuses.DRAFT]: {
    value: EOrderStatuses.DRAFT,
    label: 'Черновик',
  },
};

type TOrdersStatusesMap = { [status in EOrderStatuses]: TOption<EOrderStatuses> };

export const ORDER_STATUSES: TOrdersStatusesMap = {
  ...COMMON_STATUSES,
  [EOrderStatuses.RESERVED]: {
    value: EOrderStatuses.RESERVED,
    label: 'Забронирована',
  },
  [EOrderStatuses.TRIP_WAITING]: {
    value: EOrderStatuses.TRIP_WAITING,
    label: 'Предстоящая',
  },
  [EOrderStatuses.DONE]: {
    value: EOrderStatuses.DONE,
    label: 'Выполнена',
  },
  [EOrderStatuses.CANCELED_BY_CARGO_OWNER]: {
    value: EOrderStatuses.CANCELED_BY_CARGO_OWNER,
    label: 'Отменена',
  },
  [EOrderStatuses.CANCELED_BY_NTK]: {
    value: EOrderStatuses.CANCELED_BY_NTK,
    label: 'Отменена исполнителем',
  },
};

export const CONTRACT_STATUSES: TOrdersStatusesMap = {
  ...COMMON_STATUSES,
  [EOrderStatuses.RESERVED]: {
    value: EOrderStatuses.RESERVED,
    label: 'Забронирован',
  },
  [EOrderStatuses.TRIP_WAITING]: {
    value: EOrderStatuses.TRIP_WAITING,
    label: 'Предстоящий',
  },
  [EOrderStatuses.DONE]: {
    value: EOrderStatuses.DONE,
    label: 'Выполнен',
  },
  [EOrderStatuses.CANCELED_BY_CARGO_OWNER]: {
    value: EOrderStatuses.CANCELED_BY_CARGO_OWNER,
    label: 'Отменен',
  },
  [EOrderStatuses.CANCELED_BY_NTK]: {
    value: EOrderStatuses.CANCELED_BY_NTK,
    label: 'Отменен исполнителем',
  },
};
