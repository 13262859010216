import React from 'react';
import SVGIcon from './index';

function IconExit(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9697 5.96973L18 10.0001L13.9697 14.0304L12.909 12.9697L15.1287 10.7501H7V9.25006H15.1287L12.909 7.03039L13.9697 5.96973Z"
        fill="#98A3B3"
      />
      <path d="M10 16L5 16L5 4L10 4L10 6L12 6V2L3 2L3 18H12V14H10L10 16Z" />
    </SVGIcon>
  );
}

IconExit.defaultProps = {
  color: 'base',
};

export default React.memo(IconExit);
