import { push as routerPush } from 'connected-react-router';
import React, { Fragment } from 'react';
import * as types from './actionTypes';
import * as documentService from '../../services/documentService';
import { toast } from '../../components/Toaster';
import { getTransportFiles, getPrintableForm } from '../suggestions/reserved/actions';
import { GRAPHICS } from './constants';
import { getRawQuery } from '../page/selectors';
import errorsMapping from '../../errors';

/**
 * Действие для получения типов
 * и обновления их в store
 * @deprecated
 * @param {String} entity
 * @param {String} entityId
 * @return {Function}
 */
export function getTypes(entity, entityId = '') {
  return (dispatch) => {
    documentService
      .getTypes(entity, entityId)
      .then((items) => {
        dispatch({
          type: types.SET_TYPES,
          payload: items,
          entity,
        });
      })
      .catch((error) => toast.errorAsync(error));
  };
}

/**
 * Действие для получения документов
 * и обновления их в store
 * @deprecated
 * @param {String} entity
 * @param {String} entityId
 * @return {Function}
 */
export function getDocuments(entity, entityId = '') {
  return (dispatch) => {
    documentService
      .getDocuments(entity, entityId)
      .then((items) => {
        dispatch({
          type: types.SET_DOCUMENTS,
          payload: items,
          entity,
        });
      })
      .catch((error) => toast.errorAsync(error));
  };
}

/**
 * Обнулить данные о типах, документах
 * и файлах для сущности
 * @param {String} entity
 * @return {Function}
 */
export function resetTypesAndDocuments(entity) {
  return (dispatch) => {
    dispatch({
      type: types.SET_TYPES_AND_DOCUMENTS,
      payload: {
        types: [],
        documents: [],
        filesInType: [],
      },
      entity,
    });
  };
}

/**
 * Действие для получения типов и документов
 * для обновления их в store
 * @param {String} entity
 * @param {String} entityId
 * @param params
 * @return {function(*): Promise<*>}
 */
export function getTypesAndDocuments(entity, entityId = '', params = {}) {
  return (dispatch) => {
    return documentService
      .getTypesAndDocuments(entity, entityId, params)
      .then((data) => {
        dispatch({
          type: types.SET_TYPES_AND_DOCUMENTS,
          payload: {
            types: data.types,
            documents: data.documents,
            filesInType: data.filesInType,
          },
          entity,
        });

        return data;
      })
      .catch((error) => toast.errorAsync(error));
  };
}

/**
 * Получение печатной формы и транспортной накладной
 * @param {String} entityId
 * @returns {function(*): *}
 */
export function getPrintableFormAndTransportFiles(entityId) {
  return (dispatch) => {
    return dispatch(getPrintableForm(entityId)).then(() => {
      return dispatch(getTransportFiles(entityId));
    });
  };
}

/**
 * Получение доументов сканкопии и траспортной накладной
 * @deprecated
 * @param entity
 * @param entityId
 * @returns {function(*): *}
 */
export function getScansAndTransportFiles(entity, entityId) {
  return (dispatch) => {
    return dispatch(getTypesAndDocuments(entity, entityId)).then(() => {
      return dispatch(getTransportFiles(entityId));
    });
  };
}

/**
 * Получение всех документов для перевозки
 * @param entity
 * @param entityId
 * @return {function(*=): *}
 */
export function getFilesForShipping(entity, entityId) {
  return (dispatch) =>
    dispatch(getTypesAndDocuments(entity, entityId)).then(() =>
      dispatch(getPrintableForm(entityId)).then(() => dispatch(getTransportFiles(entityId))),
    );
}

/**
 * Сбросить и получить новые данные типов и документов
 * для обновления их в store
 * @param {*} entity
 * @param {*} entityId
 * @return {function(*): Promise<unknown>}
 */
export function reloadTypesAndDocuments(entity, entityId) {
  return (dispatch) => {
    dispatch(resetTypesAndDocuments(entity));
    return documentService
      .getTypesAndDocuments(entity, entityId)
      .then((data) => {
        dispatch({
          type: types.SET_TYPES_AND_DOCUMENTS,
          payload: {
            types: data.types,
            documents: data.documents,
            filesInType: data.filesInType,
          },
          entity,
        });
      })
      .catch((error) => toast.errorAsync(error));
  };
}

/**
 * Загрузка списка файлов на сервер
 * @param {String} entity
 * @param {String} typeId
 * @param {String} documentId
 * @param {Array} files
 * @param {String} entityId
 * @param altEntityId
 * @param getTypesParams
 * @return {function(*): Promise<unknown>}
 */
export function addFilesToDocument(
  typeId,
  documentId,
  files,
  entity,
  entityId = '',
  altEntityId = '',
  getTypesParams = undefined,
) {
  return (dispatch) => {
    // В данном типе документов идет загрузка
    dispatch({
      type: types.SET_DOCUMENT_TYPE_FILES_LOADING,
      payload: {
        typeId,
        status: true,
      },
      entity,
    });
    return documentService
      .addFilesToDocument(entity, documentId, files, entityId, typeId)
      .then(({ uploadedFiles, errorFiles }) => {
        // Установить файлы, которые не удалось загрузить
        dispatch({
          type: types.SET_DOCUMENT_UNLOADED_FILES,
          payload: errorFiles.map((errorFile) => ({
            documentId,
            id: errorFile.file.preview,
            name: errorFile.file.name,
            preview: errorFile.file.preview,
          })),
          entity,
        });
        dispatch({
          type: types.SET_DOCUMENT_TYPE_FILES_LOADING,
          payload: {
            typeId,
            status: false,
          },
          entity,
        });
        const title = `${uploadedFiles.length === 1 ? 'Файл загружен' : 'Файлы загружены'}`;
        if (uploadedFiles.length) {
          const message = uploadedFiles.map(({ name }, i) => (
            <Fragment key={`${i}${name}`}>
              {name}
              <br />
            </Fragment>
          ));
          toast.success(title, message);
        }
        errorFiles.forEach((file, index) => {
          if (index < 4) {
            const errorObj =
              errorsMapping.get(file.error?.response?.data?.error_code) ||
              errorsMapping.get(file.error?.response?.data?.error) ||
              errorsMapping.get(file.error?.response?.data?.error_description);
            if (errorObj) {
              toast.error(errorObj.title, errorObj.message);
            } else {
              toast.error(`Не удалось загрузить файл ${file.file.name}`);
            }
          }
        });
        dispatch(getTypesAndDocuments(entity, altEntityId || entityId, getTypesParams));
      })
      .catch(() => {
        // В данном типе документов загрузка завершена
        dispatch({
          type: types.SET_DOCUMENT_TYPE_FILES_LOADING,
          payload: {
            typeId,
            status: false,
          },
          entity,
        });
      });
  };
}

/**
 * Удаление файла
 * @param {String} documentId
 * @param {String} fileId
 * @param entity
 * @param {String} entityId
 * @param altEntityId
 * @return {Function}
 */
// убрать altEntityId после замены запроса на получение документов партнёрства при онбординге
export function deleteFile(documentId, fileId, entity, entityId = '', altEntityId = '', getTypesParams = undefined) {
  return (dispatch) => {
    return documentService
      .deleteFile(entity, documentId, fileId, entityId)
      .then(() => {
        toast.success('Файл удален');
        dispatch(getTypesAndDocuments(entity, altEntityId || entityId, getTypesParams));
      })
      .catch((error) => toast.errorAsync(error));
  };
}

/**
 * Добавить файлы, которые ну удалось загрузить
 * @param {String} entity
 * @param {String} typeId
 * @param {String} documentId
 * @param {Array} files
 * @returns {Function}
 */
export function setUnloadedFiles(typeId, documentId, files, entity) {
  return (dispatch) => {
    dispatch({
      type: types.SET_DOCUMENT_UNLOADED_FILES,
      payload: files.map((file) => ({
        typeId,
        documentId,
        id: file.preview,
        name: file.name,
        preview: file.preview,
      })),
      entity,
    });
    files.forEach((file) => toast.error(`Не удалось загрузить файл ${file.name}`));
  };
}

/**
 * Смена статуса загрузки
 * у типа документов
 * @param {String} typeId
 * @param {Boolean} status
 * @param {String} entity
 * @return {Function}
 */
export function setTypeLoading(typeId, status, entity) {
  return (dispatch) => {
    dispatch({
      type: types.SET_DOCUMENT_TYPE_FILES_LOADING,
      payload: {
        typeId,
        status,
      },
      entity,
    });
  };
}

/**
 * Удаление не загруженного файла
 * из списка
 * @param {String} fileId
 * @param {String} entity
 * @return {Function}
 */
export function deleteUnloadFile(fileId, entity) {
  return (dispatch) => {
    dispatch({
      type: types.DELETE_DOCUMENT_UNLOAD_FILE,
      payload: fileId,
      entity,
    });
  };
}

/**
 * Создание документа с последующим
 * добавлением файлов
 * @param {*} typeId
 * @param {*} documentId
 * @param {*} files
 * @param {*} entity
 * @param {*} entityId
 * @param getTypesParams
 * @return {function(*)}
 */
export function addDocumentWithFiles(typeId, documentId, files, entity, entityId = '', getTypesParams = undefined) {
  return (dispatch) => {
    return documentService
      .addDocument(entity, typeId, entityId)
      .then((data) => {
        return addFilesToDocument(
          typeId,
          data.data.id,
          files,
          entity,
          data.data.entity_id,
          entityId,
          getTypesParams,
        )(dispatch);
      })
      .catch(toast.errorAsync);
  };
}

/**
 * Событие на скачивание шаблона сканкопии
 * @returns {function(*, *): Promise<*>}
 */
export function downloadScanCopy(entity, entityId) {
  return () => {
    return documentService.getScanCopyTemplate(entity, entityId).catch((error) => toast.errorAsync(error));
  };
}

/**
 * Подписание документа при онлайн бронировании
 * @param entityType
 * @param entityId
 * @param scanCopyId
 * @param entityHFID
 * @return {function(*)}
 */
export function acceptOnlineDocument(entityType, entityId, scanCopyId, entityHFID) {
  return (dispatch, getState) => {
    const queryString = getRawQuery(getState());
    return documentService
      .acceptOnlineDocument(entityType, entityId, scanCopyId)
      .then(() => {
        if (entityType === GRAPHICS) {
          toast.success(`Доп. соглашение по графику № ${entityHFID} подписано`);
          dispatch(routerPush(`/graphics${queryString}`));
        } else {
          toast.success('Документ подписан');
          // const activeItemStatus = getState().suggestionsReserved?.activeItem?.status?.code_name;
          dispatch(routerPush(`/shippings${queryString}`));
        }
      })
      .catch(toast.errorAsync);
  };
}
