import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

import ModalWrapper from '../ModalWrapper';
import IconClose from '../../Icons/SVGIcons/IconClose';

interface ModalProps {
  wrapperClass?: string;
  onClose: () => void;
  children: React.ReactNode;
  innerContentClass?: string;
  overlayClass?: string;
}

function ModalDetail({ children, onClose, innerContentClass, wrapperClass, overlayClass }: ModalProps) {
  return (
    <ModalWrapper onClose={onClose}>
      <section className={classNames(styles.wrapper, overlayClass)} onClick={onClose}>
        <div className={classNames(styles.wrapperInner, wrapperClass)}>
          <div
            className={classNames(styles.wrapperInnerContent, innerContentClass)}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}
          >
            {children}
          </div>
          <IconClose className={styles.wrapperClose} width="30" height="30" onClick={onClose} />
        </div>
      </section>
    </ModalWrapper>
  );
}

export default ModalDetail;
