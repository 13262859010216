import { useEffect } from 'react';

import {
  AUCTION_STAGES,
  AUCTION_TYPE,
  BID_STEP,
  MAX_BID_WITH_VAT,
  MAX_BID_WITHOUT_TAX,
  MIN_BID_WITH_VAT,
  MIN_BID_WITHOUT_TAX,
  PRICE_WITH_VAT,
  PRICE_WITHOUT_TAX,
  VISIBILITY,
  WITHOUT_PRICES,
} from '../blocks/AuctionBlock';
import { ALL_PEOPLE, DESCENDING_PRICE_AUCTION } from '../../../store/order/auction/formKeys';
import { useFormikContextSelector } from '../../../components/FormikFieldsV3/FormikContext';
import { useFieldValue } from '../../../components/FormikFieldsV3/hooks';

export function useWithoutPricesEffect(auctionStageIndex?: number, fieldsPrefix: string = '') {
  const setFieldValue = useFormikContextSelector((c) => c.setFieldValue);
  const fieldName = fieldsPrefix || `${AUCTION_STAGES}[${auctionStageIndex}]`;
  const [auctionType] = useFieldValue(`${fieldName}.${AUCTION_TYPE}`);
  const [visibility] = useFieldValue(`${fieldName}.${VISIBILITY}`);
  const [step] = useFieldValue(`${fieldName}.${BID_STEP}`);
  const [isAuctionWithoutPrices] = useFieldValue(`${fieldName}.${WITHOUT_PRICES}`);

  /* Эффект на смену чекбокса "Без цены" при "Все исполнители" или аукцион на понижение */
  useEffect(() => {
    if (visibility === ALL_PEOPLE || auctionType === DESCENDING_PRICE_AUCTION) {
      setFieldValue(`${fieldName}.${WITHOUT_PRICES}`, false);
    }
  }, [auctionType, fieldName, setFieldValue, visibility]);

  /* Эффект на смену чекбокса "Не знаю цену" (сброс цен) */
  useEffect(() => {
    if (isAuctionWithoutPrices) {
      setFieldValue(`${fieldName}.${PRICE_WITHOUT_TAX}`, null);
      setFieldValue(`${fieldName}.${MIN_BID_WITHOUT_TAX}`, null);
      setFieldValue(`${fieldName}.${MAX_BID_WITHOUT_TAX}`, null);
      setFieldValue(`${fieldName}.${PRICE_WITH_VAT}`, null);
      setFieldValue(`${fieldName}.${MIN_BID_WITH_VAT}`, null);
      setFieldValue(`${fieldName}.${MAX_BID_WITH_VAT}`, null);
    }
  }, [fieldName, isAuctionWithoutPrices, setFieldValue]);

  /* Эффект на сброс шага при переключении чекбокса "Не знаю цену" */
  useEffect(() => {
    if (!isAuctionWithoutPrices && (step || 0) < 100) {
      setFieldValue(`${fieldName}.${BID_STEP}`, 500);
    }
  }, [fieldName, isAuctionWithoutPrices, setFieldValue, step]);
}
