import React from 'react';
import SVGIcon from './index';

function IconAssignmentCheck(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.95 4H16V17H3V4H7.05001C7.28164 2.85888 8.29052 2 9.5 2C10.7095 2 11.7184 2.85888 11.95 4ZM11 4.5C11 5.32843 10.3284 6 9.5 6C8.67157 6 8 5.32843 8 4.5C8 3.67157 8.67157 3 9.5 3C10.3284 3 11 3.67157 11 4.5ZM7.82841 14.0711L7.12135 13.364L7.12132 13.364L5 11.2427L5.70711 10.5356L7.82838 12.6569L13.4853 7L14.1924 7.70711L7.82841 14.0711Z"
      />
    </SVGIcon>
  );
}

IconAssignmentCheck.defaultProps = {
  color: 'danger',
};

export default IconAssignmentCheck;
