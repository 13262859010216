import { combineReducers } from 'redux';
import { DRIVER, CAR, TRAILER } from '../resources/formKeys';
import common from './common';
import { SHIPPING, SHIPPING_ORDER, GRAPHICS, OUTCOMING_CLAIMS, INCOMING_CLAIMS, PARTNERSHIP } from './constants';

function reducerCommonWrapper(reducerFunction, reducerName) {
  return (state, action) => {
    const { entity } = action;
    const isInitializationCall = state === undefined;
    if (entity !== reducerName && !isInitializationCall) {
      return state;
    }
    return reducerFunction(state, action);
  };
}

const documents = combineReducers({
  company: reducerCommonWrapper(common, 'company'),
  [GRAPHICS]: reducerCommonWrapper(common, GRAPHICS),
  [SHIPPING]: reducerCommonWrapper(common, SHIPPING),
  [SHIPPING_ORDER]: reducerCommonWrapper(common, SHIPPING_ORDER),
  [OUTCOMING_CLAIMS]: reducerCommonWrapper(common, OUTCOMING_CLAIMS),
  [INCOMING_CLAIMS]: reducerCommonWrapper(common, INCOMING_CLAIMS),
  [DRIVER]: reducerCommonWrapper(common, DRIVER),
  [CAR]: reducerCommonWrapper(common, CAR),
  [TRAILER]: reducerCommonWrapper(common, TRAILER),
  [PARTNERSHIP]: reducerCommonWrapper(common, PARTNERSHIP),
});

export default documents;
