import * as types from './actionTypes';
import * as AuthService from '../../services/authService';
import * as AuthTokenService from '../../services/authTokenService';
import { authenticateUser, deauthorizeUser } from '../user/actions';
// import { replace as routerReplace } from 'connected-react-router';
import Cookies from 'js-cookie';
import { TRANSPORT_COMPANY } from '../../constants';

export function logIn_DEPRECATED(loginAndPassword) {
  return (dispatch) => {
    return AuthService.authorization_DEPRECATED(loginAndPassword)
      .then(() => dispatch(startLoading()))
      .then(() => dispatch(authenticateUser()))
      .catch((error) => {
        const errorCode = error?.response?.status;
        return Promise.reject(errorCode);
      })
      .finally(() => dispatch(stopLoading()));
  };
}

const flowToUrl = (flow, companyInfo) => {
  switch (flow) {
    case 'registration':
      if (companyInfo?.kinds[0] === TRANSPORT_COMPANY) {
        return `${window.location.origin}/onboarding?flow=registration`;
      }
      return '';
    default:
      return '';
  }
};

export function authByCode(code, state, flow) {
  return (dispatch) => {
    return AuthService.authorization({ code, state, flow })
      .then(() => dispatch(startLoading()))
      .then(() => dispatch(authenticateUser()))
      .then(([userRequestPromise]) => {
        const companyInfo = userRequestPromise?.value?.[1];
        const redirectUri = Cookies.get(state);
        // dispatch(routerReplace(redirectUri || window.location.pathname));
        const flowUri = flowToUrl(flow, companyInfo);
        if (redirectUri) Cookies.remove(state);
        window.location.href = flowUri || redirectUri || window.location.pathname; // FIXME
      })
      .catch((error) => {
        console.error('auth by code -> ERROR', error);
        const errorCode = error?.response?.status;
        return Promise.reject(errorCode);
      })
      .finally(() => dispatch(stopLoading()));
  };
}

export function authByToken() {
  return (dispatch) => {
    const refreshToken = AuthTokenService.getRefreshToken();

    if (refreshToken) {
      dispatch(startLoading());

      return AuthService.authorization({ refresh_token: refreshToken })
        .then(() => dispatch(authenticateUser()))
        .catch(() => dispatch(deauthorizeUser()))
        .finally(() => dispatch(stopLoading()));
    } else {
      dispatch(stopLoading());
    }
  };
}

export function startLoading() {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_LOADING, loading: true });
    dispatch({ type: types.UPDATE_LOADED, loaded: false });
  };
}

export function stopLoading() {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_LOADING, loading: false });
    dispatch({ type: types.UPDATE_LOADED, loaded: true });
  };
}
