import {
  MatomoProvider as _MatomoProvider,
  createInstance,
  useMatomo as _useMatomo,
} from '@jonkoops/matomo-tracker-react';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { getIsAuthenticated } from '../../store/user/selectors';
import { replaceUUIDInURL } from '../../helpers/replaceUUIDInURL';
import { useUserContext } from '../../contexts/userContext';
import { useMatomoCustomDimensions } from './hooks';

const checkUserId = (userId) => {
  if (process.env.NODE_ENV !== 'production') {
    return true;
    // return [
    //   '3f4a02be-d07f-4cf6-94ad-6eed77c9243d', // morkovka
    // ].includes(userId);
  }

  return true;
  // return [
  //   'b74abc2e-5177-497f-840a-f32881e369f1',
  //   'b7bfcfd4-d77e-4dc9-82ca-c97d5e1e15b6',
  //   '73a4ee27-6c21-42b2-933c-404043f0f34d',
  //   '1a16a1b1-dcc5-4444-8dae-c90ce77eac31',
  //   '1f0cdfec-28dc-425d-9d85-2efd06da4f2b',
  //   'd907f5be-1c6d-442d-adee-9d1427264ec7',
  //   '55554ae5-0d7c-4b29-b053-37f6d8e49121',
  //   'faef55f7-be0d-4d59-be65-d7766c3f78f8',
  //   'bd50a234-3738-421e-a058-02ab3a35a98a',
  //   '11b85ade-12d7-4b9b-8bcb-2ebb00b6de1f',
  //   'e1f94a00-033a-4b03-8076-d39baedfbd48',
  //   'a0e72d81-a58d-46ee-9791-38a84f601c1a',
  //   '024b75e6-39d4-4995-9774-a9857c9f3212',
  //   '85525993-9028-4d25-a774-d34ea34441e9',
  //   'fb5fae0a-c606-461d-adf5-a8f22d15c423',
  //   '91fe36ec-487d-41ee-9a0a-24525773ac46',
  //   'b5e65f73-3818-4d36-9d22-6dd5e5e70357',
  //   '81d99afd-1c27-4a4a-b71d-59edcc667599',
  //   'f64256c2-4267-4ff1-a4f1-dbbd54ec15b1', // test user
  //   '05addeaa-52a8-4693-82ef-bc1ead300a53', // test user
  // ].includes(userId);
};

function MatomoProvider({ children }) {
  const { userId } = useUserContext();

  const instance = useMemo(() => {
    if (!(process.env.REACT_APP_MATOMO_BASE_URL && process.env.REACT_APP_MATOMO_SITE_ID)) return null;

    if (!(userId && checkUserId(userId))) return null;

    return createInstance({
      urlBase: process.env.REACT_APP_MATOMO_BASE_URL,
      siteId: Number(process.env.REACT_APP_MATOMO_SITE_ID),
      linkTracking: false,
    });
  }, [userId]);

  if (instance) {
    return (
      <_MatomoProvider value={instance}>
        {children}
        <MatomoPageTracker />
      </_MatomoProvider>
    );
  }
  return children;
}

function MatomoPageTracker() {
  const location = useLocation();
  const { trackPageView, pushInstruction, enableLinkTracking } = useMatomo();
  const isUserAuthenticated = useShallowEqualSelector(getIsAuthenticated);

  const { userId } = useUserContext();
  const customDimensions = useMatomoCustomDimensions();

  useEffect(() => {
    const formattedPathname = replaceUUIDInURL(location.pathname);
    if (formattedPathname === '/' && !isUserAuthenticated) {
      pushInstruction('appendToTrackingUrl', 'new_visit=1'); // forces a new visit
      pushInstruction('deleteCookies'); // deletes existing tracking cookies to start the new visit
      // the two lines must be above the call to track* function
      trackPageView({
        documentTitle: formattedPathname,
      });
      pushInstruction('appendToTrackingUrl', ''); // do not force a new visit anymore (only do it once)
    } else {
      pushInstruction('setUserId', userId);
      trackPageView({
        documentTitle: formattedPathname,
        customDimensions: customDimensions,
      });
    }
  }, [isUserAuthenticated, location.pathname, pushInstruction, trackPageView, userId, customDimensions]);

  enableLinkTracking();

  return null;
}

function useMatomo() {
  const { userId } = useUserContext();

  if (process.env.REACT_APP_MATOMO_BASE_URL && process.env.REACT_APP_MATOMO_SITE_ID && userId && checkUserId(userId)) {
    return _useMatomo();
  }
  return {
    trackEvent: () => {},
    trackEvents: () => {},
    trackPageView: () => {},
    trackSiteSearch: () => {},
    trackLink: () => {},
    enableLinkTracking: () => {},
    pushInstruction: () => {},
  };
}

export { MatomoProvider, useMatomo };
