import React from 'react';
import SVGIcon from './index';
import useMultiColorsIcons from '../useMultiColorIcons';

const IconCircle = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  const { mainStroke, altFill } = useMultiColorsIcons(props.color);
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <rect x="5" y="5" width="10" height="10" rx="5" className={mainStroke} strokeWidth="2" />
      <rect x="6" y="6" width="8" height="8" rx="4" className={altFill} />
    </SVGIcon>
  );
};

IconCircle.defaultProps = {
  color: 'baseTheme',
};

export default IconCircle;
