enum Documents {
  passport = 'passport',
  identification_card = 'identification_card',
  refugee_certificate = 'refugee_certificate',
  biometric_passport = 'biometric_passport',
}

const Documents_ = new Map()
  .set(Documents.passport, 'Паспорт')
  .set(Documents.identification_card, 'ID-карта')
  .set(Documents.refugee_certificate, 'Удостоверение беженца')
  .set(Documents.biometric_passport, 'Биометрический паспорт');

const DOCUMENTS = [
  { value: Documents.passport, label: Documents_.get(Documents.passport) },
  { value: Documents.biometric_passport, label: Documents_.get(Documents.biometric_passport) },
  { value: Documents.identification_card, label: Documents_.get(Documents.identification_card) },
  { value: Documents.refugee_certificate, label: Documents_.get(Documents.refugee_certificate) },
];

enum Countries {
  RU = 'Россия',
  BY = 'Беларусь',
  KZ = 'Казахстан',
  KG = 'Киргизия',
  UZ = 'Узбекистан',
  LV = 'Латвия',
  TJ = 'Таджикистан',
}

enum COUNTRY_ID {
  RUS = 1, // РФ
  BLR = 2, // Беларусь
  KAZ = 3, // Казахстан
  UKR = 4, // Украина
  DPR = 5, // ДНР
  LPR = 6, // ЛНР
  KGZ = 7, // Киргизия
  UZB = 8, // Узбекистан
  LVA = 9, // Латвия
  TJK = 10, // Таджикистан
}

const Countries_ = new Map()
  .set(COUNTRY_ID.RUS, 'Российская Федерация')
  .set(COUNTRY_ID.BLR, 'Республика Беларусь')
  .set(COUNTRY_ID.KAZ, 'Республика Казахстан')
  .set(COUNTRY_ID.UKR, 'Украина')
  .set(COUNTRY_ID.LPR, 'ЛНР')
  .set(COUNTRY_ID.DPR, 'ДНР')
  .set(COUNTRY_ID.KGZ, 'Киргизия')
  .set(COUNTRY_ID.UZB, 'Узбекистан')
  .set(COUNTRY_ID.LVA, 'Латвия')
  .set(COUNTRY_ID.TJK, 'Таджикистан');

const COUNTRIES = Array.from(Countries_).map(([value, label]) => ({ value, label }));

export { Documents, Documents_, DOCUMENTS, Countries, Countries_, COUNTRIES, COUNTRY_ID };
