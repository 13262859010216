import * as actionTypes from './actionTypes';

import produce from 'immer';

const initialState = {
  items: [],
  totalItems: 0,
  currentPage: 0,
  activeItem: null,
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    // Инициализировать список заявок
    case actionTypes.INIT_ITEMS:
      draft.items = action.payload.items;
      draft.totalItems = action.payload.totalItems;
      draft.currentPage = action.payload.currentPage;
      break;

    // ID активного элемента
    case actionTypes.CURRENT_SET:
      draft.activeItem = action.payload;
      break;

    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
