import * as constants from '../../../store/claims/constants';

/**
 * Перевод статуса претензии
 * @param type
 * @param status
 * @param price
 * @returns {string}
 */
export function getClaimStatusTranslation(type, status, price) {
  return type === constants.OUTCOMING
    ? getOutcomingClaimStatusTranslation(status, price)
    : getIncomingClaimStatusTranslation(status);
}

/**
 * Перевод статуса претензии исходящей
 * @param status
 * @param price
 * @returns {string}
 */
export function getOutcomingClaimStatusTranslation(status, price) {
  switch (status) {
    case constants.NEED_FIX:
      return 'На доработке';
    case constants.REJECT:
      return 'Отклонена';
    case constants.ACCEPT:
    case constants.PARTIAL_ACCEPT:
      return price ? `Согласована (сумма — ${price} ₽)` : 'Согласована';
    case constants.DONE:
      return 'Удовлетворена';
    case constants.NEW:
      return 'Черновик';
    case constants.CANCELED:
      return 'Отозвана';
    case constants.PENDING_TC_REVIEW:
      return 'Ожидает решения';
    case constants.PENDING_REVIEW:
    case constants.IN_PROGRESS:
    default:
      return 'На рассмотрении';
  }
}

/**
 * Перевод статуса претензии входящей
 * @param status
 * @returns {string}
 */
export function getIncomingClaimStatusTranslation(status) {
  switch (status) {
    case constants.NEED_FIX:
      return 'Требуется уточнение';
    case constants.DONE:
      return 'Удовлетворена';
    case constants.NEW:
      return 'Ожидает рассмотрения';
    case constants.ACCEPT:
      return 'Согласована';
    case constants.PARTIAL_ACCEPT:
    case constants.ACCEPT_UNILATERALLY:
      return 'Согласована в одностороннем порядке';
    case constants.ACCEPT_EXPIRATION:
      return 'Согласована по истечению срока';
    case constants.REJECT:
      return 'Ответ на рассмотрении';
    case constants.CANCELED:
      return 'Отозвана';
    case constants.PENDING_TC_REVIEW:
      return 'Ожидает решения';
    case constants.PENDING_REVIEW:
    case constants.IN_PROGRESS:
    default:
      return 'Ответ на рассмотрении';
  }
}
