export const INTERMEDIATE_PERIODS = new Map()
  .set(null, 'По окончанию этапа')
  .set(30, 'Раз в 30 минут')
  .set(60, 'Раз в 1 час')
  .set(180, 'Раз в 3 часа')
  .set(300, 'Раз в 5 часов');

export const intermediatePeriodList = [...INTERMEDIATE_PERIODS].map((i) => ({
  value: (i[0] || 0) + '',
  label: i[1],
}));
