import React from 'react';
import SVGIcon from './index';

export default function IconAwningWithTruck3(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 55 25">
      <path d="m14.5 18a3.5 3.5 0 1 0 3.5 3.5 3.47 3.47 0 0 0 -3.5-3.5zm0 5a1.5 1.5 0 1 1 1.5-1.5 1.49 1.49 0 0 1 -1.5 1.5zm30-5a3.5 3.5 0 1 0 3.5 3.5 3.47 3.47 0 0 0 -3.5-3.5zm0 5a1.5 1.5 0 1 1 1.5-1.5 1.49 1.49 0 0 1 -1.5 1.5zm9.5-6v-3a1.86 1.86 0 0 0 -1.23-1.93l-4.77-1.91-3.91-4.46c-.76-1-1.36-1.7-2.89-1.7h-5.2v-4h-36v19h10.67l.3-.36a4.71 4.71 0 0 1 3.53-1.64 4.56 4.56 0 0 1 4.5 4.11l.09.89h20.81l.1-.89a4.52 4.52 0 0 1 9 0l.09.89h5.91v-5zm-11.47-10c.12.15 3.26 3.74 3.26 3.74l-.57.28-9.22-1.11v-3.91h5.2c.55 0 .64.09 1.33 1zm-28.03 8a6.66 6.66 0 0 0 -4.74 2h-7.76v-15h32v15h-14.83a6.48 6.48 0 0 0 -4.67-2zm19.5 5h-13.16a6.53 6.53 0 0 0 -.34-1h13.5zm19 0h-2.16a6.52 6.52 0 0 0 -12.68 0h-2.16v-8.08l9.58 1.08 1.87-.93 4.55 1.85v5.08h1z" />
    </SVGIcon>
  );
}

IconAwningWithTruck3.defaultProps = {
  color: 'base',
};
