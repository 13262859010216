import http from './config/webConfig';

/**
 * Получение файла через AJAX
 * запрос
 * @param url
 * @param fileName
 * @param axiosOptions
 * @returns {Promise<*>}
 */
export async function secureDownload(url, fileName, axiosOptions = {}) {
  return http
    .get(url, { responseType: 'arraybuffer', ...axiosOptions })
    .then((response) => _redirectLocal(response.data, fileName));
}

/**
 * Локальный редирект на загрузку файла
 * @param blob
 * @param fileName
 * @private
 */
function _redirectLocal(blob, fileName) {
  const objectUrl = window.URL.createObjectURL(new Blob([blob]));
  // For chrome
  if (/Chrome/.test(navigator.userAgent) || /Google Inc/.test(navigator.vendor) || /Safari/.test(navigator.userAgent)) {
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = objectUrl;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
    return;
  }
  // For IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(objectUrl, fileName);
    return;
  }
  // For firefox
  window.open(objectUrl);
}

/**
 * Добавление или обновление записи в хранилище
 */
export async function createStorageRecord(key, value) {
  const url = `/storage/${key}`;
  const data = { value };
  return http.post(url, data, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Чтение записи из хранилища
 */
export async function getStorageRecord(key) {
  const url = `/storage/${key}`;
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}
