type TTime = number | null;

type TStep = {
  label: string;
  value: number;
};

/**
 * Генерация списка выбора времени
 * @param start {String|number|null} - стартовое значение в минутах
 * @param addEnd {Boolean} - флаг: нужно ли добавлять 23:59 к списку
 * @param end {String|number|null} - значение ограничения времени в минутах
 * @returns {Array}
 */
function getTimeSteps(start: TTime = null, addEnd: boolean = true, end: TTime = null): TStep[] {
  const steps: TStep[] = [];
  const halfHour = 30;

  const startTime = (start: TTime): number => {
    if (start) {
      return start - Math.abs(start - Math.floor(start / 30) * 30);
    } else {
      return 0;
    }
  };

  const endTime = (end: TTime): number => {
    if (end && end !== 24 * 60) {
      return end + 1 - Math.abs(end - Math.floor(end / 30) * 30);
    } else {
      return 24 * 60;
    }
  };

  for (let i = startTime(start); i < endTime(end); i += halfHour) {
    const hour = Math.floor(i / 60);
    const minutes = Math.abs(i - hour * 60);
    steps.push({
      label: `${hour < 10 ? '0' + hour : hour}:${minutes < 10 ? '0' + minutes : minutes}`,
      value: i,
    });
  }

  if (addEnd) {
    steps.push({
      label: '23:59',
      value: 24 * 60 - 1,
    });
  }
  return steps;
}

export default getTimeSteps;
