import React from 'react';
import SVGIcon from './index';

export default function IconTruck(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4H15V7H17L19 10V14H17C17 15.1046 16.1046 16 15 16C13.8954 16 13 15.1046 13 14H7C7 15.1046 6.10457 16 5 16C3.89543 16 3 15.1046 3 14H1V4ZM15 15C15.5523 15 16 14.5523 16 14C16 13.4477 15.5523 13 15 13C14.4477 13 14 13.4477 14 14C14 14.5523 14.4477 15 15 15ZM6 14C6 14.5523 5.55228 15 5 15C4.44772 15 4 14.5523 4 14C4 13.4477 4.44772 13 5 13C5.55228 13 6 13.4477 6 14ZM16.5 8H15V10H18L16.5 8Z"
      />
    </SVGIcon>
  );
}

IconTruck.defaultProps = {
  color: 'primary',
};
