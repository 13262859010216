import http, { httpApiV2 } from '../../../services/config/webConfig';

/**
 * Получение статуса доступа к мультиполису для текущей компании
 * @return {*}
 */
export function getMultipolicyAccessStatus() {
  return httpApiV2.get(`insurance/companies/me`).then(({ data }) => {
    return data;
  });
}

/**
 * валидация перевозки
 * @param data
 * @return {*}
 */
export function getMultipolicyValidations({ shippingId }) {
  return httpApiV2.post(`insurance/validations/${shippingId}`).then(({ data }) => {
    return data;
  });
}

/**
 * валидация перевозки и получение цены страховки
 * @param data
 * @return {*}
 */
export function getMultipolicyValidationsWithPrice({ shippingId, companyType, firstPointSelectedCarSupply }) {
  const data = {
    // email,
    // source,
    first_point_selected_car_supply: firstPointSelectedCarSupply,
  };

  return httpApiV2.post(`insurance/multipolicies/prices/${shippingId}/${companyType}`, data).then(({ data }) => {
    return data;
  });
}

/**
 * Получить пример заявки на страхование
 * @param shippingId
 * @return {*}
 */
export function getMultipolicyDraft(shippingId) {
  return httpApiV2.get(`insurance/multipolicies/applications/drafts/${shippingId}`, {
    headers: { Accept: 'application/pdf' },
    responseType: 'blob',
  });
}

/**
 * Заказать полис
 * @param data
 * @return {*}
 */
export function orderMultipolicy({
  shippingId,
  email,
  source = '',
  firstPointSelectedCarSupply = null,
  carId,
  trailerId,
  driversIds,
}) {
  const data = {
    email,
    source,
    first_point_selected_car_supply: firstPointSelectedCarSupply,
    assigned_resources: {
      car_id: carId,
      trailer_id: trailerId,
      drivers_ids: driversIds,
    },
  };
  return httpApiV2.post(`insurance/multipolicies/${shippingId}`, data).then(({ data }) => {
    return data;
  });
}

/**
 * Отказаться от полиса
 * @param shippingId
 * @return {*}
 */
export function rejectMultipolicy(shippingId) {
  return httpApiV2.delete(`insurance/multipolicies/${shippingId}`).then(({ data }) => {
    return data;
  });
}

/**
 * Получить файлы по страхованию
 * @param shippingId
 * @return {*}
 */
export function getMultipolicyFiles(shippingId) {
  return httpApiV2.get(`insurance/multipolicies/${shippingId}`).then(({ data }) => {
    return data;
  });
}

/**
 * Получить пример заявления на страхование
 * @return {*}
 */
export function getMultipolicySample() {
  return httpApiV2.get(`insurance/files/multipolicy-sample.pdf`, { responseType: 'blob' });
}

/**
 * Получить страховку из core, чтобы убедиться, что core успел обновить перевозку после
 * оформления/отказа от страховки
 * * @param multipolicyId
 * @return {*}
 */
export function getMultipolicyFromCore(multipolicyId) {
  return http.get(`/multipolice/${multipolicyId}`).then(({ data }) => {
    return data;
  });
}
