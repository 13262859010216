/* Маппинг необходимых документов */
import * as formKeys from '../../../store/order/formalization/formKeys';

export function getRequiredDocs(item: {
  docsForPayment: [];
  closingDocs: { name: string; count: number }[];
  closingDocsPeriod: string;
}): { paymentDocs: string; closingDocs: string; closingDocsPeriod: string } {
  const paymentDocs: string[] = item?.docsForPayment?.map((doc) => documentsForPayment.get(doc) || '');
  const closingDocs: string[] = item?.closingDocs?.map((doc) => {
    return `${doc.name} ${doc.count && `(${doc.count} шт)`}`;
  });

  return {
    paymentDocs: paymentDocs?.join(', '),
    closingDocs: closingDocs?.join(', '),
    closingDocsPeriod: item.closingDocsPeriod,
  };
}

const documentsForPayment: Map<string, string> = new Map()
  .set(formKeys.BILL, 'Счёт')
  .set(formKeys.BILL_OF_LADING, 'ТрН')
  .set(formKeys.ACT, 'Акт')
  .set(formKeys.INVOICE, 'Счёт-фактура')
  .set(formKeys.WAYBILL, 'ТТН')
  .set(formKeys.PACKING_LIST, 'ТН')
  .set(formKeys.POWER_OF_ATTORNEY, 'Доверенность')
  .set(formKeys.UNIVERSAL_TRANSFER_DOC, 'УПД')
  .set(formKeys.ACCOMPANYING_DOC, 'Сопроводительный документ')
  .set(formKeys.ACCEPTANCE_CERTIFICATE, 'Акт об оказании услуг')
  .set(formKeys.DELIVERY_CERTIFICATE, 'Акт о выгрузке')
  .set(formKeys.CMR, 'CMR')
  .set(formKeys.BILL_OF_LADING_OR_WAYBILL, 'ТрН или ТТН')
  .set(formKeys.FORWARDING_ORDER, 'Поручение экспедитору')
  .set(formKeys.SHIPPING_ORDER, 'Заявка на перевозку')
  .set(formKeys.FORWARDING_RECEIPT, 'Экспедиторская расписка')
  .set(formKeys.ATTACHMENT_TO_BILL_OF_LADING, 'Приложение к Транспортной накладной');
