import React from 'react';
import SVGIcon from './index';

const IconDownload = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 14L11.9407 12H12V11.9389L15.5 8.33198L12 8.33198L12 3H8L8 8.33198L4.5 8.33198L8 11.9389V12H8.05929L10 14ZM16 17V15.5H4V17H16Z"
      />
    </SVGIcon>
  );
};

export default IconDownload;
