import { FRIDAY, MONDAY, SATURDAY, SUNDAY, THURSDAY, TUESDAY, WEDNESDAY } from '../../../constants';

/**
 * Переводим на русский дни недели из константы и сохраняем результат ф-ции
 * @type {Function}
 */
export const getTranslatedDays = (day) => {
  return DAYS.get(day) || '';
};

const DAYS = new Map()
  .set(MONDAY, 'Пн')
  .set(TUESDAY, 'Вт')
  .set(WEDNESDAY, 'Ср')
  .set(THURSDAY, 'Чт')
  .set(FRIDAY, 'Пт')
  .set(SATURDAY, 'Сб')
  .set(SUNDAY, 'Вс');
