import * as actionTypes from './locationsActionTypes';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

const initialState = {
  items: [],
  totalItems: 0,
  currentPage: 0,
  totalPages: 0,
  resultsPerPage: 20,
  activeItemId: null,
  activeItem: null,
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    // Создаем список адресов
    case actionTypes.INIT_ITEMS:
      draft.items = action.payload.items;
      draft.totalItems = action.payload.totalItems;
      draft.currentPage = action.payload.currentPage;
      draft.totalPages = action.payload.totalPages;
      draft.resultsPerPage = action.payload.resultsPerPage;
      break;

    // Добавление в конец списка новых элементов
    case actionTypes.UPDATE_ITEMS:
      draft.totalItems = action.payload.totalItems;
      draft.currentPage = action.payload.currentPage;
      draft.totalPages = action.payload.totalPages;
      draft.resultsPerPage = action.payload.resultsPerPage;
      draft.items = draft.items.concat(action.payload.items);
      break;

    // ID активного элемента
    case actionTypes.ACTIVE_ITEM_ID_SET:
      draft.activeItemId = action.payload;
      break;

    case actionTypes.SET_CURRENT:
      draft.activeItem = action.payload;
      break;

    case actionTypes.CLEAR_ITEMS:
      return initialState;
    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
