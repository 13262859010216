import React from 'react';
import SVGIcon from './index';

function IconBox(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <rect x="2" y="3" width="16" height="2" />
      <path fillRule="evenodd" d="M17 7H3V17H17V7ZM15 13H11V15H15V13Z" />
    </SVGIcon>
  );
}

IconBox.defaultProps = { color: 'base' };

export default React.memo(IconBox);
