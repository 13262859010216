import React from 'react';
import SVGIcon from './index';

function IconColorDotSmall(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <circle fillRule="evenodd" cx="10" cy="10" r="4" />
    </SVGIcon>
  );
}

IconColorDotSmall.defaultProps = {
  color: 'primary',
};

export default React.memo(IconColorDotSmall);
