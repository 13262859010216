/**
 *  Храним библиотеку Тип упаковки на фронте (по просьбе Тимура), на бэк не ходим (26.03.20)
 **/
export const PACKAGE_ITEMS = new Map()
  .set('reels', { value: 'reels', label: 'Барабаны' })
  .set('big_bags', { value: 'big_bags', label: 'Биг-бэги' })
  .set('barrels', { value: 'barrels', label: 'Бочки' })
  .set('eurocubes', { value: 'eurocubes', label: 'Еврокубы' })
  .set('cans', { value: 'cans', label: 'Канистры' })
  .set('coils', { value: 'coils', label: 'Катушки' })
  .set('boxes', { value: 'boxes', label: 'Коробки' })
  .set('sheets', { value: 'sheets', label: 'Листы' })
  .set('bags', { value: 'bags', label: 'Мешки' })
  .set('in_bulk', { value: 'in_bulk', label: 'Навалом' })
  .set('not_specified', { value: 'not_specified', label: 'Нет упаковки' })
  .set('pallets', { value: 'pallets', label: 'Паллеты EUR' })
  .set('fin_pallets', { value: 'fin_pallets', label: 'Паллеты FIN' })
  .set('packs', { value: 'packs', label: 'Пачки' })
  .set('pyramid', { value: 'pyramid', label: 'Пирамида' })
  .set('loose', { value: 'loose', label: 'Россыпью' })
  .set('rolls', { value: 'rolls', label: 'Рулоны' })
  .set('cases', { value: 'cases', label: 'Ящики' });

/**
 * Класс опасности
 */
export const HAZARD_CLASSES = new Map()
  .set('1', '1 класс')
  .set('2', '2 класс')
  .set('3', '3 класс')
  .set('4', '4 класс')
  .set('5', '5 класс')
  .set('6', '6 класс')
  .set('7', '7 класс')
  .set('8', '8 класс')
  .set('9', '9 класс');
