import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconArrowClock = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15C11.5927 15 13.7245 13.0267 13.9753 10.5H16V12.5L19 10L16 7.5V9.5H13.9753C13.7245 6.97334 11.5927 5 9 5C6.40733 5 4.27555 6.97334 4.02469 9.5H1V10.5H4.02469C4.27555 13.0267 6.40733 15 9 15ZM9 14C11.2091 14 13 12.2091 13 10C13 7.79086 11.2091 6 9 6C6.79086 6 5 7.79086 5 10C5 12.2091 6.79086 14 9 14ZM10.0713 7.24275L8.57125 9.74275L8.3704 10.0775L8.64645 10.3536L10.1464 11.8536L10.8536 11.1464L9.6296 9.92249L10.9287 7.75725L10.0713 7.24275Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconArrowClock.defaultProps = {
  color: 'base',
};

export default IconArrowClock;
