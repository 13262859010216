import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RowContext } from './Row';

const Col = (props) => {
  const { part, children, className } = props;
  const { span } = useContext(RowContext);
  const [width, setWidth] = useState('100%');

  useEffect(() => {
    if (!part || isNaN(+part)) {
      setWidth('100%');
    } else {
      const colWidth = ((part / 24) * 100).toFixed(5) + '%';
      setWidth(`calc(${colWidth}`);
    }
  }, [part, span]);

  const getColumnStyle = useCallback(() => {
    return {
      width,
      minWidth: width,
      paddingRight: span / 2 + 'px',
      paddingLeft: span / 2 + 'px',
    };
  }, [width, span]);

  return (
    <div style={getColumnStyle()} className={className}>
      {children}
    </div>
  );
};

export default Col;
