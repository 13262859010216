import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

function IconShieldChecked(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4.33334C5.8 2.44444 10 1.5 10 1.5C10 1.5 14.2 2.44444 17 4.33334V9.05556C17 12.8333 13.7333 17.0833 10 18.5C6.26667 17.0833 3.00008 12.8333 3.00008 9.05556L3 4.33334ZM9.11958 10.71L12.8965 6.91998L14 8.03433L9.11958 12.9309L6.3038 10.1065L7.40728 9L9.11958 10.71Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
}

IconShieldChecked.defaultProps = { color: 'base' };

export default React.memo(IconShieldChecked);
