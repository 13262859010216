import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconFullScreen = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3H3V4V8H5V5H8V3H4ZM3 16V17H4H8L8 15H5L5 12H3V16ZM17 17H16H12V15H15V12H17V16V17ZM17 4V3H16H12V5L15 5V8L17 8V4Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconFullScreen.defaultProps = {
  color: 'base',
};

export default IconFullScreen;
