import { SendEvent, eventCreator } from './action';

/* Аналитика */

/* Вход */
const CAT_ENTER = 'Вход';

const GA_ENTER = 'Войти на главной';
const GA_CANT_SIGN = 'Не могу войти';
const GA_RECOVERY = 'Восстановить';
const YM_ENTER = 'enter';

/* Регистрация */
const CAT_REGISTRATION = 'Регистрация';

const GA_REGISTER = 'Зарегистрироваться';
const GA_REG_TYPE = 'Выбор типа регистрации';
const GA_REG_NEXT_STEP = 'Далее';
const GA_SEND_CODE = 'Выслать код';
const GA_REG_SUCCESS = 'Регистрация завершена';
const GA_MANAGER_REG_SUCCESS = 'Запрос на регистрацию отправлен';
const YM_REGISTER = 'register';
const YM_REG_SUCCESS = 'reg-finish';
const YM_MANAGER_REG_SUCCESS = 'manager-reg-finish';

/* Предложения */
const CAT_SUGGESTIONS = 'Предложения';

const GA_GET_SUGGESTIONS_SECTION = 'Предложения раздел';
const GA_SPOT_REQUESTS = 'Заявки спот';
const GA_GET_INSTANT = 'Беру';
const GA_GET_AUCTION = 'Торг';
const GA_RESERVE = 'Забронировать';
const GA_MAKE_BET = 'Сделать ставку';
const GA_CONFIRM_RESERVE = 'Подтверждение бронирования';
const GA_CHANGE_AUCTION = 'Предложить изменения - Торг';
const GA_CHANGE_INSTANT = 'Предложить изменения - Беру';
const GA_AUCTION_CHANGE_REQUEST = 'Отправить запрос - Торг';
const GA_INSTANT_CHANGE_REQUEST = 'Отправить запрос - Беру';
const GA_ASSIGN_RESOURCE = 'Назначить ресурс - окно';
const GA_ASSIGN_RESOURCE_SUCCESS = 'Назначить ресурс - отправка';
const YM_GET_INSTANT = 'beru';
const YM_GET_AUCTION = 'torg';

/* Персональные предложения */
const CAT_OFFERS = 'Персональные предложения';

const GA_OPEN_OFFERS = 'Персональные';
const GA_VIEW_OFFER = 'Принять';
const GA_GET_OFFER = 'Принять предложение';
const GA_SUBMIT_OFFER = 'Принять предложение - подтверждение';
const GA_REFUSE_OFFER = 'Отклонить';
const GA_CONFIRM_REJECTION_OF_OFFER = 'Отклонить - подтверждение';

/* Графики */
const CAT_GRAPHICS = 'Предложения - Графики';

const GA_BID = 'Торг/ПГ';
const GA_SET_BID = 'Сделать ставку/ПГ';
const GA_CHANGE_BET = 'Изменить ставку/ПГ';
const GA_EXIT_AUCTION = 'Выйти из торгов/ПГ';
const GA_FOLLOW = 'Следить/ПГ';
const GA_STOP_FOLLOW = 'Перестать следить/ПГ';
const GA_GRAPHICS_SECTION = 'Точные графики раздел';
const GA_SUGGESTIONS_GRAPHICS = 'Точные графики';
// общая YM цель на взаимодействие с разделом
const YM_GRAPHICS = 'stavka-grafiki';

/* Поиск заявки */
const CAT_SUGGESTION_SEARCH = 'Поиск заявки';

const GA_FROM = 'Откуда';
const GA_TO = 'Направление';
const GA_PERIOD = 'Период';
const GA_CIRC_ROUTE = 'Фильтр - Круговой маршрут';
const GA_BODYTYPE = 'Фильтр - Тип кузова';
const GA_TONNAGE = 'Фильтр - Тоннаж по заявке';
const GA_SEARCH_ID = 'Поиск по номеру заявки';
const GA_RADIUS_FROM = 'Радиус Откуда';
const GA_RADIUS_DIRECTION = 'Радиус Направление';
const YM_FROM = 'otkuda';

/* Мои ресурсы */
export const CAT_RESOURCES = 'Мои ресурсы';

const GA_ADD_RESOURCE = 'Добавить ресурс';
const GA_NEXT_STEP = 'Следующий шаг';
const GA_CHECK_RESOURCE = 'Проверить ресурс';
const GA_ADD_FROM_XLS = 'Добавить импорт из .xls';
const GA_DOWNLOAD_DRIVERS_TEMPLATE = 'Cкачать шаблон водителей';
const GA_DOWNLOAD_CARS_TEMPLATE = 'Cкачать шаблон автомашин';
const GA_DOWNLOAD_TRAILERS_TEMPLATE = 'Cкачать шаблон прицепов';
const GA_UPLOAD_DRIVERS_TEMPLATE = 'Загрузить файл с данными для водителей';
const GA_UPLOAD_CARS_TEMPLATE = 'Загрузить файл с данными для автомашин';
const GA_UPLOAD_TRAILERS_TEMPLATE = 'Загрузить файл с данными для прицепов';
const GA_RESOURCES_SECTION = 'Ресурсы раздел';
const YM_ADD_RESOURCE = 'resurs-add';
const YM_CHECK_RESOURCE = 'resurs-check';

/* Партнёры */
const CAT_TC_PARTNERS = 'Партнёры перевозчика';
const CAT_CO_PARTNERS = 'Партнёры грузовладельца';
/* TC */
const GA_ACCEPT_PARTNERSHIP = 'Принять условия';
const GA_STOP_PARTNERSHIP = 'Прекратить партнёрство';
const GA_TO_BLACKLIST = 'В чёрный список';
const GA_REJECT_PARTNER_REQUEST = 'Отклонить запрос';
const GA_NTK_PARTNERS_SECTION = 'Партнёры НТК раздел';
/* CO */
const GA_ADD_PARTNER = 'Добавить партнёра';
const GA_SEARCH_PARTNER = 'Отправить запрос на партнёрство';
const GA_SAVE_PARTNERS_GROUP = 'Сохранить группу партнёров';
const GA_CO_PARTNERS_SECTION = 'Партнёры ГВ раздел';
const GA_SEARCH_PARTNER_BY_ID = 'Найти партнёра';
/* Инструменты */
const CAT_TOOLS = 'Инструменты';

const GA_MARKET_PRICES = 'Сформировать список рыночных цен';
const GA_BUY_TARIFF = 'Купить тариф';
const GA_PASTE_FROM_TEMPLATE = 'Вставить направления из шаблона';
const GA_SAVE_TEMPLATE = 'Сохранить направления как шаблон';
const GA_DOWNLOAD_XLS = 'Скачать отчет в XLS';
const GA_ADD_ROUTE = 'Добавить направления';
const GA_TOOLS_SECTION = 'Инструменты раздел';

/* Претензии */
export const CAT_CLAIMS = 'Претензии';
const GA_CREATE_CLAIM = 'Создать претензию';
const GA_SEND_CLAIM = 'Отправить претензию';
const GA_CLAIMS_SECTION = 'Претензии раздел';
const GA_ACCEPT_CLAIM = 'Принять претензию';
const GA_ACCEPT_CLAIM_CONFIRM = 'Принять претензию - подтверждение';
const GA_REJECT_CLAIM = 'Отклонить претензию';
const GA_REJECT_CLAIM_CONFIRM = 'Отклонить претензию - подтверждение';

/* Профиль компании */
const CAT_PROFILE = 'Профиль компании';

const GA_SEND_FOR_VERIFICATION = 'Отправить на проверку';
const GA_SEND_AFTER_CORRECTION = 'Отправить на проверку после доработки';
const GA_SAVE_MAIN_INFO = 'Общая информация - сохранить';
const GA_SAVE_COMPANY_REQUISITES = 'Реквизиты компании - сохранить';
const GA_SAVE_PAYMENT_REQUISITES = 'Платёжные реквизиты - сохранить';
const GA_SEND_LICENSE_ACCEPT = 'Ознакомлен с договором присоединения';
const GA_PROFILE_SECTION = 'Профиль компании раздел';
const YM_SEND_FOR_VERIFICATION = 'proverka';

/* Техподдержка */
const CAT_SUPPORT = 'Техподдержка';
const GA_SUP_LABEL = 'Значок техподдержки';
const GA_INSTRUCTION_TC = 'Инструкция для перевозчика';
const GA_INSTRUCTION_CO = 'Инструкция для грузовладельца';

/* Заявки */
const CAT_REQUEST = 'Заявки';

const GA_CREATE_ORDER = 'Создать заявку';
const GA_APPROVE_TRIP = 'Утвердить перевозку';
const GA_CONFIRM_APPROVE = 'Утвердить перевозку - подтверждение';
const GA_CREATE_FROM_ID = 'Создать на основании';
const GA_CANCEL_REQUEST = 'Отменить заявку';
const GA_CANCEL_REQUEST_CONFIRM = 'Отменить заявку - подтверждение';
const GA_APPROVE_TRIP_END = 'Перевозка выполнена';
const GA_ARCHIVE_REQUEST = 'Отправить в архив';
const GA_STEP_TRANSPORT = 'Далее "Транспорт"';
const GA_STEP_ROUTES = 'Далее "Маршрут"';
const GA_STEP_AUCTION = 'Далее "Торг"';
const GA_STEP_CARGO = 'Далее "Груз"';
const GA_STEP_REGISTRATION = 'Оформить заявку';
const GA_ADD_AUCTION_STAGE = 'Добавить этап торга';
const GA_RM_AUCTION_STAGE = 'Удалить этап';
const GA_SAVE_AS_DRAFT = 'Сохранить как черновик';
const GA_SAVE_DRAFT_CONFIRM = 'Сохранить как черновик - подтверждение';
const GA_VOLUME_CONTRACT = 'Объемный контракт';
const GA_Q_SIGNATURE = 'Квалифицированная подпись';
const GA_Q_SIGNATURE_REQUEST = 'Квалифицированная подпись - заявка';
const GA_SELECT_AHEAD_OF_TIME = 'Досрочно выбрать победителя';
const GA_REJECT_AHEAD_OF_TIME_SELECTION = 'Досрочно выбрать победителя - Отмена';
const GA_SUBMIT_AHEAD_OF_TIME_SELECTION = 'Досрочно выбрать победителя - подтверждение';
const GA_ORDER_LIST_SECTION = 'Заявки раздел';
const GA_PENDING_ORDER_LIST = 'Заявки в торге';
const GA_IN_WORK_ORDER_LIST = 'Заявки на исполнении';
const GA_ARCHIVE_ORDER_LIST = 'Заявки в архиве';
const GA_DRAFT_ORDER_LIST = 'Черновики заявок';
const GA_FILL_FROM_DIRECTORY = 'Заполнить из справочника';
const GA_APPLY_ESTIMATED_TIME = 'Применить расчетное время';
const GA_SAVE_SENDER_ADDRESS = 'Сохранить адрес отправителя';
const YM_CREATE_ORDER = 'order';
const YM_CONFIRM_APPROVE = 'order-confirm';

/* Страховка */
const CAT_INSURANCE = 'Страховка';

const GA_GET_INSURANCE = 'Оформить страховку';
const GA_GET_INSURANCE_CONFIRM = 'Оформить страховку - подтверждение';
const GA_INSURANCE_AMOUNT = 'Сумма страховки';
const GA_REFUSE_INSURANCE = 'Отказаться от страховки';
const GA_REFUSE_INSURANCE_CONFIRM = 'Отказаться от страховки - подтверждение';
const GA_RENEW_INSURANCE = 'Дооформить страховку';
const GA_INSURANCE_CASE = 'Страховой случай';
const GA_CONFIRM_INSURED_EVENT = 'Подтвердить страховой случай';
const YM_GET_INSURANCE = 'oformit-srazu';
const YM_INSURANCE_AMOUNT = 'summa';
const YM_REFUSE_INSURANCE = 'refuse';
const YM_REFUSE_INSURANCE_CONFIRM = 'refuse-confirm';
const YM_RENEW_INSURANCE = 'oformit-posle';
const YM_INSURANCE_CASE = 'insurance-case';
const YM_CONFIRM_INSURED_EVENT = 'insurance-case-confirm';

/* Биллинг */
export const CAT_BILLING = 'Тарифы';

const GA_BILLING_SECTION = 'Пакеты и услуги раздел';

/* Уведомления */
const CAT_NOTIFICATIONS = 'Уведомления';

const GA_NOTIFICATION_SETTINGS = 'Настройка уведомлений';
const GA_ADD_NOTIFICATION = 'Добавить';
const GA_SAVED_NOTIFICATION_FILTERS = 'Сохранённые фильтры';
const GA_ADD_NOTIFICATION_FILTER = 'Добавить фильтр';
const GA_SUGGESTIONS_BY_FILTERS_NOTIFICATION = 'Предложение в торге, соответсвующее сохраненным фильтрам';
const GA_AUCTION_RESULT_NOTIFICATION = 'Результат торгов';
const GA_CHANGE_REQUEST_NOTIFICATION = 'Изменение требований по перевозке';
const GA_RESOURCE_ADDING_NOTIFICATION = 'Назначение ресурсов на заявку';
const GA_STATUS_INFO_NOTIFICATION = 'Информирование о статусе заявки';
const GA_PARTNERSHIP_NOTIFICATION = 'Партнерские отношения';
const GA_RESOURCE_ADMISSION_NOTIFICATION = 'Допуск ресурсов';

/* Объёмные графики НТК */
const CAT_VOLUME_GRAPHICS = 'Объёмные графики';

const GA_ENTER_VG = 'Объёмные графики';
const GA_GET_VG = 'Торг/ОГ';
const GA_MAKE_BET_VG = 'Сделать ставку/ОГ';
const GA_CHANGE_BET_VG = 'Изменить ставку/ОГ';
const GA_EXIT_AUCTION_VG = 'Выйти из аукциона/ОГ';
const GA_FOLLOW_VG = 'Следить/ОГ';
const GA_VOLUME_GRAPHICS_SECTION = 'Объёмные графики - раздел';

/* Изменение требований */
const CAT_SUGGESTION_CHANGE = 'Изменение требований';

const GA_SET_CHANGES = 'Внести изменения';
const GA_SET_CHANGES_CONFIRM = 'Внести изменения - подтверждение';
const GA_EXIT_WITHOUT_CHANGES = 'Закрыть без изменений';
const GA_CHANGES_HISTORY = 'Детализация истории изменений';
const GA_SUBMIT_CHANGE_REQUEST = 'Принять изменения требований';
const GA_REJECT_CHANGE_REQUEST = 'Отклонить изменения требований';
const GA_REASSIGN_RESOURCES = 'Переназначить ресурсы';
const GA_SEND_CHANGES_FOR_APPROVE = 'Отправить на утверждение';
const GA_AGREE_ON_RESOURCE_CHANGES = 'Согласовать изменения ресурсов';
const GA_REJECT_RESOURCE_CHANGES = 'Отклонить изменения ресурсов';

/* Переход из формы создания заявки в ПФЦ */
const CAT_FROM_ORDER_TO_PRICE_HELPER = 'Переход из формы создания заявки в ПФЦ';

const GA_DETAILS_ABOUT_HELPER = 'Подробнее о рыночных ценах';
const GA_VIEW_MARKET_PRICES = 'Показать рыночные цены';
const GA_DONT_VIEW_MARKET_PRICES = 'Отмена показа рыночных цен';
const GA_PRICE_HELPER = 'Помощник формирования цены';

/* Периодичность выбора победителя в заявке */
const CAT_WINNER_SELECTION_PERIOD = 'Периодичность выбора победителя в заявке';

const GA_AFTER_AUCTION_STAGE = 'По окончанию этапа';
const GA_BY_HALF_HOUR = 'Раз в 30 мин';
const GA_BY_HOUR = 'Раз в час';
const GA_BY_THREE_HOURS = 'Раз в 3 часа';
const GA_BY_FIVE_HOURS = 'Раз в 5 часов';

/* Контракты ГВ */
const CAT_CONTRACTS = 'Контракты';

const GA_CONTRACTS_SECTION = 'Контракты раздел';
const GA_PENDING_CONTRACTS = 'Контракты в торге';
const GA_IN_WORK_CONTRACTS = 'Контракты на исполнении';
const GA_ARCHIVED_CONTRACTS = 'Контракты в архиве';
const GA_DRAFT_CONTRACTS = 'Черновики контрактов';
const GA_SELECT_WINNER_FROM_DETAIL = 'Выбрать победителя';
const GA_SELECT_WINNER_FROM_REGISTRY = 'Выбрать';
const GA_SELECT_WINNER_CONFIRM = 'Выбрать - подтверждение';

/* Пользователи */
const CAT_USERS = 'Пользователи';

const GA_USERS_SECTION = 'Пользователи';
const GA_USERS_ROLES = 'Роли';
const GA_ADD_USER = 'Добавить пользователя';
const GA_SAVE_USER = 'Сохранить пользователя';
const GA_SEND_ACTIVATION_EMAIL = 'Отправить письмо для активации';
const GA_BLOCK_USER = 'Заблокировать пользователя';
const GA_BLOCK_USER_CONFIRM = 'Заблокировать пользователя - подтверждение';
const GA_UNBLOCK_USER = 'Разблокировать пользователя';
const GA_UNBLOCK_USER_CONFIRM = 'Разблокировать пользователя - подтверждение';
const GA_EDIT_USER = 'Редактировать пользователя';
const GA_SAVE_USER_EDITING = 'Сохранить редактирование пользователя';

/* Профиль пользователя */
const CAT_USER_PROFILE = 'Профиль пользователя';

const GA_USER_PROFILE_SECTION = 'Профиль пользователя';
const GA_USER_PROFILE = 'Профиль';
const GA_AUCTIONS_AND_REQUESTS = 'Торги и заказы';
const GA_AUTO_EXTEND_TRADING = 'Продлевать торг автоматически';
const GA_MANUAL_WINNER_SELECTION = 'Выбор победителя вручную';
const GA_WITHOUT_ATTORNEY = 'Без доверенности';
const GA_TYPICAL_M2_FORM = 'Типовая форма М-2';

/* Адреса */
const CAT_ADDRESSES = 'Адреса';

const GA_ADRESSES_SECTION = 'Адреса раздел';
const GA_ADD_ADDRESS = 'Добавить адрес';
const GA_ADD_CONTACT_PERSON = 'Добавить контактное лицо';
const GA_SAVE_ADDRESS = 'Сохранить адрес';

/* События */
/* Вход */
export const enterFromMainEvent: SendEvent = eventCreator(CAT_ENTER, GA_ENTER, YM_ENTER);
export const cantSignInEvent: SendEvent = eventCreator(CAT_ENTER, GA_CANT_SIGN);
export const recoveryEvent: SendEvent = eventCreator(CAT_ENTER, GA_RECOVERY);

/* Регистрация */
export const registerEvent: SendEvent = eventCreator(CAT_REGISTRATION, GA_REGISTER, YM_REGISTER);
export const registrationTypeEvent: SendEvent = eventCreator(CAT_REGISTRATION, GA_REG_TYPE);
export const registrationNextStepEvent: SendEvent = eventCreator(CAT_REGISTRATION, GA_REG_NEXT_STEP);
export const sendCodeEvent: SendEvent = eventCreator(CAT_REGISTRATION, GA_SEND_CODE);
export const registrationSuccessEvent: SendEvent = eventCreator(CAT_REGISTRATION, GA_REG_SUCCESS, YM_REG_SUCCESS);
export const managerRegistrationSuccessEvent: SendEvent = eventCreator(
  CAT_REGISTRATION,
  GA_MANAGER_REG_SUCCESS,
  YM_MANAGER_REG_SUCCESS,
);
/* Предложения */
export const suggestionsSectionEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_GET_SUGGESTIONS_SECTION);
export const getSuggestionsRequestsEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_SPOT_REQUESTS);
export const getInstantEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_GET_INSTANT, YM_GET_INSTANT);
export const getAuctionEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_GET_AUCTION, YM_GET_AUCTION);
export const reserveEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_RESERVE);
export const suggestionsMakeBetEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_MAKE_BET);
export const confirmReserveEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_CONFIRM_RESERVE);
export const changeInstantEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_CHANGE_INSTANT);
export const changeAuctionEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_CHANGE_AUCTION);
export const exitAuctionEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_EXIT_AUCTION);
export const sendAuctionChangeReqEvent = eventCreator(CAT_SUGGESTIONS, GA_AUCTION_CHANGE_REQUEST);
export const sendInstantChangeReqEvent = eventCreator(CAT_SUGGESTIONS, GA_INSTANT_CHANGE_REQUEST);
export const addingResourceEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_ASSIGN_RESOURCE);
export const addResourceSuccessEvent: SendEvent = eventCreator(CAT_SUGGESTIONS, GA_ASSIGN_RESOURCE_SUCCESS);
/* Графики */
export const graphicBidEvent: SendEvent = eventCreator(CAT_GRAPHICS, GA_BID, YM_GRAPHICS);
export const graphicMakeBetEvent: SendEvent = eventCreator(CAT_GRAPHICS, GA_SET_BID, YM_GRAPHICS);
export const graphicChangeBetEvent: SendEvent = eventCreator(CAT_GRAPHICS, GA_CHANGE_BET, YM_GRAPHICS);
export const graphicExitAuctionEvent: SendEvent = eventCreator(CAT_GRAPHICS, GA_EXIT_AUCTION);
export const graphicFollowEvent: SendEvent = eventCreator(CAT_GRAPHICS, GA_FOLLOW, YM_GRAPHICS);
export const graphicUnfollowEvent: SendEvent = eventCreator(CAT_GRAPHICS, GA_STOP_FOLLOW);
export const graphicsSectionEvent: SendEvent = eventCreator(CAT_GRAPHICS, GA_GRAPHICS_SECTION);
export const graphicsSuggestionsEvent: SendEvent = eventCreator(CAT_GRAPHICS, GA_SUGGESTIONS_GRAPHICS);
/* Поиск заявки */
export const suggestionsFromEvent: SendEvent = eventCreator(CAT_SUGGESTION_SEARCH, GA_FROM, YM_FROM);
export const suggestionsToEvent: SendEvent = eventCreator(CAT_SUGGESTION_SEARCH, GA_TO);
export const suggestionsChangePeriodEvent: SendEvent = eventCreator(CAT_SUGGESTION_SEARCH, GA_PERIOD);
export const suggestionsCircRouteEvent: SendEvent = eventCreator(CAT_SUGGESTION_SEARCH, GA_CIRC_ROUTE);
export const suggestionsBodyTypeEvent: SendEvent = eventCreator(CAT_SUGGESTION_SEARCH, GA_BODYTYPE);
export const suggestionsTonnageEvent: SendEvent = eventCreator(CAT_SUGGESTION_SEARCH, GA_TONNAGE);
export const suggestionsSearchIdEvent: SendEvent = eventCreator(CAT_SUGGESTION_SEARCH, GA_SEARCH_ID);
export const suggestionsFromRadiusEvent: SendEvent = eventCreator(CAT_SUGGESTION_SEARCH, GA_RADIUS_FROM);
export const suggestionsDirectionRadiusEvent: SendEvent = eventCreator(CAT_SUGGESTION_SEARCH, GA_RADIUS_DIRECTION);
/* Мои ресурсы */
export const resourcesAddEvent: SendEvent = eventCreator(CAT_RESOURCES, GA_ADD_RESOURCE, YM_ADD_RESOURCE);
export const resourcesNextStepEvent: SendEvent = eventCreator(CAT_RESOURCES, GA_NEXT_STEP);
export const resourcesCheckEvent: SendEvent = eventCreator(CAT_RESOURCES, GA_CHECK_RESOURCE, YM_CHECK_RESOURCE);
export const addResourceFromXlsEvent: SendEvent = eventCreator(CAT_RESOURCES, GA_ADD_FROM_XLS);
export const downloadDriversTemplateEvent: SendEvent = eventCreator(CAT_RESOURCES, GA_DOWNLOAD_DRIVERS_TEMPLATE);
export const downloadCarsTemplateEvent: SendEvent = eventCreator(CAT_RESOURCES, GA_DOWNLOAD_CARS_TEMPLATE);
export const downloadTrailersTemplateEvent: SendEvent = eventCreator(CAT_RESOURCES, GA_DOWNLOAD_TRAILERS_TEMPLATE);
export const uploadDriversTemplateEvent: SendEvent = eventCreator(CAT_RESOURCES, GA_UPLOAD_DRIVERS_TEMPLATE);
export const uploadCarsTemplateEvent: SendEvent = eventCreator(CAT_RESOURCES, GA_UPLOAD_CARS_TEMPLATE);
export const uploadTrailersTemplateEvent: SendEvent = eventCreator(CAT_RESOURCES, GA_UPLOAD_TRAILERS_TEMPLATE);
export const resourcesSectionEvent: SendEvent = eventCreator(CAT_RESOURCES, GA_RESOURCES_SECTION);
/* Партнёры перевозчика */
export const acceptPartnershipEvent: SendEvent = eventCreator(CAT_TC_PARTNERS, GA_ACCEPT_PARTNERSHIP);
export const stopPartnershipTCEvent: SendEvent = eventCreator(CAT_TC_PARTNERS, GA_STOP_PARTNERSHIP);
export const toBlackListTCEvent: SendEvent = eventCreator(CAT_TC_PARTNERS, GA_TO_BLACKLIST);
export const rejectPartnerRequestEvent: SendEvent = eventCreator(CAT_TC_PARTNERS, GA_REJECT_PARTNER_REQUEST);
export const ntkPartnersSectionEvent: SendEvent = eventCreator(CAT_TC_PARTNERS, GA_NTK_PARTNERS_SECTION);
/* Партнёры грузовладельца */
export const addPartnerEvent: SendEvent = eventCreator(CAT_CO_PARTNERS, GA_ADD_PARTNER);
export const searchPartnerEvent: SendEvent = eventCreator(CAT_CO_PARTNERS, GA_SEARCH_PARTNER);
export const savePartnersGroupEvent: SendEvent = eventCreator(CAT_CO_PARTNERS, GA_SAVE_PARTNERS_GROUP);
export const stopPartnershipCOEvent: SendEvent = eventCreator(CAT_CO_PARTNERS, GA_STOP_PARTNERSHIP);
export const toBlackListCOEvent: SendEvent = eventCreator(CAT_CO_PARTNERS, GA_TO_BLACKLIST);
export const coPartnersSectionEvent: SendEvent = eventCreator(CAT_CO_PARTNERS, GA_CO_PARTNERS_SECTION);
export const searchPartnerByIdEvent: SendEvent = eventCreator(CAT_CO_PARTNERS, GA_SEARCH_PARTNER_BY_ID);
/* Инструменты */
export const getMarketPricesEvent: SendEvent = eventCreator(CAT_TOOLS, GA_MARKET_PRICES);
export const buyTariffEvent: SendEvent = eventCreator(CAT_TOOLS, GA_BUY_TARIFF);
export const pasteFromTemplateEvent: SendEvent = eventCreator(CAT_TOOLS, GA_PASTE_FROM_TEMPLATE);
export const saveTemplateEvent: SendEvent = eventCreator(CAT_TOOLS, GA_SAVE_TEMPLATE);
export const downloadXlsEvent: SendEvent = eventCreator(CAT_TOOLS, GA_DOWNLOAD_XLS);
export const addRouteEvent: SendEvent = eventCreator(CAT_TOOLS, GA_ADD_ROUTE);
export const toolsSectionEvent: SendEvent = eventCreator(CAT_TOOLS, GA_TOOLS_SECTION);
/* Претензии */
export const createClaimEvent: SendEvent = eventCreator(CAT_CLAIMS, GA_CREATE_CLAIM);
export const sendClaimEvent: SendEvent = eventCreator(CAT_CLAIMS, GA_SEND_CLAIM);
export const claimsSectionEvent: SendEvent = eventCreator(CAT_CLAIMS, GA_CLAIMS_SECTION);
export const acceptClaimEvent: SendEvent = eventCreator(CAT_CLAIMS, GA_ACCEPT_CLAIM);
export const confirmAcceptClaimEvent: SendEvent = eventCreator(CAT_CLAIMS, GA_ACCEPT_CLAIM_CONFIRM);
export const rejectClaimEvent: SendEvent = eventCreator(CAT_CLAIMS, GA_REJECT_CLAIM);
export const confirmRejectClaimEvent: SendEvent = eventCreator(CAT_CLAIMS, GA_REJECT_CLAIM_CONFIRM);
/* Профиль компании */
export const sendForVerificationEvent: SendEvent = eventCreator(
  CAT_PROFILE,
  GA_SEND_FOR_VERIFICATION,
  YM_SEND_FOR_VERIFICATION,
);
export const sendAfterCorrectionEvent: SendEvent = eventCreator(CAT_PROFILE, GA_SEND_AFTER_CORRECTION);
export const saveMainInfoEvent: SendEvent = eventCreator(CAT_PROFILE, GA_SAVE_MAIN_INFO);
export const saveCompanyRequisitesEvent: SendEvent = eventCreator(CAT_PROFILE, GA_SAVE_COMPANY_REQUISITES);
export const savePaymentRequisitesEvent: SendEvent = eventCreator(CAT_PROFILE, GA_SAVE_PAYMENT_REQUISITES);
export const sendLisenceAcceptEvent: SendEvent = eventCreator(CAT_PROFILE, GA_SEND_LICENSE_ACCEPT);
export const profileSectionEvent: SendEvent = eventCreator(CAT_PROFILE, GA_PROFILE_SECTION);
/* Техподдержка */
export const supportLabelEvent: SendEvent = eventCreator(CAT_SUPPORT, GA_SUP_LABEL);
export const instructionTCEvent: SendEvent = eventCreator(CAT_SUPPORT, GA_INSTRUCTION_TC);
export const instructionCOEvent: SendEvent = eventCreator(CAT_SUPPORT, GA_INSTRUCTION_CO);
/* Заявки */
export const createOrderEvent: SendEvent = eventCreator(CAT_REQUEST, GA_CREATE_ORDER, YM_CREATE_ORDER);
export const approveTripEvent: SendEvent = eventCreator(CAT_REQUEST, GA_APPROVE_TRIP);
export const confirmApproveEvent: SendEvent = eventCreator(CAT_REQUEST, GA_CONFIRM_APPROVE, YM_CONFIRM_APPROVE);
export const createFromIdEvent: SendEvent = eventCreator(CAT_REQUEST, GA_CREATE_FROM_ID);
export const cancelRequestEvent: SendEvent = eventCreator(CAT_REQUEST, GA_CANCEL_REQUEST);
export const archiveRequestEvent: SendEvent = eventCreator(CAT_REQUEST, GA_ARCHIVE_REQUEST);
export const cancelRequestConfirmEvent: SendEvent = eventCreator(CAT_REQUEST, GA_CANCEL_REQUEST_CONFIRM);
export const approveTripEndEvent: SendEvent = eventCreator(CAT_REQUEST, GA_APPROVE_TRIP_END);
export const nextStepTransportEvent: SendEvent = eventCreator(CAT_REQUEST, GA_STEP_TRANSPORT);
export const nextStepRoutesEvent: SendEvent = eventCreator(CAT_REQUEST, GA_STEP_ROUTES);
export const nextStepAuctionEvent: SendEvent = eventCreator(CAT_REQUEST, GA_STEP_AUCTION);
export const nextStepCargoEvent: SendEvent = eventCreator(CAT_REQUEST, GA_STEP_CARGO);
export const makeRequestEvent: SendEvent = eventCreator(CAT_REQUEST, GA_STEP_REGISTRATION);
export const addAuctionStageEvent: SendEvent = eventCreator(CAT_REQUEST, GA_ADD_AUCTION_STAGE);
export const removeAuctionStageEvent: SendEvent = eventCreator(CAT_REQUEST, GA_RM_AUCTION_STAGE);
export const saveDraftEvent: SendEvent = eventCreator(CAT_REQUEST, GA_SAVE_AS_DRAFT);
export const saveDraftConfirmEvent: SendEvent = eventCreator(CAT_REQUEST, GA_SAVE_DRAFT_CONFIRM);
export const volumeContractEvent: SendEvent = eventCreator(CAT_REQUEST, GA_VOLUME_CONTRACT);
export const qualifiedSignatureEvent: SendEvent = eventCreator(CAT_REQUEST, GA_Q_SIGNATURE);
export const qualifiedSignatureRequestEvent: SendEvent = eventCreator(CAT_REQUEST, GA_Q_SIGNATURE_REQUEST);
export const selectWinnerAheadOfTimeEvent: SendEvent = eventCreator(CAT_REQUEST, GA_SELECT_AHEAD_OF_TIME);
export const rejectAheadOfTimeWinnerSelectEvent: SendEvent = eventCreator(
  CAT_REQUEST,
  GA_REJECT_AHEAD_OF_TIME_SELECTION,
);
export const submitAheadOfTimeWinnerSelectEvent: SendEvent = eventCreator(
  CAT_REQUEST,
  GA_SUBMIT_AHEAD_OF_TIME_SELECTION,
);
export const orderListSectionEvent: SendEvent = eventCreator(CAT_REQUEST, GA_ORDER_LIST_SECTION);
export const pendingRequestsEvent: SendEvent = eventCreator(CAT_REQUEST, GA_PENDING_ORDER_LIST);
export const inWorkRequestsEvent: SendEvent = eventCreator(CAT_REQUEST, GA_IN_WORK_ORDER_LIST);
export const arhivedRequestsEvent: SendEvent = eventCreator(CAT_REQUEST, GA_ARCHIVE_ORDER_LIST);
export const draftRequestsEvent: SendEvent = eventCreator(CAT_REQUEST, GA_DRAFT_ORDER_LIST);
export const fillFromDirectoryEvent: SendEvent = eventCreator(CAT_REQUEST, GA_FILL_FROM_DIRECTORY);
export const applyEstimatedTimeEvent: SendEvent = eventCreator(CAT_REQUEST, GA_APPLY_ESTIMATED_TIME);
export const saveSenderAddressEvent: SendEvent = eventCreator(CAT_REQUEST, GA_SAVE_SENDER_ADDRESS);
/* Страховка */
export const getInsuranceEvent: SendEvent = eventCreator(CAT_INSURANCE, GA_GET_INSURANCE, YM_GET_INSURANCE);
export const getInsuranceConfirmEvent: SendEvent = eventCreator(CAT_INSURANCE, GA_GET_INSURANCE_CONFIRM);
export const insuranceAmountEvent: SendEvent = eventCreator(CAT_INSURANCE, GA_INSURANCE_AMOUNT, YM_INSURANCE_AMOUNT);
export const refuseInsuranceEvent: SendEvent = eventCreator(CAT_INSURANCE, GA_REFUSE_INSURANCE, YM_REFUSE_INSURANCE);
export const refuseInsuranceConfirmEvent: SendEvent = eventCreator(
  CAT_INSURANCE,
  GA_REFUSE_INSURANCE_CONFIRM,
  YM_REFUSE_INSURANCE_CONFIRM,
);
export const renewInsuranceEvent: SendEvent = eventCreator(CAT_INSURANCE, GA_RENEW_INSURANCE, YM_RENEW_INSURANCE);
export const insuranceCaseEvent: SendEvent = eventCreator(CAT_INSURANCE, GA_INSURANCE_CASE, YM_INSURANCE_CASE);
export const confirmInsuredEvent: SendEvent = eventCreator(
  CAT_INSURANCE,
  GA_CONFIRM_INSURED_EVENT,
  YM_CONFIRM_INSURED_EVENT,
);

/* Уведомления */
export const openNotificationSettingsEvent: SendEvent = eventCreator(CAT_NOTIFICATIONS, GA_NOTIFICATION_SETTINGS);
export const addNotificationEvent: SendEvent = eventCreator(CAT_NOTIFICATIONS, GA_ADD_NOTIFICATION);
export const savedNotificationFiltersEvent: SendEvent = eventCreator(CAT_NOTIFICATIONS, GA_SAVED_NOTIFICATION_FILTERS);
export const addNotificationFilterEvent: SendEvent = eventCreator(CAT_NOTIFICATIONS, GA_ADD_NOTIFICATION_FILTER);
export const suggestionsByFiltersCheckEvent: SendEvent = eventCreator(
  CAT_NOTIFICATIONS,
  GA_SUGGESTIONS_BY_FILTERS_NOTIFICATION,
);
export const auctionResultCheckEvent: SendEvent = eventCreator(CAT_NOTIFICATIONS, GA_AUCTION_RESULT_NOTIFICATION);
export const changeRequestCheckEvent: SendEvent = eventCreator(CAT_NOTIFICATIONS, GA_CHANGE_REQUEST_NOTIFICATION);
export const resoureAddingCheckEvent: SendEvent = eventCreator(CAT_NOTIFICATIONS, GA_RESOURCE_ADDING_NOTIFICATION);
export const statusInfoCheckEvent: SendEvent = eventCreator(CAT_NOTIFICATIONS, GA_STATUS_INFO_NOTIFICATION);
export const partnershipsCheckEvent: SendEvent = eventCreator(CAT_NOTIFICATIONS, GA_PARTNERSHIP_NOTIFICATION);
export const resourceAdmissionCheckEvent: SendEvent = eventCreator(
  CAT_NOTIFICATIONS,
  GA_RESOURCE_ADMISSION_NOTIFICATION,
);
/* Объёмные графики НТК */
export const enterVolumeGraphicsEvent: SendEvent = eventCreator(CAT_VOLUME_GRAPHICS, GA_ENTER_VG);
export const getVolumeGraphicEvent: SendEvent = eventCreator(CAT_VOLUME_GRAPHICS, GA_GET_VG);
export const makeBetVolumeGraphicEvent: SendEvent = eventCreator(CAT_VOLUME_GRAPHICS, GA_MAKE_BET_VG);
export const changeBetVolumeGraphicEvent: SendEvent = eventCreator(CAT_VOLUME_GRAPHICS, GA_CHANGE_BET_VG);
export const exitAuctionVolumeGraphicEvent: SendEvent = eventCreator(CAT_VOLUME_GRAPHICS, GA_EXIT_AUCTION_VG);
export const followVolumeGraphicEvent: SendEvent = eventCreator(CAT_VOLUME_GRAPHICS, GA_FOLLOW_VG);
export const sectionVolumeGraphicEvent: SendEvent = eventCreator(CAT_VOLUME_GRAPHICS, GA_VOLUME_GRAPHICS_SECTION);
/* Изменение требований */
export const createChangeRequestEvent: SendEvent = eventCreator(CAT_SUGGESTION_CHANGE, GA_SET_CHANGES);
export const changeRequestConfirmEvent: SendEvent = eventCreator(CAT_SUGGESTION_CHANGE, GA_SET_CHANGES_CONFIRM);
export const exitWithoutChangesEvent: SendEvent = eventCreator(CAT_SUGGESTION_CHANGE, GA_EXIT_WITHOUT_CHANGES);
export const viewHistoryOfChangesEvent: SendEvent = eventCreator(CAT_SUGGESTION_CHANGE, GA_CHANGES_HISTORY);
export const submitChangeRequestEvent: SendEvent = eventCreator(CAT_SUGGESTION_CHANGE, GA_SUBMIT_CHANGE_REQUEST);
export const reassignResourcesEvent: SendEvent = eventCreator(CAT_SUGGESTION_CHANGE, GA_REASSIGN_RESOURCES);
export const sendResourcesChangesEvent: SendEvent = eventCreator(CAT_SUGGESTION_CHANGE, GA_SEND_CHANGES_FOR_APPROVE);
export const agreeOnResourcesChangesEvent: SendEvent = eventCreator(
  CAT_SUGGESTION_CHANGE,
  GA_AGREE_ON_RESOURCE_CHANGES,
);
export const rejectResourcesChangesEvent: SendEvent = eventCreator(CAT_SUGGESTION_CHANGE, GA_REJECT_RESOURCE_CHANGES);
export const rejectChangeRequestEvent: SendEvent = eventCreator(CAT_SUGGESTION_CHANGE, GA_REJECT_CHANGE_REQUEST);
/* Переход из формы создания заявки в ПФЦ */
export const viewPriceHelperDetailsEvent: SendEvent = eventCreator(
  CAT_FROM_ORDER_TO_PRICE_HELPER,
  GA_DETAILS_ABOUT_HELPER,
);
export const viewMarketPricesEvent: SendEvent = eventCreator(CAT_FROM_ORDER_TO_PRICE_HELPER, GA_VIEW_MARKET_PRICES);
export const dontViewMarketPricesEvent: SendEvent = eventCreator(
  CAT_FROM_ORDER_TO_PRICE_HELPER,
  GA_DONT_VIEW_MARKET_PRICES,
);
export const marketPricesEvent: SendEvent = eventCreator(CAT_FROM_ORDER_TO_PRICE_HELPER, GA_PRICE_HELPER);
/* Периодичность выбора победителя в заявке */
export const selectWinnerAfterStageEndEvent: SendEvent = eventCreator(
  CAT_WINNER_SELECTION_PERIOD,
  GA_AFTER_AUCTION_STAGE,
);
export const selectWinnerByHalfHourEvent: SendEvent = eventCreator(CAT_WINNER_SELECTION_PERIOD, GA_BY_HALF_HOUR);
export const selectWinnerByHourEvent: SendEvent = eventCreator(CAT_WINNER_SELECTION_PERIOD, GA_BY_HOUR);
export const selectWinnerByThreeHoursEvent: SendEvent = eventCreator(CAT_WINNER_SELECTION_PERIOD, GA_BY_THREE_HOURS);
export const selectWinnerByFiveHoursEvent: SendEvent = eventCreator(CAT_WINNER_SELECTION_PERIOD, GA_BY_FIVE_HOURS);
/* Персональные предложения */
export const openSuggestionOffersEvent: SendEvent = eventCreator(CAT_OFFERS, GA_OPEN_OFFERS);
export const viewOffersEvent: SendEvent = eventCreator(CAT_OFFERS, GA_VIEW_OFFER);
export const getOfferEvent: SendEvent = eventCreator(CAT_OFFERS, GA_GET_OFFER);
export const submitOfferEvent: SendEvent = eventCreator(CAT_OFFERS, GA_SUBMIT_OFFER);
export const refuseOfferEvent: SendEvent = eventCreator(CAT_OFFERS, GA_REFUSE_OFFER);
export const confirmRejectionOfferEvent: SendEvent = eventCreator(CAT_OFFERS, GA_CONFIRM_REJECTION_OF_OFFER);
/* Контракты ГВ */
export const contractsSectionEvent: SendEvent = eventCreator(CAT_CONTRACTS, GA_CONTRACTS_SECTION);
export const contractsPendingEvent: SendEvent = eventCreator(CAT_CONTRACTS, GA_PENDING_CONTRACTS);
export const contractsInWorkEvent: SendEvent = eventCreator(CAT_CONTRACTS, GA_IN_WORK_CONTRACTS);
export const contractsArchivedEvent: SendEvent = eventCreator(CAT_CONTRACTS, GA_ARCHIVED_CONTRACTS);
export const contractsDraftEvent: SendEvent = eventCreator(CAT_CONTRACTS, GA_DRAFT_CONTRACTS);
export const selectWinnerFromDetailEvent: SendEvent = eventCreator(CAT_CONTRACTS, GA_SELECT_WINNER_FROM_DETAIL);
export const selectWinnerFromReqistryEvent: SendEvent = eventCreator(CAT_CONTRACTS, GA_SELECT_WINNER_FROM_REGISTRY);
export const selectWinnerConfirmEvent: SendEvent = eventCreator(CAT_CONTRACTS, GA_SELECT_WINNER_CONFIRM);
/* Пакеты и услуги */
export const billingSectionEvent: SendEvent = eventCreator(CAT_BILLING, GA_BILLING_SECTION);
/* Пользователи */
export const usersSectionEvent: SendEvent = eventCreator(CAT_USERS, GA_USERS_SECTION);
export const rolesEvent: SendEvent = eventCreator(CAT_USERS, GA_USERS_ROLES);
export const addUserEvent: SendEvent = eventCreator(CAT_USERS, GA_ADD_USER);
export const saveUserEvent: SendEvent = eventCreator(CAT_USERS, GA_SAVE_USER);
export const sendActivationEmailEvent: SendEvent = eventCreator(CAT_USERS, GA_SEND_ACTIVATION_EMAIL);
export const blockUserEvent: SendEvent = eventCreator(CAT_USERS, GA_BLOCK_USER);
export const blockUserConfirmEvent: SendEvent = eventCreator(CAT_USERS, GA_BLOCK_USER_CONFIRM);
export const unblockUserEvent: SendEvent = eventCreator(CAT_USERS, GA_UNBLOCK_USER);
export const unblockUserConfirmEvent: SendEvent = eventCreator(CAT_USERS, GA_UNBLOCK_USER_CONFIRM);
export const editUserEvent: SendEvent = eventCreator(CAT_USERS, GA_EDIT_USER);
export const saveUserEditingEvent: SendEvent = eventCreator(CAT_USERS, GA_SAVE_USER_EDITING);
/* Адреса */
export const addressesSectionEvent: SendEvent = eventCreator(CAT_ADDRESSES, GA_ADRESSES_SECTION);
export const addAddressEvent: SendEvent = eventCreator(CAT_ADDRESSES, GA_ADD_ADDRESS);
export const addContactPersonEvent: SendEvent = eventCreator(CAT_ADDRESSES, GA_ADD_CONTACT_PERSON);
export const saveAddressEvent: SendEvent = eventCreator(CAT_ADDRESSES, GA_SAVE_ADDRESS);
/* Профиль пользователя */
export const userProfileSectionEvent: SendEvent = eventCreator(CAT_USER_PROFILE, GA_USER_PROFILE_SECTION);
export const userProfileEvent: SendEvent = eventCreator(CAT_USER_PROFILE, GA_USER_PROFILE);
export const auctionsAndRequestsEvent: SendEvent = eventCreator(CAT_USER_PROFILE, GA_AUCTIONS_AND_REQUESTS);
export const autoExtendTradingEvent: SendEvent = eventCreator(CAT_USER_PROFILE, GA_AUTO_EXTEND_TRADING);
export const manualWinnerSelectionEvent: SendEvent = eventCreator(CAT_USER_PROFILE, GA_MANUAL_WINNER_SELECTION);
export const withoutAttorneyEvent: SendEvent = eventCreator(CAT_USER_PROFILE, GA_WITHOUT_ATTORNEY);
export const typicalMTwoFormEvent: SendEvent = eventCreator(CAT_USER_PROFILE, GA_TYPICAL_M2_FORM);
