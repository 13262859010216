import React from 'react';
import SVGIcon from './index';
import Tooltip from '../../Tooltip';

function IconReservationRequest(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <Tooltip content="Заявка на бронирование ТС">
      <SVGIcon {...props} viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          d="M15 5c-3.86 0-7 3.14-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm.88 13.75h-1.75V17h1.75v1.75zm0-2.87h-1.75c0-2.84 2.62-2.62 2.62-4.38 0-.96-.79-1.75-1.75-1.75s-1.75.79-1.75 1.75H11.5C11.5 9.57 13.07 8 15 8s3.5 1.57 3.5 3.5c0 2.19-2.62 2.41-2.62 4.38z"
        />
      </SVGIcon>
    </Tooltip>
  );
}
IconReservationRequest.defaultProps = {
  color: 'base',
};

export default React.memo(IconReservationRequest);
