const normalizeNum =
  (numbersAfterComma: number = 2, numbersBeforeComma: number = 20) =>
  (value?: string): string | undefined => {
    if (!value) return value;

    const onlyNum: string = value
      .replace(/[,.]/g, '.')
      .replace(/^(0{2,}|0(?=[1-9]))|[^\d.]/g, '')
      .replace(/^\./, '0.');

    let [start, end]: string[] = onlyNum.split('.');

    start = start.substring(0, numbersBeforeComma);

    if (end) {
      end = end.substring(0, numbersAfterComma);

      return start + '.' + end;
    }

    if (value.endsWith('.') || value.endsWith(',')) {
      return start + '.';
    }

    return start;
  };

export default normalizeNum;
