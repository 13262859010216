import React, { useCallback, useMemo } from 'react';
import { useFormikContextSelector } from '../FormikContext';
import { useFieldValue } from '../hooks';
import { BaseSwitcher, TSwitcherProps } from '../../Switcher';

interface IProps extends TSwitcherProps {
  validate?: (any: any) => void;
  onChangeCallback?: (value: string) => void;
}

function FormSwitcher(props: IProps) {
  const setFieldValue = useFormikContextSelector((c) => c.setFieldValue);
  const [value] = useFieldValue(props.name!);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(props.name!, event.target.value);
      props?.onChangeCallback?.(event.target.value);
    },
    [props, setFieldValue],
  );

  const inputProps = useMemo(
    () => ({
      validate: props.validate,
      className: 'visually-hidden',
    }),
    [props.validate],
  );

  return (
    <BaseSwitcher
      data={props.data}
      name={props.name}
      onChange={onChange}
      checked={value}
      className={props.className}
      disabled={props.disabled}
      inputProps={inputProps}
    />
  );
}

export default FormSwitcher;
