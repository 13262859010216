import React from 'react';
import SVGIcon from './index';

const IconArrowCircle = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M15.94 8.98275L17 5.22527L15.1824 6.03499C13.9879 4.49406 12.1117 3.5 10.0015 3.5C7.15487 3.5 4.73452 5.30818 3.8375 7.83029L4.78557 8.1633C5.54502 6.02796 7.59426 4.49929 10.0015 4.49929C11.6994 4.49929 13.219 5.25945 14.234 6.45747L12.4122 7.26906L15.94 8.98275Z" />
      <path d="M9.99852 15.5007C12.4057 15.5007 14.455 13.972 15.2144 11.8367L16.1625 12.1697C15.2655 14.6918 12.8451 16.5 9.99852 16.5C7.88832 16.5 6.01211 15.5059 4.81764 13.965L3 14.7747L4.05998 11.0173L7.58784 12.7309L5.76601 13.5425C6.781 14.7405 8.3006 15.5007 9.99852 15.5007Z" />
    </SVGIcon>
  );
};

IconArrowCircle.defaultProps = {
  color: 'base',
};

export default IconArrowCircle;
