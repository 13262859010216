import * as claimsHelpers from './helpers';
import { ACCEPTED_DOC_FILE_TYPES, ACCEPTED_UPLOAD_FILE_TYPES } from '../../constants';
import { getClaimStatusTranslation } from '../../components/DataListCollections/_helpers/getClaimStatusTranslation';

// разновидности претензий
export const INCOMING = 'incoming';
export const OUTCOMING = 'outcoming';

// типы действий с входящими претензиями
export const APPROVE_URL = 'approve';
export const REJECT_URL = 'reject';
export const CLARIFICATION_URL = 'clarification';

// id документов по входящим претензиям
export const APPROVE_DOC = 36;
export const REJECT_DOC = 37;
export const OTHER_DOC = 38;
export const SHIPPING_ORDER_DOC = 44;
export const FORWARDER_OTHER_DOC = 46;

export const CLAIM_SOLUTION = 31;
export const REVISION_REQUEST = 39;
export const FORWARDER_CLAIM_SOLUTION = 35;

// id претензий, приходящих от 1С
export const EXTRA_CLAIMS_ID = [CLAIM_SOLUTION, REVISION_REQUEST, FORWARDER_CLAIM_SOLUTION];

// разновидности с переводом
export const CLAIMS_KINDS = [
  {
    label: 'Входящие',
    value: INCOMING,
  },
  {
    label: 'Исходящие',
    value: OUTCOMING,
  },
];

// статусы претензии
export const NEW = 'new';
export const PENDING_REVIEW = 'pending_review';
export const IN_PROGRESS = 'in_progress_by_manager';
export const NEED_FIX = 'need_fix_by_user';
export const ACCEPT = 'accept';
export const PARTIAL_ACCEPT = 'partial_accept';
export const ACCEPT_UNILATERALLY = 'accept_unilaterally';
export const ACCEPT_EXPIRATION = 'accept_expiration';
export const REJECT = 'reject';
export const DONE = 'done';
export const PENDING_TC_REVIEW = 'pending_tc_review';
export const CANCELED = 'canceled';

const OUTCOMING_STATUSES = [PENDING_REVIEW, NEW, DONE, ACCEPT, NEED_FIX, REJECT];
const INCOMING_STATUSES = [
  PENDING_TC_REVIEW,
  REJECT,
  NEED_FIX,
  ACCEPT,
  ACCEPT_UNILATERALLY,
  ACCEPT_EXPIRATION,
  DONE,
  CANCELED,
];

const _getStatusModel = (type) => (status) => ({
  label: getClaimStatusTranslation(type, status),
  value: status,
});

// массив исходящих статусов с переводом
export const OUTCOME_STATUSES_OBJ = OUTCOMING_STATUSES.map(_getStatusModel('outcoming'));
export const INCOMING_STATUSES_OBJ = INCOMING_STATUSES.map(_getStatusModel('incoming'));

// типы претензий
export const IDLE = 'idle';
export const LOADING_FAIL = 'loading_failure';
export const OTHER = 'other';
export const LATENESS = 'lateness';
export const DOCUMENTS = 'documents';
export const DAMAGE = 'damage';

export const OUTCOMING_TYPES = [IDLE, LOADING_FAIL, OTHER];
export const INCOMING_TYPES = [LATENESS, LOADING_FAIL, OTHER];
// export const INCOMING_TYPES = [LOADING_FAIL, LATENESS, DOCUMENTS, DAMAGE];

// типы претензий с переводом
export const OUTCOMING_TYPES_OBJ = OUTCOMING_TYPES.map(_getTypeModel);
export const INCOMING_TYPES_OBJ = INCOMING_TYPES.map(_getTypeModel);

function _getTypeModel(type) {
  return {
    label: claimsHelpers.getClaimTypeTranslation(type),
    value: type,
  };
}

// query параметры
export const KIND = 'kind';
export const STATUS = 'status';
export const CREATED_AT_FROM = 'created_at_from';
export const CREATED_AT_TO = 'created_at_to';
export const HUMAN_FRIENDLY_ID = 'human_friendly_id';
export const TYPE = 'type';

// поля формы
export const FORM_NAME = 'claimForm';
export const TYPE_FIELD = 'type';
export const SHIPPING_HFID_FIELD = 'shipping_hfid';
export const SHIPPING_ID_FIELD = 'shipping_id';
export const PRICE_FIELD = 'price';

export const ACCEPTED_FILE_TYPES = [...ACCEPTED_UPLOAD_FILE_TYPES, ...ACCEPTED_DOC_FILE_TYPES];
