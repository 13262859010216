export const TAX_BOTH = 'all';
export const TAX_WITH = 'with_vat';
export const TAX_WITHOUT = 'without_vat';
export type TAX_TYPES = typeof TAX_WITHOUT | typeof TAX_WITH | typeof TAX_BOTH;

/* участники заявки */
export const ALL_PEOPLE = 'all';
export const PARTNERS = 'partners';
export const EXCLUSIVE = 'exclusive';
export const C_EXCLUSIVE = 'c_exclusive';
export type VISIBILITY_TYPES = typeof ALL_PEOPLE | typeof PARTNERS | typeof EXCLUSIVE | typeof C_EXCLUSIVE;

export const GROUPS = 'groups';
export const COMPANIES = 'companies';

/* типы акционов */
export const OPEN_AUCTION = 'auction'; // Открытый
export const DESCENDING_PRICE_AUCTION = 'descending_price_auction'; // На понижение
export const BLIND_AUCTION = 'blind_auction'; // Закрытый
export type AUCTION_SUBTYPES = typeof OPEN_AUCTION | typeof DESCENDING_PRICE_AUCTION | typeof BLIND_AUCTION;
