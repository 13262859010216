export const CARGO = 'cargo';
export const DOCS = 'docs';

export type TCargoDocs = typeof CARGO | typeof DOCS;

export const LAW = 'law';
export const BACKEND_LAW = 'charter';
export const PROXY = 'proxy';
export const BACKEND_PROXY = 'power_of_attorney';
export const CERTIFICATE = 'certificate';

export type TBasisForSign = typeof LAW | typeof BACKEND_LAW | typeof PROXY | typeof BACKEND_PROXY | typeof CERTIFICATE;

export const WITHOUT_TAX = 'withoutTax';
export const WITH_TAX = 'withTax';

export type TTaxType = typeof WITHOUT_TAX | typeof WITH_TAX;

export const WITH_VAT = 'with_vat';
export const WITHOUT_VAT = 'without_vat';

export type TTaxBackType = typeof WITHOUT_VAT | typeof WITH_VAT;
/* Attorney */
export const WITHOUT = 'withoutPowerOfAttorney';
export const M2 = 'm2';
