import {
  ORDERS_ARCHIVE,
  ORDERS_DRAFT,
  ORDERS_IN_WORK,
  ORDERS_PENDING,
} from '../../features/matomo-analytics/eventCategoryConstants';
import { EOrderStatuses } from '../../constants/order';

export enum EOrdersTabs {
  PENDING = 'pending',
  IN_WORK = 'in_work',
  ARCHIVE = 'archive',
  DRAFT = 'draft',
}

const getOrderPath = (key: EOrdersTabs): string => `/order-list/${key}`;

type TTabParams = {
  key: EOrdersTabs;
  label: string;
  path: string;
};
export const TABS: TTabParams[] = [
  {
    key: EOrdersTabs.PENDING,
    label: 'В торге',
    path: getOrderPath(EOrdersTabs.PENDING),
  },
  {
    key: EOrdersTabs.IN_WORK,
    label: 'На исполнении',
    path: getOrderPath(EOrdersTabs.IN_WORK),
  },
  {
    key: EOrdersTabs.ARCHIVE,
    label: 'В архиве',
    path: getOrderPath(EOrdersTabs.ARCHIVE),
  },
  {
    key: EOrdersTabs.DRAFT,
    label: 'Черновики',
    path: getOrderPath(EOrdersTabs.DRAFT),
  },
];

type TTabsParamsMap = {
  [key in EOrdersTabs]: TTabParams;
};
export const TABS_MAP = TABS.reduce((acc, tabParams) => {
  return { ...acc, [tabParams.key]: tabParams };
}, {} as TTabsParamsMap);

type TOrderStatusesTree = { [key in EOrdersTabs]: EOrderStatuses[] };
export const ORDER_STATUSES_TREE: TOrderStatusesTree = {
  [EOrdersTabs.PENDING]: [
    EOrderStatuses.AUCTION_WAIT,
    EOrderStatuses.IN_AUCTION,
    EOrderStatuses.RESERVED,
    EOrderStatuses.APPROVAL,
    EOrderStatuses.WINNER_SELECTION,

    EOrderStatuses.EXPIRED,
    EOrderStatuses.CANCELED_BY_NTK,
    EOrderStatuses.FAILED_BY_NTK,
  ],
  [EOrdersTabs.IN_WORK]: [EOrderStatuses.TRIP_WAITING, EOrderStatuses.IN_TRIP],
  [EOrdersTabs.ARCHIVE]: [
    EOrderStatuses.DONE,
    EOrderStatuses.CANCELED_BY_CARGO_OWNER,
    EOrderStatuses.CANCELED_BY_NTK,
    EOrderStatuses.FAILED_BY_CARGO_OWNER,
    EOrderStatuses.FAILED_BY_NTK,
    EOrderStatuses.EXPIRED,
  ],
  [EOrdersTabs.DRAFT]: [EOrderStatuses.DRAFT],
};

type TOrderStatusesToTabTree = { [key in EOrderStatuses]?: EOrdersTabs[] };
export const ORDER_STATUS_TO_TAB_MAP: TOrderStatusesToTabTree = {
  // in work
  [EOrderStatuses.TRIP_WAITING]: [EOrdersTabs.IN_WORK],
  [EOrderStatuses.IN_TRIP]: [EOrdersTabs.IN_WORK],
  // draft
  [EOrderStatuses.DRAFT]: [EOrdersTabs.DRAFT],
  // pending
  [EOrderStatuses.AUCTION_WAIT]: [EOrdersTabs.PENDING],
  [EOrderStatuses.IN_AUCTION]: [EOrdersTabs.PENDING],
  [EOrderStatuses.RESERVED]: [EOrdersTabs.PENDING],
  [EOrderStatuses.APPROVAL]: [EOrdersTabs.PENDING],
  [EOrderStatuses.WINNER_SELECTION]: [EOrdersTabs.PENDING],
  // archive
  [EOrderStatuses.DONE]: [EOrdersTabs.ARCHIVE],
  [EOrderStatuses.CANCELED_BY_CARGO_OWNER]: [EOrdersTabs.ARCHIVE],
  [EOrderStatuses.FAILED_BY_CARGO_OWNER]: [EOrdersTabs.ARCHIVE],
  // pending or archive
  [EOrderStatuses.EXPIRED]: [EOrdersTabs.PENDING, EOrdersTabs.ARCHIVE],
  [EOrderStatuses.CANCELED_BY_NTK]: [EOrdersTabs.PENDING, EOrdersTabs.ARCHIVE],
  [EOrderStatuses.FAILED_BY_NTK]: [EOrdersTabs.PENDING, EOrdersTabs.ARCHIVE],
};

export const TAB_TO_MATOMO_CATEGORY_MAP = {
  [EOrdersTabs.PENDING]: ORDERS_PENDING,
  [EOrdersTabs.IN_WORK]: ORDERS_IN_WORK,
  [EOrdersTabs.ARCHIVE]: ORDERS_ARCHIVE,
  [EOrdersTabs.DRAFT]: ORDERS_DRAFT,
};
