import React, { useCallback } from 'react';
import { FieldProps } from 'formik';

import InputHOC from '../../FormikFieldsV3/inputHOC';
import Selector from '../../FormElements/Selector/Selector';
import { useFormikContextSelector } from '../FormikContext';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IOption {
  value: string;
  label: string;
}

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  options: Array<IOption>;
  label?: string;
  isMulti?: boolean;
  maxOptionsToChoose?: number;
  isError?: boolean;
  inputClassName?: string;
}

const FormSelector = (props: IProps & FieldProps) => {
  const { form, field, isError, options, isMulti, maxOptionsToChoose, onChange, ...rest } = props;
  const { name, value } = field;
  const setFieldTouched = useFormikContextSelector((c) => c.setFieldTouched);
  const validateField = useFormikContextSelector((c) => c.validateField);

  const handleChange = useCallback(
    (value, selectedOptions) => {
      // @ts-ignore
      // TODO если есть нормализация поля - из за хока она не доходит, поэтому "эмулируем" эвент
      onChange?.({ target: { value, name, selectedOptions } });

      // Без таймаута в валидацию попадают предыдущие значения
      setTimeout(() => {
        // TODO: добавить проверку на наличие валидации поля, если есть - то вызывать валидацию
        try {
          return validateField(name);
        } catch {
          console.warn(`Field "${name}" has no validation!`);
        }
      }, 0);
    },
    [onChange, name, validateField],
  );

  const handleBlur = useCallback((e) => setFieldTouched(name, e, true), [setFieldTouched, name]);

  const acceptedProps = {
    ...rest,
    ...field,
    options,
    isMulti,
    maxOptionsToChoose,
    isError,
    value,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <Selector
      {...acceptedProps}
      inputClassName={classNames(rest.inputClassName, { [styles.error]: isError })}
      DropdownFooterContent={Selector.RemoteOptionsDropdownFooterContent}
    />
  );
};

FormSelector.defaultValue = {
  // Возвращать только value (для обратной совместимости форм заказа)
  cleanValue: false,
};

export default InputHOC(FormSelector);
