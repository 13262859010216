import React from 'react';
import SVGIcon from './index';

function IconAuctionOpened(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.65686 4.12132L10.7782 2L14.3137 5.53553L12.1924 7.65685L8.65686 4.12132ZM5.82837 6.94969L7.94969 4.82837L14.6225 11.5012C14.582 11.5004 14.5411 11.5 14.5 11.5C13.233 11.5 12.2228 11.8853 11.4044 12.5257L5.82837 6.94969ZM2 16H9V18H2V16ZM5.12132 7.65674L3 9.77806L6.53553 13.3136L8.65685 11.1923L5.12132 7.65674Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 17.5C17 17.5 18.5 15 18.5 15C18.5 15 17 12.5 14.5 12.5C12 12.5 10.5 15 10.5 15C10.5 15 12 17.5 14.5 17.5ZM14.5 16C15.0523 16 15.5 15.5523 15.5 15C15.5 14.4477 15.0523 14 14.5 14C13.9477 14 13.5 14.4477 13.5 15C13.5 15.5523 13.9477 16 14.5 16Z"
      />
    </SVGIcon>
  );
}

IconAuctionOpened.defaultProps = {
  color: 'base',
};

export default React.memo(IconAuctionOpened);
