import React from 'react';
import SVGIcon from './index';

import colors from '../../../styles/colors.scss';

const IconSearch = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M13.0057 11.805H12.3734L12.1492 11.5889C12.9337 10.6764 13.4059 9.49171 13.4059 8.20297C13.4059 5.32933 11.0766 3 8.20297 3C5.32933 3 3 5.32933 3 8.20297C3 11.0766 5.32933 13.4059 8.20297 13.4059C9.49171 13.4059 10.6764 12.9337 11.5889 12.1492L11.805 12.3734V13.0057L15.8073 17L17 15.8073L13.0057 11.805ZM8.203 11.805C6.20986 11.805 4.60094 10.1961 4.60094 8.20293C4.60094 6.20979 6.20986 4.60088 8.203 4.60088C10.1961 4.60088 11.8051 6.20979 11.8051 8.20293C11.8051 10.1961 10.1961 11.805 8.203 11.805Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconSearch.defaultProps = {
  color: 'base',
};

export default IconSearch;
