import React from 'react';
import {
  AWNING_URL,
  FULL_METAL_URL,
  ISOTHERMAL_URL,
  REFRIGERATOR_URL,
  DROPSIDE_URL,
  TRUCK_URL,
  LORRY_URL,
  TANKER_URL,
  CONTAINER_URL,
  TIMBER_CARRIER_URL,
  STEP_DECK_URL,
} from '../../helpers/carTypesMapping';

import {
  IconTractor,
  IconTrailer,
  IconAwningWithTruck10,
  IconAwningWithTruck5,
  IconAwningWithTruck3,
  IconAwningWithTruck15,
  IconCargoOwner,
  IconTransportCompany,
} from '../Icons/index';

import * as formFields from '../../store/registration/formFields';

interface IProps {
  vehicleType?: string;
  bodyType: string;
  tonnage?: number;
  active?: boolean;
}

/**
 * Получение типа прицепа/-ов
 */
function VehicleIcon({ vehicleType, bodyType, tonnage, active }: IProps) {
  if (vehicleType === TRUCK_URL) {
    return <IconTractor height="25" width="60" />;
  }

  if (vehicleType === LORRY_URL) {
    return (
      <>
        {LORRY_BY_TONNAGE.get(tonnage)}
        <span className="color-dark ml-3">{TYPES.get(bodyType) || ''}</span>
      </>
    );
  }

  if (vehicleType === formFields.TRANSPORT_COMPANY) {
    if (bodyType === formFields.TRANSPORT_COMPANY) {
      return <IconTransportCompany width="75" height="25" color={active ? 'primary' : 'base'} />;
    }

    if (bodyType === formFields.CARGO_OWNING_COMPANY) {
      return <IconCargoOwner width="60" height="30" color={active ? 'primary' : 'base'} />;
    }
  }

  return (
    <>
      <IconTrailer height="25" width="60" />
      <span className="color-dark ml-3">{TYPES.get(bodyType) || ''}</span>
    </>
  );
}

export default VehicleIcon;

const LORRY_BY_TONNAGE = new Map()
  .set(20, <IconAwningWithTruck10 height="25" width="60" />)
  .set(15, <IconAwningWithTruck10 height="25" width="60" />)
  .set(10, <IconAwningWithTruck10 height="25" width="60" />)
  .set(7, <IconAwningWithTruck5 height="25" width="60" />)
  .set(5, <IconAwningWithTruck5 height="25" width="60" />)
  .set(3.5, <IconAwningWithTruck3 height="25" width="60" />)
  .set(3, <IconAwningWithTruck3 height="25" width="60" />)
  .set(2, <IconAwningWithTruck15 height="25" width="60" />)
  .set(1.5, <IconAwningWithTruck15 height="25" width="60" />)
  .set(1, <IconAwningWithTruck15 height="25" width="60" />);

export const TYPES = new Map()
  .set(AWNING_URL, 'Тент')
  .set(REFRIGERATOR_URL, 'Реф.')
  .set(ISOTHERMAL_URL, 'Изотерм.')
  .set(FULL_METAL_URL, 'Цельнометалл.')
  .set(DROPSIDE_URL, ' Бортовой')
  .set(TIMBER_CARRIER_URL, ' Лесовоз')
  .set(STEP_DECK_URL, 'Автотрал')
  .set(TANKER_URL, 'Автоцистерна')
  .set(CONTAINER_URL, 'Контейнеровоз');
