import { FormikContextType } from 'formik';
import { createContext, useContext, useContextSelector } from 'use-context-selector';

export const FormikContext = createContext<FormikContextType<any>>(undefined as any);

export function useFormikContext<Values>() {
  return useContext<FormikContextType<Values>>(FormikContext);
}

export function useFormikContextSelector<Values = any, Slice = any>(
  selector: (value: FormikContextType<Values>) => Slice,
): Slice {
  return useContextSelector(FormikContext, selector);
}
