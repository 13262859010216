import _isEqual from 'lodash/isEqual';

/**
 * Сравнение двух объектов (текущего и предыдущего значения)
 * @param currentValue
 * @param selectedValue
 * @return {boolean}
 */
export function getIsChecked(currentValue, selectedValue) {
  if (!currentValue || !selectedValue) {
    return false;
  }
  return _isEqual(currentValue, selectedValue);
}
