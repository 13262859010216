import React, { lazy, Suspense } from 'react';

import { CONTRACT_BY_COUNT, FORM_TYPE, FULL, ORDER_TYPE, SHIPPING_REQUEST, SIMPLE } from '../OrderNFormTypeBlock';

import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import ContentLoader from '../../../../components/ContentLoader';
import { getHashString } from '../../helpers/getHashString';
import useQuotes from '../../hooks/useQuotes';

export const WAREHOUSE_ID = 'warehouse_id';
export const ROUTE_POINTS = 'route_points';
export const ROUTE_ADDRESS_CITY = 'route_address_city';
export const ROUTE_RADIUS = 'radius';
export const ROUTE_OPERATIONS = 'operations';
export const ROUTE_ADDRESS_STREET = 'route_address_street';
export const ROUTE_ADDRESS_HOUSE = 'route_address_house';
export const ROUTE_ADDRESS_ROOM = 'route_address_room';
export const ROUTE_ADDRESS_COMMENT = 'route_address_comment';
export const ROUTE_DATES = 'route_dates';
export const ROUTE_DATE_FROM = 'from';
export const ROUTE_DATE_TILL = 'till';
export const ROUTE_COVER_TYPE = 'route_cover_type';
export const ROUTE_PRR = 'route_prr';
export const ROUTE_PRR_MIN = 'route_prr_min';
export const ROUTE_SHIPPINGS_COUNT = 'shippings_count';
export const ROUTE_MAX_SHIPPINGS_PER_DAY = 'max_shippings_per_day';
export const ROUTE_MAX_SHIPPINGS_PER_WEEK = 'max_shippings_per_week';
export const ROUTE_CONTRACT_BEGINS_AT = 'contract_begins_at';
export const ROUTE_CONTRACT_ENDS_AT = 'contract_ends_at';
export const WITH_QUOTES = 'use_quotes';
export const QUOTA_TYPE = 'quota_type';
export const QUOTA_DISTRIBUTION = 'quota_distribution';
export const QUOTA_WINNERS = 'quota_winners';
export const SUGGESTION_HOURS = 'prr_hours';
export const SUGGESTION_MINUTES = 'prr_minutes';
export const AVERAGE_PRICE = 'total_average_price';
export const ADDITIONAL_AUCTION = 'additional_auction';

/* Технические поля */
export const ROUTE_CONTRACT_PERIOD = 'route_contract_period';
export const SHOW_ADDITIONAL_SERVICES_BLOCK = 'show_additional_services_block';
export const SHOW_CONTACTS_INFO_BLOCK = 'show_contacts_info_block';
export const IS_ROUTE_BLOCK_OPENED = 'is_route_block_opened';
export const NEW_WAREHOUSE_SLOT_DATE = 'new_warehouse_slot_date';
export const NEW_WAREHOUSE_SLOT = 'new_warehouse_slot';
export const EXACT_ADDRESS = 'exact_address';

/* Технические поля */
export const IS_INTERVAL = 'is_interval';

const ShippingRequestSimpleRoutesBlock = lazy(() => import('./ShippingRequestSimpleRoutesBlock'));
const ShippingRequestFullRoutesBlock = lazy(() => import('./ShippingRequestFullRoutesBlock'));
const ContractByCountSimpleRoutesBlock = lazy(() => import('./ContractByCountSimpleRoutesBlock'));
const ContractByCountFullRoutesBlock = lazy(() => import('./ContractByCountFullRoutesBlock'));
const ShippingRequestSimpleRoutesBlockQuotes = lazy(() => import('./ShippingRequestSimpleRoutesBlockQuotes'));

function getBlockComponent(hashString) {
  switch (hashString) {
    // Спот + упрощенка + квоты
    case getHashString({ orderType: SHIPPING_REQUEST, formType: SIMPLE, withQuotes: true }):
      return ShippingRequestSimpleRoutesBlockQuotes;
    // Спот + упрощенка
    case getHashString({ orderType: SHIPPING_REQUEST, formType: SIMPLE }):
      return ShippingRequestSimpleRoutesBlock;
    // Спот + полная
    case getHashString({ orderType: SHIPPING_REQUEST, formType: FULL, withQuotes: true }):
    case getHashString({ orderType: SHIPPING_REQUEST, formType: FULL }):
      return ShippingRequestFullRoutesBlock;
    // Объемный контракт + упрощенка
    case getHashString({ orderType: CONTRACT_BY_COUNT, formType: SIMPLE, withQuotes: true }):
    case getHashString({ orderType: CONTRACT_BY_COUNT, formType: SIMPLE }):
      return ContractByCountSimpleRoutesBlock;
    // Объемный контракт + полная
    case getHashString({ orderType: CONTRACT_BY_COUNT, formType: FULL }):
    case getHashString({ orderType: CONTRACT_BY_COUNT, formType: FULL, withQuotes: true }):
      return ContractByCountFullRoutesBlock;
    default:
      return null;
  }
}

export function RoutesBlock() {
  const [orderType] = useFieldValue(ORDER_TYPE);
  const [formType] = useFieldValue(FORM_TYPE);
  const hashParams = { orderType, formType };
  const { withQuotes } = useQuotes();
  if (withQuotes) hashParams['withQuotes'] = withQuotes;

  const hashString = getHashString(hashParams);
  const Component = getBlockComponent(hashString);
  return <Suspense fallback={<ContentLoader withBackground />}>{Component ? <Component /> : null}</Suspense>;
}
