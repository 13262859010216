import * as Yup from 'yup';
import { TYPES } from '../../../../features/insurance/components/ProfileFormInsurance/constants';
import { INSURANCE_OPTION, INSURANCE_SUM, TONNAGE } from '../../../../features/insurance/constants';

export const MESSAGES = {
  REQUIRED: 'Обязательно',
  GR_ONE: 'Значение должно быть больше или равно 1',
  MIN_INSURANCE_SUM: 'Страховая сумма должна быть больше нуля',
  MAX_INSURANCE_SUM: 'Не более 50 000 000 ₽',
  INTERVAL: 'Границы интервала должны быть заполнены',
};

export const validationSchema = Yup.object().shape({
  [INSURANCE_SUM]: Yup.number()
    .nullable()
    .when(INSURANCE_OPTION, (value, schema) => {
      return value === TYPES.REQUIRED
        ? schema
            .required(MESSAGES.REQUIRED)
            .min(1, MESSAGES.MIN_INSURANCE_SUM)
            .max(50000000, MESSAGES.MAX_INSURANCE_SUM)
        : schema;
    }),
  [TONNAGE]: Yup.array().when(INSURANCE_OPTION, (value, schema) => {
    return [TYPES.REQUIRED, TYPES.CARGO_COST].includes(value)
      ? schema.of(Yup.number().min(1, MESSAGES.GR_ONE).required(MESSAGES.INTERVAL)).min(2).required(MESSAGES.REQUIRED)
      : schema;
  }),
});
