import './polyfills'; // Move it to index.html (polyfill.io)

import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/browser';
import { enableMapSet, enableES5 } from 'immer';
import { setDefaultLocale } from 'react-datepicker';

import './styles.scss';

import Root from './containers/Root';
import 'react-dates/initialize';
import 'moment/locale/ru';
import ru from 'date-fns/locale/ru';

import { history } from './store/configureStore';
import { store } from './store/store';

enableES5();
enableMapSet();

setDefaultLocale(ru);

const sentryDsn = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SENTRY_DSN : null;
if (sentryDsn) Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

const tagManagerArgs = { gtmId: process.env.REACT_APP_GMT_ID };
if (process.env.REACT_APP_GMT_ID) TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Root history={history} store={store} />
  </React.StrictMode>,
  document.getElementById('root'),
);
