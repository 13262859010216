import classNames from 'classnames';
import React, { ChangeEvent, useCallback } from 'react';

import styles from './styles.module.scss';

import { IconTriangle } from '../Icons';
import { useLongPress } from '../../hooks/useLongPress';
import { ComponentsHandbookHOC } from '../../dev/pages/ComponentsHandbookPage/hocs';

const PROPS_TYPES = {
  readOnly: Boolean,

  value: Number,
  max: Number,
  min: Number,
  step: Number,

  additional: String,
  placeholder: String,
  disabled: Boolean,
};

const DEFAULT_VALUES = {
  additional: 'ч',
  readOnly: true,
  step: 1,
};

function Stepper(props) {
  const {
    name,
    additional,
    className,
    disabled,
    label,
    onChange: onChangeCallback,
    onBlur: onBlurCallback,
    max,
    min,
    placeholder,
    readOnly,
    inputClassName,
    step,
    comment,
    isError,
    /* для текста ошибки = тексту комментария */
    booleanError,
  } = props;

  const handleIncrease = useLongPress(increase, { clickHandler: increase });
  const handleDecrease = useLongPress(decrease, { clickHandler: decrease });
  function increase() {
    let value = parseFloat(props.value);
    if (!value) {
      value = 0;
    }

    if (value === max) return;

    onChangeCallback?.(value + step);
    onBlurCallback?.(value + step);
  }

  function decrease() {
    let value = parseFloat(props.value);
    if (value === min || isNaN(value)) return;

    onChangeCallback?.(value - step);
    onBlurCallback?.(value - step);
  }

  const handleChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      onChangeCallback?.(value);
    },
    [onChangeCallback],
  );

  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let value = parseFloat(e.target.value);
      if (!isNaN(value)) {
        if (min != null && +value < +min) value = min;
        if (max != null && +value > +max) value = max;
      } else {
        //@ts-ignore
        value = '';
      }
      onChangeCallback?.(value);
      onBlurCallback?.(value);
    },
    [onChangeCallback, onBlurCallback, min, max],
  );

  return (
    <div className="flex column">
      {label && (
        <span className={classNames('caption mb-1', { 'color-danger': isError || booleanError })}>{label}</span>
      )}

      <div
        className={classNames(
          styles.stepper,
          {
            [styles.error]: isError,
          },
          {
            [styles.disabled]: disabled,
          },
          className,
        )}
      >
        <input
          type="number"
          name={name}
          value={props.value}
          onChange={handleChangeInput}
          onBlur={handleBlur}
          min={min}
          max={max}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          className={classNames(styles.stepperInput, inputClassName)}
        />
        <span className={styles.stepperInputAdditional}>{additional}</span>
        <div className={styles.stepperButtons}>
          <button type="button" className={styles.button} disabled={disabled} {...handleIncrease}>
            <IconTriangle direction="top" />
          </button>
          <button type="button" className={styles.button} disabled={disabled} {...handleDecrease}>
            <IconTriangle direction="bottom" />
          </button>
        </div>

        <span className={classNames('caption', styles.bottomText, { 'color-danger': isError || booleanError })}>
          {isError && !booleanError && comment}
        </span>
      </div>
    </div>
  );
}

Stepper.defaultProps = {
  additional: DEFAULT_VALUES.additional,
  step: DEFAULT_VALUES.step,
  readOnly: DEFAULT_VALUES.readOnly,
};

export default ComponentsHandbookHOC(Stepper, PROPS_TYPES, DEFAULT_VALUES);
