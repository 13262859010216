import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

import Input from '../../Input';
import IconSearch from '../../Icons/SVGIcons/IconSearch';
import withDebounceOnChange from '../withDebounceOnChange';

interface SearchProps {
  onChange: () => void;
  value: string;
  placeholder: string;
  className: string;
}

function FilterSearch({ value, placeholder, className, onChange }: SearchProps) {
  return (
    <div className={classNames(styles.filterInput, className)}>
      <Input role="search" value={value} placeholder={placeholder} onChange={onChange} aria-label={placeholder} />
      <IconSearch width="20" height="20" className={styles.filterIcon} />
    </div>
  );
}

export default withDebounceOnChange(FilterSearch);
