import React from 'react';

import * as ordersService from '../../services/orders/ordersService';
import { toast } from '../../components/Toaster';

import { SHIPPING_REQUEST } from './routes/formKeys';
/* Analytics */
import {
  cancelRequestConfirmEvent,
  approveTripEndEvent,
  submitAheadOfTimeWinnerSelectEvent,
} from '../googleAnalytics/events';
import * as documentService from '../../services/documentService';
import { SHIPPING_ORDER } from '../documents/constants';
import { SHIPPING_ORDER_DOC } from '../claims/constants';
import errorsMapping from '../../errors';
import { getUrlString } from '../../helpers/getUrlString';
import { EOrdersTabs } from '../../containers/order-list/constants';
import { EOrderStatuses } from '../../constants/order';
import { EContractsTabs } from '../../containers/contracts/constants';
import { ACTIVE_ITEM_QUERY_KEY } from '../../services/_constants';

export async function attachFilesToResource(files, resourceId) {
  if (files) {
    const doc = await documentService.addDocument(SHIPPING_ORDER, SHIPPING_ORDER_DOC, resourceId);
    await documentService.addFilesToDocument(SHIPPING_ORDER, doc.data.id, files, resourceId);
  }
}

/**
 * Удалить черновик
 */
export function deleteDraft(item: { id: string; humanFriendlyId: string; orderType: string }): () => Promise<void> {
  return async () => {
    const isShipping = item?.orderType === SHIPPING_REQUEST;
    try {
      await ordersService.deleteOrderDraft(item?.id);

      toast.info(`${isShipping ? 'Заявка' : 'Контракт'} № ${item?.humanFriendlyId} удал${isShipping ? 'ена' : 'ён'}`);
      return Promise.resolve();
    } catch (error) {
      const errorObj = errorsMapping.get(error?.response?.data?.error_code);
      if (errorObj) {
        toast.error(errorObj.title, errorObj.message);
      } else {
        await toast.errorAsync(error);
      }
    }
  };
}

/**
 * Отправить в архив
 */
export function moveToArchive(item: { id: string; humanFriendlyId: string; orderType: string }): () => Promise<void> {
  return async () => {
    const isShipping = item?.orderType === SHIPPING_REQUEST;
    try {
      await ordersService.moveToArchive(item?.id);

      toast.info(
        `${isShipping ? 'Заявка' : 'Контракт'} № ${item?.humanFriendlyId} отправлен${isShipping ? 'а' : ''} в архив`,
      );
      return Promise.resolve();
    } catch (error) {
      const errorObj = errorsMapping.get(error?.response?.data?.error_code);
      if (errorObj) {
        toast.error(errorObj.title, errorObj.message);
        throw error;
      } else {
        await toast.errorAsync(error);
      }
    }
  };
}

/**
 * Подтверждение перевозки грузовладельцем
 * @param orderId
 * @param params
 * @returns {*}
 */
export function confirmShipping(orderId: string, params: any): () => Promise<void> {
  return () => {
    return ordersService
      .confirmShipping(orderId, params)
      .then(() => {
        toast.success('Перевозка выполнена и перемещена во вкладку «В архиве»');
        return Promise.resolve();
      })
      .then(() => approveTripEndEvent())
      .catch(toast.errorAsync);
  };
}

/**
 * Отмена заявки
 * @param orderId
 * @param params
 */
export function cancelOrder(orderId: string, params: any): () => Promise<void> {
  return () => {
    const initiator = params.status === EOrderStatuses.CANCELED_BY_CARGO_OWNER ? 'Грузовладелец' : 'Перевозчик';
    return ordersService
      .cancelOrder(orderId, params)
      .then((data) => {
        const isShipping = data?.type === SHIPPING_REQUEST;

        isShipping
          ? toast.success('Перевозка отменена и перемещена во вкладку «В архиве»')
          : toast.info(`Контракт отменён и перемещён во вкладку «В архиве»`);

        return Promise.resolve();
      })
      .then(() => cancelRequestConfirmEvent(initiator))
      .catch(toast.errorAsync);
  };
}

export function _getCreatedToaster(payload) {
  const hfid = payload?.human_friendly_id;
  const cityFrom =
    payload?.route_points?.[0]?.location?.locality_with_type ||
    payload?.route_points?.[0]?.location?.locality ||
    payload?.route_points?.[0]?.location?.settlement_with_type ||
    payload?.route_points?.[0]?.location?.city_with_type;
  const lastPointIndex = payload?.route_points?.length > 1 ? payload?.route_points?.length - 1 : -1;
  const cityTo =
    payload?.route_points?.[lastPointIndex]?.location?.locality_with_type ||
    payload?.route_points?.[lastPointIndex]?.location?.locality ||
    payload?.route_points?.[lastPointIndex]?.location?.settlement_with_type ||
    payload?.route_points?.[lastPointIndex]?.location?.city_with_type;
  const isShipping = payload?.type === SHIPPING_REQUEST;
  const hasExecutors = Array.isArray(payload?.executors) && payload?.executors.length > 0;

  if (hasExecutors) {
    toast.success(
      <>
        Объёмный контракт{' '}
        <a
          href={getUrlString(isShipping ? `order-list/${EOrdersTabs.PENDING}` : `contracts/${EContractsTabs.PENDING}`, {
            [ACTIVE_ITEM_QUERY_KEY]: payload?.id,
          })}
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          № {hfid}
        </a>{' '}
        {cityFrom} – {cityTo} с предустановленным исполнителем создан, находится во вкладке «На исполнении»
      </>,
    );
  } else {
    toast.success(
      <>
        {isShipping ? 'Заявка' : 'Объёмный контракт'}{' '}
        <a
          href={getUrlString(isShipping ? `order-list/${EOrdersTabs.PENDING}` : `contracts/${EContractsTabs.PENDING}`, {
            [ACTIVE_ITEM_QUERY_KEY]: payload?.id,
          })}
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          № {hfid}
        </a>{' '}
        {cityFrom} – {cityTo} создан{isShipping ? 'а' : ''}
      </>,
    );
  }
}

/**
 * Досрочно выбрать победителя аукциона
 * Обязательно делать запрос на полуение нового объекта заказа
 * для получения свежей информации о ставках
 */
export function earlySelectWinner(item: { id: string; humanFriendlyId: string }): () => Promise<void> {
  return async () => {
    try {
      await ordersService.setEarlyWinner(item.id);
      submitAheadOfTimeWinnerSelectEvent(item.id);
      toast.success(`Торг по заявке № ${item.humanFriendlyId} завершён`);
      return Promise.resolve();
    } catch (error) {
      const errorObj = errorsMapping.get(error?.response?.data?.error_code);
      if (!!errorObj) {
        toast.error(errorObj.title, errorObj.message);
        return Promise.reject();
      } else {
        toast.error(error);
      }
    }
  };
}

export function getChatRoom(shippingId: string): () => Promise<any> {
  return async () => {
    try {
      const data = await ordersService.getChatRoom(shippingId);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.resolve();
    }
  };
}

export function replyOnBroadcast(id: string): () => Promise<any> {
  return async () => {
    try {
      const data = await ordersService.replyOnBroadcast(id);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.resolve();
    }
  };
}

export function updateResourcesAutoConfirm(orderId: string, value: boolean): () => Promise<void> {
  return () => {
    return ordersService
      .updateResourcesAutoConfirm(orderId, value)
      .then(() => {
        const message = value
          ? 'Автосогласование замены ресурсов для заявки запрещено'
          : 'Замена ресурсов будет выполняться согласно настройке автосогласования для партнера';
        toast.success(message);
        return Promise.resolve();
      })
      .catch(toast.errorAsync);
  };
}
