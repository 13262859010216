import React from 'react';
import SVGIcon from './index';

const IconArrowRight = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M13.2985 9L10 5.7925L11.332 4.5L17 10L11.332 15.5L10 14.2075L13.2985 11H3V9H13.2985Z"
        fillRule="evenodd"
      />
    </SVGIcon>
  );
};

export default IconArrowRight;
