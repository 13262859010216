import React from 'react';

import TimeLeft from '../../TimeLeft';
import { IconAssignmentCheck, IconExclamation } from '../../Icons';
import * as queryKeys from '../../../store/suggestions/queryKeys';

const TEXT_BY_STATUS = new Map()
  .set(queryKeys.CARGO_OWNER_SIGNING, 'Ожидание')
  .set(queryKeys.IN_PROGRESS, 'Проверка')
  .set(queryKeys.ACCEPTED, 'Приняты')
  .set(queryKeys.REJECTED, 'Отклонены');

export function getScanCopyStatus(item: {
  scanCopyStatus?: string;
  isScanCopyError?: boolean;
  isScanCopyDead?: boolean;
  scanCopyDeadline?: string;
}) {
  const text = TEXT_BY_STATUS.get(item.scanCopyStatus) || '';

  const SCAN_COPIES_STATUS = new Map()
    .set(
      queryKeys.CARGO_OWNER_SIGNING,
      <>
        <span>
          <IconExclamation width="20" height="20" color="warning" />
        </span>
        <span>{text}</span>
      </>,
    )
    .set(
      queryKeys.IN_PROGRESS,
      <>
        <span>
          <IconExclamation width="20" height="20" color="warning" />
        </span>
        <span>{text}</span>
      </>,
    )
    .set(
      queryKeys.ACCEPTED,
      <>
        <span>
          <IconAssignmentCheck width="20" height="20" color="success" />
        </span>
        <span>{text}</span>
      </>,
    )
    .set(
      queryKeys.REJECTED,
      <>
        <span>
          <IconExclamation width="20" height="20" color="danger" />
        </span>
        <span className="color-danger">{text}</span>
      </>,
    )
    .set(
      queryKeys.NOT_ATTACHED,
      <>
        <span>
          <IconExclamation
            width="20"
            height="20"
            color={item.isScanCopyError || item.isScanCopyDead ? 'danger' : 'base'}
          />
        </span>
        <span>
          <TimeLeft time={item?.scanCopyDeadline} title={''} withTimerIcon={false} />
        </span>
      </>,
    );

  return (
    SCAN_COPIES_STATUS.get(item.scanCopyStatus) || (
      <>
        <span>
          <IconExclamation
            width="20"
            height="20"
            color={item.isScanCopyError || item.isScanCopyDead ? 'danger' : 'base'}
          />
        </span>
        <span>
          <TimeLeft time={item?.scanCopyDeadline} title={''} withTimerIcon={false} />
        </span>
      </>
    )
  );
}
