/**
 * Начисление НДС
 * @param value
 */
export function getWithVat(value: string | number): number {
  return Math.round(Number(value) * 1.2);
}

/**
 * Выделение НДС
 * @param value
 */
export function getWithoutVat(value: string | number): number {
  return Math.round(Number(value) / 1.2);
}

/**
 * Из значения получить округленную цену, обрезанную до целого или пустую строку
 */
export function getPriceValue(value: string | number): string {
  const roundedValue = Math.round(Number(value));
  return !!roundedValue ? roundedValue.toFixed() : '';
}
