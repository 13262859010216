/**
 * Поля формы для маршрутов
 */
export const ORDER_TYPE = 'order_type';
export const SHIPPINGS_COUNT = 'shippings_count';
export const MAX_SHIPPING_PER_DAY = 'max_shippings_per_day';
export const MAX_SHIPPING_PER_WEEK = 'max_shippings_per_week';

export const ROUTE_ADDRESS_CITY = 'route_address_city';
export const ROUTE_COVER_TYPE = 'route_cover_type';

export const ADDITIONAL_SERVICES = 'additional_services';
export const FLOOR_COUNT = 'floor_count';
export const TIME = 'time';

/* Дополнительные услуги в точке маршрута */
export const MANIPULATOR = '612a65de-c07d-414a-934a-84c269961fbe';
export const LOADERS = '6cda189f-5112-4a86-a68e-eb57a15a3f66';
export const CRANE = '78580c63-94af-4693-9476-9204e3ca9332';
export const RISE_FLOOR = 'f2c01a64-5b7d-4015-b5de-543fdff4260d';
export const RECOUNTING = 'fb5814ca-9878-4bf8-9739-47700d4e4b46';
export const EXTRA_TIME = '22f2f419-b36d-4968-8101-94a16803ec64';

export type TAdditionalServicesIds =
  | typeof MANIPULATOR
  | typeof LOADERS
  | typeof CRANE
  | typeof RISE_FLOOR
  | typeof RECOUNTING
  | typeof EXTRA_TIME;

export const SERVICES_IDS = new Map()
  .set(MANIPULATOR, '612a65de-c07d-414a-934a-84c269961fbe')
  .set(LOADERS, '6cda189f-5112-4a86-a68e-eb57a15a3f66')
  .set(CRANE, '78580c63-94af-4693-9476-9204e3ca9332')
  .set(RISE_FLOOR, 'f2c01a64-5b7d-4015-b5de-543fdff4260d')
  .set(RECOUNTING, 'fb5814ca-9878-4bf8-9739-47700d4e4b46');
//.set(EXTRA_TIME, '22f2f419-b36d-4968-8101-94a16803ec64');

/* Параметры услуги в точке маршрута для детализации */
export const DESCRIPTION_MASK = `%count%`;
export const SERVICE_DESCRIPTIONS = new Map().set(RISE_FLOOR, ` (этаж ${DESCRIPTION_MASK})`);
//.set(EXTRA_TIME, ` (${DESCRIPTION_MASK} мин)`);
