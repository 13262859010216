/**
 * Аналог Prosime.allSettled, но с указанием одновременно активных промисов
 */
export async function promiseRateLimit<T>(promises: (() => Promise<T>)[], limit: number = 2) {
  const results: PromiseSettledResult<T>[] = [];
  let started = 0;

  function maker() {
    const makeRequest = promises.shift();
    return makeRequest
      ? Promise.allSettled([makeRequest()]).then((result) => {
          results[started] = result[0];
          started += 1;
          return maker();
        })
      : null;
  }

  await Promise.all(Array.from({ length: limit }, maker));
  return results;
}
