import _get from 'lodash/get';
import http from './config/webConfig';
import * as authTokenService from './authTokenService';
import { DRIVER, CAR, TRAILER } from '../store/resources/formKeys';
import { NEW, NOT_ATTACHED, ACCEPTED, IN_PROGRESS, ON_REVIEW } from '../store/suggestions/queryKeys';
import {
  SHIPPING,
  SHIPPING_ORDER,
  GRAPHICS,
  OUTCOMING_CLAIMS,
  INCOMING_CLAIMS,
  PARTNERSHIP,
} from '../store/documents/constants';
import { WORK_RELATIONSHIP_DOC_TYPE } from '../store/company/constants';
import { COMPANY } from '../store/company/formFields';
import {
  deletePartnershipFile,
  getPartnershipDocuments,
  getRequiredDocsForPartnership,
  sendRequiredForPartnershipDoc,
} from './partnersService';

/**
 * Отправка запроса на сервер по заданным параметрам
 * @param {String} url
 * @param {String} type
 * @param {Object} params
 * @param upload {boolean}
 */
function sendRequest(url, type = 'get', params = {}, upload = false) {
  const headers = {};
  if (upload) {
    headers['Content-Type'] = 'application/octet-stream';
  }
  switch (type.toUpperCase()) {
    case 'PUT':
      return http.put(url, params, { headers });
    case 'POST':
      return http.post(url, params, { headers });
    case 'DELETE':
      return http.delete(url, { headers });
    case 'GET':
    default:
      return http.get(url, { headers, cache: false });
  }
}

/* URL взависимости от сущности */
let ENTITY_TO_PATH = (entityId, company_id) =>
  new Map()
    .set(COMPANY, `/company/${company_id}`)
    .set(SHIPPING, `/shippings/${entityId}`)
    .set(SHIPPING_ORDER, entityId ? `/shipping_orders/${entityId}` : '/')
    .set(GRAPHICS, `/contracts/${entityId}`)
    .set(OUTCOMING_CLAIMS, `/claims/${entityId}`)
    .set(INCOMING_CLAIMS, `/forwarder_claims/${entityId}`)
    .set(DRIVER, `/resources/${company_id}/drivers/${entityId}`)
    .set(CAR, `/resources/${company_id}/cars/${entityId}`)
    .set(TRAILER, `/resources/${company_id}/trailers/${entityId}`)
    .set(PARTNERSHIP, `/partnerships/request_cargo_company/${entityId}`);

/**
 * Получение базового URL
 * для работы с документами
 * @param {String} entity
 * @param {String} entityId
 */
async function getMainPathForUrl(entity, entityId = '') {
  let token = await authTokenService.getToken();
  let { company_id } = authTokenService.getDecodeToken(token);
  let baseUrl = ENTITY_TO_PATH(entityId, company_id).get(entity) || '';

  return { baseUrl };
}

/**
 * Построение карты типов и файлов
 * @param types {Array} types
 * @param {Array} documents
 * @return {Object}
 */
export function getTypesMap(types, documents) {
  const types2documents = {};
  if (documents?.length) {
    documents.forEach((element) => {
      if (element?.files?.length) {
        if (types.find((type) => type.id === element.typeId)) {
          types2documents[element.typeId] = element.files.length;
        }
      }
    });
  }
  return types2documents;
}

/**
 * Мапинг типов документов
 * @param {Array} response
 * @return {Array}
 */
export function responseToTypes(response) {
  if (response?.length) {
    return response.map((type) => {
      const t = Object.create(null);
      t.id = _get(type, 'id', 0);
      t.name = _get(type, 'name', '');
      t.description = _get(type, 'description', '');
      t.required = _get(type, 'required', true);
      t.readOnly = _get(type, 'read_only', false);
      return t;
    });
  }
  return [];
}

/**
 * Мапинг документов
 * @param {Array} response
 * @returns {Array}
 */
export function responseToDocument(response) {
  if (response?.length) {
    return response.map((document) => {
      const d = Object.create(null);
      d.id = _get(document, 'id', 0);
      d.typeId = _get(document, 'document_type_id', 0);
      d.files = responseToFiles(
        _get(document, 'files', []),
        _get(document, 'document_type_id', 0),
        _get(document, 'id'),
      );
      return d;
    });
  }
  return [];
}
/**
 * Мапинг файлов
 * @param {Array} files
 * @return {Array}
 */
export function responseToFiles(files, typeId, documentId) {
  if (files?.length) {
    return files.map((file) => {
      const f = Object.create(null);
      f.id = _get(file, 'id');
      f.documentId = documentId; // _get(file, 'company_document_id');
      f.url = _get(file, 'url');
      f.singedUrl = _get(file, 'singed_url');
      f.fileName = _get(file, 'file_name');
      f.createAt = _get(file, 'created_at');

      if (typeId === WORK_RELATIONSHIP_DOC_TYPE) {
        f.insurancePremiumsInfo = file?.insurance_premiums_info;
      }
      return f;
    });
  }
  return [];
}

/**
 * Получение списка типов документов
 * для компании
 * @param {String} entity
 * @param {String} entityId
 */
export async function getTypes(entity, entityId = '') {
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/document_types`;
  return sendRequest(url, 'get').then((response) => response.data.items);
}
/**
 * Получение списка документов
 * для компании
 * @param {String} entity
 * @param {String} entityId
 */
export async function getDocuments(entity, entityId = '') {
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/documents`;
  return sendRequest(url, 'get').then((response) => response.data.items);
}

export async function getShippingOrderTypes(entity, entityId) {
  const { baseUrl } = await getMainPathForUrl(entity);
  const url = `${baseUrl}document_types?entity=shipping_order`;
  return sendRequest(url, 'get').then((response) => response.data.items);
}
export async function getShippingOrderDocuments(entity, entityId = '') {
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/documents`;
  return sendRequest(url, 'get').then((response) => response.data.items);
}
/**
 * Агрегированный запрос для получения
 * типов документов и списка документов
 * @param {String} entity
 * @param {String} entityId
 * @param params
 * @return {Promise}
 */
export function getTypesAndDocuments(entity, entityId = '', params) {
  switch (entity) {
    case SHIPPING:
    case GRAPHICS:
      return getScanCopies(entity, entityId);
    case SHIPPING_ORDER:
      if (entityId) {
        return Promise.all([getShippingOrderTypes(entity, entityId), getShippingOrderDocuments(entity, entityId)]).then(
          ([types, documents]) => {
            return {
              types: responseToTypes(types),
              documents: responseToDocument(documents),
              filesInType: getTypesMap(responseToTypes(types), responseToDocument(documents)),
            };
          },
        );
      } else {
        return Promise.all([getShippingOrderTypes(entity, entityId)]).then(([types]) => {
          return {
            types: responseToTypes(types),
          };
        });
      }
    case PARTNERSHIP:
      return Promise.all([getRequiredDocsForPartnership(params), getPartnershipDocuments(entityId)]).then(
        ([types, documents]) => {
          return {
            types: responseToTypes(types),
            documents: responseToDocument(documents),
            filesInType: getTypesMap(responseToTypes(types), responseToDocument(documents)),
          };
        },
      );
    default:
      return Promise.all([getTypes(entity, entityId), getDocuments(entity, entityId)]).then(([types, documents]) => {
        return {
          types: responseToTypes(types),
          documents: responseToDocument(documents),
          filesInType: getTypesMap(responseToTypes(types), responseToDocument(documents)),
        };
      });
  }
}

/**
 * Запрос на создание документа по типу
 * @param {String} entity
 * @param {Number} typeId
 * @param {String} entityId
 */
export async function addDocument(entity, typeId, entityId) {
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/documents`;
  return sendRequest(url, 'post', { document_type_id: typeId });
}

/**
 * Загрузка файла
 * @param {String} entity
 * @param {String} documentId
 * @param {Array} file
 * @param {String} entityId
 */
export async function addFileToDocument(entity, documentId, file, entityId) {
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/documents/${documentId}/file`;
  const formData = new FormData();
  formData.append('file', file);
  return new Promise((response, reject) => {
    sendRequest(url, 'post', formData, true)
      .then((data) => response(data))
      .catch((data) => reject(data));
  });
}

/**
 * Загрузка списка документов
 * на сервер
 * @param {String} entity
 * @param {String} documentId
 * @param {Array} files
 * @param {String} entityId
 * @return {Promise}
 */
export function addFilesToDocument(entity, documentId, files, entityId) {
  const uploadedFiles = [];
  const errorFiles = [];
  let promises = [];

  if (entity === SHIPPING || entity === GRAPHICS) {
    promises = files.map((file) =>
      addFileToScanCopy(entity, entityId, documentId, file)
        .then(() => uploadedFiles.push(file))
        .catch(() => errorFiles.push(file)),
    );
  } else if (entity === PARTNERSHIP) {
    promises = files.map((file) =>
      sendRequiredForPartnershipDoc(entityId, documentId, file)
        .then(() => uploadedFiles.push(file))
        .catch((e) => errorFiles.push({ file, error: e })),
    );
  } else {
    promises = files.map((file) =>
      addFileToDocument(entity, documentId, file, entityId)
        .then(() => uploadedFiles.push(file))
        .catch((e) => errorFiles.push({ file, error: e })),
    );
  }

  return Promise.allSettled(promises).then(() => ({
    uploadedFiles,
    errorFiles,
  }));
}

/**
 * Удаление файлов по идентификатору
 * документа и идентификатору файла
 * @param {String} entity
 * @param {String} documentId
 * @param {String} fileId
 * @param {String} entityId
 * @return {Promise}
 */
export async function deleteFile(entity, documentId, fileId, entityId) {
  //---------------------------------------
  if (entity === SHIPPING || entity === GRAPHICS) {
    return removeFileFromScanCopy(entity, entityId, documentId, fileId);
  }
  //---------------------------------------
  if (entity === PARTNERSHIP) {
    return deletePartnershipFile(entityId, documentId, fileId);
  }
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/documents/${documentId}/file/${fileId}`;
  return sendRequest(url, 'delete');
}

/**
 * Получение информации о файле
 * @param {String} entity
 * @param {String} documentId
 * @param {String} fileId
 * @param {String} entityId
 * @return {Promise}
 */
export async function getFileUri(entity, documentId, fileId, entityId) {
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/documents/${documentId}/file/${fileId}/download`;
  return sendRequest(url, 'get');
}

/**
 * --------------------------------------*
 * Фкнкции для работы со сканкопией  ----*
 * --------------------------------------*
 */

/**
 * Получение списка сканкопий
 * @param entity
 * @param entityId
 * @returns {Promise<{types: Array, documents: Array, filesInType: Array}>}
 */
export async function getScanCopies(entity, entityId) {
  // const url = `/shippings/${shippingId}/scancopies`;
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/scancopies`;
  const startIndex = 1000;
  return sendRequest(url, 'get')
    .then((response) => _get(response, 'data.items'))
    .then((items) => {
      const result = {
        types: [],
        documents: [],
        filesInType: [],
      };
      result.types = items.map((item, index) => {
        const isActive =
          item.status === NEW ||
          item.status === NOT_ATTACHED ||
          item.status === ACCEPTED ||
          item.status === IN_PROGRESS ||
          item.status === ON_REVIEW;
        const nameByKind =
          item?.kind === 'ContractScancopy'
            ? `Скан-копия подписанного доп. соглашения`
            : `Скан-копия подписанной заявки на перевозку`;

        return {
          id: startIndex + index,
          isActiveScanCopy: isActive,
          isAccepted: item.status === ACCEPTED,

          name: isActive ? nameByKind : '',
          description: '',
          required: true,
          updatedAt: isActive ? undefined : _get(item, 'updated_at'),
        };
      });
      result.documents = items.map((item, index) => ({
        id: item.id,
        typeId: startIndex + index,
        status: _get(item, 'status'),
        comment: _get(item, 'comment'),
        files: _get(item, 'files', []).map((file) => ({
          id: _get(file, 'id'),
          documentId: item.id,
          url: _get(file, 'url'),
          singedUrl: _get(file, 'singed_url'),
          fileName: _get(file, 'file_name', 'Без названия'),
          createAt: _get(file, 'created_at'),
        })),
      }));
      result.filesInType = getTypesMap(result.types, result.documents);
      return result;
    });
}

/**
 * Получение информации о сканкопии
 * @param entity
 * @param entityId
 * @param scancopyId
 * @returns {Promise<{access_token: string, token_type: string, expires_in: number, refresh_token: string}>}
 */
export async function getScanCopy(entity, entityId, scancopyId) {
  // const url = `/shippings/${shippingId}/scancopies/${scancopyId}`;
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/scancopies/${scancopyId}`;
  return sendRequest(url, 'get').then((response) => response.data);
}

/**
 * Добавление файлов в скан копию
 * @param entity
 * @param entityId
 * @param scanCopyId
 * @param files
 * @returns {Promise<any>}
 */
export async function addFilesToScanCopy(entity, entityId, scanCopyId, files) {
  return new Promise((response) => {
    const uploadedFiles = [];
    const errorFiles = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      try {
        addFileToScanCopy(entity, entityId, scanCopyId, file);
        uploadedFiles.push(file);
      } catch (e) {
        errorFiles.push(file);
      }
    }
    response({ uploadedFiles, errorFiles });
  });
}

/**
 * Добавление файла в сканкопию
 * @param entity
 * @param entityId
 * @param scanCopyId
 * @param file
 * @returns {Promise<any>}
 */
export async function addFileToScanCopy(entity, entityId, scanCopyId, file) {
  // const url = `/shippings/${entityId}/scancopies/${scanCopyId}/file`;
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/scancopies/${scanCopyId}/file`;
  const formData = new FormData();
  formData.append('file', file);
  return sendRequest(url, 'post', formData, true).then((data) => data);
}

/**
 * Удаление файла из сканкопии
 * @param entity
 * @param entityId
 * @param scanCopyId
 * @param fileId
 * @returns {Promise}
 */
export async function removeFileFromScanCopy(entity, entityId, scanCopyId, fileId) {
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/scancopies/${scanCopyId}/file/${fileId}`;
  return sendRequest(url, 'delete');
}

/**
 * Получение ссылки на скачивание шаблона скан-копии
 * @param entity
 * @param entityId
 * @return {Promise<String>}
 */
export async function getScanCopyTemplate(entity, entityId) {
  const { baseUrl } = await getMainPathForUrl(entity, entityId);
  const url = `${baseUrl}/scancopies/template`;
  return sendRequest(url, 'get')
    .then((response) => response.data)
    .then((data) => {
      if (data.signed_url) {
        return data.signed_url;
      }
      return '';
    });
}

/**
 * Подтвержедние скан-копии заявки
 * @param entityType
 * @param entityId
 * @param scanCopyId
 * @return {Promise<Promise<T>>}
 */
export async function acceptOnlineDocument(entityType, entityId, scanCopyId) {
  const { baseUrl } = await getMainPathForUrl(entityType, entityId);
  const url = `${baseUrl}/scancopies/${scanCopyId}/accept`;
  return sendRequest(url, 'post').then((data) => data);
}

/**
 * Отправка ПФЗ на подписание перевозчиком (в статус not_attached)
 * @param {string} shippingId
 * @param {string} scanCopyId
 * @returns {Promise<*>}
 */
export async function sendDocumentToReview(shippingId, scanCopyId) {
  const url = `/shippings/${shippingId}/scancopies/${scanCopyId}/send_to_review`;
  return sendRequest(url, 'post').then((data) => data);
}

/**
 * Перевод ПФЗ в статус accepted
 * @param {string} shippingId
 * @param {string} scanCopyId
 * @returns {Promise<*>}
 */
export async function acceptDocument(shippingId, scanCopyId) {
  const url = `/shippings/${shippingId}/scancopies/${scanCopyId}/accept`;
  return sendRequest(url, 'post').then((data) => data);
}

/**
 * Получение файла в виде массива байт
 * @param {string} url
 * @param {boolean} isAuthRequest
 * @return {Promise<String>}
 */
export async function getDocumentToSign(url, isAuthRequest) {
  // const token = await authTokenService.getToken();
  const params = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  };
  // if (isAuthRequest) params.headers['Authorization'] = `Bearer ${token}`; // TODO проверить необходимость
  return http.get(url, params).then((response) => response.data);
}

/**
 * Получение хэша файла
 * @param {arrayBuffer} documentBytes
 * @param {string} algorithm
 * @param {Object} cert
 * @param {string} scancopyId
 * @param {string} companyTitle
 * @param {string} companyKPP
 * @returns {Promise<*>}
 */
export async function getCalculatedHash(documentBytes, algorithm, cert, scancopyId, companyTitle, companyKPP) {
  const headers = { 'Content-Type': 'application/octet-stream' };
  const url = '/edm/calculate_hash';
  const params = {
    algorithm,
    location: cert.subject.country,
    contact: cert.subject.email,
    signature_creator: `${cert.subject.lastName} ${cert.subject.firstName}`,
    company_name: cert.subject.organization,
    certificate_publisher: cert.issuer.organization,
    document_id: scancopyId,
    certificate_serial_number: cert.serialNumber,
    certificate_start_date: cert.validFromDate,
    certificate_expiration_date: cert.validToDate,
    inn: cert.subject.inn,
    ogrn: cert.subject.ogrn || cert.subject.ogrnip,
    position_creator: cert.subject.position,
    ...(companyKPP && { kpp: companyKPP }),
  };
  return http.post(url, documentBytes, { responseType: 'arraybuffer', params, headers }).then((response) => {
    const hash = response.headers['x-edm-hash-value'];
    const documentBytes = response.data;
    return { hash, documentBytes };
  });
}

/**
 * Подписать документ
 * @param {string} signature
 * @param {arrayBuffer} documentBytes
 * @returns {Promise<*>}
 */
export async function signDocument(signature, documentBytes) {
  const url = 'edm/document/sign';
  let binary = '';
  const bytes = new Uint8Array(documentBytes);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  const fileBase64 = btoa(binary);

  const payload = { signature, file: fileBase64 };
  return http.post(url, payload, { responseType: 'arraybuffer' }).then((response) => response.data);
}

/**
 * Сохранить документ
 * @param {arrayBuffer} file
 * @param {string} shippingId
 * @param {string}scancopyId
 * @param {string} humanFriendlyId
 * @returns {Promise<*>}
 */
export async function saveDocument(file, shippingId, scancopyId, humanFriendlyId) {
  const url = `shippings/${shippingId}/scancopies/${scancopyId}/file`;
  const formData = new FormData();
  formData.append('file', file, `Печатная форма заявки №${humanFriendlyId}.pdf`);
  return sendRequest(url, 'post', formData, true).then((data) => data);
}

export async function getTempAttorney(shippingId) {
  let url = `/shippings/${shippingId}/attorney/template`;
  let { data } = await http.get(url);
  return data;
}

export async function getAttorney(shippingId) {
  let url = `/shippings/${shippingId}/attorney`;
  let { data } = await http.get(url, { cache: false });
  return data;
}

export async function uploadAttorney(shippingId, file) {
  let url = `/shippings/${shippingId}/attorney`;
  let tempResult = (file) =>
    new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onerror = (error) => reject(error);
      reader.onload = () => {
        const [, base64result] = String(reader.result)?.split(',');
        resolve(base64result);
      };
      reader.readAsDataURL(file);
    });

  let base64file = await tempResult(file);

  let { data } = await http.post(url, { content: base64file });
  return data;
}
