import React from 'react';
import getFullCompanyName from '../../../helpers/getFullCompanyName';
import { TContact, TCounterAgent } from '../../../store/counterAgents/models';
import moment from 'moment';
import {
  DIPLOMATIC_PASSPORT,
  DRIVER_LICENCE,
  EMAIL,
  EXTENSION_NUMBER,
  FOREIGN_CITIZEN_PASSPORT,
  FOREIGN_DRIVER_LICENSE,
  IDENTITY_KAZAKHSTAN,
  INTERNATIONAL_PASSPORT,
  IS_PERSON_INDIVIDUAL,
  LEGAL_ENTITY_FORM,
  LEGAL_ENTITY_INN,
  LEGAL_ENTITY_KPP,
  LEGAL_ENTITY_TITLE,
  MILITARY_IDENTITY,
  NAME,
  PASSPORT,
  PASSPORT_ISSUED_AT,
  PASSPORT_ISSUER,
  PASSPORT_NUMBER,
  PASSPORT_TYPE,
  PERSON_FULL_NAME,
  PHONE,
  REFUGE_REQUEST_CERTIFICATE,
  RESIDENCE_REQUEST,
  SAILOR_PASSPORT,
  SERVICE_PASSPORT,
  TEMPORARY_REFUGE_CERTIFICATE,
  USSR_PASSPORT,
} from '../../../store/counterAgents/formFields';
import { DATE_FORMAT } from '../../../constants';
import getFormattedPhone from '../../../helpers/getFormattedPhone';
import {
  PAYER_FULL_NAME,
  PAYER_INN,
  PAYER_IS_PRIVATE_PERSON,
  PAYER_KPP,
  PAYER_OPF_FORM,
  PAYER_PASSPORT_ISSUED_AT,
  PAYER_PASSPORT_ISSUER,
  PAYER_PASSPORT_NUMBER,
  PAYER_TITLE,
} from '../../../containers/order-v2/blocks/DocumentsBlock';

const PASSPORT_TYPES = new Map()
  .set(PASSPORT, 'Паспорт РФ')
  .set(DRIVER_LICENCE, 'Водительское удостоверение РФ')
  .set(INTERNATIONAL_PASSPORT, 'Загранпаспорт РФ')
  .set(MILITARY_IDENTITY, 'Военный билет РФ')
  .set(USSR_PASSPORT, 'Паспорт СССР')
  .set(IDENTITY_KAZAKHSTAN, 'Уд. личности РК (Республика Казахстан)')
  .set(FOREIGN_CITIZEN_PASSPORT, 'Паспорт иностранного гражданина')
  .set(SAILOR_PASSPORT, 'Паспорт моряка')
  .set(REFUGE_REQUEST_CERTIFICATE, 'Свидетельство о рассмотрении ходатайства о признании беженцем на территории РФ')
  .set(SERVICE_PASSPORT, 'Служебный паспорт')
  .set(DIPLOMATIC_PASSPORT, 'Дипломатический паспорт')
  .set(RESIDENCE_REQUEST, 'Вид на жительство лица без гражданства')
  .set(TEMPORARY_REFUGE_CERTIFICATE, 'Свидетельство о предоставлении временного убежища')
  .set(FOREIGN_DRIVER_LICENSE, 'Водительское удостоверение иностранного гражданина');

export function getCounterAgent(counter_agent: TCounterAgent) {
  if (!counter_agent) return null;

  if (counter_agent[IS_PERSON_INDIVIDUAL]) {
    const passport: string[] = [];
    if (counter_agent[PASSPORT_NUMBER]) {
      const passportType = PASSPORT_TYPES.get(counter_agent[PASSPORT_TYPE]) ?? '';
      passport.push(`${passportType} ${counter_agent[PASSPORT_NUMBER]}`);
    }
    if (counter_agent[PASSPORT_ISSUED_AT])
      passport.push(`, выдан ${moment(counter_agent[PASSPORT_ISSUED_AT]).format(DATE_FORMAT)} г.`);
    if (counter_agent[PASSPORT_ISSUER]) passport.push(` ${counter_agent[PASSPORT_ISSUER]}`);

    return [counter_agent[PERSON_FULL_NAME], passport.join('')].filter(Boolean).join(', ');
  }

  const result: string[] = [];
  if (counter_agent[LEGAL_ENTITY_TITLE] || counter_agent[LEGAL_ENTITY_FORM]) {
    result.push(getFullCompanyName(counter_agent[LEGAL_ENTITY_TITLE] ?? '', counter_agent[LEGAL_ENTITY_FORM] ?? ''));
  }
  if (counter_agent[LEGAL_ENTITY_INN]) {
    result.push(`ИНН ${counter_agent[LEGAL_ENTITY_INN]}`);
  }
  if (counter_agent[LEGAL_ENTITY_KPP]) result.push(`КПП ${counter_agent[LEGAL_ENTITY_KPP]}`);

  return result.filter(Boolean).join(', ');
}

export function getPayer(payer) {
  if (!payer) return null;

  if (payer[PAYER_IS_PRIVATE_PERSON]) {
    const passport: string[] = [];
    if (payer[PAYER_PASSPORT_NUMBER]) {
      const passportType = PASSPORT_TYPES.get(PASSPORT) ?? '';
      passport.push(`${passportType} ${payer[PAYER_PASSPORT_NUMBER]}`);
    }
    if (payer[PAYER_PASSPORT_ISSUED_AT])
      passport.push(`, выдан ${moment(payer[PAYER_PASSPORT_ISSUED_AT]).format(DATE_FORMAT)} г.`);
    if (payer[PAYER_PASSPORT_ISSUER]) passport.push(` ${payer[PAYER_PASSPORT_ISSUER]}`);

    return [payer[PAYER_FULL_NAME], passport.join('')].filter(Boolean).join(', ');
  }

  const result: string[] = [];
  if (payer[PAYER_TITLE] || payer[PAYER_OPF_FORM]) {
    result.push(getFullCompanyName(payer[PAYER_TITLE] ?? '', payer[PAYER_OPF_FORM] ?? ''));
  }
  if (payer[PAYER_INN]) {
    result.push(`ИНН ${payer[PAYER_INN]}`);
  }
  if (payer[PAYER_KPP]) result.push(`КПП ${payer[PAYER_KPP]}`);

  return result.filter(Boolean).join(', ');
}

export function getContacts(contacts: TContact[]) {
  if (!contacts) return null;

  const contactsList: string[] = [];
  const emailList: string[] = [];

  contacts.forEach((contact) => {
    const contactFields: string[] = [contact[NAME]];
    if (contact[PHONE]) contactFields.push(getFormattedPhone(contact[PHONE]).replace(/\s/g, '\u00a0'));
    if (contact[PHONE] && contact[EXTENSION_NUMBER]) contactFields.push(`доб. ${contact[EXTENSION_NUMBER]}`);
    // if (contact[EMAIL]) contactFields.push(contact[EMAIL]!);

    // @ts-ignore
    contactsList.push(contactFields.filter(Boolean).join(', '));
    // @ts-ignore
    emailList.push(!!contact[EMAIL] ? contact[EMAIL] : null);
  });

  return contactsList.filter(Boolean).map((contact, index) => (
    <div key={`contact_${contact}`} className="mt-0 mb-2">
      {contact}
      {/*<div className="data-truncate">{emailList[index]}</div>*/}
      {!!emailList[index] && (
        <div className="data-truncate">
          <a className="link" href={`mailto:${emailList[index]?.toLowerCase()!}`}>
            {emailList[index]?.toLowerCase()}
          </a>
        </div>
      )}
    </div>
  ));
}
