import React from 'react';
import SVGIcon from './index';

function IconClock(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M16.5 10C16.5 13.5899 13.5899 16.5 10 16.5C6.41015 16.5 3.5 13.5899 3.5 10C3.5 6.41015 6.41015 3.5 10 3.5C13.5899 3.5 16.5 6.41015 16.5 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM10.5 5H9V10.4157L13.3525 13.136L14.1475 11.864L10.5 9.58431V5Z"
        fillRule="evenodd"
      />
    </SVGIcon>
  );
}

IconClock.defaultProps = {
  color: 'base',
};

export default React.memo(IconClock);
