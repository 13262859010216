import React, { createContext, useContext, useMemo, useState } from 'react';
import EmptySearch from '../../EmptySearch';

const TableWrapperContext = createContext({
  onVisibleItemsChange: (ids) => {},
});

export function useTableWrapperContext() {
  const context = useContext(TableWrapperContext);
  return context || {};
}

export function TableWrapper({ isEmptyList = false, pagination, children }) {
  const [visibleItemsIds, setVisibleItemsIds] = useState([]);

  const context = useMemo(() => {
    return {
      onVisibleItemsChange: (ids) => {
        setVisibleItemsIds(ids);
      },
    };
  }, []);

  return (
    <TableWrapperContext.Provider value={context}>
      {isEmptyList && <EmptySearch />}
      {!isEmptyList && children}
      {!!visibleItemsIds?.length && pagination}
    </TableWrapperContext.Provider>
  );
}
