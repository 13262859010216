import React from 'react';
import classNames from 'classnames';
import Popover from '../Popover';

interface IProps {
  content: React.ReactNode | string;
  children: React.ReactNode;
  className?: string;
  [key: string]: any;
}

function Tooltip({ content, children, className, ...rest }: IProps) {
  return (
    <Popover trigger="hover" placement="top" content={content} className={classNames(className, 'tooltip')} {...rest}>
      {children}
    </Popover>
  );
}

export default React.memo(Tooltip);
