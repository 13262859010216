import moment from 'moment';
import { CAR, DRIVER, TRAILER } from '../../../store/resources/formKeys';
import { DATE_FORMAT } from '../../../constants';

export function getResourceTitle(resource, type) {
  let formattedDate = moment(resource?.createdAt).format(DATE_FORMAT);
  let hfId = resource?.humanFriendlyId;
  let title = resource?.title;
  let subtitle = resource?.subtitle;

  if (type === DRIVER) {
    const driverLabel = `Водитель № ${hfId} от ${formattedDate}`;
    return {
      headerLabel: driverLabel,
      headerTitle: title,
    };
  }

  if (type === CAR) {
    const carLabel = `Автомашина № ${hfId} от ${formattedDate}`;
    return {
      headerLabel: carLabel,
      headerTitle: title,
      headerSubtitle: subtitle,
    };
  }

  if (type === TRAILER) {
    const carLabel = `Прицеп № ${hfId} от ${formattedDate}`;
    return {
      headerLabel: carLabel,
      headerTitle: title,
      headerSubtitle: subtitle,
    };
  }

  return {
    headerLabel: '',
    headerTitle: '',
    headerSubtitle: '',
  };
}
