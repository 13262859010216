import * as actionTypes from './actionTypes';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

const initialState = {
  items: [],
  activeItem: null,
  totalItems: 0,
  currentPage: 0,
  totalPages: 0,
  resultsPerPage: 20,
  transportFiles: null,
  printableForm: null,
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    // Инициализировать список заявок
    case actionTypes.INIT_ITEMS:
      draft.items = action.payload.items;
      draft.totalItems = action.payload.totalItems;
      draft.currentPage = action.payload.currentPage;
      draft.totalPages = action.payload.totalPages;
      draft.resultsPerPage = action.payload.resultsPerPage;
      break;
    // Инициализировать список заявок
    case actionTypes.LOAD_MORE_ITEMS:
      draft.items = draft.items.concat(action.payload.items);
      draft.totalItems = action.payload.totalItems;
      draft.currentPage = action.payload.currentPage;
      draft.totalPages = action.payload.totalPages;
      draft.resultsPerPage = action.payload.resultsPerPage;
      break;

    // Установить текущую перевозку
    case actionTypes.CURRENT_SET:
      draft.activeItem = action.payload;
      break;

    // Обновить транспортною накладную
    case actionTypes.UPDATE_TRANSPORT_FILES:
      draft.transportFiles = action.payload;
      break;

    // Обновить печатную форму
    case actionTypes.UPDATE_PRINTABLE_FORM:
      draft.printableForm = action.payload;
      break;

    // Сбросить редьюсер в первоначальное состояние
    case actionTypes.RESET:
      return initialState;

    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
