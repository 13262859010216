import React, { ReactElement, useCallback } from 'react';
import styles from './PageHeader.module.scss';
import { useHistory, Link } from 'react-router-dom';

import Button from '../Button';
import IconArrowLeft from '../Icons/SVGIcons/IconArrowLeft';
import classNames from 'classnames';

interface IProps {
  backLinkText?: string | React.ReactElement;
  header: string | React.ReactElement;
  backLink?: string;
  pageHeaderClassName?: string;
  backLinkClassName?: string;
  headerClassName?: string;
  backLinkCallback?: any;
  leftElement?: ReactElement;
  rightElement?: ReactElement;
}

function PageHeader({
  backLinkText,
  header,
  backLink,
  pageHeaderClassName,
  backLinkClassName,
  headerClassName,
  backLinkCallback,
  leftElement,
  rightElement,
}: IProps) {
  const history = useHistory();
  const component = backLink ? <Link to={backLink} className="flex items-center link" /> : 'button';
  const handleClick = useCallback(() => {
    if (!backLink) history.goBack();
    backLinkCallback?.();
    // (backLink ? {} : history.goBack())
  }, [backLink, history, backLinkCallback]);

  return (
    <hgroup className={classNames(styles.pageHeader, pageHeaderClassName)}>
      {backLinkText && (
        <Button
          type="button"
          theme="text"
          component={component}
          onClick={handleClick}
          className={classNames(styles.backLink, backLinkClassName)}
        >
          <IconArrowLeft width="20" height="20" color="primary" className="mr-1" />
          {backLinkText}
        </Button>
      )}
      {!!leftElement && <div className={styles.leftElement}>{leftElement}</div>}
      <h3 className={classNames(styles.header, headerClassName)}>{header}</h3>
      <div className={styles.rightElement}>{rightElement}</div>
    </hgroup>
  );
}

export default React.memo(PageHeader);
