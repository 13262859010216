import getFormattedPrice from '../../helpers/getFormattedPrice';
import { isDLT } from './helpers';
import INSURANCE_DOCS from './components/TermsDocsLinks';
import React from 'react';

export const INSURANCE_STATUSES = {
  REQUESTED: 'REQUESTED', // мультиполис запрошен. статус только у перевоза
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELLED', // при отмене заказа когда страховка в статусе REQUESTED
  ERROR: 'ERROR', // если не удается заказать страховку, которая в статусе REQUESTED
  REVOKED: 'REVOKED', // только из ACTIVE
  PENDING_FOR_REVOCATION: 'PENDING_FOR_REVOCATION', // запрос на аннулирование был отправлен, но завершился ошибкой
};

export const INSURANCE_REVOKED_STATUSES = [
  INSURANCE_STATUSES.REVOKED,
  INSURANCE_STATUSES.CANCELED,
  INSURANCE_STATUSES.PENDING_FOR_REVOCATION,
];

export const WITH_POLICY = 'with_policy';
export const POLICY_EMAIL = 'policy_email';
export const POLICY_PERIOD = 'policy_period';
export const POLICY_PERIOD_START = 'policy_period_start';
export const POLICY_PERIOD_END = 'policy_period_end';

export const CONFIRM_MULTIPOLIS = 'confirm_multipolis';
export const CONFIRM_PERSONAL_DATA = 'confirm_personal_data';

export const BILL_TYPE = 'bill_type';

export const SINGLE_BILL = 'single_bill';
export const SEPARATED_BILL = 'separated_bill';

export const BILL_TYPES_MAP = {
  SINGLE: SINGLE_BILL,
  SEPARATED: SEPARATED_BILL,
};

export const BILL_TYPES_ITEMS = [
  {
    key: SINGLE_BILL,
    label: 'Единым счётом',
  },
  {
    key: SEPARATED_BILL,
    label: 'Раздельным счётом',
  },
];

export const SHIPPING_VALIDATION_STATUSES = {
  OK: 'OK',
  WARNING: 'WARNING',
  INVALID: 'INVALID',
};

export const INSURANCE_OPTIONS = {
  MULTIPOLICY: 'multipolicy',
  ALFA: 'alfa',
  OWN: 'own',
};

export const VALIDATIONS = {
  ROUTE_POINTS_COUNT: 'ROUTE_POINTS_COUNT',
  CARGO_DANGER_LEVEL: 'CARGO_DANGER_LEVEL',
  CARGO_TYPE: 'CARGO_TYPE',
  CREATE_DATE_TIME_LIMIT: 'CREATE_DATE_TIME_LIMIT',
  CARGO_COST: 'CARGO_COST',
  SENDER_STATE: 'SENDER_STATE',
  CARRIER_STATE: 'CARRIER_STATE',
  CANCELLATION_DATE_TIME_LIMIT: 'CANCELLATION_DATE_TIME_LIMIT',
  SHIPPING_REQUEST_TYPE: 'SHIPPING_REQUEST_TYPE',
};

export const VALIDATIONS_MAP = {
  [VALIDATIONS.ROUTE_POINTS_COUNT]: {
    getLabel: () => 'в маршруте указана 1 или более 20 точек',
  },
  [VALIDATIONS.CARGO_DANGER_LEVEL]: {
    getLabel: () => 'груз заявлен с каким-либо классом опасности',
  },
  [VALIDATIONS.CARGO_TYPE]: {
    getLabel: (validation, { isCargoOwningUser, transportCompanyId }) => (
      <span>
        тип груза не соответствует разрешённым к страхованию, прописанных в{' '}
        {isCargoOwningUser ? (
          isDLT(transportCompanyId) ? (
            <INSURANCE_DOCS.RulesForCoWithDlTrans text="Полисных условиях" />
          ) : (
            <INSURANCE_DOCS.RulesForCo text="Полисных условиях" />
          )
        ) : (
          <INSURANCE_DOCS.RulesForTc text="Полисных условиях" />
        )}
      </span>
    ),
  },
  [VALIDATIONS.CREATE_DATE_TIME_LIMIT]: {
    getLabel: () => 'до прибытия на загрузку менее 3-х часов',
  },
  [VALIDATIONS.CARGO_COST]: {
    getLabel: ({ expected }, { transportCompanyId }) => (
      <span>
        стоимость груза более {getFormattedPrice(expected)}₽
        {isDLT(transportCompanyId) && (
          <>
            {'. '}
            Чтобы оформить страховой полис, напишите на почту{' '}
            <a href={`mailto:${RENINS_MANAGER_EMAIL}`} className={'link'}>
              {RENINS_MANAGER_EMAIL}
            </a>
          </>
        )}
      </span>
    ),
  },
  [VALIDATIONS.SENDER_STATE]: {
    getLabel: () => 'для заявок грузовладельца оформление «Мультиполиса» недоступно',
  },
  [VALIDATIONS.CARRIER_STATE]: {
    getLabel: () => 'для данного перевозчика оформление «Мультиполиса» недоступно',
  },
  [VALIDATIONS.CANCELLATION_DATE_TIME_LIMIT]: {
    getLabel: () => 'вышло время для отмены страховки', // TODO текст ошибки
  },
  [VALIDATIONS.SHIPPING_REQUEST_TYPE]: {
    getLabel: () => 'Нельзя застраховать почасовую заявку',
  },
};

export const VALIDATIONS_STATUSES = {
  OK: 'OK',
  WARNING: 'WARNING',
  INVALID: 'INVALID',
};

export const COMPANY_MULTIPOLICY_STATUSES = {
  PERMITTED: 'PERMITTED',
  FORBIDDEN: 'FORBIDDEN',
  FORBIDDEN_ALL: 'FORBIDDEN_ALL',
};

export const INSURANCE_SUPPORT_EMAIL = 'pp_scs@traffic.online';
export const RENINS_MANAGER_EMAIL = 'OKrylova@spb.renins.com';

/* cargo owner profile insurance settings */
// settings:
export const INSURANCE_SUM = 'insurance_price';
export const TONNAGE = 'tonnage';
export const INSURANCE_OPTION = 'type';
// order form:
export const INSURANCE_REQUIRED = 'insurance_required';
// поля для хранения настроек страхования на момент создания заказа/контракта:
export const INSURANCE_METHOD = 'insurance_method';
export const INHERITANCE_MIN_INSURANCE_SUM = 'insurance_min_amount';
export const INHERITANCE_TONNAGE = 'insure_vehicle_tonnage';
