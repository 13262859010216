import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import Button, { TTheme as TThemeButton } from '../Button';

type TTheme = 'default' | 'grey' | 'thin' | 'darkTitle';

interface EmptyProps {
  title?: string | React.ReactNode;
  message?: string;
  caption?: string;
  styleModifier?: string;
  theme: TTheme;
  buttonTitle?: string;
  buttonTheme?: TThemeButton;
  onButtonClick?: () => void;
}

const EmptySearch = ({
  title,
  message,
  caption,
  styleModifier,
  theme,
  buttonTitle,
  onButtonClick,
  buttonTheme = 'primary',
}: EmptyProps) => (
  <section className={classNames(styleModifier ? styles[styleModifier] : styles.emptyWrapper, styles[theme])}>
    <h3 className="color-base mt-0 mb-0">{title}</h3>
    <p className="color-dark mt-2 mb-0">{message}</p>
    {!!caption && <p className="caption mt-1 mb-0">{caption}</p>}
    {buttonTitle && onButtonClick && (
      <Button className="mt-3 pl-4 pr-4" theme={buttonTheme} size="medium" onClick={onButtonClick}>
        {buttonTitle}
      </Button>
    )}
  </section>
);

EmptySearch.defaultProps = {
  title: 'Ничего не найдено',
  message: 'Уточните параметры поиска',
  theme: 'default',
};

export default EmptySearch;
