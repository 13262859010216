import React, { lazy, Suspense } from 'react';

import { CONTRACT_BY_COUNT, ORDER_TYPE, SHIPPING_REQUEST } from '../OrderNFormTypeBlock';
import { useFieldValue, useScrollToError } from '../../../../components/FormikFieldsV3/hooks';
import { useExpandBlocksOnError } from '../../hooks/useSubmitErrors';
import ContentLoader from '../../../../components/ContentLoader';
import { getHashString } from '../../helpers/getHashString';
import useQuotes from '../../hooks/useQuotes';

const ShippingRequestActionsBlock = lazy(() => import('./ShippingRequestActionsBlock'));
const ShippingRequestQuotesActionsBlock = lazy(() => import('./ShippingRequestQuotesActionsBlock'));

function getBlockComponent(hashString) {
  switch (hashString) {
    // Спот + упрощенка
    case getHashString({ orderType: SHIPPING_REQUEST }):
    case getHashString({ orderType: CONTRACT_BY_COUNT }):
      return ShippingRequestActionsBlock;
    // заявки по квотам
    case getHashString({ orderType: SHIPPING_REQUEST, withQuotes: true }):
      return ShippingRequestQuotesActionsBlock;
    default:
      return null;
  }
}

/**
 * Блок кнопок-экшнов
 * @constructor
 */
export function ActionsBlock() {
  const [orderType] = useFieldValue(ORDER_TYPE);
  const hashParams = { orderType };

  const { withQuotes } = useQuotes();
  if (withQuotes && orderType === SHIPPING_REQUEST) hashParams['withQuotes'] = true;

  const hashString = getHashString(hashParams);
  const Component = getBlockComponent(hashString);

  /* Разворачивание блоков точек маршрута */
  useExpandBlocksOnError();
  /* Скролл к ошибкам */
  useScrollToError();

  return <Suspense fallback={<ContentLoader withBackground />}>{Component ? <Component /> : null}</Suspense>;
}
