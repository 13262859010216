import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type TSize = 'xsmall' | 'small' | 'medium' | 'xmedium' | 'xxmedium' | 'large' | 'xLarge';
export type TTheme = 'light' | 'dark' | 'grey' | 'lighter' | 'primary' | 'success' | 'warning' | 'danger';
type TShape = 'round' | 'square';

interface IProps {
  logo?: string | null;
  icon?: JSX.Element | null;
  string: string;
  size?: TSize;
  theme?: TTheme;
  shape?: TShape;
  className?: string;
}

function LetterBox({
  logo,
  icon,
  string,
  className,
  size,
  theme,
  shape,
  ...rest
}: IProps & React.HtmlHTMLAttributes<HTMLDivElement>) {
  const firstLetter = string?.[0]?.toUpperCase();
  const letterClassName = classNames(
    styles.letterBox,
    {
      [styles[theme!]]: theme,
      [styles[size!]]: size,
      [styles[shape!]]: shape,
    },
    className,
  );

  return (
    <div className={letterClassName} {...rest}>
      {logo ? <img src={logo} alt="Лого компании" className={styles.withLogo} /> : icon ? icon : firstLetter}
    </div>
  );
}

LetterBox.defaultProps = {
  size: 'medium',
  theme: 'light',
};

export default LetterBox;
