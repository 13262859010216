import { AUCTION, INSTANT } from '../../../constants';
import * as formKeys from '../../../store/order/auction/formKeys';
import { TAuctionStage, TExtendAuctionStage } from '../../../containers/order-list/models';

export function getAuctionPrice(withoutVat) {
  return `${withoutVat} ₽`;
}

export function getAuctionInfo(auctionInfo: TAuctionStage, prevAuctionInfo: TAuctionStage): TExtendAuctionStage {
  const { auctionType } = auctionInfo;

  const type = auctionType === INSTANT ? auctionTypes.get(INSTANT) : auctionTypes.get(AUCTION);
  const subtype = auctionSubtypes.get(auctionType) || '';

  return {
    ...auctionInfo,
    type,
    subtype,
    bidStep: `${auctionInfo.bidStep} ₽`,
    prevStageEnds: prevAuctionInfo?.ends,
  };
}

const auctionTypes = new Map().set(AUCTION, 'Аукцион').set(INSTANT, 'Моментальное бронирование');

const auctionSubtypes = new Map()
  .set(formKeys.OPEN_AUCTION, 'Открытый')
  .set(formKeys.BLIND_AUCTION, 'Закрытый')
  .set(formKeys.DESCENDING_PRICE_AUCTION, 'На понижение');
