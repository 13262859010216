import { createContext, useContext } from 'react';
import { TRequestExpeditedPayment } from '../models';

const ExpeditedPaymentContext = createContext<TRequestExpeditedPayment>({
  isExpeditedPaymentDisabled: false,
  isExpeditedPaymentLoading: false,
  isExpeditedPayment: false,
  setExpeditedPayment(value: ((prevState: boolean) => boolean) | boolean): void {},
  onExpeditedPaymentToggle(): void {},
  expeditedPaymentDays: 0,
  setExpeditedPaymentDays(value: ((prevState: number) => number) | number): void {},
  userBid: null,
  setUserBid(value: ((prevState: number | null) => number | null) | number | null): void {},
  expeditedPaymentUserBid: null,
  setExpeditedPaymentUserBid(value: ((prevState: number | null) => number | null) | number | null): void {},
  resultUserBid: 0,
  resultLimits: [0, 0],
  expeditedPaymentInitialBid: null,
  expeditedPaymentWinningBid: null,
  resetExpeditedPayment(): void {},
});

export function useExpeditedPaymentContext(): TRequestExpeditedPayment {
  const store = useContext<TRequestExpeditedPayment>(ExpeditedPaymentContext);

  if (!store) {
    throw new Error('Cannot use `useExpeditedPaymentContext` outside of a ExpeditedPaymentContext.Provider');
  }

  return store;
}

export const ExpeditedPaymentContextProvider = ExpeditedPaymentContext.Provider;
