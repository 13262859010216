import React from 'react';
import SVGIcon from './index';

function IconNotification(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 8.97434V13.0769L17 14.7179V15.5384H3V14.7179L4.75 13.0769V8.97434C4.75 6.44717 6.17625 4.34666 8.6875 3.78871V2H11.3125V3.78871C13.815 4.34666 15.25 6.45537 15.25 8.97434ZM11.75 16.359C11.75 17.2616 10.9625 18 10 18C9.02877 18 8.25002 17.2616 8.25002 16.359H11.75Z"
      />
    </SVGIcon>
  );
}

IconNotification.defaultProps = {
  color: 'base',
};

export default React.memo(IconNotification);
