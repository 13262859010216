import React from 'react';
import SVGIcon from './index';

const IconSaveTemplate = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10H16V4H4V16H10V15H5V7H15V10ZM10 13V12H9V13H10ZM13 10H10H9V9H13V10ZM7 9H8V10H7V9ZM7 12H8V13H7V12ZM11 11H16L18 13V18H11V11ZM15.5 12H12V13.5H15.5V12ZM14.5 16.5C15.0523 16.5 15.5 16.0523 15.5 15.5C15.5 14.9477 15.0523 14.5 14.5 14.5C13.9477 14.5 13.5 14.9477 13.5 15.5C13.5 16.0523 13.9477 16.5 14.5 16.5Z"
      />
    </SVGIcon>
  );
};

export default IconSaveTemplate;
