import React from 'react';

import TimeLeft from '../../TimeLeft';
import { IconAssignmentCheck, IconAssignmentLate, IconDriver, IconRequirementsChange } from '../../Icons';
import { PENDING_REVIEW } from '../../../store/requirements-change/constants';
import { EOrderDocsProcessStatuses, EOrderStatuses } from '../../../constants/order';

/**
 * Получаем значение статуса заказа по хешу
 * @param orderStatus
 * @param scanCopyStatus
 * @param resourceDeadline
 * @param shippingChangeRequestStatus
 * @returns {*}
 */
export function getOrderStatusByHash({
  orderStatus,
  scanCopyStatus = EOrderDocsProcessStatuses.DOC_OWNER_SIGNING,
  resourceDeadline,
  shippingChangeRequestStatus,
}) {
  const changeRequestStatus = shippingChangeRequestStatus === PENDING_REVIEW && PENDING_REVIEW;
  const hash = changeRequestStatus || `${orderStatus}-${scanCopyStatus}`;
  const { text, icon } = statuses.get(hash) || {};

  const timers = new Map().set(
    `${EOrderStatuses.RESERVED}-${EOrderDocsProcessStatuses.DOC_OWNER_SIGNING}`,
    <TimeLeft title={text} time={resourceDeadline ?? '2010-10-10 00:00:00'} withTimerIcon={false} />,
  );

  return (
    <div className="flex items-start">
      {icon || <IconAssignmentLate width="20" height="20" color="base" />}
      <div className="flex column">
        <span className="ml-1 color-dark">{timers.get(hash) || text}</span>
      </div>
    </div>
  );
}

/* Сет из статусов с ключами-хешами */
const statuses = new Map()
  .set(`${EOrderStatuses.RESERVED}-${EOrderDocsProcessStatuses.DOC_OWNER_SIGNING}`, {
    text: 'Ожидание ресурсов',
    icon: <IconDriver width="20" height="20" color="base" />,
  })
  .set(`${EOrderStatuses.TRIP_WAITING}-${EOrderDocsProcessStatuses.DOC_IN_PROGRESS}`, {
    text: 'Оформление документов',
  })
  .set(`${EOrderStatuses.TRIP_WAITING}-${EOrderDocsProcessStatuses.DOC_OWNER_SIGNING}`, {
    text: 'Документы на подписи',
  })
  .set(`${EOrderStatuses.TRIP_WAITING}-${EOrderDocsProcessStatuses.NEED_RESIGN}`, {
    text: 'Документы на подписи',
  })
  .set(`${EOrderStatuses.TRIP_WAITING}-${EOrderDocsProcessStatuses.DOC_NOT_ATTACHED}`, {
    text: 'Документы на подписи',
  })
  .set(`${EOrderStatuses.TRIP_WAITING}-${EOrderDocsProcessStatuses.DOC_ACCEPTED}`, {
    text: 'Документы оформлены',
    icon: <IconAssignmentCheck width="20" height="20" color="base" />,
  })
  .set(`${EOrderStatuses.IN_TRIP}-${EOrderDocsProcessStatuses.DOC_OWNER_SIGNING}`, {
    text: 'Проверка документов',
  })
  .set(`${EOrderStatuses.IN_TRIP}-${EOrderDocsProcessStatuses.DOC_NOT_ATTACHED}`, {
    text: 'Документы на подписи',
  })
  .set(`${EOrderStatuses.IN_TRIP}-${EOrderDocsProcessStatuses.NEED_RESIGN}`, {
    text: 'Документы на подписи',
  })
  .set(`${EOrderStatuses.IN_TRIP}-${EOrderDocsProcessStatuses.DOC_ACCEPTED}`, {
    text: 'Документы оформлены',
    icon: <IconAssignmentCheck width="20" height="20" color="base" />,
  })
  .set(PENDING_REVIEW, {
    text: 'Изменения внесены',
    icon: <IconRequirementsChange width="20" height="20" color="warning" />,
  });
