import _get from 'lodash/get';
import { createSelector, defaultMemoize } from 'reselect';
import passwordValidation from '../../helpers/passwordValidation';
import {
  EMAIL,
  FULL_NAME,
  LOGIN,
  PASSWORD_NEW,
  PASSWORD_OLD,
  PHONE_PERSON,
  CARGO_OWNING_ADMIN,
  POSITION,
  ID,
  ROLES,
  COMMENT,
  TRANSPORT_COMPANY_ADMIN,
  COMPANY_ID,
  ATTORNEY,
  ICQ,
  VIBER,
  SKYPE,
  WHATS_APP,
  TELEGRAM,
  CREATED_AT,
} from './formFields';
import { IUserInfo } from './models';
import { getIn } from 'formik';

export const getIsAuthenticated = createSelector(
  (state: any) => state?.user,
  (state: any) => state?.company,
  (userData: any, companyData: any): boolean => !!userData?.id && !!companyData?.id,
);

export const getPasswordValidation = createSelector(
  (values: IUserInfo): string => values[PASSWORD_NEW],
  passwordValidation,
);

export const getUserInfo = createSelector<any, any, any, IUserInfo | null>(
  (state: any) => _get(state, 'user'),
  (state: any) => _get(state, 'company'),
  (userData: any, company: any): IUserInfo | null => {
    if (!userData) return null;

    return {
      [ID]: userData?.id,
      [CREATED_AT]: userData?.created_at,
      [LOGIN]: userData?.username,
      [EMAIL]: userData?.email,
      [FULL_NAME]: userData?.full_name,
      [PHONE_PERSON]: userData?.phone,
      [POSITION]: userData?.position ?? '',
      [PASSWORD_OLD]: '',
      [PASSWORD_NEW]: '',
      [ROLES]: userData?.roles?.[0],
      [COMMENT]: userData?.comment ?? '',
      [COMPANY_ID]: userData?.company_id,
      [ATTORNEY]: company?.attorney_requirement,
      [ICQ]: userData?.icq ?? '',
      [VIBER]: userData?.viber ?? '',
      [SKYPE]: userData?.skype ?? '',
      [WHATS_APP]: userData?.whats_app ?? '',
      [TELEGRAM]: userData?.telegram ?? '',
    };
  },
);

export const getUserId = defaultMemoize((state: any): string => state?.user?.id);

export const getUserFirstLetter = createSelector<any, string, string>(
  (state: any): string => state?.user?.username,
  (username: string): string => {
    if (username) {
      return username[0].toUpperCase();
    }
    return '';
  },
);

export const getFullName = defaultMemoize((state: any): string => _get(state, 'user.full_name'));

export const getEmail = defaultMemoize((state: any): string => _get(state, 'user.email'));

export const getUserProfileUpdateData = createSelector(
  (state: any) => getIn(state, FULL_NAME),
  (state: any) => getIn(state, PASSWORD_OLD),
  (state: any) => getIn(state, PASSWORD_NEW),
  (state: any) => getIn(state, EMAIL),
  (state: any) => getIn(state, POSITION),
  (state: any) => getIn(state, COMMENT),
  (state: any) => getIn(state, PHONE_PERSON),
  (state: any) => getIn(state, ICQ),
  (state: any) => getIn(state, VIBER),
  (state: any) => getIn(state, SKYPE),
  (state: any) => getIn(state, WHATS_APP),
  (state: any) => getIn(state, TELEGRAM),
  (
    fullName: string,
    oldPassword: string,
    newPassword: string,
    email: string,
    position: string,
    comment: string,
    phone: string,
    icq: string,
    viber: string,
    skype: string,
    whats_app: string,
    telegram: string,
  ): Array<string> => [
    fullName,
    oldPassword,
    newPassword,
    email,
    position,
    comment,
    phone,
    icq,
    viber,
    skype,
    whats_app,
    telegram,
  ],
);

/**
 * Проводились ли изменений в форме
 */
export const isProfileFormChanged = (initialValues) =>
  createSelector(
    (values: any) => getUserProfileUpdateData(values),
    ([fullName, oldPassword, newPassword, email]: Array<string>): Array<boolean> => [
      fullName !== initialValues.fullName || email !== initialValues.email,
      !!oldPassword && !!newPassword,
    ],
  );

/**
 * Проверка, является ли пользователь экспедитором
 * @type {OutputSelector<any, *, (res: (*|Array)) => *>}
 */
export const isCargoOwningUser = createSelector<any, Array<any>, boolean>(
  (state: any): Array<any> => _get(state, 'user.roles') || [],
  (roles: Array<any>): boolean => !!roles.find((role: any) => role === CARGO_OWNING_ADMIN),
);
/* Относится роль грузовладельцу */
export const getRoleIsCargoOwner = defaultMemoize((state) => (state?.user?.roles ?? [])?.[0]?.includes('CargoOwning'));

export const getIsAdmin = defaultMemoize((state) =>
  (state?.user?.roles ?? []).some((role) => role === CARGO_OWNING_ADMIN || role === TRANSPORT_COMPANY_ADMIN),
);

export const getUserCompanyId = createSelector<any, Array<any>, string>(
  (state: any): Array<any> => _get(state, 'company'),
  (companyData: Array<any>): string => _get(companyData, 'id'),
);

export const confirmedDocs = defaultMemoize((state) => state?.user?.confirmedDocs);
