import React from 'react';
import SVGIcon from './index';

function IconOrganizations(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M14 13H15V14H14V13Z" />
      <path d="M14 11H15V12H14V11Z" />
      <path d="M14 9H15V10H14V9Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2H12V6H18V17H3V2ZM12 9V8L16 8V15H12V14H13V13H12V12H13V11H12V10H13V9H12ZM5 4H6V5H5V4ZM6 6H5V7H6V6ZM5 8H6V9H5V8ZM6 10H5V11H6V10ZM5 12H6V13H5V12ZM5 14H6V15H5V14ZM7 14H8V15H7V14ZM10 14H9V15H10V14ZM8 12H7V13H8V12ZM9 12H10V13H9V12ZM7 10H8V11H7V10ZM10 10H9V11H10V10ZM8 8H7V9H8V8ZM9 8H10V9H9V8ZM7 6H8V7H7V6ZM10 6H9V7H10V6ZM8 4H7V5H8V4ZM9 4H10V5H9V4Z"
      />
    </SVGIcon>
  );
}

IconOrganizations.defaultProps = { color: 'base' };

export default React.memo(IconOrganizations);
