import React, { lazy, Suspense } from 'react';

import ContentLoader from '../../../../components/ContentLoader';
import { useOrderContext } from '../../index';
import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import { CONTRACT_BY_COUNT, FORM_TYPE, FULL, ORDER_TYPE, SHIPPING_REQUEST, SIMPLE } from '../OrderNFormTypeBlock';
import { getHashString } from '../../helpers/getHashString';

const InsuranceBlock = lazy(() => import('./InsuranceBlock'));

function getBlockComponent(hashString) {
  switch (hashString) {
    // спот
    case getHashString({ isOrderBasedOnContract: false, orderType: SHIPPING_REQUEST, formType: FULL }):
    case getHashString({ isOrderBasedOnContract: false, orderType: SHIPPING_REQUEST, formType: SIMPLE }):
      return InsuranceBlock;
    // контракт
    case getHashString({ isOrderBasedOnContract: false, orderType: CONTRACT_BY_COUNT, formType: FULL }):
    case getHashString({ isOrderBasedOnContract: false, orderType: CONTRACT_BY_COUNT, formType: SIMPLE }):
      return InsuranceBlock;
    // заявка по контракту + полная форма
    case getHashString({ isOrderBasedOnContract: true, orderType: SHIPPING_REQUEST, formType: FULL }):
      return InsuranceBlock;
    default:
      return null;
  }
}

export function RequiredInsuranceBlock() {
  const [formType] = useFieldValue(FORM_TYPE);
  const [orderType] = useFieldValue(ORDER_TYPE);
  const { isOrderBasedOnContract } = useOrderContext();

  const hashString = getHashString({ isOrderBasedOnContract, orderType, formType });
  const Component = getBlockComponent(hashString);
  return <Suspense fallback={<ContentLoader withBackground />}>{Component ? <Component /> : null}</Suspense>;
}
