import * as formKeys from '../../../store/order/formalization/formKeys';

export const documents = [
  { label: 'Счёт', value: formKeys.BILL },
  { label: 'ТрН', value: formKeys.BILL_OF_LADING },
  { label: 'Акт об оказании услуг', value: formKeys.ACCEPTANCE_CERTIFICATE },
  { label: 'Акт о выгрузке', value: formKeys.DELIVERY_CERTIFICATE },
  { label: 'Счёт-фактура', value: formKeys.INVOICE },
  { label: 'ТТН', value: formKeys.WAYBILL },
  { label: 'ТН', value: formKeys.PACKING_LIST },
  { label: 'Доверенность', value: formKeys.POWER_OF_ATTORNEY },
  { label: 'УПД', value: formKeys.UNIVERSAL_TRANSFER_DOC },
  { label: 'Сопроводительный документ', value: formKeys.ACCOMPANYING_DOC },
  { label: 'CMR', value: formKeys.CMR },
  { label: 'ТрН или ТТН', value: formKeys.BILL_OF_LADING_OR_WAYBILL },
  { label: 'Поручение экспедитору', value: formKeys.FORWARDING_ORDER },
  { label: 'Заявка на перевозку', value: formKeys.SHIPPING_ORDER },
  { label: 'Экспедиторская расписка', value: formKeys.FORWARDING_RECEIPT },
  {
    label: 'Приложение к Транспортной накладной',
    value: formKeys.ATTACHMENT_TO_BILL_OF_LADING,
  },
];

export const paymentDocs: any = [
  { label: 'Предоплата', value: formKeys.PREPAYMENT },
  {
    label: 'Оплата по скан-копиям документов',
    value: formKeys.PAYMENT_BY_SCANCOPY,
  },
  {
    label: 'Оплата по оригиналам документов',
    value: formKeys.PAYMENT_BY_ORIGIN_DOCS,
  },
  { label: 'Оплата по факту выгрузки', value: formKeys.PAYMENT_BY_UNLOADING },
  { label: 'Оплата по факту загрузки', value: formKeys.PAYMENT_BY_LOADING },
];

export const closingDocs: any = [
  { docName: 'ТРН', docCount: 2 },
  { docName: 'ТТН', docCount: 2 },
  { docName: 'Товарная накладная/УПД', docCount: 1 },
];

export const registrationTypes = [
  {
    label: 'Онлайн-бронирование',
    value: formKeys.ONLINE,
  },
  {
    label: 'Квалифицированная подпись',
    value: formKeys.QUALIFIED,
  },
];
