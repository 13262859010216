import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { IconChecked } from '../../Icons';

export default function Common(option, suggestion) {
  const { icon, label, disabled } = option;
  const isSelected = label === suggestion;
  return (
    <div
      className={classnames(styles.suggestion, styles.title, {
        [styles.hidden]: !label,
        [styles.disabled]: disabled,
        [styles.withIcon]: !!icon,
        flex: isSelected,
      })}
      title={label}
    >
      {!!icon && icon}
      {label}
      {isSelected && <IconChecked width={20} height={20} color="primary" className="ml-a" />}
    </div>
  );
}
