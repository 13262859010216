import React from 'react';
import isBefore from 'date-fns/isBefore';
import { CATEGORY_1, CATEGORY_2, CATEGORY_DELLIN_MKT } from '../../../store/resources/queryKeys';
import { getDriverStatusByCategory } from '../../DataListCollections/_helpers';
import { convertToShortDateWithFullYear } from '../../../helpers/dateTimeTools';

import DLLogo from '../../../assets/images/partners/DL-logo.png';
import DLTLogo from '../../../assets/images/partners/DLT-logo.jpg';

/**
 * Получение срока действия доверености
 * @param item
 * @param category
 * @return {{attorneyFrom: string, attorneyTill: string}|null}
 */
export function getAttorneyDates(item, category) {
  const { attorneys } = item;
  return {
    attorneyFrom: attorneys[category] ? convertToShortDateWithFullYear(attorneys[category].from) : undefined,
    attorneyTill: attorneys[category] ? convertToShortDateWithFullYear(attorneys[category].until) : undefined,
    isValid: attorneys[category]
      ? isBefore(new Date(attorneys[category].from), new Date()) &&
        isBefore(new Date(), new Date(attorneys[category].until))
      : undefined,
  };
}

export function getCategoryStatusList(item, companyCategories) {
  const result: any = [];
  if (companyCategories.includes(CATEGORY_1)) {
    result.push({
      ...getDriverStatusByCategory(item, companyCategories, CATEGORY_1),
      attorneyDates: {
        bts: { ...getAttorneyDates(item, CATEGORY_1) },
        mkt: { ...getAttorneyDates(item, CATEGORY_DELLIN_MKT) },
      },
      logo: CATEGORIES_LOGO.get(CATEGORY_1),
      category: CATEGORY_1,
      title: 'ООО «Деловые линии»',
    });
  }
  if (companyCategories.includes(CATEGORY_2)) {
    result.push({
      ...getDriverStatusByCategory(item, companyCategories, CATEGORY_2),
      attorneyDates: {
        bts: { ...getAttorneyDates(item, CATEGORY_2) },
        mkt: null,
      },
      logo: CATEGORIES_LOGO.get(CATEGORY_2),
      category: CATEGORY_2,
      title: 'ООО «ДЛ-Транс»',
    });
  }

  return result;
}

export const CATEGORIES_LOGO = new Map()
  .set(
    CATEGORY_1,
    <span className="categoryLogo mr-2">
      <img src={DLLogo} alt="Деловые линии" width="16" height="16" />
    </span>,
  )
  .set(
    CATEGORY_2,
    <span className="categoryLogo mr-2">
      <img src={DLTLogo} alt="ДЛ-Транс" width="16" height="16" />
    </span>,
  );
