import React from 'react';
import SVGIcon from './index';

function IconAuctionDrop(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.65686 4.12132L10.7782 2L14.3137 5.53553L12.1924 7.65685L8.65686 4.12132ZM5.82837 6.94969L7.94969 4.82837L14 10.8787L14 14H12.8787L5.82837 6.94969ZM16 14.88L14.8793 16.0007L13.8787 15H15L15 12H15.1213L16 12.8787V14.88ZM17 13.88L17.0007 13.8793L17 13.8787V13.88ZM2 16H9V18H2V16ZM5.12132 7.65674L3 9.77806L6.53553 13.3136L8.65685 11.1923L5.12132 7.65674Z"
      />
      <path d="M15 11H16V15H18L15.5 18L13 15H15L15 11Z" />
    </SVGIcon>
  );
}

IconAuctionDrop.defaultProps = {
  color: 'base',
};

export default React.memo(IconAuctionDrop);
