import React from 'react';
import SVGIcon from './index';

const IconListOrder = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        clipRule="evenodd"
        d="M4 16.0001H16V4.00012H4V16.0001ZM5 7.00012H15V15.0001H5V7.00012ZM7 9.00012H8V10.0001H7V9.00012ZM7 12.0001H8V13.0001H7V12.0001ZM13 9.00012H9V10.0001H13V9.00012ZM9 12.0001H13V13.0001H9V12.0001Z"
        fillRule="evenodd"
      />
    </SVGIcon>
  );
};

export default IconListOrder;
