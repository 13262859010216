import { useEffect, useState, useCallback, useRef } from 'react';

const DEFAULT_INITIAL_DELAY = 300;
const DEFAULT_TICK_INTERVAL = 100;
export function useLongPress(callback = () => {}, options?) {
  const [startLongPress, setStartLongPress] = useState(false);
  const initialTimerId = useRef<any>(null);
  const tickingTimerId = useRef<any>(null);
  // options:
  const _initialDelay = options?.initialDelay || DEFAULT_INITIAL_DELAY;
  const _tickInterval = options?.tickInterval || DEFAULT_TICK_INTERVAL;
  const stopHandler = options?.stopHandler;
  const clickHandler = options?.clickHandler;

  useEffect(() => {
    if (startLongPress) {
      clearTimeout(tickingTimerId.current);
      tickingTimerId.current = setTimeout(callback, _tickInterval);
    } else {
      clearTimeout(tickingTimerId.current);
      // click срабатывает позже useEffect, поэтому очищаем tickingTimerId чуть позже
      // необходимо чтобы скипать событие клика и не слать onChange 2 раза
      setTimeout(() => {
        tickingTimerId.current = null;
      }, 100);
    }

    return () => {
      clearTimeout(tickingTimerId.current);
      clearTimeout(initialTimerId.current);
    };
  }, [callback, _tickInterval, startLongPress]);

  const start = useCallback(() => {
    initialTimerId.current = setTimeout(() => setStartLongPress(true), _initialDelay);
  }, [_initialDelay]);

  const stop = useCallback(() => {
    initialTimerId.current && clearTimeout(initialTimerId.current);
    initialTimerId.current = null;
    tickingTimerId.current && stopHandler && stopHandler();
    setStartLongPress(false);
  }, [stopHandler]);

  const click = useCallback(() => {
    if (!tickingTimerId.current) {
      clickHandler && clickHandler();
    }
  }, [clickHandler]);

  return {
    onClick: click,
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  };
}
