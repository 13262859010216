import React from 'react';
import SVGIcon from './index';

const IconArrowWithDotBold = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M13.6488 11H3L3 9H13.6488L10.945 6.28182L12.1875 5L17 10L12.1875 15L10.9537 13.7182L13.6488 11Z" />
      <path d="M8 12.5C9.38114 12.5 10.5 11.3811 10.5 10C10.5 8.61886 9.38114 7.5 8 7.5C6.61886 7.5 5.5 8.61886 5.5 10C5.5 11.3811 6.61886 12.5 8 12.5Z" />
    </SVGIcon>
  );
};

IconArrowWithDotBold.defaultProps = {
  color: 'base',
};

export default IconArrowWithDotBold;
