import React, { createContext, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { useQuery } from 'react-query';

import { BLOCKED, CO_MOCKED_SUBSCRIPTION, TC_MOCKED_SUBSCRIPTION } from './constants';
import { toast } from '../../components/Toaster';
import ContentLoader from '../../components/ContentLoader';
import * as billingServices from '../../services/billingService';
// import { getIsCompanyApproved } from '../../store/company/selectors';
import { getMappedAddons, getMappedSubscription, getMappedTariffs, isActualSubscription } from './selectors';
import { getRoleIsCargoOwner } from '../../store/user/selectors';
import { useAppSelector } from '../../hooks/useShallowEqualSelector';
import { useDeepMemoValue } from '../../hooks/useDeepMemo';
import { SUPPORT_PHONE_NUMBER } from '../../constants';

const initialContextTariffsAndAddonsValue = {
  tariffs: [],
  addons: [],
};
const initialContextValue = {
  isBillingLoading: true,
  subscriptions: [],
  ...initialContextTariffsAndAddonsValue,
  isCompanyBillingBlocked: false,
  getSubscriptions: () => {},
  getCatalog: () => {},
};

const sendReportToSentry = (error) => {
  Sentry.withScope((scope) => {
    scope.setTag('page', 'billing');
    Sentry.captureMessage(error);
  });
};

export const BillingContext = createContext<any>(initialContextValue);

export const useBillingContext = () => {
  const store = useContext(BillingContext);

  if (!store) {
    throw new Error('Cannot use `useBillingContext` outside of a withBilling HOC');
  }

  return store;
};

const withBilling = (WrappedComponent, { needCatalog = true, needSubscriptions = true } = {}) => {
  function HOComponent(props) {
    // const isCompanyApproved = useAppSelector(getIsCompanyApproved);
    const isCargoOwningUser = useAppSelector(getRoleIsCargoOwner);

    const useBilling = false; //isCargoOwningUser && isCompanyApproved;
    // const defaultSubscriptions = useBilling
    //   ? initialContextValue.subscriptions
    //   : [getMappedSubscription(TC_MOCKED_SUBSCRIPTION)];
    const defaultSubscriptions = [
      getMappedSubscription(isCargoOwningUser ? CO_MOCKED_SUBSCRIPTION : TC_MOCKED_SUBSCRIPTION),
    ];

    /**
     * Получение статуса компании: блок/актив
     */
    const {
      data: isCompanyBillingBlocked = initialContextValue.isCompanyBillingBlocked,
      isLoading: isCompanyStateLoading,
      isError: isCompanyStateError,
    } = useQuery([`billing`, `company-state`], () => billingServices.getCompanyBillingState(), {
      enabled: useBilling,
      select: (data: any) => data.state === BLOCKED,
      onError: (err) => sendReportToSentry(err),
    });

    /**
     * Получение списка подписок
     */
    const {
      data: subscriptions = defaultSubscriptions,
      // refetch: getSubscriptions,
      isLoading: isSubscriptionsLoading,
      isError: isSubscriptionsError,
    } = useQuery([`billing`, `subscriptions`], () => billingServices.getSubscriptions(), {
      enabled: useBilling && needSubscriptions,
      select: (data) => data.map(getMappedSubscription).filter(isActualSubscription),
      onError: (err) => sendReportToSentry(err),
    });

    /**
     * Получение каталога с тарифами и услугами
     */
    const {
      data: tariffsAndAddons = initialContextTariffsAndAddonsValue,
      refetch: getCatalog,
      isLoading: isCatalogLoading,
      isError: isCatalogError,
    } = useQuery([`billing`, `tariffs`], () => billingServices.getCatalog(), {
      enabled: useBilling && needCatalog,
      select: (data) => ({
        tariffs: getMappedTariffs(data),
        addons: getMappedAddons(data),
      }),
      onError: (err) => sendReportToSentry(err),
    });

    const isBillingLoading = useBilling ? isCompanyStateLoading || isSubscriptionsLoading || isCatalogLoading : false;
    const isError = isCompanyStateError || isSubscriptionsError || isCatalogError;

    // Показать ошибку если один из запросов отвалился
    useEffect(() => {
      if (isError) {
        toast.error(
          'Сервис временно недоступен',
          `Попробуйте обновить страницу. Если проблема не исчезла, обратитесь в службу поддержки ${SUPPORT_PHONE_NUMBER.common} (будние дни, с 9:00 до 18:00).`,
        );
      }
    }, [isError]);

    const billingProps = useDeepMemoValue({
      isCompanyBillingBlocked: useBilling ? isCompanyBillingBlocked : false,
      isBillingLoading,
      subscriptions: [getMappedSubscription(isCargoOwningUser ? CO_MOCKED_SUBSCRIPTION : TC_MOCKED_SUBSCRIPTION)],
      // subscriptions: useBilling ? subscriptions : [getMappedSubscription(TC_MOCKED_SUBSCRIPTION)],
      getSubscriptions: () => Promise.resolve(),
      // tariffs,
      // addons,
      ...tariffsAndAddons,
      getCatalog,
    });

    if (isBillingLoading) {
      return <ContentLoader withBackground />;
    }

    return (
      <BillingContext.Provider value={billingProps}>
        {/* Pass billingProps to WrappedComponent for un-breaking changes */}
        <WrappedComponent {...props} {...billingProps} />
      </BillingContext.Provider>
    );
  }

  HOComponent.displayName = `WithBillingHOComponent`;
  return HOComponent;
};

export default withBilling;
