import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

function IconSearchSaved(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6681 3.91965L14.5 1L13.3319 3.91965L10 4.05573L12.61 5.99625L11.7188 9L14.5 7.27965L17.2812 9L16.39 5.99625L19 4.05573L15.6681 3.91965ZM3 8.20297C3 5.41941 5.18559 3.14656 7.93441 3.00681L9.50904 4.84388C9.10434 4.68693 8.66395 4.60088 8.203 4.60088C6.20986 4.60088 4.60094 6.20979 4.60094 8.20293C4.60094 10.1961 6.20986 11.805 8.203 11.805C9.43562 11.805 10.5213 11.1896 11.1704 10.2486L13.25 9.46876C13.0507 10.2622 12.6683 10.9851 12.1492 11.5889L12.3734 11.805H13.0057L17 15.8073L15.8073 17L11.805 13.0057V12.3734L11.5889 12.1492C10.6764 12.9337 9.49171 13.4059 8.20297 13.4059C5.32933 13.4059 3 11.0766 3 8.20297Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
}

IconSearchSaved.defaultProps = {
  color: 'base',
};

export default IconSearchSaved;
