import axios from 'axios';
import * as AuthService from '../authService';
import { history } from '../../store/configureStore';
import { getAccessToken, getRefreshToken } from '../authTokenService';
// import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
// import axiosCancel from 'axios-cancel';

axios.defaults.headers.get['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';

export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;

function handleError(error) {
  history.push('/profile/logout');
  // return resolve for prevent sending error to sentry
  return Promise.resolve(error);
}

const createHttp = (url, apiVersion = 'v1') => {
  const http = axios.create({
    baseURL: `${url}/api/${apiVersion}`,
    adapter: axios.defaults.adapter,
  });

  http.interceptors.request.use(
    (config) => {
      const accessToken = getAccessToken();
      if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`;
      return config;
    },
    (error) => Promise.reject(error),
  );

  http.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      const refreshToken = getRefreshToken();
      if (error?.response?.status === UNAUTHORIZED) {
        if (refreshToken && !originalRequest._retry) {
          originalRequest._retry = true;
          return AuthService.authorization({ refresh_token: refreshToken })
            .then(() => {
              console.info('Access token refreshed!');
              return http(originalRequest);
            })
            .catch(handleError);
        } else {
          return handleError(error);
        }
      }

      return Promise.reject(error);

      // const { status } = error.response;
      //
      // if (status === UNAUTHORIZED) {
      //   history.push('/profile/logout');
      //   // return empty reject for hiding extra error toasters
      //   return Promise.reject();
      // }
      // return Promise.reject(error);
    },
  );

  return http;
};

export const createSynapseHttp = (url) => {
  const http = axios.create({
    baseURL: `${url}/synapse`,
    adapter: axios.defaults.adapter,
  });

  http.interceptors.request.use(
    (config) => {
      const accessToken = getAccessToken();
      if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`;
      return config;
    },
    (error) => Promise.reject(error),
  );

  http.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      const refreshToken = getRefreshToken();
      if (error?.response?.status === FORBIDDEN) {
        if (refreshToken && !originalRequest._retry) {
          originalRequest._retry = true;
          return AuthService.authorization({ refresh_token: refreshToken })
            .then(() => {
              console.info('Chat access token refreshed!');
              try {
                const data = JSON.parse(originalRequest.data);
                if (data.token) {
                  const token = getAccessToken();
                  originalRequest.data = JSON.stringify({ ...data, token });
                }
              } catch {}
              return http(originalRequest);
            })
            .catch(handleError);
        } else {
          return handleError(error);
        }
      }

      return Promise.reject(error);
    },
  );

  return http;
};

export default createHttp;
