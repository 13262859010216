import { useCallback, useEffect, useState } from 'react';
import { toast } from '../components/Toaster';
import { getExpeditedPaymentInfo, updateExpeditedPaymentInfo } from '../services/companyService';
import { replaceEmptyStringToNull, replaceNullToEmptyString } from '../helpers/replaceEmptyString';

export enum EExpeditedPaymentRequestTime {
  at_reserve = 'at_reserve',
  before_accept = 'before_accept',
  '24_hours_unloading' = '24_hours_unloading',
}

export type TExpeditedPayment = {
  request_time: EExpeditedPaymentRequestTime;
  yearly_margin_percentage: number | null;
  min_margin_amount: number | null;
  interest_rate: number | null;
  enabled: boolean;
  isLoading: boolean;
};

function useExpeditedPayment() {
  const [{ enabled, isLoading, ...expeditedPayment }, setExpeditedPayment] = useState<TExpeditedPayment>({
    request_time: EExpeditedPaymentRequestTime.before_accept,
    interest_rate: null,
    min_margin_amount: null,
    yearly_margin_percentage: null,
    enabled: false,
    isLoading: true,
  });
  function setExpiredPaymentData(data: TExpeditedPayment): void {
    setExpeditedPayment(replaceNullToEmptyString<TExpeditedPayment>(data));
  }
  const getExpeditedPayment = useCallback(() => {
    return getExpeditedPaymentInfo()
      .then((data) => setExpiredPaymentData({ ...data, isLoading: false }))
      .catch(toast.errorAsync);
  }, []);
  const updateExpeditedPayment = useCallback((data: Partial<TExpeditedPayment>) => {
    return updateExpeditedPaymentInfo(replaceEmptyStringToNull<Partial<TExpeditedPayment>>(data))
      .then(setExpiredPaymentData)
      .catch(toast.errorAsync);
  }, []);
  const enableExpeditedPayment = useCallback((data: Partial<TExpeditedPayment>) => {
    return updateExpeditedPaymentInfo({ ...replaceEmptyStringToNull<Partial<TExpeditedPayment>>(data), enabled: true })
      .then(setExpiredPaymentData)
      .catch(toast.errorAsync);
  }, []);
  const disableExpeditedPayment = useCallback(() => {
    return updateExpeditedPaymentInfo({ enabled: false }).then(setExpiredPaymentData).catch(toast.errorAsync);
  }, []);

  useEffect(() => {
    getExpeditedPayment();
  }, [getExpeditedPayment]);

  return {
    expeditedPayment,
    getExpeditedPayment,
    updateExpeditedPayment,
    enableExpeditedPayment,
    disableExpeditedPayment,
    isExpeditedPaymentEnabled: enabled,
    isExpeditedPaymentLoading: isLoading,
  };
}

export default useExpeditedPayment;
