import React from 'react';
import SVGIcon from './index';

const styles = {
  text: { fontSize: 10 },
};

function IconBadgeCounter(props: React.SVGAttributes<HTMLOrSVGElement>);
function IconBadgeCounter(props: React.SVGAttributes<HTMLOrSVGElement> & { count: number; maxCount: number });
function IconBadgeCounter(props) {
  const { count, maxCount } = props;
  const text = count && count > maxCount ? `${maxCount}+` : count;

  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3C0 1.34315 1.34315 0 3 0H14C15.6569 0 17 1.34315 17 3V12C17 13.6569 15.6569 15 14 15H13.6H3.4H3C1.34315 15 0 13.6569 0 12V3Z"
        fill="#0B5EDA"
      />
      {!!text && (
        <text textAnchor="middle" x="9" y="11" fill="white" style={styles.text}>
          {text}
        </text>
      )}
    </SVGIcon>
  );
}

IconBadgeCounter.defaultProps = {
  color: 'base',
  maxCount: 9,
};

export default IconBadgeCounter;
