import React from 'react';
import SVGIcon from './index';

function IconAuctionClosed(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.65686 4.12132L10.7782 2L14.3137 5.53553L12.1924 7.65685L8.65686 4.12132ZM5.82837 6.94969L7.94969 4.82837L13.844 10.7227C12.6147 11.2262 12 12.4512 12 13H11.8787L5.82837 6.94969ZM2 16H9V18H2V16ZM5.12132 7.65674L3 9.77806L6.53553 13.3136L8.65685 11.1923L5.12132 7.65674Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 14L13 13.5C13 12.8 13.5 11.5 15 11.5C16.5 11.5 17 12.8 17 13.5L17 14H18V18H12V14H13ZM16 14H14L14 13.4999C14 13.3461 14.0664 13.0634 14.2346 12.8414C14.3712 12.661 14.5843 12.5 15 12.5C15.4157 12.5 15.6288 12.661 15.7654 12.8414C15.9336 13.0634 16 13.3461 16 13.5L16 14Z"
      />
    </SVGIcon>
  );
}

IconAuctionClosed.defaultProps = {
  color: 'base',
};

export default React.memo(IconAuctionClosed);
