import http from './config/webConfig';
import { getToken, getDecodeToken } from './authTokenService';

interface IUpdate {
  fullName: string;
  email: string;
  position: string | null;
  comment: string | null;
  phone: string | null;
  attorney: string | null;
  icq: string | null;
  viber: string | null;
  skype: string | null;
  whats_app: string | null;
  telegram: string | null;
}

export async function getUserProfile(): Promise<any> {
  const token = await getToken();
  const { user_id } = getDecodeToken(token);
  const url = `/profile/${user_id}`;

  let { data } = await http.get(url, { cache: false, requestId: url });
  return _mapUserData(data);
}

export async function updateUserProfile({
  fullName,
  email,
  position,
  comment,
  phone,
  icq,
  viber,
  skype,
  whats_app,
  telegram,
}: IUpdate): Promise<any> {
  const token = await getToken();
  const { user_id } = getDecodeToken(token);
  const url: string = `/profile/${user_id}`;
  const params = {
    full_name: fullName,
    email,
    position,
    comment,
    phone,
    icq,
    viber,
    skype,
    whats_app,
    telegram,
  };
  let { data } = (await http.put(url, params)) ?? {};
  return _mapUserData(data);
}

function _mapUserData(user) {
  const company = user.company;
  delete user.company;
  return [user, company];
}
