import produce from 'immer';

import * as types from './actionTypes';

const initialState = {
  isCodeRequested: false,
  isFirstStepCompleted: false,
  isInnError: false,
  token: '',
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    case types.CODE_REQUESTED:
      draft.isCodeRequested = true;
      break;

    case types.CODE_CANCELLED:
      draft.isCodeRequested = false;
      break;

    case types.UPDATE_FIRST_STEP:
      draft.isFirstStepCompleted = action.isFirstStepCompleted;
      break;

    case types.CLEAR_COMPANY:
      draft.isInnError = true;
      break;

    case types.SAVE_PHONE_TOKEN:
      draft.token = action.token;
      break;

    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
