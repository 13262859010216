import React from 'react';

import { TExpeditedPaymentStatuses } from '../../../containers/partners/models';

import Tooltip from '../../Tooltip';
import { IconChecked, IconInfo } from '../../Icons';

export function getExpeditedPaymentIcon(expeditedPaymentInfo?: TExpeditedPaymentStatuses['status']) {
  // Если включена для партнёра и парнёрство активно
  if (expeditedPaymentInfo?.enabled_for_ntk && expeditedPaymentInfo?.active_partnership) {
    if (expeditedPaymentInfo?.current_subcontract && expeditedPaymentInfo?.contract_postpone_days_filled) {
      return (
        <Tooltip content="Ускоренная оплата подключена">
          <IconChecked width={20} height={20} color="success" />
        </Tooltip>
      );
    } else if (
      expeditedPaymentInfo?.active_partnership ||
      expeditedPaymentInfo?.current_subcontract ||
      expeditedPaymentInfo?.contract_postpone_days_filled
    ) {
      return (
        <Tooltip
          content={
            <>
              <p className="mt-0 mb-0">Для подключения ускоренной оплаты необходимо:</p>
              <p className="mt-0 mb-0">
                <IconChecked
                  width="12"
                  height="12"
                  color={expeditedPaymentInfo?.active_partnership ? 'success' : 'white'}
                  className="mr-1"
                />
                Активное партнёрство
              </p>
              <p className="mt-0 mb-0">
                <IconChecked
                  width="12"
                  height="12"
                  color={expeditedPaymentInfo?.current_subcontract ? 'success' : 'white'}
                  className="mr-1"
                />
                Загружено действующее доп. соглашение
              </p>
              <p className="mt-0 mb-0">
                <IconChecked
                  width="12"
                  height="12"
                  color={expeditedPaymentInfo?.contract_postpone_days_filled ? 'success' : 'white'}
                  className="mr-1"
                />
                Указано количество дней отсрочки
              </p>
            </>
          }
        >
          <IconInfo width="20" height="20" color="danger" />
        </Tooltip>
      );
    }
  }
  return null;
}
