import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _flatMap from 'lodash/flatMap';
import { useInfiniteQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';

import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

import Input from '../../Input';
import { IconChevron, IconClear } from '../../Icons';
import Checkbox from '../../Checkbox';
import { declinationOfNumbers } from '../../../helpers/declinationOfNumbers';
import IconLoading from '../../Icons/CSSIcons/IconLoading/IconLoading';

// input label:
function getDefaultMultipleOptionsInputLabel({ selectedOptions }) {
  let str = declinationOfNumbers(selectedOptions?.length, ['Выбран', 'Выбрано', 'Выбрано']);
  str += ` ${selectedOptions?.length} `;
  str += declinationOfNumbers(selectedOptions?.length, ['элемент', 'элемента', 'элементов']);
  return str;
}
function getDefaultInputLabel(params) {
  const { isMulti, selectedOptions, getLabel, getMultipleOptionsInputLabel } = params;
  if (isMulti) {
    if (Array.isArray(selectedOptions)) {
      if (!selectedOptions?.length) return '';
      if (selectedOptions?.length === 1) return getLabel(selectedOptions[0]);
      return getMultipleOptionsInputLabel({ selectedOptions });
    }
  } else {
    return getLabel(selectedOptions);
  }
}

// dropdown options:
function DefaultOption(params) {
  const { option, getLabel, optionStyle, optionClassName } = params;
  return (
    <div className={classnames(styles.defaultOption, optionClassName)} style={optionStyle}>
      {getLabel(option)}
    </div>
  );
}

function OptionWrapper(params) {
  const { isMulti, option, getLabel, optionStyle, optionClassName, OptionElement, isSelected, optionClickHandler } =
    params;

  const optionParams = useMemo(() => {
    return { option, getLabel, optionStyle, optionClassName };
  }, [option, getLabel, optionStyle, optionClassName]);

  return (
    <div
      className={classnames(styles.optionWrapper, { [styles.selected]: isSelected(option) })}
      onClick={() => optionClickHandler(option)}
    >
      {isMulti && (
        <div className="mr-2" style={{ height: '16px' }}>
          <Checkbox
            checked={isSelected(option)}
            onChange={() => {}}
            readOnly
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              optionClickHandler(option);
            }}
          />
        </div>
      )}
      <OptionElement {...optionParams} />
    </div>
  );
}
// dropdown footer:
function DefaultDropdownFooterContent(params) {
  const { visible, selectAll, unselectAll } = params;

  if (!visible) return null;

  return (
    <div>
      <div className="divider" />

      <div className="flex content-between items-center ml-3 mr-3 pt-1">
        <div className="link" onClick={selectAll}>
          Выбрать всё
        </div>
        <div className="link" onClick={unselectAll}>
          Снять выбор
        </div>
      </div>
    </div>
  );
}

function RemoteOptionsDropdownFooterContent(params) {
  const { unselectAll, visible } = params;

  if (!visible) return null;

  return (
    <div>
      <div className="divider" />

      <div className="flex content-between items-center ml-3 mr-3 pt-1 pb-1">
        <div className="link" onClick={unselectAll}>
          Снять выбор
        </div>
      </div>
    </div>
  );
}

function Selector(params) {
  // common params:
  const {
    className,
    isMulti,
    maxOptionsToChoose,
    isSearchable,
    isClearable,
    disabled,
    onSelect: onSelectHandler,
  } = params;
  // input params:
  const { placeholder, showChevron, inputClassName, onBlur } = params;
  // search input params:
  const { searchPlaceholder } = params;
  // dropdown params:
  const { dropdownMinWidth, dropdownMaxHeight, dropdownClassName, noOptionsMessage, emptySearchMessage } = params;
  // option params:
  const { optionStyle, optionClassName } = params;
  // data params:
  const { name, value, options = [], onSearch, keyField, labelField, onChange: onChangeHandler } = params;
  // elements:
  const { OptionElement, getInputLabel, getMultipleOptionsInputLabel, DropdownFooterContent } = params;

  const selectorRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [_value, setValue] = useState<any>(value);
  const [_options, setOptions] = useState<any>(options);
  const [searchValue, setSearchValue] = useState('');

  // цепляемся за футер
  const [ref, inView] = useInView();

  // загрузчик данных если передан метод onSearch
  const {
    isFetching: isSearching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['selector', name, { q: searchValue }],
    ({ pageParam = 1 }) => onSearch(searchValue ? { q: searchValue, page: pageParam } : { page: pageParam }),
    {
      enabled: isDropdownOpen && !!onSearch,
      onSuccess: (data) => {
        const flatData = data.pages.map((page) => page.items).flat();
        setOptions(flatData);
      },
      retry: 0,
      getNextPageParam: (lastPage) => {
        if (lastPage.currentPage < lastPage.totalPages) return lastPage.currentPage + 1;
        return null;
      },
    },
  );

  // Загрузка данных если футер в фокусе и используется удаленная загрузка данных
  useEffect(() => {
    if (inView && onSearch && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, onSearch]);

  useOnClickOutside(selectorRef, () => {
    setIsDropdownOpen(false);
    setSearchValue('');
  });

  const inputWrapperClickHandler = useCallback(() => {
    if (disabled) return;
    setIsDropdownOpen(true);
  }, [disabled]);

  useEffect(() => {
    if (!_isEqual(_value, value)) setValue(value);
  }, [value, _value]);

  useEffect(() => {
    if (!_isEqual(_options, options) && !onSearch) setOptions(options);
  }, [options, _options, onSearch]);

  // helpers
  const getValue = useCallback(
    (option) => {
      if (option && keyField) return _get(option, keyField);
      return option;
    },
    [keyField],
  );

  const getLabel = useCallback(
    (option) => {
      if (option && labelField) return _get(option, labelField);
      return option;
    },
    [labelField],
  );

  const isSelected = useCallback(
    (option) => {
      if (isMulti) {
        return _value?.includes(getValue(option)) || false;
      } else {
        return _isEqual(_value, getValue(option));
      }
    },
    [_value, isMulti, getValue],
  );

  const getSelectedOptions = useCallback(
    (newValue) => {
      if (isMulti) return _options?.filter((option) => newValue?.includes(getValue(option))) || null;
      if (_options?.[0]?.options)
        return _flatMap(_options, (item) => item.options)?.find((option) => _isEqual(newValue, getValue(option)));
      return _options?.find((option) => _isEqual(newValue, getValue(option)));
    },
    [isMulti, _options, getValue],
  );

  // data functions
  const emitNewValue = useCallback(
    (value) => {
      onChangeHandler?.(value, getSelectedOptions(value));
      onSelectHandler?.(value, getSelectedOptions(value));
    },
    [onChangeHandler, onSelectHandler, getSelectedOptions],
  );

  const optionClickHandler = useCallback(
    (option) => {
      if (isMulti) {
        if (Array.isArray(_value) && _value.includes(getValue(option))) {
          const newValue = _value.slice().filter((key) => key !== getValue(option));
          setValue(newValue);
          emitNewValue(newValue);
        } else if (
          Array.isArray(_value) &&
          ((!!maxOptionsToChoose && _value.length < maxOptionsToChoose) || !maxOptionsToChoose)
        ) {
          const newValue = _value.slice();
          newValue.push(getValue(option));
          setValue(newValue);
          emitNewValue(newValue);
          // Если выбранное кол-во вариантов достигло максимума, то не даем выбирать дальше, можно только удалять
        } else if (Array.isArray(_value) && !!maxOptionsToChoose && _value.length >= maxOptionsToChoose) {
        } else {
          const newValue = [getValue(option)];
          setValue(newValue);
          emitNewValue(newValue);
        }
      } else {
        setValue(getValue(option));
        emitNewValue(getValue(option));
        setIsDropdownOpen(false);
        setSearchValue('');
      }
    },
    [isMulti, _value, getValue, maxOptionsToChoose, emitNewValue],
  );

  const selectAllHandler = useCallback(() => {
    if (!isMulti || !!maxOptionsToChoose) return;
    const newValue = _options.map((option) => getValue(option));
    setValue(newValue);
    emitNewValue(newValue);
  }, [isMulti, maxOptionsToChoose, _options, emitNewValue, getValue]);

  const unselectAllHandler = useCallback(() => {
    if (!isMulti) return;
    const newValue = [];
    setValue(newValue);
    emitNewValue(newValue);
  }, [isMulti, emitNewValue]);

  const cleanSearchInputHandler = useCallback(() => {
    setSearchValue('');
  }, []);

  // input label
  const selectedOptions = useMemo(() => {
    return getSelectedOptions(_value);
  }, [_value, getSelectedOptions]);

  const getInputLabelFunctionParams = useMemo(() => {
    return { isMulti, selectedOptions, getLabel, getMultipleOptionsInputLabel, value: _value };
  }, [isMulti, selectedOptions, getLabel, getMultipleOptionsInputLabel, _value]);

  const inputLabel = useMemo(() => {
    return getInputLabel(getInputLabelFunctionParams);
  }, [getInputLabel, getInputLabelFunctionParams]);

  // computed values:
  const optionWrapperParams = useMemo(() => {
    return {
      isMulti,
      getLabel,
      OptionElement,
      optionStyle,
      optionClassName,
      isSelected,
      optionClickHandler,
    };
  }, [isMulti, getLabel, OptionElement, optionStyle, optionClassName, isSelected, optionClickHandler]);

  const dropdownFooterParams = useMemo(() => {
    return {
      visible: isMulti ? _value?.length > 0 : false,
      selectAll: selectAllHandler,
      unselectAll: unselectAllHandler,
    };
  }, [_value, isMulti, selectAllHandler, unselectAllHandler]);

  const filteredOptions = useMemo(() => {
    if (!searchValue || onSearch) return _options;
    return _options
      .map((option) => {
        if (option.options) {
          const groupOptions = option.options.filter((opt) =>
            getLabel(opt)?.toLowerCase().includes(searchValue.toLowerCase()),
          );
          if (groupOptions.length) {
            return { label: option.label, options: groupOptions };
          }
          return null;
        }
        return getLabel(option)?.toLowerCase().includes(searchValue.toLowerCase()) ? option : null;
      })
      .filter(Boolean);
  }, [_options, searchValue, getLabel, onSearch]);

  const handleClear = useCallback(
    (e) => {
      e.stopPropagation();
      setValue(isMulti ? [] : null);
      emitNewValue(isMulti ? [] : null);
      cleanSearchInputHandler();
    },
    [isMulti, emitNewValue, cleanSearchInputHandler],
  );

  return (
    <div ref={selectorRef} className={classnames(styles.selector, className)}>
      <div className={styles.inputWrapper} onClick={inputWrapperClickHandler}>
        <Input
          name={name}
          value={inputLabel ?? ''}
          onBlur={() => onBlur?.()}
          placeholder={placeholder}
          className={classnames(styles.input, inputClassName)}
          readOnly
          disabled={disabled}
        />
        {isClearable && !!inputLabel && (
          <IconClear width={20} height={20} className={styles.icon} onClick={handleClear} />
        )}
        {showChevron && ((isClearable && !inputLabel) || !isClearable) && (
          <IconChevron
            width="20"
            height="20"
            className={classnames(styles.icon, styles.chevronIcon, { [styles.disabled]: disabled })}
          />
        )}
      </div>

      {isDropdownOpen && (
        <div
          className={classnames(styles.dropdownContainer, dropdownClassName)}
          style={{
            minWidth: dropdownMinWidth,
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          {isSearchable && (
            <div className={styles.filterInputContainer}>
              <Input
                name={name}
                className={styles.searchInput}
                placeholder={searchPlaceholder}
                value={searchValue}
                rightIcon={true}
                onChange={(event) => setSearchValue(event.target.value)}
              />
              {!!searchValue && (
                <IconClear width="20" height="20" className={styles.icon} onClick={cleanSearchInputHandler} />
              )}
            </div>
          )}

          <div className={styles.optionsContainer} style={{ maxHeight: dropdownMaxHeight }}>
            {!!filteredOptions.length && !(isSearching && searchValue) && (
              <>
                {filteredOptions.map((item, index) => (
                  <div key={getValue(item) + index}>
                    {item.options ? (
                      <>
                        {/* group title */}
                        <p className="color-dark pl-2 pr-2">{getLabel(item)}</p>
                        {item.options.map((i, ind) => (
                          <OptionWrapper key={getValue(i) + ind} option={i} {...optionWrapperParams} />
                        ))}
                        {/* group divider */}
                        <hr className="divider mt-0" />
                      </>
                    ) : (
                      <OptionWrapper key={getValue(item) + index} option={item} {...optionWrapperParams} />
                    )}
                  </div>
                ))}
              </>
            )}

            {!isSearching && !isFetchingNextPage && !filteredOptions.length && (
              <div className="flex items-center content-center height40 color-dark">
                {searchValue ? emptySearchMessage : noOptionsMessage}
              </div>
            )}

            {/* Anchor for loading data on scroll */}
            <div ref={ref} className="flex items-center content-center" style={{ minHeight: '1px' }}>
              {(isFetchingNextPage || isSearching) && <IconLoading width={20} height={20} className="mt-2 mb-2" />}
            </div>
          </div>

          <div className={styles.dropdownFooter}>
            <DropdownFooterContent {...dropdownFooterParams} />
          </div>
        </div>
      )}
    </div>
  );
}

Selector.defaultProps = {
  // common params
  isMulti: false,
  isSearchable: false,
  isClearable: false,
  disabled: false,
  maxOptionsToChoose: undefined,
  // input params
  placeholder: 'Выберите',
  showChevron: true,
  // dropdown params:
  dropdownMinWidth: '100%',
  dropdownMaxHeight: '150px',
  noOptionsMessage: 'Список пуст',
  emptySearchMessage: 'Нет результатов поиска',
  // search params
  searchPlaceholder: 'Поиск',
  // data params
  keyField: 'value',
  labelField: 'label',
  // elements
  OptionElement: DefaultOption,
  getInputLabel: getDefaultInputLabel,
  getMultipleOptionsInputLabel: getDefaultMultipleOptionsInputLabel,
  DropdownFooterContent: DefaultDropdownFooterContent,
};

Selector.RemoteOptionsDropdownFooterContent = RemoteOptionsDropdownFooterContent;
export default Selector;
