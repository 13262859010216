import get from 'lodash/get';
import { createSelector } from 'reselect';

export const getLoading = createSelector(
  (state) => get(state, 'auth.loading'),
  (loading) => loading,
);

export const getLoaded = createSelector(
  (state) => get(state, 'auth.loaded'),
  (loaded) => loaded,
);
