import { useUserContext } from '../../contexts/userContext';
import { useMatomo } from './context';
import { useCallback } from 'react';
import { CARGO_OWNING_COMPANY, TRANSPORT_COMPANY } from '../../constants';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { getCompanyINN } from '../../store/company/selectors';

export function useMatomoCustomDimensions() {
  const { companyId, userInfo, isCargoOwningUser } = useUserContext();
  const companyInn = useShallowEqualSelector(getCompanyINN);

  return [
    {
      id: 1,
      value: isCargoOwningUser ? CARGO_OWNING_COMPANY : TRANSPORT_COMPANY,
    },
    {
      id: 2,
      value: companyId,
    },
    {
      id: 3,
      value: userInfo?.roles,
    },
    {
      id: 4,
      value: userInfo?.email,
    },
    {
      id: 5,
      value: companyInn,
    },
  ];
}

export function useMatomoEvent(category: string, action: string, name: string, value?: number) {
  const customDimensions = useMatomoCustomDimensions();

  const { trackEvent } = useMatomo();
  return useCallback(
    (_value = undefined) =>
      trackEvent({
        category,
        action,
        name,
        value: _value ?? value,
        customDimensions: customDimensions,
      }),
    [action, category, name, trackEvent, value, customDimensions],
  );
}
