import { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const KEYCODES = {
  ESCAPE: 27,
};

function ModalWrapper(props) {
  const { disableClosing, children, onClose, portalNode } = props;
  const node = document.createElement('div');
  const ref = useRef(node);

  const closeModal = useCallback(() => {
    if (!disableClosing) {
      onClose();
    }
  }, [disableClosing, onClose]);

  const handleKeydown = useCallback(
    (event) => {
      if (event.keyCode === KEYCODES.ESCAPE) {
        closeModal();
      }
    },
    [closeModal],
  );

  useEffect(() => {
    const currentRef = ref.current;

    (portalNode || document.body).appendChild(currentRef);

    currentRef.addEventListener('keydown', handleKeydown);
    currentRef.setAttribute('tabindex', '0');
    currentRef.focus();

    return () => {
      currentRef.removeEventListener('keydown', handleKeydown);
      // TODO: навесить нормальный полифил для IE11
      if (currentRef.remove && typeof currentRef.remove === 'function') {
        currentRef.remove();
      } else if (currentRef.parentNode && typeof currentRef.parentNode.removeChild === 'function') {
        currentRef.parentNode.removeChild(currentRef);
      }
    };
  }, [handleKeydown, portalNode]);

  return createPortal(children, ref.current);
}

ModalWrapper.defaultProps = {
  disableClosing: false,
};

export default ModalWrapper;
