import React, { useState } from 'react';

import Button from '../../../components/Button';
import ModalConfirm from '../../../components/ModalCollections/ModalConfirm';
import useExpeditedPayment from '../../../hooks/useExpeditedPayment';

export function useGlobalExpeditedPayment() {
  const { isExpeditedPaymentEnabled, isExpeditedPaymentLoading, expeditedPayment, ...expeditedPaymentData } =
    useExpeditedPayment();
  const [showExpeditedPaymentActivationModal, setShowExpeditedPaymentActivationModal] = useState<any>(false);
  const [showExpeditedPaymentCancellationModal, setShowExpeditedPaymentCancellationModal] = useState(false);

  function handleExpeditedPaymentActivation(values) {
    return expeditedPaymentData
      .enableExpeditedPayment(values)
      .then(() => setShowExpeditedPaymentActivationModal(false));
  }

  function handleExpeditedPaymentCancellation() {
    return expeditedPaymentData.disableExpeditedPayment().then(() => setShowExpeditedPaymentCancellationModal(false));
  }

  let modal: null | React.ReactElement = null;
  if (showExpeditedPaymentActivationModal) {
    modal = (
      <ModalConfirm
        title="Подключить ускоренную оплату?"
        text="После подключения вы сможете разрешить использование ускоренной оплаты вашим партнёрам на их странице в реестре партнёров."
        textAlign="left"
        onClose={() => setShowExpeditedPaymentActivationModal(false)}
      >
        <Button theme="secondary" size="medium" onClick={() => setShowExpeditedPaymentActivationModal(false)}>
          Отмена
        </Button>
        <Button theme="primary" size="medium" onClick={showExpeditedPaymentActivationModal?.handleActivation}>
          Подключить
        </Button>
      </ModalConfirm>
    );
  }
  if (showExpeditedPaymentCancellationModal) {
    modal = (
      <ModalConfirm
        title="Отключить ускоренную оплату для всех партнёров?"
        text="После этого партнёры больше не смогут использовать эту услугу в ваших заявках. У заявок, которые уже в работе, ускоренная оплата остаётся."
        textAlign="left"
        onClose={() => setShowExpeditedPaymentCancellationModal(false)}
      >
        <Button theme="secondary" size="medium" onClick={() => setShowExpeditedPaymentCancellationModal(false)}>
          Отмена
        </Button>
        <Button theme="danger" size="medium" onClick={handleExpeditedPaymentCancellation}>
          Отключить
        </Button>
      </ModalConfirm>
    );
  }

  return {
    isExpeditedPaymentEnabled,
    isExpeditedPaymentLoading,
    expeditedPayment,
    openActivationModal: setShowExpeditedPaymentActivationModal,
    openCancellationModal: setShowExpeditedPaymentCancellationModal,
    handleExpeditedPaymentActivation,
    handleExpeditedPaymentCancellation,
    modal,
  };
}
