import React from 'react';
import SVGIcon from './index';

export default function IconCheckOutline(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 45 45">
      <path
        fillRule="evenodd"
        d="M22.5 7.5c-8.28 0-15 6.72-15 15 0 8.28 6.72 15 15 15 8.28 0 15-6.72 15-15 0-8.28-6.72-15-15-15zm0 27c-6.615 0-12-5.385-12-12s5.385-12 12-12 12 5.385 12 12-5.385 12-12 12zm6.885-18.63L19.5 25.755l-3.885-3.87L13.5 24l6 6 12-12-2.115-2.13z"
      />
    </SVGIcon>
  );
}
