import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { IconClose } from '../../../components/Icons';
import Button from '../../../components/Button';
import Popup from './Popup';

export default function ReviewSuggestionAdditionalModal({ onClose }) {
  return (
    <Popup
      placement={'top-end'}
      triggerClassName={styles.reviewSuggestionModalTrigger}
      className={styles.reviewSuggestionPopup}
      hideArrow
      content={
        <>
          <div>
            <div className={'flex content-between'}>
              <div className={classNames('leading-4 text-bold', styles.popupContent)}>
                К обзору можно вернуться позднее
              </div>
              <div onClick={onClose} className={styles.closeIcon}>
                <IconClose width={20} height={20} color={'white'} />
              </div>
            </div>
            <div className={classNames('mt-1 leading-4', styles.popupContent)}>
              На верхней панели нажмите «Поддержка» и выберите «Обзор платформы»
            </div>
          </div>

          <div className={'flex content-end'}>
            <Button type={'button'} theme={'secondary'} className={'ml-2'} onClick={onClose}>
              Понятно
            </Button>
          </div>
        </>
      }
    />
  );
}
