import { render } from 'react-dom';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import Button from '../components/Button';
import { IconArrowUp } from '../components/Icons';

import { scrollTo } from '../helpers/browserTools';
import styles from '../containers/common/Aside/styles.module.scss';

export function useScrollToTop(enabled) {
  const [show, setShow] = useState(false);
  const scrollArea = document.getElementById('contentScroll');
  const node = document.getElementById('aside-scroll-to-top');

  function handleScroll(e) {
    const scrollTop = e.target.scrollTop;
    setShow(scrollTop > 500);
  }

  /* Слушаем события скролла */
  useEffect(() => {
    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll);
      return () => scrollArea.removeEventListener('scroll', handleScroll);
    }
  }, [scrollArea]);

  /* Рендер кнопки скролл наверх */
  useEffect(() => {
    if (node) {
      if (enabled) {
        render(
          <Button
            theme="secondary"
            onClick={() => scrollTo(0, scrollArea)}
            className={classnames('width100p', styles.btnToTop, { [styles.btnToTopShow]: show })}
          >
            <IconArrowUp width="20" height="20" color="primary" className="mr-1" />
            <span>Наверх</span>
          </Button>,
          node,
        );
      }
      return () => render(<></>, node);
    }
  }, [enabled, node, scrollArea, show]);
}
