import * as actionTypes from './actionTypes';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

const initialState = {
  items: [],
  activeItem: {},
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    // Инициализация списка групп
    case actionTypes.INIT_GROUPS_ITEMS:
      draft.items = action.payload;
      break;

    // Установить текущее значение
    case actionTypes.SET_ACTIVE_ITEM:
      draft.activeItem = action.payload;
      break;

    // Очистка списка групп
    case actionTypes.CLEAR_GROUPS_ITEMS:
      return initialState;

    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
