import produce from 'immer';
import * as types from './actionTypes';
import * as companySelectors from './selectors';
import * as companyService from '../../services/companyService';
import { toast } from '../../components/Toaster';
import { getTypesAndDocuments } from '../documents/actions';
import { getCity, getHouseByFias, getStreetByFias } from '../../services/catalogService';
import { sendForVerificationEvent, sendLisenceAcceptEvent } from '../googleAnalytics/events';
import { getCompanyID } from './selectors';
import { getCompanyInfo } from '../../helpers/getCompanyInfo';
import { getCompanyInfoById } from '../../services/companyService';
import errorsMapping from '../../errors';

export function searchForCity(query) {
  return getCity(query);
}

export function searchForStreet(query, cityField, values) {
  const cityFieldValue = values?.[cityField]?.value;
  const fiasId = cityFieldValue?.settlement_fias_id ?? cityFieldValue?.city_fias_id ?? cityFieldValue?.fias_id;
  const level = cityFieldValue?.settlement_fias_id ? 'settlement' : 'city';

  return getStreetByFias(query, fiasId, level);
}

export function searchForHouse(query, cityField, streetField, values) {
  const city = companySelectors.getLabel(values)(cityField);
  const street = companySelectors.getLabel(values)(streetField);

  const fiasId =
    values?.[streetField]?.value?.street_fias_id ||
    values?.[streetField]?.value?.settlement_fias_id ||
    values?.[streetField]?.value?.city_fias_id ||
    values?.[cityField]?.value?.settlement_fias_id ||
    values?.[cityField]?.value?.city_fias_id;

  return getHouseByFias(query, fiasId ? '' : city ?? '', street ?? '', fiasId);
}

// в функцию передаем selector, соответствующий каждому табу, если потребуется валидация полей форм,
// то скорее всего необходимо будет написать action для каждой формы и селектора
export function updateCompanyProfile(values) {
  return (dispatch, getState) => {
    const companyId = getCompanyID(getState());
    const params = { companyId, ...values };

    return companyService
      .updateCompany(params)
      .then((companyData) => {
        dispatch(updateCompany(companyData));
        dispatch(getTypesAndDocuments('company', companyData.id));
        toast.success('Профиль успешно обновлен');
        return Promise.resolve(companyData);
      })
      .catch((error) => {
        const errorObj = errorsMapping.get(error?.response?.data?.error_description);
        if (errorObj) {
          toast.error(errorObj.title, errorObj.message);
        } else {
          toast.errorAsync(error);
        }
        throw error;
      });
  };
}

export function sendToApprove() {
  return (dispatch, getState) => {
    let companyId = getCompanyID(getState());

    return companyService
      .sendToApprove(companyId)
      .then((companyData) => {
        dispatch(updateCompany(companyData));
      })
      .then(sendForVerificationEvent)
      .catch((error) => toast.errorAsync(error));
  };
}

export function acceptContractTraffic() {
  return (dispatch, getState) => {
    let companyId = getCompanyID(getState());
    let params = {
      companyId,
      online_contract_approved: true,
    };

    return companyService
      .updateCompany(params)
      .then((companyData) => {
        dispatch(updateCompany(companyData));
        toast.success('Вы ознакомились с договором присоединения');
      })
      .then(sendLisenceAcceptEvent)
      .catch(toast.errorAsync);
  };
}

export function updateCompany(companyData) {
  return { type: types.UPDATE_COMPANY, companyData };
}

export function clearCompany() {
  return { type: types.CLEAR_COMPANY_DATA };
}

export function extendItemWithCompanyInfo(item) {
  let id = item?.shipping?.company_id || item?.executors?.[0]?.company_id;
  if (!!id) {
    return getCompanyInfoById(id).then((data) => {
      return produce(item, (draftState) => {
        if (data) {
          draftState['performerCompanyInfo'] = getCompanyInfo(data);
        }
      });
    });
  }
  return Promise.resolve(item);
}

export function confirmDltOffer(value) {
  return (dispatch, getState) => {
    let companyId = getCompanyID(getState());

    return companyService.confirmDltOffer(companyId, value).then((res) => {
      dispatch({ type: types.UPDATE_DLT_OFFER_STATE, res });
    });
  };
}

export function confirmDlOffer(value) {
  return (dispatch, getState) => {
    let companyId = getCompanyID(getState());

    return companyService.confirmDlOffer(companyId, value).then((res) => {
      dispatch({ type: types.UPDATE_DL_OFFER_STATE, res });
    });
  };
}
