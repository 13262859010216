import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

function IconLock(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M4 17C3.44772 17 3 16.5523 3 16L3 7C3 6.44771 3.44772 6 4 6H6.04169V4.5415C6.04169 3.40475 6.42202 2.4539 7.18267 1.68894C7.94332 0.923983 8.88244 0.541504 10 0.541504C11.1176 0.541504 12.0567 0.923983 12.8174 1.68894C13.578 2.4539 13.9584 3.40475 13.9584 4.5415V6H16C16.5523 6 17 6.44772 17 7V16C17 16.5523 16.5523 17 16 17H4ZM7.50002 6H12.5V4.5383C12.5 3.84599 12.257 3.24984 11.7709 2.74984C11.2847 2.24984 10.6945 1.99984 10 1.99984C9.30558 1.99984 8.7153 2.24845 8.22919 2.74567C7.74308 3.24289 7.50002 3.83873 7.50002 4.53317V6ZM10.0033 13.3957C10.4499 13.3957 10.8213 13.2463 11.1178 12.9476C11.4143 12.649 11.5625 12.2764 11.5625 11.8299C11.5625 11.3833 11.4132 11.0119 11.1145 10.7154C10.8158 10.4189 10.4432 10.2707 9.99671 10.2707C9.5502 10.2707 9.1787 10.42 8.88223 10.7187C8.58576 11.0174 8.43752 11.39 8.43752 11.8365C8.43752 12.283 8.58686 12.6545 8.88555 12.951C9.18424 13.2474 9.55684 13.3957 10.0033 13.3957Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
}

IconLock.defaultProps = {
  color: 'base',
};

export default React.memo(IconLock);
