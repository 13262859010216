import queryString from 'query-string';
import http from './config/webConfig';
import { clearToken, getAccessToken, getRefreshToken, saveToken } from './authTokenService';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { getAuthUrl } from '../helpers/getAuthUrl';

const { default: axios } = require('axios');

export async function authorization_DEPRECATED({
  login,
  password,
  refresh_token,
}: {
  login?: string;
  password?: string;
  refresh_token?: string | null;
}): Promise<any> {
  const url = `${process.env.REACT_APP_REST_API_URL_REPLACE}/api/v1/auth/token`;
  const params = {
    grant_type: refresh_token ? 'refresh_token' : 'password',
    username: login,
    password,
    refresh_token,
  };
  try {
    return axios.post(url, { ...params }).then((response: { data: any }) => {
      // сохраняем токен после успешной авторизации
      saveToken(response.data);
      return response.data;
    });
  } catch (error) {
    const errorCode = error?.response?.status;
    if (errorCode === 401 || errorCode === 403) {
      // throw error;
      return Promise.reject(error);
    }
  }
}

type TAuth = {
  code?: string;
  state?: string;
  flow?: string;
  refresh_token?: string;
};

const getRedirectUri = (flow) => {
  if (!flow) return process.env.REACT_APP_OAUTH_REDIRECT_URL;

  return `${process.env.REACT_APP_OAUTH_REDIRECT_URL}?flow=${flow}`;
};

export async function authorization({ code, state, flow, refresh_token }: TAuth): Promise<void> {
  const params = {
    ...(refresh_token ? { refresh_token } : { code, state, redirect_uri: getRedirectUri(flow) }),
    grant_type: refresh_token ? 'refresh_token' : 'authorization_code',
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
  };

  // debugger;

  try {
    return axios
      .post(process.env.REACT_APP_OAUTH_URL + '/token', null, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cache: false,
        transformRequest: [() => queryString.stringify(params)],
      })
      .then((response: { data: any }) => {
        // await saveChatAccessToken();
        return saveToken(response.data);
      });
  } catch (error) {
    let errorCode = error?.response?.status;
    if (errorCode === 401 || errorCode === 403) {
      throw error;
    }
  }
}

export async function logout(): Promise<any> {
  const params = {
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
  };

  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();

  if (accessToken) {
    const accessTokenParams = {
      ...params,
      token_type_hint: 'access_token',
      token: getAccessToken(),
    };
    try {
      await axios.post(process.env.REACT_APP_OAUTH_URL + '/revoke', null, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cache: false,
        transformRequest: [() => queryString.stringify(accessTokenParams)],
      });
    } catch {}
  }

  if (refreshToken) {
    try {
      const refreshTokenParams = {
        ...params,
        token_type_hint: 'refresh_token',
        token: refreshToken,
      };
      await axios.post(process.env.REACT_APP_OAUTH_URL + '/revoke', null, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cache: false,
        transformRequest: [() => queryString.stringify(refreshTokenParams)],
      });
    } catch {}
  }

  clearToken();
  // return logoutFromChat();
}

export function recovery(email: string): Promise<any> {
  const url: string = '/auth/password_recovery';
  return http.post(url, { email });
}

export function confirmNewPassword({
  confirmation,
  password,
}: {
  confirmation: string | null;
  password: string;
}): Promise<any> {
  const url: string = '/auth/password';
  return http.put(url, { confirmation, new_password: password });
}

/**
 * Получить все доступные роли
 */
export async function getRolePermissions() {
  let url = '/auth/roles';
  let {
    data: { roles },
  } = (await http.get(url)) ?? {};
  return roles;
}

/**
 * Изменение пароля по токену восстановления пароля
 */
export async function setNewPassword(params) {
  const url = '/auth/set_password';
  const { data } = await http.post(url, { ...params });
  return data;
}

/**
 * Смена пароля пользователя
 * @param oldPassword
 * @param newPassword
 */
export async function changePassword({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}): Promise<any> {
  let url = '/auth/update_password';
  let params = {
    old_password: oldPassword,
    new_password: newPassword,
  };
  let { data } = (await http.post(url, { ...params })) ?? {};
  saveToken(data);
  return data;
}

/**
 * Проверка срока действия кода установки пароля
 * @param confirmation
 */
export async function validateConfirmation(confirmation) {
  let url = '/auth/validate';
  return await http.get(url, { params: { code: confirmation } });
}

/**
 * Выслать письмо об активации аккаунта
 * * @param username
 */
export async function resendEmail(username) {
  let url = '/auth/resend_email';
  let { data } = (await http.post(url, { username })) ?? {};
  return data;
}

/**
 * Проверка - подтвердил ли пользователь регистрацию
 * @param username
 */
export async function checkRegistration(username) {
  let url = '/auth/check_registration';
  let { data } = (await http.get(url, { params: { username } })) ?? {};
  return data;
}

/**
 * Заказать звонок менеджера на лендинге интеграции с траффиком
 * * @param params
 */
export async function demoRequest(params): Promise<any> {
  const url: string = '/marketing/demo_request';
  return await http.post(url, params).then((response) => response?.data);
}

/**
 * Редирект на страницу авторизации с сохранением редирект ури в куки
 */
export function redirectToAuth() {
  const state = uuidv4();
  const {
    code: searchCode,
    state: searchState,
    ...search
  } = queryString.parse(window.location.search, { arrayFormat: 'bracket' });
  const redirectUri = [window.location.pathname, queryString.stringify(search, { arrayFormat: 'bracket' })].join('?');

  if (redirectUri !== '/') {
    const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);

    Cookies.set(state, redirectUri, { expires: inFifteenMinutes });
  }

  window.location.href = getAuthUrl({ state });
}
