import React from 'react';

import styles from './styles.module.scss';

import { SHIPPING_REQUEST } from '../../blocks/OrderNFormTypeBlock';
import NotificationBar from '../../../../components/NotificationBar';
import { useGlobalExpeditedPayment } from '../../../tools/expedited-payment/hooks';

export function AuctionExpeditedPaymentBlock(props) {
  const { orderType, isOrderBasedOnContract } = props;
  const { isExpeditedPaymentEnabled } = useGlobalExpeditedPayment();

  if (isExpeditedPaymentEnabled || orderType !== SHIPPING_REQUEST || isOrderBasedOnContract) return null;

  return (
    <NotificationBar
      className={styles.notificationBar}
      theme="info-lightest"
      text={
        <>
          Вы можете подключить{' '}
          <a href="/tools/expedited-payment" target="_blank" rel="noreferrer noopener" className="link">
            ускоренную оплату
          </a>
          . Это позволит вам экономить на выплатах по заказу.
        </>
      }
    />
  );
}
