/**
 * Склонение числительных
 * @param number {Number} - число
 * @param titles {Array} - масив слов
 * @return {String}  слово, после склонения
 */
import { CALENDAR, WORKING } from '../store/order/formalization/formKeys';

export function declinationOfNumbers(number: number, titles: string[]): string {
  const cases: number[] = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

export function declinationOfDays(number: number): string {
  return declinationOfNumbers(number, ['день', 'дня', 'дней']);
}

export function declinationClosingPeriod(number: number, type: string): string {
  if (type === CALENDAR) {
    return declinationOfNumbers(number, ['календарный день', 'календарных дня', 'календарных дней']);
  }
  if (type === WORKING) {
    return declinationOfNumbers(number, ['рабочий день', 'рабочих дня', 'рабочих дней']);
  }
  return '';
}

/**
 * Склонение перевозок
 * сколько
 * @param number
 * @returns {String}
 */
export function declinationOfShippingsHowMuch(number: number): string {
  return declinationOfNumbers(number, ['перевозка', 'перевозки', 'перевозок']);
}

/**
 * Склонение перевозок
 * за сколько
 * @param number {Number} - число перевозок
 * @return {String} - слово, после склонения
 */
export function declinationOfShippingsForHowMuch(number: number): string {
  return declinationOfNumbers(number, ['перевозку', 'перевозки', 'перевозок']);
}

/**
 * Склонение перевозок
 * на основании
 * @param number {Number} - число перевозок
 * @return {String} - слово, после склонения
 */
export function declinationOfShippingsBasedOnHowMuch(number: number): string {
  return declinationOfNumbers(number, ['перевозки', 'перевозок', 'перевозок']);
}

/**
 * Склонение перевозчиков
 * за сколько
 * @param number {Number} - число перевозчиков
 * @return {String} - слово, после склонения
 */
export function declinationOfCarriersHowMuch(number: number): string {
  return declinationOfNumbers(number, ['перевозчика', 'перевозчиков', 'перевозчиков']);
}

/**
 * Склонение предложений
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfOffers(number: number): string {
  return declinationOfNumbers(number, ['предложение', 'предложения', 'предложений']);
}

/**
 * Склонение файлов для документов компании
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfFiles(number: number): string {
  return declinationOfNumbers(number, ['файл', 'файла', 'файлов']);
}

/**
 * Склонение компаний
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfCompanies(number: number): string {
  return declinationOfNumbers(number, ['компания', 'компании', 'компаний']);
}

/**
 * Склонение групп
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfGroups(number: number): string {
  return declinationOfNumbers(number, ['группа', 'группы', 'групп']);
}

/**
 * Склонение этапов
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfStages(number: number): string {
  return declinationOfNumbers(number, ['этап', 'этапа', 'этапов']);
}

/**
 * Склонение минут
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfMinutes(number: number): string {
  return declinationOfNumbers(number, ['минуту', 'минуты', 'минут']);
}

/**
 * Склонение "корректных"
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfCorrect(number: number): string {
  return declinationOfNumbers(number, ['корректный', 'корректных', 'корректных']);
}

/**
 * Склонение "некорректных"
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfIncorrect(number: number): string {
  return declinationOfNumbers(number, ['некорректный', 'некорректных', 'некорректных']);
}

/**
 * Склонение "создано"
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfCreated(number: number): string {
  return declinationOfNumbers(number, ['создан', 'созданы', 'созданы']);
}

/**
 * Склонение "партнёры"
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfPartners(number: number): string {
  return declinationOfNumbers(number, ['партнёр', 'партнёра', 'партнёров']);
}

/**
 * Склонение частоты обновления
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfMinutesFrequency(number: number): string {
  let time = 'По факту';
  const minutes = Math.floor(number / 60);
  if (number) {
    time = `Раз в ${minutes} ${declinationOfMinutes(minutes)}`;
  }
  return time;
}

/**
 * Склонение "просмотры"
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfViews(number: number): string {
  return declinationOfNumbers(number, ['просмотр', 'просмотра', 'просмотров']);
}

/**
 * Склонение "заявки"
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfOrders(number: number): string {
  return declinationOfNumbers(number, ['заявка', 'заявки', 'заявок']);
}

/**
 * Склонение "создана"
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfCreateF(number: number): string {
  return declinationOfNumbers(number, ['создана', 'созданы', 'создано']);
}

/**
 * Склонение "требование"
 * @param number {Number} - число предложений
 * @return {String} - слово, после склонения
 */
export function declinationOfRequirement(number: number): string {
  return declinationOfNumbers(number, ['требованию', 'требованиям', 'требованиям']);
}
