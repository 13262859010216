import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconArrowWavy = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 3.5H3.5V6.5H6.5V5.5H14C15.1046 5.5 16 6.39543 16 7.5C16 8.60457 15.1046 9.5 14 9.5L6 9.5C4.34315 9.5 3 10.8431 3 12.5C3 14.1569 4.34315 15.5 6 15.5L14 15.5V17.5L17 15L14 12.5V14.5L6 14.5C4.89543 14.5 4 13.6046 4 12.5C4 11.3954 4.89543 10.5 6 10.5L14 10.5C15.6569 10.5 17 9.15685 17 7.5C17 5.84315 15.6569 4.5 14 4.5H6.5V3.5Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconArrowWavy.defaultProps = {
  color: 'base',
};

export default IconArrowWavy;
