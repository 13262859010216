import { CONTRACT } from '../../../store/order/routes/formKeys';
import { Link } from 'react-router-dom';
import React from 'react';

function getAboutHeader(item, isContract, withGraphicLink) {
  const headers: string[] = [];
  if (item?.isInterval) {
    headers.push(`Интервальная заявка`);
  } else {
    if (isContract) {
      // компания исполнитель
      if (item?.authorId) {
        headers.push(`Объёмный контракт`);
      } else {
        headers.push(`График`);
      }
    } else {
      headers.push(`Заявка с фиксированным временем подачи`);
    }
  }

  headers.push(` № ${item?.humanFriendlyId} от ${item?.createdAt} `);

  if (item?.contractId) {
    headers.push('по контракту № ');
  }
  if (withGraphicLink) {
    headers.push(`по графику № `);
  }

  return headers.join('');
}

export function getDetailTitle(item, options = { useFinishLocation: false }) {
  const isContract = item.orderType === CONTRACT || item?.isGraphic;

  const withGraphicLink = !item?.isGraphic && !item?.contractId && (item?.graphicId || item?.contractShippingId);

  const radiusStart = item?.routePoints && item?.routePoints[0]?.location.radius;

  const radiusEnd = item?.routePoints && item?.routePoints?.[item?.routePoints?.length - 1]?.location?.radius;

  const aboutHeader = getAboutHeader(item, isContract, withGraphicLink);

  const externalInfo = item?.externalHumanFriendlyId ? (
    <>
      {item?.contractId || withGraphicLink ? <br /> : ' '}
      Номер заказа: {item.externalHumanFriendlyId}
    </>
  ) : (
    ''
  );

  const locationFrom = item?.points?.[0]?.route;

  const lastRoutePointIndex = item?.routePoints?.length - 1;
  const locationTo = options.useFinishLocation
    ? lastRoutePointIndex > 0
      ? item?.routePoints?.[lastRoutePointIndex]?.location?.route
      : null
    : item?.points?.[1]?.route;

  const LINK_INFO = new Map()
    .set(item?.graphicId, {
      link: `/graphics?active_item=${item?.graphicId}`,
      hfid: item?.graphicHumanFriendlyId,
    })
    .set(item?.contractId, {
      link: `/contracts?active_item=${item?.contractId}`,
      hfid: item?.contractHFId,
    })
    .set(item?.contractShippingId, {
      link: `/volume-graphics?active_item=${item?.contractShippingId}`,
      hfid: item?.shippingOrderContractHFId,
    });

  const getLink = (item) => {
    if (
      !!item.orderType && // only for `shipping_request`
      (item?.graphicId || item?.contractId || item?.contractShippingId)
    ) {
      const { link, hfid } = LINK_INFO.get(item?.graphicId || item?.contractId || item?.contractShippingId) || {};
      return (
        <Link to={link} target="_blank" rel="noopener noreferrer" className="link">
          {hfid}
        </Link>
      );
    }

    return null;
  };

  const commonTitle = {
    aboutHeader,
    link: getLink(item),
    externalInfo,
    isHourlyRent: item.isHourlyRent,
    isCircular: item.isCircular,
    isCityRoute: item.isCityRoute,
  };

  if (!locationFrom && !locationTo) {
    return {
      ...commonTitle,
      locationFrom: undefined,
      locationTo: undefined,
    };
  }

  if (isContract && (radiusStart || radiusEnd)) {
    return {
      ...commonTitle,
      locationFrom: `${locationFrom} ${!!radiusStart ? `+${radiusStart} км` : ''}`,
      locationTo: `${locationTo} ${!!radiusEnd ? `+${radiusEnd} км` : ''}`,
    };
  }

  return {
    ...commonTitle,
    locationFrom: locationFrom ?? '–',
    locationTo: locationTo ?? '–',
  };
}
