import _flattenDeep from 'lodash/flattenDeep';
import _minBy from 'lodash/minBy';

/**
 * Хелпер для создания текстового пути до элементов
 * @param object
 * @param initialPath
 * @returns {Array}
 */
// export function getPathsToItems(object, initialPath = '') {
//   const errorArr = [];
//
//   errorArr
//     .push(
//       Object.keys(object).map((key) => {
//         const next = object[key];
//         if (!next) return null;
//
//         if (typeof next === 'string' || typeof next === 'boolean') {
//           return initialPath + key;
//         }
//
//         if (Array.isArray(next)) {
//           errorArr.push(
//             next
//               .map((item, index) =>
//                 getPathsToItems(item, `${initialPath}${key}[${index}].`),
//               )
//               .filter(Boolean),
//           );
//         }
//         return errorArr;
//       }),
//     )
//     .filter(Boolean);
//
//   return _flattenDeep(errorArr);
// }

/**
 * Хелпер для создания текстового пути до элементов
 * @param object
 * @param initialPath
 * @returns {Array}
 */
export function getPathsToItems(object, initialPath = '') {
  const errorArr = [];

  errorArr.push(
    Object.keys(object || {})
      // eslint-disable-next-line array-callback-return
      .map((key) => {
        const next = object[key];

        if (!next) return null;

        if (typeof next === 'string' || typeof next === 'boolean') {
          return initialPath + key;
        }

        if (Object.prototype.toString.call(next) === '[object Object]') {
          errorArr.push(getPathsToItems(next, `${initialPath}${key}.`));
        }

        if (Array.isArray(next)) {
          errorArr.push(
            next.map((item, index) => getPathsToItems(item, `${initialPath}${key}[${index}].`)).filter(Boolean),
          );
        }
      })
      .filter(Boolean),
  );

  return _flattenDeep(errorArr);
}

/**
 * Получение элемента дома первой ошибки
 * @param errorArr
 * @return {*}
 */
export function getFirstErrorElement(errorArr) {
  const result = _minBy(
    errorArr.map((path) => {
      const node = document.querySelector(`[name="${path}"]`);
      return {
        node,
        top: node ? node.getBoundingClientRect().top : null,
      };
    }),
    (o) => {
      return o ? o.top : null;
    },
  );
  return result ? result.node : null;
}

/**
 * Прокрутка на первый элемент с ошибкой
 * @param errors
 */
function onSubmitFail(errors) {
  if (!errors) {
    return;
  }

  try {
    const element = getFirstErrorElement(getPathsToItems(errors));
    // TODO: для датапикера не находится элемент ноды, так как нет инпута
    if (!element) {
      return;
    }
    const position = element.getBoundingClientRect().top + window.pageYOffset - 60;

    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: position,
    });

    setTimeout(() => {
      element.focus();
    }, 200);
  } catch (error) {
    throw new Error(error);
  }
}

export default onSubmitFail;
