import classNames from 'classnames';
import React, { ReactNode, useCallback } from 'react';
import { FieldProps, getIn } from 'formik';

import styles from './styles.module.scss';

import Tooltip from '../Tooltip';
import IconHelpOutline from '../Icons/SVGIcons/IconHelpOutline';
import { useFormikContextSelector } from './FormikContext';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  additional?: React.ReactNode;
  comment?: string;
  label?: string;
  labelHelp?: string;
  labelDisable?: boolean;
  labelClassName?: string;
  normalize?: (value: any, prevValue: any) => any;
  error?: string | ReactNode;
}

const inputHOC = (WrappedComponent) => (props: IProps & FieldProps) => {
  const { field, onChange: onChangeProps, onBlur: onBlurProps } = props;
  const apiError = useFormikContextSelector((c) => getIn(c.status, field.name));
  const error = useFormikContextSelector((c) => getIn(c.errors, field.name));
  const touch = useFormikContextSelector((c) => getIn(c.touched, field.name));
  const status = useFormikContextSelector((c) => c.status);
  const setStatus = useFormikContextSelector((c) => c.setStatus);
  const setFieldValue = useFormikContextSelector((c) => c.setFieldValue);

  const {
    label,
    labelHelp,
    labelDisable,
    labelClassName,
    additional,
    comment,
    normalize,
    error: externalError,
    ...rest
  } = props;

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value ?? event;
      const prevValue = field.value;
      if (normalize) {
        setFieldValue(field.name, normalize(value, prevValue));
      } else {
        const res = event?.target;
        res ? field.onChange(event) : setFieldValue(field.name, event);
      }
      onChangeProps?.(event);

      // сброс асинхронной ошибки
      if (value !== prevValue) {
        setStatus({ ...status, [field.name]: null });
      }
    },
    [field, normalize, onChangeProps, setFieldValue, setStatus, status],
  );

  const onBlur = useCallback(
    (event) => {
      if (event) {
        field.onBlur(event);
        onBlurProps?.(event);
      }
    },
    [field, onBlurProps],
  );

  const isFormError = touch && (error || apiError || externalError);

  return (
    <label className={classNames(styles.label, { [styles.error]: isFormError }, labelClassName)}>
      {!labelDisable && (
        <span
          className={classNames('flex caption color-dark mb-0', {
            'color-danger': isFormError,
          })}
          style={{ height: 20 }}
        >
          <span className={classNames({ 'mr-1': labelHelp })}>{label}</span>
          {labelHelp && (
            <Tooltip content={labelHelp} className={styles.labelPopover}>
              <IconHelpOutline className={styles.labelIcon} />
            </Tooltip>
          )}
        </span>
      )}
      <WrappedComponent {...rest} onBlur={onBlur} onChange={onChange} isError={isFormError} />
      {additional && (
        <span className={labelDisable ? styles.additionalWithOutLabel : styles.additional}>{additional}</span>
      )}
      {(comment || isFormError) && (
        <span
          className={classNames(styles.comment, {
            [styles.error]: isFormError,
          })}
        >
          {isFormError ? error || apiError || externalError : comment}
        </span>
      )}
    </label>
  );
};

export default inputHOC;
