import { useEffect, useRef } from 'react';
import { useFieldValue } from '../../../components/FormikFieldsV3/hooks';
import { ROUTE_COVER_TYPE, ROUTE_POINTS } from '../blocks/RoutesBlock';

/**
 * Изменение типа загрузки при смене типа кузова
 * @param routePointIndex
 * @param coverTypes
 */
export function useCoverTypeChange(routePointIndex: number, coverTypes: any[]) {
  const [formCoverTypes, setFormCoverType] = useFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_COVER_TYPE}`);
  const formCoverTypesMutable = useRef(formCoverTypes);

  useEffect(() => {
    formCoverTypesMutable.current = formCoverTypes;
  }, [formCoverTypes]);

  useEffect(() => {
    const availableCoverTypes = coverTypes?.map((c) => c.value) || [];
    const filteredCoverTypes =
      formCoverTypesMutable.current?.filter((c) => availableCoverTypes.includes(c.value)) || [];
    const selectedCoverTypes = availableCoverTypes.length === 1 ? coverTypes : filteredCoverTypes;
    setFormCoverType(selectedCoverTypes);
  }, [setFormCoverType, coverTypes]);
}
