import React, { useCallback } from 'react';
import classNames from 'classnames';
import Popover from '../../../../components/Popover';
import styles from '../../../../components/DataListCollections/styles.module.scss';
import { TGeoLocationLevel, TLocation } from '../../../../store/counterAgents/models';
import { IconTarget } from '../../../../components/Icons';
import Table from '../../../../components/Table';

function getAddressString(item: TLocation) {
  return (
    <>
      <div className="data-truncate">{item.warehouse_name ?? '—'}</div>
      <div className="caption data-truncate">
        <Popover
          trigger={item?.location_string ? 'hover' : 'none'}
          placement="top"
          className="tooltip"
          withoutInline
          content={item?.location_string}
        >
          {item?.location_string ?? '—'}
        </Popover>
      </div>
    </>
  );
}

interface IAddressesList {
  items: TLocation[];
  onSelect?: (id: string) => void;
  actionElement?: React.ReactElement;
  isOrderChangeWithWarehouse?: boolean;
  isWarehouses?: boolean;
}

function AddressesList({
  items,
  onSelect,
  actionElement,
  isOrderChangeWithWarehouse = false,
  isWarehouses,
}: IAddressesList) {
  const isItemDisabled = useCallback(
    (item) => isOrderChangeWithWarehouse && item.is_warehouse,
    [isOrderChangeWithWarehouse],
  );

  const handleRowClick = React.useCallback(
    (item) => {
      if (isItemDisabled(item)) return;

      if (actionElement && !isWarehouses) {
        actionElement.props.onClick?.(item);
      } else {
        onSelect?.(item.id);
      }
    },
    [isItemDisabled, actionElement, isWarehouses, onSelect],
  );

  return (
    <>
      <Table data={items} onRowClicked={handleRowClick}>
        <Table.Column header={'ID'} width={'50px'}>
          {(item) => (
            <span className={isItemDisabled(item) ? 'color-base' : 'color-dark'}>{item.human_friendly_id}</span>
          )}
        </Table.Column>

        <Table.Column header={''} width={'20px'} center>
          {(item) => (
            <span>
              {item?.geo_location?.level === TGeoLocationLevel.Point && (
                <div className="flex items-center">
                  <Popover placement="top" className="tooltip" content="Заданы координаты точки подачи ТС">
                    <IconTarget width={20} height={20} />
                  </Popover>
                </div>
              )}
            </span>
          )}
        </Table.Column>

        <Table.Column header={'Наименование и адрес'}>
          {(item) => (
            <span className={classNames({ 'color-base': isItemDisabled(item) })}>{getAddressString(item)}</span>
          )}
        </Table.Column>

        <Table.Column header={'Контрагент и контактное лицо'}>
          {(item) => (
            <span className={classNames('flex column', { 'color-base': isItemDisabled(item) })}>
              <div className={styles.dataTruncate}>
                <Popover
                  trigger={item?.counter_agent_string ? 'hover' : 'none'}
                  placement="top"
                  className="tooltip"
                  withoutInline
                  content={item?.counter_agent_string}
                >
                  {item?.counter_agent_string ?? '—'}
                </Popover>
              </div>

              {item?.contacts_list?.length > 0 && (
                <div className="flex">
                  <span className={classNames('caption', styles.dataTruncate, { 'color-base': isItemDisabled(item) })}>
                    {item.contacts_list[0]}
                  </span>
                  {item?.contacts_list?.length > 1 && (
                    <Popover
                      trigger="hover"
                      placement="top"
                      className="tooltip"
                      content={
                        <>
                          {item.contacts_list.slice(1).map((item, i) => (
                            <React.Fragment key={i}>
                              {item}
                              <br />
                            </React.Fragment>
                          ))}
                        </>
                      }
                    >
                      <span className="caption color-primary ml-1 text-nowrap">+ {item.contacts_list.length - 1}</span>
                    </Popover>
                  )}
                </div>
              )}
            </span>
          )}
        </Table.Column>

        {!!actionElement && (
          <Table.Column header={''} width={isWarehouses ? '160px' : '75px'}>
            {(item) => (
              <span>
                {React.cloneElement(actionElement, {
                  onClick: (e) => {
                    e.stopPropagation();
                    actionElement.props.onClick?.(item);
                  },
                  disabled: isItemDisabled(item),
                })}
              </span>
            )}
          </Table.Column>
        )}
      </Table>
    </>
  );
}

export default AddressesList;
