import { TRequestExpeditedPayment } from '../../../features/expedited-payment/models';
export type BidProps = {
  start: number | null;
  max: number | null;
  min: number;
  step: number | null;
  best: number | null;
  maxCount: number;
  minCount: number;
  isWinning: string;
  initialBid: number;
  currentBid: string;
  currentBidRaw: number;
  winningBid: number;
  expeditedPaymentInitialBid: number;
  expeditedPaymentWinningBid: number;
  expeditedPaymentMax: number;
  expeditedPaymentMin: number;
  isExpeditedPayment: boolean;
  expeditedPaymentDays: number | null;
  lastArchiveBid?: number;
  currentShippingsCount: number | null;
  currentMinWorkHours: number | null;
};

export function getBidsInfo(
  bid: BidProps,
  isExpeditedPayment: boolean,
  isAuctionWithPrices: boolean,
  isBlindAuction: boolean,
  isDescPriceAuction: boolean,
  withInitialBidInfo: boolean,
) {
  let result: any = { ...bid, start: bid.initialBid };
  if (isExpeditedPayment) {
    result = {
      ...bid,
      best: bid.expeditedPaymentWinningBid,
      start: bid.expeditedPaymentInitialBid,
      min: bid.expeditedPaymentMin,
      max: bid.expeditedPaymentMax,
    };
  }

  if (isDescPriceAuction) {
    result = { ...result, max: null };
  }

  if (!isAuctionWithPrices) {
    result = { ...result, step: null };
  }

  if (!withInitialBidInfo) {
    result = { ...result, start: null };
  }

  if (isBlindAuction) {
    result = { ...result, best: null };
  }

  return result;
}

/**
 * * Маппинг данных по ставкам
 * @param bid {Object} - данные по ставкам
 * @param isExpeditedPayment {Boolean} - ставка с ускоренной оплатой
 * @param isDescPriceAuction {Boolean} - тип аукциона - на понижение
 * @param withDynamicRange {Boolean} - признак динамического коридора ставок
 * @param withLowerBidLimit {Boolean} - закрытый аукцион заявок ДЛ - мин ставка - 80%
 * @return {{current: *, min: *, isSet: ({currentBid}|*), max: *, start: *, best: *, step: *, isWin: boolean}}
 */
export function getAuctionBids(
  bid: BidProps,
  isExpeditedPayment: boolean,
  isDescPriceAuction: boolean,
  withDynamicRange: boolean,
  withLowerBidLimit: boolean,
) {
  let current: number = bid.initialBid;
  let best: number | null = null;
  let max: number | null = null;
  let min = bid.min;

  if (withDynamicRange && bid.lastArchiveBid) current = bid.lastArchiveBid;

  if (bid.currentBidRaw) current = bid.currentBidRaw;

  if (bid.winningBid) {
    best = bid.winningBid;
  }

  if (isDescPriceAuction) {
    max = best || bid.initialBid;
  } else {
    max = bid.max;
  }
  if (withDynamicRange && current > max!) {
    let actualCurrent = Number(current);
    while ((Number(max) * 10) / 11 < actualCurrent) {
      actualCurrent -= Number(bid.step);
    }
    current = actualCurrent;
  }

  if (withLowerBidLimit || (withDynamicRange && !!bid.currentBid)) {
    let limit = current * 0.8;
    let actualMin = Number(min);

    if (actualMin < limit) {
      while (limit > actualMin) {
        actualMin += Number(bid.step);
      }
    } else {
      while (limit < actualMin) {
        actualMin -= Number(bid.step);
      }
    }
    min = actualMin;
  }

  return {
    ...bid,
    current,
    best,
    isBidSet: !!bid?.currentBid,
    isWinning: !!bid?.isWinning,
    max,
    min,
  };
}

export function getBidsWithExpeditedPayment(
  bid: BidProps,
  expeditedPaymentInfo: Omit<TRequestExpeditedPayment, 'setExpeditedPayment'>,
): BidProps {
  const { resultLimits, expeditedPaymentUserBid, expeditedPaymentInitialBid, expeditedPaymentWinningBid } =
    expeditedPaymentInfo;
  const expeditedPaymentMin = resultLimits[0];
  const expeditedPaymentMax = resultLimits[1];
  return {
    ...bid,
    currentBidRaw: bid.isWinning && bid.currentBidRaw,
    expeditedPaymentCurrentBidRaw: bid.isWinning && expeditedPaymentUserBid,
    expeditedPaymentInitialBid,
    expeditedPaymentWinningBid,
    expeditedPaymentMin,
    expeditedPaymentMax,
  } as BidProps;
}
