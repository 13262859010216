import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useUserContext } from '../../../contexts/userContext';

import Button from '../../../components/Button';
import ModalConfirm from '../../../components/ModalCollections/ModalConfirm';

function ForbiddenOrderCreating(props) {
  const { hasSubscription } = props;
  const history = useHistory();
  const { ability } = useUserContext();
  const goBackFromModal = () => history.goBack();

  return (
    <ModalConfirm
      title="Создание заказов недоступно"
      text={
        hasSubscription
          ? `Вам необходимо пополнить транзакции, чтобы иметь возможность создавать заказы с типом «Аукцион» и «Кто быстрее».`
          : ability.can('edit', 'billing')
          ? 'Выберите и подключите пакет, чтобы создавать заказы на перевозку.'
          : 'Для создания заказа нужного вида необходимо подключить пакет или услугу. Обратитесь к ответственному лицу.'
      }
      onClose={goBackFromModal}
      textAlign={hasSubscription ? 'left' : 'center'}
    >
      <div className="flex">
        <Button theme="secondary" size="medium" onClick={goBackFromModal}>
          Отмена
        </Button>

        {ability.can('edit', 'billing') && (
          <Button size="medium" className="ml-4" component={<Link to="/billing" />} style={{ width: 230 }}>
            {hasSubscription ? `Пополнить` : `Подключить`}
          </Button>
        )}
      </div>
    </ModalConfirm>
  );
}

export default ForbiddenOrderCreating;
