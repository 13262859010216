import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import { useOrderContext } from '../index';
import { useFieldValue } from '../../../components/FormikFieldsV3/hooks';
import { getParsedLocationObject } from '../../../store/counterAgents/selectors';
import { useFormikContextSelector } from '../../../components/FormikFieldsV3/FormikContext';

import { defaultRoutePoint } from '../helpers/getInitialValues';
import { ADDITIONAL_SERVICES, FLOOR_COUNT } from '../../../store/order/routes/formFields';
import { CONTACTS, COUNTER_AGENT, HOUSE, OFFICE, STREET } from '../../../store/counterAgents/formFields';
import {
  ROUTE_ADDRESS_HOUSE,
  ROUTE_ADDRESS_ROOM,
  ROUTE_ADDRESS_STREET,
  ROUTE_ADDRESS_CITY,
  ROUTE_POINTS,
  SHOW_ADDITIONAL_SERVICES_BLOCK,
  SHOW_CONTACTS_INFO_BLOCK,
  WAREHOUSE_ID,
  EXACT_ADDRESS,
  ROUTE_ADDRESS_COMMENT,
} from '../blocks/RoutesBlock';
import { useDeepMemoValue } from '../../../hooks/useDeepMemo';
import { useDeepCompareEffect } from '../../../hooks/useDeepCompareEffect';

/**
 * Событие на изменение улицы.
 * Если новое значение пустое, сброс дома и квартиры
 * Если новое значение маркировано как сохраненное - устанавливаем очищенную улицу, дом и квартиру
 */
export function useRoutePointStreetEffect(routePointIndex) {
  const initialValuesRoutePoint = useFormikContextSelector((c) => c.initialValues?.[ROUTE_POINTS]?.[routePointIndex]);
  const isInitialRoutePointStreetFilled = Boolean(initialValuesRoutePoint?.[ROUTE_ADDRESS_STREET]);
  const isInitialRoutePointHouseFilled = Boolean(initialValuesRoutePoint?.[ROUTE_ADDRESS_HOUSE]);
  const setFieldValue = useFormikContextSelector((c) => c.setFieldValue);
  const [streetValue] = useFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_STREET}`);

  const isInitialAddressFilledWithoutStreet = isInitialRoutePointHouseFilled && !isInitialRoutePointStreetFilled;
  const isStreetEmpty = !streetValue?.value;

  useDeepCompareEffect(() => {
    if (isStreetEmpty && !isInitialAddressFilledWithoutStreet) {
      setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_HOUSE}`, null);
      setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_ROOM}`, '');
      setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${EXACT_ADDRESS}`, null);
      setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_COMMENT}`, '');
    }
  }, [isInitialAddressFilledWithoutStreet, routePointIndex, setFieldValue, isStreetEmpty]);

  // Если адрес из сохраненных - заполнить остальные поля
  useDeepCompareEffect(() => {
    if (streetValue?.value?.from_saved) {
      const addressObj = getParsedLocationObject(streetValue?.value);
      setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_STREET}`, addressObj[STREET]);
      setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_HOUSE}`, addressObj[HOUSE]);
      setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_ROOM}`, addressObj[OFFICE]);
      setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${EXACT_ADDRESS}`, addressObj[EXACT_ADDRESS]);
      setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_COMMENT}`, addressObj[ROUTE_ADDRESS_COMMENT]);
    }
  }, [setFieldValue, streetValue?.value, routePointIndex]);
}

/**
 * Эффект на изменение адресов в точках
 * получить контракт, сравнить с текущими точками. если фиас не совпали = очистить
 */
export function useContractOrderRoutePointEffect(routePointIndex) {
  const { isOrderBasedOnContract, currentOrder } = useOrderContext();
  const setFieldValue = useFormikContextSelector((c) => c.setFieldValue);
  const [warehouseId] = useFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${WAREHOUSE_ID}`);

  const [houseValue] = useFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_HOUSE}`);
  const pointHouse = houseValue?.value?.house;
  const contractPointHouse = useDeepMemoValue(currentOrder?.[ROUTE_POINTS]?.[routePointIndex]?.location.house);

  const [streetValue] = useFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_STREET}`);
  const pointStreetFiasId = streetValue?.value?.street_fias_id ?? null;
  const contractPointStreetFiasId = useDeepMemoValue(
    currentOrder?.[ROUTE_POINTS]?.[routePointIndex]?.location.street_fias_id,
  );

  const [cityValue] = useFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ROUTE_ADDRESS_CITY}`);
  const pointCityFiasId = cityValue?.value?.settlement_fias_id || cityValue?.value?.city_fias_id;
  const contractPointCityFiasId =
    useDeepMemoValue(
      currentOrder?.[ROUTE_POINTS]?.[routePointIndex]?.location.settlement_fias_id ||
        currentOrder?.[ROUTE_POINTS]?.[routePointIndex]?.location.city_fias_id,
    ) ?? null;

  const isHouseChanged = pointHouse !== contractPointHouse;
  const isStreetChanged = pointStreetFiasId !== contractPointStreetFiasId;
  const isCityChanged = pointCityFiasId !== contractPointCityFiasId;

  const isPointChanged = isCityChanged || isStreetChanged || isHouseChanged;

  useEffect(() => {
    if (isOrderBasedOnContract) {
      if (isPointChanged && !warehouseId) {
        setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${SHOW_CONTACTS_INFO_BLOCK}`, false);
        setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${COUNTER_AGENT}`, defaultRoutePoint[COUNTER_AGENT]);
        setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${CONTACTS}`, defaultRoutePoint[CONTACTS]);
        setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${FLOOR_COUNT}`, '');
        setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${ADDITIONAL_SERVICES}`, undefined);
        setFieldValue(`${ROUTE_POINTS}[${routePointIndex}].${SHOW_ADDITIONAL_SERVICES_BLOCK}`, false);
      }
    }
  }, [isOrderBasedOnContract, streetValue, setFieldValue, routePointIndex, isPointChanged, warehouseId]);
}

/**
 * Эффект на изменение города
 */
export function useRoutePointsCityEffect() {
  const [routePoints] = useFieldValue(ROUTE_POINTS);
  const initialRoutePoints = useFormikContextSelector((ctx) => ctx.initialValues[ROUTE_POINTS]);

  const routePointsFiasIdList = routePoints.map((point) => {
    const cityValue = point[ROUTE_ADDRESS_CITY]?.value;
    return cityValue?.settlement_fias_id || cityValue?.city_fias_id;
  });

  const initialRoutePointsFiasIdList = initialRoutePoints.map((point) => {
    const cityValue = point[ROUTE_ADDRESS_CITY]?.value;
    return cityValue?.settlement_fias_id || cityValue?.city_fias_id;
  });

  return !isEqual(routePointsFiasIdList, initialRoutePointsFiasIdList);
}
