import React from 'react';
import SVGIcon from './index';

const IconAgreement = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M16 7.37494L11.7693 3H5V17H16V7.37494ZM14 11H7V12H14V11ZM7 13H14V14H7V13ZM13 6L11 4V8H15L13 6Z"
      />
    </SVGIcon>
  );
};

IconAgreement.defaultProps = {
  color: 'base',
};

export default React.memo(IconAgreement);
