import isEqual from 'lodash/isEqual';
import React, { useEffect, useRef } from 'react';

import { useFormikContextSelector } from '../../../../components/FormikFieldsV3/FormikContext';
import { getExtraOrders, getFormData } from '../../helpers/getFormData';
import { useUserContext } from '../../../../contexts/userContext';
import { useOrderContext } from '../../index';

export function OrderFormDevTools() {
  if (process.env.NODE_ENV !== 'production') return <DevTool />;
  return null;
}

function deepDiffMapper() {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    getDiff: function (obj1, obj2) {
      return this.map(obj1, obj2);
    },
    map: function (obj1, obj2) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw new Error('Invalid argument. Function given, object expected.');
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        const compareValues = this.compareValues(obj1, obj2);
        if (compareValues === this.VALUE_UNCHANGED) return null;
        return {
          type: compareValues,
          data: { from: obj1, to: obj2 }, // === undefined ? obj2 : obj1,
        };
      }

      const diff = {};
      for (const key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }

        let value2 = undefined;
        if (obj2[key] !== undefined) {
          value2 = obj2[key];
        }

        diff[key] = this.map(obj1[key], value2);
      }
      for (const key in obj2) {
        if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
          continue;
        }

        diff[key] = this.map(undefined, obj2[key]);
      }

      return diff;
    },
    compareValues: function (value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED;
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED;
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction: function (x) {
      return Object.prototype.toString.call(x) === '[object Function]';
    },
    isArray: function (x) {
      return Object.prototype.toString.call(x) === '[object Array]';
    },
    isDate: function (x) {
      return Object.prototype.toString.call(x) === '[object Date]';
    },
    isObject: function (x) {
      return Object.prototype.toString.call(x) === '[object Object]';
    },
    isValue: function (x) {
      return !this.isObject(x) && !this.isArray(x);
    },
  };
}

function DevTool() {
  const { userInfo } = useUserContext();
  const { serviceList, isOrderBasedOnContract, currentOrder, isOrderChange, isDraftEditing } = useOrderContext();

  const values = useFormikContextSelector((c) => c.values);
  const errors = useFormikContextSelector((c) => c.errors);

  const previousErrors = useRef(errors);
  const previousValues = useRef(values);

  console.log('values', values, 'errors', errors);

  useEffect(() => {
    try {
      if (!isEqual(values, previousValues.current)) {
        const result = deepDiffMapper().getDiff(previousValues.current, values);
        console.log('[VALUES CHANGE LIST]', result);
        console.log(
          '[MAPPED VALUES] [SUBMIT]',
          getFormData(values, {
            userInfo,
            serviceList,
            isOrderBasedOnContract,
            currentOrder,
            isDraftEditing,
            isPublishing: true,
            isOrderChange,
          }),
          getExtraOrders(values),
        );
        console.log(
          '[MAPPED VALUES] [DRAFT]',
          getFormData(values, {
            userInfo,
            serviceList,
            isOrderBasedOnContract,
            currentOrder,
            isDraftEditing,
            isPublishing: false,
            isOrderChange,
          }),
        );
        previousValues.current = values;
      }
    } catch (e) {
      console.error('ERROR FORM DATA MAPPING');
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!isEqual(errors, previousErrors.current)) {
      const result = deepDiffMapper().getDiff(previousErrors.current, errors);
      console.log('[ERRORS CHANGE LIST]', result);
      previousErrors.current = errors;
    }
  }, [errors]);

  return null;
}
