import React, { lazy, Suspense } from 'react';

import { useOrderContext } from '../../index';
import ContentLoader from '../../../../components/ContentLoader';
import { getHashString } from '../../helpers/getHashString';
import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import { FORM_TYPE, FULL, SIMPLE } from '../OrderNFormTypeBlock';

export const PAYER = 'payer';
export const PAYER_IS_PRIVATE_PERSON = 'private_person';
export const PAYER_CONTACTS = 'contacts';
export const PAYER_FULL_NAME = 'full_name';
export const PAYER_PASSPORT_NUMBER = 'passport_number';
export const PAYER_PASSPORT_ISSUER = 'passport_issuer';
export const PAYER_PASSPORT_ISSUED_AT = 'passport_issued_at';
export const PAYER_TITLE = 'title';
export const PAYER_INN = 'inn';
export const PAYER_KPP = 'kpp';
export const PAYER_OPF_FORM = 'opf_name';
export const PAYER_LEGAL_ADDRESS = 'legal_address';

export const DOCUMENT_FLOWS = 'document_flows';
export const EXTERNAL_HUMAN_FRIENDLY_ID = 'external_human_friendly_id';
export const RESOURCE_ASSIGNMENT_TIME = 'resource_assignment_time';
export const CONTRACT_RESOURCE_ASSIGNMENT_TIME = 'contract_resource_assignment_time';
export const TERMS_BY_PARTNERSHIP_CONTRACT = 'terms_by_partnership_contract';
export const AGREEMENT_TYPE = 'agreement_type';
export const IDLE_TIME_COST = 'idle_time_cost_without_vat';
export const CANCELLATION_WITHOUT_PENALTY_BEFORE = 'cancellation_without_penalty_before';
export const PAYMENT_PROCEDURE = 'payment_procedure';
export const PAYMENT_PERIOD = 'payment_period';
export const PAYMENT_PERIOD_TYPE = 'payment_period_type';
export const CANCELLATION_PENALTY_PERCENT = 'cancellation_penalty_percent';
export const MINIMUM_CANCELLATION_PENALTY = 'minimum_cancellation_penalty_without_vat';
export const COMMENT = 'comment';
export const DOCUMENTS_FOR_PAYMENT = 'documents_for_payment';
export const CLOSING_DOCUMENTS_PERIOD = 'closing_documents_period';
export const CLOSING_DOCUMENTS_PERIOD_TYPE = 'closing_documents_period_type';
export const CLOSING_DOCUMENTS = 'closing_documents';
export const NAME = 'name';
export const COUNT = 'count';
export const SHIPPING_AVAILABLE_IN = 'shipping_available_in';
export const RESOURCE_DEADLINE_OPTION = 'resource_deadline_option';
export const FROM_RESOURCE_SUPPLY = 'from_resource_supply';
export const FROM_RESERVE = 'from_reserve';

const FullDocumentsBlock = lazy(() => import('./DocumentsBlock'));
const OrderChangeBlock = lazy(() => import('./OrderChangeBlock'));

function getBlockComponent(hashString) {
  switch (hashString) {
    // ИТ
    case getHashString({ isOrderBasedOnContract: true, isOrderChange: true, formType: SIMPLE }):
    case getHashString({ isOrderBasedOnContract: false, isOrderChange: true, formType: SIMPLE }):
    case getHashString({ isOrderBasedOnContract: false, isOrderChange: true, formType: FULL }):
    case getHashString({ isOrderBasedOnContract: true, isOrderChange: true, formType: FULL }):
      return OrderChangeBlock;
    // создание заявки по контракту
    case getHashString({ isOrderBasedOnContract: true, isOrderChange: false, formType: SIMPLE }):
      return null;
    // Все остальные
    default:
      return FullDocumentsBlock;
  }
}

export function DocumentsBlock() {
  const { isOrderBasedOnContract } = useOrderContext();
  const { isOrderChange } = useOrderContext();
  const [formType] = useFieldValue(FORM_TYPE);
  const hashString = getHashString({ isOrderBasedOnContract, isOrderChange, formType });
  const Component = getBlockComponent(hashString);

  return <Suspense fallback={<ContentLoader withBackground />}>{Component ? <Component /> : null}</Suspense>;
}
