import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import NotificationBar from '../../../../components/NotificationBar';
import Button from '../../../../components/Button';
import { useOrderContext } from '../../index';
import { useUserContext } from '../../../../contexts/userContext';
import { SHIPPING_REQUEST } from '../../blocks/OrderNFormTypeBlock';

export function AuctionTypeNotification(props) {
  const { orderType } = props;
  const isContract = orderType !== SHIPPING_REQUEST;
  const { canCreateInstant, canCreateAuction, isOrderChange, isOrderBasedOnContract } = useOrderContext();
  const { ability } = useUserContext();
  const showNotificationBar =
    !isOrderBasedOnContract && !isOrderChange && !isContract && (!canCreateInstant || !canCreateAuction);

  if (showNotificationBar) {
    return (
      <NotificationBar
        theme="danger"
        className={styles.notificationBar}
        header={`Доступен только торг с типом «${canCreateInstant ? 'Кто быстрее' : 'Аукцион'}»`}
        text={
          ability.can('edit', 'billing')
            ? `Пополните транзакции, чтобы иметь возможность создавать заявки с типом торга «${
                canCreateInstant ? 'Аукцион' : 'Кто быстрее'
              }».`
            : `Для торга с типом «${
                canCreateInstant ? 'Аукцион' : 'Кто быстрее'
              }» недостаточно транзакций. Обратитесь к ответственному лицу вашей компании, чтобы пополнить транзакции.`
        }
      >
        {ability.can('edit', 'billing') && (
          <Button theme="primary" size="medium" component={<Link to="/billing" />} className={styles.button}>
            Пополнить
          </Button>
        )}
      </NotificationBar>
    );
  }

  return null;
}
