import React, { useCallback } from 'react';

import Button from '../../../components/Button';
import Modal from '../../../components/ModalCollections/Modal';
import Slider from '../../../components/Slider/Slider';

import { SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER } from '../../../constants';

import SlideTemplate from './SlideTemplate/SlideTemplate';
import styles from './styles.module.scss';

function GreetingModal({ onClose }) {
  const getStarted = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal onClose={onClose} isCloseOutOfModal className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.leftSide}>
          <h1 className="mt-0 mb-0">
            Добро
            <br />
            пожаловать
            <br />в Traffic!
          </h1>

          <p className="mt-6 mb-0 fs-18 leading-5">
            Нам важно, чтобы на платформе были только добросовестные контрагенты, поэтому мы проверяем каждого при
            оформлении компании.
          </p>

          <p className="mt-2 mb-0 fs-18 leading-5">Заполнение формы займет примерно 10 минут.</p>

          <div className="mt-10">
            <Button theme="primary" type={'button'} size={'medium'} className="width175" onClick={getStarted}>
              Начать оформление
            </Button>
          </div>

          <div className="color-dark" style={{ marginTop: 'auto', bottom: 0 }}>
            <span>{SUPPORT_PHONE_NUMBER.common}</span>
            <span className="ml-4">{SUPPORT_EMAIL}</span>
          </div>
        </div>

        <div className={styles.rightSide}>
          <div className={styles.sliderBackground}>
            <Slider>
              <SlideTemplate
                title="Предложения от грузовладельцев"
                text="Ищите новых партнеров, забирайте заявки, ведите торги и будьте уверены, что на платформе присутствуют только проверенные, надежные клиенты"
                imageClass={styles.suggestionsImage}
              />
              <SlideTemplate
                title="Ресурсы вашего парка"
                text="Чтобы быстрее забирать заявки, рекомендуем добавить на платформу своих водителей, автомашины и прицепы. Добавить ресурс можно массово или каждого вручную."
                imageClass={styles.resourcesImage}
              />
              <SlideTemplate
                title="Помощник формирования цены"
                text="Проверяйте, насколько предложенные цены соответствуют рынку и следите за динамикой изменения стоимости перевозок."
                imageClass={styles.analyticsImage}
              />
            </Slider>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default GreetingModal;
