import React from 'react';
import SVGIcon from './index';

function IconEstimate(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path
        fillRule="evenodd"
        d="M23 13h-5v12H7V5h16v8zM9 7v4h12V7H9zm1 6v2h2v-2h-2zm4 0v2h2v-2h-2zm-4 4v2h2v-2h-2zm4 0v2h2v-2h-2zm-4 4v2h2v-2h-2zm4 0v2h2v-2h-2zm7-6h3c2.003 0 3.004 1.18 3.004 3.54 0 2.36-1.001 3.513-3.004 3.46h-1.003v1H26v2h-3.003v2H21v-2h-1v-2h1v-1h-1v-2h1v-5zm2 5c1.333 0 2-.5 2-1.5s-.667-1.5-2-1.5v3z"
      />
    </SVGIcon>
  );
}

IconEstimate.defaulProps = {
  color: 'base',
};

export default IconEstimate;
