import React from 'react';
import SVGIcon from './index';

const IconFilter = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2H2V4H3L8.5 12V18L11.5 17V12L17 4H18V2ZM14.4341 4H5.5659L6.93954 6H13.0605L14.4341 4Z"
      />
    </SVGIcon>
  );
};

IconFilter.defaultProps = {
  color: 'base',
};

export default React.memo(IconFilter);
