import React from 'react';
import { useOrderContext } from '../../index';
import PageHeader from '../../../../components/PageHeader';
import { getUrlString } from '../../../../helpers/getUrlString';
import { ACTIVE_ITEM_QUERY_KEY } from '../../../../services/_constants';
import { EOrdersTabs } from '../../../order-list/constants';
import { EContractsTabs } from '../../../contracts/constants';

/**
 * Блок заголовок
 * Используется при утверждении, при внесении изменений
 * @constructor
 */
export function PageHeaderBlock() {
  const { isOrderBasedOnContract, isDraftEditing, currentOrder, isOrderChange } = useOrderContext();

  if (isOrderChange) {
    return <PageHeader header={`Изменение заявки № ${currentOrder.human_friendly_id}`} backLinkText="К Заявкам" />;
  }

  if (isOrderBasedOnContract) {
    let header = `Создание заявки по контракту № ${currentOrder.human_friendly_id}`;
    if (isDraftEditing) {
      header = `Редактирование заявки по контракту № ${currentOrder.contract_hfid}`;
    }
    const backLink = isDraftEditing
      ? getUrlString(`order-list/${EOrdersTabs.DRAFT}`, { [ACTIVE_ITEM_QUERY_KEY]: currentOrder.id })
      : getUrlString(`contracts/${EContractsTabs.IN_WORK}`, { [ACTIVE_ITEM_QUERY_KEY]: currentOrder.id });
    const backLinkText = isDraftEditing ? 'К Заявке' : 'К Контракту';
    return <PageHeader header={header} backLinkText={backLinkText} backLink={backLink} />;
  }
  return null;
}
