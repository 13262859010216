import React, { useState, useEffect } from 'react';
import moment from 'moment';

interface IProps {
  time: string;
  delay: number;
  children: (diff: any) => React.ReactElement;
}

function TimerWrapper(props: IProps) {
  /**
   * Форматирование числового значения времени в строку
   * @param time
   * @return {string}
   */
  const formattedTime = (time) => {
    if (time < 0) {
      return '00';
    }
    return time < 10 ? `0${time}` : time;
  };

  /**
   * Создание объекта для построение оставшегося времени
   * @param time
   * @return {*}
   */
  const getTime = (time) => {
    // TODO: добавить сравнение локального времени со временим сервера
    const momentTime = moment(time || 0);
    const now = moment();
    const days = momentTime.diff(now, 'days');
    const hours = momentTime.diff(now, 'hours');
    const minutes = momentTime.diff(now, 'minutes');
    const seconds = momentTime.diff(now, 'seconds');

    return {
      days: formattedTime(days),
      hours: formattedTime(hours - days * 24),
      minutes: formattedTime(minutes - hours * 60),
      seconds: formattedTime(seconds - minutes * 60),
      daysIsOver: days < 1,
      hoursIsOver: hours < 1,
      minutesIsOver: minutes < 1,
      secondsIsOver: seconds < 1,
      totalMinutes: minutes,
    };
  };

  const [timerDiff, setTimerDiff] = useState(getTime(props.time));

  // Идетификатор таймера
  let timeInterval;

  /**
   * Остановка таймера
   */
  const stopTimer = () => {
    if (timeInterval) {
      clearInterval(timeInterval);
    }
  };

  /**
   * Событие на тик таймера
   */
  const handleTick = () => {
    const diff = getTime(props.time);
    setTimerDiff(getTime(props.time));
    if (diff.secondsIsOver) {
      stopTimer();
    }
  };

  /**
   * Запуск таймера
   */
  const startTimer = () => {
    timeInterval = setInterval(handleTick, props.delay);
  };

  useEffect(() => {
    startTimer();
    return () => {
      stopTimer();
    };
  });

  return props.children(timerDiff);
}

TimerWrapper.defaultProps = {
  delay: 1000,
};

export default TimerWrapper as any;
