import * as actionTypes from './actionTypes';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

const initialState = {
  items: [],
  activeItem: null,
  totalItems: 0,
  currentPage: 0,
  totalPages: 0,
  resultsPerPage: 20,
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    // Создаем список графиков
    case actionTypes.INIT_ITEMS:
      draft.items = action.payload.items;
      draft.totalItems = action.payload.totalItems;
      draft.currentPage = action.payload.currentPage;
      draft.totalPages = action.payload.totalPages;
      draft.resultsPerPage = action.payload.resultsPerPage;
      break;
    // Пополняем список графиков
    case actionTypes.LOAD_MORE_ITEMS:
      draft.items = draft.items.concat(action.payload.items);
      draft.totalItems = action.payload.totalItems;
      draft.currentPage = action.payload.currentPage;
      draft.totalPages = action.payload.totalPages;
      draft.resultsPerPage = action.payload.resultsPerPage;
      break;
    // Установка новой ставки для графика
    case actionTypes.SET_BID:
      // eslint-disable-next-line no-case-declarations
      const itemIndex = draft.items.findIndex((item) => item.id === action.payload.id);
      if (itemIndex > -1) draft.items[itemIndex].bids = [action.payload.bid];
      break;
    // Установить текущий график
    case actionTypes.SET_CURRENT:
      draft.activeItem = action.payload;
      break;
    // Сбросить редьюсер в первоначальное состояние
    case actionTypes.CLEAR_ITEMS:
      return initialState;
    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
