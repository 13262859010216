import classnames from 'classnames';
import React, { useCallback } from 'react';

import styles from './styles.module.scss';
import Button from '../Button';
import IconChevron from '../Icons/SVGIcons/IconChevron';
import { scrollTo } from '../../helpers/browserTools';
import { useScrollToTop } from '../../hooks/useScrollToTop';

const PER_PAGE_VARIANTS = [20, 60];

export type TPaginatorProps = {
  page: number;
  perPage?: number;
  total?: number;
  pages: number;
  margin?: number;
  range?: number;
  onClick: (number) => void;
  onPerPageClick?: (number) => void;
  onLoadMore?: (number) => void;
  useNewPagination?: boolean;
  disableScroll?: boolean;
  wrapperClassName?: string;
};

function Paginator(props: TPaginatorProps) {
  const {
    range = 4,
    page,
    total,
    perPage,
    pages,
    margin = 2,
    onClick,
    onPerPageClick,
    onLoadMore,
    useNewPagination,
    disableScroll,
    wrapperClassName,
  } = props;

  /* Рендер кнопки "наверх" для страниц с пагинацией */
  useScrollToTop(useNewPagination && pages > 1);

  const handlePageClick = useCallback(
    (page: number): void => {
      onClick(page);
      if (!disableScroll) {
        setTimeout(() => scrollTo(0, document.querySelector('#contentScroll')), 50);
      }
    },
    [disableScroll, onClick],
  );

  const handlePerPageClick = useCallback(
    (perPage: number): void => {
      onPerPageClick?.(perPage);
      if (!disableScroll) {
        setTimeout(() => scrollTo(0, document.querySelector('#contentScroll')), 50);
      }
    },
    [disableScroll, onPerPageClick],
  );

  const createPageElement = useCallback(
    (index, canSelect = true, label = null) => (
      <li
        onClick={() => handlePageClick(index)}
        className={canSelect && index === page ? styles.active : styles.simple}
      >
        {label || index}
      </li>
    ),
    [handlePageClick, page],
  );

  const createPageList = () => {
    const items: any = [];
    const selected = page;

    if (page > 1) {
      items.push(createPageElement(Math.max(1, page - 1), false, <IconChevron width="20" height="20" />));
    }

    if (pages <= range) {
      for (let index = 1; index <= pages; index++) {
        items.push(createPageElement(index));
      }
    } else {
      let leftSide = range / 2;
      let rightSide = range - leftSide;
      if (selected > pages - range / 2) {
        rightSide = pages - selected;
        leftSide = range - rightSide;
      } else if (selected < range / 2) {
        leftSide = selected;
        rightSide = range - leftSide;
      }

      let breakView: React.ReactNode;
      for (let index = 1; index <= pages; index++) {
        if (index <= margin) {
          items.push(createPageElement(index));
          continue;
        }
        if (index > pages - margin) {
          items.push(createPageElement(index));
          continue;
        }
        if (index >= selected - leftSide && index <= selected + rightSide) {
          items.push(createPageElement(index));
          continue;
        }
        if (items[items.length - 1] !== breakView) {
          breakView = <li className={styles.simple}>&hellip;</li>;

          items.push(breakView);
        }
      }
    }
    if (page < pages) {
      items.push(
        createPageElement(
          Math.min(pages, page + 1),
          false,
          <IconChevron width="20" height="20" className={styles.rotateIcon} />,
        ),
      );
    }

    return items;
  };

  if (useNewPagination) {
    return pages > 1 ? (
      <div className={classnames(styles.wrapper2, styles.wrapper, wrapperClassName)}>
        {page < pages && (
          <Button theme="secondary" size="medium" className="mr-4" onClick={() => onLoadMore?.(page + 1)}>
            Показать ещё
          </Button>
        )}
        <div className={classnames(styles.paginator2, styles.paginator)}>
          <ul className="flex">
            {createPageList().map((page, index) => (
              <React.Fragment key={index}>{page}</React.Fragment>
            ))}
          </ul>
          <div className="flex items-center mr-1">
            <span className="caption mr-1">Показывать по</span>
            {PER_PAGE_VARIANTS.map((variant) => (
              <div
                key={`per_page_${variant}`}
                className={classnames(styles.perPage, {
                  [styles.active]: perPage === variant,
                  [styles.disabled]: total! < variant,
                })}
                onClick={() => (total! < variant ? () => {} : handlePerPageClick?.(variant))}
              >
                {variant}
              </div>
            ))}
          </div>
        </div>
      </div>
    ) : null;
  }

  return pages > 1 ? (
    <div className={classnames(styles.wrapper, wrapperClassName)}>
      <ul className={styles.paginator}>
        {createPageList().map((page, index) => (
          <React.Fragment key={index}>{page}</React.Fragment>
        ))}
      </ul>
    </div>
  ) : null;
}

export default Paginator;
