import React from 'react';
import PlatformPartnersImg from '../../../assets/images/platform-partners-info.png';
import Button from '../../../components/Button';
import Modal from '../../../components/ModalCollections/Modal';

export default function PlatformPartnersModal({ onClose, onAccept }) {
  return (
    <Modal onClose={onClose} style={{ height: 'unset' }} isCloseOutOfModal>
      <div className={'ml-1 mt-1'}>
        <img src={PlatformPartnersImg} alt={'Партнёры платформы'} className={'width100p'} />

        <h4 className={'mt-5 mb-3 fs-20 leading-5 bold'}>
          Предлагаем стать партнёром компаний ДЛ-Транс и Деловые Линии
        </h4>

        <div className={'color-darker'}>
          Партнёрство позволит вам участвовать в закрытых торгах и выбирать заявки среди большего количества
          предложений.
        </div>

        <div className={'mt-11 flex content-center'}>
          <Button theme={'secondary'} type={'button'} size={'medium'} onClick={onClose} className={'pl-4 pr-4'}>
            Пропустить
          </Button>
          <Button theme={'primary'} type={'button'} size={'medium'} onClick={onAccept} className={'ml-4 pl-4 pr-4'}>
            Перейти в Партнёрство
          </Button>
        </div>
      </div>
    </Modal>
  );
}
