import * as actionTypes from './actionTypes';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

const initialState = {
  items: [],
  activeItem: null,
  totalItems: 0,
  currentPage: 0,
  totalPages: 0,
  resultsPerPage: 20,
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    // Инициализация списка перевозчиков
    case actionTypes.INIT_CARRIER_ITEMS:
      draft.items = action.payload.items;
      draft.totalItems = action.payload.totalItems;
      draft.currentPage = action.payload.currentPage;
      draft.totalPages = action.payload.totalPages;
      draft.resultsPerPage = action.payload.resultsPerPage;
      break;

    // Обновить список
    case actionTypes.UPDATE_CARRIER_ITEMS:
      draft.items = draft.items.concat(action.payload.items);
      draft.totalItems = action.payload.totalItems;
      draft.currentPage = action.payload.currentPage;
      draft.totalPages = action.payload.totalPages;
      draft.resultsPerPage = action.payload.resultsPerPage;
      break;

    // Очистка списка перевозчиков
    case actionTypes.CLEAR_CARRIER_ITEMS:
      return initialState;

    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
