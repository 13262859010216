import React from 'react';
import classNames from 'classnames';

const ALFA_INSURANCE_CONTRACT = '/files/insurance-contract.pdf';

const COMPLEX_RULES = '/files/complex-insurance-terms-co-tc.pdf';
const RULES_FOR_CARGO_OWNERS = '/files/insurance-terms-co.pdf';
const RULES_FOR_CO_DL_TRANS = '/files/insurance-terms-co-dl-trans.pdf';
const RULES_FOR_TC = '/files/insurance-terms-tc.pdf';
const LIABILITY_TC = '/files/insurance-terms-liability-tc.pdf';

function getLinkComponent(link, defaultText) {
  return ({ text = '', className = '', ...rest }) => (
    <a className={classNames('link', className)} href={link} target="_blank" rel="noopener noreferrer" {...rest}>
      {text || defaultText}
    </a>
  );
}

const INSURANCE_DOCS = {
  CompexRules: getLinkComponent(COMPLEX_RULES, 'Правила комплексного страхования грузов'),
  RulesForCo: getLinkComponent(RULES_FOR_CARGO_OWNERS, 'Полисные условия для грузовладельцев'),
  RulesForCoWithDlTrans: getLinkComponent(
    RULES_FOR_CO_DL_TRANS,
    'Полисные условия для перевозок, осуществляемых ООО «ДЛ-Транс»',
  ),
  RulesForTc: getLinkComponent(RULES_FOR_TC, 'Полисные условия'),
  LiabilityTC: getLinkComponent(LIABILITY_TC, 'Правила страхования ответственности'),

  AlfaInsuranceContract: getLinkComponent(ALFA_INSURANCE_CONTRACT, 'Договором страхования ответственности перевозчика'),
};
export default INSURANCE_DOCS;
