import { TTaxType } from '../store/company/formKeys';

/**
 * Получаем объект значений по компании (ГВ или НТК)
 * @param data
 * @returns {{bank: *, opf: *, manager: *, corrAccount: *, bik: *, okved: *, name: *, inn: *, checkingAccount: *, kpp: *, legalAddress: *}}
 */
interface IManager {
  phone: string;
  extension_number: string;
  email: string;
  full_name: string;
}

export interface ICompany {
  id: string;
  opf: string;
  name: string;
  inn: string;
  kpp: string;
  ogrn: string;
  okved: string;
  checkingAccount: string;
  bank: string;
  corrAccount: string;
  bik: string;
  legalAddress: {
    zip_code?: string;
    city?: string;
    street?: string;
    house_number?: string;
  };
  manager: IManager;
  rating: number | null;
  taxType: TTaxType;
  logoUrl: string | null;
}

export function getCompanyInfo(data: { id; props; personal_manager; rating; logo_url }): ICompany {
  return {
    id: data?.id,
    opf: data?.props?.opf.name,
    name: data?.props?.title,
    inn: data?.props?.inn,
    kpp: data?.props?.kpp,
    ogrn: data?.props?.ogrn,
    okved: data?.props?.okvds[0],
    checkingAccount: data?.props?.settlement_account,
    bank: data?.props?.bank_name,
    corrAccount: data?.props?.correspondent_account,
    bik: data?.props?.bik,
    legalAddress: data?.props?.legal_address,
    manager: data?.personal_manager,
    rating: data?.rating,
    taxType: data?.props?.tax_type,
    logoUrl: data?.logo_url,
  };
}
