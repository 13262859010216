export const INSURANCE_SUM_LIST = [
  { value: 50000000, label: '500 000 ₽' },
  { value: 100000000, label: '1 000 000 ₽' },
  { value: 500000000, label: '5 000 000 ₽' },
  { value: 1000000000, label: '10 000 000 ₽' },
];

export const TYPES = {
  ALLOW: 'available',
  REQUIRED: 'required',
  CARGO_COST: 'cargo_cost',
};
