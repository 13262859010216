import { useEffect, useRef } from 'react';

const useDidMount = (callback) => {
  const handler = useRef(callback);
  useEffect(() => {
    handler?.current?.();
  }, []);
};

export default useDidMount;
