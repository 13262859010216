import { TChat } from './models';

export const UPDATE_INVITED_ROOMS_STATE = 'chat.UPDATE_INVITED_ROOMS_STATE';
export const UPDATE_JOINED_ROOMS_STATE = 'chat.UPDATE_JOINED_ROOMS_STATE';
export const UPDATE_LEFT_ROOMS_STATE = 'chat.UPDATE_LEFT_ROOMS_STATE';
export const UPDATE_SYNC_SINCE = 'chat.UPDATE_SYNC_SINCE';
export const SET_CHATS = 'chat.SET_CHATS';
export const SET_CHATS_FOR_ENTITY_ID = 'chat.SET_CHATS_FOR_ENTITY_ID';

export interface ISetChatsForEntityIdAction {
  type: typeof SET_CHATS_FOR_ENTITY_ID;
  payload: TChat[];
}
