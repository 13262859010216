import classNames from 'classnames';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import {
  AUCTION_TYPES,
  AUCTION_TYPE_HELPER,
  TAX_TYPES,
  VISIBILITY_HELPER,
} from '../../containers/order-v2/blocks/AuctionBlock/constants';
import { useDeepMemoValue } from '../../hooks/useDeepMemo';
import {
  ALL_PEOPLE,
  DESCENDING_PRICE_AUCTION,
  EXCLUSIVE,
  OPEN_AUCTION,
  PARTNERS,
  TAX_BOTH,
  TAX_WITH,
  TAX_WITHOUT,
} from '../../store/order/auction/formKeys';

import Collapse from '../../components/Collapse';
import { Col, Row } from '../../components/Grid';
import { INSTANT } from '../../constants';

import { useFieldErrorShown, useFieldValue } from '../../components/FormikFieldsV3/hooks';
import { Field } from '../../components/FormikFieldsV3/Field';
import FormDropdown from '../../components/FormikFieldsV3/FormDropdown';
import FormCheckbox from '../../components/FormikFieldsV3/FormCheckbox';
import FormInput from '../../components/FormikFieldsV3/FormInput';
import FieldLabel from '../../components/FieldsCollection/FieldLabel';
import FormCounter from '../../components/FormikFieldsV3/FormCounter';
import FormPriceInput from '../../components/FormikFieldsV3/FormPriceInput';

import Toggle from '../../components/Toggle';
import {
  AUCTION_TYPE,
  BID_STEP,
  VISIBILITY,
  PRICE_TYPE,
  WITHOUT_PRICES,
  MAX_BID_WITHOUT_TAX,
  MIN_BID_WITHOUT_TAX,
  PRICE_WITHOUT_TAX,
  MAX_BID_WITH_VAT,
  PRICE_WITH_VAT,
  MIN_BID_WITH_VAT,
} from '../../containers/order-v2/blocks/AuctionBlock';
import { useGetPartners } from '../../hooks/useGetPartners';
import { declinationOfNumbers } from '../../helpers/declinationOfNumbers';
import { QUOTA_DISTRIBUTION } from '../../containers/order-v2/blocks/RoutesBlock';
import { QUOTA_FORM_FIELDS } from './quotesForm';
import { usePricesRecalculation } from '../../containers/order-v2/hooks/usePricesRecalculation';
import { useWithoutPricesEffect } from '../../containers/order-v2/hooks/useWithoutPricesEffect';
import { getPriceValue, getWithVat } from '../../helpers/getPrice';
import IconBlock from '../../components/PageElements/IconBlock/IconBlock';
import { IconInfo } from '../../components/Icons';

const OTHER_PARTNERS = 'other_partners';

export const EXECUTORS_OPTIONS_MAP = new Map()
  .set(PARTNERS, 'Все партнёры')
  .set(OTHER_PARTNERS, 'Все партнёры, кроме участников распределения')
  .set(EXCLUSIVE, 'Все партнёры, кроме участников распределения')
  .set(ALL_PEOPLE, 'Все исполнители');

function getVisibilityOptions(hasNoPartners, noOtherPartners) {
  return Object.entries(Object.fromEntries(EXECUTORS_OPTIONS_MAP))
    .filter((entry) => entry[0] !== EXCLUSIVE)
    .map((entry: any) => ({
      value: entry[0],
      label: entry[1],
      disabled: (hasNoPartners && entry[0] !== ALL_PEOPLE) || (noOtherPartners && entry[0] === OTHER_PARTNERS),
    }));
}
function getAuctionTypeOptions() {
  return Object.entries(Object.fromEntries(AUCTION_TYPES)).map((entry: any) => {
    return {
      value: entry[0],
      label: entry[1].text,
      icon: entry[1].icon,
      disabled: false,
    };
  });
}

export const QUOTES_AUCTION = 'auction';

export const AUCTION_FORM_FIELDS = {
  WITH_AUCTION: 'withAdditionalAuction',
  START_BID_WITHOUT_TAX: PRICE_WITHOUT_TAX,
  MAX_BID_WITHOUT_TAX: MAX_BID_WITHOUT_TAX,
  MIN_BID_WITHOUT_TAX: MIN_BID_WITHOUT_TAX,
  HOURS: 'hours',
  MINUTES: 'minutes',
};
export const getAuctionFormInitialValues = (auctionStage?) => {
  const taxType = !auctionStage
    ? TAX_WITH
    : auctionStage.price_with_vat && auctionStage?.price_without_vat
    ? TAX_BOTH
    : auctionStage.price_with_vat && !auctionStage?.price_without_vat
    ? TAX_WITH
    : TAX_WITHOUT;

  return {
    [VISIBILITY]: auctionStage?.visibility || OTHER_PARTNERS,
    [AUCTION_TYPE]: auctionStage?.auction_type || OPEN_AUCTION,
    [PRICE_TYPE]: taxType,
    [WITHOUT_PRICES]: auctionStage ? !auctionStage.price_with_vat && !auctionStage?.price_without_vat : false,
    [AUCTION_FORM_FIELDS.START_BID_WITHOUT_TAX]: auctionStage?.price_without_vat || null,
    [AUCTION_FORM_FIELDS.MAX_BID_WITHOUT_TAX]: auctionStage?.max_bid_without_vat || null,
    [AUCTION_FORM_FIELDS.MIN_BID_WITHOUT_TAX]: auctionStage?.min_bid_without_vat || null,
    [PRICE_WITH_VAT]: getPriceValue(getWithVat(auctionStage?.price_without_vat || null)),
    [MAX_BID_WITH_VAT]: getPriceValue(getWithVat(auctionStage?.max_bid_without_vat || null)),
    [MIN_BID_WITH_VAT]: getPriceValue(getWithVat(auctionStage?.min_bid_without_vat || null)),
    [BID_STEP]: auctionStage?.bid_step || 500,
    [AUCTION_FORM_FIELDS.HOURS]: auctionStage?.hours || 6,
    [AUCTION_FORM_FIELDS.MINUTES]: auctionStage?.minutes || 0,
  };
};
const Fields = (params) => {
  const { auctionFieldName = QUOTES_AUCTION } = params;

  const getFieldname = (fieldName) => {
    return `${auctionFieldName}.${fieldName}`;
  };

  const [visibility, setVisibility] = useFieldValue(getFieldname(VISIBILITY));
  const isAllPeopleAuction = visibility === ALL_PEOPLE;

  const [auctionType] = useFieldValue(getFieldname(AUCTION_TYPE));
  const [taxType] = useFieldValue(getFieldname(PRICE_TYPE));
  const isAuction = auctionType !== INSTANT;
  const isDescendingPriceAuction = auctionType === DESCENDING_PRICE_AUCTION;
  const [isAuctionWithoutPrices] = useFieldValue(getFieldname(WITHOUT_PRICES));
  const [hours] = useFieldValue(getFieldname(AUCTION_FORM_FIELDS.HOURS));
  const showHoursError = useFieldErrorShown(getFieldname(AUCTION_FORM_FIELDS.HOURS));
  const showMinutesError = useFieldErrorShown(getFieldname(AUCTION_FORM_FIELDS.MINUTES));

  const { partnerList } = useGetPartners();
  const hasNoPartners = !partnerList?.length;
  const [quotaDistribution] = useFieldValue(QUOTA_DISTRIBUTION);
  const noOtherPartners = useMemo(() => {
    if (!quotaDistribution.length || !taxType) return false;
    // среди партнеров НЕ осталось компаний с тем же типом налогообложения и
    // НЕ участвующих в распределении:
    return !partnerList.filter((partner) => {
      const correctTaxType = taxType === TAX_BOTH || partner.taxType === taxType;
      return (
        correctTaxType &&
        !quotaDistribution.find((item) => {
          return (item[QUOTA_FORM_FIELDS.COMPANY_ID] || item[QUOTA_FORM_FIELDS.BID_ID]) === partner.companyId;
        })
      );
    }).length;
  }, [quotaDistribution, taxType, partnerList]);
  useEffect(() => {
    if (noOtherPartners) {
      setVisibility(null);
    }
  }, [noOtherPartners, setVisibility]);

  const visibilityOptions = useDeepMemoValue(getVisibilityOptions(hasNoPartners, noOtherPartners));
  const auctionTypeOptions = useDeepMemoValue(getAuctionTypeOptions());

  const taxTypeOptions = Object.entries(Object.fromEntries(TAX_TYPES)).map((entry: any) => ({
    value: entry[0],
    label: entry[1],
  }));

  const {
    handleChangePriceWithTax,
    handleChangePriceWithoutTax,
    handleChangeMaxBidWithoutTax,
    handleChangeMaxBidWithTax,
    handleChangeMinBidWithoutTax,
    handleChangeMinBidWithTax,
    handleBlurMaxBidWithTax,
    handleBlurMinBidWithTax,
    handleBlurMaxBidWithoutTax,
    handleBlurMinBidWithoutTax,
    handleChangeBidStep,
    handleChangeAuctionType,
  } = usePricesRecalculation(null, auctionFieldName, true);

  useWithoutPricesEffect(undefined, auctionFieldName);

  const showMinBidTaxError = useFieldErrorShown(getFieldname(MIN_BID_WITH_VAT));
  const showMinBidNoTaxError = useFieldErrorShown(getFieldname(MIN_BID_WITHOUT_TAX));
  const showMaxBidTaxError = useFieldErrorShown(getFieldname(MAX_BID_WITH_VAT));
  const showMaxBidNoTaxError = useFieldErrorShown(getFieldname(MAX_BID_WITHOUT_TAX));
  const showStartBidTaxError = useFieldErrorShown(getFieldname(PRICE_WITH_VAT));
  const showStartBidNoTaxError = useFieldErrorShown(getFieldname(PRICE_WITHOUT_TAX));

  return (
    <>
      <Row span={20}>
        <Col part={12}>
          <Field
            name={getFieldname(VISIBILITY)}
            component={FormDropdown}
            options={visibilityOptions}
            label="Список участников"
            labelHelp={VISIBILITY_HELPER}
            placeholder=""
            isSearchable={false}
            cleanValue
            labelClassName="width100p mr-4"
            className="bg-color-white"
          />
        </Col>
        <Col part={8}>
          <Field
            name={getFieldname(AUCTION_TYPE)}
            component={FormDropdown}
            placeholder=""
            options={auctionTypeOptions}
            label="Тип торга"
            labelHelp={AUCTION_TYPE_HELPER}
            isSearchable={false}
            cleanValue
            labelClassName="width100p mr-4"
            className="bg-color-white"
            onChange={handleChangeAuctionType}
          />
        </Col>
      </Row>
      <Row span={20} className="mt-5">
        <Col part={8}>
          <Field
            name={getFieldname(PRICE_TYPE)}
            component={FormDropdown}
            options={taxTypeOptions}
            label="Видимость торга для перевозчиков"
            labelHelp="До торга будут допущены перевозчики с выбранным видом налогооблажения"
            isSearchable={false}
            cleanValue
            labelClassName="width100p mr-4"
            className="bg-color-white"
          />
        </Col>
      </Row>

      {isAuction && !isAllPeopleAuction && !isDescendingPriceAuction && (
        <Row span={20} className="mt-5">
          <Col part={8}>
            <Field
              name={getFieldname(WITHOUT_PRICES)}
              component={FormCheckbox}
              label="Не знаю стоимость, жду предложений от перевозчиков"
              labelHelp={
                <Fragment>
                  В этом случае вы не указываете начальную, минимальную и максимальную ставки, а исполнитель может
                  поставить любую ставку, без ограничений.
                  <br />
                  <br />
                  Доступно, только если торг проходит среди партнёров
                </Fragment>
              }
            />
          </Col>
        </Row>
      )}

      <Row span={20} className="mt-5">
        <Col part={24}>
          <IconBlock Icon={<IconInfo width={20} height={20} color={'primary'} />} className={'flex items-center'}>
            После ввода «Ставки с НДС» мы автоматически пересчитаем ее на «Без НДС» (20%) и также в обратную сторону.
            При конвертации копейки округлятся до целого числа.
          </IconBlock>
        </Col>
      </Row>

      {auctionType === INSTANT && (
        <>
          <Row span={20} className="mt-5">
            <Col part={8}>
              <Field
                name={getFieldname(PRICE_WITH_VAT)}
                component={FormPriceInput}
                type="number"
                additional="₽"
                label={`Стоимость заявки (с НДС)`}
                placeholder={'Укажите желаемое значение'}
                labelClassName={classNames('width100p', showStartBidTaxError && 'mb-2')}
                className="bg-color-white"
                onChangeCallback={handleChangePriceWithTax}
              />
              <span className="caption">За одну перевозку</span>
            </Col>
          </Row>

          <Row span={20} className="mt-5">
            <Col part={8}>
              <Field
                name={getFieldname(AUCTION_FORM_FIELDS.START_BID_WITHOUT_TAX)}
                component={FormPriceInput}
                type="number"
                additional="₽"
                label={`Стоимость заявки (без НДС)`}
                placeholder={'Укажите желаемое значение'}
                labelClassName={classNames('width100p', showStartBidNoTaxError && 'mb-2')}
                className="bg-color-white"
                onChangeCallback={handleChangePriceWithoutTax}
              />
              <span className="caption">За одну перевозку</span>
            </Col>
          </Row>
        </>
      )}

      {auctionType !== INSTANT && !isAuctionWithoutPrices && (
        <>
          <Row span={20} className="mt-5">
            <Col part={8}>
              <Field
                name={getFieldname(PRICE_WITH_VAT)}
                component={FormPriceInput}
                type="number"
                additional="₽"
                label="Начальная ставка (с НДС)"
                placeholder={'Укажите желаемое значение'}
                labelClassName={classNames('width100p', showStartBidTaxError && 'mb-2')}
                className="bg-color-white"
                onChangeCallback={handleChangePriceWithTax}
              />
              <span className="caption">За одну перевозку</span>
            </Col>
            <Col part={8}>
              <Field
                name={getFieldname(MIN_BID_WITH_VAT)}
                component={FormPriceInput}
                type="number"
                additional="₽"
                label="Минимальная ставка (с НДС)"
                placeholder={'Укажите желаемое значение'}
                labelClassName={classNames('width100p', showMinBidTaxError && 'mb-2')}
                className="bg-color-white"
                onChangeCallback={handleChangeMinBidWithTax}
                onBlur={handleBlurMinBidWithTax}
              />
              <span className="caption">За одну перевозку</span>
            </Col>
            {auctionType !== DESCENDING_PRICE_AUCTION && (
              <Col part={8}>
                <Field
                  name={getFieldname(MAX_BID_WITH_VAT)}
                  component={FormPriceInput}
                  type="number"
                  additional="₽"
                  label="Максимальная ставка (с НДС)"
                  placeholder={'Укажите желаемое значение'}
                  labelClassName={classNames('width100p', showMaxBidTaxError && 'mb-2')}
                  className="bg-color-white"
                  onChangeCallback={handleChangeMaxBidWithTax}
                  onBlur={handleBlurMaxBidWithTax}
                />
                <span className="caption">За одну перевозку</span>
              </Col>
            )}
          </Row>

          <Row span={20} className="mt-5">
            <Col part={8}>
              <Field
                name={getFieldname(AUCTION_FORM_FIELDS.START_BID_WITHOUT_TAX)}
                component={FormPriceInput}
                type="number"
                additional="₽"
                label="Начальная ставка (без НДС)"
                placeholder={'Укажите желаемое значение'}
                labelClassName={classNames('width100p', showStartBidNoTaxError && 'mb-2')}
                className="bg-color-white"
                onChangeCallback={handleChangePriceWithoutTax}
              />
              <span className="caption">За одну перевозку</span>
            </Col>
            <Col part={8}>
              <Field
                name={getFieldname(AUCTION_FORM_FIELDS.MIN_BID_WITHOUT_TAX)}
                component={FormPriceInput}
                type="number"
                additional="₽"
                label="Минимальная ставка (без НДС)"
                placeholder={'Укажите желаемое значение'}
                labelClassName={classNames('width100p', showMinBidNoTaxError && 'mb-2')}
                className="bg-color-white"
                onChangeCallback={handleChangeMinBidWithoutTax}
                onBlur={handleBlurMinBidWithoutTax}
              />
              <span className="caption">За одну перевозку</span>
            </Col>
            {auctionType !== DESCENDING_PRICE_AUCTION && (
              <Col part={8}>
                <Field
                  name={getFieldname(AUCTION_FORM_FIELDS.MAX_BID_WITHOUT_TAX)}
                  component={FormPriceInput}
                  type="number"
                  additional="₽"
                  label="Максимальная ставка (без НДС)"
                  placeholder={'Укажите желаемое значение'}
                  labelClassName={classNames('width100p', showMaxBidNoTaxError && 'mb-2')}
                  className="bg-color-white"
                  onChangeCallback={handleChangeMaxBidWithoutTax}
                  onBlur={handleBlurMaxBidWithoutTax}
                />
                <span className="caption">За одну перевозку</span>
              </Col>
            )}
          </Row>
        </>
      )}

      <Row span={20} className="mt-5">
        {!isAuctionWithoutPrices && auctionType !== INSTANT && (
          <Col part={6}>
            <FieldLabel title="Шаг ставки" />
            <Field
              name={getFieldname(BID_STEP)}
              component={FormCounter}
              min={100}
              max={1000}
              step={100}
              // additional="₽"
              readOnly
              labelClassName="width100p"
              className="bg-color-white"
              onChange={handleChangeBidStep}
            />
          </Col>
        )}
        <Col part={18}>
          <FieldLabel title="Окончание аукциона" />
          <Row span={4}>
            <Col part={6}>
              <Field
                name={getFieldname(AUCTION_FORM_FIELDS.HOURS)}
                component={FormInput}
                type="number"
                min={0}
                labelDisable={true}
                additional="ч"
                labelClassName={classNames('width100p', showHoursError && 'mb-2')}
                className="bg-color-white"
              />
            </Col>
            <Col part={6}>
              <Field
                name={getFieldname(AUCTION_FORM_FIELDS.MINUTES)}
                component={FormInput}
                type="number"
                min={0}
                max={59}
                labelDisable={true}
                additional="мин"
                labelClassName={classNames('width100p', showMinutesError && 'mb-2')}
                className="bg-color-white"
              />
            </Col>
          </Row>
          <span className="caption">
            Не позже, чем за {hours || 0} {declinationOfNumbers(hours, ['час', 'часа', 'часов'])} до подачи в первую
            точку
          </span>
        </Col>
      </Row>
    </>
  );
};

const AuctionForm = (params) => {
  const { auctionFieldName, className } = params;
  const [withAuction, setWithAuction] = useFieldValue(AUCTION_FORM_FIELDS.WITH_AUCTION);

  const collapseClickHandler = useCallback(
    (event) => {
      setWithAuction(!withAuction);
    },
    [setWithAuction, withAuction],
  );

  return (
    <div className={classNames(className)}>
      <Collapse open={withAuction} onClick={() => {}}>
        <Collapse.CustomTrigger>
          <div
            className={classNames('flex cursor-pointer', styles.formCard, {
              [styles.openedCollapseTrigger]: withAuction,
            })}
          >
            <Toggle theme="classic" disabled={false} checked={withAuction || false} onChange={collapseClickHandler} />

            <div className="ml-2">
              <div className={styles.collapseHeader}>
                Добавить правила торга по заявкам, не закрытым выбранными исполнителями
              </div>
              <div className={styles.secondaryText}>
                Заявки, которые не будут взяты исполнителями попадут в торг и к ним будут применяться заданные вами
                правила
              </div>
            </div>
          </div>
        </Collapse.CustomTrigger>
        <Collapse.Content>
          <div className={styles.formCard}>
            <Fields auctionFieldName={auctionFieldName} />
          </div>
        </Collapse.Content>
      </Collapse>
    </div>
  );
};

export default AuctionForm;
