import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const POSITIONS = {
  'bottom-right': styles.bottomRight,
  'bottom-left': styles.bottomLeft,
};
const POSITIONS_LIST = Object.keys(POSITIONS);
const DEFAULT_POSITION = POSITIONS_LIST[1];

function Dropdown({
  className = '',
  dropdownClassName = '',
  dropdownMaxHeight = '220px',
  position = DEFAULT_POSITION,
  triggerContent,
  triggerClassName = '',
  scrollable = true,
  children,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  const triggerClickHandler = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  const positionClassName = POSITIONS_LIST.includes(position) ? POSITIONS[position] : POSITIONS[DEFAULT_POSITION];

  return (
    <div ref={dropdownRef} className={classnames(styles.dropdown, className)}>
      <div onClick={triggerClickHandler} className={triggerClassName}>
        {triggerContent}
      </div>

      {isDropdownOpen && (
        <div
          className={classnames(styles.dropdownContainer, positionClassName, dropdownClassName)}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <div
            className={classnames(styles.optionsContainer, {
              [styles.scrollable]: scrollable,
              'custom-scrollbar': scrollable,
            })}
            style={{ maxHeight: dropdownMaxHeight }}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
