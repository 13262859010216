import React from 'react';
import { createSelector, defaultMemoize } from 'reselect';
import { getFormMeta, getFormSyncErrors, getFormValues } from 'redux-form';
import _get from 'lodash/get';
import isValid from 'date-fns/isValid';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import _memoize from 'lodash/memoize';
import _pick from 'lodash/pick';

import moment from 'moment';
import * as queryKeys from './queryKeys';
import * as formFields from './formFields';
import * as formKeys from './formKeys';
import * as dBrainKeys from './dBrainKeys';
import * as statusKeys from './statusKeys';
import * as bodyTypeOptions from './bodyTypeOptions';
import {
  getLabelForTrailerType,
  getMappedCarTypes,
  getMappedTrailerTypes,
  getTypeUrl,
} from '../../helpers/carTypesMapping';
import getMappedLiftingCapacity from '../../helpers/getMappedLiftingCapacity';
import { getVehicleType } from '../../components/DataListCollections/_helpers/getVehicleType';
import { getVehicleLoadingType } from '../../components/DataListCollections/_helpers/getVehicleLoadingType';
import filterByMask from '../../helpers/filterByMask';
import passportFormat, { biometricPassportFormat } from '../../helpers/passportFormat';
import { CATEGORY_1, CATEGORY_2, DATE_FORMAT, DESC, DF_DATE_FORMAT, DF_DATE_TIME_PICKER_FORMAT } from '../../constants';
import { getQuery } from '../page/selectors';
import * as DocumentSelectors from '../documents/selectors';
import * as companySelectors from '../company/selectors';
import getTonnageVolume from '../../helpers/getTonnageVolume';
import { RENTED_TYPES } from './formKeys';
import { RESOURCE_FORM_ACTION } from './constants';
import {
  Countries_,
  Documents,
  Documents_,
  COUNTRY_ID,
} from '../../containers/resources-v2/forms/DriverForm/constants';

const getAllDriverValues = getFormValues(formFields.DRIVER_FORM_NAME);
const carFormSyncErrorsSelectors = getFormSyncErrors(formFields.CAR_FORM_NAME);
const carFormMetaSelectors = getFormMeta(formFields.CAR_FORM_NAME);

const trailerFormSyncErrorsSelectors = getFormSyncErrors(formFields.TRAILER_FORM_NAME);
const trailerFormMetaSelectors = getFormMeta(formFields.TRAILER_FORM_NAME);

const resourcesFormSyncErrorsSelectors = getFormSyncErrors('ShippingSelectResources');
const resourcesFormMetaSelectors = getFormMeta('ShippingSelectResources');

/**
 * Все значения формы автомашин
 */
export const getAllCarValues = getFormValues(formFields.CAR_FORM_NAME);

/**
 * Все значения формы прицепов
 */
export const getAllTrailerValues = getFormValues(formFields.TRAILER_FORM_NAME);

/**
 * Все значения формы назначения ресурсов
 */
export const getAllResourceFormValues = getFormValues('ShippingSelectResources');

/**
 * Получение всех значений, мета информации и ошибок формы прицепов
 */
export const trailerFormValues = defaultMemoize((state) => {
  return [getAllTrailerValues(state), trailerFormSyncErrorsSelectors(state), trailerFormMetaSelectors(state)];
});

/**
 * Получение всех значений, мета информации и ошибок формы автомобилей
 */
export const carFormValues = defaultMemoize((state) => {
  return [getAllCarValues(state), carFormSyncErrorsSelectors(state), carFormMetaSelectors(state)];
});

/**
 * Получение всех значений, мета информации и ошибок формы назнаяения ресурсов
 */
export const resourcesFormValues = defaultMemoize((state) => {
  return [getAllResourceFormValues(state), resourcesFormSyncErrorsSelectors(state), resourcesFormMetaSelectors(state)];
});

/**
 * Получение водителей в форме назначения ресурсов
 */
export const getDriversResourcesForm = defaultMemoize((state) => {
  return getAllResourceFormValues(state)?.driver;
});

/**
 * Валидация строки поиска из url
 */
export const getIsValidQuery = createSelector(
  (state) => getQuery(state),
  (query) => {
    /**
     * Обернул в try/catch что бы не было ошибок вида
     * Cannot read property 'find' of undefined
     */
    try {
      const type = query[queryKeys.TYPE];
      const isValidType = [formKeys.DRIVERS, formKeys.CARS, formKeys.TRAILERS].includes(type);
      const isValidStatus = query[queryKeys.STATUS]
        ? query[queryKeys.STATUS].every((status) =>
            queryKeys.RESOURCES_FILTERS[type].some(({ value }) => value === status),
          )
        : true;
      const isValidSort = query[queryKeys.SORT]
        ? Boolean(queryKeys.RESOURCES_SORT_VALUES[type].find(({ value }) => value === query[queryKeys.SORT]))
        : false;

      return isValidType && isValidStatus && isValidSort;
    } catch (error) {
      return false;
    }
  },
);

/**
 * Получение объекта со статусов инициализации по каждому ресурсу
 */
export const getWasResourceInitialized = createSelector(
  (state) => _get(state, `resources.${[formKeys.DRIVERS]}.wasInitialized`),
  (state) => _get(state, `resources.${[formKeys.CARS]}.wasInitialized`),
  (state) => _get(state, `resources.${[formKeys.TRAILERS]}.wasInitialized`),
  (driversWasInitialized, carsWasInitialized, trailersWasInitialized) => ({
    [formKeys.DRIVERS]: driversWasInitialized,
    [formKeys.CARS]: carsWasInitialized,
    [formKeys.TRAILERS]: trailersWasInitialized,
  }),
);

/**
 * Получение объекта со значениями о законченности поиска по ресурсам
 */
export const getIsMoreResourcesItems = createSelector(
  (state) => _getIsMoreDriversItems(state),
  (state) => _getIsMoreCarsItems(state),
  (state) => _getIsMoreTrailersItems(state),
  (driversIsComplete, carsIsComplete, trailersIsComplete) => ({
    [formKeys.DRIVERS]: driversIsComplete,
    [formKeys.CARS]: carsIsComplete,
    [formKeys.TRAILERS]: trailersIsComplete,
  }),
);

/**
 * Получение объекта с общим количеством найденных ресурсов
 */
export const getTotalFindResourcesItems = createSelector(
  (state) => _get(state, `resources.${[formKeys.DRIVERS]}.totalItems`),
  (state) => _get(state, `resources.${[formKeys.CARS]}.totalItems`),
  (state) => _get(state, `resources.${[formKeys.TRAILERS]}.totalItems`),
  (driversTotalItems, carsTotalItems, trailersTotalItems) => ({
    [formKeys.DRIVERS]: driversTotalItems,
    [formKeys.CARS]: carsTotalItems,
    [formKeys.TRAILERS]: trailersTotalItems,
  }),
);

/**]
 * Получение значения о закончености поиска по водителям
 */
const _getIsMoreDriversItems = createSelector(
  (state) => getDrivers(state),
  (state) => _get(state, `resources.${[formKeys.DRIVERS]}.totalItems`),
  (drivers, totalItems) => drivers.length !== totalItems,
);

/**
 * Получение значения о закончености поиска по автомашинам
 */
const _getIsMoreCarsItems = createSelector(
  (state) => getCars(state),
  (state) => _get(state, `resources.${[formKeys.CARS]}.totalItems`),
  (cars, totalItems) => cars.length !== totalItems,
);

/**
 * Получение значения о закончености поиска по прицепам
 */
const _getIsMoreTrailersItems = createSelector(
  (state) => getTrailers(state),
  (state) => _get(state, `resources.${[formKeys.TRAILERS]}.totalItems`),
  (trailers, totalItems) => trailers.length !== totalItems,
);

/**
 * Получение объекта с последними запрошенными страницами для прокрутки
 */
export const getAllCurrentPages = createSelector(
  (state) => _get(state, `resources.${[formKeys.DRIVERS]}.currentPage`),
  (state) => _get(state, `resources.${[formKeys.CARS]}.currentPage`),
  (state) => _get(state, `resources.${[formKeys.TRAILERS]}.currentPage`),
  (driversPage, carsPage, trailersPage) => {
    return {
      [formKeys.DRIVERS]: driversPage,
      [formKeys.CARS]: carsPage,
      [formKeys.TRAILERS]: trailersPage,
    };
  },
);

/**
 * Получение типа растентовки
 */
function _vehicleTypeOptions(option) {
  if (!option) return option;

  const full = _get(option, 'full');
  const lateral = _get(option, 'lateral');
  const upper = _get(option, 'upper');
  const rear = _get(option, 'rear');

  if (full || [rear, lateral, upper].every(Boolean)) {
    return bodyTypeOptions.FULL_COVER_TRANSLATION || null;
  }
  return (
    [
      rear ? bodyTypeOptions.BACK_COVER_TRANSLATION : null,
      lateral ? bodyTypeOptions.SIDE_COVER_TRANSLATION : null,
      upper ? bodyTypeOptions.TOP_COVER_TRANSLATION : null,
    ]
      .filter(Boolean)
      // С заглавной буквы только первое значение
      .reduce((acc, value, index) => {
        acc.push(index === 0 ? value : value.toLowerCase());
        return acc;
      }, [])
      .join(', ')
  );
}

/**
 * Получение размеров грузового отсека
 */
const _getDimensionsCargoHold = defaultMemoize((length, width, height) => {
  if (length && width && height) return `${length} × ${width} × ${height} м`;
});

/**
 * Получение даты выдачи паспорта, кода подразделения и кем выдан
 */
const _getIssued = defaultMemoize((whoIssued, dateIssued, dateExpired, subdivisionCode) => {
  const formattedIssuedDate = moment(dateIssued).format(DATE_FORMAT);
  const formattedExpiredDate = dateExpired ? moment(dateExpired).format(DATE_FORMAT) : null;
  const date = [formattedIssuedDate, formattedExpiredDate].filter(Boolean).join(' - ');
  return [subdivisionCode, whoIssued, date].filter(Boolean).join(', ');
});

/**
 * Получение кол-во ремней
 */
const _getBelts = defaultMemoize((belts) => {
  return belts ? `${belts} шт.` : belts;
});

/**
 * Получение адреса водителя
 */
const _getSuggestAddress = defaultMemoize((city, street, house, apartment) => {
  let currCity = city.split(', ').reverse().join(', ');
  return `${currCity}${street ? `, ${street}` : ''}${house ? `, ${house}` : ''}${
    apartment ? `, кв. ${apartment}` : ''
  }`;
});

/**
 * Получение категорий
 */
const _getDriverLicenseCategories = defaultMemoize((categories) => {
  if (categories?.length > 0) {
    return `категории ${categories.join(', ')}`;
  }
});

/**
 * Получение периода дат
 */
const _getPeriodOfDates = defaultMemoize((dateFirst, dateSecond) => {
  if (dateFirst && dateSecond)
    return `с ${moment(dateFirst).format(DATE_FORMAT)} по ${moment(dateSecond).format(DATE_FORMAT)}`;
});

/**
 * Получение типа автомашины (грузовик или седельный тягач)
 */
const _getCarType = (kind) => {
  switch (getMappedCarTypes(kind).vehicleType) {
    case formKeys.LORRY:
      return 'Грузовик';
    case formKeys.TRUCK:
      return 'Седельный тягач';
    default:
      return '';
  }
};

/**
 * Добавление маски для телефона
 */
const _getPhoneMask = defaultMemoize((phone) =>
  phone ? `+7 ${filterByMask(phone.slice(-10), '(999) 999-99-99')}` : '',
);

/**
 * Проверка на доступность сущности
 * к редактировании
 * @param {Boolean} status
 */
export function canEditEntity(status) {
  switch (status) {
    case statusKeys.REJECTED:
    case statusKeys.REVIEW:
      return false;
    case statusKeys.NONE:
    case statusKeys.NEED_FIX_BY_USER:
    case statusKeys.NOT_APPROVED:
    case statusKeys.APPROVED:
    case statusKeys.ACCESS_SUSPENDED:
    default:
      return true;
  }
}

/**
 * Получение помаппенного активного водителя
 */
export const getDriver = createSelector(
  (state) => _get(state, 'resources.drivers.activeItem'),
  (driver) => getMappedDriver(driver),
);

/**
 * Получение помаппенного активного прицепа
 */
export const getTrailer = createSelector(
  (state) => _get(state, 'resources.trailers.activeItem'),
  (trailer) => getMappedTrailer(trailer),
);

/**
 * Получение помаппенной активной машины
 */
export const getCar = createSelector(
  (state) => _get(state, 'resources.cars.activeItem'),
  (car) => getMappedCar(car),
);

/**
 *
 * Маппинг водителя
 * @param driver: object
 * @returns {null}
 */
export function getMappedDriver(driver) {
  if (!driver) return null;

  const id = _get(driver, 'id');
  const documentType = _get(driver, 'driver_secret_info.doc.type');
  const passport = _get(driver, 'driver_secret_info.doc.number');
  const nationality = _get(driver, 'driver_secret_info.common.country_id');
  const typeOfDocument = [
    Documents_.get(Documents[documentType] ?? Documents.passport),
    documentType === Documents.passport
      ? passportFormat(passport, nationality)
      : documentType === Documents.biometric_passport
      ? biometricPassportFormat(passport, nationality)
      : passport,
  ].join(', ');
  const passportIssued = _getIssued(
    _get(driver, 'driver_secret_info.doc.who_issued'),
    _get(driver, 'driver_secret_info.doc.issued_at'),
    _get(driver, 'driver_secret_info.doc.expired_at'),
    _get(driver, 'driver_secret_info.doc.subdivision_code'),
  );
  const addressManual = _get(driver, 'driver_secret_info.address.manually_enter');
  const contractNumber = driver?.contract?.number;
  const contractIssuedAt = driver?.contract?.issued_at ? moment(driver?.contract?.issued_at).format(DATE_FORMAT) : null;
  const contractExpiredAt = driver?.contract?.expired_at
    ? moment(driver?.contract?.expired_at).format(DATE_FORMAT)
    : null;

  const fields = [
    {
      label: 'Дата рождения',
      value: moment(_get(driver, 'driver_secret_info.common.birth_date')).format(DATE_FORMAT),
    },
    {
      label: 'Гражданство',
      value: _get(driver, 'driver_secret_info.common.country.name'),
    },
    {
      label: 'Документ, удостоверяющий личность',
      value: typeOfDocument,
    },
    {
      label: 'Код подразделения, кем и когда выдан',
      value: passportIssued,
    },
    {
      label: 'Адрес регистрации',
      value: !!addressManual
        ? addressManual
        : _getSuggestAddress(
            _get(driver, 'driver_secret_info.address.city'),
            _get(driver, 'driver_secret_info.address.street'),
            _get(driver, 'driver_secret_info.address.house_number'),
            _get(driver, 'driver_secret_info.address.room'),
          ),
    },
    {
      label: 'Водительское удостоверение',
      value: [
        _get(driver, 'driver_secret_info.driver_license.number'),
        _getPeriodOfDates(
          _get(driver, 'driver_secret_info.driver_license.issued_at'),
          _get(driver, 'driver_secret_info.driver_license.expired_at'),
        ),
        _get(driver, 'driver_secret_info.driver_license.who_issued'),
        _getDriverLicenseCategories(_get(driver, 'driver_secret_info.driver_license.categories')),
      ]
        .filter(Boolean)
        .join(', '),
    },
    /*
    {
      label: 'Мед. книжка',
      value: _get(driver, 'driver_secret_info.have_medical_book') ? 'Да' : 'Нет'
    },
    */
    {
      label: 'ИНН',
      value: _get(driver, 'driver_secret_info.inn'),
    },
    {
      label: (
        <div>
          Номер трудового договора или ГПХ,
          <br />
          срок действия
        </div>
      ),
      value: contractNumber && contractIssuedAt && (
        <div>
          {`${contractNumber}`} <br />
          {`с ${contractIssuedAt}${contractExpiredAt ? ' до ' + contractExpiredAt : ''}`}
        </div>
      ),
    },
    {
      label: 'Доп. информация',
      value: _get(driver, 'driver_secret_info.have_medical_book') ? 'Наличие мед. книжки' : null,
    },
    {
      label: 'Телефон',
      value: _get(driver, 'driver_secret_info.phones').map(_getPhoneMask).join(', '),
    },
  ];

  return {
    id,
    createdAt: _get(driver, 'created_at'),
    listId: id,
    title: _get(driver, 'full_name'),
    humanFriendlyId: _get(driver, 'human_friendly_id'),
    status: _get(driver, 'status.code_name'),
    statusMessage: _getStatusTranslation(_get(driver, 'status.code_name')),
    statusComment: _get(driver, 'status.comment'),
    isDeliveryAllowed: _get(driver, 'delivery_allowed'),
    statusUpdatedAt: _get(driver, 'status_updated_at'),
    freezing: _get(driver, 'freezing', []),
    prohibitedCategories: _get(driver, 'prohibited_categories', []),
    canEdit: canEditEntity(_get(driver, 'status.code_name')),
    editUrl: `/resources/${formKeys.DRIVER}/form/${id}/edit`,
    fields: fields.filter((field) => Boolean(field.value)),
    attorneys: _get(driver, 'attorneys') || {},
    phone: _getFieldValueByLabel(fields, 'Телефон'),
    isNeedPassportSubdivisionCode: nationality === 1 && !_get(driver, 'driver_secret_info.doc.subdivision_code'),
    passport: {
      number: passportFormat(passport, nationality),
      issued: passportIssued,
    },
    isRusCitizenship: _get(driver, 'driver_secret_info.common.country_id') === 1,
    _original: driver,
    validations: driver?.validation?.entries || [],
    validationDate:
      driver?.validation?.updated_at && format(parseISO(driver?.validation?.updated_at), DF_DATE_TIME_PICKER_FORMAT),
  };
}

/**
 * Маппинг автомашины
 * @param car: object
 * @returns {*}
 */
export function getMappedCar(car) {
  if (!car) return null;

  const id = car?.id;
  const kind = car?.kind;
  const typeUrl = getTypeUrl(kind);
  const type = getMappedCarTypes(kind);
  const loadingTypesObj = _pick(car?.loading_option, [
    formKeys.FULL_COVER,
    formKeys.SIDE_COVER,
    formKeys.BACK_COVER,
    formKeys.TOP_COVER,
  ]);
  const loadingTypes = Object.keys(loadingTypesObj).filter((key) => Boolean(loadingTypesObj[key]));
  const fields = [
    {
      label: 'Тип транспортного средства',
      value: _getCarType(car?.kind),
    },
    {
      label: 'Тип прицепа',
      value: type?.bodyType?.label,
    },
    {
      label: 'VIN',
      value: car?.car_additional_info.vin,
    },
    {
      label: 'Номер двигателя',
      value: car?.car_additional_info.engine_number,
    },
    {
      label: 'Номер шасси',
      value: car?.car_additional_info.chassis_number,
    },
    {
      label: 'Номер кузова',
      value: car?.car_additional_info.body_number,
    },
    type[formFields.VEHICLE_TYPE] !== formKeys.LORRY
      ? {
          label: 'Габариты ТС',
          value: _getDimensionsCargoHold(
            car?.dimensions_vehicle?.length,
            car?.dimensions_vehicle?.width,
            car?.dimensions_vehicle?.height,
          ),
        }
      : {},
    // добавляем поля прицепа, если тип автомашины - Грузовик
    ...(type[formFields.VEHICLE_TYPE] === formKeys.LORRY ? _getTrailerFields(car) : []),
    {
      label: 'Телефон в автомашине',
      value: _getPhoneMask(car?.car_phone),
    },
    {
      label: 'IMEI',
      value: car?.tracker_id,
    },
    {
      label: 'Доп. информация',
      value: _getAdditionalInfo(car),
    },
    {
      label: 'Право владения',
      value: _getOwningType(car?.owning_type),
    },
    {
      label: 'Свидетельство о регистрации',
      value: _getOwningInfo(car),
    },
    RENTED_TYPES.includes(car?.owning_type) && {
      label: 'Номер договора',
      value: car?.owning_doc_number,
    },
    RENTED_TYPES.includes(car?.owning_type) && {
      label: 'Период действия',
      value: _getOwningDocPeriod(car?.owning_doc_from, car?.owning_doc_till),
    },
  ];

  return {
    id,
    kind,
    createdAt: car?.created_at,
    listId: id,
    title: car?.car_number,
    subtitle: car?.brand,
    sts: car?.number_sts,
    humanFriendlyId: car?.human_friendly_id,
    type,
    typeUrl,
    lifting: {
      tonnage: car?.lifting_capacity?.tonnage,
      volume: car?.lifting_capacity?.volume,
    },
    transportBodyType: type?.bodyType?.value ? getVehicleType({ bodyTypes: [type?.bodyType?.value] }) : undefined,
    transportLoadingTypes: loadingTypes.length > 0 ? getVehicleLoadingType({ loadingTypes }) : undefined,
    status: car?.status.code_name,
    statusMessage: _getStatusTranslation(car?.status?.code_name),
    statusComment: car?.status.comment,
    statusUpdatedAt: car?.status_updated_at,
    freezing: car?.freezing,
    prohibitedCategories: car?.prohibited_categories,
    canEdit: canEditEntity(car?.status.code_name),
    editUrl: `/resources/${formKeys.CAR}/form/${id}/edit`,
    createBasedOnUrl: `/resources/${formKeys.CAR}/form/${id}/create_based_on`,
    fields: fields.filter((field) => Boolean(field.value)),
    coverType: _getFieldValueByLabel(fields, 'Растентовка'),
    dimensionsCargoHold: _getFieldValueByLabel(fields, 'Размеры грузового отсека'),
    validations: car?.validation?.entries || [],
    validationDate:
      car?.validation?.updated_at && format(parseISO(car?.validation?.updated_at), DF_DATE_TIME_PICKER_FORMAT),
  };
}

/**
 * Маппинг списка прицепов
 * @param trailer: object
 * @returns {*}
 */
export function getMappedTrailer(trailer) {
  if (!trailer) return null;

  const id = _get(trailer, 'id');
  const kind = _get(trailer, 'kind');
  const typeUrl = getTypeUrl(kind);
  const loadingTypesObj = _pick(trailer?.loading_option, [
    formKeys.FULL_COVER,
    formKeys.SIDE_COVER,
    formKeys.BACK_COVER,
    formKeys.TOP_COVER,
  ]);
  const loadingTypes = Object.keys(loadingTypesObj).filter((key) => Boolean(loadingTypesObj[key]));

  const type = getMappedTrailerTypes(kind);

  const fields = [
    {
      label: 'Тип прицепа',
      value: getLabelForTrailerType(kind),
    },
    {
      label: 'VIN',
      value: _get(trailer, 'trailer_additional_info.vin'),
    },
    {
      label: 'Номер шасси',
      value: _get(trailer, 'trailer_additional_info.chassis_number'),
    },
    ..._getTrailerFields(trailer),
    {
      label: 'Доп. информация',
      value: _getAdditionalInfo(trailer),
    },
    {
      label: 'Право владения',
      value: _getOwningType(trailer?.owning_type),
    },
    {
      label: 'Свидетельство о регистрации',
      value: _getOwningInfo(trailer),
    },
    {
      label: 'ИНН владельца по СТС',
      value: trailer?.owner_inn,
    },
    RENTED_TYPES.includes(trailer?.owning_type) && {
      label: 'Номер договора',
      value: trailer?.owning_doc_number,
    },
    RENTED_TYPES.includes(trailer?.owning_type) && {
      label: 'Период действия',
      value: _getOwningDocPeriod(trailer?.owning_doc_from, trailer?.owning_doc_till),
    },
  ];

  return {
    id,
    kind,
    createdAt: _get(trailer, 'created_at'),
    listId: id,
    title: _get(trailer, 'trailer_number'),
    subtitle: _get(trailer, 'brand'),
    sts: _get(trailer, 'number_sts'),
    humanFriendlyId: _get(trailer, 'human_friendly_id'),
    type: type,
    lifting: {
      tonnage: _get(trailer, 'lifting_capacity.tonnage'),
      volume: _get(trailer, 'lifting_capacity.volume'),
    },
    transportBodyType: type?.bodyType ? getVehicleType({ bodyTypes: [type?.bodyType] }) : undefined,
    transportLoadingTypes: loadingTypes.length > 0 ? getVehicleLoadingType({ loadingTypes }) : undefined,
    maxLiftingCapacity: trailer?.max_lifting_capacity,
    status: _get(trailer, 'status.code_name'),
    statusMessage: _getStatusTranslation(_get(trailer, 'status.code_name')),
    statusComment: _get(trailer, 'status.comment'),
    statusUpdatedAt: _get(trailer, 'status_updated_at'),
    freezing: _get(trailer, 'freezing', []),
    prohibitedCategories: _get(trailer, 'prohibited_categories', []),
    canEdit: canEditEntity(_get(trailer, 'status.code_name')),
    editUrl: `/resources/${formKeys.TRAILER}/form/${id}/edit`,
    createBasedOnUrl: `/resources/${formKeys.TRAILER}/form/${id}/create_based_on`,
    typeUrl,
    fields: fields.filter((field) => Boolean(field.value)),
    coverType: _getFieldValueByLabel(fields, 'Растентовка'),
    dimensionsCargoHold: _getFieldValueByLabel(fields, 'Размеры грузового отсека'),
    validations: trailer?.validation?.entries || [],
    validationDate:
      trailer?.validation?.updated_at && format(parseISO(trailer?.validation?.updated_at), DF_DATE_TIME_PICKER_FORMAT),
  };
}

function _getTrailerFields(entity) {
  const itemTonnage = _get(entity, 'lifting_capacity.tonnage');
  const itemVolume = _get(entity, 'lifting_capacity.volume');
  return [
    {
      label: 'Тоннаж / Объём',
      value: getTonnageVolume(itemTonnage, itemVolume),
    },
    {
      label: 'Грузоподъёмность',
      value: _get(entity, 'max_lifting_capacity') ? `${_get(entity, 'max_lifting_capacity')} т` : null,
    },
    {
      label: 'Растентовка',
      value: _vehicleTypeOptions(_get(entity, 'loading_option')),
    },
    {
      label: 'Размеры грузового отсека',
      value: _getDimensionsCargoHold(
        _get(entity, 'dimensions_cargo_hold.length'),
        _get(entity, 'dimensions_cargo_hold.width'),
        _get(entity, 'dimensions_cargo_hold.height'),
      ),
    },
    {
      label: 'Габариты ТС',
      value: _getDimensionsCargoHold(
        entity?.dimensions_vehicle?.length,
        entity?.dimensions_vehicle?.width,
        entity?.dimensions_vehicle?.height,
      ),
    },
    {
      label: 'Количество ремней',
      value: _getBelts(_get(entity, 'loading_option.number_of_belts')),
    },
  ];
}

function _getAdditionalInfo(entity) {
  // TODO добавить всплывающие подсказки
  const infoArray = [];

  if (_get(entity, 'loading_option.removable_upper_beam')) {
    infoArray.push('Съёмная верхняя балка');
  }

  if (_get(entity, 'loading_option.removable_side_racks')) {
    infoArray.push('Съёмные боковые стойки');
  }

  if (_get(entity, 'loading_option.rigid_board')) {
    infoArray.push('Жёсткий борт');
  }

  if (_get(entity, 'loading_option.hydro_board')) {
    infoArray.push('Гидроборт');
  }

  if (_get(entity, 'loading_option.manipulator')) {
    infoArray.push('Манипулятор');
  }

  if (_get(entity, 'additional.has_temperature_check')) {
    infoArray.push('Наличие температурного чека');
  }

  if (
    _get(entity, 'additional.is_disinfected') &&
    _get(entity, 'additional.disinfected_from') &&
    _get(entity, 'additional.disinfected_until')
  ) {
    const dateFrom = moment(_get(entity, 'additional.disinfected_from')).format(DATE_FORMAT);
    const dateUntil = moment(_get(entity, 'additional.disinfected_until')).format(DATE_FORMAT);
    infoArray.push(`Наличие санитарной обработки: ${dateFrom} – ${dateUntil}`);
  }

  return infoArray.length > 0
    ? infoArray.map((el) => (
        <React.Fragment key={el}>
          {el}
          <br />
        </React.Fragment>
      ))
    : null;
}

function _getOwningInfo(entity) {
  const infoArray = [];

  if (entity?.number_sts) {
    infoArray.push(entity.number_sts);
  }

  if (entity?.owner_sts) {
    infoArray.push(entity.owner_sts);
  }

  if (entity?.owner_inn || entity?.owner_kpp) {
    infoArray.push(
      `${entity?.owner_inn ? `ИНН: ${entity?.owner_inn}` : ''}${
        entity?.owner_kpp ? `, КПП: ${entity?.owner_kpp}` : ''
      }`,
    );
  }

  return infoArray.length > 0
    ? infoArray.map((el) => (
        <React.Fragment key={el}>
          {el}
          <br />
        </React.Fragment>
      ))
    : null;
}

function _getStatusTranslation(status) {
  switch (status) {
    case statusKeys.REJECTED:
      return 'Заблокирован';
    case statusKeys.REVIEW:
      return 'На проверке';
    case statusKeys.NEED_FIX_BY_USER:
      return 'На доработке';
    case statusKeys.NOT_APPROVED:
      return 'На заполнении';
    case statusKeys.ACCESS_SUSPENDED:
      return 'Приостановлен';
    case statusKeys.APPROVED:
      return 'Допущен';
    default:
      return '';
  }
}

function _getOwningType(ownership) {
  switch (ownership) {
    case formKeys.OWN:
      return 'Собственность';
    case formKeys.RENT:
      return 'Аренда';
    case formKeys.JOINT_OWNERSHIP:
      return 'Общая собственность супругов';
    case formKeys.LEASING:
      return 'Лизинг';
    case formKeys.GRATUITOUS_USE:
      return 'Договор ссуды';
    default:
      return '';
  }
}

function _getOwningDocPeriod(from, till) {
  const docFrom = from && isValid(new Date(from)) ? format(new Date(from), DF_DATE_FORMAT) : null;
  const docTill = till && isValid(new Date(till)) ? format(new Date(till), DF_DATE_FORMAT) : null;
  return [docFrom, docTill].filter(Boolean).join(' - ');
}

/**
 * Получение списка водителей
 */
export const getDrivers = defaultMemoize((state) => _get(state, `resources.${formKeys.DRIVERS}.items`, []));

/**
 * Получение списка автомобилей
 */
export const getCars = defaultMemoize((state) => _get(state, `resources.${formKeys.CARS}.items`, []));

/**
 * Получение списка прицепов
 */
export const getTrailers = defaultMemoize((state) => _get(state, `resources.${formKeys.TRAILERS}.items`, []));

/**
 * Получить текущую улицу из формы
 */
export const getDriverFromStreet = defaultMemoize((state) => getAllDriverValues(state));

/**
 * Получение ссылки на добавление ресурса
 */
export const getNewResourceUrl = createSelector(
  (state) => getQuery(state),
  (query) => {
    const type = _get(query, queryKeys.TYPE);

    switch (type) {
      case formKeys.CARS:
        return _getNewResourceUrl(formKeys.CAR);
      case formKeys.TRAILERS:
        return _getNewResourceUrl(formKeys.TRAILER);
      case formKeys.DRIVERS:
      default:
        return _getNewResourceUrl(formKeys.DRIVER);
    }
  },
);

/**
 * Получение выбранного объекта сортировки
 */
export const getSortActiveValue = createSelector(
  (state) => getQuery(state),
  (query) => {
    /**
     * Обернул в try/catch что бы не было ошибок вида
     * Cannot read property 'find' of undefined
     */
    try {
      const type = _get(query, queryKeys.TYPE);
      const sort = _get(query, queryKeys.SORT);
      const selected = queryKeys.RESOURCES_SORT_VALUES[type].find(({ value }) => value === sort);

      if (selected) return selected.label;

      return queryKeys.LAST_CHANGE_OBJ.label;
    } catch (error) {
      return queryKeys.LAST_CHANGE_OBJ.label;
    }
  },
);

const _getNewResourceUrl = defaultMemoize((type) => `/resources/${type}/main/`);

/**
 * Получить текущую информацию о
 * выбранной стране из формы
 */
export const getFormDriverCountryId = createSelector(
  (state) => getAllDriverValues(state),
  (data) => {
    return data?.[formFields.COUNTRY]?.value;
  },
);

export const getFormDriverDocumentType = createSelector(
  (state) => getAllDriverValues(state),
  (data) => {
    return data?.[formFields.DOCUMENT_TYPE]?.value;
  },
);

/**
 * Получить текущий город из формы
 */
export const getDriverFormCity = createSelector(
  (state) => getAllDriverValues(state),
  (data) => (data && data[formFields.ADDRESS_REG_CITY] ? data[formFields.ADDRESS_REG_CITY] : null),
);
/**
 * Получить текущую улицу из формы
 */
export const getDriverFormStreet = createSelector(
  (state) => getAllDriverValues(state),
  (data) => (data && data[formFields.ADDRESS_REG_STREET] ? data[formFields.ADDRESS_REG_STREET] : null),
);
export const getCurrentDriverId = createSelector(
  (state) => _get(state, 'resources.drivers.activeItem.id', null),
  (driverId) => driverId,
);
/**
 * Генерация объекта о водителе
 * в нормализованном виде
 */
export const getDriverInfoByForm = (values) => {
  const countryId = values[formFields.COUNTRY].value;

  return {
    full_name: [values[formFields.LAST_NAME], values[formFields.FIRST_NAME], values[formFields.MIDDLE_NAME]]
      .filter(Boolean)
      .join(' '),
    contract: {
      number: values[formFields.CONTRACT_NUMBER],
      issued_at: values[formFields.CONTRACT_ISSUED_AT],
      expired_at: values[formFields.CONTRACT_EXPIRED_AT],
    },
    driver_secret_info: {
      common: {
        country_id: countryId,
        first_name: values[formFields.FIRST_NAME],
        last_name: values[formFields.LAST_NAME],
        middle_name: values[formFields.MIDDLE_NAME] || null,
        birth_date: values[formFields.BIRTHDAY],
      },
      doc: {
        type: values[formFields.DOCUMENT_TYPE]?.value,
        number: values[formFields.PASSPORT],
        who_issued:
          countryId === 1 ? values[formFields.PASSPORT_WHO_ISSUED]?.value : values[formFields.PASSPORT_WHO_ISSUED],
        subdivision_code: values[formFields.PASSPORT_SUBDIVISION_CODE] || null,
        issued_at: values[formFields.PASSPORT_ISSUED_AT],
        expired_at:
          values[formFields.DOCUMENT_TYPE]?.value === Documents.refugee_certificate
            ? values[formFields.PASSPORT_EXPIRED_AT]
            : null,
      },
      address: {
        country_id: countryId,
        city: _get(values[formFields.ADDRESS_REG_CITY], 'label'),
        street: _get(values[formFields.ADDRESS_REG_STREET], 'label') || null,
        house_number: _get(values[formFields.ADDRESS_REG_HOUSE], 'label') || null,
        zip_code: values[formFields.INDEX],
        room: values[formFields.ADDRESS_REG_ROOM] || null,
        manually_enter: values[formFields.ADDRESS_REG_MANUAL] || null,
      },
      driver_license: {
        number: values[formFields.DRIVER_LICENSE],
        who_issued: values[formFields.DRIVER_LICENSE_WHO_ISSUED] || null,
        issued_at: values[formFields.DRIVER_LICENSE_ISSUED_AT],
        expired_at: values[formFields.DRIVER_LICENSE_EXPIRED_AT],
        categories: values[formFields.DRIVER_LICENSE_CATEGORIES],
      },
      have_medical_book: !!values[formFields.HAS_MEDICAL_LICENSE],
      inn: values[formFields.INN] || null,
      phones: values[formFields.PHONE],
    },
  };
};

/**
 * Получение сниппета для водителя
 */
export const getDriverSnippet = createSelector(
  (state) => getDriverName(state),
  (data) => {
    return data;
    // return [data?.lastName, data?.firstName, data?.middleName].filter(Boolean).join(' ');
  },
);

/**
 * Генерация объекта для формы
 */
export const getDriverInitialState = createSelector(
  (state) => _get(state, 'resources.drivers.activeItem', null),
  (state) => _get(state, 'documents.driver.documents'),
  (driver, documents) => {
    if (driver) {
      const country = {
        value: _get(driver, 'driver_secret_info.common.country_id'),
        label: Countries_.get(_get(driver, 'driver_secret_info.common.country_id')),
      };
      const passportIssuedAt = format(parseISO(driver?.driver_secret_info?.doc?.issued_at), DF_DATE_FORMAT);
      const passportExpiredAt = driver?.driver_secret_info?.doc?.expired_at
        ? format(parseISO(driver?.driver_secret_info?.doc?.expired_at), DF_DATE_FORMAT)
        : '';
      const driverLicenseIssuedAt = format(
        parseISO(driver?.driver_secret_info.driver_license.issued_at),
        DF_DATE_FORMAT,
      );
      const driverLicenseExpiredAt = format(
        parseISO(driver?.driver_secret_info.driver_license.expired_at),
        DF_DATE_FORMAT,
      );
      const contractIssuedAt = driver?.contract?.issued_at
        ? format(parseISO(driver?.contract?.issued_at), DF_DATE_FORMAT)
        : '';
      const contractExpiredAt = driver?.contract?.expired_at
        ? format(parseISO(driver?.contract?.expired_at), DF_DATE_FORMAT)
        : '';
      const docTypeValue = _get(driver, 'driver_secret_info.doc.type');
      const docType = {
        value: docTypeValue ?? Documents.passport,
        label: Documents_.get(docTypeValue ?? Documents.passport),
      };
      const whoIssued = _get(driver, 'driver_secret_info.doc.who_issued');
      return {
        [formFields.ID]: _get(driver, 'id'),
        [formFields.STATUS]: _get(driver, 'status'),
        [formFields.LAST_NAME]: _get(driver, 'driver_secret_info.common.last_name'),
        [formFields.FIRST_NAME]: _get(driver, 'driver_secret_info.common.first_name'),
        [formFields.MIDDLE_NAME]: _get(driver, 'driver_secret_info.common.middle_name') || '',
        [formFields.BIRTHDAY]: moment(_get(driver, 'driver_secret_info.common.birth_date')).format(DATE_FORMAT),
        [formFields.COUNTRY]: country,
        [formFields.DOCUMENT_TYPE]: docType,
        [formFields.PASSPORT]: _get(driver, 'driver_secret_info.doc.number'),
        [formFields.PASSPORT_SUBDIVISION_CODE]: _get(driver, 'driver_secret_info.doc.subdivision_code') || '',
        [formFields.PASSPORT_WHO_ISSUED]: country.value === 1 ? { label: whoIssued, value: whoIssued } : whoIssued,
        [formFields.PASSPORT_ISSUED_AT]: passportIssuedAt,
        [formFields.PASSPORT_EXPIRED_AT]: passportExpiredAt,
        [formFields.ADDRESS_REG_CITY]: {
          label: _get(driver, 'driver_secret_info.address.city'),
          value: _get(driver, 'driver_secret_info.address.city'),
        },
        [formFields.ADDRESS_REG_STREET]: {
          label: _get(driver, 'driver_secret_info.address.street') || '',
          value: _get(driver, 'driver_secret_info.address.street') || '',
        },
        [formFields.ADDRESS_REG_HOUSE]: {
          label: _get(driver, 'driver_secret_info.address.house_number') || '',
          value: _get(driver, 'driver_secret_info.address.house_number') || '',
        },
        [formFields.ADDRESS_REG_ROOM]: _get(driver, 'driver_secret_info.address.room') || '',
        [formFields.ADDRESS_REG_MANUAL]: _get(driver, 'driver_secret_info.address.manually_enter'),
        [formFields.IS_MANUAL_ADDRESS_REG]: !!_get(driver, 'driver_secret_info.address.manually_enter'),
        [formFields.INDEX]: _get(driver, 'driver_secret_info.address.zip_code'),
        [formFields.DRIVER_LICENSE]: _get(driver, 'driver_secret_info.driver_license.number'),
        [formFields.DRIVER_LICENSE_WHO_ISSUED]: _get(driver, 'driver_secret_info.driver_license.who_issued'),
        [formFields.DRIVER_LICENSE_ISSUED_AT]: driverLicenseIssuedAt,
        [formFields.DRIVER_LICENSE_EXPIRED_AT]: driverLicenseExpiredAt,
        [formFields.DRIVER_LICENSE_CATEGORIES]: driver?.driver_secret_info?.driver_license?.categories ?? [],
        [formFields.HAS_MEDICAL_LICENSE]: _get(driver, 'driver_secret_info.have_medical_book'),
        [formFields.PHONE]: _get(driver, 'driver_secret_info.phones', []),
        [formFields.INN]: _get(driver, 'driver_secret_info.inn'),
        [formFields.CONTRACT_NUMBER]: driver?.contract?.number || '',
        [formFields.CONTRACT_ISSUED_AT]: contractIssuedAt || '',
        [formFields.CONTRACT_EXPIRED_AT]: contractExpiredAt || '',
        [formFields.CONTRACT_FILES]: [],
        [formFields.RECOGNIZABLE_DOCUMENTS_FILES]: [],
      };
    } else {
      return {
        [formFields.COUNTRY]: { value: COUNTRY_ID.RUS, label: Countries_.get(COUNTRY_ID.RUS) },
        [formFields.DOCUMENT_TYPE]: { value: Documents.passport, label: Documents_.get(Documents.passport) },
        [formFields.PASSPORT]: '',
        [formFields.PASSPORT_SUBDIVISION_CODE]: '',
        [formFields.PASSPORT_WHO_ISSUED]: '',
        [formFields.PASSPORT_ISSUED_AT]: '',
        [formFields.PASSPORT_EXPIRED_AT]: '',
        [formFields.LAST_NAME]: '',
        [formFields.FIRST_NAME]: '',
        [formFields.MIDDLE_NAME]: '',
        [formFields.BIRTHDAY]: '',
        [formFields.ADDRESS_REG_CITY]: null,
        [formFields.ADDRESS_REG_STREET]: null,
        [formFields.ADDRESS_REG_HOUSE]: null,
        [formFields.ADDRESS_REG_ROOM]: '',
        [formFields.ADDRESS_REG_MANUAL]: '',
        [formFields.IS_MANUAL_ADDRESS_REG]: false,
        [formFields.INDEX]: null,
        [formFields.DRIVER_LICENSE]: '',
        [formFields.DRIVER_LICENSE_WHO_ISSUED]: '',
        [formFields.DRIVER_LICENSE_ISSUED_AT]: '',
        [formFields.DRIVER_LICENSE_EXPIRED_AT]: '',
        [formFields.DRIVER_LICENSE_CATEGORIES]: [],
        [formFields.INN]: '',
        [formFields.CONTRACT_NUMBER]: '',
        [formFields.CONTRACT_ISSUED_AT]: '',
        [formFields.CONTRACT_EXPIRED_AT]: '',
        [formFields.CONTRACT_FILES]: [],
        [formFields.HAS_MEDICAL_LICENSE]: false,
        [formFields.PHONE]: [''],
        [formFields.RECOGNIZABLE_DOCUMENTS_FILES]: [],
      };
    }
  },
);

/**
 * Получение статуса водителя
 */
export const getDriverStatus = defaultMemoize((state) =>
  _get(state, `resources.driver.status`, {
    code_name: statusKeys.NONE,
    comment: '',
  }),
);

/**
 * Получение статуса водителя
 */
export const getCarStatus = defaultMemoize((state) =>
  _get(state, `resources.car.status`, {
    code_name: statusKeys.NONE,
    comment: '',
  }),
);

/**
 * Получение статуса прицепа
 */
export const getTrailerStatus = defaultMemoize((state) =>
  _get(state, 'resources.trailer.status', {
    code_name: statusKeys.NONE,
    comment: '',
  }),
);

/**
 * Получение полного имени водителя
 */
export const getDriverName = defaultMemoize((state) => _get(state, 'resources.drivers.activeItem.full_name'));

/**
 * Поля формы водителя не доступны для редактирования
 */
export const isDriverFormDisabled = createSelector(
  (state) => _get(state, 'resources.driver.status'),
  (status) => !canEditEntity(status),
);

/**
 * Получение даты выдачи прав водителя
 */
export const getDriverLicenceIssuedAt = createSelector(
  (state) => getAllDriverValues(state),
  (data) =>
    data && data[formFields.DRIVER_LICENSE_ISSUED_AT]
      ? moment(data[formFields.DRIVER_LICENSE_ISSUED_AT], DATE_FORMAT)
      : null,
);

/**
 * Получить номер автомашины
 */
export const getCarNumber = defaultMemoize((state) => _get(state, 'resources.car.car_number', ''));

/**
 * Получение сниппета для автомашины
 */
export const getCarSnippet = createSelector(
  (state) => getAllCarValues(state),
  (data) => data?.number,
);

/**
 * Получение сниппета для прицепа
 */
export const getTrailerSnippet = createSelector(
  (state) => getAllTrailerValues(state),
  (data) => data?.number,
);

export const getTrailerNumber = defaultMemoize((state) => _get(state, 'resources.trailer.trailer_number', ''));

/**
 * Получение даты окончания действия прав водителя
 */
export const getDriverLicenceExpiredAt = createSelector(
  (state) => getAllDriverValues(state),
  (data) =>
    data && data[formFields.DRIVER_LICENSE_EXPIRED_AT]
      ? moment(data[formFields.DRIVER_LICENSE_EXPIRED_AT], DATE_FORMAT)
      : null,
);

/**
 * Получение даты окончания действия прав водителя
 */
export const getPassportIssuedAt = createSelector(
  (state) => getAllDriverValues(state),
  (data) =>
    data && data[formFields.PASSPORT_ISSUED_AT] ? moment(data[formFields.PASSPORT_ISSUED_AT], DATE_FORMAT) : null,
);

/**
 * Получение даты окончания действия прав водителя
 */
export const getPassportExpiredAt = createSelector(
  (state) => getAllDriverValues(state),
  (data) =>
    data && data[formFields.PASSPORT_EXPIRED_AT] ? moment(data[formFields.PASSPORT_EXPIRED_AT], DATE_FORMAT) : null,
);

/**
 * Начальные значения формы
 */
export const getCarInitialState = createSelector(
  (state) => _get(state, 'resources.cars.activeItem'),
  (state) => _get(state, 'resources.availableLifting'),
  (state) => companySelectors.getCompanyTitle(state),
  (state) => DocumentSelectors.typeDocumentsMap(state, 'car'),
  (state, action) => action,
  (car, liftings, companyTitle, carTypeDocumentsMap, action) => {
    if (car && action === RESOURCE_FORM_ACTION.EDIT) {
      const ownershipFiles = carTypeDocumentsMap.filter((item) => item.typeId === queryKeys.DOC_ID_CAR_OWNERSHIP);
      const docFrom = _get(car, 'owning_doc_from')
        ? format(new Date(_get(car, 'owning_doc_from')), DF_DATE_FORMAT)
        : null;

      const docTill = _get(car, 'owning_doc_till')
        ? format(new Date(_get(car, 'owning_doc_till')), DF_DATE_FORMAT)
        : null;

      const mappedCarTypes = getMappedCarTypes(car?.kind);

      return {
        [formFields.ID]: _get(car, 'id'),
        [formFields.VEHICLE_TYPE]: mappedCarTypes?.[formFields.VEHICLE_TYPE],
        [formFields.BODY_TYPE]: mappedCarTypes?.[formFields.BODY_TYPE]?.value,
        [formFields.BRAND]: {
          value: _get(car, 'brand'),
          label: _get(car, 'brand'),
        },
        [formFields.NUMBER]: _get(car, 'car_number'),
        [formFields.VIN_NUMBER]: _get(car, 'car_additional_info.vin'),
        [formFields.IS_NO_VIN]: !_get(car, 'car_additional_info.vin'),
        [formFields.ENGINE_NUMBER]: _get(car, 'car_additional_info.engine_number'),
        [formFields.EXTRA_NUMBER_TYPE]: !!_get(car, 'car_additional_info.vin')
          ? formKeys.EXTRA_NUMBER_TYPE.BODY_NUMBER
          : !!_get(car, 'car_additional_info.body_number')
          ? formKeys.EXTRA_NUMBER_TYPE.BODY_NUMBER
          : formKeys.EXTRA_NUMBER_TYPE.CHASSIS_NUMBER,
        [formFields.BODY_NUMBER]: _get(car, 'car_additional_info.body_number'),
        [formFields.CHASSIS_NUMBER]: _get(car, 'car_additional_info.chassis_number'),
        [formFields.WITHOUT_BODY_NUMBER]: Boolean(_get(car, 'car_additional_info.chassis_number')),
        [formFields.TONNAGE_VOLUME]: _getTonnageVolumeById(liftings, _get(car, 'lifting_capacity_id')),
        [formFields.MAX_LIFTING]: _get(car, 'max_lifting_capacity'),
        [formFields.LENGTH]: _get(car, 'dimensions_cargo_hold.length'),
        [formFields.WIDTH]: _get(car, 'dimensions_cargo_hold.width'),
        [formFields.HEIGHT]: _get(car, 'dimensions_cargo_hold.height'),
        [formFields.VEHICLE_LENGTH]: _get(car, 'dimensions_vehicle.length') ?? '',
        [formFields.VEHICLE_WIDTH]: _get(car, 'dimensions_vehicle.width') ?? '',
        [formFields.VEHICLE_HEIGHT]: _get(car, 'dimensions_vehicle.height') ?? '',
        [formFields.OWNERSHIP]: _get(car, 'owning_type'),
        [formFields.CAR_PHONE]: _get(car, 'car_phone') ?? '',
        [formFields.COVER_TYPE]: _getMappedLoadingTypes(_get(car, 'loading_option')),
        [formFields.BELTS]: _get(car, 'loading_option.number_of_belts') || 0,
        [formFields.REMOVABLE_UPPER_BEAM]: _get(car, 'loading_option.removable_upper_beam'),
        [formFields.REMOVABLE_SIDE_RACKS]: _get(car, 'loading_option.removable_side_racks'),
        [formFields.RIGID_BOARD]: _get(car, 'loading_option.rigid_board'),
        [formFields.MANIPULATOR]: _get(car, 'loading_option.manipulator'),
        [formFields.HYDRO_BOARD]: _get(car, 'loading_option.hydro_board'),
        [formFields.DISINFECTED]: _get(car, 'additional.is_disinfected'),
        [formFields.DISINFECTED_FROM]: _get(car, 'additional.disinfected_from')
          ? moment(_get(car, 'additional.disinfected_from')).format(DATE_FORMAT)
          : null,
        [formFields.DISINFECTED_UNTIL]: _get(car, 'additional.disinfected_until')
          ? moment(_get(car, 'additional.disinfected_until')).format(DATE_FORMAT)
          : null,
        [formFields.TEMPERATURE_CHECK]: _get(car, 'additional.has_temperature_check'),
        [formFields.NUMBER_STS]: _get(car, 'number_sts') ?? '',
        [formFields.OWNER_STS]: _get(car, 'owner_sts') ?? '',
        [formFields.OWNER_INN]: _get(car, 'owner_inn') ?? '',
        [formFields.OWNER_KPP]: {
          value: _get(car, 'owner_kpp'),
          label: _get(car, 'owner_kpp'),
        },
        [formFields.OWNING_DOC_NUMBER]: _get(car, 'owning_doc_number'),
        [formFields.OWNING_DOC_FROM]: docFrom,
        [formFields.OWNING_DOC_TILL]: docTill,
        [formFields._OWNER_TYPE]: !car?.owner_inn ? formKeys.OWNER_TYPE.INDIVIDUAL : formKeys.OWNER_TYPE.COMPANY,
        [formFields.RECOGNIZABLE_DOCUMENTS_FILES]: [],
        [formFields.OWNERSHIP_FILES]: ownershipFiles?.[0]?.files ?? [],
        [formFields.IMEI]: _get(car, 'tracker_id'),
      };
    } else if (car && action === RESOURCE_FORM_ACTION.CREATE_BASED_ON) {
      const mappedCarTypes = getMappedCarTypes(car?.kind);
      return {
        [formFields.IS_NO_VIN]: false,
        [formFields.VIN_NUMBER]: '',
        [formFields.NUMBER]: '',
        [formFields.VEHICLE_TYPE]: mappedCarTypes?.[formFields.VEHICLE_TYPE],
        [formFields.BODY_TYPE]: mappedCarTypes?.[formFields.BODY_TYPE]?.value,
        [formFields.EXTRA_NUMBER_TYPE]: formKeys.EXTRA_NUMBER_TYPE.BODY_NUMBER,
        [formFields.COVER_TYPE]: _getMappedLoadingTypes(_get(car, 'loading_option')),
        [formFields.TONNAGE_VOLUME]: _getTonnageVolumeById(liftings, _get(car, 'lifting_capacity_id')),
        [formFields.MAX_LIFTING]: _get(car, 'max_lifting_capacity'),
        [formFields.LENGTH]: _get(car, 'dimensions_cargo_hold.length'),
        [formFields.WIDTH]: _get(car, 'dimensions_cargo_hold.width'),
        [formFields.HEIGHT]: _get(car, 'dimensions_cargo_hold.height'),
        [formFields.VEHICLE_LENGTH]: _get(car, 'dimensions_vehicle.length') ?? '',
        [formFields.VEHICLE_WIDTH]: _get(car, 'dimensions_vehicle.width') ?? '',
        [formFields.VEHICLE_HEIGHT]: _get(car, 'dimensions_vehicle.height') ?? '',
        [formFields.BELTS]: _get(car, 'loading_option.number_of_belts') || 0,
        [formFields.REMOVABLE_UPPER_BEAM]: _get(car, 'loading_option.removable_upper_beam'),
        [formFields.REMOVABLE_SIDE_RACKS]: _get(car, 'loading_option.removable_side_racks'),
        [formFields.RIGID_BOARD]: _get(car, 'loading_option.rigid_board'),
        [formFields.MANIPULATOR]: _get(car, 'loading_option.manipulator'),
        [formFields.HYDRO_BOARD]: _get(car, 'loading_option.hydro_board'),
        [formFields.OWNERSHIP]: _get(car, 'owning_type'),
        [formFields.OWNING_DOC_NUMBER]: '',
        [formFields.OWNING_DOC_FROM]: '',
        [formFields.OWNING_DOC_TILL]: '',
        [formFields._OWNER_TYPE]: formKeys.OWNER_TYPE.COMPANY,
        [formFields.NUMBER_STS]: '',
        [formFields.OWNER_STS]: _get(car, 'owning_type') === formKeys.OWN ? companyTitle : '',
        [formFields.OWNER_INN]: '',
        [formFields.OWNER_KPP]: '',
        [formFields.RECOGNIZABLE_DOCUMENTS_FILES]: [],
        [formFields.OWNERSHIP_FILES]: [],
        [formFields.IMEI]: '',
      };
    } else {
      const loadOptions = bodyTypeOptions.loadOptions[bodyTypeOptions.AWNING_OBJ.value].reduce((acc, item) => {
        acc[item.value] = false;
        return acc;
      }, {});
      return {
        [formFields.VEHICLE_TYPE]: formKeys.LORRY,
        [formFields.NUMBER]: '',
        [formFields.VIN_NUMBER]: '',
        [formFields.BRAND]: null,
        [formFields.TONNAGE_VOLUME]: null,
        [formFields.MAX_LIFTING]: '',
        [formFields.IS_NO_VIN]: false,
        [formFields.BODY_TYPE]: bodyTypeOptions.AWNING_OBJ.value,
        [formFields.EXTRA_NUMBER_TYPE]: formKeys.EXTRA_NUMBER_TYPE.BODY_NUMBER,
        [formFields.LENGTH]: '',
        [formFields.WIDTH]: '',
        [formFields.HEIGHT]: '',
        [formFields.BELTS]: 0,
        [formFields.COVER_TYPE]: null,
        [formFields.OWNERSHIP]: formKeys.OWN,
        [formFields.NUMBER_STS]: '',
        [formFields._OWNER_TYPE]: formKeys.OWNER_TYPE.COMPANY,
        [formFields.OWNER_STS]: companyTitle,
        [formFields.OWNER_INN]: '',
        [formFields.RECOGNIZABLE_DOCUMENTS_FILES]: [],
        [formFields.OWNERSHIP_FILES]: [],
        [formFields.IMEI]: '',
        ...loadOptions,
      };
    }
  },
);

const _getTonnageVolumeById = defaultMemoize((liftings, id) => {
  const selectedLifting = liftings.find((lifting) => lifting.id === id);

  if (!selectedLifting) return null;

  return getMappedLiftingCapacity(selectedLifting);
});

export const getTrailerInitialState = createSelector(
  (state) => _get(state, 'resources.trailers.activeItem'),
  (state) => _get(state, 'resources.availableLifting'),
  (state) => companySelectors.getCompanyTitle(state),
  (state) => DocumentSelectors.typeDocumentsMap(state, 'trailer'),
  (state, action) => action,
  (trailer, liftings, companyTitle, trailerTypeDocumentsMap, action) => {
    if (trailer && action === RESOURCE_FORM_ACTION.EDIT) {
      const ownershipFiles = trailerTypeDocumentsMap.filter(
        (item) => item.typeId === queryKeys.DOC_ID_TRAILER_OWNERSHIP,
      );
      const docFrom = _get(trailer, 'owning_doc_from')
        ? format(new Date(_get(trailer, 'owning_doc_from')), DF_DATE_FORMAT)
        : null;

      const docTill = _get(trailer, 'owning_doc_till')
        ? format(new Date(_get(trailer, 'owning_doc_till')), DF_DATE_FORMAT)
        : null;

      return {
        [formFields.ID]: _get(trailer, 'id'),
        ...getMappedTrailerTypes(_get(trailer, 'kind')),
        [formFields.BRAND]: {
          value: _get(trailer, 'brand'),
          label: _get(trailer, 'brand'),
        },
        [formFields.NUMBER]: _get(trailer, 'trailer_number'),
        [formFields.VIN_NUMBER]: _get(trailer, 'trailer_additional_info.vin'),
        [formFields.IS_NO_VIN]: !_get(trailer, 'trailer_additional_info.vin'),
        [formFields.EXTRA_NUMBER_TYPE]: !!_get(trailer, 'car_additional_info.vin')
          ? formKeys.EXTRA_NUMBER_TYPE.BODY_NUMBER
          : !!_get(trailer, 'car_additional_info.body_number')
          ? formKeys.EXTRA_NUMBER_TYPE.BODY_NUMBER
          : formKeys.EXTRA_NUMBER_TYPE.CHASSIS_NUMBER,
        [formFields.CHASSIS_NUMBER]: _get(trailer, 'trailer_additional_info.chassis_number'),
        [formFields.TONNAGE_VOLUME]: _getTonnageVolumeById(liftings, _get(trailer, 'lifting_capacity_id')),
        [formFields.MAX_LIFTING]: _get(trailer, 'max_lifting_capacity'),
        [formFields.LENGTH]: _get(trailer, 'dimensions_cargo_hold.length'),
        [formFields.WIDTH]: _get(trailer, 'dimensions_cargo_hold.width'),
        [formFields.HEIGHT]: _get(trailer, 'dimensions_cargo_hold.height'),
        [formFields.VEHICLE_LENGTH]: _get(trailer, 'dimensions_vehicle.length'),
        [formFields.VEHICLE_WIDTH]: _get(trailer, 'dimensions_vehicle.width'),
        [formFields.VEHICLE_HEIGHT]: _get(trailer, 'dimensions_vehicle.height'),
        [formFields.COVER_TYPE]: _getMappedLoadingTypes(_get(trailer, 'loading_option')),
        [formFields.BELTS]: _get(trailer, 'loading_option.number_of_belts') || 0,
        [formFields.REMOVABLE_UPPER_BEAM]: _get(trailer, 'loading_option.removable_upper_beam'),
        [formFields.REMOVABLE_SIDE_RACKS]: _get(trailer, 'loading_option.removable_side_racks'),
        [formFields.RIGID_BOARD]: _get(trailer, 'loading_option.rigid_board'),
        [formFields.HYDRO_BOARD]: _get(trailer, 'loading_option.hydro_board'),
        [formFields.DISINFECTED]: _get(trailer, 'additional.is_disinfected'),
        [formFields.DISINFECTED_FROM]: _get(trailer, 'additional.disinfected_from')
          ? moment(_get(trailer, 'additional.disinfected_from')).format(DATE_FORMAT)
          : null,
        [formFields.DISINFECTED_UNTIL]: _get(trailer, 'additional.disinfected_until')
          ? moment(_get(trailer, 'additional.disinfected_until')).format(DATE_FORMAT)
          : null,
        [formFields.TEMPERATURE_CHECK]: _get(trailer, 'additional.has_temperature_check'),
        [formFields.OWNERSHIP]: _get(trailer, 'owning_type'),
        [formFields.NUMBER_STS]: _get(trailer, 'number_sts'),
        [formFields.OWNER_STS]: _get(trailer, 'owner_sts'),
        [formFields.OWNER_INN]: _get(trailer, 'owner_inn'),
        [formFields.OWNER_KPP]: {
          value: _get(trailer, 'owner_kpp'),
          label: _get(trailer, 'owner_kpp'),
        },
        [formFields.OWNING_DOC_NUMBER]: _get(trailer, 'owning_doc_number'),
        [formFields.OWNING_DOC_FROM]: docFrom,
        [formFields.OWNING_DOC_TILL]: docTill,
        [formFields._OWNER_TYPE]: !trailer?.owner_inn ? formKeys.OWNER_TYPE.INDIVIDUAL : formKeys.OWNER_TYPE.COMPANY,
        [formFields.RECOGNIZABLE_DOCUMENTS_FILES]: [],
        [formFields.OWNERSHIP_FILES]: ownershipFiles?.[0]?.files ?? [],
      };
    } else if (trailer && action === RESOURCE_FORM_ACTION.CREATE_BASED_ON) {
      return {
        ...getMappedTrailerTypes(_get(trailer, 'kind')),
        [formFields.BRAND]: null,
        [formFields.NUMBER]: '',
        [formFields.VIN_NUMBER]: '',
        [formFields.IS_NO_VIN]: false,
        [formFields.EXTRA_NUMBER_TYPE]: formKeys.EXTRA_NUMBER_TYPE.BODY_NUMBER,
        [formFields.TONNAGE_VOLUME]: _getTonnageVolumeById(liftings, _get(trailer, 'lifting_capacity_id')),
        [formFields.MAX_LIFTING]: _get(trailer, 'max_lifting_capacity'),
        [formFields.LENGTH]: _get(trailer, 'dimensions_cargo_hold.length'),
        [formFields.WIDTH]: _get(trailer, 'dimensions_cargo_hold.width'),
        [formFields.HEIGHT]: _get(trailer, 'dimensions_cargo_hold.height'),
        [formFields.VEHICLE_LENGTH]: _get(trailer, 'dimensions_vehicle.length') ?? '',
        [formFields.VEHICLE_WIDTH]: _get(trailer, 'dimensions_vehicle.width') ?? '',
        [formFields.VEHICLE_HEIGHT]: _get(trailer, 'dimensions_vehicle.height') ?? '',
        [formFields.COVER_TYPE]: _getMappedLoadingTypes(_get(trailer, 'loading_option')),
        [formFields.BELTS]: _get(trailer, 'loading_option.number_of_belts') || 0,
        [formFields.REMOVABLE_UPPER_BEAM]: _get(trailer, 'loading_option.removable_upper_beam'),
        [formFields.REMOVABLE_SIDE_RACKS]: _get(trailer, 'loading_option.removable_side_racks'),
        [formFields.RIGID_BOARD]: _get(trailer, 'loading_option.rigid_board'),
        [formFields.HYDRO_BOARD]: _get(trailer, 'loading_option.hydro_board'),
        [formFields.OWNERSHIP]: _get(trailer, 'owning_type'),
        [formFields.OWNING_DOC_NUMBER]: '',
        [formFields.OWNING_DOC_FROM]: '',
        [formFields.OWNING_DOC_TILL]: '',
        [formFields.NUMBER_STS]: '',
        [formFields._OWNER_TYPE]: formKeys.OWNER_TYPE.COMPANY,
        [formFields.OWNER_STS]: _get(trailer, 'owning_type') === formKeys.OWN ? companyTitle : '',
        [formFields.OWNER_INN]: '',
        [formFields.OWNER_KPP]: '',
        [formFields.RECOGNIZABLE_DOCUMENTS_FILES]: [],
        [formFields.OWNERSHIP_FILES]: [],
      };
    } else {
      const loadOptions = bodyTypeOptions.loadOptions[bodyTypeOptions.AWNING_OBJ.value].reduce((acc, item) => {
        acc[item.value] = false;
        return acc;
      }, {});
      return {
        [formFields.NUMBER]: '',
        [formFields.VIN_NUMBER]: '',
        [formFields.IS_NO_VIN]: false,
        [formFields.BRAND]: null,
        [formFields.TONNAGE_VOLUME]: null,
        [formFields.BODY_TYPE]: bodyTypeOptions.AWNING_OBJ.value,
        [formFields.MAX_LIFTING]: '',
        [formFields.LENGTH]: '',
        [formFields.WIDTH]: '',
        [formFields.HEIGHT]: '',
        [formFields.BELTS]: 0,
        [formFields.COVER_TYPE]: null,
        [formFields.OWNERSHIP]: formKeys.OWN,
        [formFields._OWNER_TYPE]: formKeys.OWNER_TYPE.COMPANY,
        [formFields.NUMBER_STS]: '',
        [formFields.OWNER_STS]: companyTitle,
        [formFields.OWNER_INN]: '',
        [formFields.RECOGNIZABLE_DOCUMENTS_FILES]: [],
        [formFields.OWNERSHIP_FILES]: [],
        ...loadOptions,
      };
    }
  },
);

/**
 * Маппим бэкендовые поля растентовки в поля формы
 * @param options
 * @returns {Array}
 * @private
 */
function _getMappedLoadingTypes(options) {
  let selectedCoverTypes = [];

  if (_get(options, 'full')) {
    selectedCoverTypes.push(bodyTypeOptions.FULL_COVER_OBJ);
  }

  if (_get(options, 'rear')) {
    selectedCoverTypes.push(bodyTypeOptions.BACK_COVER_OBJ);
  }

  if (_get(options, 'lateral')) {
    selectedCoverTypes.push(bodyTypeOptions.SIDE_COVER_OBJ);
  }

  if (_get(options, 'upper')) {
    selectedCoverTypes.push(bodyTypeOptions.TOP_COVER_OBJ);
  }

  return selectedCoverTypes;
}

export const getCurrentCarId = defaultMemoize((state) => _get(state, 'resources.cars.activeItem.id'));

export const getCurrentTrailerId = defaultMemoize((state) => _get(state, 'resources.trailers.activeItem.id'));

/**
 * Маппинг выпадающего списка тоннажа и объема
 */
export const getAvailableLifting = createSelector(
  (state) => _get(state, 'resources.availableLifting', []),
  (options) => options.map(getMappedLiftingCapacity),
);

/**
 * Маппинг выпадающего списка марок ТС
 */
export const getAvailableBrands = defaultMemoize((state) => _get(state, 'resources.availableBrands'));

export const getCarKind = defaultMemoize((state) => _get(state, `resources.cars.activeItem.kind`));

export const getTrailerKind = defaultMemoize((state) => _get(state, `resources.trailers.activeItem.kind`));

/**
 * Получение параметров запроса для водителей
 */
export const getDriversRequestParams = createSelector(
  (state) => getQuery(state),
  (query) => {
    let queryStatus = query[queryKeys.STATUS];
    const admittedTo = [];

    const status = queryStatus
      ? (Array.isArray(queryStatus) ? queryStatus : [queryStatus]).map((item) => {
          if (item === queryKeys.APPROVED_TO_K1) {
            admittedTo.push(CATEGORY_1);
            return queryKeys.APPROVED_TO_K1_K2;
          }

          if (item === queryKeys.APPROVED_TO_K2) {
            admittedTo.push(CATEGORY_2);
            return queryKeys.APPROVED_TO_K1_K2;
          }

          return item;
        })
      : null;

    return {
      status,
      order: DESC,
      sort: queryKeys.LAST_CHANGE,
      full_name: query[queryKeys.FULL_NAME],
      admitted_to: admittedTo,
      per_page: query[queryKeys.PER_PAGE],
      page: query[queryKeys.PAGE],
    };
  },
);

/**
 * Получение query для автомашин
 */
export const getCarsRequestParams = createSelector(
  (state) => getQuery(state),
  (query) => {
    return {
      status: query[queryKeys.STATUS],
      order: DESC,
      sort: queryKeys.LAST_CHANGE,
      car_number: query[queryKeys.CAR_NUMBER],
      kind: query[queryKeys.KIND],
      page: query[queryKeys.PAGE],
      per_page: query[queryKeys.PER_PAGE],
    };
  },
);

/**
 * Получение query для прицепов
 */
export const getTrailersRequestParams = createSelector(
  (state) => getQuery(state),
  (query) => {
    return {
      status: query[queryKeys.STATUS],
      order: DESC,
      sort: queryKeys.LAST_CHANGE,
      trailer_number: query[queryKeys.TRAILER_NUMBER],
      page: query[queryKeys.PAGE],
      per_page: query[queryKeys.PER_PAGE],
    };
  },
);

/**
 * Получение количества выбранных фильтров в попапе
 */
export const getActiveStatusFilterLength = createSelector(
  (state) => getQuery(state),
  (query) => {
    return query[queryKeys.STATUS] ? query[queryKeys.STATUS].length : 0;
  },
);

export const getActiveKindFilterLength = createSelector(
  (state) => getQuery(state),
  (query) => (!!query[queryKeys.KIND] ? 1 : 0),
);

/**
 * Получение id активного элемента
 */
// export const getActiveItemID = createSelector(
//   (state) => getResources(state),
//   (state) => getQuery(state),
//   (items, query) => {
//     if (!query) return null;
//
//     const type = query[queryKeys.TYPE];
//     const activeItemId = query[queryKeys.ACTIVE_ITEM];
//
//     if (activeItemId) return activeItemId;
//
//     return _get(items, `${type}[0].id`) || null;
//   }
// );

/**
 * Получение id активного элемента после удаления ресурса
 */
// export const getActiveItemIdOnDelete = createSelector(
//   (state) => getResources(state),
//   (state) => getQuery(state),
//   (items, query) => {
//     if (!query) return null;
//
//     const type = query[queryKeys.TYPE];
//     const activeItemId = query[queryKeys.ACTIVE_ITEM];
//     const itemIndex = items[type].findIndex((item) => item.id === activeItemId);
//
//     return (
//       _get(items, `${type}[${itemIndex + 1}].id`) ||
//       _get(items, `${type}[${itemIndex - 1}].id`)
//     );
//   }
// );

/**
 * Получение активного элемента по id
 */
// export const getActiveItem = createSelector(
//   (state) => getResources(state),
//   (state) => getQuery(state),
//   (items, query) =>
//     _memoize((activeItemId) => {
//       const type = query[queryKeys.TYPE];
//
//       if (!query || !type) return null;
//
//       const firstItem = items[type][0];
//
//       if (!activeItemId) return firstItem;
//
//       return items[type].find((item) => item.id === activeItemId) || firstItem;
//     })
// );

/**
 * Получение статуса о загруженных документах
 */
export const isDocsReady = createSelector(
  (state, entity) =>
    DocumentSelectors.isTypesReadyByCustomFilter(
      state,
      entity,
    )((type) => {
      return ![
        queryKeys.DOC_ID_DELLIN,
        queryKeys.DOC_ID_DLTRANS,
        queryKeys.DOC_ID_DELLIN_MKT,
        queryKeys.DOC_ID_EMPLOYEE_CONTRACT,
        queryKeys.DOC_ID_CAR_OWNERSHIP,
        queryKeys.DOC_ID_TRAILER_OWNERSHIP,
      ].includes(type.typeId);
    }),
  (state, entity) => entity,
  (state, entity) => isDocIdAttached(state, entity)(queryKeys.DOC_ID_DELLIN),
  (state, entity) => isDocIdAttached(state, entity)(queryKeys.DOC_ID_DLTRANS),
  (state, entity) => isDocIdAttached(state, entity)(queryKeys.DOC_ID_DELLIN_MKT),

  (isRegularFilesReady, entity, isDellinDocAttached, isDLTDocAttached, isDellinMKTDocAttached) => {
    if (entity !== formKeys.DRIVER) return isRegularFilesReady;

    // для водителей должна быть хотя бы одна доверенность
    const isAnyOneProxyReady = isDellinDocAttached || isDLTDocAttached || isDellinMKTDocAttached;

    return isRegularFilesReady && isAnyOneProxyReady;
  },
);

/**
 * Наличие файла в определенном типе документа
 */
export const isDocIdAttached = createSelector(
  (state, entity) => DocumentSelectors.getFilesInType(state, entity),
  (files) => _memoize((docId) => !!files[docId]),
);

/**
 * Получение значения поля по названию
 * @param fields
 * @param needLabel
 * @return {*|string}
 * @private
 */
function _getFieldValueByLabel(fields, needLabel) {
  const field = fields.find(({ label }) => label === needLabel);
  return _get(field, 'value') || '';
}

// Водительское Удостоверение: образец 2011/2014 года (лицевая сторона)
export function driverLicenseFrontMapper(fields) {
  // const fields = data.recognitionData.items[0].fields;
  return {
    [dBrainKeys.DATE_FROM]: fields[dBrainKeys.DATE_FROM]?.text,
    [dBrainKeys.DATE_OF_BIRTH]: fields[dBrainKeys.DATE_OF_BIRTH]?.text,
    [dBrainKeys.DATE_END]: fields[dBrainKeys.DATE_END]?.text,
    [dBrainKeys.NAME]: fields[dBrainKeys.NAME]?.text,
    [dBrainKeys.CATEGORY]: fields[dBrainKeys.CATEGORY]?.text,
    [dBrainKeys.PLACE_OF_ISSUE]: fields[dBrainKeys.PLACE_OF_ISSUE]?.text,
    [dBrainKeys.PLACE_OF_BIRTH]: fields[dBrainKeys.PLACE_OF_BIRTH]?.text,
    [dBrainKeys.NUMBER]: fields[dBrainKeys.NUMBER]?.text,
    [dBrainKeys.PATRONYMIC]: fields[dBrainKeys.PATRONYMIC]?.text,
    [dBrainKeys.ISSUER]: fields[dBrainKeys.ISSUER]?.text,
    [dBrainKeys.SURNAME]: fields[dBrainKeys.SURNAME]?.text,
  };
}

// Водительское Удостоверение: образец 2011 года (обратная сторона)
export function driverLicense2011BackMapper(fields) {
  return {
    [dBrainKeys.CATEGORY_A_END]: fields[dBrainKeys.CATEGORY_A_END]?.text,
    [dBrainKeys.CATEGORY_A_BEGIN]: fields[dBrainKeys.CATEGORY_A_BEGIN]?.text,
    [dBrainKeys.CATEGORY_B_END]: fields[dBrainKeys.CATEGORY_B_END]?.text,
    [dBrainKeys.CATEGORY_B_BEGIN]: fields[dBrainKeys.CATEGORY_B_BEGIN]?.text,
    [dBrainKeys.CATEGORY_BE_END]: fields[dBrainKeys.CATEGORY_BE_END]?.text,
    [dBrainKeys.CATEGORY_BE_BEGIN]: fields[dBrainKeys.CATEGORY_BE_BEGIN]?.text,
    [dBrainKeys.CATEGORY_C_END]: fields[dBrainKeys.CATEGORY_C_END]?.text,
    [dBrainKeys.CATEGORY_C_BEGIN]: fields[dBrainKeys.CATEGORY_C_BEGIN]?.text,
    [dBrainKeys.CATEGORY_CE_END]: fields[dBrainKeys.CATEGORY_CE_END]?.text,
    [dBrainKeys.CATEGORY_CE_BEGIN]: fields[dBrainKeys.CATEGORY_CE_BEGIN]?.text,
    [dBrainKeys.CATEGORY_D_END]: fields[dBrainKeys.CATEGORY_D_END]?.text,
    [dBrainKeys.CATEGORY_D_BEGIN]: fields[dBrainKeys.CATEGORY_D_BEGIN]?.text,
    [dBrainKeys.CATEGORY_DE_END]: fields[dBrainKeys.CATEGORY_DE_END]?.text,
    [dBrainKeys.CATEGORY_DE_BEGIN]: fields[dBrainKeys.CATEGORY_DE_BEGIN]?.text,
    [dBrainKeys.CATEGORY_TB_END]: fields[dBrainKeys.CATEGORY_TB_END]?.text,
    [dBrainKeys.CATEGORY_TB_BEGIN]: fields[dBrainKeys.CATEGORY_TB_BEGIN]?.text,
    [dBrainKeys.CATEGORY_TM_END]: fields[dBrainKeys.CATEGORY_TM_END]?.text,
    [dBrainKeys.CATEGORY_TM_BEGIN]: fields[dBrainKeys.CATEGORY_TM_BEGIN]?.text,
    [dBrainKeys.SPECIAL_MARKS]: fields[dBrainKeys.SPECIAL_MARKS]?.text,
    [dBrainKeys.SERIES_NUMBER]: fields[dBrainKeys.SERIES_NUMBER]?.text,
  };
}

// Водительское Удостоверение: образец 2014 года (обратная сторона)
export function driverLicense2014BackMapper(fields) {
  return {
    [dBrainKeys.CATEGORY_A_END]: fields[dBrainKeys.CATEGORY_A_END]?.text,
    [dBrainKeys.CATEGORY_A_BEGIN]: fields[dBrainKeys.CATEGORY_A_BEGIN]?.text,
    [dBrainKeys.CATEGORY_A1_END]: fields[dBrainKeys.CATEGORY_A1_END]?.text,
    [dBrainKeys.CATEGORY_A1_BEGIN]: fields[dBrainKeys.CATEGORY_A1_BEGIN]?.text,
    [dBrainKeys.CATEGORY_B_END]: fields[dBrainKeys.CATEGORY_B_END]?.text,
    [dBrainKeys.CATEGORY_B1_BEGIN]: fields[dBrainKeys.CATEGORY_B1_BEGIN]?.text,
    [dBrainKeys.CATEGORY_B1_END]: fields[dBrainKeys.CATEGORY_B1_END]?.text,
    [dBrainKeys.CATEGORY_B_BEGIN]: fields[dBrainKeys.CATEGORY_B_BEGIN]?.text,
    [dBrainKeys.CATEGORY_BE_END]: fields[dBrainKeys.CATEGORY_BE_END]?.text,
    [dBrainKeys.CATEGORY_BE_BEGIN]: fields[dBrainKeys.CATEGORY_BE_BEGIN]?.text,
    [dBrainKeys.CATEGORY_C_END]: fields[dBrainKeys.CATEGORY_C_END]?.text,
    [dBrainKeys.CATEGORY_C_BEGIN]: fields[dBrainKeys.CATEGORY_C_BEGIN]?.text,
    [dBrainKeys.CATEGORY_C1_END]: fields[dBrainKeys.CATEGORY_C1_END]?.text,
    [dBrainKeys.CATEGORY_C1_BEGIN]: fields[dBrainKeys.CATEGORY_C1_BEGIN]?.text,
    [dBrainKeys.CATEGORY_C1E_END]: fields[dBrainKeys.CATEGORY_C1E_END]?.text,
    [dBrainKeys.CATEGORY_C1E_BEGIN]: fields[dBrainKeys.CATEGORY_C1E_BEGIN]?.text,
    [dBrainKeys.CATEGORY_CE_END]: fields[dBrainKeys.CATEGORY_CE_END]?.text,
    [dBrainKeys.CATEGORY_CE_BEGIN]: fields[dBrainKeys.CATEGORY_CE_BEGIN]?.text,
    [dBrainKeys.CATEGORY_D_END]: fields[dBrainKeys.CATEGORY_D_END]?.text,
    [dBrainKeys.CATEGORY_D_BEGIN]: fields[dBrainKeys.CATEGORY_D_BEGIN]?.text,
    [dBrainKeys.CATEGORY_D1_END]: fields[dBrainKeys.CATEGORY_D1_END]?.text,
    [dBrainKeys.CATEGORY_D1_BEGIN]: fields[dBrainKeys.CATEGORY_D1_BEGIN]?.text,
    [dBrainKeys.CATEGORY_D1E_END]: fields[dBrainKeys.CATEGORY_D1E_END]?.text,
    [dBrainKeys.CATEGORY_D1E_BEGIN]: fields[dBrainKeys.CATEGORY_D1E_BEGIN]?.text,
    [dBrainKeys.CATEGORY_DE_END]: fields[dBrainKeys.CATEGORY_DE_END]?.text,
    [dBrainKeys.CATEGORY_DE_BEGIN]: fields[dBrainKeys.CATEGORY_DE_BEGIN]?.text,
    [dBrainKeys.CATEGORY_M_END]: fields[dBrainKeys.CATEGORY_M_END]?.text,
    [dBrainKeys.CATEGORY_M_BEGIN]: fields[dBrainKeys.CATEGORY_M_BEGIN]?.text,
    [dBrainKeys.CATEGORY_TB_END]: fields[dBrainKeys.CATEGORY_TB_END]?.text,
    [dBrainKeys.CATEGORY_TB_BEGIN]: fields[dBrainKeys.CATEGORY_TB_BEGIN]?.text,
    [dBrainKeys.CATEGORY_TM_END]: fields[dBrainKeys.CATEGORY_TM_END]?.text,
    [dBrainKeys.CATEGORY_TM_BEGIN]: fields[dBrainKeys.CATEGORY_TM_BEGIN]?.text,
    [dBrainKeys.SPECIAL_MARKS]: fields[dBrainKeys.SPECIAL_MARKS]?.text,
    [dBrainKeys.SERIES_NUMBER]: fields[dBrainKeys.SERIES_NUMBER]?.text,
  };
}

// Паспорт гражданина РФ: главный разворот, печатный образец
export function passportRFMainPageMapper(fields) {
  return {
    [dBrainKeys.MRZ]: fields[dBrainKeys.MRZ]?.text,
    [dBrainKeys.DATE_OF_ISSUE]: fields[dBrainKeys.DATE_OF_ISSUE]?.text,
    [dBrainKeys.DATE_OF_BIRTH]: fields[dBrainKeys.DATE_OF_BIRTH]?.text,
    [dBrainKeys.FIRST_NAME]: fields[dBrainKeys.FIRST_NAME]?.text,
    [dBrainKeys.SUBDIVISION_CODE]: fields[dBrainKeys.SUBDIVISION_CODE]?.text,
    [dBrainKeys.PLACE_OF_BIRTH]: fields[dBrainKeys.PLACE_OF_BIRTH]?.text,
    [dBrainKeys.OTHER_NAMES]: fields[dBrainKeys.OTHER_NAMES]?.text,
    [dBrainKeys.ISSUING_AUTHORITY]: fields[dBrainKeys.ISSUING_AUTHORITY]?.text,
    [dBrainKeys.SEX]: fields[dBrainKeys.SEX]?.text,
    [dBrainKeys.SERIES_AND_NUMBER]: fields[dBrainKeys.SERIES_AND_NUMBER]?.text,
    [dBrainKeys.SURNAME]: fields[dBrainKeys.SURNAME]?.text,
  };
}

// Паспорт гражданина РФ: главный разворот, рукописный образец
export function passportRFMainPageHandwrittenMapper(fields) {
  return {
    [dBrainKeys.DATE_OF_ISSUE]: fields[dBrainKeys.DATE_OF_ISSUE]?.text,
    [dBrainKeys.DATE_OF_BIRTH]: fields[dBrainKeys.DATE_OF_BIRTH]?.text,
    [dBrainKeys.FIRST_NAME]: fields[dBrainKeys.FIRST_NAME]?.text,
    [dBrainKeys.SUBDIVISION_CODE]: fields[dBrainKeys.SUBDIVISION_CODE]?.text,
    [dBrainKeys.PLACE_OF_BIRTH]: fields[dBrainKeys.PLACE_OF_BIRTH]?.text,
    [dBrainKeys.OTHER_NAMES]: fields[dBrainKeys.OTHER_NAMES]?.text,
    [dBrainKeys.ISSUING_AUTHORITY]: fields[dBrainKeys.ISSUING_AUTHORITY]?.text,
    [dBrainKeys.SEX]: fields[dBrainKeys.SEX]?.text,
    [dBrainKeys.SERIES_AND_NUMBER]: fields[dBrainKeys.SERIES_AND_NUMBER]?.text,
    [dBrainKeys.SURNAME]: fields[dBrainKeys.SURNAME]?.text,
  };
}

// Паспорт гражданина РФ: страница «Место жительства», печатный образец
export function passportRFRegistrationPageMapper(fields) {
  return {
    [dBrainKeys.ADDRESS]: fields[dBrainKeys.ADDRESS]?.text,
    [dBrainKeys.DATE]: fields[dBrainKeys.DATE]?.text,
    [dBrainKeys.HOUSE]: fields[dBrainKeys.HOUSE]?.text,
    [dBrainKeys.APARTMENT]: fields[dBrainKeys.APARTMENT]?.text,
    [dBrainKeys.SUBDIVISION_CODE]: fields[dBrainKeys.SUBDIVISION_CODE]?.text,
    [dBrainKeys.BUILDING]: fields[dBrainKeys.BUILDING]?.text,
    [dBrainKeys.ISSUER]: fields[dBrainKeys.ISSUER]?.text,
    [dBrainKeys.LOCALITY]: fields[dBrainKeys.LOCALITY]?.text,
    [dBrainKeys.REGION_DISTRICT]: fields[dBrainKeys.REGION_DISTRICT]?.text,
    [dBrainKeys.REGION]: fields[dBrainKeys.REGION]?.text,
    [dBrainKeys.LOCALITY_DISTRICT]: fields[dBrainKeys.LOCALITY_DISTRICT]?.text,
    [dBrainKeys.STREET]: fields[dBrainKeys.STREET]?.text,
    [dBrainKeys.PARSED_ADDRESS]: fields[dBrainKeys.PARSED_ADDRESS],
  };
}

// Паспорт гражданина РФ: страница «Место жительства», печатный образец
export function passportRFRegistrationPageHandwrittenMapper(fields) {
  return {
    [dBrainKeys.ADDRESS]: fields[dBrainKeys.ADDRESS]?.text,
    [dBrainKeys.DATE]: fields[dBrainKeys.DATE]?.text,
    [dBrainKeys.ISSUER]: fields[dBrainKeys.ISSUER]?.text,
    [dBrainKeys.PARSED_ADDRESS]: fields[dBrainKeys.PARSED_ADDRESS],
  };
}

// Свидетельство о регистрации ТС: лицевая сторона
export function vehicleRegistrationCertificateFrontMapper(fields) {
  return {
    [dBrainKeys.RELEASE_YEAR]: fields[dBrainKeys.RELEASE_YEAR]?.text,
    [dBrainKeys.ENGINE_NUMBER]: fields[dBrainKeys.ENGINE_NUMBER]?.text,
    [dBrainKeys.VEHICLE_CATEGORY]: fields[dBrainKeys.VEHICLE_CATEGORY]?.text,
    [dBrainKeys.VEHICLE_BODY]: fields[dBrainKeys.VEHICLE_BODY]?.text,
    [dBrainKeys.BRAND_ENG]: fields[dBrainKeys.BRAND_ENG]?.text,
    [dBrainKeys.BRAND_RUS]: fields[dBrainKeys.BRAND_RUS]?.text,
    [dBrainKeys.MASS]: fields[dBrainKeys.MASS]?.text,
    [dBrainKeys.ENGINE_MODEL]: fields[dBrainKeys.ENGINE_MODEL]?.text,
    [dBrainKeys.MODEL_ENG]: fields[dBrainKeys.MODEL_ENG]?.text,
    [dBrainKeys.MODEL_RUS]: fields[dBrainKeys.MODEL_RUS]?.text,
    [dBrainKeys.ENGINE_KW]: fields[dBrainKeys.ENGINE_KW]?.text,
    [dBrainKeys.ENGINE_HP]: fields[dBrainKeys.ENGINE_HP]?.text,
    [dBrainKeys.VIN]: fields[dBrainKeys.VIN]?.text,
    [dBrainKeys.NUMBER_BOTTOM]: fields[dBrainKeys.NUMBER_BOTTOM]?.text,
    [dBrainKeys.PASSPORT_NUMBER]: fields[dBrainKeys.PASSPORT_NUMBER]?.text,
    [dBrainKeys.PASSPORT_SERIES]: fields[dBrainKeys.PASSPORT_SERIES]?.text,
    [dBrainKeys.ENGINE_VOLUME]: fields[dBrainKeys.ENGINE_VOLUME]?.text,
    [dBrainKeys.MAX_MASS]: fields[dBrainKeys.MAX_MASS]?.text,
    [dBrainKeys.REG_NUMBER]: fields[dBrainKeys.REG_NUMBER]?.text,
    [dBrainKeys.DOCUMENT_NUMBER]: fields[dBrainKeys.DOCUMENT_NUMBER]?.text,
    [dBrainKeys.SERIES_BOTTOM]: fields[dBrainKeys.SERIES_BOTTOM]?.text,
    [dBrainKeys.TEMPORARY_REGISTRATION_TERM]: fields[dBrainKeys.TEMPORARY_REGISTRATION_TERM]?.text,
    [dBrainKeys.VEHICLE_TYPE]: fields[dBrainKeys.VEHICLE_TYPE]?.text,
    [dBrainKeys.COLOR]: fields[dBrainKeys.COLOR]?.text,
    [dBrainKeys.VEHICLE_CHASSIS]: fields[dBrainKeys.VEHICLE_CHASSIS]?.text,
    [dBrainKeys.ECOLOGIC_CLASS]: fields[dBrainKeys.ECOLOGIC_CLASS]?.text,
  };
}

// Свидетельство о регистрации ТС: обратная сторона
export function vehicleRegistrationCertificateBackMapper(fields) {
  return {
    [dBrainKeys.DATE]: fields[dBrainKeys.DATE]?.text,
    [dBrainKeys.HOUSE_NUMBER]: fields[dBrainKeys.HOUSE_NUMBER]?.text,
    [dBrainKeys.APARTMENT_NUMBER]: fields[dBrainKeys.APARTMENT_NUMBER]?.text,
    [dBrainKeys.BUILDING_NUMBER]: fields[dBrainKeys.BUILDING_NUMBER]?.text,
    [dBrainKeys.LEGAL_NAME]: fields[dBrainKeys.LEGAL_NAME]?.text,
    [dBrainKeys.LEGAL_NAME_RUS]: fields[dBrainKeys.LEGAL_NAME_RUS]?.text,
    [dBrainKeys.CITY]: fields[dBrainKeys.CITY]?.text,
    [dBrainKeys.NUMBER_TOP]: fields[dBrainKeys.NUMBER_TOP]?.text,
    [dBrainKeys.NUMBER_BOTTOM]: fields[dBrainKeys.NUMBER_BOTTOM]?.text,
    [dBrainKeys.POLICE_UNIT_CODE]: fields[dBrainKeys.POLICE_UNIT_CODE]?.text,
    [dBrainKeys.SPECIAL_MARKS]: fields[dBrainKeys.SPECIAL_MARKS]?.text,
    [dBrainKeys.REGION]: fields[dBrainKeys.REGION]?.text,
    [dBrainKeys.PROVINCE_RUS]: fields[dBrainKeys.PROVINCE_RUS]?.text,
    [dBrainKeys.PROVINCE]: fields[dBrainKeys.PROVINCE]?.text,
    [dBrainKeys.SERIES_TOP]: fields[dBrainKeys.SERIES_TOP]?.text,
    [dBrainKeys.SERIES_BOTTOM]: fields[dBrainKeys.SERIES_BOTTOM]?.text,
    [dBrainKeys.NAME]: fields[dBrainKeys.NAME]?.text,
    [dBrainKeys.NAME_RUS]: fields[dBrainKeys.NAME_RUS]?.text,
    [dBrainKeys.PATRONYMIC_RUS]: fields[dBrainKeys.PATRONYMIC_RUS]?.text,
    [dBrainKeys.SURNAME]: fields[dBrainKeys.SURNAME]?.text,
    [dBrainKeys.SURNAME_RUS]: fields[dBrainKeys.SURNAME_RUS]?.text,
    [dBrainKeys.STREET]: fields[dBrainKeys.STREET]?.text,
  };
}

// Выбор маппера в соответствии с типом документа
export function selectResourceDocumentRecognitionMapper(document) {
  const fields = document[dBrainKeys.FIELDS];
  switch (document[dBrainKeys.DOC_TYPE]) {
    case dBrainKeys.DRIVER_LICENSE_2011_BACK:
      return driverLicense2011BackMapper(fields);
    case dBrainKeys.DRIVER_LICENSE_FRONT:
      return driverLicenseFrontMapper(fields);
    case dBrainKeys.DRIVER_LICENSE_2014_BACK:
      return driverLicense2014BackMapper(fields);
    case dBrainKeys.PASSPORT_MAIN:
      return passportRFMainPageMapper(fields);
    case dBrainKeys.PASSPORT_MAIN_HANDWRITTEN:
      return passportRFMainPageHandwrittenMapper(fields);
    case dBrainKeys.PASSPORT_REGISTRATION:
      return passportRFRegistrationPageMapper(fields);
    case dBrainKeys.PASSPORT_REGISTRATION_HANDWRITTEN:
      return passportRFRegistrationPageHandwrittenMapper(fields);
    case dBrainKeys.VEHICLE_REGISTRATION_CERTIFICATE_FRONT:
      return vehicleRegistrationCertificateFrontMapper(fields);
    case dBrainKeys.VEHICLE_REGISTRATION_CERTIFICATE_BACK:
      return vehicleRegistrationCertificateBackMapper(fields);
    default:
      return null;
  }
}

/**
 * Выбор списка обязательных полей (чтобы документ считался распознанным полностью) по названию документа
 */
export function selectRequiredDocFields(docName) {
  switch (docName) {
    case dBrainKeys.DRIVER_LICENSE_FRONT:
      return dBrainKeys.DRIVER_LICENSE_FRONT_REQUIRED_FIELDS;
    case dBrainKeys.PASSPORT_MAIN:
    case dBrainKeys.PASSPORT_MAIN_HANDWRITTEN:
      return dBrainKeys.PASSPORT_MAIN_REQUIRED_FIELDS;
    case dBrainKeys.PASSPORT_REGISTRATION:
    case dBrainKeys.PASSPORT_REGISTRATION_HANDWRITTEN:
      return dBrainKeys.PASSPORT_REGISTRATION_REQUIRED_FIELDS;
    default:
      return [];
  }
}

/**
 * Получение промаппенных данных распознанных документов водителя
 */
export const getRecognizedDriverDocsData = createSelector(
  (state) => _get(state, 'resources.drivers.recognizedDocsData'),
  (docsData) => recognizedDocsDataMapper(docsData),
);

/**
 * Получение промаппенных данных распознанных документов машины
 */
export const getRecognizedCarDocsData = createSelector(
  (state) => _get(state, 'resources.cars.recognizedDocsData'),
  (docsData) => recognizedDocsDataMapper(docsData),
);

/**
 * Получение промаппенных данных распознанных документов прицепа
 */
export const getRecognizedTrailerDocsData = createSelector(
  (state) => _get(state, 'resources.trailers.recognizedDocsData'),
  (docsData) => recognizedDocsDataMapper(docsData),
);

export const recognizedDocsDataMapper = (docsData) => {
  const mappedData = docsData?.map((doc) =>
    doc?.recognition_data?.documents.reduce((acc, document) => {
      const dataObj = selectResourceDocumentRecognitionMapper(document);
      if (!acc[document.doc_type]) {
        acc[document.doc_type] = {
          id: doc.fileId,
          ...dataObj,
        };
      }
      return acc;
    }, {}),
  );

  return mappedData.reduce((acc, doc) => {
    return {
      ...acc,
      ...doc,
    };
  }, {});
};

export const docTypeByDBrainTypeMapper = new Map()
  .set(dBrainKeys.PASSPORT_MAIN, formKeys.RESOURCE_DOC_TYPE.PASSPORT_MAIN_PAGE)
  .set(dBrainKeys.PASSPORT_MAIN_HANDWRITTEN, formKeys.RESOURCE_DOC_TYPE.PASSPORT_MAIN_PAGE)
  .set(dBrainKeys.PASSPORT_REGISTRATION, formKeys.RESOURCE_DOC_TYPE.PASSPORT_REGISTRATION_PAGE)
  .set(dBrainKeys.PASSPORT_REGISTRATION_HANDWRITTEN, formKeys.RESOURCE_DOC_TYPE.PASSPORT_REGISTRATION_PAGE)
  .set(dBrainKeys.DRIVER_LICENSE_FRONT, formKeys.RESOURCE_DOC_TYPE.DRIVER_LICENCE)
  .set(dBrainKeys.DRIVER_LICENSE_2011_BACK, formKeys.RESOURCE_DOC_TYPE.DRIVER_LICENCE)
  .set(dBrainKeys.DRIVER_LICENSE_2014_BACK, formKeys.RESOURCE_DOC_TYPE.DRIVER_LICENCE)
  .set(dBrainKeys.VEHICLE_REGISTRATION_CERTIFICATE_FRONT, formKeys.RESOURCE_DOC_TYPE.VEHICLE_REGISTRATION_CERTIFICATE)
  .set(dBrainKeys.VEHICLE_REGISTRATION_CERTIFICATE_BACK, formKeys.RESOURCE_DOC_TYPE.VEHICLE_REGISTRATION_CERTIFICATE);
