import { useBillingContext } from '../../billing/withBilling';
import { getServiceBalance, hasActiveSubscription } from '../../billing/selectors';
import { CREATE_AUCTION, CREATE_CONTRACT, CREATE_INSTANT, UNLIMITED } from '../../billing/constants';

export function useOrderCreatingPermissions() {
  const { subscriptions } = useBillingContext();
  const hasSubscription = hasActiveSubscription(subscriptions);
  // @ts-ignore
  const balanceCreateInstant = getServiceBalance(subscriptions, CREATE_INSTANT);
  const canCreateInstant = balanceCreateInstant === UNLIMITED || balanceCreateInstant > 0;
  // @ts-ignore
  const balanceCreateAuction = getServiceBalance(subscriptions, CREATE_AUCTION);
  const canCreateAuction = balanceCreateAuction === UNLIMITED || balanceCreateAuction > 0;
  // @ts-ignore
  const balanceCreateContract = getServiceBalance(subscriptions, CREATE_CONTRACT);
  const canCreateContract = balanceCreateContract === UNLIMITED || balanceCreateContract > 0;

  const canCreateOrder = canCreateInstant || canCreateAuction || canCreateContract;

  return {
    hasSubscription,
    canCreateOrder,
    canCreateAuction,
    canCreateInstant,
    canCreateContract,
    balanceCreateContract,
  };
}
