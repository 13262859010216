import React from 'react';
import getFormattedPrice, { getUnformattedPrice } from '../../../helpers/getFormattedPrice';
import Tooltip from '../../Tooltip';
import getMinWorkHours from '../../../helpers/getMinWorkHours';

/**
 * Получение иконки о типе маршрута
 * @param currentPrice
 * @param minWorkHours
 * @param isHourlyRent
 * @param partnerTariff
 * @param priceSorting
 * @param pricePerKm
 * @param pricePerHour
 * @returns {*}
 */
export function getSuggestionPrice(
  currentPrice,
  isHourlyRent,
  minWorkHours,
  partnerTariff,
  priceSorting: any = undefined,
  pricePerKm = undefined,
  pricePerHour = undefined,
  isPricePerHourSelected = false,
) {
  if (isHourlyRent) {
    const totalPrice = partnerTariff
      ? partnerTariff.price * getMinWorkHours(partnerTariff?.min_work_hours, minWorkHours)
      : Number(getUnformattedPrice(currentPrice)) * minWorkHours;
    return (
      <Tooltip
        content={
          <>
            <div>
              Стоимость за {getMinWorkHours(partnerTariff?.min_work_hours, minWorkHours)} часа:{' '}
              {getFormattedPrice(totalPrice)} ₽
            </div>
            <div>Ставка за час: {partnerTariff?.price ?? currentPrice} ₽/ч</div>
          </>
        }
      >
        <div className="flex column">
          <span>
            <span className="color-dark">({getMinWorkHours(partnerTariff?.min_work_hours, minWorkHours)} ч) </span>
            {getFormattedPrice(totalPrice)} ₽
          </span>
          {(priceSorting?.sortBy === 'cost_per_h' || (isPricePerHourSelected && priceSorting?.sortBy === 'price')) &&
            pricePerHour && <span className="caption color-dark">{pricePerHour} ₽/ч</span>}
          {priceSorting?.sortBy !== 'cost_per_h' &&
            !(isPricePerHourSelected && priceSorting?.sortBy === 'price') &&
            pricePerKm && <span className="caption color-dark">{pricePerKm} ₽/км</span>}
          {/*<span className="caption color-dark nmt-1">*/}
          {/*  за {getMinWorkHours(partnerTariff?.min_work_hours, minWorkHours)} ч*/}
          {/*</span>*/}
        </div>
      </Tooltip>
    );
  }

  if (!currentPrice) return <>&ndash;</>;

  return (
    <div className="flex column">
      <span>{getFormattedPrice(currentPrice)} ₽</span>
      {(priceSorting?.sortBy === 'cost_per_h' || (isPricePerHourSelected && priceSorting?.sortBy === 'price')) &&
        pricePerHour && <span className="caption color-dark">{pricePerHour} ₽/ч</span>}
      {priceSorting?.sortBy !== 'cost_per_h' &&
        !(isPricePerHourSelected && priceSorting?.sortBy === 'price') &&
        pricePerKm && <span className="caption color-dark">{pricePerKm} ₽/км</span>}
    </div>
  );
}
