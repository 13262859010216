import { INDIVIDUAL } from '../constants';

/**
 * Получение полного имени компании с формой организации
 * @param title - название компании
 * @param opf - название формы организации
 * @returns {string}
 * @private
 */
function getFullCompanyName(title: string, opf: string): string {
  if (opf === INDIVIDUAL.name) {
    return `${opf} ${title}`;
  }
  return `${opf} «${title}»`;
}

export default getFullCompanyName;
