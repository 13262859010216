import React from 'react';
import SVGIcon from './index';

function IconPersonCircle(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 8C12 9.10457 11.1046 10 10 10C8.89543 10 8 9.10457 8 8C8 6.89543 8.89543 6 10 6C11.1046 6 12 6.89543 12 8ZM9.99998 11C7.80984 11 5.94859 12.1265 5.27222 13.6948C6.37045 15.0981 8.07989 16 9.99998 16C11.9201 16 13.6295 15.0981 14.7277 13.6948C14.0514 12.1265 12.1901 11 9.99998 11Z"
      />
    </SVGIcon>
  );
}

IconPersonCircle.defaultProps = { color: 'base' };

export default React.memo(IconPersonCircle);
