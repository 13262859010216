export type TChatStore = {
  _rooms: {
    [roomId: string]: TChatStoreRoom;
  };
  _syncSince: string | null;
  _chats: TChat[];
};

export enum ROOM_STATE {
  invite = 'invite',
  join = 'join',
  // leave = 'leave',
}

export type TChatStoreRoom = {
  state: ROOM_STATE;
  unread: number;
};

export type TChat = {
  id: string;
  cargo_owner_id: string;
  transport_company_id: string;
  room_id: string;
  entity_id: string;
  created_at: string;
};

export type TChatsRes = {
  chats: TChat[];
};

export type TUnreadMessagesByEntity = {
  [entityId: string]: {
    totalUnread: number;
    [roomId: string]: number;
  };
};
