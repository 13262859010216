import React from 'react';
import {
  AWNING_URL,
  DROPSIDE_URL,
  FULL_METAL_URL,
  ISOTHERMAL_URL,
  REFRIGERATOR_URL,
  TANKER_URL,
  CONTAINER_URL,
  TIMBER_CARRIER_URL,
  STEP_DECK_URL,
} from '../../../helpers/carTypesMapping';
import Tooltip from '../../Tooltip';
import renderTonnageVolume from '../../../helpers/renderTonnageVolume';

export const TYPES = new Map()
  .set(AWNING_URL, 'Тент')
  .set(REFRIGERATOR_URL, 'Реф.')
  .set(ISOTHERMAL_URL, 'Изотерм.')
  .set(FULL_METAL_URL, 'Цельнометалл.')
  .set(DROPSIDE_URL, 'Бортовой')
  .set(TIMBER_CARRIER_URL, 'Лесовоз')
  .set(STEP_DECK_URL, 'Автотрал')
  .set(TANKER_URL, 'Автоцистерна')
  .set(CONTAINER_URL, 'Контейнеровоз');

export const TYPES_FULL = new Map()
  .set(AWNING_URL, 'Тент')
  .set(REFRIGERATOR_URL, 'Рефрижератор')
  .set(ISOTHERMAL_URL, 'Изотермический')
  .set(FULL_METAL_URL, 'Цельнометаллический')
  .set(DROPSIDE_URL, 'Бортовой')
  .set(TIMBER_CARRIER_URL, 'Лесовоз')
  .set(STEP_DECK_URL, 'Автотрал')
  .set(TANKER_URL, 'Автоцистерна')
  .set(CONTAINER_URL, 'Контейнеровоз');

interface TypeProps {
  bodyTypes: string[];
  tonnage?: number;
  volume?: string;
}

export function getVehicleType({ bodyTypes, tonnage, volume }: TypeProps) {
  const typesLength = bodyTypes?.length;
  const types = bodyTypes?.map((type) => TYPES.get(type)).join(', ');
  return (
    <span className="flex column">
      <span>
        {TYPES.get(bodyTypes?.[0]) || 'Тент'}
        {typesLength > 1 && (
          <Tooltip content={types}>
            <span className="caption color-primary ml-1">+{typesLength - 1}</span>
          </Tooltip>
        )}
      </span>
      {tonnage && volume && <span className="caption nmt-1">{renderTonnageVolume(tonnage, volume)}</span>}
    </span>
  );
}

export function getVehicleTypeString({ bodyTypes }: TypeProps) {
  return bodyTypes.map((type) => TYPES_FULL.get(type)).join(', ');
}
