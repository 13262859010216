import React from 'react';
import SVGIcon from './index';

const IconInfoFilled = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM11 14L11 9L9 9L9 14L11 14ZM11 8L11 6L9 6L9 8L11 8Z"
      />
    </SVGIcon>
  );
};

IconInfoFilled.defaultProps = {
  color: 'base',
};

export default React.memo(IconInfoFilled);
