import React from 'react';
import SVGIcon from './index';

const IconTrash = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 24 24">
      <path d="M 10 3 L 9 4 L 5 4 L 5 6 L 7 6 L 17 6 L 19 6 L 19 4 L 15 4 L 14 3 L 10 3 z M 6 7 L 6 20 C 6 21.1 6.9 22 8 22 L 16 22 C 17.1 22 18 21.1 18 20 L 18 7 L 6 7 z" />
    </SVGIcon>
  );
};

export default IconTrash;
