import { useQuery } from 'react-query';
import { useFieldValue } from '../../../components/FormikFieldsV3/hooks';
import { getAddressByFiasId } from '../../../services/catalogService';
import { ROUTE_POINTS, ROUTE_ADDRESS_CITY, ROUTE_ADDRESS_STREET, ROUTE_ADDRESS_HOUSE } from '../blocks/RoutesBlock';

/* Получение координат для адресов без координат */
function useRoutePointCoordsFetcher(index) {
  const [routePointCity, setRoutePointCity] = useFieldValue(`${ROUTE_POINTS}[${index}].${ROUTE_ADDRESS_CITY}`);
  const [routePointStreet, setRoutePointStreet] = useFieldValue(`${ROUTE_POINTS}[${index}].${ROUTE_ADDRESS_STREET}`);
  const [routePointHouse, setRoutePointHouse] = useFieldValue(`${ROUTE_POINTS}[${index}].${ROUTE_ADDRESS_HOUSE}`);

  const enabled =
    (!!routePointCity && !routePointCity?.value?.lat) ||
    (!!routePointStreet && routePointStreet?.values?.lat) || // TODO тут бага (??)
    (!!routePointHouse && !routePointHouse?.value?.lat);
  const fiasId = routePointHouse?.value?.fias_id || routePointStreet?.values?.fias_id || routePointCity?.value?.fias_id;

  useQuery(['catalog', 'address', fiasId], () => getAddressByFiasId(fiasId), {
    enabled,
    onSuccess: ({ lat, lng, timezone_name }) => {
      setRoutePointCity({ ...routePointCity, value: { ...routePointCity.value, lat, lng, timezone_name } });
      if (!!routePointStreet)
        setRoutePointStreet({
          ...routePointStreet,
          value: { ...routePointStreet.value, lat, lng, timezone_name },
        });
      if (!!routePointHouse)
        setRoutePointHouse({ ...routePointHouse, value: { ...routePointHouse.value, lat, lng, timezone_name } });
    },
  });
  return;
}

export { useRoutePointCoordsFetcher };
