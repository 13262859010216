import { defaultMemoize, createSelector } from 'reselect';
import _get from 'lodash/get';
import * as queryKeys from '../queryKeys';
import * as pageSelectors from '../../page/selectors';
import * as statusKeys from './statusKeys';
import getFullCompanyName from '../../../helpers/getFullCompanyName';

export function getAddressString(addrObj, country) {
  const addressArray = [
    country,
    _get(addrObj, 'zip_code'),
    _get(addrObj, 'city'),
    _get(addrObj, 'street'),
    _get(addrObj, 'house_number'),
    _get(addrObj, 'room'),
  ];
  return addressArray.filter(Boolean).join(', ');
}

/**
 * Получение данных о компании в партнерстве
 */
export const getMappedCompanyData = defaultMemoize((company) => {
  const country = company?.country?.name ?? null;
  const opf = company?.opf?.name ?? null;
  const opfId = company?.opf?.id ?? null;
  const title = company?.title ?? null;
  const contacts = company?.contacts ?? null;

  return {
    humanFriendlyId: company?.human_friendly_id,
    companyId: company?.id,
    title,
    opf,
    opfId,
    titleWithOpf: getFullCompanyName(title, opf),

    kind: company?.kinds?.[0] ?? null,
    inn: company?.inn,
    kpp: company?.kpp,
    ogrn: company?.ogrn ?? null,

    partnerWebsite: company?.additional_info?.website_url ?? null,
    logoUrl: company?.logo_url,

    contacts,
    country,
    legalAddress: getAddressString(company?.legal_address, country),
    realAddress: getAddressString(company?.real_address, country),
    legalEqReal: company?.legal_address_eq_real ?? null,

    taxType: company?.tax_type ?? null,
    rating: company?.rating ?? null,
  };
});

/**
 * Получение данных о партнере
 */
export const getMappedPartnerItem = defaultMemoize((item, currentCompanyId) => {
  const isMyCompanyInviter = currentCompanyId === _get(item, 'inviter_company_id');
  const companyInfo = isMyCompanyInviter
    ? getMappedCompanyData(_get(item, 'invitee_company'))
    : getMappedCompanyData(_get(item, 'inviter_company'));
  return {
    isMyCompanyInviter,
    partnershipId: _get(item, 'id'),
    groups: _get(item, 'groups_ids', []),
    lastStatusUpdatedAt: _get(item, 'status_updated_at'),
    partnershipStatus: _get(item, 'status'),
    statusHistory: _get(item, 'status_history'),
    ...companyInfo,
  };
});

/**
 * Получение данных о найденной компании для партнерства
 */
export const getMappedPartnershipItem = defaultMemoize((item) => {
  const isMyCompanyInviter = _get(item, 'company.id') !== _get(item, 'partnership.inviter_company_id');
  const companyInfo = getMappedCompanyData(_get(item, 'company'));
  return {
    isMyCompanyInviter,
    partnershipId: _get(item, 'partnership.id'),
    lastStatusUpdatedAt: _get(item, 'partnership.status_updated_at'),
    partnershipStatus: _get(item, 'partnership.status', statusKeys.NO_PARTNERSHIP),
    statusHistory: _get(item, 'partnership.status_history'),
    ...companyInfo,
  };
});

/**
 * Получение количества партнеров
 */
export const getPartnersCount = defaultMemoize((state) => _get(state, 'partners.totalItems'));

/**
 * Получение количества активных фильтров
 */
export const getActiveFilterLength = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) => [query[queryKeys.GROUP]].filter(Boolean).length,
);

/**
 * Получение типа налогообложения партнера
 */
export const getPartnerTaxType = defaultMemoize((state) => _get(state, 'partners.activeItem.taxType'));

/**
 * Значение query для запроса заявок по графикам и перевозкам
 */
export const getPartnersQueryParams = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) => {
    return {
      [queryKeys.QUERY]: query[queryKeys.QUERY],
      [queryKeys.PAGE]: query[queryKeys.PAGE],
      [queryKeys.PER_PAGE]: query[queryKeys.PER_PAGE],
      [queryKeys.STATUS]: query[queryKeys.STATUS],
      [queryKeys.GROUP]: query[queryKeys.GROUP],
    };
  },
);

export const getPartnersList = defaultMemoize((state) => state?.partners?.items ?? []);

/**
 * Получение выбранного партнера
 */
export const getCurrentPartner = defaultMemoize((state) => state?.partners?.activeItem);
