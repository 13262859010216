import React from 'react';
import SVGIcon from './index';

function IconArrowBold(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M13.6487 11L3 11L3 9L13.6487 9L10.945 6.28182L12.1875 5L17 10L12.1875 15L10.9537 13.7182L13.6487 11Z" />
    </SVGIcon>
  );
}

IconArrowBold.defaultProps = {
  color: 'base',
};

export default IconArrowBold;
