import React from 'react';
import SVGIcon from './index';

export default function IconAwningWithTruck10(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 60 25">
      <path d="m54.1 2h-7.1v-2h-47v20h4v3h3.34a3.5 3.5 0 0 0 6.32 0h1.7a3.55 3.55 0 0 0 3.14 2 3.44 3.44 0 0 0 3.16-2h3.34v-3h15v3h7.34a3.5 3.5 0 0 0 6.32 0h3.84a2.53 2.53 0 0 0 2.5-2.5v-12.5c0-2.42-2.48-6-5.9-6zm-47.05 19h-1.05v-1h1.34a3.39 3.39 0 0 0 -.29 1zm3.45 2a1.5 1.5 0 1 1 1.5-1.5 1.49 1.49 0 0 1 -1.5 1.5zm4.55-2h-1.05a3.39 3.39 0 0 0 -.29-1h1.68a3.39 3.39 0 0 0 -.34 1zm3.45 2a1.52 1.52 0 0 1 -1.5-1.5 1.5 1.5 0 1 1 1.5 1.5zm4.5-2h-1a3.39 3.39 0 0 0 -.29-1h1.29zm-21-3v-16h43v16zm45.05 3h-5.05v-1h5.34a3.39 3.39 0 0 0 -.29 1zm3.45 2a1.5 1.5 0 1 1 1.5-1.5 1.49 1.49 0 0 1 -1.5 1.5zm7.5-2.5a.54.54 0 0 1 -.5.5h-3.5a3.45 3.45 0 0 0 -3.45-3h-3.55v-14h7.1c1.78 0 3.17 1.64 3.69 3h-8.79v4.9a3.09 3.09 0 0 0 2.9 3.1h6.1zm0-7.5h-6.1a1.1 1.1 0 0 1 -.9-1.1v-2.9h7z" />
    </SVGIcon>
  );
}

IconAwningWithTruck10.defaultProps = {
  color: 'base',
};
