import http from './config/webConfig';
import _uniqBy from 'lodash/uniqBy';
import {
  getAddressViewObject,
  // getBuildingViewObject,
  getCityViewObject,
  getFullAddressObject,
  getHouseViewObject,
  getStreetViewObject,
} from '../helpers/addressMapping';
import { REGION, FEDERAL_DISTRICT } from '../store/tools/marketPrices/formKeys';
import { getLocationVariantsForTariffs } from '../helpers/locationFormatter';
import { LEN_OBL_FIAS_ID, MSK_FIAS_ID, MSK_OBL_FIAS_ID, SPB_FIAS_ID } from '../constants';

// не нашел в react-select опции minLength или подобной
// для предотвращения запросов с пустым query
function _emptySearchImitation() {
  return Promise.resolve([]);
}

/**
 * Поиск адреса
 * @param params
 * @private
 */
async function _getSuggestAddress(params) {
  let url = 'catalog/suggest/address';
  let { data } = (await http.get(url, { params, requestId: url })) || {};

  return data;
}

/**
 * Получение информации по адресу по fias_id
 * @private
 * @param fiasId
 */
export async function getAddressByFiasId(fiasId) {
  const url = `catalog/address/${fiasId}`;
  const { data } = (await http.get(url, { requestId: url })) || {};

  return data;
}

/**
 * Получение информации по адресу по координатам
 * @private
 */
export async function getAddressByCoords(lat: number, lon: number, level?: string, radius_meters = 1000) {
  const url = `catalog/suggest/address_by_geolocate`;
  return http.get(url, { params: { lat, lon, radius_meters, level }, requestId: url }).then((res) => res.data);
}

/**
 * Получение результатов по улицам без дублей
 * @param results
 */
export async function getStreetWithoutCopies(results) {
  // Если улица с повтором - 1; Без повтора - 0
  const hashmap = new Map();

  results.forEach((street) => {
    if (hashmap.has(street.label)) {
      hashmap.set(street.label, 1);
    } else {
      hashmap.set(street.label, 0);
    }
  });

  return results.map((street) => ({
    ...street,
    label: hashmap.get(street.label)
      ? // если улица с повторами - показываем к чему она относится
        [street.value.settlement_with_type || street.value.city_with_type, street.label].filter(Boolean).join(', ')
      : street.label,
  }));
}

/**
 * Получения городов для автокомплитов форм
 * @param query
 * @returns {*}
 */
export async function getCity(query) {
  if (query.length === 0) return _emptySearchImitation();

  let params = {
    query,
    from_bound: 'city',
    to_bound: 'settlement',
    country_iso_code: 'RU', // для поиска по миру - '*',
  };

  let { items } = (await _getSuggestAddress(params)) || {};
  return _uniqBy(
    (items ?? []).map((item) => getCityViewObject(item)),
    'label',
  );
}

/**
 * Получения городов для автокомплитов форм
 * @param query
 * @returns {*}
 */
export async function getFullAddress(query) {
  if (query.length === 0) return _emptySearchImitation();

  let params = {
    query,
    from_bound: 'city',
    to_bound: 'house',
    country_iso_code: 'RU', // для поиска по миру - '*',
  };

  let { items } = (await _getSuggestAddress(params)) || {};

  return _uniqBy(
    (items ?? []).map((item) => getFullAddressObject(item)),
    'label',
  );
}

/**
 * Получение улиц
 * @param query
 * @param city
 */
export async function getStreet(query, city) {
  if (!query) return _emptySearchImitation();

  let params = {
    query: [city, query].join(' '),
    from_bound: 'street',
    to_bound: 'street',
  };
  let { items } = (await _getSuggestAddress(params)) || {};

  return (items ?? []).map((item) => getAddressViewObject(item.street, item.street_type, item));
}

/**
 * Получение улиц для РФ (по фиас)
 * @param query
 * @param fias
 * @param level
 */
export async function getStreetByFias(query: string, fias: string, level: string = 'parent') {
  if (!query) return _emptySearchImitation();

  let params = {
    query,
    from_bound: 'street',
    to_bound: 'street',
    [`${level}_fias_id`]: fias,
  };
  let { items } = (await _getSuggestAddress(params)) || {};

  return items.map((item) => getStreetViewObject(item));
}

/**
 * Получаем дома
 * @param query
 * @param city
 * @param street
 */
export async function getHouse(query, city, street) {
  if (query.length === 0) return _emptySearchImitation();
  const _city = city?.split(', ').reverse().join(', ') ?? null;
  let params = {
    query: [_city, street, query].filter(Boolean).join(', '),
    from_bound: 'house',
    to_bound: 'house',
  };

  let { items } = (await _getSuggestAddress(params)) || {};
  return (items ?? []).map((item) => getHouseViewObject(item));
}
/**
 * Получение домов для РФ (по фиас)
 * @param query
 * @param city
 * @param street
 * @param fias
 * @param level
 */
export async function getHouseByFias(query, city, street, fias, level: string = 'parent') {
  if (query.length === 0) return _emptySearchImitation();
  const _city = city?.split(', ').reverse().join(', ') ?? null;
  let params = {
    query: [_city, street, query].filter(Boolean).join(', '),
    from_bound: 'house',
    to_bound: 'house',
    [`${level}_fias_id`]: fias,
  };

  let { items } = (await _getSuggestAddress(params)) || {};

  let hash = items.reduce((acc, curr) => {
    acc[curr.house] = curr;
    return acc;
  }, {});

  let UniqItems = Object.values(hash);

  return (UniqItems ?? []).map((item) => getHouseViewObject(item));
}

export const getLocationByType = (locationType) => (query) => {
  if (query.length === 0 && locationType === 'CITY') {
    return _emptySearchImitation();
  }

  const params = {
    query,
    from_bound: locationType.toLowerCase(),
    to_bound: locationType.toLowerCase() === 'city' ? 'settlement' : locationType.toLowerCase(),
  };

  //-------------------------------------------------------------------------------------
  // TODO: убрать данный хардкод после подготовки обшего ендпоинта по гео поиску
  // BEGIN
  //-------------------------------------------------------------------------------------
  if (locationType === FEDERAL_DISTRICT) {
    return _getAreas(query);
  }
  //-------------------------------------------------------------------------------------
  // END
  //-------------------------------------------------------------------------------------

  return _getSuggestAddress(params).then((data) =>
    _uniqBy(
      data.items.map((item) => getCityViewObject(item)),
      'value.fias_id',
    ),
  );
};

export const getLocationForPartnerTariffs = (query) => {
  const saintPetersburgRegionFiasIds = [SPB_FIAS_ID, LEN_OBL_FIAS_ID];
  const moscowRegionFiasIds = [MSK_FIAS_ID, MSK_OBL_FIAS_ID];
  let params = {
    query,
    from_bound: REGION.toLowerCase(),
    to_bound: REGION.toLowerCase(),
  };

  return _getSuggestAddress(params)
    .then((data) =>
      data.items.map((item) => {
        return {
          ...getCityViewObject(item),
          label: getLocationVariantsForTariffs(item).details,
        };
      }),
    )
    .then((data) => {
      const fiasIds = data.map((item) => item.value.fias_id);
      let idsToDelete: string[] = [];
      if (saintPetersburgRegionFiasIds.every((i) => fiasIds.includes(i))) {
        idsToDelete.push(SPB_FIAS_ID);
      }
      if (moscowRegionFiasIds.every((i) => fiasIds.includes(i))) {
        idsToDelete.push(MSK_FIAS_ID);
      }
      return JSON.parse(JSON.stringify(data)).filter((i) => !idsToDelete.includes(i.value.fias_id));
    });
};

/**
 * Поиск по Предложениям
 * @param query
 * @param limit
 * Deprecated
 */
export async function getLocationCity(query, limit = 10) {
  const url = 'catalog/locations/search';
  const params = { q: query, limit };

  let {
    data: { items },
  } = (await http.get(url, { params, requestId: url })) ?? {};

  return items.map((item) => {
    return {
      label: item?.value,
      value: { ...item },
    };
  });
}

/**
 * Получение всех возможных пар тоннаж/объем
 * @param transportType
 * @param transportBodyType
 * @returns {*}x
 */
export function getLiftingCapacity(transportBodyType, transportType) {
  const url = 'catalog/lifting_capacity';
  const params = {};
  if (transportBodyType) {
    params['transport_body_type'] = transportBodyType;
  }
  if (transportType) {
    params['transport_type'] = transportType;
  }
  return http.get(url, { params, requestId: url }).then((response) => {
    return response?.data?.items;
  });
}

/**
 * Получение ИНН
 * @param inn
 * @param require_opf
 */
export async function getCompanyByInn(inn, require_opf = true) {
  let url = 'catalog/company/info';
  let { data } = await http.get(url, {
    params: { inn, require_opf },
    requestId: url,
  });

  return data;
}

/**
 * Получение ИНН
 * @param inn
 */
export async function getCompaniesByInn(inn: string) {
  const url = 'catalog/company/all_by_inn';
  const { data } = await http.get(url, {
    params: { inn },
    requestId: url,
  });
  return data;
}

/**
 * Получение по коду подразделения субъектов, выдавших паспорт
 * @param subdivision_code
 */
export async function getWhoIssuedByCode(subdivision_code: string) {
  const url = 'catalog/passport/who_issued_by_code';
  const { data } = await http.get(url, {
    params: { subdivision_code },
    requestId: url,
  });
  return data;
}

/* Получение всех типов загрузки */
export async function getAllLiftingCapacity() {
  let url = 'catalog/lifting_capacity';
  let {
    data: { items },
  } = (await http.get(url, { requestId: url })) ?? {};

  return items;
}

/**
 * Получение справочника грузов
 * @return {*}
 */
export async function getCargoList() {
  const url = `/catalog/cargo`;
  return http.get(url).then((response) => response.data?.items);
}

export async function getParsedAddress(query) {
  const url = '/catalog/clean/address';
  const params = {
    query,
  };
  return http.get(url, { params }).then((response) => response.data?.items?.[0]);
}

//-------------------------------------------------------------------------------------
// TODO: убрать данный хардкод после подготовки обшего ендпоинта по гео поиску
// BEGIN
//-------------------------------------------------------------------------------------
async function _getAreas(query) {
  return [
    {
      name: 'Центральный Федеральный Округ',
      abbrev: 'ЦФО',
      id: 'd151b8ae-8ded-11e9-9ebb-506b8df91bb5',
    },
    {
      name: 'Северо-Западный Федеральный Округ',
      abbrev: 'СЗФО',
      id: '13be0ee0-8dee-11e9-9ebb-506b8df91bb5',
    },
    {
      name: 'Приволжский Федеральный Округ',
      abbrev: 'ПФО',
      id: '3370c5c0-8dee-11e9-9ebb-506b8df91bb5',
    },
    {
      name: 'Уральский Федеральный Округ',
      abbrev: 'УФО',
      id: '6b00ef42-8dee-11e9-9ebb-506b8df91bb5',
    },
    {
      name: 'Южный Федеральный Округ',
      abbrev: 'ЮФО',
      id: '260670a6-8dee-11e9-9ebb-506b8df91bb5',
    },
    {
      name: 'Дальневосточный Федеральный Округ',
      abbrev: 'ДФО',
      id: '4ca95f84-8dee-11e9-9ebb-506b8df91bb5',
    },
    {
      name: 'Северо-Кавказский Федеральный Округ',
      abbrev: 'СКФО',
      id: '59ff6ab6-8dee-11e9-9ebb-506b8df91bb5',
    },
    {
      name: 'Сибирский Федеральный Округ',
      abbrev: 'СФО',
      id: '3ecb15ce-8dee-11e9-9ebb-506b8df91bb5',
    },
  ]
    .filter(
      (item) =>
        item.name.toLowerCase().includes(query?.toLowerCase() ?? '') ||
        item.abbrev.toLowerCase().includes(query?.toLowerCase() ?? ''),
    )
    .map(mapAreaToLocation)
    .map((item) => ({
      label: item.value,
      value: {
        ...item,
      },
    }));
}

function mapAreaToLocation(area) {
  return {
    country: 'Россия',
    region: '',
    region_type: '',
    region_fias_id: '',
    area: null,
    area_type: area.abbrev,
    area_fias_id: area.id,
    city: '',
    city_type: '',
    city_fias_id: '',
    settlement: null,
    settlement_type: null,
    settlement_fias_id: null,
    street: null,
    street_type: null,
    street_fias_id: null,
    house_number: null,
    house_type: null,
    house_fias_id: null,
    block: null,
    block_type: null,
    fias_id: area.id,
    lat: null,
    lng: null,
    zip_code: null,
    time_zone: null,
    value: area.name,
  };
}
//-------------------------------------------------------------------------------------
// END
//-------------------------------------------------------------------------------------
