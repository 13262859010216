import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import * as queryKeys from '../queryKeys';
import * as formFields from './formFields';
import { formValueSelector } from 'redux-form';
import { createSelector, defaultMemoize } from 'reselect';
import * as pageSelectors from '../../page/selectors';
import { DESC, TIME_FORMAT } from '../../../constants';
import { minutesValueFromTimeStr } from '../../../helpers/dateTimeTools';

const formResourcesSelectors = formValueSelector(formFields.RESOURCES_FROM);

/**
 * Валидна ли строка поиска
 */
export const getIsValidQuery = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) => {
    try {
      const queryStatus = query[queryKeys.STATUS];
      return queryKeys.ALL_STATUSES.some(({ value }) => value === queryStatus);
    } catch (error) {
      return false;
    }
  },
);

/**
 * Получение значения об активности фильтров
 */
export const getIsFilterActive = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) =>
    [
      query[queryKeys.HUMAN_FRIENDLY_ID],
      query[queryKeys.FROM_LOCATION_ID],
      query[queryKeys.FROM_LOCATION_LABEL],
      query[queryKeys.FROM_RADIUS],
      query[queryKeys.DIRECTION_LOCATION_ID],
      query[queryKeys.DIRECTION_LOCATION_LABEL],
      query[queryKeys.DIRECTION_RADIUS],
      query[queryKeys.DATE_FROM],
      query[queryKeys.DATE_UNTIL],
      query[queryKeys.ONLY_REQUESTS_TYPE],
      query[queryKeys.ONLY_GRAPHICS_TYPE],
    ].some(Boolean),
);

/**
 * Получение количества выбранных фильтров в попапе
 */
export const getActiveFilterLength = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) => {
    return (
      [
        query[queryKeys.ONLY_REQUESTS_TYPE],
        query[queryKeys.ONLY_GRAPHICS_TYPE],
        query[queryKeys.DISTANCE_REQUEST_TYPE] !== queryKeys.ALL_REQUESTS,
        query[queryKeys.PAYMENT_STATUS] === queryKeys.WAITING_PAYMENT_OBJ.value,
        query[queryKeys.PAYMENT_STATUS] === queryKeys.PAID_OBJ.value,
      ].filter(Boolean).length +
      Number(_isEqual(query[queryKeys.SCANCOPY_STATUS]?.sort(), queryKeys.NEED_TO_SIGN_SCANCOPIES_OBJ.value.sort()))
    );
  },
);

/**
 * Получение значения выбранного типа заявок в попапе
 */
export const getSelectedRequestTypesValues = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) => {
    const onlyRegular = query[queryKeys.ONLY_REQUESTS_TYPE];
    const onlyByContract = query[queryKeys.ONLY_GRAPHICS_TYPE];
    if (!onlyRegular && !onlyByContract) return null;
    if (onlyRegular) return queryKeys.ONLY_REQUESTS_TYPE;
    return queryKeys.ONLY_GRAPHICS_TYPE;
  },
);

/**
 * Значение query для запроса заявок по графикам и перевозкам
 */
export const getContractsRequestParams = createSelector(
  (state) => pageSelectors.getQuery(state),
  (query) => {
    // только для отмененных перевозок меняем порядок (16.07.23 - зачем?)
    // const order = query[queryKeys.STATUS] === queryKeys.CANCELED ? DESC : ASC;
    /* TODO: вынести в хелпер и из order selector */
    //---------------------------------------
    const status = query[queryKeys.STATUS];
    const subStatuses = query[queryKeys.SUB_STATUS] || [];
    let filterStatus;

    if (subStatuses.length > 0) {
      filterStatus = subStatuses;
    } else if (Array.isArray(queryKeys.TREE_STATUSES[status])) {
      filterStatus = queryKeys.TREE_STATUSES[status].map(({ value }) => value);
      // TODO: подумать, как сделать нормально
      if (!!query[queryKeys.ASSIGN_RESOURCES] && status === queryKeys.AT_CLEARANCE) {
        filterStatus = filterStatus.filter((item) => item === queryKeys.RESOURCES_WAITING);
      }
    } else {
      filterStatus = [status];
    }

    const type =
      query[queryKeys.DISTANCE_REQUEST_TYPE] !== queryKeys.ALL_REQUESTS
        ? query[queryKeys.DISTANCE_REQUEST_TYPE] === queryKeys.CITY_REQUESTS
          ? ['delivery', 'city_delivery']
          : ['shipping_request']
        : ['shipping_request', 'delivery', 'city_delivery'];

    const scancopyStatus = !_isEqual(query[queryKeys.SCANCOPY_STATUS], [queryKeys.ALL_SCANCOPY_STATUSES])
      ? { [queryKeys.SCANCOPY_STATUS]: query[queryKeys.SCANCOPY_STATUS] }
      : {};
    const queryPaymentStatus = query[queryKeys.PAYMENT_STATUS];
    const paymentStatus =
      !!queryPaymentStatus && queryPaymentStatus !== queryKeys.ALL_PAYMENTS_OBJ.value
        ? {
            ...(queryPaymentStatus === queryKeys.WAITING_PAYMENT_OBJ.value && {
              waiting_for_payment: true,
            }),
            ...(queryPaymentStatus === queryKeys.PAID_OBJ.value && {
              paid: true,
            }),
          }
        : {};

    //---------------------------------------
    return {
      shipping_request_type: type,
      query: query[queryKeys.HUMAN_FRIENDLY_ID],
      page: query[queryKeys.PAGE],
      per_page: query[queryKeys.PER_PAGE] || 20,
      from_location_id: query[queryKeys.FROM_LOCATION_ID],
      from_radius: query[queryKeys.FROM_RADIUS],
      direction_location_id: query[queryKeys.DIRECTION_LOCATION_ID],
      direction_radius: query[queryKeys.DIRECTION_RADIUS],
      [queryKeys.FINAL_LOCATION_ID]: query[queryKeys.FINAL_LOCATION_ID],
      [queryKeys.FINAL_LOCATION_RADIUS]: query[queryKeys.FINAL_LOCATION_RADIUS],
      begin_from: query[queryKeys.DATE_FROM],
      begin_until: query[queryKeys.DATE_UNTIL],
      status: filterStatus,
      ...scancopyStatus,
      ...paymentStatus,
      closing_document_errors: query[queryKeys.CLOSING_DOCUMENT_ERRORS] || undefined,
      // отображенение заказов по брони ТС
      // include_by_reservation_request: true,
      sort: query[queryKeys.SORT],
      order: query[queryKeys.ORDER] || DESC,
      only_by_contract: !!query[queryKeys.ONLY_GRAPHICS_TYPE] || null,
      only_regular: !!query[queryKeys.ONLY_REQUESTS_TYPE] || null,
      assign_resources: !!query[queryKeys.ASSIGN_RESOURCES] || null,
      agree_on_change_request: !!query[queryKeys.AGREE_ON_CHANGE_REQUEST] || null,
      need_to_sign_scancopy: !!query[queryKeys.NEED_TO_SIGN_SCANCOPY] || null,
      need_of_attorney: !!query[queryKeys.NEED_OF_ATTORNEY] || null,
    };
  },
);

export const getShippingResourcesFromForm = (values) => {
  const driverList =
    values?.[formFields.DRIVER_FIELD]?.length > 0
      ? values?.[formFields.DRIVER_FIELD].map((driver) => _get(driver, 'value.id', undefined))
      : [];
  return {
    assigned_resources: [
      {
        route_segment_id: 1,
        driver_contact_info: values?.[formFields.PHONE_FIELD] || null,
        drivers_ids: driverList,
        car_id: _get(values?.[formFields.CAR_FIELD], 'value.id', null),
        trailer_id: _get(values?.[formFields.TRAILER_FIELD], 'value.id', null),
      },
    ],
    document_flow: values?.[formFields.DOCUMENT_FLOW],
  };
};

/**
 * Мапинг формы назначения дат
 */
export const getShippingDatesFromForm = defaultMemoize((state) => _get(state, `form.${formFields.DATES_FORM}.values`));

/**
 * Получение телефона водителя
 */
export const getPhone = defaultMemoize((state) => formResourcesSelectors(state, formFields.PHONE_FIELD));

/**
 * Получение даты прибытия на погрузку для смены ресурсов
 */
export const getDate = defaultMemoize((state) => formResourcesSelectors(state, formFields.DATE_FIELD));

/**
 * Получение списка выбранных водителей
 */
export const getDrivers = createSelector(
  (state) => formResourcesSelectors(state, formFields.DRIVER_FIELD),
  (drivers) =>
    Array.isArray(drivers)
      ? drivers.reduce((acc, driver, index) => {
          acc[index] = _get(driver, 'value') || null;
          return acc;
        }, [])
      : [],
);

/**
 * Получение начальных значений для формы ресурсов
 */
export const getChangeResourcesFormInitialValues = createSelector(
  (state) => state,
  (shipping) => {
    const {
      assignResourcesObj: { car, trailer, phone, driver = [undefined] } = {},
      selectedSupply,
      _original,
    } = shipping;
    const { date, time } = selectedSupply?.[0];
    const originalSelectedSupply = _sortBy(_original.selected_car_supply, ['route_point_id'])[0];
    const timeValue = minutesValueFromTimeStr(time, TIME_FORMAT);
    const isFirstPointWarehouse = !!shipping._original.shipping_request_info.route_points[0].warehouse_id;
    const timeField = isFirstPointWarehouse
      ? {
          from: originalSelectedSupply.car_supply_at,
          till: originalSelectedSupply.car_supply_to,
          gateId: originalSelectedSupply.gate_id,
        }
      : {
          label: time,
          value: timeValue,
        };

    return {
      [formFields.CAR_FIELD]: car,
      [formFields.PHONE_FIELD]: phone,
      [formFields.DRIVER_FIELD]: driver,
      [formFields.TRAILER_FIELD]: trailer,
      [formFields.DATE_FIELD]: date,
      [formFields.TIME_FIELD]: timeField,
      [formFields.INN_FIELD]: driver?.map((item) => item?.value?.inn),
    };
  },
);

/**
 * Получение текущего значения способа оформления документов
 */
export const getCurrentDocumentFlow = createSelector(
  (state) => formResourcesSelectors(state, formFields.DOCUMENT_FLOW),
  (type) => type,
);
