import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IProps {
  color?: string;
  className?: string;
  width?: number;
  height?: number;
  style?: any;
}

const IconLoading = (props: IProps) => {
  const { className, width, height, color, style } = props;
  return (
    <div className={classNames(styles.ring, className)} style={{ width, height, ...style }}>
      <div className={styles[`border-${color}`]} />
      <div className={styles[`border-${color}`]} />
      <div className={styles[`border-${color}`]} />
      <div className={styles[`border-${color}`]} />
    </div>
  );
};

IconLoading.defaultProps = {
  color: 'primary',
};

export default IconLoading;
