import { useRef } from 'react';
import _isEqual from 'lodash/isEqual';

export function useDeepMemo<TKey, TValue>(memoFn: () => TValue, key: TKey): TValue {
  const ref = useRef<{ key: TKey; value: TValue }>();

  if (!ref.current || !_isEqual(key, ref.current.key)) {
    ref.current = { key, value: memoFn() };
  }

  return ref.current.value;
}

export function useDeepMemoValue<TKey>(value: TKey): TKey {
  return useDeepMemo<TKey, TKey>(() => value, value);
}
