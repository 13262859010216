import * as types from './actionTypes';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

const initialState = null;

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    case types.UPDATE_USER_DATA:
      return { ...draft, ...action.userData };

    case types.CLEAR_USER_DATA:
      return initialState;

    case types.UPDATE_DOCS_CONFIRMING:
      return { ...draft, confirmedDocs: action.data.agreed_documents };

    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
