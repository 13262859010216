import {
  APPROVAL_WAITING,
  AT_CLEARANCE,
  CANCELED,
  CANCELED_BY_CARGO_OWNER,
  CANCELED_BY_NTK,
  COMPLETED,
  DONE,
  FAILED_BY_CARGO_OWNER,
  FAILED_BY_NTK,
  IN_WAY,
  RESOURCES_WAITING,
  TRIP_WAITING,
} from '../suggestions/queryKeys';

export const STATUS_TO_SUGGESTION_TAB = {
  [TRIP_WAITING]: TRIP_WAITING,

  [RESOURCES_WAITING]: AT_CLEARANCE,
  [APPROVAL_WAITING]: AT_CLEARANCE,

  [IN_WAY]: IN_WAY,

  [DONE]: COMPLETED,
  [CANCELED]: COMPLETED,
  [CANCELED_BY_CARGO_OWNER]: COMPLETED,
  [CANCELED_BY_NTK]: COMPLETED,
  [FAILED_BY_CARGO_OWNER]: COMPLETED,
  [FAILED_BY_NTK]: COMPLETED,
};

/**
 * Вернуть подходящий под статус таб
 * @param status
 */
export function getRelevantTabForStatus(status: string) {
  return STATUS_TO_SUGGESTION_TAB[status] ?? TRIP_WAITING;
}
