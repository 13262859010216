import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

import IconInfo from '../Icons/SVGIcons/IconInfo';
import IconAlertCircle from '../Icons/SVGIcons/IconAlertCircle';
import IconCheckCircle from '../Icons/SVGIcons/IconCheckCircle';

export type TTheme =
  | 'default'
  | 'info-lightest'
  | 'success'
  | 'warning'
  | 'danger'
  | 'danger-bordered'
  | 'white'
  | 'white-bordered'
  | 'white-primary-bordered'
  | 'primary';

type SIZES = 'small' | 'medium';

interface NotificationProps {
  header?: string | React.ReactNode;
  text?: string | React.ReactNode;
  comment?: string | React.ReactNode;
  additional?: string | React.ReactNode;
  icon?: string | React.ReactNode;
  theme?: TTheme;
  children?: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
  small?: boolean;
  size?: SIZES;
  style?: Object;
}

function NotificationBar({
  theme = 'default',
  text,
  icon,
  header,
  comment,
  additional,
  children,
  description,
  className,
  small,
  size,
  style,
}: NotificationProps) {
  const notificationClassName = classNames(
    'block',
    styles.notification,
    [styles[`${theme}`]],
    {
      [styles.small]: small,
    },
    [styles[size || '']],
    className,
  );
  const notificationIcon = ICONS.get(theme) || '';
  return (
    <div className={notificationClassName} style={style}>
      <span className={styles.icon}>{icon ?? notificationIcon}</span>
      <div className={styles.content}>
        {header && <h5 className="subtitle mt-0 mb-1 ml-2">{header}</h5>}
        {text && <p className="ml-2 mt-0 mb-1">{text}</p>}
        {comment && <p className="ml-2 mt-0 mb-1 comment">{comment}</p>}
        {additional && <p className="ml-2 mt-0 mb-1">{additional}</p>}
        {description}
      </div>
      {children}
    </div>
  );
}

const ICONS = new Map()
  .set('default', <IconInfo width="20" height="20" color="base" />)
  .set('info-lightest', <IconInfo width="20" height="20" color="base" />)
  .set('white', <IconInfo width="20" height="20" color="base" />)
  .set('white-bordered', <IconInfo width="20" height="20" color="primary" />)
  .set('white-primary-bordered', <IconInfo width="20" height="20" color="primary" />)
  .set('warning', <IconAlertCircle width="20" height="20" color="warning" />)
  .set('success', <IconCheckCircle width="20" height="20" color="success" />)
  .set('danger', <IconAlertCircle width="20" height="20" color="danger" />)
  .set('danger-bordered', <IconAlertCircle width="20" height="20" color="danger" />)
  .set('primary', <IconInfo width="20" height="20" color="primary" />);

export default React.memo(NotificationBar);
