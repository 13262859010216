import React from 'react';
import SVGIcon from './index';

const IconExcelDownload = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3L2 5V15L10 17V3ZM5.1276 7H3.64648L5.21712 9.99684L3.5 13H4.94694L5.88281 11.0601C5.94531 10.8746 5.9873 10.7363 6.00814 10.644H6.02116C6.05533 10.8368 6.09684 10.9754 6.13184 11.0443L7.05957 13H8.5L6.83984 9.9731L8.4528 7H7.10189L6.24414 8.78006C6.15997 9.01095 6.104 9.18795 6.0765 9.3038H6.05534C6.00701 9.11099 5.95996 8.94094 5.89746 8.7943L5.1276 7Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M17 5H11V6H13V8H11V9H13V11H14V9H16V14H17V5ZM14 6H16V8H14V6Z" />
      <path d="M11 11H13L13 12H11V11Z" />
      <path d="M14 12H15V15H17L14.5 18L12 15H14L14 12Z" />
    </SVGIcon>
  );
};

export default IconExcelDownload;
