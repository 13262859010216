import http from './config/webConfig';

import { TListState } from '../store/models';
import { TCounterAgent, TGateDaySchedule, TLocation, TPickupInterval } from '../store/counterAgents/models';
import pageListMapper from '../helpers/mappers/pageListMapper';

/**
 * Создание адреса
 */
export async function createLocation(counterAgentId, params): Promise<TLocation> {
  const url = `/counter_agents/${counterAgentId}/locations`;
  return http.post(url, params, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получение адреса
 */
export async function getLocation(id: string): Promise<TLocation> {
  const url = `/counter_agents/locations/${id}`;
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получение списка адресов
 */
export async function getLocations(params, cache = false): Promise<TListState<TLocation>> {
  const url = `/counter_agents/locations`;
  return http.get(url, { params, cache, requestId: url }).then(pageListMapper);
}

/**
 * Отметка о том, что адрес был выбран
 */
export async function pickLocation(id: string): Promise<TListState<TLocation>> {
  const url = `/counter_agents/locations/${id}/pick`;
  return http.post(url, {}, { requestId: url }).catch(() => Promise.resolve());
}

/**
 * Обновление адреса
 */
export async function updateLocation(id: string, params): Promise<TLocation> {
  const url = `/counter_agents/locations/${id}`;
  return http.put(url, params, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Удаление адреса
 */
export async function removeLocation(id: string) {
  const url = `/counter_agents/locations/${id}`;
  return http.delete(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получение списка контрагентов
 * @returns {Promise<*>}
 */
export async function getCounterAgents(params): Promise<TListState<TCounterAgent>> {
  const url = '/counter_agents';
  return http.get(url, { params, cache: false, requestId: url }).then(pageListMapper);
}

/**
 * Создание контр-агента
 * @param params
 */
export async function createCounterAgent(params): Promise<TCounterAgent> {
  const url = '/counter_agents';
  return http.post(url, params, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получение контр-агента
 * @param id
 */
export async function getCounterAgent(id: string): Promise<TCounterAgent> {
  const url = `/counter_agents/${id}`;
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Обновление контр-агента
 * @param params
 */
export async function updateCounterAgent(params): Promise<TCounterAgent> {
  const url = `/counter_agents/${params.id}`;
  return http.put(url, params, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Удаление контрагента
 * @param id
 */
export async function removeCounterAgent(id: string) {
  const url = `/counter_agents/${id}`;
  return http.delete(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Создание фактического расписания
 * @param warehouseId
 * @param params
 * @returns
 */
export async function createRealSchedule(warehouseId: string, params) {
  const url = `/counter_agents/locations/${warehouseId}/set_gate_schedules`;
  return http.post(url, params, { cache: false, requestId: url }).then((response) => response.data);
}

export async function getDaySchedule(warehouseId: string, params): Promise<{ items: TGateDaySchedule[] }> {
  const url = `/counter_agents/locations/${warehouseId}/warehouse_details`;
  return http.get(url, { params, cache: false, requestId: url }).then((response) => response.data);
}

export async function updateBusySlots(warehouseId: string, params): Promise<{ items: TGateDaySchedule[] }> {
  const url = `/counter_agents/locations/${warehouseId}/gates`;
  return http.put(url, params, { cache: false, requestId: url }).then((response) => response.data);
}

export async function getPickupAvailableIntervals(pickupId: string): Promise<{ items: TPickupInterval[] }> {
  const url = `/pickup/${pickupId}/free_intervals`;
  return http.get(url, { requestId: url }).then((response) => response.data);
}

export async function getPickupInfo(pickupId: string) {
  const url = `/pickup/${pickupId}`;
  return http.get(url, { requestId: url }).then((response) => response.data);
}

export async function checkPickup(pickupId: string, params) {
  const url = `/pickup/${pickupId}`;
  return http.post(url, params, { requestId: url }).then((response) => response.data);
}

export async function bookPickupRequest(pickupId: string, params) {
  const url = `/pickup/${pickupId}/booking`;
  return http.post(url, params, { requestId: url }).then((response) => response.data);
}

export async function updatePickupRequest(pickupId: string, params) {
  const url = `/pickup/${pickupId}`;
  return http.put(url, params, { requestId: url }).then((response) => response.data);
}

export async function removePickupRequest(pickupId: string) {
  const url = `/pickup/${pickupId}`;
  return http.delete(url, { requestId: url }).then((response) => response.data);
}

export async function createPickupRequest<T>(warehouseId: string, params: T) {
  const url = `/counter_agents/locations/${warehouseId}/pickup_request`;
  return http.post(url, params, { cache: false, requestId: url }).then((response) => response.data);
}
