import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import classNames from 'classnames';

import IconLoading from '../Icons/CSSIcons/IconLoading/IconLoading';

const SIZES = {
  L: 'large',
  S: 'small',
};
type TSizes = 'large' | 'small';
const ICON_SIZE_PARAMS = {
  [SIZES.L]: {
    width: 40,
    height: 40,
  },
  [SIZES.S]: {
    width: 20,
    height: 20,
  },
};

interface LoaderProps {
  withPortal?: boolean;
  withBackground?: boolean;
  local?: boolean;
  size: TSizes;
}

const ContentLoader = ({ size, withBackground, local, withPortal }: LoaderProps) => {
  let rootElemRef = useRef<HTMLSpanElement>(null!);

  useEffect(() => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('span');
    }
    document.body.appendChild(rootElemRef.current);

    return () => rootElemRef.current.remove();
  }, []);

  let loadingContent = (
    <section
      className={classNames(styles.loadingWrapper, {
        [local ? styles.loadingBackground_local : styles.loadingBackground_global]: withBackground,
      })}
    >
      <IconLoading {...ICON_SIZE_PARAMS[size]} className={styles.loadingIcon} />
    </section>
  );

  return withPortal && rootElemRef.current
    ? ReactDOM.createPortal(loadingContent, rootElemRef.current)
    : loadingContent;
};

ContentLoader.defaultProps = {
  size: SIZES.L,
};

export default React.memo(ContentLoader);
