import * as types from './actionTypes';
import { DRIVERS, CARS, TRAILERS } from './formKeys';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

const initialState = {
  [DRIVERS]: {
    items: [],
    activeItem: null,
    recognizedDocsData: [],
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    resultsPerPage: 20,
    wasInitialized: false,
  },
  [CARS]: {
    items: [],
    activeItem: null,
    recognizedDocsData: [],
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    resultsPerPage: 20,
    wasInitialized: false,
  },
  [TRAILERS]: {
    items: [],
    activeItem: null,
    recognizedDocsData: [],
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    resultsPerPage: 20,
    wasInitialized: false,
  },
  availableLifting: [],
  availableBrands: [],
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    case types.INIT_DRIVERS:
      draft[DRIVERS].items = action.payload.items;
      draft[DRIVERS].totalItems = action.payload.totalItems;
      draft[DRIVERS].currentPage = action.payload.currentPage;
      draft[DRIVERS].totalPages = action.payload.totalPages;
      draft[DRIVERS].resultsPerPage = action.payload.resultsPerPage;
      draft[DRIVERS].wasInitialized = true;
      break;

    case types.LOAD_MORE_DRIVERS:
      draft[DRIVERS].items = draft[DRIVERS].items.concat(action.payload.items);
      draft[DRIVERS].totalItems = action.payload.totalItems;
      draft[DRIVERS].currentPage = action.payload.currentPage;
      draft[DRIVERS].totalPages = action.payload.totalPages;
      draft[DRIVERS].resultsPerPage = action.payload.resultsPerPage;
      draft[DRIVERS].wasInitialized = true;
      break;

    case types.UPDATE_DRIVER:
      draft[DRIVERS].activeItem = action.payload;
      break;

    case types.RECOGNIZE_DRIVER_DOCS:
      draft[DRIVERS].recognizedDocsData = draft[DRIVERS].recognizedDocsData.concat(action.payload);
      break;

    case types.DELETE_RECOGNIZED_DRIVER_DOCS:
      draft[DRIVERS].recognizedDocsData = draft[DRIVERS].recognizedDocsData.filter(
        (doc) => doc.id !== action.payload,
        // (doc) => doc.id !== action.payload && doc.fileId !== action.payload,
      );
      break;

    case types.CLEAR_RECOGNIZED_DRIVER_DOCS:
      draft[DRIVERS].recognizedDocsData = [];
      break;

    case types.INIT_CARS:
      draft[CARS].items = action.payload.items;
      draft[CARS].totalItems = action.payload.totalItems;
      draft[CARS].currentPage = action.payload.currentPage;
      draft[CARS].totalPages = action.payload.totalPages;
      draft[CARS].resultsPerPage = action.payload.resultsPerPage;
      draft[CARS].wasInitialized = true;
      break;

    case types.LOAD_MORE_CARS:
      draft[CARS].items = draft[CARS].items.concat(action.payload.items);
      draft[CARS].totalItems = action.payload.totalItems;
      draft[CARS].currentPage = action.payload.currentPage;
      draft[CARS].totalPages = action.payload.totalPages;
      draft[CARS].resultsPerPage = action.payload.resultsPerPage;
      draft[CARS].wasInitialized = true;
      break;

    case types.UPDATE_CAR:
      draft[CARS].activeItem = action.payload;
      break;

    case types.RECOGNIZE_CAR_DOCS:
      draft[CARS].recognizedDocsData = draft[CARS].recognizedDocsData.concat(action.payload);
      break;

    case types.DELETE_RECOGNIZED_CAR_DOCS:
      draft[CARS].recognizedDocsData = draft[CARS].recognizedDocsData.filter((doc) => doc.id !== action.payload);
      break;

    case types.CLEAR_RECOGNIZED_CAR_DOCS:
      draft[CARS].recognizedDocsData = [];
      break;

    case types.INIT_TRAILERS:
      draft[TRAILERS].items = action.payload.items;
      draft[TRAILERS].totalItems = action.payload.totalItems;
      draft[TRAILERS].currentPage = action.payload.currentPage;
      draft[TRAILERS].totalPages = action.payload.totalPages;
      draft[TRAILERS].resultsPerPage = action.payload.resultsPerPage;
      draft[TRAILERS].wasInitialized = true;
      break;

    case types.LOAD_MORE_TRAILERS:
      draft[TRAILERS].items = draft[TRAILERS].items.concat(action.payload.items);
      draft[TRAILERS].totalItems = action.payload.totalItems;
      draft[TRAILERS].currentPage = action.payload.currentPage;
      draft[TRAILERS].totalPages = action.payload.totalPages;
      draft[TRAILERS].resultsPerPage = action.payload.resultsPerPage;
      draft[TRAILERS].wasInitialized = true;
      break;

    case types.UPDATE_TRAILER:
      draft[TRAILERS].activeItem = action.payload;
      break;

    case types.RECOGNIZE_TRAILER_DOCS:
      draft[TRAILERS].recognizedDocsData = draft[TRAILERS].recognizedDocsData.concat(action.payload);
      break;

    case types.DELETE_RECOGNIZED_TRAILER_DOCS:
      draft[TRAILERS].recognizedDocsData = draft[TRAILERS].recognizedDocsData.filter(
        (doc) => doc.id !== action.payload,
      );
      break;

    case types.CLEAR_RECOGNIZED_TRAILER_DOCS:
      draft[TRAILERS].recognizedDocsData = [];
      break;

    case types.UPDATE_AVAILABLE_LIFTING:
      draft.availableLifting = action.payload;
      break;

    case types.UPDATE_AVAILABLE_BRANDS:
      draft.availableBrands = action.payload;
      break;

    case types.CLEAR_RECOGNIZED_DOCS:
      draft[DRIVERS].recognizedDocsData = [];
      draft[CARS].recognizedDocsData = [];
      draft[TRAILERS].recognizedDocsData = [];
      break;

    case types.CLEAR_RESOURCES:
      return initialState;

    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
