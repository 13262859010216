export const POPUPS_IDS = {
  PROFILE: 'profile',
  TOOLS: 'tools',
  PARTNERS: 'partners',
  RESOURCES: 'resources',
  SUPPORT: 'support',
  SUGGESTIONS: 'suggestions',
  PROFILE_MODAL: 'profileModal',
};

export const config = [
  {
    id: POPUPS_IDS.SUGGESTIONS,
    title: 'Предложения грузовладельцев',
    description: 'Поиск и борьба за выгодные предложения от грузовладельцев платформы.',
    action: (history) => history.push('/suggestions'),
  },
  {
    id: POPUPS_IDS.RESOURCES,
    title: 'Ресурсы вашего парка',
    description:
      'Подробная информация о водителях, автомашинах и прицепах вашей компании, а также отслеживание их допуска к работе на платформе.',
    action: (history) => history.push('/resources'),
  },
  {
    id: POPUPS_IDS.TOOLS,
    title: 'Полезные инструменты для эффективной работы',
    description: 'Комплексный мониторинг динамики рынка и оценка стоимости перевозки по интересующему направлению.',
    action: (history) => history.push('/tools'),
  },
  {
    id: POPUPS_IDS.PROFILE,
    title: 'Профиль компании',
    description:
      'Общая информация, необходимые реквизиты, а также ваши документы, необходимые для работы на платформе.',
    action: (history) => history.push('/profile/company'),
  },
  {
    id: POPUPS_IDS.SUPPORT,
    title: 'Поддержка',
    description:
      'Инструкции по работе на платформе, необходимая документация, а также способы связи с техподдержкой в случае возникновения вопросов. ',
  },
  {
    id: POPUPS_IDS.PROFILE_MODAL,
    title: 'Возможности вашего личного профиля',
    description:
      'Информация о вас, настройка уведомлений по интересующим событиям, сохраненные поиски и управление правами доступа для ваших сотрудников.',
  },
  // {
  //   id: POPUPS_IDS.PARTNERS,
  //   title: 'Больше партнёров, больше заявок',
  //   description: 'Чаще всего клиенты предпочитают размещать предложения только на своих партнеров.',
  //   action: (history) => history.push('/partners'),
  // },
];
