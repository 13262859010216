import React from 'react';
import SVGIcon from './index';

const IconAdd = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path fillRule="evenodd" d="M11 9V4H9V9H4V11H9V16H11V11H16V9H11Z" />
    </SVGIcon>
  );
};

IconAdd.defaultProps = {
  color: 'base',
};

export default IconAdd;
