/* реальные статусы */
export const BLACKLISTED: string = 'blacklisted';
export const REQUESTED: string = 'requested';
export const ACTIVE: string = 'active';
export const INACTIVE: string = 'inactive';

/* статусы для фронта */
export const NO_PARTNERSHIP: string = 'noPartnership';

/* Статусы партнерских договоров */
export const ACTIVE_CONTRACTS = 'active';
export const ARCHIVED_CONTRACTS = 'archived';

/* Статусы партнерских тарифов */
export const ALL_TARIFFS = 'all';
