import React from 'react';
import SVGIcon from './index';

const IconArrowLeft = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M6.70154 9L10 5.7925L8.66802 4.5L3 10L8.66802 15.5L10 14.2075L6.70154 11H17V9H6.70154Z"
        fillRule="evenodd"
      />
    </SVGIcon>
  );
};

export default IconArrowLeft;
