import * as types from './actionTypes';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';
import { TMassSendingStore } from './models';

const initialState: TMassSendingStore = {
  isMassSendingModalOpen: false,
  massSendingModalId: null,
  items: [],
  itemsHeader: [],
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    case types.TOGGLE_MASS_SENDING_MODAL:
      draft.isMassSendingModalOpen = action.payload.open;
      if (action.payload.open) {
        draft.massSendingModalId = action.payload.id;
      } else {
        draft.massSendingModalId = null;
      }
      break;
    case types.SET_ITEMS:
      draft.items = action.payload;
      break;
    case types.LOAD_MORE_ITEMS:
      draft.items = [...draft.items, ...action.payload];
      break;
    case types.SET_HEADER_ITEMS:
      draft.itemsHeader = action.payload;
      break;
    case types.CREATE_ITEM:
      draft.items.unshift(action.payload);
      draft.itemsHeader.unshift(action.payload);
      break;
  }
}, initialState);

export default reducer;
