import React from 'react';
import SVGIcon from './index';

function IconError(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path
        fillRule="evenodd"
        d="M15 5C9.48 5 5 9.48 5 15s4.48 10 10 10 10-4.48 10-10S20.52 5 15 5zm1 15h-2v-2h2v2zm0-4h-2v-6h2v6z"
      />
    </SVGIcon>
  );
}

IconError.defaultProps = {
  color: 'danger',
};

export default IconError;
