import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

import HelpPopover from '../../PopoversCollection/HelpPopover';

interface FieldProps {
  title: string;
  help?: string | React.ReactElement;
  isError?: boolean;
  htmlFor?: string;
  className?: string;
}

function FieldLabel({ title, help, isError, htmlFor, className }: FieldProps) {
  const labelClassNames = classNames(
    'color-dark',
    styles.label,
    {
      [styles.error]: isError,
    },
    className,
  );
  return (
    <label className={labelClassNames} htmlFor={htmlFor}>
      {title}
      {help && (
        <span className="ml-1">
          <HelpPopover text={help} />
        </span>
      )}
    </label>
  );
}

FieldLabel.defaultProps = {
  isError: false,
};

export default React.memo(FieldLabel);
