import React from 'react';
import SVGIcon from './index';

export default function IconAwningWithTruck5(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 60 25">
      <path d="m59.76 15.1-2.22-7a3.06 3.06 0 0 0 -2.84-2.1h-7.7v-6h-47v19h7v4h3.34a3.5 3.5 0 0 0 6.32 0h28.68a3.5 3.5 0 0 0 6.32 0h6.34a2 2 0 0 0 2-2v-4.3a7.11 7.11 0 0 0 -.24-1.6zm-49.71 5.9h-1.05v-2h2a3.46 3.46 0 0 0 -.95 2zm3.45 2a1.5 1.5 0 1 1 1.5-1.5 1.49 1.49 0 0 1 -1.5 1.5zm31.5-2h-28a3.46 3.46 0 0 0 -1-2h29zm0-4h-43v-15h43zm12.31-3h-5.31a1.17 1.17 0 0 1 -1-1v-2h5.37zm-8.81 9a1.5 1.5 0 0 1 0-3 1.52 1.52 0 0 1 1.5 1.5 1.49 1.49 0 0 1 -1.5 1.5zm3.5-2a3.54 3.54 0 0 0 -3.45-3 3.59 3.59 0 0 0 -1.5.34v-10.34h7.7a1.08 1.08 0 0 1 .95.7l.09.3h-6.79v4a3.16 3.16 0 0 0 3 3h5.92a4.61 4.61 0 0 1 .09.7v4.3z" />
    </SVGIcon>
  );
}

IconAwningWithTruck5.defaultProps = {
  color: 'base',
};
