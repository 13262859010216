export const DRIVER_FORM_NAME = 'userFormDriverInfo';
export const ID = 'id';
export const STATUS = 'status';
export const LAST_NAME = 'lastName';
export const FIRST_NAME = 'firstName';
export const MIDDLE_NAME = 'middleName';
export const BIRTHDAY = 'birthday';
export const COUNTRY = 'country';
export const DOCUMENT_TYPE = 'document_type';
export const PASSPORT = 'passport';
export const PASSPORT_WHO_ISSUED = 'passportWhoIssued';
export const PASSPORT_SUBDIVISION_CODE = 'passportSubdivisionCode';
export const PASSPORT_ISSUED_AT = 'passportIssuedAt';
export const PASSPORT_EXPIRED_AT = 'passportExpiredAt';
export const PASSPORT_PERIOD = 'passportPeriod';
export const ADDRESS_REG_STREET = 'addressRegStreet';
export const ADDRESS_REG_CITY = 'addressRegCity';
export const ADDRESS_REG_HOUSE = 'addressRegHouse';
export const ADDRESS_REG_ROOM = 'addressRegRoom';
export const IS_MANUAL_ADDRESS_REG = 'isManualAddressReg';
export const ADDRESS_REG_MANUAL = 'addressRegManual';
export const DRIVER_LICENSE = 'driverLicense';
export const DRIVER_LICENSE_WHO_ISSUED = 'driverLicenseWhoIssued';
export const DRIVER_LICENSE_ISSUED_AT = 'driverLicenseIssuedAt';
export const DRIVER_LICENSE_EXPIRED_AT = 'driverLicenseExpiredAt';
export const DRIVER_LICENSE_CATEGORIES = 'driverLicenseCategories';
export const HAS_MEDICAL_LICENSE = 'hasMedicalLicense';
export const PHONE = 'phone';
export const INDEX = 'index';
export const INN = 'inn';
export const CONTRACT_NUMBER = 'contractNumber';
export const CONTRACT_ISSUED_AT = 'contractIssuedAt';
export const CONTRACT_EXPIRED_AT = 'contractExpiredAt';
export const CONTRACT_FILES = 'contractFiles'; // файлы трудового договора/ГПХ (для хранения файлов, пока не создан водитель)
export const RECOGNIZABLE_DOCUMENTS_FILES = 'recognizableDocumentsFiles'; // файлы распознаваемых доков

export const CAR_FORM_NAME = 'userFormCarInfo';
export const VEHICLE_TYPE = 'vehicleType';
export const BODY_TYPE = 'bodyType';
export const BRAND = 'brand';
export const NUMBER = 'number';
export const IDENTIFY_NUMBER = 'identifyNumber'; // TODO: удалить
export const IS_NO_VIN = 'isNoVin';
export const VIN_NUMBER = 'vinNumber';
export const ENGINE_NUMBER = 'engineNumber';
export const BODY_NUMBER = 'bodyNumber';
export const CHASSIS_NUMBER = 'chassisNumber';
export const EXTRA_NUMBER_TYPE = 'extraNumberType';
export const WITHOUT_BODY_NUMBER = 'withoutBodyNumber'; // TODO: delete
export const TONNAGE_VOLUME = 'tonnageVolume';
export const MAX_LIFTING = 'maxLifting';
export const LENGTH = 'length';
export const WIDTH = 'width';
export const HEIGHT = 'height';
export const VEHICLE_LENGTH = 'vehicleLength';
export const VEHICLE_WIDTH = 'vehicleWidth';
export const VEHICLE_HEIGHT = 'vehicleHeight';
export const TRAILER_LENGTH = 'trailerLength';
export const TRAILER_WIDTH = 'trailerWidth';
export const TRAILER_HEIGHT = 'trailerHeight';
export const OWNERSHIP = 'ownership';
export const OWNERSHIP_FILES = 'ownership_files'; // для доков подтвержения права владения (для хранения файлов, пока не создан авто/прицеп)
export const OWNING_DOC_NUMBER = 'owning_doc_number';
export const OWNING_DOC_PERIOD = 'owning_doc_period'; // TODO: delete
export const OWNING_DOC_FROM = 'owning_doc_from';
export const OWNING_DOC_TILL = 'owning_doc_till';
export const IS_OWNING_DOC_OPEN_ENDED = 'is_owning_doc_open_ended';
export const _OWNER_TYPE = 'ownerType';
export const CAR_PHONE = 'carPhone';
export const COVER_TYPE = 'coverType';
export const BELTS = 'belts';
export const REMOVABLE_UPPER_BEAM = 'removableUpperBeam';
export const REMOVABLE_SIDE_RACKS = 'removableSideRacks';
export const RIGID_BOARD = 'rigidBoard';
export const DISINFECTED = 'disinfected';
export const DISINFECTED_FROM = 'disinfectedFrom';
export const DISINFECTED_UNTIL = 'disinfectedUntil';
export const TEMPERATURE_CHECK = 'temperatureCheck';
export const NUMBER_STS = 'numberSts';
export const OWNER_STS = 'ownerSts';
export const OWNER_INN = 'ownerINN';
export const OWNER_KPP = 'ownerKPP';
export const MANIPULATOR = 'manipulator';
export const HYDRO_BOARD = 'hydroBoard';
export const IMEI = 'tracker_id';
export const _IS_PERSON_INDIVIDUAL = 'isPersonIndividual'; // TODO: delete

export const TRAILER_FORM_NAME = 'userFormTrailerInfo';
