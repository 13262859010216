import React from 'react';
import classNames from 'classnames';
import styles from './IconDot.module.scss';

interface IDot {
  height: number;
  width: number;
  color: string;
  className?: string;
  children?: React.ReactElement;
  pulse?: boolean;
}

function IconDot(props: IDot) {
  const divStyle = {
    height: props.height,
    width: props.width,
    borderRadius: '50%',
  };

  return (
    <div
      style={divStyle}
      className={classNames(`bg-color-${props.color}`, { [styles.pulse]: props.pulse }, props.className)}
    >
      {props.children}
    </div>
  );
}

export default IconDot;
