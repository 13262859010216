import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import styles from './styles.module.scss';

import Button from '../../Button';
import Paginator from '../../Paginator';
import EmptySearch from '../../EmptySearch';
import ContentLoader from '../../ContentLoader';
import ModalDetail from '../ModalDetail';
import FilterSearch from '../../FilterPanelComponents/FilterSearch';
import AddressesList from '../../../containers/locations/LocationsTablePage/AddressesList';

import { getMappedLocationForList } from '../../../store/counterAgents/selectors';
import { IS_WAREHOUSE } from '../../../store/counterAgents/formFields';
import { useLocations } from '../../../services/hooks/useLocations';
import RadioLabel from '../../FilterPanelComponents/RadioLabel';

type TParams = {
  page: number;
  query: string;
  [IS_WAREHOUSE]: string;
};

const tabs = [
  {
    value: 'false',
    title: 'Адреса',
  },
  {
    value: 'true',
    title: 'Склады',
  },
];

function LocationsListModal(props) {
  const { onClose, onSelect, queryParams, isOrderChangeWithWarehouse } = props;
  const [params, setParams] = useState<TParams>({ page: 1, query: '', [IS_WAREHOUSE]: 'false' });
  const _params = {
    ...params,
    per_page: 10,
    sort_by: 'picked_at',
    ...queryParams,
  };
  const { data, isLoading } = useLocations(_params, {
    select: (data) => ({
      ...data,
      items: data.items.map(getMappedLocationForList),
    }),
  });

  const getContent = () => {
    if (isLoading) {
      return <ContentLoader withPortal />;
    }
    if (!data?.totalItems && !!params.query) {
      return <EmptySearch title="Ничего не найдено" message="Уточните параметры поиска" />;
    }
    return (
      <AddressesList
        items={data?.items ?? []}
        onSelect={() => {}}
        isOrderChangeWithWarehouse={isOrderChangeWithWarehouse}
        actionElement={
          <Button theme="secondary" type="button" onClick={onSelect}>
            Выбрать
          </Button>
        }
      />
    );
  };

  const handleSearch = useCallback(
    (queryString) => setParams((prev) => ({ ...prev, query: queryString, page: 1 })),
    [],
  );
  const handlePageClick = useCallback((page) => setParams((prev) => ({ ...prev, page })), []);

  const handleTabClick = useCallback((value) => {
    setParams((prev) => ({
      ...prev,
      [IS_WAREHOUSE]: value,
    }));
  }, []);

  return (
    <ModalDetail
      onClose={onClose}
      wrapperClass={styles.addressesWindowWrapper}
      innerContentClass={styles.addressesWindowContent}
    >
      {/* Заголовок */}
      <header className={classNames('block', styles.header)}>
        <section>
          <h4 className="mt-0 mb-1">Адреса и контактные лица</h4>
        </section>
      </header>

      <section className="flex content-between items-center">
        <div>
          {tabs.map((tab) => (
            <RadioLabel {...tab} queryKey={params[IS_WAREHOUSE]} onClick={() => handleTabClick(tab.value)} />
          ))}
        </div>
        <FilterSearch
          value={params.query}
          onChange={handleSearch}
          placeholder="Поиск по адресу, ID, наименованию"
          className={styles.searchWrapper}
        />
      </section>

      {/* Таблица адресов */}
      {getContent()}

      {/* Пагинация */}
      {(data?.totalPages ?? 0) > 1 && (
        <Paginator
          page={params.page}
          pages={data?.totalPages ?? 0}
          range={3}
          margin={3}
          onClick={handlePageClick}
          disableScroll
        />
      )}
    </ModalDetail>
  );
}

LocationsListModal.defaultProps = {
  queryParams: {},
};

export default LocationsListModal;
