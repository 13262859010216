import { defineAbility } from '@casl/ability';
// import { TC_LOGISTICS } from '../users/_helpers/roles';

const extraPermissionsForRoles = {
  // [TC_LOGISTICS]: [['edit', 'partners']],
};

export default function defineAbilityFor(rolePermissions: string[], useRole: string) {
  return defineAbility((can, cannot) => {
    /* Service accesses */
    can('get', '/');
    can('get', '404');
    can('get', 'files');
    can('get', 'authorize');
    can('get', 'logout');
    can('get', 'register-self');
    can('get', 'register-manager');
    can('get', 'recovery');
    can('get', 'integration');
    can('get', 'no-access');
    can('get', 'foul-token');
    can('get', 'change-password');
    can('get', 'notifications');
    can('get', 'pickup');
    // can('get', 'platform-docs');
    if (process.env.NODE_ENV !== 'production') {
      can('get', 'development');
      can('get', 'gates');
    }
    /* ---------------- */
    rolePermissions.forEach((permission) => {
      const [subject, action] = permission.split('.'); // ex.: 'order.get'
      can(action, subject);
    });

    const extraRolePermissions = extraPermissionsForRoles[useRole];
    if (extraRolePermissions) {
      extraRolePermissions.forEach(([action, subject]) => {
        can(action, subject);
      });
    }
  });
}

export const ALL_PERMISSIONS = new Map()
  .set('/users', 'users')
  .set('/suggestions', 'suggestions')
  .set('/suggestion-change', 'suggestion-change')
  .set('/shippings', 'shippings')
  .set('/shipping-confirm', 'shipping-confirm')
  .set('/graphics', 'graphics')
  .set('/volume-graphics', 'volume-graphics')
  .set('/resources', 'resources')
  .set('/organizations', 'organizations')
  .set('/partners', 'partners')
  .set('/counter-agents', 'counter-agents')
  .set('/counter-agents/locations', 'counter-agents')
  .set('/counter-agents/warehouses', 'gates')
  .set('/analytics', 'analytics')
  .set('/claims', 'claims')
  .set('/profile/company', 'profile')
  .set('/profile/user', 'user-profile')
  .set('/profile/logout', 'logout')
  .set('/profile/notifications', 'notifications')
  .set('/notifications-service', 'notifications-service')
  .set('/billing', 'billing')
  .set('/order', 'order')
  .set('/order-old', 'order')
  .set('/order-history', 'order')
  .set('/orders-uploading', 'order')
  .set('/order-list', 'order-list')
  .set('/cargo-tracking', '/')
  .set('/fleet-debug', '/')
  .set('/contracts', 'contracts')
  .set('/authorize', 'authorize')
  .set('/files', 'files')
  .set('/register-self', 'register-self')
  .set('/register-manager', 'register-manager')
  .set('/recovery', 'recovery')
  .set('/integration', 'integration')
  .set('/no-access', 'no-access')
  .set('/foul-token', 'foul-token')
  .set('/change-password', 'change-password')
  .set('/404', '404')
  .set('/mass-sending', 'chats')
  .set('/notifications', 'notifications')
  .set('/scheduler', 'gates')
  .set('/pickup', 'pickup')
  .set('/documents_and_files', 'platform-docs')
  .set('/tools', 'tools')
  .set('/tools/expedited-payment', 'expedited-payment')
  .set('/tools/price-helper', 'pa-calculator')
  .set('/onboarding', '/')
  .set('/dev', 'development')
  .set('/', '/');
