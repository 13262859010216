import { Field } from '../../../../components/FormikFieldsV3/Field';
import FormCheckbox from '../../../../components/FormikFieldsV3/FormCheckbox';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { TYPES } from '../../../../features/insurance/components/ProfileFormInsurance/constants';
import FormPriceInput from '../../../../components/FormikFieldsV3/FormPriceInput';
import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import { TONNAGE_VOLUME } from '../TransportBlock';
import { useOrderContext } from '../../index';
import { CARGO_COST } from '../CargoBlock';
import { useFormikContext } from '../../../../components/FormikFieldsV3/FormikContext';
import {
  INHERITANCE_TONNAGE,
  INSURANCE_METHOD,
  INSURANCE_REQUIRED,
  INSURANCE_SUM,
} from '../../../../features/insurance/constants';

/**
 * Подходит ли тоннаж ТС под интервал; true - подходит
 * @param insuranceTonnage
 * @param tonnage
 * @return {boolean}
 */
export const checkTonnageInterval = (insuranceTonnage, tonnage) => {
  if (!insuranceTonnage || !insuranceTonnage[0] || !insuranceTonnage[1]) return false;
  const min = +insuranceTonnage[0];
  const max = +insuranceTonnage[1];
  return min <= tonnage && tonnage <= max;
};

function InsuranceBlock() {
  const { insuranceOption, insuranceTonnage, currentOrder, inheritInsuranceSettings, isOrderChange } =
    useOrderContext();
  const { validateField } = useFormikContext();
  const [insuranceRequired, setInsuranceRequired] = useFieldValue(INSURANCE_REQUIRED);
  const [insuranceMethod, setInsuranceMethod] = useFieldValue(INSURANCE_METHOD);
  const [, setInsuranceSum] = useFieldValue(INSURANCE_SUM);
  const [cargoCost] = useFieldValue(CARGO_COST);
  const [transportTonnage] = useFieldValue(TONNAGE_VOLUME);

  const tonnageChangeHandler = useCallback(
    (value) => {
      if (isOrderChange) return;

      const currentInsuranceOption = inheritInsuranceSettings ? currentOrder[INSURANCE_METHOD] : insuranceOption;
      const currentTonnageInterval = inheritInsuranceSettings ? currentOrder[INHERITANCE_TONNAGE] : insuranceTonnage;

      if (currentInsuranceOption === TYPES.ALLOW) return;

      const tonnage = typeof value === 'number' ? value : value.target.value.transport_tonnage;

      if (checkTonnageInterval(currentTonnageInterval, tonnage)) {
        // интервал не нарушен
        setInsuranceRequired(true);
        setInsuranceMethod(currentInsuranceOption);
      } else {
        setInsuranceMethod(TYPES.ALLOW);
      }
      setTimeout(() => {
        validateField(CARGO_COST);
      }, 200);
    },
    [
      isOrderChange,

      insuranceOption,
      insuranceTonnage,
      setInsuranceMethod,
      setInsuranceRequired,
      validateField,

      inheritInsuranceSettings,
      currentOrder,
    ],
  );

  useEffect(() => {
    if (transportTonnage?.transport_tonnage) {
      tonnageChangeHandler(transportTonnage.transport_tonnage);
    }
  }, [transportTonnage, tonnageChangeHandler]);

  const cargoCostChangeHandler = useCallback(
    (value) => {
      if (!isOrderChange) setInsuranceSum(value);
    },
    [isOrderChange, setInsuranceSum],
  );

  const insuranceRequiredChangeHandler = useCallback(() => {
    setInsuranceSum(cargoCost);
  }, [setInsuranceSum, cargoCost]);

  const formParams = useMemo(() => {
    const res = {
      checkboxParams: {
        disabled: false,
        label: 'Страхование перевозчиком обязательно',
      },
      description: '',
      insuranceSumParams: {
        disabled: true,
      },
      insuranceSumDescription: 'Заполняется из поля «Объявленная стоимость груза»',
    };

    if (insuranceMethod === TYPES.ALLOW) {
      res.checkboxParams.disabled = isOrderChange;
      res.description =
        'У перевозчика обязательно должен быть страховой полис на указанную страховую сумму. ' +
        'Пункт по обязательному страхованию будет включён в печатную форму заказа. ' +
        'Ответственность за его выполнение несёт перевозчик';
    }
    if (insuranceMethod === TYPES.REQUIRED) {
      res.checkboxParams.disabled = true;
      res.description =
        'Согласно настройкам в «Профиле компании» страхование перевозки обязательно на ' +
        'конкретную сумму, но её можно изменить в большую сторону при необходимости';
    }
    if (insuranceMethod === TYPES.CARGO_COST) {
      res.checkboxParams.disabled = true;
      res.description =
        'Согласно настройкам в «Профиле компании» страхование перевозки обязательно на указанный тоннаж транспорта';
    }
    return res;
  }, [insuranceMethod, isOrderChange]);

  return (
    <Fragment>
      <section className="mb-3">
        <Field
          name={INSURANCE_REQUIRED}
          component={FormCheckbox}
          {...formParams.checkboxParams}
          labelClassName="mt-4 mr-4"
          labelHelpClassName="tooltip"
          onChangeCallback={insuranceRequiredChangeHandler}
        />
        <div className={'caption ml-5 leading-3'}>{formParams.description}</div>
        <div className={'caption ml-5 mt-1 leading-3'}>
          Если после создания заявки поменять объявленную стоимость/ценность груза, страховая сумма не изменится
        </div>
      </section>

      <section className="flex mb-7 ml-5">
        <div>
          <div className={'caption leading-4'}>
            Объявленная стоимость/ценность груза {!insuranceRequired ? '(необязательно)' : ''}
          </div>
          <Field
            name={CARGO_COST}
            component={FormPriceInput}
            additional="₽"
            labelDisable
            labelClassName="width240"
            placeholder="Например, 3 000 000"
            onChangeCallback={cargoCostChangeHandler}
          />
        </div>
        {insuranceRequired && (
          <div>
            <Field
              name={INSURANCE_SUM}
              component={FormPriceInput}
              additional="₽"
              label="Страховая сумма"
              labelClassName="width240 ml-4"
              {...formParams.insuranceSumParams}
            />
            <div className={'caption ml-4'}>{formParams.insuranceSumDescription}</div>
          </div>
        )}
      </section>
    </Fragment>
  );
}

export default InsuranceBlock;
