import React from 'react';
import SVGIcon from './index';

function IconSchedulePlus(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 16H11V15H5V7H15V11H16V4H4V16ZM13 13H11H9V12H13V13ZM13 9H9V10H13V9ZM7 12H8V13H7V12ZM7 9H8V10H7V9ZM15 13H16V15H18V16H16V18H15V16H13V15H15V13Z"
      />
    </SVGIcon>
  );
}

IconSchedulePlus.defaultProps = {
  color: 'base',
};

export default React.memo(IconSchedulePlus);
