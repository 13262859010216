import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Row from './elements/Row';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { TableColumn, TableColumnsGroup, TableRowOverlay } from './TableChildren';
import ColumnsSelector from './elements/ColumnsSelector';
import { useSavingTableSettings, useStickyColumns, useTableMatomoEvent } from './hooks';
import { useTableWrapperContext } from '../../PageElements/TableWrapper/TableWrapper';
import ScrollPanel from './elements/ScrollPanel';
import {
  CHANGE_TABLE_COLUMN_PINNED_STATE,
  CHANGE_TABLE_COLUMN_POSITION,
  CHANGE_TABLE_COLUMN_VISIBILITY_STATE,
  RESET_TABLE_COLUMNS_SETTINGS,
} from '../../../features/matomo-analytics/eventNameConstants';

export const TableContext = createContext({
  tableKey: '',
  matomoEventsCategory: '',
  itemsIdsOrder: null,
  visibleItemsIds: null,
  lockedItemsIds: null,
  lockedColumnsIds: null,
  tableRef: null,
  tableContainerRef: null,
  scrollLeft: null,
});

function mergeArrays(arr1, arr2) {
  if (!arr1 || !arr2) return arr1 || arr2;
  return [...arr1, ...arr2.filter((item) => !arr1.includes(item))];
}

function getTableItemEntity(child, additionalProps = {}) {
  if (!child) return;
  return {
    type: child.type,
    props: Object.assign({}, child.props, additionalProps),
  };
}

function Table({
  data = [],
  className = '',
  rowClassName = '',
  clickableRows = true,
  children = null,
  onRowClicked = function (item, event) {},
  useColumnsSelector = false,
  tableKey = '', // обязательно для сохранения данных
  matomoEventsCategory = '', // обязательно для сбора событий в матомо
}) {
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);
  const rowRef = useRef();

  const [showScroll, setShowScroll] = useState(false);
  const [tableWidth, setTableWidth] = useState(null);
  const [tableContainerWidth, setTableContainerWidth] = useState(null);

  const updateShowScroll = useCallback(() => {
    const _tableWidth = tableRef?.current?.clientWidth;
    const _tableContainerWidth = tableContainerRef?.current?.clientWidth;
    const _showScroll = _tableWidth > _tableContainerWidth;

    setTableWidth(_tableWidth);
    setTableContainerWidth(_tableContainerWidth);
    setShowScroll(_showScroll);
  }, [tableRef, tableContainerRef]);

  // matomo:
  const sendColumnVisibilityEvent = useTableMatomoEvent(CHANGE_TABLE_COLUMN_VISIBILITY_STATE, matomoEventsCategory);
  const sendColumnPinnedEvent = useTableMatomoEvent(CHANGE_TABLE_COLUMN_PINNED_STATE, matomoEventsCategory);
  const sendChangeColumnPositionEvent = useTableMatomoEvent(CHANGE_TABLE_COLUMN_POSITION, matomoEventsCategory);
  const sendResetColumnEvent = useTableMatomoEvent(RESET_TABLE_COLUMNS_SETTINGS, matomoEventsCategory);

  // mapped columns or groups of columns in columns selector:
  const selectableItems = useMemo(() => {
    if (!children) return [];
    const res = [];
    React.Children.toArray(children).forEach((child, index) => {
      const defaultChildId = child.props.id || `default-column-id-${index}`;

      if (child.type === TableColumn) {
        res.push(getTableItemEntity(child, { id: defaultChildId }));
      }

      if (child.type === TableColumnsGroup) {
        const mappedGroupChildren = [];
        const childrenArray = React.Children.toArray(child.props.children);
        childrenArray.forEach((groupChild, index) => {
          if (groupChild.type !== TableColumn) return;

          const additionalProps = {
            id: groupChild.props.id || `${defaultChildId}-${index}`,
            groupChildIndex: index,
            groupChildrenNumber: childrenArray.length,
            groupProps: {
              id: child.props.id,
              name: child.props.name,
              required: child.props.required,
              pinned: child.props.pinned,
              optional: child.props.optional,
              showAsColumn: child.props.showAsColumn,
            },
          };
          mappedGroupChildren.push(getTableItemEntity(groupChild, additionalProps));
        });
        const updatedProps = {
          id: defaultChildId,
          children: mappedGroupChildren,
        };
        res.push(getTableItemEntity(child, updatedProps));
      }
    });
    return res;
  }, [children]);

  const rowItems = useMemo(() => {
    if (!children) return [];
    const res = [];
    React.Children.toArray(children).forEach((child) => {
      if (child.type === TableRowOverlay) {
        res.push(getTableItemEntity(child));
      }
    });
    return res;
  }, [children]);

  // selectableItems, прошедшие проверку на заполнение необходимых для селектора полей
  const availableItems = useMemo(() => {
    return selectableItems.map((col) => {
      const header = typeof col.props.header === 'string' ? col.props.header : null;
      return {
        id: col.props.id,
        label: col.props.name || header,
        required: col.props.required,
        pinned: col.props.pinned,
        optional: col.props.optional,
      };
    });
  }, [selectableItems]);

  const orderByDefault = useMemo(() => {
    return availableItems.map((item) => item.id);
  }, [availableItems]);
  const selectedByDefault = useMemo(() => {
    return availableItems.filter((col) => !col.optional).map((item) => item.id);
  }, [availableItems]);
  const pinnedByDefault = useMemo(() => {
    return availableItems.filter((col) => col.pinned).map((item) => item.id);
  }, [availableItems]);

  const { fields, save, get, reset, getActiveColumns, columnsOrder, setColumnsOrder } =
    useSavingTableSettings(tableKey);

  const [itemsIdsOrder, setItemsIdsOrder] = useState(mergeArrays(columnsOrder, orderByDefault));
  const [visibleItemsIds, setVisibleItemsIds] = useState(getActiveColumns(fields.VISIBLE) || selectedByDefault);
  const [pinnedItemsIds, setPinnedItemsIds] = useState(getActiveColumns(fields.PINNED) || pinnedByDefault);

  // console.log(orderByDefault, columnsOrder, itemsIdsOrder, availableItems);

  const { onVisibleItemsChange } = useTableWrapperContext();

  useEffect(() => {
    onVisibleItemsChange && onVisibleItemsChange(visibleItemsIds);
    updateShowScroll();
  }, [onVisibleItemsChange, visibleItemsIds, updateShowScroll]);

  useEffect(() => {
    const storageSettings = get();
    if (!storageSettings) {
      setColumnsOrder(itemsIdsOrder);
      save(visibleItemsIds, { [fields.VISIBLE]: true });
      save(pinnedItemsIds, { [fields.PINNED]: true });
    }
  }, [fields.PINNED, fields.VISIBLE, get, itemsIdsOrder, pinnedItemsIds, save, setColumnsOrder, visibleItemsIds]);

  const selectItemChangeHandler = useCallback(
    (item, isSelected) => {
      let newVisibleIds;
      setVisibleItemsIds((prev) => {
        if (isSelected) {
          newVisibleIds = prev?.slice() || [];
          newVisibleIds.push(item.id);
        } else {
          newVisibleIds = prev.filter((id) => id !== item.id);
        }
        return newVisibleIds;
      });
      save(item.id, { [fields.VISIBLE]: isSelected });

      sendColumnVisibilityEvent();
    },
    [save, sendColumnVisibilityEvent, fields.VISIBLE],
  );

  const lockItemChangeHandler = useCallback(
    (item, isPinned) => {
      let newPinnedIds;
      setPinnedItemsIds((prev) => {
        if (isPinned) {
          newPinnedIds = prev?.slice() || [];
          newPinnedIds.push(item.id);
        } else {
          newPinnedIds = prev.filter((id) => id !== item.id);
        }
        return newPinnedIds;
      });
      save(item.id, { [fields.PINNED]: isPinned });

      sendColumnPinnedEvent();
    },
    [save, sendColumnPinnedEvent, fields.PINNED],
  );

  const columnsOrderChangeHandler = useCallback(
    (id, newIndex) => {
      const currentIndex = itemsIdsOrder.indexOf(id);
      let copy = itemsIdsOrder.slice();
      if (currentIndex !== -1) {
        const id = copy[currentIndex];
        copy.splice(currentIndex, 1);
        copy.splice(newIndex, 0, id);
      }
      setItemsIdsOrder(copy);
      setColumnsOrder(copy);

      sendChangeColumnPositionEvent();
    },
    [itemsIdsOrder, setColumnsOrder, sendChangeColumnPositionEvent],
  );

  const resetClickHandler = useCallback(() => {
    reset();
    setColumnsOrder(orderByDefault);
    save(selectedByDefault, { [fields.VISIBLE]: true });
    save(pinnedByDefault, { [fields.PINNED]: true });

    setItemsIdsOrder(orderByDefault);
    setVisibleItemsIds(selectedByDefault);
    setPinnedItemsIds(pinnedByDefault);

    sendResetColumnEvent();
  }, [
    fields.PINNED,
    fields.VISIBLE,
    orderByDefault,
    pinnedByDefault,
    reset,
    save,
    selectedByDefault,
    setColumnsOrder,
    sendResetColumnEvent,
  ]);

  const showMarker = useMemo(() => {
    return orderByDefault.find((id, index) => {
      if (id !== itemsIdsOrder[index]) return true; // проверка порядка колонок
      if (selectedByDefault.includes(id) !== visibleItemsIds?.includes(itemsIdsOrder[index])) return true; // проверка отображения колонок
      return false;
    });
  }, [itemsIdsOrder, orderByDefault, selectedByDefault, visibleItemsIds]);

  const sortedAvailableItems = useMemo(() => {
    return itemsIdsOrder
      .map((id) => {
        return availableItems.find((item) => item.id === id);
      })
      .filter(Boolean);
  }, [itemsIdsOrder, availableItems]);

  // visible columns in table:
  const visibleColumns = useMemo(() => {
    if (useColumnsSelector && !visibleItemsIds) return [];

    const filter = useColumnsSelector ? (id) => visibleItemsIds?.includes(id) : () => true;

    const res = [];
    itemsIdsOrder.filter(filter).forEach((id) => {
      const selectedItem = selectableItems.find((child) => child.props.id === id);
      if (!selectedItem) return;

      if (selectedItem.type === TableColumn) {
        res.push(selectedItem);
      }
      if (selectedItem.type === TableColumnsGroup) {
        res.push(...selectedItem.props.children);
      }
    });
    return res;
  }, [useColumnsSelector, itemsIdsOrder, visibleItemsIds, selectableItems]);

  const lockedColumnsIds = useMemo(() => {
    if (!pinnedItemsIds) return [];

    const res = [];
    itemsIdsOrder
      .filter((id) => visibleItemsIds?.includes(id))
      .filter((id) => pinnedItemsIds.includes(id))
      .forEach((id) => {
        const selectedItem = selectableItems.find((selectableItem) => selectableItem.props.id === id);
        if (!selectedItem) return;
        if (selectedItem.type === TableColumn) res.push(selectedItem.props.id);
        if (selectedItem.type === TableColumnsGroup)
          res.push(...selectedItem.props.children.map((child) => child.props.id));
      });
    return res;
  }, [pinnedItemsIds, visibleItemsIds, itemsIdsOrder, selectableItems]);

  const tableContext = useMemo(() => {
    return {
      tableKey,
      matomoEventsCategory,
      itemsIdsOrder,
      visibleItemsIds,
      pinnedItemsIds,
      lockedColumnsIds,
      tableRef,
      tableContainerRef,
    };
  }, [
    tableKey,
    matomoEventsCategory,
    itemsIdsOrder,
    visibleItemsIds,
    pinnedItemsIds,
    lockedColumnsIds,
    tableRef,
    tableContainerRef,
  ]);

  const { redraw: redrawStickColumns, ...rowProps } = useStickyColumns(
    showScroll,
    visibleColumns,
    lockedColumnsIds,
    tableContainerRef,
    tableRef,
    rowRef,
  );

  const resizeCallback = useCallback(() => {
    redrawStickColumns();
    updateShowScroll();
  }, [redrawStickColumns, updateShowScroll]);

  useEffect(() => {
    window.addEventListener('resize', resizeCallback);
    return () => window.removeEventListener('resize', resizeCallback);
  }, [resizeCallback]);

  return (
    <div className={'p-relative'}>
      <TableContext.Provider value={tableContext}>
        {useColumnsSelector && (
          <ColumnsSelector
            selectableItems={sortedAvailableItems}
            showMarker={showMarker}
            onChange={columnsOrderChangeHandler}
            onSelect={selectItemChangeHandler}
            onLocked={lockItemChangeHandler}
            onReset={resetClickHandler}
          />
        )}

        <div ref={tableContainerRef} className={classNames(styles.tableContainer, className)}>
          <table ref={tableRef} className={classNames(styles.table)}>
            <thead>
              <Row ref={rowRef} isHeader={true} columns={visibleColumns} {...rowProps} />
            </thead>
            <tbody>
              {!visibleColumns?.length && (
                <tr>
                  <td className={'text-align-center pt-4 pb-4'}>
                    <div className={'bold'}>Не выбран ни один столбец</div>
                    <div className={'link'} onClick={resetClickHandler}>
                      Восстановить по умолчанию
                    </div>
                  </td>
                </tr>
              )}
              {!!visibleColumns?.length &&
                data.map((dataItem, index) => {
                  return (
                    <Row
                      key={index}
                      data={dataItem}
                      columns={visibleColumns}
                      rowItems={rowItems}
                      {...rowProps}
                      clickable={clickableRows}
                      className={rowClassName}
                      onClick={(e) => onRowClicked(dataItem, e)}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>

        {showScroll && <ScrollPanel data={data} tableWidth={tableWidth} tableContainerWidth={tableContainerWidth} />}
      </TableContext.Provider>
    </div>
  );
}

Table.Column = TableColumn;
Table.Group = TableColumnsGroup;
Table.RowOverlay = TableRowOverlay;

export default Table;
