// @ts-ignore typescript не видит импорт из axios, пришлось заигнорить
import { CancelToken } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { toast } from '../../../components/Toaster';
import { TRequestExpeditedPayment, TUseRequestExpeditedPayment } from '../models';
import { getShippingRequestExpeditedPaymentPrice } from '../../../services/suggestionsRequestService';

/**
 * получение минимальной положительной цены
 * берем отрицательную минимальную и прибавляем шаги пока не станет больше нуля
 * @param start
 * @param step
 */
function getMinLimit(start, step) {
  let result = start;
  while (true) {
    if (result < 0 || result === 0) {
      result += step;
    } else {
      break;
    }
  }
  return result;
}

/**
 * Получение информации по ускоренной оплате для заказа (shipping request)
 * @param props {id} - id заказа\заяки\перевозки
 * @param props {isExpeditedPaymentAvailable}
 * @param props {initialExpeditedPayment}
 * @param props {initialExpeditedPaymentDays}
 * @param props {initialBid}
 * @param props {initialLimits}
 * @param props {initialExpeditedPaymentUserBid}
 * @param props {initialExpeditedPaymentWinningBid}
 * @param props {shouldExpeditedPaymentCalculate} - делать ли запрос на расчет уо
 * @param props {shouldTurnOffExpeditedPaymentOnDisable} - требуется ли отключать уо при недоступности чекбокса включения уо
 * @param props {step}
 */
export function useRequestExpeditedPayment({
  id,
  isExpeditedPaymentAvailable,
  initialExpeditedPayment,
  initialExpeditedPaymentDays,
  initialBid,
  initialLimits = [0, 0],
  initialExpeditedPaymentUserBid,
  initialExpeditedPaymentWinningBid,
  shouldExpeditedPaymentCalculate = true,
  shouldTurnOffExpeditedPaymentOnDisable = true,
  step = 1,
}: TUseRequestExpeditedPayment): TRequestExpeditedPayment {
  // Для некоторых кейсов чекбокс подключения УО может быть недоступен
  const [isExpeditedPaymentDisabled, setIsExpeditedPaymentDisabled] = useState(false);
  const [isExpeditedPaymentLoading, setExpeditedPaymentLoading] = useState<boolean>(false);
  const [isExpeditedPayment, setExpeditedPayment] = useState<boolean>(initialExpeditedPayment);
  const [expeditedPaymentDays, setExpeditedPaymentDays] = useState<number>(initialExpeditedPaymentDays);
  const [userBid, setUserBid] = useState<TRequestExpeditedPayment['userBid']>(initialBid);
  const [expeditedPaymentUserBid, setExpeditedPaymentUserBid] =
    // Используем начальное значение - ставку без ускорки как fallback чтобы исключить моргание на интерфейсе
    useState<TRequestExpeditedPayment['expeditedPaymentUserBid']>(initialExpeditedPaymentUserBid ?? initialBid);
  // Лидирующая ставка для УО
  const [expeditedPaymentWinningBid, setExpeditedPaymentWinningBid] = useState<
    TRequestExpeditedPayment['expeditedPaymentWinningBid']
  >(initialExpeditedPaymentWinningBid ?? null);
  const [expeditedPaymentInitialBid, setExpeditedPaymentInitialBid] =
    useState<TRequestExpeditedPayment['expeditedPaymentInitialBid']>(null);
  const [limits, setLimits] = useState<TRequestExpeditedPayment['resultLimits']>(initialLimits);

  const onExpeditedPaymentToggle = useCallback(() => setExpeditedPayment((prev) => !prev), []);
  const resultUserBid = isExpeditedPayment ? expeditedPaymentUserBid! : userBid!;
  const resultLimits = isExpeditedPayment ? limits : initialLimits;

  useEffect(() => {
    if (!userBid) setIsExpeditedPaymentDisabled(true);
  }, [userBid]);

  // При проставлении ставки с УО необходимо обновить лидирующую с УО
  useEffect(() => {
    setExpeditedPaymentWinningBid(initialExpeditedPaymentWinningBid ?? null);
  }, [initialExpeditedPaymentWinningBid]);

  const shouldMakeRequest = shouldExpeditedPaymentCalculate && isExpeditedPaymentAvailable && !!id && !!userBid;

  useEffect(() => {
    const cancelToken = CancelToken.source();
    if (shouldMakeRequest) {
      setExpeditedPaymentLoading(true);
      const price = userBid ? Math.round(userBid) : userBid;
      getShippingRequestExpeditedPaymentPrice(id, price, expeditedPaymentDays, cancelToken)
        .then((data) => {
          const minBid = data.min_bid > 0 ? data.min_bid : getMinLimit(data.min_bid, step);
          setExpeditedPaymentUserBid(data.price);
          setUserBid(data.value + data.price);
          setLimits([minBid, data.max_bid]);
          setExpeditedPaymentInitialBid(data.initial_price);
          setExpeditedPaymentWinningBid(data.winning_bid);
          const expeditedPaymentDisabled = data.price < 0 || data.price === 0;
          setIsExpeditedPaymentDisabled(expeditedPaymentDisabled);
          if (expeditedPaymentDisabled && shouldTurnOffExpeditedPaymentOnDisable) setExpeditedPayment(false);
        })
        .catch(toast.errorAsync)
        .catch(() => Promise.resolve())
        .finally(() => setExpeditedPaymentLoading(false));
    }
    return () => {
      cancelToken.cancel();
    };
  }, [id, expeditedPaymentDays, userBid, shouldMakeRequest, shouldTurnOffExpeditedPaymentOnDisable, step]);

  /* Метод для сброса пользовательских данных при отмене изменения ставки или выходе из торга */
  const resetExpeditedPayment = useCallback(() => {
    setExpeditedPayment(initialExpeditedPayment);
    setUserBid(initialBid);
    setExpeditedPaymentDays(initialExpeditedPaymentDays);
  }, [initialBid, initialExpeditedPayment, initialExpeditedPaymentDays]);

  /* Вызвать метод сброса данных указанных пользователем при изменении входных данных (для корректного выхода из торга) */
  useEffect(() => {
    resetExpeditedPayment();
  }, [resetExpeditedPayment]);

  return {
    isExpeditedPaymentLoading,
    isExpeditedPayment,
    setExpeditedPayment,
    expeditedPaymentDays,
    setExpeditedPaymentDays,
    userBid,
    setUserBid,
    expeditedPaymentUserBid,
    setExpeditedPaymentUserBid,
    onExpeditedPaymentToggle,
    resultUserBid,
    resultLimits,
    expeditedPaymentInitialBid,
    expeditedPaymentWinningBid,
    resetExpeditedPayment,
    isExpeditedPaymentDisabled,
  };
}
