/**
 * ------------------
 * Общее
 * ------------------
 */
/* Url авторизации */
export const AUTH_URL = '/authorize';

/* Контактный телефон службы поддержки */
export const SUPPORT_PHONE_NUMBER = {
  common: '8 800 707-00-71',
  short: '+78007070071',
};
/* Контактный email службы поддержки */
export const SUPPORT_EMAIL = 'info@traffic.online';

/* Часовой пояс приложения по умолчанию */
export const DEFAULT_APP_TIME_ZONE = 3;
export const DEFAULT_APP_TIME_ZONE_NAME = 'Europe/Moscow';
export const UTC_TIME_ZONE_NAME = 'Etc/UTC';

/* Максимальное кол-во символов в input поле */
export const INPUT_MAX_LENGTH = 150;
/* Максимальное кол-во символов в textarea */
export const TEXTAREA_MAX_LENGTH = 1024;

/* Формат отображения даты */
export const DATE_FORMAT = 'DD.MM.YYYY';
/* Короткий формат отображения даты*/
export const SHORT_DATE_FORMAT = 'DD.MM.YY';
/* Короткий формат отображения даты и времени*/
export const SHORT_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
/* Формат даты для запроса */
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';
/* Формат отображения времени */
export const TIME_FORMAT = 'HH:mm';
/* Формат отображения времени с секундами */
export const EXTENDED_TIME_FORMAT = 'HH:mm:ss';
/* Длинный формат отображения даты и времени*/
export const LONG_DATE_TIME_FORMAT = `${DATE_FORMAT} ${EXTENDED_TIME_FORMAT}`;
/* Формат даты время в en формате */
export const DATE_TIME_EN_FORMAT = 'YYYY-MM-DD HH:mm';
/* Формат даты c названием месяца прописью */
export const DATE_FORMAT_MONTH_NAME = 'DD MMMM YYYY';
/* Формат даты и времени c названием месяца прописью */
export const DATE_TIME_FORMAT_MONTH_NAME = 'DD MMMM YYYY HH:mm';
/* Формат отображения даты и времени (короткий) */
export const DATE_TIME_FORMAT_SHORT = `${SHORT_DATE_FORMAT} ${TIME_FORMAT}`;
/* Формат отображения даты и времени*/
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
/* Формат отображения даты и времени с двойным пробелом */
export const DATE_TIME_FORMAT_DS = `${DATE_FORMAT}  ${TIME_FORMAT}`;

/* DATE-FNS */
/* Формат даты для дашборда */
export const DASHBOARD_DATE_FORMAT = 'yyyy-MM-dd';
/* Формат времени для дашборда */
export const DASHBOARD_TIME_FORMAT = 'HH:mm:ssxxx';
/* Формат даты и времени для дашборда */
export const DASHBOARD_DATE_TIME_FORMAT = `${DASHBOARD_DATE_FORMAT}'T'${DASHBOARD_TIME_FORMAT}`;
/* Формат даты */
export const DF_REQUEST_DATE_FORMAT = 'yyyy-MM-dd';
export const DF_DATE_FORMAT = 'dd.MM.yyyy';
/* Формат короткой даты */
export const DF_SHORT_DATE_FORMAT = 'dd.MM.yy';
/* Формат времени */
export const DF_TIME_FORMAT = 'HH:mm';
/* Формат отображения даты и времени (короткий) */
export const DF_DATE_TIME_FORMAT_SHORT = `${DF_SHORT_DATE_FORMAT} ${DF_TIME_FORMAT}`;
export const DF_DATE_TIME_FORMAT = `${DF_DATE_FORMAT} ${TIME_FORMAT}`;
/* Формат даты и времени для пикеров */
export const DF_DATE_TIME_PICKER_FORMAT = `${DF_DATE_FORMAT}  ${DF_TIME_FORMAT}`;
/* Формат полного названия дня Понедельник, Вторник, Среда */
export const DF_DAY_FORMAT = 'EEEE';
/* Формат сокращенного названия дня Пн, Вт, Ср */
export const DF_SHORT_DAY_FORMAT = 'EEEEEE';
export const DF_TIME_DURATION = 'HH ч  mm мин';

/* Типы категорий клиентов */
export const CATEGORY_1 = 'category_1';
export const CATEGORY_2 = 'category_2';
export const CATEGORY_3 = 'category_3';
export type TClientCategory = typeof CATEGORY_1 | typeof CATEGORY_2 | typeof CATEGORY_3;

/**
 * ------------------
 * Ресурсы
 * ------------------
 */

/* Максималное количество телефонов для водителя */
export const RESOURCE_DRIVER_MAX_PHONES = 2;
/* Регулярка для валидации общих букв в латинице и кириллице */
export const LATIN_CIRILLIC_REG = /[^ABEKMHOPCTYXАВЕКМНОРСТУХ0-9 ]/g;
/* Регулярка для валидации номера vin */
export const VIN_NUMBER_REG = /[^a-hj-npr-z0-9]/gi;
/* Регулярка для валидации номера vin */
export const FULL_VIN_NUMBER_REG = /[(A-H|J-N|P|R-Z|0-9)]{1,17}/i;
// диапазон тоннажей на платформе, для параметров InputRange
export const MIN_VEHICLE_TONNAGE = 1;
export const MAX_VEHICLE_TONNAGE = 60;

/**
 * ------------------
 * Дни недели
 * ------------------
 */
export const MONDAY = 'monday';
export const TUESDAY = 'tuesday';
export const WEDNESDAY = 'wednesday';
export const THURSDAY = 'thursday';
export const FRIDAY = 'friday';
export const SATURDAY = 'saturday';
export const SUNDAY = 'sunday';

export const WEEK_ORDER_ARRAY = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

/**
 * Очередность при сортировке
 */
export const ASC = 'asc';
export const DESC = 'desc';

/**
 * Загрузка файлов
 */
/* Максимально допустимый размер загружаемых файлов */
export const MAX_UPLOAD_FILE_SIZE = 10 * 1024 * 1024;
/* Допустимые типы файлов для загрузки */
export const ACCEPTED_UPLOAD_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/xml',
  'text/xml',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
/* Допустимые типы файлов doc и docx */
export const ACCEPTED_DOC_FILE_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const XLSX_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const XLSM_FILE_TYPE = 'application/vnd.ms-excel.sheet.macroEnabled.12';
/* Допустимые типы файлов xls и xlsx */
export const ACCEPTED_XLS_FILE_TYPES = ['application/vnd.ms-excel', XLSX_FILE_TYPE];
export const ACCEPTED_RESOURCE_DOCS_FILE_TYPES = ['image/png', 'image/jpeg', 'application/pdf'];
export const ACCEPTED_XML_FILE_TYPES = ['application/atom+xml', 'application/xml', 'text/xml'];

/* типы торга */
export const INSTANT = 'instant_reservation';
export const AUCTION = 'auction';
export const PERSONAL_OFFER = 'personal_offer';

/* Запись в localStorage */
export const ACCESS_NOTIFICATION_READ = 'tp.accessNotificationRead';
export const DISMISS_NOTIFICATION = 'tp.dismissNotification';
export const DISMISS_CLOSING_DOCUMENTS_NOTIFICATION = 'tp.dismissClosingDocumentsNotification';

/* Тип работы с документами (document_flow) */
export const SCAN_COPY = 'scancopy';
export const ONLINE = 'online_confirmation';
export const QUALIFIED = 'qualified_signature';

export type TDocumentFlow = typeof SCAN_COPY | typeof ONLINE | typeof QUALIFIED;

/* Виды деятельности компании: */
/* Транспортная компания */
export const TRANSPORT_COMPANY = 'TransportCompany';
/* Грузовладелец */
export const CARGO_OWNING_COMPANY = 'CargoOwningCompany';
/*  Экспедитор (в будущих выпусках) */
export const FORWARDING_COMPANY = 'ForwardingCompany';

export type TCompanyType = typeof TRANSPORT_COMPANY | typeof CARGO_OWNING_COMPANY | typeof FORWARDING_COMPANY;

/* Длина промокода */
export const PROMOCODE_LENGTH = 6;

/* Длина смс кода */
export const SMS_CODE_LENGTH = 4;

/* Коды ОПФ */
export const INDIVIDUAL = {
  name: 'ИП',
  code: '50102',
  id: 4,
};

/* Ссылки на инструкции для НТК и ГО */
export const INSTRUCTION_CARRIER = 'https://wiki.traffic.online/pages/viewpage.action?pageId=327722';
export const INSTRUCTION_CARGO = 'https://wiki.traffic.online/pages/viewpage.action?pageId=327720';

/* Аналитика */
/*  Переменная активации отправки аналитики: TRUE на проде!!! */
export const ANALYTICS_ON = process.env.REACT_APP_ANALYTICS === 'enabled'; // TODO: delete
/* Яндекс.Метрика */
export const YM_COUNTER = 56351440; // TODO: delete
/* Google Analytics */
export const GA_COUNTER = 'UA-146236143-3'; // TODO: delete
/* CRM Bitrix */
export const BITRIX_CRM_CHAT_URL = 'https://crm.getcargo.ru/upload/crm/site_button/loader_1_guz31w.js';
/* Виды деятельности компании: */
export const A_TRANSPORT_COMPANY = 'perevozchik';
export const A_CARGO_OWNING_COMPANY = 'gruzovladelec';
/* Виды допуска НТК до заявок */
export const A_EXTERNAL_ONLY = 'Внешние';
export const A_WITH_PLATFORMS = 'Деловые линии/ДЛ-транс';

/* Флаг для рейтинга компаний */
export const ENABLE_RATING = true;

/* Fias ids */
export const SPB_FIAS_ID = 'c2deb16a-0330-4f05-821f-1d09c93331e6'; // Санкт-Петербург
export const LEN_OBL_FIAS_ID = '6d1ebb35-70c6-4129-bd55-da3969658f5d'; // Ленинградская область
export const MSK_FIAS_ID = '0c5b2444-70a0-4932-980c-b4dc0d3f02b5'; // Москва
export const MSK_OBL_FIAS_ID = '29251dcf-00a1-4e34-98d4-5c47484a36d4'; // Московская область

/* UTM метки */
export enum UtmCode {
  UtmCampaign = 'utm_campaign',
  UtmMedium = 'utm_medium',
  UtmSource = 'utm_source',
  UtmContent = 'utm_content',
  UtmTerm = 'utm_term',
  Referer = 'referer',
}

export const REG_AUTHOR = 'author';

export const REGEXP_DF_DATE_FORMAT = /^[0-9]{2}.{1}[0-9]{2}.{1}[0-9]{4}$/;
export const REGEXP_DF_DATE_TIME_PICKER_FORMAT = /^[0-9]{2}.{1}[0-9]{2}.{1}[0-9]{4}[ \t]{2}[0-9]{2}:[0-9]{2}$/;
export const DEFAULT_ORDER_VALUE = 'desc';

export const SYMBOLS = {
  LONG_DASH: '—',
  RUBLE: '₽',
};
