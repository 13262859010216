import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconCollapsedScreen = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 8H8.5V7L8.5 3H6.5L6.5 6L3.5 6V8L7.5 8ZM8.5 13V12H7.5H3.5V14H6.5L6.5 17H8.5V13ZM12.5 12H13.5L17.5 12V14L14.5 14L14.5 17H12.5L12.5 13V12ZM12.5 7V8H13.5H17.5V6H14.5V3H12.5V7Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconCollapsedScreen.defaultProps = {
  color: 'base',
};

export default IconCollapsedScreen;
