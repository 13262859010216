/**
 * Статусы подписок или тарифов
 *  * @type {string}
 */
export const PENDING = 'PENDING';
export const ACTIVE = 'ACTIVE';
export const BLOCKED = 'BLOCKED';
export const CANCELLED = 'CANCELLED';
export const EXPIRED = 'EXPIRED';

/**
 * Типы активаций
 * @type {string}
 */
export const APPEND = 'append';
export const IMMEDIATELY = 'immediately';

/**
 * Длительность тарифа
 */
export const THIRTY_DAYS = 'THIRTY_DAYS';
export const NINETY_DAYS = 'NINETY_DAYS';
export const PERIODS = {
  THIRTY_DAYS: 30,
  NINETY_DAYS: 90,
};

/**
 * Типы пакетов услуг и подписок
 * @type {string}
 */
export const BASE = 'BASE';
export const STANDALONE = 'STANDALONE';

/**
 * Неограниченное число услуги в пакете
 * @type {number}
 */
export const UNLIMITED = -1;

/**
 * Тип юнитов (услуг)
 */
export const REQUEST_MARKET_PRICE = 'request_market_price';
export const CREATE_MARKET_PRICE_TEMPLATE = 'create_market_price_template';
export const DOWNLOAD_MARKET_PRICE_FILE = 'download_market_price_file';
export const CREATE_INSTANT = 'create_instant_reservation_shipping_order';
export const CREATE_AUCTION = 'create_any_auction_shipping_order';
export const CREATE_CONTRACT = 'create_any_auction_contract_shipping_order';
export const WIN_REQUEST = 'win_request';
export const INSURANCE = 'insurance';

export const TC_MOCKED_SUBSCRIPTION = {
  id: '',
  category: 'BASE',
  price: {
    currency: 'RUB',
    value: 0,
  },
  state: ACTIVE,
  product_name: 'TC_Plans',
  product_category: 'BASE',
  plan_name: 'TCPS06',
  plan_pretty_name: '',
  plan_description: '',
  billing_period: NINETY_DAYS,
  start_date: '2021-04-15T00:00:00.000+00:00',
  billing_start_date: '2021-04-15T00:00:00.000+00:00',
  bill_cycle_day_local: 15,
  limits: [
    {
      unit_name: '',
      unit: WIN_REQUEST,
      description: '',
      max: -1,
    },
    {
      unit_name: '',
      unit: INSURANCE,
      description: '',
      max: -1,
    },
    {
      unit_name: '',
      unit: REQUEST_MARKET_PRICE,
      description: '',
      max: -1,
    },
    {
      unit_name: '',
      unit: CREATE_MARKET_PRICE_TEMPLATE,
      description: '',
      max: -1,
    },
    {
      unit_name: '',
      unit: DOWNLOAD_MARKET_PRICE_FILE,
      description: '',
      max: -1,
    },
  ],
  usage: [],
};

export const CO_MOCKED_SUBSCRIPTION = {
  id: '',
  category: 'BASE',
  price: {
    currency: 'RUB',
    value: 0,
  },
  state: ACTIVE,
  product_name: 'TC_Plans',
  product_category: 'BASE',
  plan_name: 'COPS06',
  plan_pretty_name: '',
  plan_description: '',
  billing_period: NINETY_DAYS,
  start_date: '2021-04-15T00:00:00.000+00:00',
  billing_start_date: '2021-04-15T00:00:00.000+00:00',
  bill_cycle_day_local: 15,
  limits: [
    {
      unit_name: '',
      unit: WIN_REQUEST,
      description: '',
      max: -1,
    },
    {
      unit_name: '',
      unit: INSURANCE,
      description: '',
      max: -1,
    },
    {
      unit_name: '',
      unit: REQUEST_MARKET_PRICE,
      description: '',
      max: -1,
    },
    {
      unit_name: '',
      unit: CREATE_MARKET_PRICE_TEMPLATE,
      description: '',
      max: -1,
    },
    {
      unit_name: '',
      unit: DOWNLOAD_MARKET_PRICE_FILE,
      description: '',
      max: -1,
    },
    {
      unit_name: '',
      unit: CREATE_INSTANT,
      description: '',
      max: -1,
    },
    {
      unit_name: '',
      unit: CREATE_AUCTION,
      description: '',
      max: -1,
    },
    {
      unit_name: '',
      unit: CREATE_CONTRACT,
      description: '',
      max: -1,
    },
  ],
  usage: [],
};
