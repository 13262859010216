import { TAX_WITH, TAX_WITHOUT } from '../store/order/auction/formKeys';

export function getTaxTypeLabel(taxType) {
  return taxType === TAX_WITHOUT ? 'без НДС' : taxType === TAX_WITH ? 'с НДС' : '';
}
export function usePriceTaxType(originalShipping) {
  // shipping_request with shipping
  const priceTaxType = originalShipping?.shipping_request_info?.price_tax_type ?? TAX_WITHOUT;

  const taxTypeLabel = getTaxTypeLabel(priceTaxType);
  return {
    priceTaxType,
    taxTypeLabel,
  };
}

export function useAuctionPrice(originalAuctionInfo, originalOrder) {
  const { priceTaxType } = usePriceTaxType(originalOrder?.shipping);

  return {
    price: priceTaxType === TAX_WITH ? originalAuctionInfo?.price_with_vat : originalAuctionInfo?.price_without_vat,
  };
}
