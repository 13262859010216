import React from 'react';
import SVGIcon from './index';

export default function IconPlus(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M11 9V4H9V9H4V11H9V16H11V11H16V9H11Z" />
    </SVGIcon>
  );
}

IconPlus.defaultProps = {
  color: 'primary',
};
