import endOfDay from 'date-fns/endOfDay';
import {
  DASHBOARD_DATE_TIME_FORMAT,
  DEFAULT_APP_TIME_ZONE_NAME,
  DF_DATE_FORMAT,
  DF_DATE_TIME_PICKER_FORMAT,
  INSTANT,
  UTC_TIME_ZONE_NAME,
} from '../../../constants';
import { getUnformattedPrice } from '../../../helpers/getFormattedPrice';
import { CONTACTS, COUNTER_AGENT, EXTENSION_NUMBER, PHONE } from '../../../store/counterAgents/formFields';
import { getCounterAgentMappedData } from '../../../store/counterAgents/selectors';
import { COMPANIES, DESCENDING_PRICE_AUCTION, EXCLUSIVE, GROUPS } from '../../../store/order/auction/formKeys';
import { WITHOUT_HAZARD } from '../../../store/order/cargo/formKeys';
import { PREPAYMENT } from '../../../store/order/formalization/formKeys';
import {
  ADDITIONAL_SERVICES,
  FLOOR_COUNT,
  MAX_SHIPPING_PER_DAY,
  MAX_SHIPPING_PER_WEEK,
  RISE_FLOOR,
} from '../../../store/order/routes/formFields';
import { CONTRACT, CONTRACT_WITH_QUOTES, LOADING, UNLOADING } from '../../../store/order/routes/formKeys';
import { TMappedAddService } from '../../../store/order/routes/model';
import {
  AUCTION_STAGES,
  AUCTION_TYPE,
  AUTO_EXTENDED,
  BID_STEP,
  ENABLE_AUCTION_AUTO_EXTEND,
  ENDS_AT,
  EXECUTORS,
  EXECUTOR_PRICE,
  INTERMEDIATE_RESULTS_PERIOD,
  IS_EXECUTOR_KNOWN,
  MANY_EXECUTORS_ALLOWED,
  MAX_BID_WITHOUT_TAX,
  MIN_BID_WITHOUT_TAX,
  PRICE_TYPE,
  PRICE_WITHOUT_TAX,
  VISIBILITY,
  WITHOUT_PRICES,
  EXECUTORS_ARRAY,
} from '../blocks/AuctionBlock';
import {
  CARGO_COST,
  CARGO_HEIGHT,
  CARGO_LENGTH,
  CARGO_NAME,
  CARGO_PACKING,
  CARGO_PLACES,
  CARGO_TONNAGE,
  CARGO_VOLUME,
  CARGO_WIDTH,
  HAZARD_CLASS,
} from '../blocks/CargoBlock';
import {
  AGREEMENT_TYPE,
  CANCELLATION_PENALTY_PERCENT,
  CANCELLATION_WITHOUT_PENALTY_BEFORE,
  CLOSING_DOCUMENTS_PERIOD,
  CLOSING_DOCUMENTS_PERIOD_TYPE,
  COMMENT,
  CONTRACT_RESOURCE_ASSIGNMENT_TIME,
  DOCUMENTS_FOR_PAYMENT,
  DOCUMENT_FLOWS,
  EXTERNAL_HUMAN_FRIENDLY_ID,
  IDLE_TIME_COST,
  MINIMUM_CANCELLATION_PENALTY,
  PAYER,
  PAYER_CONTACTS,
  PAYER_FULL_NAME,
  PAYER_INN,
  PAYER_IS_PRIVATE_PERSON,
  PAYER_KPP,
  PAYER_LEGAL_ADDRESS,
  PAYER_OPF_FORM,
  PAYER_PASSPORT_ISSUED_AT,
  PAYER_PASSPORT_ISSUER,
  PAYER_PASSPORT_NUMBER,
  PAYER_TITLE,
  PAYMENT_PERIOD,
  PAYMENT_PERIOD_TYPE,
  PAYMENT_PROCEDURE,
  RESOURCE_ASSIGNMENT_TIME,
  RESOURCE_DEADLINE_OPTION,
  SHIPPING_AVAILABLE_IN,
  TERMS_BY_PARTNERSHIP_CONTRACT,
} from '../blocks/DocumentsBlock';
import { EXTRA_ORDERS_LIST, EXTRA_ORDERS_LIST_POINTS } from '../blocks/ExtraOrdersBlock';
import {
  CONTRACT_BY_COUNT,
  FORM_TYPE,
  FULL,
  ORDER_TYPE,
  SHIPPING_REQUEST,
  SIMPLE,
} from '../blocks/OrderNFormTypeBlock';
import {
  NEW_WAREHOUSE_SLOT,
  ROUTE_ADDRESS_CITY,
  ROUTE_ADDRESS_HOUSE,
  ROUTE_ADDRESS_ROOM,
  ROUTE_ADDRESS_STREET,
  ROUTE_CONTRACT_BEGINS_AT,
  ROUTE_CONTRACT_ENDS_AT,
  ROUTE_COVER_TYPE,
  ROUTE_DATES,
  ROUTE_DATE_FROM,
  ROUTE_DATE_TILL,
  ROUTE_OPERATIONS,
  ROUTE_POINTS,
  ROUTE_PRR,
  ROUTE_PRR_MIN,
  ROUTE_RADIUS,
  ROUTE_SHIPPINGS_COUNT,
  WAREHOUSE_ID,
  WITH_QUOTES,
  QUOTA_DISTRIBUTION,
  QUOTA_TYPE,
  ADDITIONAL_AUCTION,
  EXACT_ADDRESS,
  ROUTE_ADDRESS_COMMENT,
} from '../blocks/RoutesBlock';
import {
  CMR_DOCUMENT,
  COVERALLS,
  DISABLE_AUTO_CHANGES,
  IS_DISINFECTED,
  MEDICAL_BOOK,
  NUMBER_OF_BELTS,
  ORIGINAL_SHIPPING_POWER_OF_ATTORNEY,
  OWN_TRANSPORT_ONLY,
  REMOVABLE_SIDE_RACKS,
  REMOVABLE_UPPER_BEAM,
  RESOURCE_REQUIREMENTS,
  RIGID_BOARD,
  RUSSIAN_CITIZENSHIP,
  TEMPERATURE_CHECK,
  TEMPERATURE_CONDITION,
  TEMPERATURE_CONDITION_MAX,
  TEMPERATURE_CONDITION_MIN,
  TIMBER_BUNKS,
  TIR_DOCUMENT,
  TONNAGE_VOLUME,
  TRANSPORT_BODY_TYPES,
  TWO_SIDED,
} from '../blocks/TransportBlock';
import { getIsAuctionForAll } from './getAuctionStageInfo';
import { getDateWithTimezone, getZonedDateString } from './getDateWithTimezone';
import { getHashString } from './getHashString';
import { WITH_QUOTES_MAP } from '../components/ContractByCountInfo';
import { AUCTION_FORM_FIELDS } from '../../../features/quotes/auctionForm';
// import { WITHOUT_GROUP } from './getPartnersTree';
import { PACKAGE_ITEMS } from '../../../store/order/formFields';
import {
  INHERITANCE_MIN_INSURANCE_SUM,
  INHERITANCE_TONNAGE,
  INSURANCE_METHOD,
  INSURANCE_REQUIRED,
  INSURANCE_SUM,
} from '../../../features/insurance/constants';
import { TYPES } from '../../../features/insurance/components/ProfileFormInsurance/constants';
import { TGeoLocationLevel } from '../../../store/counterAgents/models';

function getMappedRoutePointDates(
  dates,
  timezone = DEFAULT_APP_TIME_ZONE_NAME,
  withoutInterval,
  dateFormat,
  isWarehouse,
) {
  return dates
    .map((date) => {
      const anyDate = withoutInterval ? date[ROUTE_DATE_FROM] : date[ROUTE_DATE_TILL] || date[ROUTE_DATE_FROM];
      if (anyDate) {
        const from = date[ROUTE_DATE_FROM]
          ? getZonedDateString(getDateWithTimezone(date[ROUTE_DATE_FROM], timezone, dateFormat), timezone)
          : null;
        const till = isWarehouse
          ? getZonedDateString(
              getDateWithTimezone(date[ROUTE_DATE_FROM], timezone, dateFormat),
              timezone,
              DASHBOARD_DATE_TIME_FORMAT,
              endOfDay,
            )
          : getZonedDateString(getDateWithTimezone(anyDate, timezone, dateFormat), timezone);
        return { from, till };
      }
      return null;
    })
    .filter(Boolean);
}

/**
 * Получаем дополнительные услуги в точке маршрута
 * @param point
 * @param serviceList
 */
function getRouteAdditionalServices(point, serviceList) {
  const pointAdditionalServices = point[ADDITIONAL_SERVICES];

  const selectedServices = Object.entries(pointAdditionalServices || {}).reduce((acc: any, [key, value]) => {
    if (value) acc.push(key);
    return acc;
  }, []);

  const services = serviceList.reduce((acc: any, item: TMappedAddService) => {
    if (item && selectedServices.includes(item.id)) {
      acc.push(item);
    }
    return acc;
  }, []);

  return services.map((service) => {
    const { id, name } = service;
    if (id === RISE_FLOOR) {
      return { id, name, count: point?.[FLOOR_COUNT] || null, price: 0 };
    }
    return { id, name, count: 1, price: 0 };
  });
}

function getMappedContacts(contacts) {
  return (
    contacts?.map((contact) => ({
      ...contact,
      [PHONE]: contact[PHONE] || null,
      [EXTENSION_NUMBER]: contact[EXTENSION_NUMBER] || null,
    })) || null
  );
}

function getMappedRoutePoints({
  routePoints,
  isContract,
  isSimple,
  serviceList = [],
  isOrderBasedOnContract = false,
  isPublishing = false,
  isOrderChange = false,
}) {
  return routePoints.map((point, index) => {
    const dates = point[ROUTE_DATES];
    const city = point[ROUTE_ADDRESS_CITY]?.value;
    const street = isSimple ? null : point[ROUTE_ADDRESS_STREET]?.value;
    const house = isSimple ? null : point[ROUTE_ADDRESS_HOUSE]?.value;
    const fiasId = house?.fias_id || street?.fias_id || city?.fias_id;
    const exactAddress = point[EXACT_ADDRESS];
    const location = {
      house: isSimple ? null : point[ROUTE_ADDRESS_HOUSE]?.label || null,
      office: isSimple ? null : point[ROUTE_ADDRESS_ROOM] || null,
      fias_id: fiasId || null,
      lat: isSimple ? city?.lat : exactAddress?.coords[0] || house?.lat || street?.lat || city?.lat,
      lng: isSimple ? city?.lng : exactAddress?.coords[1] || house?.lng || street?.lng || city?.lng,
      ...(exactAddress?.coords ? { level: TGeoLocationLevel.Point } : {}),
      comment: isSimple ? null : point[ROUTE_ADDRESS_COMMENT] || null,
    };
    const warehouseId = point[WAREHOUSE_ID];
    const isWarehouse = !!warehouseId;
    const carSuppleRanges = isContract
      ? []
      : getMappedRoutePointDates(
          isSimple ? [dates[0]] : dates,
          city?.timezone_name,
          isSimple,
          isWarehouse ? DF_DATE_FORMAT : DF_DATE_TIME_PICKER_FORMAT,
          isWarehouse,
        );
    const operations = isSimple ? (index > 0 ? [UNLOADING] : [LOADING]) : point[ROUTE_OPERATIONS];
    const operationsDuration = +point[ROUTE_PRR] * 60 + +point[ROUTE_PRR_MIN];
    const additionalServices = isSimple ? [] : getRouteAdditionalServices(point, serviceList);
    const contacts = isSimple && !isWarehouse ? [{}] : getMappedContacts(point[CONTACTS]) ?? [{}];
    const counterAgent =
      isSimple && !isWarehouse ? null : getCounterAgentMappedData(point[COUNTER_AGENT]) ?? { private_person: false };
    return {
      number: index + 1,
      location,
      ...(isContract || (isOrderBasedOnContract && !isPublishing)
        ? { radius: !!point[ROUTE_RADIUS] ? +point[ROUTE_RADIUS] : null }
        : {}),
      car_supply_ranges: carSuppleRanges,
      operations,
      operations_loadings: point[ROUTE_COVER_TYPE]?.map((item) => item.value) ?? [],
      operations_duration: operationsDuration ?? 0,
      additional_services: additionalServices,
      contacts,
      counter_agent: counterAgent,
      warehouse_id: point[WAREHOUSE_ID],
    };
  });
}

const defaultResourceRequirements = {
  [CMR_DOCUMENT]: false,
  [COVERALLS]: false,
  [IS_DISINFECTED]: false,
  [MEDICAL_BOOK]: false,
  [NUMBER_OF_BELTS]: null,
  [ORIGINAL_SHIPPING_POWER_OF_ATTORNEY]: false,
  [OWN_TRANSPORT_ONLY]: false,
  [REMOVABLE_SIDE_RACKS]: false,
  [REMOVABLE_UPPER_BEAM]: false,
  [RIGID_BOARD]: false,
  [RUSSIAN_CITIZENSHIP]: false,
  [TEMPERATURE_CHECK]: false,
  [TEMPERATURE_CONDITION]: null,
  [TIMBER_BUNKS]: false,
  [TIR_DOCUMENT]: false,
  [TWO_SIDED]: false,
};

function getMappedResourceRequirements(requirements) {
  const hasTempCondition =
    requirements[TEMPERATURE_CONDITION] ??
    requirements[TEMPERATURE_CONDITION_MIN] ??
    requirements[TEMPERATURE_CONDITION_MAX];
  return {
    [CMR_DOCUMENT]: requirements[CMR_DOCUMENT],
    [COVERALLS]: requirements[COVERALLS],
    [IS_DISINFECTED]: requirements[IS_DISINFECTED],
    [MEDICAL_BOOK]: requirements[MEDICAL_BOOK],
    [NUMBER_OF_BELTS]: requirements[NUMBER_OF_BELTS] ? Number(requirements[NUMBER_OF_BELTS]) : null,
    [ORIGINAL_SHIPPING_POWER_OF_ATTORNEY]: requirements[ORIGINAL_SHIPPING_POWER_OF_ATTORNEY],
    [OWN_TRANSPORT_ONLY]: requirements[OWN_TRANSPORT_ONLY],
    [REMOVABLE_SIDE_RACKS]: requirements[REMOVABLE_SIDE_RACKS],
    [REMOVABLE_UPPER_BEAM]: requirements[REMOVABLE_UPPER_BEAM],
    [RIGID_BOARD]: requirements[RIGID_BOARD],
    [RUSSIAN_CITIZENSHIP]: requirements[RUSSIAN_CITIZENSHIP],
    [TEMPERATURE_CHECK]: requirements[TEMPERATURE_CHECK],
    [TEMPERATURE_CONDITION]: hasTempCondition
      ? {
          min: requirements[TEMPERATURE_CONDITION_MIN],
          max: requirements[TEMPERATURE_CONDITION_MAX],
        }
      : null,
    [TIMBER_BUNKS]: requirements[TIMBER_BUNKS],
    [TIR_DOCUMENT]: requirements[TIR_DOCUMENT],
    [TWO_SIDED]: requirements[TWO_SIDED],
  };
}

// function getExclusiveIdsList(entity) {
//   return Object.entries(entity ?? {}).reduce((acc: any, [key, value]) => {
//     if (value && key !== WITHOUT_GROUP) acc.push(key);
//     return acc;
//   }, []);
// }
const getPriceValue = (value) => (value ? Number(value) : null);

function getMappedAuctionStages(auctionStages, isContract, isExecutorKnown, isSimpleForm, withQuotes?) {
  if (isContract && isExecutorKnown) return null;
  if (auctionStages?.length <= 0) return [];

  const stages = isSimpleForm ? [auctionStages[0]] : auctionStages;
  return stages.map(({ partners, ...auctionStage }, index) => {
    const partnersInfo =
      auctionStage[VISIBILITY] === EXCLUSIVE
        ? {
            companies_ids: partners?.[COMPANIES] ?? [], //getExclusiveIdsList(partners?.[COMPANIES]),
            groups_ids: partners?.[GROUPS] ?? [], // getExclusiveIdsList(partners?.[GROUPS]),
          }
        : {};
    const intermediateResultsPeriod =
      auctionStage[AUCTION_TYPE] !== INSTANT && !isContract
        ? { intermediate_results_period: Number(auctionStage[INTERMEDIATE_RESULTS_PERIOD]) || null }
        : {};
    const maxBids =
      auctionStage[AUCTION_TYPE] !== DESCENDING_PRICE_AUCTION
        ? {
            bid_step: auctionStage[WITHOUT_PRICES] ? 1 : getPriceValue(auctionStage[BID_STEP]),
            max_bid_without_vat: auctionStage[WITHOUT_PRICES] ? null : getPriceValue(auctionStage[MAX_BID_WITHOUT_TAX]),
          }
        : {
            bid_step: getPriceValue(auctionStage[BID_STEP]),
            max_bid_without_vat: getPriceValue(auctionStage[PRICE_WITHOUT_TAX]),
          };
    const bidInfo =
      auctionStage[AUCTION_TYPE] !== INSTANT
        ? {
            min_bid_without_vat: auctionStage[WITHOUT_PRICES] ? null : getPriceValue(auctionStage[MIN_BID_WITHOUT_TAX]),
            ...maxBids,
          }
        : {};
    const priceInfo =
      auctionStage[AUCTION_TYPE] !== INSTANT && auctionStage[WITHOUT_PRICES]
        ? {
            price_without_vat: null,
          }
        : {
            price_without_vat: getPriceValue(auctionStage[PRICE_WITHOUT_TAX]),
          };
    const prevEndsAt = index > 0 ? auctionStages[index - 1][ENDS_AT] : null;
    const beginsAt = prevEndsAt
      ? getZonedDateString(
          getDateWithTimezone(prevEndsAt, DEFAULT_APP_TIME_ZONE_NAME, DF_DATE_TIME_PICKER_FORMAT),
          DEFAULT_APP_TIME_ZONE_NAME,
        )
      : getZonedDateString(new Date(), DEFAULT_APP_TIME_ZONE_NAME);
    return {
      number: index + 1,
      auction_type: auctionStage[AUCTION_TYPE],
      price_type: auctionStage[PRICE_TYPE],
      visibility: auctionStage[VISIBILITY],
      ...bidInfo,
      ...priceInfo,
      ...intermediateResultsPeriod,
      ...partnersInfo,
      winner_selection_type: isContract ? 'manually' : 'automatically',
      ...(isContract ? { many_executors_allowed: withQuotes ? true : auctionStage[MANY_EXECUTORS_ALLOWED] } : {}),
      begins_at: beginsAt,
      ends_at: auctionStage[ENDS_AT]
        ? getZonedDateString(
            getDateWithTimezone(auctionStage[ENDS_AT], DEFAULT_APP_TIME_ZONE_NAME, DF_DATE_TIME_PICKER_FORMAT),
            DEFAULT_APP_TIME_ZONE_NAME,
          )
        : null,
    };
  });
}

function getMappedExecutors(executors, executorPrice, isContract, isExecutorKnown) {
  if (isContract && isExecutorKnown && executors) {
    if (!Array.isArray(executors)) {
      return [{ company_id: executors?.value, price: parseFloat(executorPrice) }];
    } else {
      return executors.map((executor) => {
        return {
          company_id: executor?.value,
          price: parseFloat(executor?.price),
        };
      });
    }
  }
  return [];
}

function getContractInfo(values) {
  const withQuotes = values[WITH_QUOTES] === WITH_QUOTES_MAP.use;
  const isExecutorKnown = values[IS_EXECUTOR_KNOWN];
  const isSimpleForm = values[FORM_TYPE] === SIMPLE;

  const executors = withQuotes ? values[EXECUTORS_ARRAY] : values[EXECUTORS];

  const beginsAt = values[ROUTE_CONTRACT_BEGINS_AT]
    ? getDateWithTimezone(values[ROUTE_CONTRACT_BEGINS_AT], UTC_TIME_ZONE_NAME, DF_DATE_FORMAT).toISOString()
    : null;
  const endsAt = values[ROUTE_CONTRACT_ENDS_AT]
    ? getDateWithTimezone(values[ROUTE_CONTRACT_ENDS_AT], UTC_TIME_ZONE_NAME, DF_DATE_FORMAT).toISOString()
    : null;

  // quotes:
  const type = values[WITH_QUOTES] === WITH_QUOTES_MAP.use ? CONTRACT_WITH_QUOTES : undefined;

  return {
    type: CONTRACT,
    contract_type: type || values[ORDER_TYPE],
    [QUOTA_TYPE]: values[QUOTA_TYPE],
    [QUOTA_DISTRIBUTION]: values[QUOTA_DISTRIBUTION],
    [ADDITIONAL_AUCTION]: withQuotes && values[AUCTION_FORM_FIELDS.WITH_AUCTION] ? values[ADDITIONAL_AUCTION] : null,
    contract_begins_at: beginsAt,
    contract_ends_at: endsAt,
    shippings_count: values[ROUTE_SHIPPINGS_COUNT] || null,
    max_shippings_per_day: values[MAX_SHIPPING_PER_DAY] || null,
    max_shippings_per_week: values[MAX_SHIPPING_PER_WEEK] || null,
    auction_stages: getMappedAuctionStages(values[AUCTION_STAGES], true, isExecutorKnown, isSimpleForm, withQuotes),
    executors: getMappedExecutors(executors, values[EXECUTOR_PRICE], true, isExecutorKnown),
    shipping_available_in: values[SHIPPING_AVAILABLE_IN] ? values[SHIPPING_AVAILABLE_IN] * 60 : null,
    resource_assignment_time: values[CONTRACT_RESOURCE_ASSIGNMENT_TIME]
      ? values[CONTRACT_RESOURCE_ASSIGNMENT_TIME] * 60
      : null,
    resource_deadline_option: values[RESOURCE_DEADLINE_OPTION],
  };
}

function getOrderBasedContractInfo(values, currentOrder, isDraftEditing) {
  const contractId = isDraftEditing ? currentOrder.contract_id : currentOrder.id;
  const beginsAt = currentOrder[ROUTE_CONTRACT_BEGINS_AT];
  const endsAt = currentOrder[ROUTE_CONTRACT_ENDS_AT];
  const shippingsCount = currentOrder[ROUTE_SHIPPINGS_COUNT];
  const maxPerDay = currentOrder[MAX_SHIPPING_PER_DAY];
  const maxPerWeek = currentOrder[MAX_SHIPPING_PER_WEEK];
  return {
    auction_stages: [],
    contract_shipping_price: +getUnformattedPrice(values[EXECUTOR_PRICE]),
    cancellation_without_penalty_before: null,
    contract_id: contractId,
    executors: [],
    type: SHIPPING_REQUEST,

    contract_begins_at: beginsAt,
    contract_ends_at: endsAt,
    shippings_count: shippingsCount,
    max_shippings_per_day: maxPerDay,
    max_shippings_per_week: maxPerWeek,
  };
}

function getFullCargoInfo(values) {
  return {
    cargo_name: values[CARGO_NAME]?.value || null,
    cargo_adr: values[HAZARD_CLASS] !== WITHOUT_HAZARD ? values[HAZARD_CLASS] : null,
    cargo_tonnage: Number(values[CARGO_TONNAGE]),
    cargo_volume: values[CARGO_VOLUME] ? Number(values[CARGO_VOLUME]) : 0,
    cargo_cost: values[CARGO_COST] ? Number(values[CARGO_COST]) : null,
    cargo_length: values[CARGO_LENGTH] ? Number(values[CARGO_LENGTH]) : null,
    cargo_width: values[CARGO_WIDTH] ? Number(values[CARGO_WIDTH]) : null,
    cargo_height: values[CARGO_HEIGHT] ? Number(values[CARGO_HEIGHT]) : null,
    cargo_places: values[CARGO_PLACES] ? Number(values[CARGO_PLACES]) : null,
    cargo_packing: values[CARGO_PACKING],
  };
}

function getSimpleCargoInfo(values) {
  return {
    cargo_name: values[CARGO_NAME]?.value || null,
    cargo_adr: values[HAZARD_CLASS] !== WITHOUT_HAZARD ? values[HAZARD_CLASS] : null,
    cargo_tonnage: Number(values[CARGO_TONNAGE]),
    cargo_volume: 0,
    cargo_cost: null,
    cargo_length: 13.5,
    cargo_width: 2.4,
    cargo_height: 2.4,
    cargo_places: null,
    cargo_packing: PACKAGE_ITEMS.get('not_specified').value,
  };
}

/**
 * Формирование объекта слотов ТОЛЬКО!!! для ИТ
 * @param values
 */
function getWarehouseInfo(values) {
  const routePoints = values.route_points;
  const warehouseInfo = routePoints
    .map((point, index) => {
      if (point[WAREHOUSE_ID] && point[NEW_WAREHOUSE_SLOT]) {
        return {
          route_point_id: index + 1,
          gate_id: point[NEW_WAREHOUSE_SLOT].gateId,
          from: point[NEW_WAREHOUSE_SLOT].from,
          till: point[NEW_WAREHOUSE_SLOT].till,
        };
      }
      return null;
    })
    .filter(Boolean);

  if (warehouseInfo.length) {
    return { warehouse_info: warehouseInfo };
  }

  return {};
}

function getPartnershipTerms(values) {
  const isContract = values[ORDER_TYPE] !== SHIPPING_REQUEST;
  const termsByContract = values[TERMS_BY_PARTNERSHIP_CONTRACT];
  const paymentPeriod = values[PAYMENT_PROCEDURE] !== PREPAYMENT ? values[PAYMENT_PERIOD] : null;
  const auctionForAllCompanies = getIsAuctionForAll(values);
  const withQuotes = values[WITH_QUOTES] === WITH_QUOTES_MAP.use;

  const emptyTermsFields = {
    agreement_type: null,
    idle_time_cost_without_vat: null,
    payment_procedure: null,
    payment_period: null,
    payment_period_type: null,
    cancellation_without_penalty_before: null,
    cancellation_penalty_percent: null,
    minimum_cancellation_penalty_without_vat: null,
    closing_documents_period: null,
    closing_documents_period_type: null,
    documents_for_payment: [],
  };

  return termsByContract && !auctionForAllCompanies && !withQuotes
    ? { terms_by_partnership_contract: true, ...emptyTermsFields } // в аукционе участвуют только партнёры и выбрана галка
    : {
        // в аукционе могут участвовать все. состояние галки может быть любым
        terms_by_partnership_contract: termsByContract,
        agreement_type: values[AGREEMENT_TYPE] ?? 'shipping_agreement',
        idle_time_cost_without_vat: values[IDLE_TIME_COST],
        payment_procedure: values[PAYMENT_PROCEDURE],
        payment_period: paymentPeriod,
        payment_period_type: values[PAYMENT_PERIOD_TYPE],
        ...(isContract
          ? {
              cancellation_without_penalty_before: null,
              cancellation_penalty_percent: null,
              minimum_cancellation_penalty_without_vat: null,
            }
          : {
              cancellation_without_penalty_before: values[CANCELLATION_WITHOUT_PENALTY_BEFORE]
                ? getZonedDateString(
                    getDateWithTimezone(
                      values[CANCELLATION_WITHOUT_PENALTY_BEFORE],
                      DEFAULT_APP_TIME_ZONE_NAME,
                      DF_DATE_TIME_PICKER_FORMAT,
                    ),
                    DEFAULT_APP_TIME_ZONE_NAME,
                  )
                : null,
              cancellation_penalty_percent: values[CANCELLATION_PENALTY_PERCENT]
                ? Number(values[CANCELLATION_PENALTY_PERCENT])
                : null,
              minimum_cancellation_penalty_without_vat: values[MINIMUM_CANCELLATION_PENALTY]
                ? Number(values[MINIMUM_CANCELLATION_PENALTY])
                : null,
            }),
        closing_documents_period: values[CLOSING_DOCUMENTS_PERIOD] ?? null,
        closing_documents_period_type: values[CLOSING_DOCUMENTS_PERIOD_TYPE],
        // closing_documents: closingDocuments,
        documents_for_payment: values[DOCUMENTS_FOR_PAYMENT],
      };
}

export function getPayer(payer) {
  if (payer[PAYER_IS_PRIVATE_PERSON]) {
    return {
      [PAYER_IS_PRIVATE_PERSON]: payer[PAYER_IS_PRIVATE_PERSON],
      [PAYER_FULL_NAME]: payer[PAYER_FULL_NAME],
      [PAYER_PASSPORT_NUMBER]: payer[PAYER_PASSPORT_NUMBER],
      [PAYER_PASSPORT_ISSUER]: payer[PAYER_PASSPORT_ISSUER],
      [PAYER_PASSPORT_ISSUED_AT]: payer[PAYER_PASSPORT_ISSUED_AT],
      [PAYER_INN]: payer[PAYER_INN],
      [PAYER_CONTACTS]: payer[PAYER_CONTACTS],
    };
  }
  return {
    [PAYER_IS_PRIVATE_PERSON]: payer[PAYER_IS_PRIVATE_PERSON],
    [PAYER_TITLE]: payer[PAYER_TITLE],
    [PAYER_INN]: payer[PAYER_INN],
    [PAYER_KPP]: payer[PAYER_KPP],
    [PAYER_OPF_FORM]: payer[PAYER_OPF_FORM],
    [PAYER_LEGAL_ADDRESS]: payer[PAYER_LEGAL_ADDRESS],
    [PAYER_CONTACTS]: payer[PAYER_CONTACTS],
  };
}

/* Составление итогового объекта заказа для оправки на бекенд */
export function getFormData(values, params) {
  const {
    userInfo,
    serviceList,
    isOrderBasedOnContract,
    currentOrder,
    isDraftEditing,
    isOrderChange,
    isPublishing,
    globalInsuranceSettings,
  } = params;

  const result =
    getFormMapper(values[ORDER_TYPE], values[FORM_TYPE])(values, {
      serviceList,
      isOrderBasedOnContract,
      isOrderChange,
      isPublishing,
    }) || {};
  const partnershipTerms = getPartnershipTerms(values);
  const payer = getPayer(values[PAYER]);
  const orderBasedContractInfo = isOrderBasedOnContract
    ? getOrderBasedContractInfo(values, currentOrder, isDraftEditing)
    : {};

  const insuranceParams = ((values) => {
    const insuranceMethod = values[INSURANCE_METHOD];
    const insuranceSum = +values[INSURANCE_SUM] * 100;
    const inheritTonnage = [TYPES.REQUIRED, TYPES.CARGO_COST].includes(insuranceMethod);
    const inheritSum = insuranceMethod === TYPES.REQUIRED;

    const inheritParams = {
      [INSURANCE_METHOD]: insuranceMethod,
      [INHERITANCE_MIN_INSURANCE_SUM]: inheritSum ? globalInsuranceSettings?.insuranceSum : null,
      [INHERITANCE_TONNAGE]: inheritTonnage ? globalInsuranceSettings?.insuranceTonnage : null,
    };

    if (globalInsuranceSettings?.inheritInsuranceSettings) {
      inheritParams[INHERITANCE_MIN_INSURANCE_SUM] = inheritSum ? currentOrder?.[INHERITANCE_MIN_INSURANCE_SUM] : null;
      inheritParams[INHERITANCE_TONNAGE] = inheritTonnage ? currentOrder?.[INHERITANCE_TONNAGE] : null;
    }

    return {
      [INSURANCE_REQUIRED]: values[INSURANCE_REQUIRED],
      [INSURANCE_SUM]: values[INSURANCE_REQUIRED] ? insuranceSum : null,
      ...inheritParams,
    };
  })(values);

  return {
    ...values[TONNAGE_VOLUME],
    transport_body_types: values[TRANSPORT_BODY_TYPES],
    change_requests_allowed: true,
    document_flows: values[DOCUMENT_FLOWS],
    disable_auto_changes: !values[DISABLE_AUTO_CHANGES], // на фронте противоположный смысл

    ...insuranceParams,
    ...result,
    ...partnershipTerms,
    ...orderBasedContractInfo,

    payer,
    external_human_friendly_id: values[EXTERNAL_HUMAN_FRIENDLY_ID] || null,
    contact: {
      full_name: userInfo?.fullName || '',
      phone: userInfo?.phonePerson || null,
      extension_number: null,
      email: userInfo?.email || null,
    },
    comment: values[COMMENT] || '',
    partnership_contract_type: 'all', // Поле для новой ускорки: bts / ftl / all
  };
}

function getFormMapper(orderType, formType) {
  const hashString = getHashString({ orderType, formType });
  switch (hashString) {
    case getHashString({ orderType: CONTRACT_BY_COUNT, formType: SIMPLE }):
      return getSimpleContractForm;
    case getHashString({ orderType: CONTRACT_BY_COUNT, formType: FULL }):
      return getFullContractForm;
    case getHashString({ orderType: SHIPPING_REQUEST, formType: SIMPLE }):
      return getSimpleShippingRequestForm;
    default:
    case getHashString({ orderType: SHIPPING_REQUEST, formType: FULL }):
      return getFullShippingRequestForm;
  }
}

function getFullShippingRequestForm(values, params) {
  const { serviceList, isOrderBasedOnContract, isOrderChange, isPublishing } = params;

  const cargoInfo = getFullCargoInfo(values);

  // Изменение требований
  if (isOrderChange) {
    const warehouseInfo = getWarehouseInfo(values);
    return {
      type: SHIPPING_REQUEST,
      resource_requirements: getMappedResourceRequirements(values[RESOURCE_REQUIREMENTS]),
      route_points: getMappedRoutePoints({
        routePoints: values[ROUTE_POINTS],
        isContract: false,
        isSimple: false,
        serviceList,
        isOrderBasedOnContract,
        isPublishing,
        isOrderChange,
      }),
      ...cargoInfo,
      price_type: values[PRICE_TYPE],
      price_without_vat: Number(values[PRICE_WITHOUT_TAX]),
      ...warehouseInfo,
    };
  }

  return {
    type: SHIPPING_REQUEST,
    resource_requirements: getMappedResourceRequirements(values[RESOURCE_REQUIREMENTS]),
    route_points: getMappedRoutePoints({
      routePoints: values[ROUTE_POINTS],
      isContract: false,
      isSimple: false,
      serviceList,
      isOrderBasedOnContract,
      isPublishing,
    }),
    auction_stages: getMappedAuctionStages(values[AUCTION_STAGES], false, false, false),
    auction_auto_extend_period: values[ENABLE_AUCTION_AUTO_EXTEND] ? values[AUTO_EXTENDED] || null : null,
    ...cargoInfo,
    resource_assignment_time: values[RESOURCE_ASSIGNMENT_TIME]
      ? Math.round(values[RESOURCE_ASSIGNMENT_TIME] * 60)
      : null,
    resource_deadline_option: values[RESOURCE_DEADLINE_OPTION],
    shipping_available_in: null,
  };
}

function getSimpleShippingRequestForm(values, params) {
  const { isOrderBasedOnContract, isPublishing, serviceList } = params;

  const cargoInfo = isOrderBasedOnContract ? getFullCargoInfo(values) : getSimpleCargoInfo(values);

  return {
    type: SHIPPING_REQUEST,
    resource_requirements: isOrderBasedOnContract
      ? getMappedResourceRequirements(values[RESOURCE_REQUIREMENTS])
      : defaultResourceRequirements,
    route_points: getMappedRoutePoints({
      routePoints: values[ROUTE_POINTS],
      isContract: false,
      isSimple: !isOrderBasedOnContract,
      serviceList,
      isOrderBasedOnContract,
      isPublishing,
    }),
    auction_stages: getMappedAuctionStages(values[AUCTION_STAGES], false, false, true),
    auction_auto_extend_period: values[ENABLE_AUCTION_AUTO_EXTEND] ? values[AUTO_EXTENDED] || null : null,
    ...cargoInfo,
    resource_assignment_time: values[RESOURCE_ASSIGNMENT_TIME]
      ? Math.round(values[RESOURCE_ASSIGNMENT_TIME] * 60)
      : null,
    resource_deadline_option: values[RESOURCE_DEADLINE_OPTION],
    shipping_available_in: null,
  };
}

function getFullContractForm(values, params) {
  const { serviceList } = params;

  const contractInfo = getContractInfo(values);
  const cargoInfo = getFullCargoInfo(values);

  return {
    ...contractInfo,
    resource_requirements: getMappedResourceRequirements(values[RESOURCE_REQUIREMENTS]),
    route_points: getMappedRoutePoints({
      routePoints: values[ROUTE_POINTS],
      isContract: true,
      isSimple: false,
      serviceList,
    }),
    ...cargoInfo,
  };
}

function getSimpleContractForm(values) {
  const contractInfo = getContractInfo(values);
  const cargoInfo = getSimpleCargoInfo(values);

  return {
    ...contractInfo,
    resource_requirements: defaultResourceRequirements,
    route_points: getMappedRoutePoints({ routePoints: values[ROUTE_POINTS], isContract: true, isSimple: true }),
    ...cargoInfo,
  };
}

/**
 *
 * @param formData - данные с формы
 */
export function getExtraOrders(formData): any[] {
  const hasWarehouses = formData[ROUTE_POINTS].some((point) => !!point.warehouse_id);
  if (!hasWarehouses && formData[EXTRA_ORDERS_LIST]?.length > 0) {
    return (
      formData[EXTRA_ORDERS_LIST].map((extraOrder) => {
        const externalHumanFriendlyId = extraOrder[EXTERNAL_HUMAN_FRIENDLY_ID];
        const orderRoutePoints = formData[ROUTE_POINTS].map((point, index) => {
          const dates = [extraOrder[EXTRA_ORDERS_LIST_POINTS][index]];
          return {
            ...point,
            [ROUTE_DATES]: dates,
          };
        });
        return { ...formData, [ROUTE_POINTS]: orderRoutePoints, [EXTERNAL_HUMAN_FRIENDLY_ID]: externalHumanFriendlyId };
      }) ?? []
    );
  }
  return [];
}
