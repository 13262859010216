import getTonnageVolume from './getTonnageVolume';

interface IProps {
  tonnage: number | string | null;
  volume: number | string | null;
  values?: {};
}

/**
 * Маппинг тоннажа/объема в формат react-select
 * @param tonnage
 * @param volume
 * @param values
 * @returns {{label: *, value: {tonnage: *, volume: *}}}
 */
function getMappedLiftingCapacity({ tonnage, volume, ...values }: IProps): {
  label: string;
  value: IProps;
} {
  const label = getTonnageVolume(+tonnage!, +volume!);
  return {
    label,
    value: {
      tonnage,
      volume,
      ...values,
    },
  };
}

export default getMappedLiftingCapacity;
