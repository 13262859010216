import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconResources = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M13 5C13 6.65685 11.6569 8 10 8C8.34315 8 7 6.65685 7 5C7 3.34315 8.34315 2 10 2C11.6569 2 13 3.34315 13 5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.21252 15.5532L3 15.0001V13.0001C3 11.8334 4.4 9 10 9C15.6 9 17 11.8334 17 13.0001V15.0001L14.7875 15.5532C14.9257 16.0112 15 16.4969 15 17H5C5 16.4969 5.0743 16.0112 5.21252 15.5532ZM5.8755 14.1728C6.7767 12.8606 8.28785 12 10 12C11.7121 12 13.2233 12.8606 14.1245 14.1728L15.5 13.8289V13.0001C15.5 12.9992 15.4997 12.9879 15.4968 12.9664C15.4936 12.9432 15.4877 12.9111 15.4773 12.871C15.4562 12.7902 15.419 12.6851 15.357 12.5634C15.2341 12.322 15.0177 12.0225 14.6534 11.7261C13.9357 11.1422 12.5491 10.5 10 10.5C7.4509 10.5 6.06435 11.1422 5.34659 11.7261C4.98229 12.0225 4.76587 12.322 4.643 12.5634C4.58103 12.6851 4.54376 12.7902 4.52274 12.871C4.51229 12.9111 4.50639 12.9432 4.50321 12.9664C4.50027 12.9879 4.50002 12.9992 4.5 13.0001L4.5 13.8289L5.8755 14.1728Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconResources.defaultProps = {
  color: 'base',
};

export default IconResources;
