import React from 'react';
import SVGIcon from './index';

const IconAttachments = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 30 40">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 37C30 38.6569 28.6569 40 27 40H3C1.34315 40 0 38.6569 0 37V3C0 1.34315 1.34315 0 3 0H19.3685C20.1723 0 20.9426 0.322591 21.5065 0.895448L29.138 8.64808C29.6904 9.20927 30 9.96517 30 10.7526V37Z"
        fill="#DEE8F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8283 9.57506C28.1452 9.88948 27.9225 10.43 27.4761 10.43H19.6899V2.70047C19.6899 2.25596 20.2266 2.03245 20.5421 2.34554L27.8283 9.57506Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 18H26V20H4V18ZM4 22H26V24H4V22ZM14 26H4V28H14V26Z"
        fill="#0B5EDA"
      />
    </SVGIcon>
  );
};

export default IconAttachments;
