/* Механизм работы с документам */
export const SCAN_COPY = 'scancopy';
export type TSCAN_COPY = typeof SCAN_COPY;
export const ONLINE = 'online_confirmation';
export type TONLINE = typeof ONLINE;
export const QUALIFIED = 'qualified_signature';
export type TQUALIFIED = typeof QUALIFIED;
export type TRegistrationType = TSCAN_COPY | TONLINE | TQUALIFIED;

/* Виды договоров */
export const SHIPPING_AGREEMENT = 'shipping_agreement';
export const FREIGHT_FORWARDING_AGREEMENT = 'freight_forwarding_agreement';

/* Тип дней в периоде */
export const CALENDAR = 'calendar';
export type TCALENDAR = typeof CALENDAR;
export const WORKING = 'working';
export type TWORKING = typeof WORKING;
export type TDayType = TCALENDAR | TWORKING;

/* Документы для оплаты */
export const BILL_OF_LADING = 'bill_of_lading';
export const WAYBILL = 'waybill';
export const PACKING_LIST = 'packing_list';
export const BILL = 'bill';
export const ACCEPTANCE_CERTIFICATE = 'acceptance_certificate';
export const DELIVERY_CERTIFICATE = 'delivery_certificate';
export const ACT = 'act';
export const INVOICE = 'invoice';
export const POWER_OF_ATTORNEY = 'power_of_attorney';
export const UNIVERSAL_TRANSFER_DOC = 'universal_transfer_document';
export const ACCOMPANYING_DOC = 'accompanying_document';
export const CMR = 'cmr';
export const FORWARDING_ORDER = 'forwarding_order';
export const SHIPPING_ORDER = 'shipping_order';
export const FORWARDING_RECEIPT = 'forwarding_receipt';
export const BILL_OF_LADING_OR_WAYBILL = 'bill_of_lading_or_waybill';
export const ATTACHMENT_TO_BILL_OF_LADING = 'attachment_to_bill_of_lading';

export type TPaymentDocs =
  | typeof BILL_OF_LADING
  | typeof WAYBILL
  | typeof PACKING_LIST
  | typeof BILL
  | typeof ACCEPTANCE_CERTIFICATE
  | typeof DELIVERY_CERTIFICATE
  | typeof ACT
  | typeof INVOICE
  | typeof POWER_OF_ATTORNEY
  | typeof UNIVERSAL_TRANSFER_DOC
  | typeof ACCOMPANYING_DOC
  | typeof CMR
  | typeof FORWARDING_ORDER
  | typeof SHIPPING_ORDER
  | typeof FORWARDING_RECEIPT
  | typeof BILL_OF_LADING_OR_WAYBILL
  | typeof ATTACHMENT_TO_BILL_OF_LADING;

/* Порядок оплаты */
export const PREPAYMENT = 'prepayment';
export const PAYMENT_BY_SCANCOPY = 'payment_by_scancopy';
export const PAYMENT_BY_ORIGIN_DOCS = 'payment_by_original_documents';
export const PAYMENT_BY_UNLOADING = 'payment_by_unloading';
export const PAYMENT_BY_LOADING = 'payment_by_loading';
export type TPaymentType =
  | typeof PREPAYMENT
  | typeof PAYMENT_BY_SCANCOPY
  | typeof PAYMENT_BY_ORIGIN_DOCS
  | typeof PAYMENT_BY_UNLOADING
  | typeof PAYMENT_BY_LOADING;
