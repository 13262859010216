import React from 'react';
import SVGIcon from './index';

function IconEvent(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path
        fillRule="evenodd"
        d="M20 18h-3v3h3v-3zm-7-4h-3v3h3v-3zm6-9v2h-8V5H9v2H6.01L6 25h18V7h-3V5h-2zm3 18H8V12h14v11z"
      />
    </SVGIcon>
  );
}

IconEvent.defaulProps = {
  color: 'base',
};

export default React.memo(IconEvent);
