import React from 'react';
import SVGIcon from './index';

export default function IconHelpOutline(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569ZM14.5962 14.5962C15.8152 13.3772 16.5 11.7239 16.5 10C16.5 8.27609 15.8152 6.62279 14.5962 5.40381C13.3772 4.18482 11.7239 3.5 10 3.5C8.27609 3.5 6.62279 4.18482 5.40381 5.40381C4.18482 6.62279 3.5 8.27609 3.5 10C3.5 11.7239 4.18482 13.3772 5.40381 14.5962C6.62279 15.8152 8.27609 16.5 10 16.5C11.7239 16.5 13.3772 15.8152 14.5962 14.5962ZM8.72435 5.25344C9.12879 5.08599 9.56226 4.99987 9.99999 5C10.4377 4.99987 10.8712 5.08599 11.2756 5.25344C11.6801 5.42089 12.0475 5.6664 12.3571 5.97592C12.6666 6.28544 12.9121 6.65292 13.0796 7.05736C13.247 7.4618 13.3331 7.89527 13.333 8.333C13.333 9.402 12.675 9.978 12.033 10.538C11.9512 10.6097 11.8696 10.6795 11.7898 10.7479C11.2769 11.1872 10.833 11.5674 10.833 12H9.16699C9.16699 11.063 9.95199 10.381 10.642 9.851C11.184 9.436 11.667 9.066 11.667 8.333C11.667 7.417 10.917 6.667 9.99999 6.667C9.08299 6.667 8.33299 7.417 8.33299 8.333H6.66699C6.66686 7.89527 6.75298 7.4618 6.92043 7.05736C7.08789 6.65292 7.33339 6.28544 7.64291 5.97592C7.95244 5.6664 8.31992 5.42089 8.72435 5.25344ZM11 13V15H8.99999V13H11Z"
        pointerEvents="all"
      />
    </SVGIcon>
  );
}

IconHelpOutline.defaultProps = {
  color: 'base',
};
