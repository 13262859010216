import classNames from 'classnames';
import React, { createContext, useCallback, useState, useContext } from 'react';
import { IconChevron } from '../Icons';
import styles from './styles.module.scss';

interface IControlPanelContext {
  total: number;
  currentIndex: number;
  changeHandler: (index: number) => void;
}

interface ISliderProps {
  children?: any[];
  ControlComponent?: any;
}

const CollapseValueContext = createContext<IControlPanelContext>({
  total: 0,
  currentIndex: 0,
  changeHandler: (index) => {},
});

const ControlPanel = () => {
  const { total, currentIndex, changeHandler } = useContext(CollapseValueContext);
  const items = new Array(total).fill(null);

  const prevButtonclickHandler = useCallback(() => {
    changeHandler(currentIndex - 1);
  }, [changeHandler, currentIndex]);
  const nextButtonclickHandler = useCallback(() => {
    changeHandler(currentIndex + 1);
  }, [changeHandler, currentIndex]);

  return (
    <div className={styles.controlPanel}>
      <div className={classNames(styles.iconButton, 'mr-4')} onClick={prevButtonclickHandler}>
        <IconChevron width={20} height={20} color="white"></IconChevron>
      </div>
      {items &&
        items.map((el, index) => {
          return (
            <div key={index} className={classNames(styles.dot, { [styles.dotActive]: index === currentIndex })}></div>
          );
        })}
      <div className={classNames(styles.iconButton, 'ml-4')} onClick={nextButtonclickHandler}>
        <IconChevron width={20} height={20} color="white" style={{ transform: 'rotate(180deg)' }}></IconChevron>
      </div>
    </div>
  );
};

const Slider = (props: ISliderProps) => {
  const { children, ControlComponent } = props;
  const total = children?.length || 0;
  const [currentIndex, setCurrentIndex] = useState(0);

  const changeHandler = useCallback(
    (index) => {
      let normalizedIndex = +index;
      if (isNaN(normalizedIndex) || normalizedIndex < 0) setCurrentIndex(0);
      else if (normalizedIndex + 1 > total) setCurrentIndex(total - 1);
      else setCurrentIndex(normalizedIndex);
    },
    [total],
  );

  return (
    <CollapseValueContext.Provider value={{ total, currentIndex, changeHandler }}>
      <div className={styles.slider}>
        <div className={styles.slidesContainer}>
          {children?.map((child, index) => {
            return (
              <div className={styles.slide} key={index} style={{ transform: `translate(${-100 * currentIndex}%)` }}>
                {child}
              </div>
            );
          })}
        </div>
        {ControlComponent && (
          <div className={styles.controlComponentContainer}>
            <ControlComponent />
          </div>
        )}
      </div>
    </CollapseValueContext.Provider>
  );
};

Slider.ControlPanel = ControlPanel;

Slider.defaultProps = {
  ControlComponent: ControlPanel,
};

export default Slider;
