import React from 'react';

import LetterBox from '../../LetterBox';

/**
 * Получение иконки НТК
 * @param partnershipInfo
 */
export function getCompanyLogo(partnershipInfo: { active?: boolean; logo_url?: string } = {}) {
  return (
    <LetterBox
      logo={partnershipInfo.logo_url}
      theme={partnershipInfo.active ? 'light' : 'grey'}
      string={partnershipInfo.active ? 'п' : 'к'}
      size="medium"
    />
  );
}
