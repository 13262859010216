import _get from 'lodash/get';
import _values from 'lodash/values';
import _sortBy from 'lodash/sortBy';
import * as authTokenService from './authTokenService';
import * as formKeys from '../store/resources/formKeys';
import http, { httpApiV2 } from './config/webConfig';
import pageListMapper from '../helpers/mappers/pageListMapper';
import { VEHICLE_REPORT_QUERY_TYPE, VEHICLE_REPORT_STATUS } from '../store/resources/constants';
import { getCarsOptions, getDriversOptions, getTrailersOptions } from '../helpers/mappers/resources';

// const driversRequestId = 'resources/drivers';
const trailersRequestId = 'resources/trailers';
const carsRequestId = 'resources/cars';

/**
 * Запрос списка водителей
 * @returns {Promise<Promise|*|PromiseLike<T>|Promise<T>>}
 * @param params
 */
export async function getDrivers(params) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const url = `/resources/${company_id}/drivers`;
  return http.get(url, { params, cache: false }).then(pageListMapper);
}

/**
 * Запрос на получения списка машин
 * @param params
 * @return {Promise<*>}
 * @private
 */
export async function getCars(params) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const url = `/resources/${company_id}/cars`;
  return http.get(url, { params, cache: false }).then(pageListMapper);
}

/**
 * Запрос на получения списка машин
 * @param params
 * @return {Promise<*>}
 * @private
 */
export async function getTrailers(params) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const url = `/resources/${company_id}/trailers`;
  return http.get(url, { params, cache: false }).then(pageListMapper);
}

/**
 * Получения списка автомашин для перевозки
 * @param payload
 * @returns {Promise<*>}
 */
export async function getCarsForShipping(payload) {
  const params = { per_page: 20, ...payload };
  return _getCars(params)
    .then(pageListMapper)
    .then((data) => data.items.map(getCarsOptions));
}

/**
 * Получение списка прицепов для перевозки
 * @param payload
 * @returns {Promise<*>}
 */
export async function getTrailersForShipping(payload) {
  const params = { per_page: 20, ...payload };
  return _getTrailers(params)
    .then(pageListMapper)
    .then((data) => data.items.map(getTrailersOptions));
}

/**
 * Получение списка водителей для перевозки
 * @param payload
 * @returns {Promise<*>}
 */
export async function getDriversForShipping(payload) {
  const params = { page: 1, per_page: 20, ...payload };
  return getDrivers(params).then((data) => data.items.map(getDriversOptions));
}

/**
 * Получить водителя по Id
 * @param {String} driverId
 */
export async function getDriverById(driverId) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const url = `/resources/${company_id}/drivers/${driverId}`;
  return http.get(url, { cache: false }).then((response) => response.data);
}

/**
 * Создание нового водителя
 * @param {Object} driver
 */
export async function addDriver(driver) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const url = `/resources/${company_id}/drivers`;
  return http.post(url, driver).then((response) => response.data);
}

/**
 * Обновление водителя
 * @param {String} driverId
 * @param {Object} driver
 */
export async function updateDriver(driverId, driver) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const url = `/resources/${company_id}/drivers/${driverId}`;
  return http.put(url, driver).then((response) => response.data);
}
/* Удалить водителя */
export async function deleteDriver(driverId) {
  const token = await authTokenService.getToken();
  const headers = { Authorization: `Bearer ${token}` };
  const { company_id } = authTokenService.getDecodeToken(token);
  const url = `/resources/${company_id}/drivers/${driverId}`;
  return http.delete(url, { headers }).then((response) => response.data);
}
/* Проверить уникальность ИНН водителя */
export async function checkINN(inn) {
  const url = '/resources/drivers/check_inn';
  return http.get(url, { params: { inn } }).then((response) => response.data);
}

/* Удалить автомашину */
export async function deleteCar(carId, typeUrl) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const isTanker = typeUrl.includes(formKeys.TANKER);
  let url;
  if (isTanker) {
    url = `/resources/cars/${carId}`;
  } else {
    url = `/resources/${company_id}/cars/type/${typeUrl}/${carId}`;
  }
  return http.delete(url).then((response) => response.data);
}
/* Удалить прицеп */
export async function deleteTrailer(trailerId, typeUrl) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const isTankerOrContainer = typeUrl.search(/tanker|container/) !== -1;
  let url;
  if (isTankerOrContainer) {
    url = `/resources/trailers/${trailerId}`;
  } else {
    url = `/resources/${company_id}/trailers/type/${typeUrl}/${trailerId}`;
  }
  return http.delete(url).then((response) => response.data);
}

/**
 * Отправка данных о водителе на проверку
 * @param entity
 * @param entityId
 */
export async function sendToReview(entity, entityId) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const urlEntity = URL_ENTITY.get(entity) || '';
  const url = `/resources/${company_id}/${urlEntity}/${entityId}/send_to_review`;
  return http.post(url, {}).then((response) => response.data);
}

const URL_ENTITY = new Map()
  .set(formKeys.DRIVER, formKeys.DRIVERS)
  .set(formKeys.CAR, formKeys.CARS)
  .set(formKeys.TRAILER, formKeys.TRAILERS);

/**
 * Запрос за возможными параметрами - тоннаж/объем
 */
export async function getLiftingCapacity() {
  const url = '/catalog/lifting_capacity';
  return http.get(url).then((response) => response.data.items);
}

/**
 * Получить список брендов для ресурса
 * @returns {Promise<*>}
 */
export async function getVehicleBrandTypes(type = null) {
  const url = '/catalog/vehicle_brands';
  let realType = null;
  if (type) {
    realType = type === formKeys.LORRY || type === formKeys.TRUCK ? formKeys.CAR : formKeys.TRAILER;
  }
  return http.get(url).then((response) => {
    const items = _get(response, 'data.items', []);

    return _sortBy(
      _values(
        items.reduce((result, current) => {
          const vehicleTypes = _get(current, 'vehicle_types', []);

          if (realType && !vehicleTypes.some((vehicleType) => vehicleType === realType)) return result;

          const name = _get(current, 'name');

          result[name] = {
            label: name,
            value: name,
          };

          return result;
        }, {}),
      ),
      [(item) => item.label],
    );
  });
}

/**
 * Запрос на создание автомашины
 * @param params
 * @param typeUrl
 * @returns {Promise<any>}
 */
export async function createCar(params, typeUrl) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const isTanker = typeUrl.includes(formKeys.TANKER);
  let url, data;
  if (isTanker) {
    url = `/resources/cars`;
    data = { company_id: company_id, ...params };
  } else {
    url = `/resources/${company_id}/cars/type/${typeUrl}`;
    data = params;
  }

  return http.post(url, data).then((response) => {
    return response.data;
  });
}

/**
 * Запрос на обновление автомашины
 * @param params
 * @param id
 * @returns {Promise<any>}
 */
export async function updateCar(params, id) {
  const url = `/resources/cars/${id}`;

  return http.put(url, params).then((response) => {
    return response.data;
  });
}

/**
 * Получение автомашины
 * @param carId
 * @returns {Promise<any>}
 */
export async function getCarById(carId) {
  const url = `/resources/cars/${carId}`;
  return http.get(url, { cache: false }).then((response) => {
    return response.data;
  });
}

/**
 * Получение прицепа
 * @param trailerId
 * @returns {Promise<any>}
 */
export async function getTrailerById(trailerId) {
  const url = `/resources/trailers/${trailerId}`;
  return http.get(url, { cache: false }).then((response) => {
    return response.data;
  });
}

/**
 * Запрос на создание прицепа
 * @param params
 * @param typeUrl
 * @returns {Promise<any>}
 */
export async function createTrailer(params, typeUrl) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  let url, data;
  if (typeUrl.search(/tanker|container|timber_carrier/) !== -1) {
    url = `/resources/trailers`;
    data = { company_id, ...params };
  } else {
    url = `/resources/${company_id}/trailers/type/${typeUrl}`;
    data = params;
  }

  return http.post(url, data).then((response) => {
    return response.data;
  });
}

/**
 * Запрос на обновление прицепа
 * @param params
 * @param typeUrl
 * @param id
 * @returns {Promise<any>}
 */
export async function updateTrailer(params, typeUrl, id) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);

  let url, data;
  if (typeUrl.search(/tanker|container|timber_carrier/) !== -1) {
    url = `/resources/trailers/${id}`;
    data = { company_id, ...params };
  } else {
    url = `/resources/${company_id}/trailers/type/${typeUrl}/${id}`;
    data = params;
  }

  return http.put(url, data).then((response) => {
    return response.data;
  });
}

/**
 * Запрос на получения списка машин
 * @param params
 * @return {Promise<*>}
 * @private
 */
async function _getCars(params) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const url = `/resources/${company_id}/cars`;
  return http.get(url, {
    params,
    cache: false,
    requestId: carsRequestId,
  });
}

/**
 * Запрос на получения списка прицепов
 * @param params
 * @return {Promise<*>}
 * @private
 */
async function _getTrailers(params) {
  const token = await authTokenService.getToken();
  const { company_id } = authTokenService.getDecodeToken(token);
  const url = `/resources/${company_id}/trailers`;
  return http.get(url, {
    params,
    cache: false,
    requestId: trailersRequestId,
  });
}

/**
 * Загрузка XLS файла
 * @param entity
 * @param file {Object}
 * @return {Promise<*>}
 */
export async function uploadXlsFile(entity, file) {
  const url = '/resources/batch_imports';

  const fileToBytesArray = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const bytesArrayToBase64 = (bytesArray) => {
    let binary = '';
    const bytes = new Uint8Array(bytesArray);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  const documentBytes = await fileToBytesArray(file);

  const data = {
    type: entity,
    file_name: file.name,
    content: bytesArrayToBase64(documentBytes),
  };

  return http.post(url, data, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Проверка статуса обработки XLS файла
 * @param importId
 * @return {Promise<*>}
 */
export async function checkXlsImportStatus(importId) {
  const url = `/resources/batch_imports/${importId}/status`;
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Сохранение корректных данных
 * @param importId
 * @return {Promise<*>}
 */
export async function confirmXlsImport(importId) {
  const url = `/resources/batch_imports/${importId}/confirm`;
  return http.post(url, {}, { cache: false, requestId: url }).then((response) => response.data);
}

export async function getDriverPowerOfAttorneySamples(payload) {
  const url = '/resources/power-of-attorneys/drivers';
  return httpApiV2
    .post(url, payload, { responseType: 'blob', cache: false, requestId: url })
    .then((response) => response.data);
}

/**
 * Получить ИНН водителя по данным паспорта
 * @param {Object} driverData
 */
export async function getDriverINN(driverData) {
  const url = '/resources/inn';
  return httpApiV2.post(url, driverData).then((response) => response.data);
}

export async function getFileRecognitionId(file) {
  const url = `/resources/recognize`;
  const formData = new FormData();
  formData.append('file', file);
  const headers = { 'Content-Type': 'application/octet-stream' };
  return httpApiV2.post(url, formData, { headers }).then((response) => response.data);
}

export async function getFileRecognitionResult(id) {
  const url = `/resources/recognize/result/${id}`;
  return httpApiV2.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получение распознанных данных файла ресурса, уже хранящегося в doc store
 * @param fileId
 * @return {Promise<*>}
 */
export async function getDocStoreFileRecognitionResults(fileId) {
  const url = `resources/file/${fileId}/recognition`;
  return httpApiV2.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Создание запроса о данных ТС во внешний сервис (автокод)
 * @param value
 * @param queryType
 * @return {Promise<*>}
 */
export async function createVehicleReport(value, queryType = VEHICLE_REPORT_QUERY_TYPE.VIN) {
  const url = '/resources/vehicle-reports';
  const payload = {
    query_type: queryType,
    value,
  };
  return httpApiV2.post(url, payload, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получение данных ТС из внешнего сервиса по id отчета (автокод)
 * @param reportId
 * @return {Promise<*>}
 */
export async function getVehicleReport(reportId) {
  const url = `/resources/vehicle-reports/${reportId}`;
  return httpApiV2.get(url, { cache: false, requestId: url }).then((response) => {
    if (response.data?.status === VEHICLE_REPORT_STATUS.NOT_READY) {
      return Promise.reject({ error: VEHICLE_REPORT_STATUS.NOT_READY });
    }
    return response.data;
  });
}

/**
 * Скачивание отчета валидаций ресурсов, назначенных на перевозку
 * @param shippingId
 * @return {Promise<*>}
 */
export async function downloadResourcesValidationResultsPDF(shippingId) {
  const url = `/resources/reports/shipping-resources/${shippingId}`;
  return httpApiV2
    .get(url, { headers: { Accept: 'application/pdf' }, responseType: 'blob', cache: false })
    .then((response) => response.data);
}

export function checkCarForShipping(shippingId, carId) {
  return http
    .get(`/resources/cars/${carId}/details_shipping_compliance/${shippingId}`)
    .then((response) => response.data);
}
export function checkTrailerForShipping(shippingId, trailerId) {
  return http
    .get(`/resources/trailers/${trailerId}/details_shipping_compliance/${shippingId}`)
    .then((response) => response.data);
}
export function checkDriverForShipping(shippingId, driverId) {
  return http
    .get(`/resources/drivers/${driverId}/details_shipping_compliance/${shippingId}`)
    .then((response) => response.data);
}
