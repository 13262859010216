import React from 'react';
import SVGIcon from './index';

function IconWhatsHot(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M13.5 6L10 2.5L6.5 6H9V8H11V6H13.5Z" />
      <path d="M6.5 14L10 17.5L13.5 14H11L11 12H9V14H6.5Z" />
      <path d="M17.5 10L14 13.5V11H12V9L14 9V6.5L17.5 10Z" />
      <path d="M6 6.5L2.5 10L6 13.5L6 11L8 11V9L6 9L6 6.5Z" />
    </SVGIcon>
  );
}

IconWhatsHot.defaultProps = {
  color: 'white',
};

export default React.memo(IconWhatsHot);
