import React from 'react';

import { IconEvent, IconClock, IconInterval, IconReservationRequest } from '../../Icons';
import Tooltip from '../../Tooltip';

/**
 * Получение иконки типа предложения
 * @param item
 * @returns {*}
 */
export function getSuggestionTypeIcon(item: {
  isReservationRequest?: boolean;
  isInterval?: boolean;
  isGraphic?: boolean;
}) {
  if (item.isReservationRequest) {
    return <IconReservationRequest width="20" height="20" />;
  }

  if (item.isInterval) {
    return <IconInterval width="20" height="20" />;
  }

  if (item.isGraphic) {
    return <IconEvent width="20" height="20" />;
  }

  return (
    <Tooltip content="Заявка с фиксированным временем">
      <IconClock width="20" height="20" />
    </Tooltip>
  );
}
