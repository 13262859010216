import * as types from './actionTypes';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

const initialState = {};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    case types.UPDATE_COMPANY:
      return { ...draft, ...action.companyData };
    case types.CLEAR_COMPANY_DATA:
      return initialState;
    case types.UPDATE_DLT_OFFER_STATE:
      return { ...draft, dlt_offer: action.res };
    case types.UPDATE_DL_OFFER_STATE:
      return { ...draft, dl_offer: action.res };
    // for eslint (default-case)
    // no default
  }
}, initialState);

export default reducer;
