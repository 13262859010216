import _pick from 'lodash/pick';
import _values from 'lodash/values';
import { MKT } from '../../../store/order/routes/formKeys';

function getPowerOfAttorneyRequirement(cargoOwnerCompanyId, partnerContractType) {
  if (cargoOwnerCompanyId === process.env.REACT_APP_DELLIN_ID) {
    if (partnerContractType === MKT) {
      return { driverPowerOfAttorneyMKT: true };
    } else {
      return { driverPowerOfAttorneyBTS: true };
    }
  }
  return null;
}

function getDriverRestrictions(driver, powerOfAttorneyRequirement) {
  if (_values(driver).every((item) => !item)) {
    return powerOfAttorneyRequirement;
  } else {
    return { ...driver, ...powerOfAttorneyRequirement };
  }
}

export function getResourceRequirements(item) {
  /**
   * Массивы требований
   * @type {string[]}
   */
  const arrDriver = [
    'russianCitizen',
    'driverCoveralls',
    'noCriminalRecords',
    'medicalBook',
    'shippingPowerOfAttorneyOriginal',
  ];

  const arrVehicle = [
    'cmrDocument',
    'isDisinfected',
    'isOwnTransport',
    'numberOfBelts',
    'removableUpperBeam',
    'removableSideRacks',
    'rigidBoard',
    'temperatureCheck',
    'temperatureCondition',
    'timberBunks',
    'tirDocument',
    'twoSided',
    'hydroBoard',
    'manipulator',
  ];

  const { cargoOwnerCompanyId, partnerContractType } = item;
  const powerOfAttorneyRequirement = getPowerOfAttorneyRequirement(cargoOwnerCompanyId, partnerContractType);
  /**
   * Получаем значения требований для ТС и водителя
   */
  if (item.additionalInfo.restrictions !== null) {
    let driver = _pick(item.additionalInfo.restrictions, arrDriver);
    let vehicle = _pick(item.additionalInfo.restrictions, arrVehicle);

    return {
      bodyTypes: item.bodyTypes,
      vehicleType: item.vehicleType,
      tonnage: item.tonnage,
      volume: item.volume,
      restrictions: {
        driver: getDriverRestrictions(driver, powerOfAttorneyRequirement),
        vehicle: _values(vehicle).every((item) => item !== 0 && !item) ? null : vehicle,
      },
    };
  }

  return {
    bodyTypes: item.bodyTypes,
    vehicleType: item.vehicleType,
    tonnage: item.tonnage,
    volume: item.volume,
    restrictions: powerOfAttorneyRequirement
      ? {
          driver: powerOfAttorneyRequirement,
        }
      : item.additionalInfo.restrictions,
  };
}
