import { v4 as uuidv4 } from 'uuid';

type GetAuthUrl = {
  registration?: boolean;
  state?: string;
};

type AuthUrlParams = {
  response_type: string;
  client_id: string;
  client_secret: string;
  redirect_uri: string;
  state: string;
  ['force-registration']?: string;
};

export function getAuthUrl({ registration, state }: GetAuthUrl = {}): string {
  const params: AuthUrlParams = {
    response_type: 'code',
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID!,
    client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET!,
    state: state || uuidv4(),
    redirect_uri: process.env.REACT_APP_OAUTH_REDIRECT_URL!,
  };
  if (registration) {
    params['force-registration'] = 'true';
  }

  const searchParams = new URLSearchParams(params as any);
  return `${process.env.REACT_APP_OAUTH_URL}/authorize?${searchParams.toString()}`;
}
