import React from 'react';

import TimeLeft from '../../TimeLeft';
import { IconAssignmentCheck, IconExclamation, IconAssignmentLate } from '../../Icons';
import { EOrderDocsProcessStatuses, ORDER_DOCS_PROCESS_STATUSES } from '../../../constants/order';

export function getOrderScanCopyStatus(item: {
  scanCopyStatus?: string;
  isScanCopyError?: boolean;
  isScanCopyDead?: boolean;
  scanCopyDeadline?: string;
  isCargoOwner?: boolean;
}) {
  const text = ORDER_DOCS_PROCESS_STATUSES[item.scanCopyStatus as EOrderDocsProcessStatuses]?.label || '';

  const SCAN_COPIES_STATUS = new Map()
    .set(
      EOrderDocsProcessStatuses.DOC_NOT_ATTACHED,
      <>
        <IconAssignmentLate width="20" height="20" color="base" className="mr-1" />
        <span className="color-dark">{text}</span>
      </>,
    )
    .set(
      EOrderDocsProcessStatuses.DOC_OWNER_SIGNING,
      <>
        <IconAssignmentLate width="20" height="20" color="base" className="mr-1" />
        <span className="color-dark">{text}</span>
      </>,
    )
    .set(
      EOrderDocsProcessStatuses.DOC_IN_PROGRESS,
      <>
        <IconAssignmentCheck width="20" height="20" color="base" className="mr-1" />
        <span className="color-dark">{text}</span>
      </>,
    )
    .set(
      EOrderDocsProcessStatuses.DOC_ACCEPTED,
      <>
        <IconAssignmentCheck width="20" height="20" color="base" className="mr-1" />
        <span className="color-dark">{text}</span>
      </>,
    );

  return (
    SCAN_COPIES_STATUS.get(item.scanCopyStatus) || (
      <>
        <IconExclamation
          width="20"
          height="20"
          color={item.isScanCopyError || item.isScanCopyDead ? 'danger' : 'base'}
        />
        <TimeLeft time={item?.scanCopyDeadline} title={''} withTimerIcon={false} />
      </>
    )
  );
}
