import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

const IconArrowWithDot = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C5.584 18 2 14.416 2 10C2 5.584 5.584 2 10 2C14.416 2 18 5.584 18 10C18 14.416 14.416 18 10 18ZM7.70154 9L11 5.7925L9.66802 4.5L4 10L9.66802 15.5L11 14.2075L7.70154 11L13.2675 11C13.0974 10.7058 13 10.3643 13 10C13 9.63571 13.0974 9.29417 13.2675 9L7.70154 9ZM15 11L14.9996 11C14.4475 10.9998 14 10.5522 14 10C14 9.44772 14.4477 9 15 9C15.5523 9 16 9.44772 16 10C16 10.5522 15.5524 10.9998 15.0003 11L15 11Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
};

IconArrowWithDot.defaultProps = {
  color: 'base',
};

export default IconArrowWithDot;
