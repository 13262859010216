import moment from 'moment';
import _get from 'lodash/get';
import { createSelector, defaultMemoize } from 'reselect';
import {
  CARS_NUMBER,
  CARS_RENT_NUMBER,
  COUNTRY,
  INN,
  KPP,
  OGRN,
  COMPANY,
  ADDRESS_LEGAL_CITY,
  ADDRESS_LEGAL_STREET,
  ADDRESS_LEGAL_BUILDING,
  ADDRESS_LEGAL_ROOM,
  ADDRESS_LEGAL_INDEX,
  DATE,
  OKVED,
  BASIS_FOR_SIGN,
  TAX_TYPE,
  ADDRESS_ACTUAL_STREET,
  ADDRESS_ACTUAL_CITY,
  ADDRESS_ACTUAL_BUILDING,
  ADDRESS_ACTUAL_ROOM,
  ADDRESS_ACTUAL_INDEX,
  FIO_DIRECTOR,
  PHONE_COMPANY,
  FIO_TRUSTED,
  TRUSTED_POSITION,
  PHONE_TRUSTED,
  FIO_ACCOUNTANT,
  PHONE_ACCOUNTANT,
  WEB_SITE,
  ACTION_TYPE,
  PAYMENT_ACCOUNT,
  BANK,
  CORRESPONDENT_ACCOUNT,
  BIK,
  SAME_ADDRESS,
  ADDITIONAL_INFO,
} from './formFields';
import { getAddressViewObject } from '../../helpers/addressMapping';
import { PROXY, WITH_TAX, WITHOUT_TAX, DOCS, CARGO, LAW, BACKEND_LAW, BACKEND_PROXY, CERTIFICATE } from './formKeys';
import { isTypesReady } from '../documents/selectors';
import { CATEGORY_1, CATEGORY_2, DATE_FORMAT, INDIVIDUAL } from '../../constants';
import * as statusKeys from '../resources/statusKeys';
import getFormattedPhone from '../../helpers/getFormattedPhone';
import getFullCompanyName from '../../helpers/getFullCompanyName';
import { ATTORNEY, M2, WITHOUT } from '../user/formFields';

export function getCounterValue(state) {
  return parseFloat(_get(state, CARS_NUMBER));
}

export function getDependentCounterValue(state) {
  return parseFloat(_get(state, CARS_RENT_NUMBER));
}

/**
 * Получаем значение поля город и улица (что приходит из actions)
 */
export const getLabel = (state) => (field) => _get(_getFieldAttr(state)(field), 'label');

/**
 * Получаем объект поля город и улица (что приходит из actions)
 */
export const getValue = (state) => (field) => _get(_getFieldAttr(state)(field), 'value');

const _getFieldAttr = defaultMemoize((values) => (field) => values[field]);

export const getIsSameAddress = createSelector(
  (state) => _get(state, SAME_ADDRESS),
  (isSameAddress) => !!isSameAddress,
);

export const getIsProxy = createSelector(
  (state) => _get(state, BASIS_FOR_SIGN),
  (regalement) => regalement === PROXY,
);

export const getCompanyTitle = createSelector(
  (state) => _get(state, 'company.props.opf.name'),
  (state) => _get(state, 'company.props.title'),
  (opf, title) => getFullCompanyName(title, opf),
);

export const getCompanyInfo = createSelector(
  (state) => _get(state, 'company'),
  (fullInfo) => fullInfo,
);

/**
 * Получение ID компании
 */
export const getCompanyHumanFriendlyID = defaultMemoize((state) => _get(state, 'company.human_friendly_id'));

export const getCompanyID = defaultMemoize((state) => _get(state, 'company.id'));

export const getCompanyINN = defaultMemoize((state) => _get(state, 'company.props.inn'));

export const getCompanyKPP = defaultMemoize((state) => _get(state, 'company.props.kpp'));

export const getCompanyOpfCode = defaultMemoize((state) => state?.company?.props?.opf?.code);

export const getCompanyTaxType = defaultMemoize((state) => _get(state, 'company.props.tax_type'));

export const getCompanyFirstLetter = createSelector(
  (state) => _get(state, 'company.props.title'),
  (company) => {
    if (company) {
      return company[0].toUpperCase();
    }
  },
);

export const getCompanyInfoForDriverSampleContract = createSelector(
  (state) => _get(state, 'company'),
  (company) => {
    return {
      name: _get(company, 'props.title'),
      opf: _get(company, 'props.opf.name'),
      [INN]: _get(company, 'props.inn'),
      [OGRN]: _get(company, 'props.ogrn'),
      city: _get(company, 'props.legal_address.city'),
      ceo_full_name: _get(company, 'ceo_info.full_name'),
    };
  },
);

export const getCompanySavedActualAddress = createSelector(
  (state) => _get(state, 'company.props.real_address'),
  (realAddress) => {
    return {
      [ADDRESS_ACTUAL_CITY]: getAddressViewObject(realAddress.city),
      [ADDRESS_ACTUAL_STREET]: getAddressViewObject(realAddress.street),
      [ADDRESS_ACTUAL_BUILDING]: getAddressViewObject(realAddress.house_number),
      [ADDRESS_ACTUAL_ROOM]: realAddress.room,
      [ADDRESS_ACTUAL_INDEX]: realAddress.zip_code,
    };
  },
);

export const getCompanyLegalInfo = createSelector(
  (state) => getCompanyTitle(state),
  (state) => state?.company?.props,
  (companyTitle, companyProps) => {
    const rawOkved = _get(companyProps, 'okvds');
    return {
      [COMPANY]: companyTitle,
      [INN]: _get(companyProps, 'inn'),
      [KPP]: _get(companyProps, 'kpp'),
      [OGRN]: _get(companyProps, 'ogrn'),
      [OKVED]: rawOkved ? rawOkved.join(', ') : '',
      [COUNTRY]: 'Россия',
    };
  },
);

export const getInitialCompanyRequisitesValues = createSelector(
  (state) => _get(state, 'company.props'),
  (state) => _get(state, 'company.props.legal_address'),
  (state) => getCompanySavedActualAddress(state),
  (companyProps, legalAddress, actualAddress) => {
    const rawDate = moment(_get(companyProps, 'ogrn_created_at'));
    const formattedLegalAddress = {
      [ADDRESS_LEGAL_CITY]: getAddressViewObject(legalAddress.city),
      [ADDRESS_LEGAL_STREET]: getAddressViewObject(legalAddress.street),
      [ADDRESS_LEGAL_BUILDING]: getAddressViewObject(legalAddress.house_number),
      [ADDRESS_LEGAL_INDEX]: legalAddress.zip_code,
      [ADDRESS_LEGAL_ROOM]: legalAddress.room,
    };

    return {
      ...formattedLegalAddress,
      ...actualAddress,
      [INN]: _get(companyProps, 'inn'),
      [OGRN]: _get(companyProps, 'ogrn'),
      [COMPANY]: _get(companyProps, 'title'),
      [DATE]: rawDate.format(DATE_FORMAT),
      [OKVED]: _get(companyProps, 'okvds'),
      [COUNTRY]: 'Россия',
      [KPP]: _get(companyProps, 'kpp'),
      [SAME_ADDRESS]: _get(companyProps, 'legal_address_eq_real'),
    };
  },
);

const BASIS_FOR_SIGN_VALUES_MAP = {
  // front to api
  [LAW]: BACKEND_LAW,
  [PROXY]: BACKEND_PROXY,
  // api to front
  [BACKEND_LAW]: LAW,
  [BACKEND_PROXY]: PROXY,
  // both
  [CERTIFICATE]: CERTIFICATE,
};
const getBasisForSignsByOpfId = (opfId) => {
  if (opfId === INDIVIDUAL.id) return [CERTIFICATE, BACKEND_PROXY];
  return [BACKEND_LAW, BACKEND_PROXY];
};
const isCorrectBasisForSign = (opfId, basisForSign) => {
  return getBasisForSignsByOpfId(opfId).includes(basisForSign);
};

export const getIsCompanyIndividual = defaultMemoize((state) => {
  const opfId = _get(state, 'company.props.opf_id');
  return opfId === INDIVIDUAL.id;
});

export const getCompanyMainInfoUpdateData = defaultMemoize((data) => {
  return {
    props: {
      basis_for_sign: BASIS_FOR_SIGN_VALUES_MAP[data[BASIS_FOR_SIGN]],
      tax_type: data[TAX_TYPE] === WITH_TAX ? 'with_vat' : 'without_vat',
      action_type: _getActionType(data[ACTION_TYPE]),
    },
    trusted_person_info: {
      full_name: data[FIO_TRUSTED] || null,
      phone: data[PHONE_TRUSTED] || null,
      position: data[TRUSTED_POSITION] || null,
    },
    ceo_info: {
      full_name: data[FIO_DIRECTOR] || null,
    },
    accountant_info: {
      full_name: data[FIO_ACCOUNTANT] || null,
      phone: data[PHONE_ACCOUNTANT] || null,
    },
    additional_info: {
      website_url: data[WEB_SITE] || null,
      car_park_total: data[CARS_NUMBER],
      car_park_lease: data[CARS_RENT_NUMBER],
      comment: data[ADDITIONAL_INFO],
      phone: data[PHONE_COMPANY] || null,
    },
    attorney_requirement: data[ATTORNEY] === M2 ? M2 : null,
  };
});

function _getActionType(actionType) {
  switch (actionType.value) {
    case 'cargo':
      return 'transport_and_forwarding';
    case 'docs':
    default:
      return 'transport';
  }
}

export const getCompanyPaymentRequisitesUpdateData = defaultMemoize((data) => {
  return {
    props: {
      settlement_account: data[PAYMENT_ACCOUNT],
      bank_name: data[BANK],
      correspondent_account: data[CORRESPONDENT_ACCOUNT],
      bik: data[BIK],
    },
  };
});

export const getCompanyRequisitesActualAddressUpdateData = defaultMemoize((data) => {
  if (!data) return {};
  return {
    city: _get(data, `${ADDRESS_ACTUAL_CITY}.label`, null),
    street: _get(data, `${ADDRESS_ACTUAL_STREET}.label`, null),
    house_number: _get(data, `${ADDRESS_ACTUAL_BUILDING}.label`, null),
    room: data[ADDRESS_ACTUAL_ROOM],
    zip_code: data[ADDRESS_ACTUAL_INDEX],
  };
});

export const getCompanyRequisitesLegalAddressUpdateData = defaultMemoize((data) => {
  if (!data) return {};
  return {
    city: _get(data, `${ADDRESS_LEGAL_CITY}.label`, null),
    street: _get(data, `${ADDRESS_LEGAL_STREET}.label`, null),
    house_number: _get(data, `${ADDRESS_LEGAL_BUILDING}.label`, null),
    room: data[ADDRESS_LEGAL_ROOM],
    zip_code: data[ADDRESS_LEGAL_INDEX],
  };
});

export const getCompanyRequisitesUpdateData = createSelector(
  (state) => getCompanyRequisitesActualAddressUpdateData(state),
  (state) => getCompanyRequisitesLegalAddressUpdateData(state),
  (state) => getIsSameAddress(state),
  (actualAddress, legalAddress, isSameAddress) => {
    if (isSameAddress) {
      return {
        props: {
          legal_address: { ...legalAddress },
          legal_address_eq_real: isSameAddress,
        },
      };
    }

    return {
      props: {
        real_address: { ...actualAddress },
        legal_address: { ...legalAddress },
        legal_address_eq_real: isSameAddress,
      },
    };
  },
);

export const getIsCompanyMainInfoComplete = createSelector(
  (state) => _get(state, 'company'),
  (company) => {
    const isProxyBasis = _get(company, 'props.basis_for_sign') === BACKEND_PROXY;

    const isRegularPartComplete = [_get(company, 'ceo_info.full_name'), _get(company, 'additional_info.phone')].every(
      Boolean,
    );

    if (isProxyBasis) {
      const isProxyPartComplete = [
        _get(company, 'trusted_person_info.full_name'),
        _get(company, 'trusted_person_info.phone'),
        _get(company, 'trusted_person_info.position'),
      ].every(Boolean);

      return isRegularPartComplete && isProxyPartComplete;
    }

    return isRegularPartComplete;
  },
);

export const getIsCompanyRequisitesComplete = createSelector(
  (state) => _get(state, 'company.props.real_address'),
  (state) => _get(state, 'company.props.legal_address'),
  (realAddress, legalAddress) => !!_get(realAddress, 'city') && !!_get(legalAddress, 'city'),
  // !!_get(realAddress, 'zip_code') &&
  // !!_get(legalAddress, 'zip_code'), // TODO уточниить обязательность этого поля
);

export const getIsCompanyPaymentRequisitesComplete = createSelector(
  (state) => _get(state, 'company.props'),
  (companyProps) =>
    !!_get(companyProps, 'settlement_account') &&
    !!_get(companyProps, 'bank_name') &&
    !!_get(companyProps, 'correspondent_account') &&
    !!_get(companyProps, 'bik'),
);

/**
 * Селектор для проверки заполнения
 * всех разделов в профиле компании
 * @return {Boolean}
 */
export const getIsCompanyProfileFullyComplete = createSelector(
  (state) => getIsCompanyMainInfoComplete(state),
  (state) => getIsCompanyRequisitesComplete(state),
  (state) => getIsCompanyPaymentRequisitesComplete(state),
  (state) => isTypesReady(state, 'company'),
  (...isCompleteForms) => isCompleteForms.every(Boolean),
);

export const getInitialCompanyMainInfoValues = createSelector(
  (state) => _get(state, 'company'),
  (company) => {
    if (!company) return {};

    const opfId = company?.props?.opf_id;

    return {
      [ACTION_TYPE]:
        company?.props.action_type === 'transport'
          ? { value: DOCS, label: 'Транспортная компания' }
          : { value: CARGO, label: 'Транспортно-экспедиционная компания' },
      [TAX_TYPE]: company?.props?.tax_type === 'without_vat' ? WITHOUT_TAX : WITH_TAX,
      [BASIS_FOR_SIGN]: isCorrectBasisForSign(opfId, company?.props?.basis_for_sign)
        ? BASIS_FOR_SIGN_VALUES_MAP[company.props.basis_for_sign]
        : getBasisForSignsByOpfId(opfId)[0],
      [FIO_DIRECTOR]: company?.ceo_info.full_name || '',
      [PHONE_COMPANY]: company?.additional_info.phone || '',
      [FIO_ACCOUNTANT]: company?.accountant_info.full_name || '',
      [PHONE_ACCOUNTANT]: company?.accountant_info.phone || '',
      [WEB_SITE]: company?.additional_info.website_url || '',
      [CARS_NUMBER]: company?.additional_info.car_park_total || 1,
      [CARS_RENT_NUMBER]: company?.additional_info.car_park_lease || 0,
      [ADDITIONAL_INFO]: company?.additional_info.comment || '',
      [FIO_TRUSTED]: company?.trusted_person_info.full_name || undefined,
      [PHONE_TRUSTED]: company?.trusted_person_info.phone || undefined,
      [TRUSTED_POSITION]: company?.trusted_person_info.position || undefined,
      [ATTORNEY]: company?.attorney_requirement ?? WITHOUT,
    };
  },
);

export const getInitialCompanyPaymentRequisitesValues = createSelector(
  (state) => _get(state, 'company.props'),
  (companyProps) => {
    const rawOkved = _get(companyProps, 'okvds');
    return {
      [PAYMENT_ACCOUNT]: _get(companyProps, 'settlement_account') || '',
      [CORRESPONDENT_ACCOUNT]: _get(companyProps, 'correspondent_account') || '',
      [BIK]: _get(companyProps, 'bik') || '',
      [BANK]: _get(companyProps, 'bank_name') || '',
      [OKVED]: rawOkved ? rawOkved.join(', ') : '',
    };
  },
);

/**
 * Получить текущий статус компании
 */
export const getCompanyStatus = createSelector(
  (state) => _get(state, 'company.company_status'),
  (status) => {
    const codeName = _get(status, 'code_name');
    const comment = _get(status, 'comment');
    return {
      isOk: codeName === statusKeys.APPROVED,
      isError: codeName === statusKeys.NEED_FIX_BY_USER,
      isProgress: codeName === statusKeys.IN_PROGRESS_BY_MANAGER || codeName === statusKeys.REVIEW, // true, //
      isWait: codeName === statusKeys.NOT_APPROVED,
      isReject: codeName === statusKeys.REJECTED,
      comment,
    };
  },
);

/**
 * Получить значение: компания прошла проверку или нет
 */
export const getIsCompanyApproved = createSelector(
  (state) => getCompanyStatus(state),
  (status) => _get(status, 'isOk') || false, //
);

/**
 * Получить значение: компания на проверке или нет
 */
export const getIsCompanyCheckInProgress = createSelector(
  (state) => getCompanyStatus(state),
  (status) => _get(status, 'isProgress') || false,
);

/**
 * Получить структуру данных для определения
 * статуса готовности компании
 */
export const getCompanyRegistrationStatus = createSelector(
  (state) => getCompanyStatus(state),
  (state) => _get(state, 'router.location.pathname'),
  (state) => getIsCompanyProfileFullyComplete(state),
  (status, path, isComplete) => {
    return {
      status,
      isCompany: path.includes('/profile/company'),
      isComplete,
    };
  },
);

/**
 * Получение полей по персональному менеджеру компании
 */
export const getPersonalManager = createSelector(
  (state) => _get(state, 'company.contacts.category_1.personal_manager'),
  (personalManager) => {
    if (!personalManager) return null;
    const phone = _get(personalManager, 'phone');

    return {
      name: _get(personalManager, 'full_name'),
      phone: phone,
      phoneFormatted: getFormattedPhone(phone),
      email: _get(personalManager, 'email').toLowerCase(),
    };
  },
);

/**
 * Получение списка допущенных сомпаний до перевозки
 */
export const getAllowCompanyCategories = defaultMemoize((state) => _get(state, 'company.tc_categories'));

/**
 * Доступна ли компании ускоренная оплата
 * @type {function(*): boolean}
 */
export const getAllowExpressPayment = defaultMemoize((state) => !!_get(state, 'company.expedited_payment_available'));

/**
 * Возможность досрочного ручного выбора победителя разовой заявки
 * @type {function(*): boolean}
 */
export const getManualSpotWinnerPickAllowed = defaultMemoize(
  (state) => !!_get(state, 'company.manual_spot_winner_pick_allowed'),
);

/**
 * Установленный период автопродления
 * @type {function(*): number}
 */
export const getAuctionAutoExtensionPeriod = defaultMemoize((state) =>
  _get(state, 'company.auction_auto_extend_period'),
);

/**
 * Разрешить перевозчикам отправлять запросы на партнерство
 * @type {function(*): number}
 */
export const getPartnershipAllowed = defaultMemoize((state) => _get(state, 'company.partnerships_allowed'));

/**
 * Страхование обязательно
 * @type {function(*): boolean}
 */
export const getInsuranceRequired = defaultMemoize((state) => _get(state, 'company.compulsory_insurance'));

/**
 * Выбранный механизм страхования
 * @type {function(*): string}
 */
export const getInsuranceOption = defaultMemoize((state) => _get(state, 'company.insurance.type'));

/**
 * Сумма страхования
 * @type {function(*): string}
 */
export const getInsuranceSum = defaultMemoize((state) => _get(state, 'company.insurance.insurance_price'));

/**
 * Тоннаж транспорта для страхования
 * @type {function(*): Array}
 */
export const getInsuranceTonnage = defaultMemoize((state) => _get(state, 'company.insurance.tonnage'));

/**
 * Согласие с онлайн-договором площадки
 */
export const getOnlineContractApproved = defaultMemoize((state) => !!state?.company?.online_contract_approved);

/**
 * Получить список контактов
 */
export const getCompaniesContacts = createSelector(
  (state) => _get(state, 'company.contacts'),
  (state) => _get(state, 'company.personal_manager'),
  (contacts, manager) => {
    //-----------------------------------
    const mappedManager = {
      personal: {
        name: _get(manager, 'full_name'),
        phone: _get(manager, 'phone'),
        email: _get(manager, 'email'),
      },
    };
    const mappedForCat1 = mapCompanyContact(_get(contacts, CATEGORY_1), CATEGORY_1);
    const mappedForCat2 = mapCompanyContact(_get(contacts, CATEGORY_2), CATEGORY_2);
    //-----------------------------------
    return {
      [CATEGORY_1]: { ...mappedManager, ...mappedForCat1 },
      [CATEGORY_2]: { ...mappedManager, ...mappedForCat2 },
    };
  },
);

export const getCompanyRate = createSelector(
  (state) => _get(state, 'company.rating'),
  (rate) => rate,
);

export const getCompanyLogo = defaultMemoize((state) => state?.company?.logo_url);

export const isWithTax = createSelector(
  (state) => _get(state, 'company.props.tax_type'),
  (taxType) => taxType === 'with_vat',
);

function mapCompanyContact(contact, category) {
  function getContact(item) {
    if (!_get(item, 'full_name')) {
      return null;
    }
    return {
      name: _get(item, 'full_name'),
      phone: _get(item, 'phone'),
      email: _get(item, 'email'),
    };
  }
  if (!contact) {
    return null;
  }

  let chief = getContact(_get(contact, 'chief'));
  const development = getContact(_get(contact, 'development_manager'));
  const accountant = getContact(_get(contact, 'accountant'));
  let personal = getContact(_get(contact, 'personal_manager'));

  if (!chief && category === CATEGORY_2) {
    chief = {
      name: 'Ломакин Александр',
      phone: '+7 (981) 133-27-61',
      email: 'Aleksandr.Lomakin@traffic.online',
    };
  }

  if (!personal && category === CATEGORY_2) {
    personal = {
      name: 'Ломакин Александр',
      phone: '+7 (981) 133-27-61',
      email: 'Aleksandr.Lomakin@traffic.online',
    };
  }

  let result = {};
  if (chief) {
    result['chief'] = chief;
  }
  if (chief) {
    result['development'] = development;
  }
  if (chief) {
    result['accountant'] = accountant;
  }
  if (chief) {
    result['personal'] = personal;
  }
  return result;
}

export const getDltOfferInfo = defaultMemoize((state) => _get(state, 'company.dlt_offer'));
export const getDlOfferInfo = defaultMemoize((state) => _get(state, 'company.dl_offer'));
