import React from 'react';
import SVGIcon from './index';

const IconArrowLong = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 10.5L15 8V10H2V11H15V13L18 10.5Z" />
    </SVGIcon>
  );
};

IconArrowLong.defaultProps = {
  color: 'base',
};

export default IconArrowLong;
