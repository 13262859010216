import React from 'react';
import SVGIcon from './index';

function IconArrowFromSquare(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20" fill="none">
      <path d="M9 4.5H4V16.5H16V11.5" stroke="#0B5EDA" fill="none" strokeWidth="2" />
      <path d="M16 4.5V9.5M16 4.5H11M16 4.5L8 12.5" stroke="#0B5EDA" strokeWidth="2" />
    </SVGIcon>
  );
}

IconArrowFromSquare.defaultProps = {
  color: 'primary',
};

export default IconArrowFromSquare;
