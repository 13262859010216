export enum ESortParams {
  SORT = 'sort',
  ORDER = 'order',
}
export enum ESortOrderTypes {
  ASC = 'asc',
  DESC = 'desc',
}

export enum EPaginationParams {
  PAGE = 'page',
  PER_PAGE = 'per_page',
  NEXT = 'next',
}
export const PAGE_DEFAULT_VALUE = 1;
export const PER_PAGE_DEFAULT_VALUE = 20;

export const ACTIVE_ITEM_QUERY_KEY = 'active_item';
