import * as constants from './constants';
import { INCOMING_CLAIMS, OUTCOMING_CLAIMS } from '../documents/constants';

/**
 * Перевод комментария статуса претензии
 * @param status
 * @param kind
 * @returns {*}
 */
export function getClaimStatusCommentTranslation(status, kind = constants.INCOMING) {
  switch (status) {
    case constants.PENDING_TC_REVIEW:
      return 'Необходимо предоставить ответ в течение 10 рабочих дней.';
    // case constants.NEW:
    //   return 'Необходимо предоставить ответ в течение 10 рабочих дней.';
    case constants.ACCEPT:
    case constants.PARTIAL_ACCEPT:
      if (kind === constants.OUTCOMING) return 'Сумма к выплате будет учтена во взаиморасчётах.';
      return 'В ближайшее время будет произведён взаиморасчёт с учётом суммы по претензии.';
    case constants.ACCEPT_UNILATERALLY:
      // case constants.PARTIAL_ACCEPT:
      return (
        'По условиям договора претензия считается согласованной и будет ' +
        'удовлетворена. Подробнее – в прикрепленном документе «Решение» в ' +
        'блоке «Документы».'
      );
    case constants.ACCEPT_EXPIRATION:
      return (
        'По условиям договора претензия считается согласованной по ' +
        'истечению срока рассмотрения и будет удовлетворена.'
      );
    case constants.NEED_FIX:
      return 'При рассмотрении претензии возникли замечания. Отредактируйте и отправьте заново.';
    case constants.DONE:
      if (kind === constants.OUTCOMING) return 'Сумма к выплате была учтена во взаиморасчётах.';
      return 'Взаиморасчёты по претензии произведены.';
    case constants.PENDING_REVIEW:
    case constants.IN_PROGRESS:
      return 'Срок рассмотрения – не более 10 рабочих дней.';
    case constants.REJECT:
      return 'Ознакомьтесь с информацией от менеджера.';
    case constants.NEW:
    default:
      return null;
  }
}

/**
 * Перевод типа претензии
 * @param type
 * @returns {string}
 */
export function getClaimTypeTranslation(type) {
  switch (type) {
    case constants.IDLE:
      return 'Простой';
    case constants.LOADING_FAIL:
      return 'Срыв погрузки';
    case constants.LATENESS:
      return 'Опоздание';
    case constants.DOCUMENTS:
      return 'Сдача документов';
    case constants.DAMAGE:
      return 'Порча груза';
    case constants.OTHER:
      return 'Прочее';
    default:
      return type;
  }
}

/**
 * Маппинг констант типа претензии на константы типа документа претензии
 * @param type
 * @returns {string}
 */
export function getDocumentsEntity(type) {
  if (type === constants.OUTCOMING) return OUTCOMING_CLAIMS;
  if (type === constants.INCOMING) return INCOMING_CLAIMS;
}
