import React from 'react';
import SVGIcon from './index';

const IconDelete = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <SVGIcon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3.5L8.5 3H12.5L13 3.5L13.5 4H16.5V6H4.5V4H7.5L8 3.5ZM5.5 7H15.5V17H5.5V7ZM7.5 15V9H8.5V15H7.5ZM10 9H11V15H10V9ZM13.5 15V9H12.5V15H13.5Z"
    />
  </SVGIcon>
);

IconDelete.defaultProps = { color: 'base' };

export default React.memo(IconDelete);
