import React, { Fragment } from 'react';

import FormType from '../../components/FormType';
import OrderType from '../../components/OrderType';
import OrderTypeNotification from '../../components/OrderTypeNotification';

import { useOrderContext } from '../../index';
import { useOrderTypeEffect } from '../../hooks/useOrderTypeEffect';
import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import { useOrderTypeUnavailableEffect } from '../../hooks/useOrderTypeUnavailableEffect';

export const ORDER_TYPE = 'order_type';
export const FORM_TYPE = 'form_type';
export const TRANSPORT_TYPE = 'transport_type';

/* Типы заказа */
export const SHIPPING_REQUEST = 'shipping_request';
export const CONTRACT_BY_COUNT = 'by_shippings_count';
export const CONTRACT_BY_SCHEDULE = 'by_schedule';

/* Типы формы */
export const FULL = 'full';
export const SIMPLE = 'simple';

/* Типы транспорта */
export const BTS = 'bts';
export const MKT = 'mkt';

/**
 * Блок: Тип заказа и Тип формы
 * Блок включает 2 поля: Вид заказа и Вид формы создания заказа
 */
export function OrderNFormTypeBlock() {
  const { isOrderChange, isOrderBasedOnContract } = useOrderContext();
  const [orderType] = useFieldValue(ORDER_TYPE);

  /* Эффект на смену типа заказа */
  useOrderTypeEffect(orderType);

  /* Эффект при недоступности Типа заказа */
  useOrderTypeUnavailableEffect();

  if (isOrderChange) return null;

  if (isOrderBasedOnContract) {
    return <FormType />;
  }

  return (
    <Fragment>
      {/* Вид заказа */}
      <OrderTypeNotification />
      <OrderType />

      {/* Вид формы создания заказа */}
      <FormType />
    </Fragment>
  );
}
