import React from 'react';
import SVGIcon from './index';

const IconTarget = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M9.45831 18.3749V16.5576C7.77776 16.3792 6.39955 15.7486 5.32369 14.6658C4.24784 13.583 3.62071 12.2083 3.44229 10.5416H1.625V9.45831H3.44229C3.62071 7.79165 4.24784 6.41691 5.32369 5.3341C6.39955 4.25131 7.77776 3.62071 9.45831 3.44229V1.625H10.5416V3.44229C12.2222 3.62071 13.6004 4.25131 14.6762 5.3341C15.7521 6.41691 16.3792 7.79165 16.5576 9.45831H18.3749V10.5416H16.5576C16.3792 12.2083 15.7521 13.583 14.6762 14.6658C13.6004 15.7486 12.2222 16.3792 10.5416 16.5576V18.3749H9.45831ZM9.99996 15.5C11.5277 15.5 12.8263 14.9652 13.8958 13.8958C14.9652 12.8263 15.5 11.5277 15.5 9.99996C15.5 8.47218 14.9652 7.17357 13.8958 6.10413C12.8263 5.03468 11.5277 4.49996 9.99996 4.49996C8.47218 4.49996 7.17357 5.03468 6.10413 6.10413C5.03468 7.17357 4.49996 8.47218 4.49996 9.99996C4.49996 11.5277 5.03468 12.8263 6.10413 13.8958C7.17357 14.9652 8.47218 15.5 9.99996 15.5ZM9.99996 12.5833C9.28122 12.5833 8.67099 12.3324 8.16925 11.8307C7.66751 11.3289 7.41665 10.7187 7.41665 9.99996C7.41665 9.28122 7.66751 8.67099 8.16925 8.16925C8.67099 7.66751 9.28122 7.41665 9.99996 7.41665C10.7187 7.41665 11.3289 7.66751 11.8307 8.16925C12.3324 8.67099 12.5833 9.28122 12.5833 9.99996C12.5833 10.7187 12.3324 11.3289 11.8307 11.8307C11.3289 12.3324 10.7187 12.5833 9.99996 12.5833Z"
        fill="#98A3B3"
      />
    </SVGIcon>
  );
};

IconTarget.defaultProps = {
  color: 'base',
};

export default IconTarget;
