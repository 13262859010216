import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

function IconMenuBurger(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 22 19">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 0.5H0.5V3.5H21.5V0.5ZM0.5 8H21.5V11H0.5V8ZM0.5 15.5H21.5V18.5H0.5V15.5Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
}

IconMenuBurger.defaultProps = {
  color: 'base',
};

export default React.memo(IconMenuBurger);
