import React, { lazy, Suspense } from 'react';

import ContentLoader from '../../../../components/ContentLoader';

const OrderAsideBlock = lazy(() => import('./OrderAsideBlock'));

function getBlockComponent(hashString) {
  switch (hashString) {
    default:
      return OrderAsideBlock;
  }
}

export function AsideBlock() {
  const Component = getBlockComponent('');
  return <Suspense fallback={<ContentLoader withBackground />}>{Component ? <Component /> : null}</Suspense>;
}
