import React from 'react';
import SVGIcon from './index';

export default function IconUpload(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path d="M16 15.5V17H4L4 15.5H16Z" />
      <path d="M8.05928 5L10 3L11.9407 5H12V5.0611L15.5 8.66802L12 8.66802L12 14H8L8 8.66802L4.5 8.66802L8 5.0611V5H8.05928Z" />
    </SVGIcon>
  );
}

IconUpload.defaulProps = { color: 'base' };
