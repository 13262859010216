import * as formKeys from './formKeys';
import * as formFields from './formFields';
import { TCarBodyType, TCoverType } from './formKeys';

export type TBodyTypeItem = {
  value: TCarBodyType;
  label: string;
};

export type TCoverTypeItem = {
  value: TCoverType;
  label: string;
};

export type TBodyTypes = {
  [formKeys.AWNING]: TBodyTypeItem[];
  [formKeys.ISOTHERMAL]: TBodyTypeItem[];
  [formKeys.REFRIGERATOR]: TBodyTypeItem[];
  [formKeys.FULL_METAL]: TBodyTypeItem[];
  [formKeys.DROPSIDE]: TBodyTypeItem[];
  [formKeys.TANKER]: TBodyTypeItem[];
  [formKeys.TIMBER_CARRIER]: TBodyTypeItem[];
  [formKeys.STEP_DECK]: TBodyTypeItem[];
};

export type TCoverTypes = {
  [formKeys.AWNING]: TCoverTypeItem[];
  [formKeys.ISOTHERMAL]: TCoverTypeItem[];
  [formKeys.REFRIGERATOR]: TCoverTypeItem[];
  [formKeys.FULL_METAL]: TCoverTypeItem[];
  [formKeys.DROPSIDE]: TCoverTypeItem[];
  [formKeys.TANKER]: TCoverTypeItem[];
  [formKeys.CONTAINER]: TCoverTypeItem[];
  [formKeys.TIMBER_CARRIER]: TCoverTypeItem[];
  [formKeys.STEP_DECK]: TCoverTypeItem[];
};

export type TLoadingType = { description?: string } & TBodyTypeItem;

export type TLoadOptions = {
  [formKeys.AWNING]: TLoadingType[];
  [formKeys.ISOTHERMAL]: TLoadingType[];
  [formKeys.REFRIGERATOR]: TLoadingType[];
  [formKeys.FULL_METAL]: TLoadingType[];
  [formKeys.DROPSIDE]: TLoadingType[];
  [formKeys.TIMBER_CARRIER]: TLoadingType[];
  [formKeys.STEP_DECK]: TLoadingType[];
};

export type TWithBelts = {
  [formKeys.AWNING]: boolean;
  [formKeys.ISOTHERMAL]: boolean;
  [formKeys.REFRIGERATOR]: boolean;
  [formKeys.FULL_METAL]: boolean;
  [formKeys.DROPSIDE]: boolean;
  [formKeys.TIMBER_CARRIER]: boolean;
  [formKeys.STEP_DECK]: boolean;
};

export type TDimensions = {
  [formFields.LENGTH]: number;
  [formFields.WIDTH]: number;
  [formFields.HEIGHT]: number;
};

//типы кузовов
export const AWNING_OBJ: TBodyTypeItem = {
  value: formKeys.AWNING,
  label: 'Тент',
};
export const ISOTHERMAL_OBJ: TBodyTypeItem = {
  value: formKeys.ISOTHERMAL,
  label: 'Изотермический',
};
export const REFRIGERATOR_OBJ: TBodyTypeItem = {
  value: formKeys.REFRIGERATOR,
  label: 'Рефрижератор',
};
export const FULL_METAL_OBJ: TBodyTypeItem = {
  value: formKeys.FULL_METAL,
  label: 'Цельнометаллический',
};
export const BOARD_OBJ: TBodyTypeItem = {
  value: formKeys.DROPSIDE,
  label: 'Бортовой',
};
export const TANKER_OBJ: TBodyTypeItem = {
  value: formKeys.TANKER,
  label: 'Автоцистерна',
};
export const CONTAINER_OBJ: TBodyTypeItem = {
  value: formKeys.CONTAINER,
  label: 'Контейнеровоз',
};
export const TIMBER_CARRIER_OBJ: TBodyTypeItem = {
  value: formKeys.TIMBER_CARRIER,
  label: 'Лесовоз',
};
export const STEP_DECK_OBJ: TBodyTypeItem = {
  value: formKeys.STEP_DECK,
  label: 'Автотрал',
};

// типы растентовки
export const FULL_COVER_TRANSLATION: string = 'Полная';
export const FULL_COVER_OBJ: TCoverTypeItem = {
  value: formKeys.FULL_COVER,
  label: FULL_COVER_TRANSLATION,
};
export const BACK_COVER_TRANSLATION: string = 'Задняя';
export const BACK_COVER_OBJ: TCoverTypeItem = {
  value: formKeys.BACK_COVER,
  label: BACK_COVER_TRANSLATION,
};
export const SIDE_COVER_TRANSLATION: string = 'Боковая';
export const SIDE_COVER_OBJ: TCoverTypeItem = {
  value: formKeys.SIDE_COVER,
  label: SIDE_COVER_TRANSLATION,
};
export const TOP_COVER_TRANSLATION: string = 'Верхняя';
export const TOP_COVER_OBJ: TCoverTypeItem = {
  value: formKeys.TOP_COVER,
  label: TOP_COVER_TRANSLATION,
};

// веса типов загрузки
export const COVER_TYPES_WEIGHTS = {
  [formKeys.FULL_COVER]: 1,
  [formKeys.BACK_COVER]: 2,
  [formKeys.SIDE_COVER]: 3,
  [formKeys.TOP_COVER]: 4,
};

export const coverTypes: TCoverTypes = {
  [formKeys.AWNING]: [FULL_COVER_OBJ, BACK_COVER_OBJ, SIDE_COVER_OBJ, TOP_COVER_OBJ],
  [formKeys.ISOTHERMAL]: [BACK_COVER_OBJ],
  [formKeys.REFRIGERATOR]: [BACK_COVER_OBJ],
  [formKeys.FULL_METAL]: [BACK_COVER_OBJ],
  [formKeys.DROPSIDE]: [FULL_COVER_OBJ, BACK_COVER_OBJ, SIDE_COVER_OBJ, TOP_COVER_OBJ],
  [formKeys.TANKER]: [TOP_COVER_OBJ],
  [formKeys.CONTAINER]: [TOP_COVER_OBJ],
  [formKeys.TIMBER_CARRIER]: [TOP_COVER_OBJ],
  [formKeys.STEP_DECK]: [BACK_COVER_OBJ],
};

// типы загрузки
const REMOVABLE_UPPER_BEAM_OBJ = {
  value: formFields.REMOVABLE_UPPER_BEAM,
  label: 'Съёмная верхняя балка',
  description:
    'Имеется съёмная верхняя перекладина ворот для возможности заведения грузов краном через ворота прицепа.',
};

const REMOVABLE_SIDE_RACKS_OBJ = {
  value: formFields.REMOVABLE_SIDE_RACKS,
  label: 'Съёмные боковые стойки',
  description: 'Возможность сдвигать боковые стойки прицепа для боковой погрузки грузов длиной до 10 метров.',
};

const RIGID_BOARD_OBJ = {
  value: formFields.RIGID_BOARD,
  label: 'Жёсткий борт',
};

const DISINFECTED_OBJ = {
  value: formFields.DISINFECTED,
  label: 'Наличие санитарной обработки',
};

const TEMPERATURE_CHECK_OBJ = {
  value: formFields.TEMPERATURE_CHECK,
  label: 'Наличие температурного чека',
  description: 'Возможность формирования температурного чека с данными о температурном режиме',
};

const MANIPULATOR_OBJ = {
  value: formFields.MANIPULATOR,
  label: 'Манипулятор',
};

const HYDRO_BOARD_OBJ = {
  value: formFields.HYDRO_BOARD,
  label: 'Гидроборт',
};

export const loadOptions = {
  [formKeys.AWNING]: [
    REMOVABLE_UPPER_BEAM_OBJ,
    REMOVABLE_SIDE_RACKS_OBJ,
    RIGID_BOARD_OBJ,
    HYDRO_BOARD_OBJ,
    MANIPULATOR_OBJ,
  ],
  [formKeys.ISOTHERMAL]: [HYDRO_BOARD_OBJ, DISINFECTED_OBJ],
  [formKeys.REFRIGERATOR]: [TEMPERATURE_CHECK_OBJ, HYDRO_BOARD_OBJ, DISINFECTED_OBJ],
  [formKeys.FULL_METAL]: [HYDRO_BOARD_OBJ, DISINFECTED_OBJ],
  [formKeys.DROPSIDE]: [RIGID_BOARD_OBJ, HYDRO_BOARD_OBJ, MANIPULATOR_OBJ],
  [formKeys.TIMBER_CARRIER]: [MANIPULATOR_OBJ],
  [formKeys.STEP_DECK]: [],
};

// наличие ремней
export const withBelts = {
  [formKeys.AWNING]: true,
  [formKeys.ISOTHERMAL]: false,
  [formKeys.REFRIGERATOR]: false,
  [formKeys.FULL_METAL]: false,
  [formKeys.DROPSIDE]: true,
  [formKeys.TIMBER_CARRIER]: true,
};

// габариты кузова Машина
export const minBodyDimensionsCar = {
  [formFields.LENGTH]: 1.5,
  [formFields.WIDTH]: 0.5,
  [formFields.HEIGHT]: 0.5,
};

// габариты кузова Прицеп
export const minBodyDimensionsTrailer = {
  [formFields.LENGTH]: 6,
  [formFields.WIDTH]: 2.4,
  [formFields.HEIGHT]: 2.2,
};

export const maxBodyDimensions = {
  [formFields.LENGTH]: 17,
  [formFields.WIDTH]: 2.75,
  [formFields.HEIGHT]: 3,
};
