import React, { useEffect, useState, createContext, useContext, useMemo } from 'react';
import queryString from 'query-string';
import { redirectToAuth } from '../services/authService';
import defineAbilityFor from '../containers/ability/defineAbility';
import { useShallowEqualSelector } from '../hooks/useShallowEqualSelector';
import {
  getUserInfo,
  getIsAdmin,
  getRoleIsCargoOwner,
  // confirmedDocs as confirmedDocsSelector,
} from '../store/user/selectors';
import { getCompanyTaxType, getIsCompanyApproved } from '../store/company/selectors';
import { IUserInfo } from '../store/user/models';
import ContentLoader from '../components/ContentLoader';
import { useActions } from '../hooks/useActions';
import * as AuthActions from '../store/auth/actions';
import { getLoading } from '../store/auth/selectors';
import { useMultipolicyInitialRequests } from '../features/insurance/hooks';
import { getScope } from '../services/authTokenService';

export const UserContext = createContext<any>(undefined);

/**
 * Было бы хорошо логику по загрузке пользовательких данных вынести в контекст из редакса, либо сделать
 * загрузку данных пользователя + ролей и выбор абилок для юзера менее костыльно
 * @param children
 * @constructor
 */
function UserProvider({ children }) {
  const [isLoading, setLoading] = useState(true);

  const authByToken = useActions(AuthActions.authByToken);
  const authByCode = useActions(AuthActions.authByCode);

  const userInfo: Partial<IUserInfo> | any = useShallowEqualSelector(getUserInfo);
  const userId = userInfo?.id;
  const userRoles = useMemo(() => userInfo?.roles, [userInfo]);
  /* State */
  const [ability, setAbility] = useState<any>(defineAbilityFor([], userRoles));
  const [isPriceHelperAvailable, setIsPriceHelperAvailable] = useState<boolean>(false);
  const [isPriceHelperExcelAvailable, setIsPriceHelperExcelAvailable] = useState<boolean>(false);
  /* Selectors */
  const isAdmin = useShallowEqualSelector(getIsAdmin);
  const isCargoOwningUser = useShallowEqualSelector(getRoleIsCargoOwner);
  const isCompanyApproved = useShallowEqualSelector(getIsCompanyApproved);
  const companyTaxType = useShallowEqualSelector(getCompanyTaxType);
  const loading = useShallowEqualSelector(getLoading);
  const { code, state: queryState, flow } = queryString.parse(window.location.search) ?? {};

  useEffect(() => {
    if (code && queryState) {
      /* Авторизация через code */
      authByCode(code.toString(), queryState.toString(), flow);
    } else {
      /* Авторизация через refresh_token */
      authByToken();
    }
  }, [authByCode, authByToken, code, queryState, flow]);

  // просмотр документов по страхованию:
  // const confirmedDocs = useShallowEqualSelector(confirmedDocsSelector);
  // const getConfirmedDocsAction = useActions(getConfirmedDocs);
  // const companyId = userInfo?.companyId;
  // useEffect(() => {
  //   if (!companyId) return;
  //   getConfirmedDocsAction(companyId);
  // }, [getConfirmedDocsAction, companyId]);

  const { inBlackList } = useMultipolicyInitialRequests(userId);

  /* Получение абилок по роли юзера */
  useEffect(() => {
    const scope = getScope();
    try {
      if (userId && scope.length > 0) {
        const abilities = defineAbilityFor(scope, userRoles);
        setAbility(abilities);
        setIsPriceHelperAvailable(abilities.can('get', 'pa-calculator'));
        setIsPriceHelperExcelAvailable(abilities.can('excel', 'pa-calculator'));
        setLoading(false);
      }
    } catch (e) {
      console.error('ERROR and redirect to login', e);
      redirectToAuth();
    }
  }, [userId, userRoles]);

  /* Не удалось получить информацию по юзеру -> пуш на логин */
  useEffect(() => {
    if (!loading && !userId && isLoading) {
      redirectToAuth();
    }
  }, [isLoading, loading, userId]);

  if (loading || isLoading) return <ContentLoader withPortal />;

  return (
    <UserContext.Provider
      value={{
        ability,
        companyId: userInfo?.companyId,
        companyTaxType,
        isAdmin,
        isCargoOwningUser,
        isCompanyApproved,
        loading,
        userInfo,
        userId,
        confirmedDocs: [], // confirmedDocs || []
        isMultipolicyAvailable: !inBlackList,
        createdAt: userInfo?.createdAt,
        isPriceHelperAvailable,
        isPriceHelperExcelAvailable,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

/**
 * контекст пользователя
 * @return {{ability,
 *         companyId,
 *         companyTaxType,
 *         isAdmin,
 *         isCargoOwningUser,
 *         isCompanyApproved,
 *         loading,
 *         userInfo,
 *         userId,
 *         confirmedDocs: [],
 *         isMultipolicyAvailable
 *         createdAt
 *         isPriceHelperAvailable
 *         isPriceHelperExcelAvailable
 *         }}
 */
function useUserContext() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserState должен использоваться в UserProvider');
  }

  return context;
}

export { UserProvider, useUserContext };
