import React, { lazy, Suspense } from 'react';
import { getCoverTypes } from '../../helpers/getCoverTypes';
import { TRANSPORT_BODY_TYPES } from '../../blocks/TransportBlock';
import { ROUTE_DATE_FROM, ROUTE_DATES, ROUTE_POINTS, WAREHOUSE_ID } from '../../blocks/RoutesBlock';
import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import { useCoverTypeChange } from '../../hooks/useCoverTypeChange';
import { useDeepMemoValue } from '../../../../hooks/useDeepMemo';
import { useRouteDateValidationByContract } from '../../hooks/useRouteDateVlidationByContract';
import { useContractOrderRoutePointEffect } from '../../hooks/useRoutePointStreetEffect';
import { getHashString } from '../../helpers/getHashString';
import IconLoading from '../../../../components/Icons/CSSIcons/IconLoading/IconLoading';
import { useRoutePointCoordsFetcher } from '../../hooks/useRoutePointCoordsFetcher';
import { withSavedLocations } from '../../hocs/withSavedLocations';

const SimpleRoutePointWarehouse = lazy(() => import('./SimpleRoutePointWarehouse'));
const SimpleRoutePointForm = lazy(() => import('./SimpleRoutePointForm'));

function getBlockComponent(hashString) {
  switch (hashString) {
    case getHashString({ isWarehouse: true }):
      return SimpleRoutePointWarehouse;
    case getHashString({ isWarehouse: false }):
      return SimpleRoutePointForm;
    default:
      return null;
  }
}

function SimpleRoutePoint(props) {
  const { index, routePointsField = ROUTE_POINTS, isExtraRoutePoint = false, cityChangeHandler } = props;

  const [bodyTypes] = useFieldValue(TRANSPORT_BODY_TYPES);
  const coverTypes = useDeepMemoValue(getCoverTypes(bodyTypes));

  const [warehouseId] = useFieldValue(`${routePointsField}[${index}].${WAREHOUSE_ID}`);
  const isWarehouse = !!warehouseId;

  /* Валидация дат при создании заказа по контракту */
  useRouteDateValidationByContract(`${routePointsField}[${index}].${ROUTE_DATES}[0].${ROUTE_DATE_FROM}`, index);

  /* Эффект на смену типа загрузки при смене типа кузова */
  useCoverTypeChange(index, coverTypes);
  /* Эффект на смену точки маршрута - только для контрактной заявки */
  useContractOrderRoutePointEffect(index);

  useRoutePointCoordsFetcher(index);

  const hashString = getHashString({ isWarehouse });
  const Content = getBlockComponent(hashString);

  return (
    <Suspense fallback={<IconLoading width={20} height={20} />}>
      {Content ? (
        <Content
          index={index}
          routePointsField={routePointsField}
          isExtraRoutePoint={isExtraRoutePoint}
          cityChangeHandler={cityChangeHandler}
        />
      ) : null}
    </Suspense>
  );
}

export default withSavedLocations(SimpleRoutePoint);
