import _get from 'lodash/get';
import _last from 'lodash/last';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import _values from 'lodash/values';
import { defaultMemoize } from 'reselect';
import moment from 'moment';

import {
  CATEGORY_1,
  CATEGORY_2,
  DATE_FORMAT,
  DATE_TIME_EN_FORMAT,
  SHORT_DATE_FORMAT,
  TIME_FORMAT,
  SHORT_DATE_TIME_FORMAT,
} from '../../constants';
import { getLocationObject, getLocationName } from '../addressMapping';
import getFormattedPrice from '../getFormattedPrice';
import { FULL_COVER } from '../../store/resources/formKeys';
import { translateCoverTypes } from '../translateCoverTypes';
import * as resourcesFormFields from '../../store/resources/formFields';
import { getMappedCarTypes, getMappedTrailerTypes } from '../carTypesMapping';

import {
  TSupply,
  TRestrictions,
  TSenderInfo,
  TRouteSegment,
  TRoutePoint,
  TSenderInfoParams,
  TLCapasities,
} from './model';

/**
 * Получение первой и последней точки маршрута
 * @return {{route: *, region: *}[]}
 * @param item
 */
export function getPoints(item): {
  route: string | null;
  region?: string | null;
}[] {
  let points = (item?.route_points || item?.route?.route_points) ?? [];
  let location = points?.[0]?.location ?? [];
  let lastLocation = _last(points as any[])?.location;
  let direction = item?.route_direction ?? {};
  let hasNoDirection = !Object.values(direction).some(Boolean);

  if (hasNoDirection) {
    return points.map(({ location }) => {
      if (!location) return { route: null, region: null };
      return {
        route: getLocationName(location),
        region: location?.region_with_type || location?.region,
      };
    });
  }

  return [location, direction || lastLocation].reduce((result, point) => {
    result.push({
      route: getLocationName(point),
      region: point.region_with_type || point.region,
    });

    return result;
  }, []);
}

/**
 * получение информации о точках и сегментах маршрута
 * @param item
 * @returns {*}
 */
export function getRoutePoints(item: { route_points: any; route_segments: TRouteSegment[] }): TRoutePoint {
  const points = item?.route_points ?? [];
  const routeSegments = item?.route_segments ?? [];

  return points.map((point, index) => {
    let additionalServices = (point?.additional_services ?? []).map((item) => ({
      ...item,
      price: getFormattedPrice(item?.price),
    }));

    return {
      id: point?.id,
      arrival: getFormattedDate(point),
      location: getLocationObject(point),
      supplyRanges: getSupplyRanges(point?.car_supply_range ?? [], point?.car_supply_at ?? null),
      needDocumentWork: point?.need_document_work,
      contactsInfo: getContactsInfo(point),
      contacts: point.contacts,
      counter_agent: point.counter_agent,
      warehouse_id: point.warehouse_id,
      additionalServices,
      ...getLoadingFields(routeSegments, index, points),
      ...getTypeOfWorks(routeSegments, index),
      _original: point,
    };
  });
}

/**
 * Получение объекта ограничений
 * @param item
 * @returns {null}
 */
export function getRestrictions(item: {}): TRestrictions {
  const requirements = _get(item, 'resource_requirements');

  const cmrDocument = _get(requirements, 'cmr_document');
  const driverCoveralls = _get(requirements, 'has_driver_coveralls');
  const medicalBook = _get(requirements, 'has_medical_book');
  const noCriminalRecords = _get(requirements, 'has_no_criminal_records');
  const russianCitizen = _get(requirements, 'has_russian_citizen');
  const isOwnTransport = _get(requirements, 'own_transport_only');
  const rigidBoard = _get(requirements, 'has_rigid_board');
  const removableUpperBeam = _get(requirements, 'has_removable_upper_beam');
  const removableSideRacks = _get(requirements, 'has_removable_side_racks');
  const tirDocument = _get(requirements, 'tir_document');
  const numberOfBelts = parseFloat(_get(requirements, 'number_of_belts')) || null;
  const timberBunks = _get(requirements, 'timber_bunks');
  const shippingPowerOfAttorneyOriginal = _get(requirements, 'has_shipping_power_of_attorney_original');
  const isDisinfected = _get(requirements, 'is_disinfected');
  const temperatureCheck = _get(requirements, 'has_temperature_check');
  const twoSided = _get(requirements, 'two_sided');
  const hydroBoard = requirements?.hydro_board;
  const manipulator = requirements?.manipulator;
  const temperatureCondition = _get(requirements, 'temperature_condition')
    ? {
        min:
          _get(requirements, 'temperature_condition.min') > 0
            ? `+${_get(requirements, 'temperature_condition.min')}`
            : _get(requirements, 'temperature_condition.min'),
        max:
          _get(requirements, 'temperature_condition.max') > 0
            ? `+${_get(requirements, 'temperature_condition.max')}`
            : _get(requirements, 'temperature_condition.max'),
      }
    : null;

  const result = {
    cmrDocument,
    driverCoveralls,
    isDisinfected,
    isOwnTransport,
    medicalBook,
    noCriminalRecords,
    numberOfBelts,
    removableSideRacks,
    removableUpperBeam,
    rigidBoard,
    russianCitizen,
    shippingPowerOfAttorneyOriginal,
    temperatureCheck,
    temperatureCondition,
    timberBunks,
    tirDocument,
    twoSided,
    hydroBoard,
    manipulator,
  };

  // возвращаем null при отсутствии все ограничений
  if (_values(result).every((restriction) => restriction !== 0 && !restriction)) return null;

  return result;
}

/**
 * Выбор минимального значения тоннажа и объема
 * @param item
 * @returns {*}
 */
export const getMinTonnageVolume = (
  item: TLCapasities,
): {
  volume: number;
  tonnage: number;
} => {
  const liftingCapacities = _get(item, 'resource_requirements.lifting_capacities', []);

  return liftingCapacities.reduce((result, capacity) => {
    if (!result.tonnage || result.tonnage > capacity.tonnage) {
      result.tonnage = capacity.tonnage;
    }

    if (!result.volume || result.volume > capacity.volume) {
      result.volume = capacity.volume;
    }

    return result;
  }, {});
};

/**
 * Получение типов работ по точкам маршрута
 * @type {Function}
 */
export const getTypeOfWorks: (
  routeSegments: TRouteSegment[],
  index: number,
) => {
  loading: boolean;
  unloading: boolean;
} = defaultMemoize((routeSegments, index) => {
  let unload = _get(routeSegments, `[${index - 1}].unloading.type`);
  let load = _get(routeSegments, `[${index}].loading.type`);

  if (!!load && !unload) {
    return {
      loading: true,
      unloading: false,
    };
  }

  if (!load && !!unload) {
    return {
      loading: false,
      unloading: true,
    };
  }

  if (!!load && !!unload) {
    return {
      loading: true,
      unloading: true,
    };
  }

  return {
    loading: false,
    unloading: false,
  };
});

/**
 * получение информации о загрузке/выгрузке по сегментам маршрута
 * @param routeSegments
 * @param index
 * @param points
 * @returns {*}
 */
export const getLoadingFields: (
  routeSegments: TRouteSegment[],
  index: number,
  points: {}[],
) =>
  | {
      duration: string;
      durationInMinutes: number;
      coverTypes: Array<string>;
      coverTypesByWorkTypes: any;
    }
  | undefined = defaultMemoize((routeSegments, index, points) => {
  if (routeSegments && points) {
    const isFirst = index === 0;
    const isLast = index === points.length - 1;

    const getDurationInMinutes = (path: string) => Number.parseInt(_get(routeSegments, `${path}.duration`, 0), 10);

    /**
     * первый сегмент маршрута
     */
    if (isFirst) {
      const loadingCoverTypes = _get(routeSegments, `[0].loading.type`, []);
      const mappedLoadingCoverTypes = loadingCoverTypes.includes(FULL_COVER)
        ? ['Полная']
        : loadingCoverTypes.map((cover) => translateCoverTypes(cover));
      return {
        duration: getDurationTranslation(getDurationInMinutes('[0].loading')),
        durationInMinutes: getDurationInMinutes('[0].loading'),
        coverTypes: mappedLoadingCoverTypes,
        coverTypesByWorkTypes: {
          loading: mappedLoadingCoverTypes,
        },
      };
    }
    /**
     * последний сегмент маршрута
     */
    if (isLast) {
      const unloadingCoverTypes = _get(routeSegments, `[${index - 1}].unloading.type`, []);
      const mappedUnloadingCoverTypes = unloadingCoverTypes.includes(FULL_COVER)
        ? ['Полная']
        : unloadingCoverTypes.map((cover) => translateCoverTypes(cover));
      return {
        duration: getDurationTranslation(getDurationInMinutes(`[${index - 1}].unloading`)),
        durationInMinutes: getDurationInMinutes(`[${index - 1}].unloading`),
        coverTypes: mappedUnloadingCoverTypes,
        coverTypesByWorkTypes: {
          unloading: mappedUnloadingCoverTypes,
        },
      };
    }

    /**
     * проверяем входные данные по растентовке на средних точках маршрута
     */
    const coverTypesArray = _get(routeSegments, `[${index - 1}].unloading.type`, []).concat(
      _get(routeSegments, `[${index}].loading.type`, []),
    );
    const unloadingCoverTypes = _get(routeSegments, `[${index - 1}].unloading.type`, []);
    const loadingCoverTypes = _get(routeSegments, `[${index}].loading.type`, []);
    const mappedUnloadingCoverTypes = unloadingCoverTypes.includes(FULL_COVER)
      ? ['Полная']
      : unloadingCoverTypes.map((cover) => translateCoverTypes(cover));
    const mappedLoadingCoverTypes = loadingCoverTypes.includes(FULL_COVER)
      ? ['Полная']
      : loadingCoverTypes.map((cover) => translateCoverTypes(cover));

    /**
     * иначе выводим требуемые виды растентовки и сумму duration
     */
    return {
      duration: getDurationTranslation(
        getDurationInMinutes(`[${index - 1}].unloading`) + getDurationInMinutes(`[${index}].loading`),
      ),
      durationInMinutes: getDurationInMinutes(`[${index - 1}].unloading`) + getDurationInMinutes(`[${index}].loading`),
      coverTypes: coverTypesArray.includes('full')
        ? ['Полная']
        : _uniq(coverTypesArray).map(translateCoverTypes).filter(Boolean),
      coverTypesByWorkTypes: {
        unloading: mappedUnloadingCoverTypes,
        loading: mappedLoadingCoverTypes,
      },
    };
  }
});

/**
 * Формирование строки ПРР
 * @param totalMinutes
 * @param hoursPostfix
 * @param minutesPostfix
 * @returns {string}
 * @private
 */
export function getDurationTranslation(
  totalMinutes: number = 0,
  hoursPostfix: string = 'ч',
  minutesPostfix: string = 'мин',
): string {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  let result: string[] = [];

  if (hours) {
    result.push(`${hours} ${hoursPostfix}`);
  }

  if (minutes) {
    result.push(`${minutes} ${minutesPostfix}`);
  }

  return result.join(' ');
}

/**
 * получение данных об отправителе
 * @param point
 * @returns []
 */
function getContactsInfo(point: TSenderInfoParams): TSenderInfo {
  let contactsInfo = _get(point, 'cargo_receiver_sender_info');
  let passportIssuedAt = _get(contactsInfo, 'passport_issued_at', null);
  if (passportIssuedAt) {
    passportIssuedAt = moment(passportIssuedAt).format(DATE_FORMAT);
  }

  return [
    {
      contactPerson: _get(contactsInfo, 'contact_name', null),
      contactPhone: _get(contactsInfo, 'phone', null),
      contactExtensionNumber: _get(contactsInfo, 'extension_number', null),
      passportNumber: _get(contactsInfo, 'passport_number', null),
      passportIssuedAt,
      passportWhoIssued: _get(contactsInfo, 'passport_who_issued', null),
      contactCompanyTitle: _get(contactsInfo, 'company_name', null),
      contactOpfName: _get(contactsInfo, 'contact_opf_name', null),
      contactCompanyInn: _get(contactsInfo, 'contact_inn', null),
      contactCompanyKpp: _get(contactsInfo, 'contact_kpp', null),
    },
  ];
}

/**
 * получение всех периодов дат и времени
 * @param point
 * @returns {*}
 * @private
 */
export function getFormattedDate(point: {}): string[] {
  const carSupplyAt = _get(point, 'car_supply_at');

  if (carSupplyAt) {
    return [getDateWithTime(carSupplyAt)];
  }

  const carSupplyRange = _get(point, 'car_supply_range');

  return getDateWithTimeRangeByArray(carSupplyRange);
}

/**
 * Получение списка даты и времени (список возможных дата подачи)
 * @param supplyRanges
 * @param supplyAt
 * @return {*}
 * @private
 */
export function getSupplyRanges(
  supplyRanges: [{ from: string; until: string }],
  supplyAt?: string,
): {
  isInterval: boolean;
  values: TSupply[];
} {
  return supplyRanges.length > 0
    ? {
        isInterval: true,
        values: createSupplyMap(supplyRanges),
      }
    : {
        isInterval: false,
        values: [
          {
            date: supplyAt ? moment(supplyAt).utc() : undefined,
            times: [supplyAt ? moment.parseZone(supplyAt).format(TIME_FORMAT) : ''],
            selected: true,
          },
        ],
      };
}

/**
 * Получение строки в виде дата - время
 * @param date
 * @return {string}
 */
export function getDateWithTime(date: string): string {
  if (!date) return '';
  const formattedDateAt = moment.parseZone(date).format(SHORT_DATE_FORMAT); // getDateShortFormat(date);
  const formattedTimeAt = moment.parseZone(date).format(TIME_FORMAT);

  return `${formattedDateAt} ${formattedTimeAt}`;
}

/**
 * Получение массива дат в виде строки с временем от - до
 * @param dateRange
 * @return {*}
 */
export function getDateWithTimeRangeByArray(dateRange: { from: string; until: string }[]): string[] {
  return dateRange.map(({ from, until }) => {
    if (moment(from).isSame(moment(until))) {
      return getDateWithTime(from);
    }
    return getDateWithTimeRange(from, until);
  });
}

/**
 * Получение даты в виде строки с временем от - до
 * @param from {String}
 * @param until {String}
 * @return {String}
 */
export function getDateWithTimeRange(from?: string, until?: string): string {
  const formattedDateRangeFrom = moment.parseZone(from).format(SHORT_DATE_FORMAT);
  const formattedTimeFrom = moment.parseZone(from).format(TIME_FORMAT);
  const formattedTimeUntil = moment.parseZone(until).format(TIME_FORMAT);
  return `${formattedDateRangeFrom} ${formattedTimeFrom} – ${formattedTimeUntil}`;
}

/**
 * Получение даты в виде строки от - до
 * @param from {String}
 * @param until {String}
 * @return {String}
 */
export function getDateWithDateRange(from, until) {
  const formattedDateRangeFrom = moment.parseZone(from).format(SHORT_DATE_FORMAT);
  const formattedDateRangeTill = moment.parseZone(until).format(SHORT_DATE_FORMAT);
  return `${formattedDateRangeFrom} – ${formattedDateRangeTill}`;
}

/**
 * Получение даты в формате dd.mm.YY
 * @param date
 * @return {string}
 */
export function getDateShortFormat(date: string): string {
  return moment.parseZone(date).format(SHORT_DATE_FORMAT);
}

/**
 * Получение полной длины маршрута
 * @returns {*}
 * @param routePoints
 */
export function getDistance(routePoints: any[] = []): string | number {
  return routePoints.reduce((total, current) => {
    const segmentDistance = current?.distance_to_next ?? 0;
    return total + segmentDistance;
  }, 0);
}

/**
 * Получение полной длины маршрута по данным из сегментов
 * @returns {*}
 * @param routeSegments
 */
export function getDistanceBySegments(routeSegments: any[] = []): string | number {
  return routeSegments.reduce((total, current) => {
    const segmentDistance = current?.distance ?? 0;
    return total + segmentDistance;
  }, 0);
}

/**
 * Получение даты в отформатированном виде
 * @param item
 * @return {string}
 */

export function getDateFormatted(item: {}): string {
  const isInterval = _get(item, 'is_interval');
  const from = _get(item, 'route_points[0].car_supply_range[0].from', false);
  const until = _get(item, 'route_points[0].car_supply_range[0].until', false);
  const supplyAt = _get(item, 'route_points[0].car_supply_at', false);
  if (isInterval && from && until) {
    return getDateWithTimeRange(from, until);
  }
  return getDateWithTime(supplyAt);
}

/**
 * Получение даты в отформатированном виде
 * для отображения в списке
 * @param item
 * @return {string}
 */

export function getSupplyDateForSuggestionList(item: {}): string {
  const carSupply: string = _get(item, 'car_supply_at', '');
  const carSupplyDate: moment.Moment = moment.parseZone(carSupply);

  const carSupplyRange: { from: string; until: string }[] = _get(item, 'route_points[0].car_supply_range', []);
  if (!_isEmpty(carSupplyRange)) {
    const rangesFilteredByDate: {
      from: string;
      until: string;
    }[] = carSupplyRange.filter((item) => carSupplyDate.isSame(moment(item.from), 'day'));

    const dateList: moment.Moment[] = rangesFilteredByDate.reduce((acc: moment.Moment[], item) => {
      acc.push(moment.parseZone(item.from));
      acc.push(moment.parseZone(item.until));
      return acc;
    }, []);
    const from: string = moment.min(dateList).format();
    const until: string = moment.max(dateList).format();
    if (from === until) return getDateWithTime(from);
    return getDateWithTimeRange(from, until);
  }

  const supplyAt: string = _get(item, 'route_points[0].car_supply_at', false);
  return getDateWithTime(supplyAt);
}

/**
 * Получение даты в отформатированном виде для персональных заявок
 * для отображения в списке
 * @param item
 * @param selectedCarSupply
 * @return {string}
 */

export function getSupplyDateForShippingList(item: any, selectedCarSupply = []): string {
  if (Array.isArray(selectedCarSupply)) {
    const selectedCarSupplyByKey = selectedCarSupply.reduce((acc, item) => {
      acc[item['route_point_id']] = item['car_supply_at'];
      return acc;
    }, {});
    const firstPointId = item?.route_points?.[0]?.id;
    const firstPointSelectedCarSupply = selectedCarSupplyByKey[firstPointId];
    if (firstPointSelectedCarSupply) return getDateWithTime(firstPointSelectedCarSupply);
  }

  const carSupplyRange = _get(item, 'route_points[0].car_supply_range', []);
  if (!_isEmpty(carSupplyRange)) {
    const dateList: moment.Moment[] = carSupplyRange.reduce((acc: moment.Moment[], item) => {
      acc.push(moment.parseZone(item.from));
      acc.push(moment.parseZone(item.until));
      return acc;
    }, []);
    const minDate: moment.Moment = moment.min(dateList);
    const maxDate: moment.Moment = moment.max(dateList);
    const from: string = moment.min(dateList).format();
    const until: string = moment.max(dateList).format();
    if (!minDate.isSame(maxDate, 'day')) {
      return getDateWithDateRange(from, until);
    }
    if (from === until) return getDateWithTime(from);
    return getDateWithTimeRange(from, until);
  }

  const supplyAt: string = _get(item, 'route_points[0].car_supply_at', false);
  return getDateWithTime(supplyAt);
}

/**
 * Группировака допустимых диапазонов дата/время
 * с учетом часового пояса на клиенте
 * @param ranges
 * @returns {Array}
 */
function createSupplyMap(ranges: { from: string; until: string; step?: number }[]): TSupply[] {
  const list: moment.Moment[] = [];
  const result: TSupply = {};
  const map: TSupply[] = [];

  ranges.forEach((range) => {
    list.push(..._getTimeListByRange(range.from, range.until));
  });

  list.forEach((element) => {
    const clone = element.clone().utcOffset(0, true);
    const date = clone.format(DATE_FORMAT);
    if (!result[date]) {
      result[date] = {
        date: clone.startOf('day'),
        dateStr: clone.format(SHORT_DATE_TIME_FORMAT),
        times: [],
        selected: false,
      };
    }
    // TODO: проверить на назначениии даты время для интевальных заявках
    result[date].times.push(element.format(TIME_FORMAT));
  });

  Object.keys(result).forEach((index) => {
    result[index].times = _uniq(result[index].times);
    map.push(result[index]);
  });

  return map;
}

/**
 * Получение временых интервалов
 * для заданныого диапазаона
 * @param from
 * @param until
 * @param step
 * @returns {Array}
 * @private
 */
function _getTimeListByRange(from: string, until: string, step: number = 30): moment.Moment[] {
  let start;
  const MINUTES_IN_HOUR = 60;
  const now = moment();
  if (now.isAfter(from)) {
    start = moment(now.utcOffset(0, true).format(DATE_TIME_EN_FORMAT)).unix();
  } else {
    start = moment(moment.parseZone(from).utcOffset(0, true).format(DATE_TIME_EN_FORMAT)).unix();
  }
  const end = moment(moment.parseZone(until).utcOffset(0, true).format(DATE_TIME_EN_FORMAT)).unix();
  const result: moment.Moment[] = [];
  //-------------
  let current = Math.ceil(start / (MINUTES_IN_HOUR * step)) * (MINUTES_IN_HOUR * step);
  while (current <= end) {
    result.push(moment.unix(current));
    current += step * MINUTES_IN_HOUR;
  }
  const endOfDayInMinutes = 24 * 60 - 1; // 23:59
  const untilMinutes = moment(moment.parseZone(until).utcOffset(0, true));
  const diff = untilMinutes.diff(moment(moment.parseZone(until).utcOffset(0, true)).startOf('day'), 'minutes');
  if (diff === endOfDayInMinutes) result.push(moment.unix(end));

  return result;
}

/**
 * получение типа кузова машины
 * @param loadingRequest
 * @returns {*}
 */
export function getCarBodyType(loadingRequest: {
  car: {
    kind: string;
  };
}): {
  value: string;
  label: string;
} {
  return _get(getMappedCarTypes(_get(loadingRequest, 'car.kind')), `${resourcesFormFields.BODY_TYPE}`);
}

/**
 * получение типа кузова прицепа
 * @param loadingRequest
 * @returns {*}
 */
export function getTrailerBodyType(loadingRequest: { trailer: { kind: string } }): string {
  return _get(getMappedTrailerTypes(_get(loadingRequest, 'trailer.kind')), `${resourcesFormFields.BODY_TYPE}`);
}

/**
 * Перевод категории клиента
 * @returns {string}
 * @param category
 */
export function getClientCategoryTranslation(
  category: 'category_1' | 'category_2' | 'category_3',
): 'ООО «ДЛ-Транс»' | 'ООО «Деловые Линии»' {
  switch (category) {
    case CATEGORY_2:
      return 'ООО «ДЛ-Транс»';
    case CATEGORY_1:
    default:
      return 'ООО «Деловые Линии»';
  }
}

/**
 * Получение значения, есть ли у перевозки статус
 * @param {*} item
 * @param {*} status
 */
export function isItemWithStatus(item: { status: { code_name: string } }, status: string): boolean {
  const statusCode = _get(item, 'status.code_name');
  return statusCode === status;
}

/**
 * Значение габаритов груза
 * @param item
 * @returns {string}
 * @private
 */
export function getCargoDimensions(item: {
  cargo_length: number | null;
  cargo_width: number | null;
  cargo_height: number | null;
}): string | undefined {
  const cargoLength = _get(item, 'cargo_length');
  const cargoWidth = _get(item, 'cargo_width');
  const cargoHeight = _get(item, 'cargo_height');

  if (!!cargoLength && !!cargoHeight && !!cargoWidth) return `${cargoLength} × ${cargoWidth} × ${cargoHeight} м`;
}

/**
 * Расчёт максимального значения ставки с учетом шага
 * @param item
 * @param max
 * @param isExpress
 * @returns {number}
 */
export const getMaxBid = (
  item: {
    initial_bid: number;
    expedited_payment_initial_bid: number;
    bid_step: number;
    client_category: string;
  },
  max: number,
  isExpress: boolean = false,
): number => {
  const start = isExpress ? _get(item, 'expedited_payment_initial_bid') : _get(item, 'initial_bid');
  const step = _get(item, 'bid_step');
  const clientCategory = _get(item, 'client_category');
  let availableMax;

  if (clientCategory === CATEGORY_1) {
    availableMax = start < max ? start : max;
  } else {
    availableMax = start;
  }

  if (step && max) {
    while (availableMax <= max - step) {
      availableMax = availableMax + step;
    }
  }

  return availableMax;
};

/**
 * Расчёт минимального значения ставки с учетом шага
 * @param item
 * @param min
 * @param isExpress
 * @returns {number}
 */
export const getMinBid = (
  item: {
    initial_bid: number;
    expedited_payment_initial_bid: number;
    bid_step: number;
  },
  min: number,
  isExpress: boolean = false,
): number => {
  const start = isExpress ? _get(item, 'expedited_payment_initial_bid') : _get(item, 'initial_bid');
  const step = _get(item, 'bid_step');
  let availableMin = start;

  if (step && min >= 0) {
    while (availableMin >= min + step) {
      availableMin = availableMin - step;
    }
  } else {
    availableMin = 0;
  }

  return availableMin;
};

/**
 * Определение маршрута по городу из item.route_points
 * @param item
 */
export function checkCityRoute(item) {
  if (!item?.route_points) return false;

  const firstPointLocation = item.route_points[0].location;

  return !item.route_points.find((point) => {
    return getLocationName(point?.location) !== getLocationName(firstPointLocation);
  });
}
