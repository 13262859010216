import * as formKeys from './formKeys';
import * as statusKeys from './statusKeys';

export const TYPE = 'type';
export const STATUS = 'status';
export const KIND = 'kind';
export const SEARCH = 'search';
export const SORT = 'sort';
export const ORDER = 'order';
export const ACTIVE_ITEM = 'active_item';
export const DETAIL_TAB = 'detail_tab';
export const PAGE = 'page';
export const PER_PAGE = 'per_page';
export const BRAND = 'brand';

export const FULL_NAME = 'full_name';
export const TRAILER_NUMBER = 'trailer_number';
export const CAR_NUMBER = 'car_number';

export const APPROVED_TO_K1_K2 = 'approved';
export const APPROVED_TO_K1 = 'approved_k1';
export const APPROVED_TO_K2 = 'approved_k2';

export const ALL_BODY_TYPES = '';
export const TRUCK_BODY_TYPE = 'truck';
export const LORRY_BODY_TYPE = 'lorry';

export const LAST_CHANGE = 'updated_at';
export const CATEGORY_1 = 'category_1';
export const CATEGORY_2 = 'category_2';
export const CATEGORY_DELLIN_MKT = 'dellin_mkt';

export const APPROVED_TO_K1_K2_OBJ = {
  label: 'Допущен',
  value: APPROVED_TO_K1_K2,
};

const ON_VERIFICATION_OBJ = {
  label: 'На проверке',
  value: statusKeys.REVIEW,
};

const ON_COMPLETING_OBJ = {
  label: 'На доработке',
  value: statusKeys.NEED_FIX_BY_USER,
};

const ACCESS_SUSPENDED_OBJ = {
  label: 'Приостановлен',
  value: statusKeys.ACCESS_SUSPENDED,
};

const BLOCKED_OBJ = {
  label: 'Заблокирован',
  value: statusKeys.REJECTED,
};

const IN_PROGRESS_OBJ = {
  label: 'На заполнении',
  value: statusKeys.NOT_APPROVED,
};

export const APPROVED_TO_K1_OBJ = {
  label: 'Допущен до заявок ООО «Деловые Линии»',
  value: APPROVED_TO_K1,
};

export const APPROVED_TO_K2_OBJ = {
  label: 'Допущен до заявок ООО «ДЛ-Транс»',
  value: APPROVED_TO_K2,
};

export const CAR_BODY_TYPE_FILTERS = [
  { label: 'Все', value: ALL_BODY_TYPES },
  { label: 'Грузовик', value: LORRY_BODY_TYPE },
  { label: 'Седельный тягач', value: TRUCK_BODY_TYPE },
];

export const RESOURCES_FILTERS = {
  [formKeys.DRIVERS]: [ON_VERIFICATION_OBJ, ON_COMPLETING_OBJ, ACCESS_SUSPENDED_OBJ, BLOCKED_OBJ, IN_PROGRESS_OBJ],
  [formKeys.CARS]: [ON_VERIFICATION_OBJ, ON_COMPLETING_OBJ, ACCESS_SUSPENDED_OBJ, BLOCKED_OBJ, IN_PROGRESS_OBJ],
  [formKeys.TRAILERS]: [ON_VERIFICATION_OBJ, ON_COMPLETING_OBJ, ACCESS_SUSPENDED_OBJ, BLOCKED_OBJ, IN_PROGRESS_OBJ],
};

export const INITIAL_QUERY = {
  [PAGE]: 1,
  [PER_PAGE]: 20,
};

export const INITIAL_SORT = {
  [formKeys.DRIVERS]: FULL_NAME,
  [formKeys.CARS]: CAR_NUMBER,
  [formKeys.TRAILERS]: TRAILER_NUMBER,
};

export const FIO_OBJ = {
  label: 'по фамилии',
  value: FULL_NAME,
};

export const BRAND_OBJ = {
  label: 'по марке',
  value: BRAND,
};

export const LAST_CHANGE_OBJ = {
  label: 'по последнему изменению',
  value: LAST_CHANGE,
};

export const RESOURCES_SORT_VALUES = {
  [formKeys.DRIVERS]: [FIO_OBJ, LAST_CHANGE_OBJ],
  [formKeys.CARS]: [BRAND_OBJ, LAST_CHANGE_OBJ],
  [formKeys.TRAILERS]: [BRAND_OBJ, LAST_CHANGE_OBJ],
};

export const DOC_ID_DELLIN = 41;
export const DOC_ID_DELLIN_MKT = 49;
export const DOC_ID_DLTRANS = 17;
export const DOC_ID_EMPLOYEE_CONTRACT = 51;
export const DOC_ID_CAR_OWNERSHIP = 19;
export const DOC_ID_TRAILER_OWNERSHIP = 21;
