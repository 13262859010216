import React, { useCallback, useState } from 'react';
import ModalConfirm from '../../../components/ModalCollections/ModalConfirm';
import Button from '../../../components/Button';

/**
 * Модалка с подтверждением утверждения перевозки без страховки
 * @return {{showSubmitModal: (function(): Promise<unknown>), modal: unknown}}
 */
export function useConfirmPolicyModal() {
  const [modal, setModal] = useState(null);
  const showSubmitModal = () => {
    return new Promise((resolve) => {
      const modal = (
        <ModalConfirm
          onClose={() => {
            resolve(null);
            setModal(null);
          }}
          title={'Вы не подключили «Мультиполис»'}
        >
          <p>
            Утвердив перевозку без подключения комплексного страхования груза «Мультиполис», вы не сможете получить
            компенсацию, если с грузом что-то случиться в пути.
          </p>
          <p>Подключите страховку чтобы получить уверенность в финансовой защищенности.</p>
          <div className={'flex mt-7'}>
            <Button
              theme={'secondary'}
              size={'medium'}
              type={'button'}
              onClick={() => {
                resolve(false);
                setModal(null);
              }}
            >
              Продолжить без страховки
            </Button>
            <Button
              theme={'primary'}
              size={'medium'}
              type={'button'}
              className={'ml-4'}
              onClick={() => {
                resolve(true);
                setModal(null);
              }}
            >
              Подключить страховку
            </Button>
          </div>
        </ModalConfirm>
      );
      setModal(modal);
    });
  };

  return {
    modal,
    showSubmitModal,
  };
}

/**
 * Модалка с подтверждением отказа от страховки
 * @return {{show: (function(callback: (function(): void)): void), Modal: unknown, close: (function(): void)}}
 */
export function useRejectPolicyModal() {
  const [Modal, setModal] = useState(null);
  const show = useCallback((callback) => {
    const ElModal = ({ isLoading }) => {
      return (
        <ModalConfirm
          onClose={() => {
            setModal(null);
          }}
          title={'Отказаться от страховки «Мультиполис»'}
        >
          <p>Отказавшись от «Мультиполиса» вы не сможете получить компенсацию при возникновении страхового случая.</p>
          <p>В детализации заявки будет создан документ “Уведомление о расторжении полиса”.</p>
          <div className={'flex mt-7'}>
            <Button
              theme={'secondary'}
              size={'medium'}
              type={'button'}
              isLoading={isLoading}
              onClick={() => {
                callback();
              }}
            >
              Отказаться
            </Button>
            <Button
              theme={'primary'}
              size={'medium'}
              type={'button'}
              className={'ml-4'}
              isLoading={isLoading}
              onClick={() => {
                setModal(null);
              }}
            >
              Не отказываться
            </Button>
          </div>
        </ModalConfirm>
      );
    };

    setModal(() => ElModal);
  }, []);

  return {
    Modal,
    show,
    close: () => setModal(null),
  };
}
