import React from 'react';

import { IconArrowBold, IconArrowCircleBold, IconArrowClockBold, IconArrowWithDotBold } from '../../Icons';
import Tooltip from '../../Tooltip';

interface IProps {
  isCircular?: boolean;
  isHourlyRent: boolean;
  isCityRoute?: boolean;
}
/**
 * Получение иконки о типе маршрута
 * @param isCircular
 * @param isHourlyRent
 * @returns {*}
 */
export function getRouteTypeIcon({ isCircular, isHourlyRent, isCityRoute }: IProps, className?: string) {
  if (isHourlyRent)
    return (
      <Tooltip content="Заявка с почасовым использованием ТС">
        <IconArrowClockBold width="20" height="20" color="base" className={className} />
      </Tooltip>
    );
  if (isCircular) {
    return (
      <Tooltip content="Круговой маршрут">
        <IconArrowCircleBold width="20" height="20" color="base" className={className} />
      </Tooltip>
    );
  }

  if (isCityRoute)
    return (
      <Tooltip content="Маршрут по городу">
        <IconArrowWithDotBold width="20" height="20" color="base" className={className} />
      </Tooltip>
    );

  return (
    <Tooltip content="Прямой маршрут">
      <IconArrowBold width="20" height="20" color="base" className={className} />
    </Tooltip>
  );
}
