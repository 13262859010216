import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import classnames from 'classnames';

import Checkbox from '../../Checkbox';
import Tooltip from '../../Tooltip';

function Multi({ label, isChecked }) {
  const span = useRef();
  const labelContainer = useRef();
  const [showTooltip, setShowTooltip] = useState(false);
  const spanWidth = span.current?.offsetWidth;
  const labelContWidth = labelContainer.current?.offsetWidth;
  useEffect(() => {
    setShowTooltip(spanWidth > labelContWidth);
  }, [spanWidth, labelContWidth]);

  return (
    <label className={classnames(styles.suggestion, styles.multiSuggestion)}>
      <div className={'flex align-center pr-2'}>
        <Checkbox checked={isChecked} readOnly />
      </div>
      <div ref={labelContainer} className={classNames(styles.title)}>
        <span ref={span}>
          {label}
          {showTooltip && (
            <Tooltip ghostTrigger={true} content={label}>
              <div
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  top: '0px',
                  left: '0px',
                }}
              ></div>
            </Tooltip>
          )}
        </span>
      </div>
    </label>
  );
}

Multi.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
};

export default Multi;
