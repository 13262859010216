import React from 'react';

import SVGIcon from './index';

function IconSettings(props) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3H8V4V6V7H6V6H3V4H6V3ZM9 6H17V4H9V6ZM14 8H12V9V11V12H14V11H17V9H14V8ZM3 9H11V11H3V9ZM7 14V13H5V14H3V16H5V17H7V16V14ZM17 16H8V14H17V16Z"
      />
    </SVGIcon>
  );
}

IconSettings.defaultProps = {
  color: 'base',
};

export default React.memo(IconSettings);
