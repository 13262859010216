import { coverTypes, COVER_TYPES_WEIGHTS } from '../../../store/resources/bodyTypeOptions';

function getSortedCoverTypes(coverTypes) {
  const coverTypesCopy = coverTypes.slice();
  coverTypesCopy.sort((a, b) => COVER_TYPES_WEIGHTS[a.value] - COVER_TYPES_WEIGHTS[b.value]);
  return coverTypesCopy;
}

/**
 * Получение списка растентовок для типа кузова
 */
export function getCoverTypes(bodyTypes) {
  let types = [];
  if (Array.isArray(bodyTypes)) {
    types = bodyTypes.reduce((acc: any, value) => {
      if (coverTypes[value]) {
        return getSortedCoverTypes([...coverTypes[value], ...acc]);
      }
      return getSortedCoverTypes(acc);
    }, []);
    types = Array.from(new Set(types));
  }
  return getSortedCoverTypes(types);
}
