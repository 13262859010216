import React, { createContext, useCallback, useEffect, useContext, useMemo } from 'react';
import { QUOTA_DISTRIBUTION, QUOTA_TYPE, ROUTE_SHIPPINGS_COUNT } from '../../containers/order-v2/blocks/RoutesBlock';
import { declinationOfNumbers, declinationOfOrders } from '../../helpers/declinationOfNumbers';
import { useKeys } from '../../hooks/useKeys';
import FieldLabel from '../../components/FieldsCollection/FieldLabel';
import { Field } from '../../components/FormikFieldsV3/Field';
import { FieldArray } from '../../components/FormikFieldsV3/FieldArray';
import { Formik } from '../../components/FormikFieldsV3/Formik';
import { useFormikContext } from '../../components/FormikFieldsV3/FormikContext';
import FormInput from '../../components/FormikFieldsV3/FormInput';
import FormPriceInput from '../../components/FormikFieldsV3/FormPriceInput';
import FormSwitcher from '../../components/FormikFieldsV3/FormSwitcher';
import { useFieldValue } from '../../components/FormikFieldsV3/hooks';
import { Col, Row } from '../../components/Grid';
import { IconDelete } from '../../components/Icons';
import NotificationBar from '../../components/NotificationBar';
import { toast } from '../../components/Toaster';
import { getAuctionFormInitialValues, QUOTES_AUCTION } from './auctionForm';
import { getValidationSchema } from './getValidationSchema';
import styles from './styles.module.scss';
import InlineList from '../../components/PageElements/InlineList/InlineList';
import { getTaxTypeLabel } from '../../hooks/usePriceTaxType';
import getFullCompanyName from '../../helpers/getFullCompanyName';
import { PRICE_WITH_VAT } from '../../containers/order-v2/blocks/AuctionBlock';
import { getPriceValue, getWithoutVat, getWithVat } from '../../helpers/getPrice';

export const QUOTES_TYPES_MAP = {
  VALUES: 'amount',
  PERCENTS: 'percent',
};
export const QUOTES_TYPES = [
  {
    key: QUOTES_TYPES_MAP.VALUES,
    label: 'Количественный',
  },
  {
    key: QUOTES_TYPES_MAP.PERCENTS,
    label: 'Процентный',
  },
];
const COMPARE_RES = {
  MORE: 1,
  LESS: -1,
  EQ: 0,
};
export const FORM_PARAMS_MAP = {
  [QUOTES_TYPES_MAP.VALUES]: {
    formDescription: 'Количество перевозок (шт), который распределяется между исполнителями',
    unit: 'шт',
    quantityPlaceholder: 'Кол-во',
    quantityError: {
      notEnoughtTitle: 'Распределены не все заявки',
      notEnoughtSub: 'Разделите общее количество заявок между выбранными победителями',
      excessTitle: 'Распределено заявок больше плана',
      excessSub: 'Разделите планируемое количество заявок между выбранными победителями',
    },
    ordersCounter: (number, total, isValid) => {
      const label = declinationOfOrders(number);
      // 37 заявок из 37
      return (
        <>
          <span className={isValid ? 'color-success' : 'color-danger'}>
            <b>{number}</b>
          </span>
          <span> {label}</span>
          <span> из</span>
          <span>
            {' '}
            <b>{total}</b>
          </span>
        </>
      );
    },
    ordersCounterAlt: (number, total, isValid) => {
      const label = declinationOfNumbers(number, ['заявки', 'заявок', 'заявок']);
      // 35 из 37 заявок
      return (
        <>
          <span className={isValid ? 'color-success' : 'color-danger'}>
            <b>{number}</b>
          </span>
          <span> из</span>
          <span>
            {' '}
            <b>{total}</b>
          </span>
          <span> {label}</span>
        </>
      );
    },
    checkDestribution: (number, totalOrders) => {
      if (number < totalOrders) return COMPARE_RES.LESS;
      if (number > totalOrders) return COMPARE_RES.MORE;
      return COMPARE_RES.EQ;
    },
    validateQuotasDistribution: (number, totalOrders) => number <= totalOrders, // form validation
    validateQuotasSum: (number, totalOrders) => number === totalOrders, // success color in notification
  },
  [QUOTES_TYPES_MAP.PERCENTS]: {
    formDescription: 'Объём перевозок, который распределяется по заданной вами квоте (в процентах) между исполнителями',
    unit: '%',
    quantityPlaceholder: 'Квота',
    quantityError: {
      notEnoughtTitle: 'Распределены не все заявки',
      notEnoughtSub: 'Разделите 100% объёма перевозок между выбранными победителями',
      excessTitle: 'Распределено заявок больше плана',
      excessSub: 'Разделите 100% объёма перевозок между выбранными победителями',
    },
    ordersCounter: (number, total, isValid) => {
      const label = declinationOfOrders(total);
      // 100% от 37 заявок
      return (
        <>
          <span className={isValid ? 'color-success' : 'color-danger'}>
            <b>{number}%</b>
          </span>
          <span> от</span>
          <span>
            {' '}
            <b>{total}</b>
          </span>
          <span> {label}</span>
        </>
      );
    },
    ordersCounterAlt: (number, total, isValid) => {
      const label = declinationOfOrders(total);
      // 100% от 37 заявок
      return (
        <>
          <span className={isValid ? 'color-success' : 'color-danger'}>
            <b>{number}%</b>
          </span>
          <span> от</span>
          <span>
            {' '}
            <b>{total}</b>
          </span>
          <span> {label}</span>
        </>
      );
    },
    checkDestribution: (number) => {
      if (number < 100) return COMPARE_RES.LESS;
      if (number > 100) return COMPARE_RES.MORE;
      return COMPARE_RES.EQ;
    },
    validateQuotasDistribution: (number) => number <= 100,
    validateQuotasSum: (number) => number === 100,
  },
};

export const QUOTA_FORM_FIELDS = {
  BID_ID: 'bid_id',
  COMPANY_ID: 'company_id',
  QUANTITY: 'quantity',
  PRICE: 'price',
  TAX: 'tax_type',
};
interface IQuotesContext {
  quotaType: string;
  form: any[];
  selectedExecutors: any[];
  totalOrders: number;
  isBidsSelectingForm: boolean;
}
const QuotesContext = createContext<IQuotesContext>({
  quotaType: '',
  form: [],
  selectedExecutors: [],
  totalOrders: 0,
  isBidsSelectingForm: false,
});

const QuotesFormTypeSwitcher = (params) => {
  const { className } = params;
  const { quotaType } = useContext(QuotesContext);
  return (
    <div className={className}>
      <FieldLabel title="Способ распределения заявок" />
      <FormSwitcher data={QUOTES_TYPES} id={QUOTA_TYPE} name={QUOTA_TYPE} />
      <span className="caption">{FORM_PARAMS_MAP[quotaType]?.formDescription}</span>
    </div>
  );
};

const TableRows = (params) => {
  const { helpers, fieldName, trashBinClickHandler, onPriceChanged: priceChangeHandler } = params;
  const { quotaType, form, selectedExecutors, isBidsSelectingForm } = useContext(QuotesContext);
  const { setFieldValue } = useFormikContext();

  const options = FORM_PARAMS_MAP[quotaType || QUOTES_TYPES_MAP.VALUES];

  const idField = isBidsSelectingForm ? QUOTA_FORM_FIELDS.BID_ID : QUOTA_FORM_FIELDS.COMPANY_ID;
  const getTaxType = useMemo(() => {
    return isBidsSelectingForm ? (executor) => executor.partnership.tax_type : (executor) => executor.taxType;
  }, [isBidsSelectingForm]);

  useEffect(() => {
    const length = selectedExecutors?.length || 0;
    const formItemsNumber = form?.length || 0;
    const getRowObj = (id, tax = '', quantity = '', price) => {
      return {
        [idField]: id,
        [QUOTA_FORM_FIELDS.TAX]: tax,
        [QUOTA_FORM_FIELDS.QUANTITY]: quantity,
        [QUOTA_FORM_FIELDS.PRICE]: price,
      };
    };

    if (length > formItemsNumber) {
      const executor = selectedExecutors.find((executor) => !form.find((item) => item[idField] === executor.id));
      const { price, value } = executor;
      // price - наследование из контракта при создании на основании
      // value - цена из ставок при создании заявки по контракту
      let bidPrice;
      if (value) {
        bidPrice = value;
      }
      let formPrice = price || (bidPrice && +bidPrice) || null;
      form.push(getRowObj(executor.id, getTaxType(executor), undefined, formPrice));
    } else if (length < form.length) {
      form.forEach((item, index) => {
        const isSelected = !!selectedExecutors?.find((executor) => item[idField] === executor.id);
        if (!isSelected) form.splice(index, 1);
      });
    }
  }, [selectedExecutors, form, idField, getTaxType]);

  const priceWithVatChangeHandler = useCallback(
    (id, value, fieldsPrefix) => {
      setFieldValue(`${fieldsPrefix}.${QUOTA_FORM_FIELDS.PRICE}`, getPriceValue(getWithoutVat(value)));
      priceChangeHandler(id, getPriceValue(getWithoutVat(value)));
    },
    [priceChangeHandler, setFieldValue],
  );

  const priceWithoutVatChangeHandler = useCallback(
    (id, value, fieldsPrefix) => {
      setFieldValue(`${fieldsPrefix}.${PRICE_WITH_VAT}`, getPriceValue(getWithVat(value)));
      priceChangeHandler(id, value);
    },
    [priceChangeHandler, setFieldValue],
  );

  return (
    <>
      {form?.map((formRow, index) => {
        const executor = selectedExecutors?.find((row) => row.id === formRow[idField]);
        if (!executor) return null;

        const fieldsPrefix = `${fieldName}[${index}]`;

        const {
          // common
          id,
          // order form companies mapping:
          titleWithOpf,
          inn,
          taxType,
          // bids list; api object:
          author,
        } = executor;
        const { title, opf, tax_type, inn: _inn } = author || {};
        const _titleWithOpf = getFullCompanyName(title, opf?.name);

        return (
          <Row span={10} key={id} className={{ 'mt-4': !!index }}>
            <Col part={9}>
              <div>{titleWithOpf || _titleWithOpf}</div>
              <div className={styles.secondaryText}>
                <InlineList>
                  <InlineList.Item label={'ИНН'} value={inn || _inn} bold={false} />
                  <InlineList.Item label={''} value={getTaxTypeLabel(taxType || tax_type)} bold={false} />
                </InlineList>
              </div>
            </Col>
            <Col part={5}>
              <div className="p-relative">
                <Field
                  name={`${fieldsPrefix}.${PRICE_WITH_VAT}`}
                  component={FormPriceInput}
                  additional="₽"
                  placeholder="Ставка с НДС"
                  labelClassName={styles.formInput}
                  disabled={isBidsSelectingForm}
                  onChangeCallback={(val) => priceWithVatChangeHandler(id, val, fieldsPrefix)}
                />
              </div>
            </Col>
            <Col part={5}>
              <div className="p-relative">
                <Field
                  name={`${fieldsPrefix}.${QUOTA_FORM_FIELDS.PRICE}`}
                  component={FormPriceInput}
                  additional="₽"
                  placeholder="Ставка без НДС"
                  labelClassName={styles.formInput}
                  disabled={isBidsSelectingForm}
                  onChangeCallback={(val) => priceWithoutVatChangeHandler(id, val, fieldsPrefix)}
                />
              </div>
            </Col>
            <Col part={4}>
              <div className="p-relative">
                <Field
                  name={`${fieldsPrefix}.${QUOTA_FORM_FIELDS.QUANTITY}`}
                  component={FormInput}
                  type="number"
                  placeholder={options.quantityPlaceholder}
                  min={0}
                  additional={options.unit}
                  labelClassName={styles.formInput}
                />
              </div>
            </Col>
            <Col part={1}>
              <div className="flex h100 items-center content-end cursor-pointer">
                <IconDelete
                  color="base"
                  width={20}
                  height={20}
                  onClick={() => {
                    helpers.remove(index);
                    trashBinClickHandler && trashBinClickHandler(executor);
                  }}
                />
              </div>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

const ExecutorsTable = (params) => {
  const { fieldName = QUOTA_DISTRIBUTION, trashBinClickHandler, onPriceChanged } = params;

  return (
    <FieldArray name={QUOTA_DISTRIBUTION}>
      {(helpers) => {
        return (
          <TableRows
            helpers={helpers}
            fieldName={fieldName}
            trashBinClickHandler={trashBinClickHandler}
            onPriceChanged={onPriceChanged}
          />
        );
      }}
    </FieldArray>
  );
};

const CountNotification = (params) => {
  const { className, isOrderFormNotification = false } = params;
  const { quotaType, form, selectedExecutors, totalOrders } = useContext(QuotesContext);
  const options = FORM_PARAMS_MAP[quotaType || QUOTES_TYPES_MAP.VALUES];

  const getQuotasSum = useCallback((form) => {
    let sum = 0;
    for (let item of form) {
      const value = +item[QUOTA_FORM_FIELDS.QUANTITY];
      sum += isNaN(value) ? 0 : value;
    }
    return sum;
  }, []);
  const isQuotasSumCorrect = useCallback(
    (form) => {
      let sum = getQuotasSum(form);
      return FORM_PARAMS_MAP[quotaType]?.validateQuotasSum(sum, totalOrders);
    },
    [quotaType, totalOrders, getQuotasSum],
  );
  const checkDestribution = useCallback(() => {
    let sum = getQuotasSum(form);
    return FORM_PARAMS_MAP[quotaType]?.checkDestribution(sum, totalOrders);
  }, [form, getQuotasSum, quotaType, totalOrders]);

  if (!selectedExecutors?.length) return null;

  return (
    <NotificationBar
      theme="white-bordered"
      text={
        <>
          {isOrderFormNotification ? (
            <>
              <span>
                {declinationOfNumbers(selectedExecutors.length, ['Распределена', 'Распределены', 'Распределено'])}
              </span>
              <span> {options?.ordersCounter(getQuotasSum(form), totalOrders, isQuotasSumCorrect(form))}.</span>
            </>
          ) : (
            <>
              <span>{declinationOfNumbers(selectedExecutors.length, ['Выбран', 'Выбрано', 'Выбрано'])}</span>
              <span>
                {' '}
                <b>{selectedExecutors.length}</b>
              </span>
              <span>
                {' '}
                {declinationOfNumbers(selectedExecutors.length, ['исполнитель', 'исполнителя', 'исполнителей'])}
              </span>
              <span> и {options?.ordersCounterAlt(getQuotasSum(form), totalOrders, isQuotasSumCorrect(form))}.</span>
            </>
          )}
          {checkDestribution() === COMPARE_RES.MORE && (
            <span className="color-danger"> Нельзя распределить заявок больше планового объёма</span>
          )}
          {!!getQuotasSum(form) && checkDestribution() === COMPARE_RES.LESS && (
            <span> Можно добавить правила торга для нераспределённых заявок</span>
          )}
        </>
      }
      className={className}
    />
  );
};

export const QUOTES_FORM_KEY_ITEMS_MAP = {
  TYPE: 'quotaType',
  VALID: 'isValid',
  LOWER: 'isLower',
};
export const QUOTES_FORM_KEY_ITEMS = [
  QUOTES_FORM_KEY_ITEMS_MAP.TYPE,
  QUOTES_FORM_KEY_ITEMS_MAP.VALID,
  QUOTES_FORM_KEY_ITEMS_MAP.LOWER,
];

const QuotesFormPartsWrapper = (params) => {
  const { totalOrders, selectedItems, isBidsSelectingForm = false } = params;
  const [quotaType] = useFieldValue(QUOTA_TYPE);
  const [form] = useFieldValue(QUOTA_DISTRIBUTION);
  const { values, errors, isSubmitting, isValid, submitForm } = useFormikContext();
  const { methods } = useKeys(QUOTES_FORM_KEY_ITEMS);

  const checkQuantity = useCallback(() => {
    const options = FORM_PARAMS_MAP[quotaType];
    const validator = (sum, targetValue) => {
      if (sum < targetValue) return methods.getKey(quotaType, true, true);
      if (sum === targetValue) return methods.getKey(quotaType, true, false);
      return methods.getKey(quotaType, false, false);
    };

    const sum = form.reduce((acc, item) => {
      const value = +item[QUOTA_FORM_FIELDS.QUANTITY];
      return acc + (isNaN(value) ? 0 : value);
    }, 0);

    const resultKey = validator(sum, quotaType === QUOTES_TYPES_MAP.VALUES ? totalOrders : 100);
    const isQuantityValid = methods.getKeyItemValue(resultKey, 'isValid');
    const isLower = methods.getKeyItemValue(resultKey, 'isLower');

    if (!isQuantityValid) {
      toast.error(options.quantityError.excessTitle, options.quantityError.excessSub);
      return { isValid: isQuantityValid };
    }
    return { isValid: isQuantityValid, isLower: isLower };
  }, [quotaType, form, totalOrders, methods]);

  const context = useMemo(() => {
    return {
      form,
      quotaType,
      selectedExecutors: selectedItems,
      totalOrders: totalOrders ? +totalOrders : 0,
      isBidsSelectingForm,
    };
  }, [form, quotaType, totalOrders, selectedItems, isBidsSelectingForm]);

  return (
    <QuotesContext.Provider value={context}>
      {typeof params.children === 'function'
        ? params.children({ values, errors, isSubmitting, isValid, checkQuantity, submitForm })
        : params.children}
    </QuotesContext.Provider>
  );
};
// form elements for order-v2 and ContractBidsList:
export const QuotesFormElements = {
  Wrapper: QuotesFormPartsWrapper,
  TypeSwitcher: QuotesFormTypeSwitcher,
  Notification: CountNotification,
  Table: ExecutorsTable,
};

// form wrapper for ContractBidsList:
function QuotesFormWrapper(params) {
  const { entity, selectedItems, className, totalOrders, submitHandler, children } = params;

  const distributionCreated = !!entity?._original[QUOTA_DISTRIBUTION].length;
  const distributionFromSelection = !!selectedItems?.length
    ? selectedItems.map((item) => {
        const { value } = item;
        return {
          [QUOTA_FORM_FIELDS.BID_ID]: item.id,
          [QUOTA_FORM_FIELDS.TAX]: item.partnership.tax_type,
          [QUOTA_FORM_FIELDS.QUANTITY]: undefined,
          [QUOTA_FORM_FIELDS.PRICE]: value,
          [PRICE_WITH_VAT]: getPriceValue(getWithVat(value)),
        };
      })
    : [];

  const initialValues = {
    // additional field for validation:
    [ROUTE_SHIPPINGS_COUNT]: totalOrders,

    [QUOTA_TYPE]: entity?._original[QUOTA_TYPE] || QUOTES_TYPES_MAP.VALUES,
    [QUOTA_DISTRIBUTION]: distributionCreated
      ? entity._original[QUOTA_DISTRIBUTION].map((distribution) => {
          return {
            ...distribution,
            [PRICE_WITH_VAT]: getPriceValue(getWithVat(distribution[QUOTA_FORM_FIELDS.PRICE])),
          };
        })
      : distributionFromSelection,

    [QUOTES_AUCTION]: getAuctionFormInitialValues(),
  };

  return (
    <div className={className}>
      <Formik initialValues={initialValues} validationSchema={getValidationSchema()} onSubmit={submitHandler}>
        <QuotesFormElements.Wrapper selectedItems={selectedItems} totalOrders={totalOrders} isBidsSelectingForm={true}>
          {children}
        </QuotesFormElements.Wrapper>
      </Formik>
    </div>
  );
}

export default QuotesFormWrapper;
