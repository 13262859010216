import { useEffect } from 'react';

import { useOrderContext } from '../index';
import { ALL_PEOPLE } from '../../../store/order/auction/formKeys';
import { AUCTION_STAGES, VISIBILITY } from '../blocks/AuctionBlock';
import { useFieldValue } from '../../../components/FormikFieldsV3/hooks';
import { useFormikContextSelector } from '../../../components/FormikFieldsV3/FormikContext';

export function useAbilityPartners() {
  const { partnerList } = useOrderContext();
  const [auctionStages] = useFieldValue(AUCTION_STAGES);
  const auctionStagesLength = auctionStages?.length ?? 0;
  const setFieldValue = useFormikContextSelector((c) => c.setFieldValue);

  const hasNoPartners = !partnerList?.length;

  /* Эффект на установку участников торгов = "Все исполнители" при отсутствии партнеров */
  useEffect(() => {
    if (hasNoPartners && auctionStagesLength) {
      let i = 0;
      while (i < auctionStagesLength) {
        setFieldValue(`${AUCTION_STAGES}[${i}].${VISIBILITY}`, ALL_PEOPLE);
        i++;
      }
    }
  }, [auctionStagesLength, hasNoPartners, setFieldValue]);
}
