import { getIn } from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import { scrollTo } from '../../helpers/browserTools';
import { getPathsToItems, getFirstErrorElement } from '../../helpers/onSubmitFail';

import { useFormikContextSelector } from './FormikContext';

function useFieldValue<Values>(name: string) {
  const state = useFormikContextSelector<Values>((ctx) => getIn(ctx.values, name));
  const set = useFormikContextSelector<Values, any>((ctx) => ctx.setFieldValue);
  const setState = useCallback(
    (value: any, shouldValidate?: boolean) => {
      set(name, value, shouldValidate);
    },
    [name, set],
  );

  return [state, setState];
}

function useFieldTouched<Values>(name: string): [boolean, (isTouched?: boolean, shouldValidate?: boolean) => void] {
  const state = useFormikContextSelector<Values, boolean>((ctx) => Boolean(getIn(ctx.touched, name)));
  const set = useFormikContextSelector<Values, any>((ctx) => ctx.setFieldTouched);
  const setState = useCallback(
    (isTouched?: boolean, shouldValidate?: boolean) => {
      set(name, isTouched, shouldValidate);
    },
    [name, set],
  );

  return [state, setState];
}

function useHasFieldError<Values>(name: string): [boolean] {
  const state = useFormikContextSelector<Values, boolean>((ctx) => Boolean(getIn(ctx.errors, name)));
  return [state];
}

function useFieldError<Values>(name: string): [string | undefined] {
  const state = useFormikContextSelector<Values, string | undefined>((ctx) => getIn(ctx.errors, name));
  return [state];
}

function useScrollToError() {
  const submitCount = useFormikContextSelector((c) => c.submitCount);
  const refSubmit = useRef<number>(submitCount);
  const errors = useFormikContextSelector((c) => c.errors);

  useEffect(() => {
    // Таймаут, чтобы успели развернуться блоки точек маршрута
    setTimeout(() => {
      try {
        const element = getFirstErrorElement(getPathsToItems(errors));
        if (submitCount !== refSubmit.current && element) {
          refSubmit.current = submitCount;

          const container = document.querySelector('#contentScroll');
          const position = container!.scrollTop + element.getBoundingClientRect().top - 120;

          scrollTo(position, container);
          setTimeout(() => element.focus(), 200);
        }
      } catch {}
    }, 0);
  }, [submitCount, errors]);
}

function useFieldErrorShown(name: string) {
  const [error] = useFieldError(name);
  const [touched] = useFieldTouched(name);

  return !!error && touched;
}

export { useFieldValue, useFieldTouched, useHasFieldError, useFieldError, useScrollToError, useFieldErrorShown };
