const ediAgreement = require('../../assets/files/edi-agreement.docx');

const transportList = require('../../assets/files/examplesForPartnership/Реестр ТС.docx');
const personalTerms = require('../../assets/files/examplesForPartnership/Согласие на обработку персональных данных.docx');
const personalNumberIndividualDl = require('../../assets/files/examplesForPartnership/Справка о ССЧ от ИП ДЛ.docx');
const personalNumberIndividualDlT = require('../../assets/files/examplesForPartnership/Справка о ССЧ от ИП ДЛт.docx');

export const ORDER = 'order';
export const SHIPPING = 'shipping';
export const SHIPPING_ORDER = 'shipping_order';
export const GRAPHICS = 'graphics';
export const OUTCOMING_CLAIMS = 'outcoming_claims';
export const INCOMING_CLAIMS = 'incoming_claims';

export const PARTNERSHIP = 'partnership';

export const PARTNERSHIP_DOCS_EXAMPLES = {
  57: {
    // Реестр ТС или СТС до 10 ТС
    label: 'Шаблон для заполнения реестра',
    getLink: () => transportList,
  },
  61: {
    // Согласие на обработку персональных данных
    label: 'Шаблон для подписи',
    getLink: () => personalTerms,
  },
  63: {
    // Соглашение о ПЭП
    label: 'Шаблон для подписи',
    getLink: () => ediAgreement,
  },
  56: {
    // Справка о средней численности персонала (DL)
    label: 'Шаблон для заполнения',
    getLink: (companyId) => {
      if (companyId === process.env.REACT_APP_DLT_CARGO_OWNER_ID) {
        return personalNumberIndividualDlT;
      }
      if (companyId === process.env.REACT_APP_DELLIN_ID) {
        return personalNumberIndividualDl;
      }
    },
  },
};
