import React from 'react';
import SVGIcon from './index';

const IconLocation = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2L2 4V18L7 16L13 18L18 16V2L13 4L7 2ZM12.5 16.3063L7.5 14.6396V3.69371L12.5 5.36038V16.3063Z"
        fill="#98A3B3"
      />
    </SVGIcon>
  );
};

IconLocation.defaultProps = {
  color: 'base',
};

export default IconLocation;
