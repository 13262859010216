import moment from 'moment';
import { REQUEST_DATE_FORMAT } from '../../constants';

export const FROM_LOCATION_ID = 'from_location_id';
export const DIRECTION_LOCATION_ID = 'direction_location_id';
export const DIRECTION_RADIUS = 'direction_radius';
export const FINAL_LOCATION_ID = 'finish_location_id';
export const FINAL_LOCATION_RADIUS = 'finish_radius';
export const FROM_RADIUS = 'from_radius';
export const FROM_PARTNERS = 'from_partners';
export const EXCLUDED_IDS = 'excluded_cargo_owner_ids';
export const CARGO_OWNING_IDS = 'cargo_owning_company_id';
export const DATE_PERIOD = 'date_period';
export const DATE_FROM = 'begin_from';
export const DATE_UNTIL = 'begin_until';
export const DATE_RANGE_START = 'range_start';
export const DATE_RANGE_START_FROM = 'range_start_from';
export const DATE_RANGE_START_UNTIL = 'range_start_until';
export const DATE_RANGE_END = 'range_end';
export const DATE_RANGE_END_FROM = 'range_end_from';
export const DATE_RANGE_END_UNTIL = 'range_end_until';
export const CIRCULAR_ROUTE = 'circular_route';
export const BODY_TYPE = 'body_type';
export const MAX_TONNAGE = 'max_tonnage';
export const HUMAN_FRIENDLY_ID = 'human_friendly_id';
export const STATUS = 'status';
export const SUB_STATUS = 'subStatus';
export const TYPE = 'type';
export const SEARCH = 'search';
export const PAGE = 'page';
export const SORT = 'sort';
export const ORDER = 'order';
export const PER_PAGE = 'per_page';
export const SET_BID = 'set_bid';
export const NEXT_FOR_SHIPPING = 'next_for_shippings';
export const SCANCOPY_STATUS = 'scancopy_status';
export const PAYMENT_STATUS = 'payment_status';
export const ACTIVE_ITEM = 'active_item';
export const PENDING = 'pending';
export const CLOSING_DOCUMENT_ERRORS = 'closing_document_errors';
export const AGREE_ON_CHANGE_REQUEST = 'agree_on_change_request';
export const ASSIGN_RESOURCES = 'assign_resources';
export const NEED_OF_ATTORNEY = 'need_of_attorney';
export const NEED_TO_SIGN_SCANCOPY = 'need_to_sign_scancopy';
export const DIRECT_CARGO_OWNER = 'direct_cargo_owner';
export const NEW_CARGO_OWNER = 'new_cargo_owner';

// вспомогательные поля для отображения имени города в автокомплите
// не нужны для запроса, но нужны после перезагрузки страницы (по F5)
export const FROM_LOCATION_LABEL = 'from_location_name';
export const DIRECTION_LOCATION_LABEL = 'direction_location_name';

// типы заказов
export const DISTANCE_REQUEST_TYPE = 'request_type';
export const ALL_REQUESTS = 'all';
export const CITY_REQUESTS = 'city_requests';
export const LONG_DISTANCE_REQUESTS = 'long_distance_requests';

// значения статусов
export const AT_CLEARANCE = 'at_clearance';
export const RESOURCES_WAITING = 'resources_waiting';
export const APPROVAL_WAITING = 'approval_waiting';
export const TRIP_WAITING = 'trip_waiting';
export const IN_WAY = 'in_way';

export const DONE = 'done';
export const CANCELED = 'canceled';
export const COMPLETED = 'completed';

export const CANCELED_BY_NTK = 'canceled_by_transport_company'; // Отменён по вине перевочика
export const CANCELED_BY_CARGO_OWNER = 'canceled_by_cargo_owning_company'; // Отменён по вине грузовладельца
export const FAILED_BY_NTK = 'failed_by_transport_company'; // Срыв по вине перевозчика
export const FAILED_BY_CARGO_OWNER = 'failed_by_cargo_owning_company'; // Срыв по вине грузовладельца

export type TStatusCodeName =
  | typeof AT_CLEARANCE
  | typeof RESOURCES_WAITING
  | typeof APPROVAL_WAITING
  | typeof TRIP_WAITING
  | typeof IN_WAY
  | typeof DONE
  | typeof CANCELED
  | typeof COMPLETED;

// Статусы подтверждающего документа перевозки
export const CARGO_OWNER_SIGNING = 'cargo_owner_signing'; // документ находится на подписании у грузовладельца
export const NOT_ATTACHED = 'not_attached'; // требуется подписание документа со стороны транспортной компании
export const REJECTED = 'rejected'; // подписанный транспортной компанией документ отклонён грузовладельцем
export const ACCEPTED = 'accepted'; // подписанный транспортной компанией документ принят грузовладельцем
export const IN_PROGRESS = 'in_progress'; // подписанный транспортной компанией документ находится на проверке у грузовладельца
export const NEED_RESIGN = 'need_resign'; // требуется переподписание документа со стороны транспортной компании
export const ALL_SCANCOPY_STATUSES: string = 'all'; // статус для фильтра

export type TScancopyStatus =
  | typeof CARGO_OWNER_SIGNING
  | typeof NOT_ATTACHED
  | typeof REJECTED
  | typeof ACCEPTED
  | typeof IN_PROGRESS
  | typeof NEED_RESIGN;

/* Статус для графиков */
export const NEW = 'new';
export const WINNER_SELECTION = 'winner_selection';

export const ON_REVIEW = 'on_review';
export const EXCEEDED_DEADLINE = 'exceeded_deadline';
// значения статусов скан-копии (индексы)
// export const NOT_ATTACHED_NUM = 1001;
// export const REJECTED_NUM = 'rejected';
// export const ACCEPTED_NUM = 1001;
// export const IN_PROGRESS_NUM = 'in_progress';
// export const EXEEDED_DEADLINE_NUM = 'exceeded_deadline';

export const ALL_STATUSES = [
  { title: 'На оформлении', value: AT_CLEARANCE },
  { title: 'Предстоящие', value: TRIP_WAITING },
  { title: 'Выполняются', value: IN_WAY },
  {
    title: 'Завершённые',
    value: COMPLETED,
    extraQuery: {
      [DATE_FROM]: moment().subtract(30, 'd').format(REQUEST_DATE_FORMAT),
      [DATE_UNTIL]: moment().format(REQUEST_DATE_FORMAT),
    },
  },
];

export const TREE_STATUSES = {
  [AT_CLEARANCE]: [
    { value: RESOURCES_WAITING, title: 'Ожидание ресурсов' },
    { value: APPROVAL_WAITING, title: 'Ожидание утверждения' },
  ],
  [COMPLETED]: [
    { value: DONE, title: 'Завершённые' },
    { value: CANCELED, title: 'Отменённые' },
    { value: CANCELED_BY_CARGO_OWNER },
    { value: CANCELED_BY_NTK },
    { value: FAILED_BY_CARGO_OWNER },
    { value: FAILED_BY_NTK },
  ],
};

export const INITIAL_RESERVED_QUERY = {
  [STATUS]: AT_CLEARANCE,
  [PAGE]: 1,
  [PER_PAGE]: 20,
  [SCANCOPY_STATUS]: [ALL_SCANCOPY_STATUSES],
  [DISTANCE_REQUEST_TYPE]: ALL_REQUESTS,
};

// типы предложений
export const REQUESTS_TYPE = 'requests';
export const TARGETS_TYPE = 'targets';
export const GRAPHICS_TYPE = 'graphics';

// значения фильтра по типу заявок
export const ALL_REQUEST_TYPES = 'all_request_types';
export const ONLY_REQUESTS_TYPE = 'only_regular';
export const ONLY_GRAPHICS_TYPE = 'only_by_contract';

export const ALL_REQUEST_TYPES_OBJ = {
  value: ALL_REQUEST_TYPES,
  label: 'Все',
};
export const ONLY_REQUESTS_TYPE_OBJ = {
  value: ONLY_REQUESTS_TYPE,
  label: 'Разовые заявки',
};
export const ONLY_GRAPHICS_TYPE_OBJ = {
  value: ONLY_GRAPHICS_TYPE,
  label: 'Заявки по контрактам',
};

// TODO: удалить после редизайна
export const SUGGESTIONS_TYPES = [
  { title: 'Заявки', key: REQUESTS_TYPE },
  { title: 'Персональные', key: TARGETS_TYPE },
  { title: 'Графики', key: GRAPHICS_TYPE },
];

// TODO: удалить после редизайна
export const SUGGESTIONS_TYPES_KEYS = SUGGESTIONS_TYPES.map((type) => type.key);

export const INITIAL_REQUESTS_QUERY = {
  [PAGE]: 1,
  [PER_PAGE]: 20,
};

export const INITIAL_VOLUME_GRAPHICS_QUERY = {
  [PAGE]: 1,
  [PER_PAGE]: 20,
  [STATUS]: [NEW, WINNER_SELECTION],
};

// значение статуса персональной заявки
export const OFFERED = 'offered';

export const TAX_WITH = 'with_vat';

// статусы документов
export type TDocsTypeItem = {
  value: TScancopyStatus[] | [typeof ALL_SCANCOPY_STATUSES];
  label: string;
};

export const ALL_SCANCOPIES_OBJ: TDocsTypeItem = {
  value: [ALL_SCANCOPY_STATUSES],
  label: 'Все',
};

export const NEED_TO_SIGN_SCANCOPIES_OBJ: TDocsTypeItem = {
  value: [NOT_ATTACHED, REJECTED, NEED_RESIGN],
  label: 'Требуют подписи',
};

// Статусы оплаты
export const ALL_PAYMENTS_OBJ = { value: '0', label: 'Все' };
export const WAITING_PAYMENT_OBJ = { value: '1', label: 'Ожидание оплаты' };
export const PAID_OBJ = { value: '2', label: 'Оплата произведена' };

// Форма оплаты плательщика
export const CASH = 'cash';
export type TCash = typeof CASH;
export const CASHLESS = 'cashless';
export type TCashless = typeof CASHLESS;
export type TPayerPaymentType = TCash | TCashless;

export const payerPaymentFormMapping = {
  [CASH]: 'Наличная',
  [CASHLESS]: 'Безналичная',
};
