import React from 'react';

function renderTonnageVolume(tonnage, volume) {
  if (tonnage === 21 && volume === 33) {
    return <>20 футов</>;
  }
  if (tonnage === 26 && volume === 67) {
    return <>40 футов</>;
  }
  return (
    <>
      {tonnage} т / {volume} м<sup>3</sup>
    </>
  );
}

export default renderTonnageVolume;
