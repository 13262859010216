import React from 'react';
import SVGIcon from './index';

const IconDatePicker = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path fillRule="evenodd" d="M6 3H7V4H13V3H14V4H16V16H4V4H6V3ZM15 7H5V15H15V7Z" />
    </SVGIcon>
  );
};

IconDatePicker.defaultProps = {
  color: 'base',
};

export default IconDatePicker;
