import React from 'react';
import SVGIcon from './index';

function IconPriceMin(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path
        d="M15.75 10.303H23.25V2.80298L20.413 5.63995L15.6817 1.5L12 5.18164L8.56068 1.7423L6.43936 3.86362L12 9.42428L15.8184 5.60593L18.287 7.76598L15.75 10.303Z"
        fill={props.color}
      />
      <path
        d="M15 22.5C16.6569 22.5 18 21.1569 18 19.5C18 17.8431 16.6569 16.5 15 16.5C13.3431 16.5 12 17.8431 12 19.5C12 21.1569 13.3431 22.5 15 22.5Z"
        fill={props.color}
      />
      <path
        d="M10.5 19.5C10.5 20.3284 9.82843 21 9 21C8.17157 21 7.5 20.3284 7.5 19.5C7.5 18.6716 8.17157 18 9 18C9.82843 18 10.5 18.6716 10.5 19.5Z"
        fill={props.color}
      />
      <path
        d="M21 21C21.8284 21 22.5 20.3284 22.5 19.5C22.5 18.6716 21.8284 18 21 18C20.1716 18 19.5 18.6716 19.5 19.5C19.5 20.3284 20.1716 21 21 21Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 27V12H27V27H3ZM22.5 14.25H7.5C7.5 15.4926 6.49264 16.5 5.25 16.5V22.5C6.49264 22.5 7.5 23.5074 7.5 24.75H22.5C22.5 23.5074 23.5074 22.5 24.75 22.5V16.5C23.5074 16.5 22.5 15.4926 22.5 14.25Z"
        fill={props.color}
      />
    </SVGIcon>
  );
}

IconPriceMin.defaultProps = {
  color: 'base',
};

export default React.memo(IconPriceMin);
