import React from 'react';

import FieldLabel from '../../../../components/FieldsCollection/FieldLabel';
import FormSwitcher from '../../../../components/FormikFieldsV3/FormSwitcher';

import { FORM_TYPE, FULL, SIMPLE } from '../../blocks/OrderNFormTypeBlock';

export function getHelp() {
  return (
    <>
      <b>Подробная форма</b> – позволяет сразу указать все детали перевозки. Более привлекательна для потенциальных
      исполнителей и экономит время на этапе Утверждения
      <br /> <br />
      <b>Упрощённая форма</b> – быстрый способ оформления заказа. Содержит только самые важные атрибуты. Подробности
      можно добавить на этапе Утверждения
    </>
  );
}

function FormType() {
  return (
    <section className="flex column mb-7">
      <FieldLabel title="Вид формы создания заказа" htmlFor={FORM_TYPE} help={getHelp()} />
      <FormSwitcher
        id={FORM_TYPE}
        name={FORM_TYPE}
        data={[
          { label: 'Подробная', key: FULL },
          { label: 'Упрощённая', key: SIMPLE },
        ]}
      />
    </section>
  );
}

export default FormType;
