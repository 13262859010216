/* Получить из полного имени Фамилию с инициалами
 * Генадьев Иван Павлович ->  Генадьев И. П. */
export function getFullNameWithInitials(fullName: string) {
  let [f, i, o] = fullName.split(' ');
  if (!i && !o) {
    return `${f}`;
  }
  if (!o) {
    return `${f} ${_getChar(i)}.`;
  }
  return `${f} ${_getChar(i)}. ${_getChar(o)}.`;
}

function _getChar(word) {
  return word.charAt(0).toUpperCase();
}
