import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';

/**
 * Декоратор над input фильтров
 *
 * Исправляет визуальные задержки при изменении значения input
 * которые появляются при привязке значения input к значения props один к одному
 * props.onChange -> props.value -> input.value
 *
 * Вместо такой привязки храним в state значение input и вызываем cb с задержкой
 * handleChange -> state.value -> input.value -> props.onChange -> props.value -> input.value
 *
 * cb - callback
 */
function withDebounceOnChange(WrappedComponent, type = 'input') {
  return class WithDebounceOnChange extends Component {
    static propTypes = {
      // cb на изменение
      onChange: PropTypes.func,
      // значение после отработки cb
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    };

    constructor(props) {
      super(props);

      // для разных типов используется разные пустые значения
      this.emptyValue = '';
      this.handleChange = this.getHandleChange();
      this.debounceChange = _debounce(props.onChange, 300);

      this.state = {
        value: props.value || this.emptyValue,
        // локально храним предыдущее значение из props
        propsValue: props.value || this.emptyValue,
        emptyValue: this.emptyValue,
      };
    }

    /**
     * После отработки cb debounce синхронизируем state с props
     * Для случая, когда props изменились НЕ через props.onChange
     * @param nextProps
     * @param prevState
     * @returns {*}
     */
    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps && prevState && nextProps.value !== prevState.propsValue) {
        if (!!nextProps.value || !!prevState.propsValue) {
          return {
            value: nextProps.value || prevState.emptyValue,
            propsValue: nextProps.value || prevState.emptyValue,
          };
        }
      }

      return null;
    }

    /**
     * В случаее изменения props.onChange создачем новую функцию debounce
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
      if (prevProps.onChange !== this.props.onChange) {
        this.debounceChange = _debounce(this.props.onChange, 300);
      }
    }

    /**
     * Обработка изменений input
     * @param event
     */
    handleInputChange = (event) => {
      const { value } = event.target;
      this.setState({ value });
      this.debounceChange(value.trim());
    };

    /**
     * Обработка изменений datepicker
     * @param value
     */
    handleDateChange = (value) => {
      this.setState({ value });
      this.debounceChange(value);
    };

    /**
     * Получение обработчика событий в зависимости от типа оборачиваемого компонента
     * @returns {*}
     */
    getHandleChange() {
      switch (type) {
        case 'datepicker':
          this.emptyValue = null;
          return this.handleDateChange;
        case 'input':
        default:
          this.emptyValue = '';
          return this.handleInputChange;
      }
    }

    /**
     * Рендерим дочерний input с новым значением
     */
    render() {
      return <WrappedComponent {...this.props} onChange={this.handleChange} value={this.state.value} />;
    }
  };
}

export default withDebounceOnChange;
