import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useOrderContext } from '../../../containers/order-v2';
import { QUOTA_FORM_FIELDS, QuotesFormElements } from '../quotesForm';
import FieldLabel from '../../../components/FieldsCollection/FieldLabel';
import { Field } from '../../../components/FormikFieldsV3/Field';
import FormDropdown from '../../../components/FormikFieldsV3/FormDropdown';
import FormInput from '../../../components/FormikFieldsV3/FormInput';
import FormPriceInput from '../../../components/FormikFieldsV3/FormPriceInput';
import { useFieldValue } from '../../../components/FormikFieldsV3/hooks';
import NotificationBar from '../../../components/NotificationBar';
import { ACTIVE } from '../../../store/partners/partners/statusKeys';
import { EXECUTORS_ARRAY } from '../../../containers/order-v2/blocks/AuctionBlock';
import { TAX_TYPES } from '../../../containers/order-v2/blocks/AuctionBlock/constants';
import {
  SUGGESTION_HOURS,
  SUGGESTION_MINUTES,
  QUOTA_DISTRIBUTION,
  ROUTE_SHIPPINGS_COUNT,
  ADDITIONAL_AUCTION,
} from '../../../containers/order-v2/blocks/RoutesBlock';
import AuctionForm from '../auctionForm';
import classNames from 'classnames';
import { useGetPartnerList } from '../../../hooks/useGetPartners';
import { TAX_BOTH } from '../../../store/order/auction/formKeys';
import FormSelector from '../../../components/FormikFieldsV3/FormSelector';
import InlineList from '../../../components/PageElements/InlineList/InlineList';
import IconBlock from '../../../components/PageElements/IconBlock/IconBlock';
import { IconInfo } from '../../../components/Icons';
import { getWithoutVat, getWithVat } from '../../../helpers/getPrice';
import { useFormikContext } from '../../../components/FormikFieldsV3/FormikContext';

const taxTypeOptions = Object.entries(Object.fromEntries(TAX_TYPES)).map((entry: any) => ({
  value: entry[0],
  label: entry[1],
}));

export const EXECUTOR_IDS = 'executor_ids';
export const TAX_TYPE = 'tax_type';

function CompanyOption({ option, getLabel }) {
  return (
    <div className={'pt-2 pb-2'}>
      <div className={'color-darker'}>{getLabel(option)}</div>
      <InlineList>
        <InlineList.Item label={`ИНН ${option.inn}`} />
        <InlineList.Item label={TAX_TYPES.get(option.taxType)} />
      </InlineList>
    </div>
  );
}

const ExecutorKnownWithQuotesForOrder = () => {
  const { setFieldValue } = useFormikContext();
  const getPartnersList = useGetPartnerList(); // TODO избавиться от загрузки всего списка, перевести на другой контрол
  const [partnerList, setPartnerList] = useState<any[]>([]);

  useEffect(() => {
    getPartnersList().then(setPartnerList);
  }, [getPartnersList]);

  const companiesList = useMemo(() => {
    return partnerList.reduce((acc, currentValue) => {
      if (currentValue?.partnershipStatus === ACTIVE) {
        acc.push({
          id: currentValue?.companyId,
          value: currentValue?.companyId,
          label: currentValue?.titleWithOpf,
          ...currentValue,
        });
      }
      return acc;
    }, []);
  }, [partnerList]);
  const [executors] = useFieldValue(EXECUTORS_ARRAY);

  const showExecutor = executors?.length === 1;
  const executorField = showExecutor ? `${EXECUTORS_ARRAY}[0]` : 'emptyForMultiExecutors';

  const handleChangePriceWithTax = useCallback(
    (value) => {
      setFieldValue(`${executorField}.price`, getWithoutVat(value));
    },
    [executorField, setFieldValue],
  );

  const handleChangePriceWithoutTax = useCallback(
    (value) => {
      setFieldValue(`${executorField}.priceWithVat`, getWithVat(value));
    },
    [executorField, setFieldValue],
  );

  if (!showExecutor) return null;

  return (
    <div className="mt-5 nml-6 nmr-6 pt-5 pb-5 pl-6 pr-6 br-5 bg-color-lightest">
      <div className="mt-2 flex">
        <Field
          id={executorField}
          name={executorField}
          label="Компания-исполнитель"
          component={FormDropdown}
          options={companiesList}
          labelClassName="width370 mr-4"
          placeholder="Выберите исполнителя"
          disabled={true}
        />
      </div>

      <div className="mt-5">
        <IconBlock Icon={<IconInfo width={20} height={20} color={'primary'} />} className={'flex items-center'}>
          После ввода «Ставки с НДС» мы автоматически пересчитаем ее на «Без НДС» (20%) и также в обратную сторону. При
          конвертации копейки округляются до целого числа.
        </IconBlock>
      </div>

      <div className="mt-5 flex">
        <Field
          id={`${executorField}.priceWithVat`}
          name={`${executorField}.priceWithVat`}
          label={`Стоимость за перевозку с НДС`}
          component={FormPriceInput}
          additional="₽"
          placeholder="Ставка"
          className="bg-color-white"
          labelClassName="width240"
          disabled={true}
          onChangeCallback={handleChangePriceWithTax}
        />

        <Field
          id={`${executorField}.price`}
          name={`${executorField}.price`}
          label={`Стоимость за перевозку без НДС`}
          component={FormPriceInput}
          additional="₽"
          placeholder="Ставка"
          className="bg-color-white"
          labelClassName="width240 ml-5"
          disabled={true}
          onChangeCallback={handleChangePriceWithoutTax}
        />
      </div>
    </div>
  );
};

const ExecutorKnownWithQuotes = () => {
  const { isOrderBasedOnContract } = useOrderContext();
  const getPartnersList = useGetPartnerList(); // TODO избавиться от загрузки всего списка, перевести на другой контрол
  const [executors, setExecutors] = useFieldValue(EXECUTORS_ARRAY);
  const [totalOrders] = useFieldValue(ROUTE_SHIPPINGS_COUNT);
  const [distribution] = useFieldValue(QUOTA_DISTRIBUTION);
  const [taxType] = useFieldValue(TAX_TYPE);
  const [executorsIds, setExecutorsIds] = useFieldValue(EXECUTOR_IDS);
  const [partnerList, setPartnerList] = useState<any[]>([]);

  useEffect(() => {
    getPartnersList().then(setPartnerList);
  }, [getPartnersList]);

  const companiesList = useMemo(() => {
    return partnerList
      .filter((partner) => {
        if (taxType === TAX_BOTH) return true;
        if (taxType) return partner.taxType === taxType;
        return false;
      })
      .reduce((acc, currentValue) => {
        if (currentValue?.partnershipStatus === ACTIVE) {
          const res = executors?.find((executor) => executor.value === currentValue?.companyId) || {};
          acc.push({
            id: currentValue?.companyId,
            value: currentValue?.companyId,
            label: currentValue?.titleWithOpf,
            ...res,
            ...currentValue,
            price: null,
          });
        }
        return acc;
      }, []);
  }, [partnerList, taxType, executors]);

  const priceChangeHandler = useCallback(
    (id, value) => {
      const res = executors?.find((executor) => executor.value === id) || {};
      res.price = value;
    },
    [executors],
  );

  useEffect(() => {
    distribution.forEach((row) => {
      row[QUOTA_FORM_FIELDS.TAX] = taxType;
    });
  }, [distribution, taxType]);

  useEffect(() => setExecutorsIds(executors?.map((item) => item.value)), [executors, setExecutorsIds]);

  const selectedPartners = useMemo(() => {
    return Array.isArray(executorsIds) ? companiesList.filter((item) => executorsIds.includes(item.id)) : null;
  }, [executorsIds, companiesList]);

  const trashBinClicked = useCallback(
    (item) => setExecutors(executors.filter((executor) => executor.id !== item.id)),
    [executors, setExecutors],
  );

  const executorsChangeHandler = useCallback(
    (event) => {
      setExecutors(companiesList.filter((item) => event.target.value.includes(item.id)));
    },
    [companiesList, setExecutors],
  );

  const taxTypeChangeHandler = useCallback(() => {
    setExecutors(null);
  }, [setExecutors]);

  if (isOrderBasedOnContract) return <ExecutorKnownWithQuotesForOrder />;

  return (
    <>
      <div className="mt-5 nml-6 nmr-6 pt-5 pb-5 pl-6 pr-6 br-5 bg-color-lightest">
        <div className="subtitle">Распределение квот</div>

        <NotificationBar
          theme="white"
          text={`Выберите компании из списка участников, между которыми будут распределяться заявки согласно 
              указанной вами квоте и стоимости. Первым в очереди на исполнение будет перевозчик с большим
              количеством заявок.`}
          className="mt-6 mb-6"
        />

        <QuotesFormElements.Wrapper selectedItems={selectedPartners} totalOrders={totalOrders}>
          <QuotesFormElements.TypeSwitcher />

          <div className="mt-5 flex">
            <Field
              id={TAX_TYPE}
              name={TAX_TYPE}
              component={FormDropdown}
              options={taxTypeOptions}
              label="Форма налогообложения исполнителей"
              labelHelp="Влияет на доступ к торгу исполнителей, в соответствии с применяемым у них видом налогообложения"
              placeholder=""
              isSearchable={false}
              cleanValue
              labelClassName="mr-4"
              className="bg-color-white"
              onChange={taxTypeChangeHandler}
            />
            <Field
              id={EXECUTOR_IDS}
              name={EXECUTOR_IDS}
              component={FormSelector}
              isMulti={true}
              options={companiesList}
              label="Компания-исполнитель"
              placeholder={
                companiesList.length ? 'Выберите исполнителя' : 'Нет исполнителей для данного типа налогообложения'
              }
              isSearchable={true}
              isClearable={true}
              labelClassName="width380 mr-4"
              inputClassName="bg-color-white"
              onChange={executorsChangeHandler}
              disabled={!taxType}
              OptionElement={CompanyOption}
            />
          </div>

          {!!selectedPartners?.length && (
            <>
              <div className="mt-5">
                <IconBlock Icon={<IconInfo width={20} height={20} color={'primary'} />} className={'flex items-center'}>
                  После ввода «Ставки с НДС» мы автоматически пересчитаем ее на «Без НДС» (20%) и также в обратную
                  сторону. При конвертации копейки округлятся до целого числа.
                </IconBlock>
              </div>

              <QuotesFormElements.Notification className="mt-5" isOrderFormNotification={true} />

              <div className="mt-7">
                <QuotesFormElements.Table onPriceChanged={priceChangeHandler} trashBinClickHandler={trashBinClicked} />
              </div>

              <div className="mt-5">
                <FieldLabel title="Длительность предложения" />
                <div className="flex">
                  <Field
                    name={SUGGESTION_HOURS}
                    type="text"
                    component={FormInput}
                    className="bg-color-white"
                    labelClassName="mr-2 width110"
                    additional="ч"
                    labelDisable={true}
                    disabled={true}
                  />
                  <Field
                    name={SUGGESTION_MINUTES}
                    type="text"
                    component={FormInput}
                    className="bg-color-white"
                    labelClassName="width110"
                    additional="мин"
                    labelDisable={true}
                    disabled={true}
                  />
                </div>
              </div>
            </>
          )}
        </QuotesFormElements.Wrapper>
      </div>

      <AuctionForm className={classNames('mt-3 nml-12 nmr-12')} auctionFieldName={ADDITIONAL_AUCTION} />
    </>
  );
};

export default ExecutorKnownWithQuotes;
