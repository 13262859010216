import * as Yup from 'yup';
import {
  AUCTION_TYPE,
  BID_STEP,
  PRICE_TYPE,
  VISIBILITY,
  WITHOUT_PRICES,
} from '../../containers/order-v2/blocks/AuctionBlock';
import { QUOTA_DISTRIBUTION, QUOTA_TYPE, ROUTE_SHIPPINGS_COUNT } from '../../containers/order-v2/blocks/RoutesBlock';
import { REQUIRED_FIELD } from '../../containers/order-v2/helpers/getValidationSchema';
// import { toast } from '../../Toaster';
import { AUCTION_FORM_FIELDS, QUOTES_AUCTION } from './auctionForm';
import { FORM_PARAMS_MAP, QUOTA_FORM_FIELDS } from './quotesForm';
import { INSTANT } from '../../constants';
import { DESCENDING_PRICE_AUCTION } from '../../store/order/auction/formKeys';

const MESSAGES = {
  REQUIRED: REQUIRED_FIELD,
  MIN_ZERO: 'Не менее 0',
  MIN_ONE: 'Не менее 1',
};

function validateQuantity(this: any) {
  const { from } = this;
  const form = from[1].value;
  const quotaType = form[QUOTA_TYPE];
  const totalOrders = +form[ROUTE_SHIPPINGS_COUNT];
  const options = FORM_PARAMS_MAP[quotaType];
  const validator = options?.validateQuotasDistribution;

  const sum = form[QUOTA_DISTRIBUTION].reduce((acc, item) => {
    const value = +item[QUOTA_FORM_FIELDS.QUANTITY];
    return acc + (isNaN(value) ? 0 : value);
  }, 0);

  const isQuantityValid = validator(sum, totalOrders);
  // if (!isQuantityValid) {
  //     toast.error(options.quantityError.notEnoughtTitle, options.quantityError.notEnoughtSub);
  // }
  return isQuantityValid;
}

export const getQuotaDistributionValidation = (params?) => {
  const { requireMessage, checkQuantity = true, idField = QUOTA_FORM_FIELDS.COMPANY_ID } = params; // totalOrders
  return Yup.array()
    .min(1, requireMessage)
    .of(
      Yup.object()
        .shape({
          [idField]: Yup.string().required(requireMessage).nullable(),
          [QUOTA_FORM_FIELDS.PRICE]: Yup.number().min(0, MESSAGES.MIN_ZERO).required(requireMessage).nullable(),
          [QUOTA_FORM_FIELDS.QUANTITY]: Yup.number()
            .min(1, MESSAGES.MIN_ONE)
            .required(requireMessage)
            .test('is-quantity-valid', ' ', checkQuantity ? validateQuantity : () => true)
            .nullable(),
          [QUOTA_FORM_FIELDS.TAX]: Yup.string().required(requireMessage).nullable(),
        })
        .nullable(),
    )
    .nullable();
};

const priceFieldValidation = Yup.number()
  .when([WITHOUT_PRICES], {
    is: false,
    then: Yup.number().min(1, MESSAGES.MIN_ONE).required(MESSAGES.REQUIRED).nullable(),
  })
  .nullable();

export const getAdditionalAuctionValidation = (params) => {
  const { requireMessage } = params;
  return Yup.object()
    .when([AUCTION_FORM_FIELDS.WITH_AUCTION], {
      is: true,
      then: Yup.object()
        .shape({
          [VISIBILITY]: Yup.string().required(requireMessage).nullable(),
          [AUCTION_TYPE]: Yup.string().required(requireMessage).nullable(),
          [PRICE_TYPE]: Yup.string().required(requireMessage).nullable(),
          [WITHOUT_PRICES]: Yup.boolean(),
          [AUCTION_FORM_FIELDS.START_BID_WITHOUT_TAX]: priceFieldValidation.when([AUCTION_TYPE], {
            is: (value) => value !== INSTANT,
            then: (schema) =>
              schema
                .when([AUCTION_FORM_FIELDS.MIN_BID_WITHOUT_TAX], (value, schema) =>
                  schema.min(value, 'Не менее минимальной ставки'),
                )
                .when([AUCTION_TYPE], {
                  is: (value) => value !== DESCENDING_PRICE_AUCTION,
                  then: (schema) =>
                    schema.when([AUCTION_FORM_FIELDS.MAX_BID_WITHOUT_TAX], (value, schema) =>
                      schema.max(value, 'Не более максимальной ставки'),
                    ),
                }),
          }),
          [AUCTION_FORM_FIELDS.MAX_BID_WITHOUT_TAX]: Yup.number()
            .when([AUCTION_TYPE], {
              is: (value) => value !== INSTANT && value !== DESCENDING_PRICE_AUCTION,
              then: priceFieldValidation,
            })
            .nullable(),
          [AUCTION_FORM_FIELDS.MIN_BID_WITHOUT_TAX]: Yup.number()
            .when([AUCTION_TYPE], {
              is: (value) => value !== INSTANT,
              then: priceFieldValidation.when([AUCTION_TYPE], {
                is: (value) => value !== DESCENDING_PRICE_AUCTION,
                then: (schema) =>
                  schema.when([AUCTION_FORM_FIELDS.MAX_BID_WITHOUT_TAX], (value, schema) =>
                    schema.max(value, 'Не более максимальной ставки'),
                  ),
              }),
            })
            .nullable(),
          [BID_STEP]: priceFieldValidation,
          [AUCTION_FORM_FIELDS.HOURS]: Yup.number().min(0, MESSAGES.MIN_ZERO).required(requireMessage).nullable(),
          [AUCTION_FORM_FIELDS.MINUTES]: Yup.number()
            .min(0, MESSAGES.MIN_ZERO)
            .max(59)
            .required(requireMessage)
            .nullable(),
        })
        .nullable(),
    })
    .nullable();
};

// bids list form validation:
export function getValidationSchema() {
  const required = MESSAGES.REQUIRED;

  return Yup.object().shape({
    [QUOTA_DISTRIBUTION]: getQuotaDistributionValidation({
      requireMessage: required,
      idField: QUOTA_FORM_FIELDS.BID_ID,
    }),
    [QUOTES_AUCTION]: getAdditionalAuctionValidation({
      requireMessage: required,
    }),
  });
}
