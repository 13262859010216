import moment from 'moment';
import { DATE_TIME_FORMAT, SUPPORT_PHONE_NUMBER } from '../../constants';
import _get from 'lodash/get';
import { toast } from '../../components/Toaster';
import { INSURANCE_STATUSES, INSURANCE_SUPPORT_EMAIL } from './constants';

export const showInsuranceRequestToaster = () =>
  toast.error(
    'Не удалось оформить страховку',
    `Попробуйте ещё раз. Если это не решит проблему, обратитесь в службу поддержки ${SUPPORT_PHONE_NUMBER.common} (будние дни, с 9:00 до 18:00) или напишите нам ${INSURANCE_SUPPORT_EMAIL}`,
  );

export function parseMultipolicyFiles(multipolicyFiles) {
  if (!multipolicyFiles?.length) return { hasInsurance: false };

  const lastMultipolicyIteration = multipolicyFiles.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];

  const isInsuranceActive = lastMultipolicyIteration.status === INSURANCE_STATUSES.ACTIVE;
  const isInsuranceRequested = lastMultipolicyIteration.status === INSURANCE_STATUSES.REQUESTED;
  const isInsuranceFailed = lastMultipolicyIteration.status === INSURANCE_STATUSES.ERROR;
  const isInsuranceRevoked = [
    INSURANCE_STATUSES.CANCELED,
    INSURANCE_STATUSES.REVOKED,
    INSURANCE_STATUSES.PENDING_FOR_REVOCATION,
  ].includes(lastMultipolicyIteration.status);

  const timeIsOver = +moment().format('X') >= moment(lastMultipolicyIteration.cancellation_till).format('X');

  const formattedDeadline = moment
    .parseZone(lastMultipolicyIteration.cancellation_till)
    .local(true)
    .format(DATE_TIME_FORMAT);

  const email = lastMultipolicyIteration.notify_emails;

  return {
    hasInsurance: true,
    // statuses:
    isInsuranceActive,
    isInsuranceRequested,
    isInsuranceFailed,
    isInsuranceRevoked,
    // info:
    timeIsOver,
    formattedDeadline,
    email,
  };
}

export function isDLT(transportCompanyId, forceResult) {
  if (forceResult != null) return forceResult; // for testing
  return [process.env.REACT_APP_DLTRANS_ID].includes(transportCompanyId);
}

// 4 категории файлов и их порядок при отображении:
const FILES_FIELDS = {
  POLICY: 'policy_file',
  APPLICATION: 'application_file',
  CANCELLATION_APPLICATION: 'cancellation_application_file',
  CANCELLATION_AGREEMENT: 'cancellation_additional_agreement_file',
};
const FILES_ROWS = [
  [FILES_FIELDS.CANCELLATION_APPLICATION, FILES_FIELDS.CANCELLATION_AGREEMENT],
  [FILES_FIELDS.POLICY, FILES_FIELDS.APPLICATION],
];
// все существующие комбинации `тип файла + поле` по страхованию:
const FILES_PARAMS = [
  {
    documentType: 'MULTIPOLICY',
    field: FILES_FIELDS.POLICY,
    title: 'Полис комплексного страхования «Мультиполис»',
    archiveTitle: 'Архивный полис комплексного страхования',
  },
  {
    documentType: 'APPLICATION_FOR_MULTIPOLICY',
    field: FILES_FIELDS.APPLICATION,
    title: 'Заявление на страхование',
    archiveTitle: 'Архивное заявление',
  },
  {
    documentType: 'CANCELLATION_MULTIPOLICY', // выпилить в марте 2027
    field: FILES_FIELDS.CANCELLATION_APPLICATION, // для старых страховок; в новых лежит в другом поле
    title: 'Уведомление о расторжении полиса',
    archiveTitle: 'Архивное уведомление о расторжении полиса',
  },
  {
    documentType: 'CANCELLATION_ADDITIONAL_AGREEMENT_MULTIPOLICY', // для старых страховок; больше не присылается. выпилить в марте 2027
    field: FILES_FIELDS.CANCELLATION_AGREEMENT,
    title: 'Доп. соглашение о расторжении',
    archiveTitle: 'Архивное доп. соглашение о расторжении',
  },
  {
    documentType: 'CANCELLATION_MULTIPOLICY', // новые страховки в которых поменялось расположение файлов
    field: FILES_FIELDS.CANCELLATION_AGREEMENT,
    title: 'Уведомление о расторжении полиса',
    archiveTitle: 'Архивное уведомление о расторжении полиса',
  },
  {
    documentType: 'APPLICATION_FOR_MULTIPOLICY_CANCELLATION',
    field: FILES_FIELDS.CANCELLATION_APPLICATION, // новые страховки
    title: 'Заявление о расторжении полиса',
    archiveTitle: 'Архивное заявление о расторжении полиса',
  },
];

export function getMultipolicyScanCopies(multipolicyFiles, isArchived = false) {
  if (!multipolicyFiles?.length) return [];

  const sortedIterations = multipolicyFiles.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const array = [];
  sortedIterations.forEach((iteration, index) => {
    const rowParamsFilter = (field) => !!iteration[field];
    // ищем параметры документа, который лежит в нужном поле и с правильным типом
    const rowParamsMapper = (field) => {
      return FILES_PARAMS.find(
        (params) => params.field === field && params.documentType === iteration[field].document_type,
      );
    };

    // параметры существующих файлов, расположенные по порядку:
    const row1params = FILES_ROWS[0].filter(rowParamsFilter).map(rowParamsMapper);
    const row2params = FILES_ROWS[1].filter(rowParamsFilter).map(rowParamsMapper);

    const getFilesByParams = (rowParams, useArchivedTitle) => {
      return rowParams.map((params) => {
        const fileName = useArchivedTitle ? params.archiveTitle : params.title;
        return {
          typeId: params.field,
          name: isArchived ? `${fileName} (архив)` : fileName,
          files: [
            {
              id: iteration[params.field].id,
              isSecure: false,
              downloadLink: false,
              fileName: iteration[params.field].file_name,
              singedUrl: iteration[params.field].signed_url,
              createAt: iteration[params.field].created_at,
            },
          ],
        };
      });
    };

    if (row1params.length) {
      array.push({
        name: '',
        items: getFilesByParams(row1params, index > 0),
      });
    }
    if (row2params.length) {
      array.push({
        name: '',
        items: getFilesByParams(row2params, index > 0 || row1params.length),
      });
    }
  });
  return array;
}

export function getLastPolicyFromOldPolicies(oldPolicies) {
  const allPoliciesArray = oldPolicies?.reduce((acc, shippingPolicies) => {
    acc.push(...shippingPolicies);
    return acc;
  }, []);
  const sortedArray = allPoliciesArray.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  return sortedArray[0];
}

export const showServiceDeniedError = () => {
  toast.error(
    'Сервис временно недоступен',
    `Попробуйте обновить страницу. Если проблема не исчезла, обратитесь в службу поддержки ${SUPPORT_PHONE_NUMBER.common} (будние дни, с 9:00 до 18:00).`,
  );
};
