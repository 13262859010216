/**
 * Получить округленную до получаса дату (в большую сторону)
 * @param date
 */
export function getHalfHourRounded(date: Date | number): Date {
  const timestamp = typeof date === 'number' ? date : date.getTime();
  /* Полчаса в секундах */
  const halfHourInMilliseconds = 30 * 60 * 1000;
  /* Секунд до ближайшего получаса */
  const upToHalfHour = timestamp ? halfHourInMilliseconds - (timestamp % halfHourInMilliseconds) : 0;
  return new Date(timestamp + upToHalfHour);
}
