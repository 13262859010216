/* Категории документов DBrain */
// Водительское Удостоверение: образец 2011 года (обратная сторона)
export const DRIVER_LICENSE_2011_BACK = 'driver_license_2011_back';
// Водительское Удостоверение: образец 2011/2014 года (лицевая сторона)
export const DRIVER_LICENSE_FRONT = 'driver_license_front';
// Водительское Удостоверение: образец 2014 года (обратная сторона)
export const DRIVER_LICENSE_2014_BACK = 'driver_license_2014_back';
// Паспорт гражданина РФ: главный разворот, печатный образец
export const PASSPORT_MAIN = 'passport_main';
// Паспорт гражданина РФ: главный разворот, рукописный образец
export const PASSPORT_MAIN_HANDWRITTEN = 'passport_main_handwritten';
// Паспорт гражданина РФ: страница «Место жительства», печатный образец
export const PASSPORT_REGISTRATION = 'passport_registration';
// Паспорт гражданина РФ: страница «Место жительства», рукописный образец
export const PASSPORT_REGISTRATION_HANDWRITTEN = 'passport_registration_handwritten';
// Свидетельство о регистрации ТС: лицевая сторона
export const VEHICLE_REGISTRATION_CERTIFICATE_FRONT = 'vehicle_registration_certificate_front';
// Свидетельство о регистрации ТС: обратная сторона
export const VEHICLE_REGISTRATION_CERTIFICATE_BACK = 'vehicle_registration_certificate_back';

/* Поля DBrain */
export const DOC_TYPE = 'doc_type'; // Тип распознанного документа
export const CONFIDENCE = 'confidence'; // Точность распознавания
export const FIELDS = 'fields'; // Объект с распознанными полями документа

export const CATEGORY_A_END = 'category_a_end'; // Категория A: конец действия
export const CATEGORY_A1_END = 'category_a1_end'; // Категория A1: конец действия
export const CATEGORY_A1_BEGIN = 'category_a1_begin'; // Категория A1: начало действия
export const CATEGORY_A_BEGIN = 'category_a_begin'; // Категория A: начало действия
export const CATEGORY_B_END = 'category_b_end'; // Категория B: конец действия
export const CATEGORY_B_BEGIN = 'category_b_begin'; // Категория B: начало действия
export const CATEGORY_B1_END = 'category_b1_end'; // Категория B1: конец действия
export const CATEGORY_B1_BEGIN = 'category_b1_begin'; // Категория B1: начало действия
export const CATEGORY_BE_END = 'category_be_end'; // Категория Be: конец действия
export const CATEGORY_BE_BEGIN = 'category_be_begin'; // Категория Be: начало действия
export const CATEGORY_C_END = 'category_b_end'; // Категория C: конец действия
export const CATEGORY_C_BEGIN = 'category_b_begin'; // Категория C: начало действия
export const CATEGORY_C1_END = 'category_c1_end'; // Категория C1: конец действия
export const CATEGORY_C1_BEGIN = 'category_c1_begin'; // Категория C1: начало действия
export const CATEGORY_C1E_END = 'category_c1e_end'; // Категория C1e: конец действия
export const CATEGORY_C1E_BEGIN = 'category_c1e_begin'; // Категория C1e: начало действия
export const CATEGORY_CE_END = 'category_ce_end'; // Категория Ce: конец действия
export const CATEGORY_CE_BEGIN = 'category_ce_begin'; // Категория Ce: начало действия
export const CATEGORY_D_END = 'category_d_end'; // Категория D: конец действия
export const CATEGORY_D_BEGIN = 'category_d_begin'; // Категория D: начало действия
export const CATEGORY_D1_END = 'category_d1_end'; // Категория D1: конец действия
export const CATEGORY_D1_BEGIN = 'category_d1_begin'; // Категория D1: начало действия
export const CATEGORY_D1E_END = 'category_d1e_end'; // Категория D1e: конец действия
export const CATEGORY_D1E_BEGIN = 'category_d1e_begin'; // Категория D1e: начало действия
export const CATEGORY_DE_END = 'category_de_end'; // Категория De: конец действия
export const CATEGORY_DE_BEGIN = 'category_de_begin'; // Категория De: начало действия
export const CATEGORY_M_END = 'category_m_end'; // Категория M: конец действия
export const CATEGORY_M_BEGIN = 'category_m_begin'; // Категория M: начало действия
export const CATEGORY_TB_END = 'category_tb_end'; // Категория Tb: конец действия
export const CATEGORY_TB_BEGIN = 'category_tb_begin'; // Категория Tb: начало действия
export const CATEGORY_TM_END = 'category_tm_end'; // Категория Tm: конец действия
export const CATEGORY_TM_BEGIN = 'category_tm_begin'; // Категория Tm: начало действия
export const SPECIAL_MARKS = 'special_marks'; // Особые отметки
export const SERIES_NUMBER = 'series_number'; // Серия и номер документа
export const DATE_FROM = 'date_from'; // Дата выдачи
export const DATE_OF_BIRTH = 'date_of_birth'; // Дата рождения
export const DATE_END = 'date_end'; // Действительно до
export const NAME = 'name'; // Имя
export const CATEGORY = 'category'; // Категории прав
export const PLACE_OF_ISSUE = 'place_of_issue'; // Место выдачи
export const PLACE_OF_BIRTH = 'place_of_birth'; // Место рождения
export const NUMBER = 'number'; // Номер документа
export const PATRONYMIC = 'patronymic'; // Отчество
export const ISSUER = 'issuer'; // Подразделение, выдавшее документ
export const SURNAME = 'surname'; // Фамилия
export const MRZ = 'mrz'; // Machine-Readable Zone
export const DATE_OF_ISSUE = 'date_of_issue'; // Дата выдачи
export const FIRST_NAME = 'first_name'; // Имя
export const SUBDIVISION_CODE = 'subdivision_code'; // Код подразделения
export const OTHER_NAMES = 'other_names'; // Отчество
export const ISSUING_AUTHORITY = 'issuing_authority'; // Паспорт выдан
export const SEX = 'sex'; // Пол
export const SERIES_AND_NUMBER = 'series_and_number'; // Серия и номер документа
export const ADDRESS = 'address'; // Адрес регистрации
export const DATE = 'date'; // Дата регистрации
export const HOUSE = 'house'; // Дом
export const APARTMENT = 'apartment'; // Квартира
export const BUILDING = 'building'; // Корпус
export const LOCALITY = 'locality'; // Пункт
export const REGION_DISTRICT = 'region_district'; // Район
export const REGION = 'region'; // Регион
export const LOCALITY_DISTRICT = 'locality_district'; // Район
export const STREET = 'street'; // Улица
export const PARSED_ADDRESS = 'parsedAddress'; // распознанный адрес из дадаты
export const RELEASE_YEAR = 'release_year'; // Год выпуска ТС
export const ENGINE_NUMBER = 'engine_number'; // Двигатель №
export const VEHICLE_CATEGORY = 'vehicle_category'; // Категория ТС
export const VEHICLE_BODY = 'vehicle_body'; // Кузов №
export const BRAND_ENG = 'brand_eng'; // Марка на английском
export const BRAND_RUS = 'brand_rus'; // Марка на русском
export const MASS = 'mass'; // Масса без нагрузки
export const ENGINE_MODEL = 'engine_model'; // Модель двигателя
export const MODEL_ENG = 'model_eng'; // Модель на английском
export const MODEL_RUS = 'model_rus'; // Модель на русском
export const ENGINE_KW = 'engine_kw'; // Мощность двигателя, кВт
export const ENGINE_HP = 'engine_hp'; // Мощность двигателя, л. с.
export const VIN = 'vin'; // Номер VIN
export const NUMBER_BOTTOM = 'number_bottom'; // Номер СТС (нижняя часть)
export const PASSPORT_NUMBER = 'passport_number'; // Паспорт ТС: номер
export const PASSPORT_SERIES = 'passport_series'; // Паспорт ТС: серия
export const ENGINE_VOLUME = 'engine_volume'; // Рабочий объем двигателя
export const MAX_MASS = 'max_mass'; // Разрешенная max масса
export const REG_NUMBER = 'reg_number'; // Регистрационный знак
export const DOCUMENT_NUMBER = 'document_number'; // Серия и номер СТС
export const SERIES_BOTTOM = 'series_bottom'; // Серия СТС (нижняя часть)
export const TEMPORARY_REGISTRATION_TERM = 'temporary_registration_term'; // Срок временной регистрации
export const VEHICLE_TYPE = 'vehicle_type'; // Тип ТС
export const COLOR = 'color'; // Цвет
export const VEHICLE_CHASSIS = 'vehicle_chassis'; // Шасси №
export const ECOLOGIC_CLASS = 'ecologic_class'; // Экологический класс
export const HOUSE_NUMBER = 'house_number'; // Дом
export const APARTMENT_NUMBER = 'apartment_number'; // Квартира
export const BUILDING_NUMBER = 'building_number'; // Корпус
export const LEGAL_NAME = 'legal_name'; // Название владельца (юрлицо на английском)
export const LEGAL_NAME_RUS = 'legal_name_rus'; // Название владельца (юрлицо на русском)
export const CITY = 'city'; // Нас. пункт
export const NUMBER_TOP = 'number_top'; // Номер СТС (верхняя часть)
export const POLICE_UNIT_CODE = 'police_unit_code'; // Орган, выдавший документ
export const PROVINCE_RUS = 'province_rus'; // Республика, край, область
export const PROVINCE = 'province'; // Республика, край, область
export const SERIES_TOP = 'series_top'; // Серия СТС (верхняя часть)
export const NAME_RUS = 'name_rus'; // Собственник: имя (на русском)
export const PATRONYMIC_RUS = 'patronymic_rus'; // Собственник: имя (на русском)
export const SURNAME_RUS = 'surname_rus'; // Собственник: фамилия (на русском)

export const PASSPORT_MAIN_REQUIRED_FIELDS = [
  FIRST_NAME,
  SURNAME,
  DATE_OF_BIRTH,
  SERIES_AND_NUMBER,
  DATE_OF_ISSUE,
  SUBDIVISION_CODE,
  ISSUING_AUTHORITY,
];

export const PASSPORT_REGISTRATION_REQUIRED_FIELDS = [ADDRESS];

export const DRIVER_LICENSE_FRONT_REQUIRED_FIELDS = [NAME, SURNAME, DATE_OF_BIRTH, NUMBER, ISSUER, DATE_FROM, DATE_END];

export const VEHICLE_REGISTRATION_CERTIFICATE_FRONT_REQUIRED_FIELDS = [
  VEHICLE_TYPE,
  REG_NUMBER,
  DOCUMENT_NUMBER,
  VIN,
  BRAND_ENG,
  MODEL_ENG,
  PASSPORT_SERIES,
  PASSPORT_NUMBER,
];

export const VEHICLE_REGISTRATION_CERTIFICATE_BACK_REQUIRED_FIELDS = [
  NUMBER_TOP,
  SERIES_TOP,
  NAME_RUS,
  PATRONYMIC_RUS,
  SURNAME_RUS,
];
