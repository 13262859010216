import React, { Fragment, lazy, Suspense } from 'react';

import { AuctionTypeNotification } from '../../components/AuctionTypeNotification';
import { AuctionExpeditedPaymentBlock } from '../../components/AuctionExpeditedPaymentBlock';

import { useAbilityPartners } from '../../hooks/useAbilityPartners';
import { CONTRACT_BY_COUNT, FORM_TYPE, FULL, ORDER_TYPE, SHIPPING_REQUEST, SIMPLE } from '../OrderNFormTypeBlock';
import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import ContentLoader from '../../../../components/ContentLoader';
import { useOrderContext } from '../../index';
import { getHashString } from '../../helpers/getHashString';

export const AUCTION_STAGES = 'auction_stages';
export const AUCTION_TYPE = 'auction_type';
export const PRICE_TYPE = 'price_type';
export const VISIBILITY = 'visibility';
export const PARTNERS = 'partners';
export const WITHOUT_PRICES = 'without_prices';
export const BEGINS_AT = 'begins_at';
export const ENDS_AT = 'ends_at';
export const ENABLE_AUCTION_AUTO_EXTEND = 'enable_auction_auto_extended';
export const AUTO_EXTENDED = 'auction_auto_extend_period';
export const INTERMEDIATE_RESULTS_PERIOD = 'intermediate_results_period';
export const BID_STEP = 'bid_step';
export const MAX_BID_WITHOUT_TAX = 'max_bid_without_vat';
export const MIN_BID_WITHOUT_TAX = 'min_bid_without_vat';
export const PRICE_WITHOUT_TAX = 'price_without_vat';
export const MANY_EXECUTORS_ALLOWED = 'many_executors_allowed';
export const EXECUTORS = 'executors';
export const EXECUTORS_ARRAY = 'executors_array';
export const EXECUTOR_PRICE = 'executor_price';
export const EXECUTOR_PRICE_WITH_VAT = 'executor_price_with_vat';

export const PRICE_WITH_VAT = 'price_with_vat';
export const MAX_BID_WITH_VAT = 'max_bid_with_vat';
export const MIN_BID_WITH_VAT = 'min_bid_with_vat';

/* Технические поля */
export const MAX_AUCTION_STAGES = 5;
export const DEFAULT_AUCTION_STAGE_DURATION = 6;
export const IS_EXECUTOR_KNOWN = 'is_executor_known';
export const AUCTION_FOR_ALL = 'auction_for_all';

const ShippingRequestSimpleAuctionBlock = lazy(() => import('./ShippingRequestSimpleAuctionBlock'));
const ShippingRequestFullAuctionBlock = lazy(() => import('./ShippingRequestFullAuctionBlock'));
const ContractByCountSimpleAuctionBlock = lazy(() => import('./ContractByCountSimpleAuctionBlock'));
const ContractOrderSimpleAuctionBlock = lazy(() => import('./ContractOrderSimpleAuctionBlock'));
const ContractByCountFullAuctionBlock = lazy(() => import('./ContactByCountFullAuctionBlock'));
const OrderChangeAuctionBlock = lazy(() => import('./OrderChangeAuctionBlock'));

function getBlockComponent(hashString, isOrderChange) {
  // Изменение требований
  if (isOrderChange) {
    return OrderChangeAuctionBlock;
  }
  switch (hashString) {
    // Спот + упрощенка
    case getHashString({ isOrderBasedOnContract: false, orderType: SHIPPING_REQUEST, formType: SIMPLE }):
      return ShippingRequestSimpleAuctionBlock;
    // Спот + полная
    case getHashString({ isOrderBasedOnContract: false, orderType: SHIPPING_REQUEST, formType: FULL }):
      return ShippingRequestFullAuctionBlock;
    // Объемный контракт + упрощенка
    case getHashString({ isOrderBasedOnContract: false, orderType: CONTRACT_BY_COUNT, formType: SIMPLE }):
      return ContractByCountSimpleAuctionBlock;
    // Заявка по контракту + упрощенка
    case getHashString({ isOrderBasedOnContract: true, orderType: SHIPPING_REQUEST, formType: SIMPLE }):
      return ContractOrderSimpleAuctionBlock;
    // Объемный контракт + полная
    case getHashString({ isOrderBasedOnContract: false, orderType: CONTRACT_BY_COUNT, formType: FULL }):
    case getHashString({ isOrderBasedOnContract: true, orderType: SHIPPING_REQUEST, formType: FULL }):
      return ContractByCountFullAuctionBlock;
    default:
      return null;
  }
}

/**
 * Блок управления торгами
 * Включает поля Типа торга, Участников торга, Способ формирования стоимости, Цены/Стоимости
 */
export function AuctionBlock() {
  const { isOrderBasedOnContract, isOrderChange } = useOrderContext();
  const [orderType] = useFieldValue(ORDER_TYPE);
  const [formType] = useFieldValue(FORM_TYPE);
  const hashString = getHashString({ isOrderBasedOnContract, orderType, formType });
  const Component = getBlockComponent(hashString, isOrderChange);

  /* Эффект для сброса "Участников торга" при отсутсвии партнеров */
  useAbilityPartners();

  return (
    <Fragment>
      <AuctionExpeditedPaymentBlock orderType={orderType} isOrderBasedOnContract={isOrderBasedOnContract} />
      <AuctionTypeNotification orderType={orderType} />
      <Suspense fallback={<ContentLoader withBackground />}>{Component ? <Component /> : null}</Suspense>
    </Fragment>
  );
}
