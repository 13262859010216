import React from 'react';
import SVGIcon from './index';
import useMultiColorsIcons from '../useMultiColorIcons';

const IconCircleFilled = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  const { mainFill } = useMultiColorsIcons(props.color);
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <rect x="5" y="5" width="10" height="10" rx="5" className={mainFill} />
    </SVGIcon>
  );
};

IconCircleFilled.defaultProps = {
  color: 'baseTheme',
};

export default IconCircleFilled;
