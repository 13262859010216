import React from 'react';

import Badge from '../../Badge';

import * as constants from '../../../store/claims/constants';
import { getClaimStatusTranslation } from './getClaimStatusTranslation';

export const getTheme = (type) => (type === 'incoming' ? _getIncomingColor : _getOutComingColor);

function _getIncomingColor(status) {
  switch (status) {
    case constants.NEED_FIX:
    case constants.PENDING_TC_REVIEW:
      return 'danger';
    case constants.CANCELED:
      return 'default';
    case constants.ACCEPT:
    case constants.ACCEPT_EXPIRATION:
    case constants.ACCEPT_UNILATERALLY:
    case constants.DONE:
      return 'success';
    case constants.REJECT:
    case constants.PENDING_REVIEW:
    case constants.IN_PROGRESS:
      return 'warning';
    case constants.NEW:
    default:
      return 'default';
  }
}

function _getOutComingColor(status) {
  switch (status) {
    case constants.NEED_FIX:
    case constants.REJECT:
    case constants.CANCELED:
      return 'danger';
    case constants.ACCEPT:
    case constants.PARTIAL_ACCEPT:
    case constants.DONE:
      return 'success';
    case constants.PENDING_REVIEW:
    case constants.IN_PROGRESS:
    case constants.PENDING_TC_REVIEW:
      return 'warning';
    case constants.NEW:
    default:
      return 'default';
  }
}

export function getClaimStatus(status: string, kind: string, finalPrice: string | number | null) {
  const statusText = getClaimStatusTranslation(kind, status, finalPrice);
  const theme = getTheme(kind)(status);
  return <Badge text={statusText || status} theme={theme || 'default'} />;
}
