import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { useTableContext, useTableMatomoEvent } from '../hooks';
import { USED_TABLE_HORIZONTAL_SCROLL } from '../../../../features/matomo-analytics/eventNameConstants';

const ScrollBar = React.forwardRef(
  ({ tableWidth, tableContainerWidth, scrollHeight, onScrollHandler, onMouseUpHandler }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(styles.scrollPanel)}
        style={{
          width: tableContainerWidth + 'px',
        }}
        onScroll={onScrollHandler}
        onMouseUp={onMouseUpHandler}
      >
        <div style={{ height: '1px', width: tableWidth + 'px', marginBottom: '-1px' }}></div>
      </div>
    );
  },
);

function ScrollPanel({ data, tableWidth, tableContainerWidth }) {
  const { tableContainerRef } = useTableContext();
  const scrollContainerEl = document.getElementById('contentScroll');
  const fixedScrollBar = useRef(null);

  const showStickyScrollRef = useRef();
  const [showStickyScroll, setShowStickyScroll] = useState(false);

  const pageContentScrollHandler = useCallback(
    (_) => {
      const fixedScrollBarRect = fixedScrollBar?.current?.getBoundingClientRect();
      const fixedScrollBarInView =
        fixedScrollBarRect?.bottom <= (window.innerHeight || document.documentElement.clientHeight);
      const newState = !fixedScrollBarInView;
      if (showStickyScrollRef.current !== newState) {
        showStickyScrollRef.current = newState;
        setShowStickyScroll(newState);
      }
    },
    [fixedScrollBar],
  );

  useEffect(() => {
    pageContentScrollHandler(data);
  }, [data, pageContentScrollHandler]);

  useEffect(() => {
    if (!fixedScrollBar) return;
    scrollContainerEl.addEventListener('scroll', pageContentScrollHandler); // { passive: true }
    pageContentScrollHandler();
    return () => scrollContainerEl.removeEventListener('scroll', pageContentScrollHandler);
  }, [fixedScrollBar, scrollContainerEl, pageContentScrollHandler]);

  const scrollHandler = useCallback(
    (e) => {
      const value = e.target.scrollLeft;
      if (tableContainerRef?.current) {
        tableContainerRef.current.scrollTo(value, 0);
      }
    },
    [tableContainerRef],
  );

  const resizeCallback = useCallback(
    (e) => {
      pageContentScrollHandler();
    },
    [pageContentScrollHandler],
  );

  useEffect(() => {
    window.addEventListener('resize', resizeCallback);
    return () => window.removeEventListener('resize', resizeCallback);
  }, [resizeCallback]);

  const sendScrollUsedEvent = useTableMatomoEvent(USED_TABLE_HORIZONTAL_SCROLL);
  const mouseUpHandler = useCallback(() => {
    sendScrollUsedEvent();
  }, [sendScrollUsedEvent]);

  return (
    <>
      <ScrollBar
        ref={fixedScrollBar}
        tableWidth={tableWidth}
        tableContainerWidth={tableContainerWidth}
        onScrollHandler={scrollHandler}
        onMouseUpHandler={mouseUpHandler}
      />
      {showStickyScroll &&
        createPortal(
          <ScrollBar
            tableWidth={tableWidth}
            tableContainerWidth={tableContainerWidth}
            onScrollHandler={scrollHandler}
          />,
          scrollContainerEl,
        )}
    </>
  );
}

export default ScrollPanel;
