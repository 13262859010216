import React from 'react';
import SVGIcon from './index';

function IconSwap(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path
        d="M5 18v5h4.011v-2l3.008 3-3.007 3-.001-2H3v-7h2zM21 4v6h6v14H13v-6H7V4h14zm-2 2H9v10h4v-6h6V6z"
        fillRule="evenodd"
      />
    </SVGIcon>
  );
}

IconSwap.defaultProps = {
  color: 'base',
};

export default React.memo(IconSwap);
