import React from 'react';
import SVGIcon from './index';

const IconRequirementsChange = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 24 24">
      <path
        d="M24.51,23c-2.92,0-5.86,0-8.79,0a2.45,2.45,0,0,1-2.42-2.13c-.12-1.36,0-2.73-.05-4.1-1.36,0-2.72.07-4.08,0A2.47,2.47,0,0,1,7,14.29Q7,9.88,7,5.47A2.47,2.47,0,0,1,9.45,3c2.83,0,5.67,0,8.5,0A2.54,2.54,0,0,1,20.7,5.14c.12,1.37,0,2.74.05,4.12a42,42,0,0,1,4.35.08A2.55,2.55,0,0,1,27,12.05c0,2.83,0,5.66,0,8.49A2.45,2.45,0,0,1,24.51,23M18.25,5.51H9.5v8.74h3.75c.09-1.42-.38-3.15.74-4.27s2.85-.63,4.26-.72Z"
        transform="translate(-3 -3)"
      />
      <path
        d="M3,17h2.5v5H9.25c0-.83,0-1.66,0-2.5L13,23.25,9.26,27c0-.83,0-1.66,0-2.5-1.36,0-2.72.07-4.07,0A2.47,2.47,0,0,1,3,22C3,20.35,3,18.67,3,17Z"
        transform="translate(-3 -3)"
      />
    </SVGIcon>
  );
};

export default IconRequirementsChange;
