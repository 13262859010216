import produce, { Draft } from 'immer';
import _unionBy from 'lodash/unionBy';

import * as types from '../chat/actionTypes';
import { ROOM_STATE, TChatStore } from './models';

function _getRestoredRooms() {
  try {
    return JSON.parse(localStorage.getItem(CHAT_ROOMS) ?? '{}');
  } catch {
    return {};
  }
}

export const CHAT_SYNC_SINCE = 'tp.chat._syncSince';
export const CHAT_ROOMS = 'tp.chat._rooms';

const initialState: TChatStore = {
  _rooms: _getRestoredRooms(),
  _syncSince: localStorage.getItem(CHAT_SYNC_SINCE) ?? null,
  _chats: [],
};

const reducer = produce((draft: Draft<TChatStore>, action = {}) => {
  switch (action.type) {
    case types.UPDATE_INVITED_ROOMS_STATE:
      Object.entries(action.payload).forEach(([roomId]) => {
        draft._rooms[roomId] = { state: ROOM_STATE.invite, unread: 0 };
      });
      localStorage.setItem(CHAT_ROOMS, JSON.stringify(draft._rooms));
      break;

    case types.UPDATE_JOINED_ROOMS_STATE:
      Object.entries(action.payload).forEach(([roomId, data]) => {
        // @ts-ignore
        draft._rooms[roomId] = { state: ROOM_STATE.join, unread: data?.unread_notifications?.notification_count ?? 0 };
      });
      localStorage.setItem(CHAT_ROOMS, JSON.stringify(draft._rooms));
      break;

    case types.UPDATE_LEFT_ROOMS_STATE:
      Object.entries(action.payload).forEach(([roomId]) => {
        delete draft._rooms[roomId];
      });
      localStorage.setItem(CHAT_ROOMS, JSON.stringify(draft._rooms));
      break;

    case types.UPDATE_SYNC_SINCE:
      draft._syncSince = action.payload;
      localStorage.setItem(CHAT_SYNC_SINCE, action.payload);
      break;

    case types.SET_CHATS:
      draft._chats = action.payload;
      break;

    case types.SET_CHATS_FOR_ENTITY_ID:
      draft._chats = _unionBy(draft._chats, action.payload ?? [], 'id');
      break;
  }
}, initialState);

export default reducer;
