function calculateMaxBidConsideringStep(bid: number, step?: number, limit: number | null = null): number {
  const max = Math.round(bid * 1.1);

  if (!step) return max;

  const maxLimit = limit || Math.round(bid * 1.1 + step);

  let currentValue = Number(bid);
  const maxArr = [currentValue];

  if (limit) {
    if (limit - bid <= step) {
      return +bid;
    }
    while (currentValue - maxLimit < 0) {
      currentValue += step;
      maxArr.push(currentValue);
    }
    return Math.abs(limit - maxArr[maxArr.length - 1]) < Math.abs(limit - maxArr[maxArr.length - 2])
      ? maxArr[maxArr.length - 1]
      : maxArr[maxArr.length - 2];
  } else {
    while (currentValue - maxLimit < 0) {
      maxArr.push(currentValue);
      currentValue += step;
    }
    return Math.abs(max - maxArr[maxArr.length - 1]) < Math.abs(max - maxArr[maxArr.length - 2])
      ? maxArr[maxArr.length - 1]
      : maxArr[maxArr.length - 2];
  }
}

export default calculateMaxBidConsideringStep;
