import React from 'react';
import SVGIcon from './index';
// import colors from '../../../styles/colors.scss';

function IconShield(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4.33334C5.8 2.44444 10 1.5 10 1.5C10 1.5 14.2 2.44444 17 4.33334V9.05556C17 12.8333 13.7333 17.0833 10 18.5C6.26667 17.0833 3.00008 12.8333 3.00008 9.05556L3 4.33334ZM11.8108 4.10343C12.8351 4.44895 13.9865 4.90575 15 5.45879V9.05556C15 10.396 14.4029 11.992 13.2998 13.4635C12.3818 14.688 11.21 15.6947 10 16.3138C8.78999 15.6947 7.61825 14.688 6.70028 13.4635C5.59713 11.992 5.00008 10.396 5.00008 9.05552L5.00002 5.45878C6.01352 4.90574 7.16487 4.44895 8.18922 4.10343C8.84266 3.88303 9.41478 3.71765 9.82105 3.60802C9.88506 3.59075 9.94482 3.57489 10 3.56046C10.0552 3.57489 10.1149 3.59075 10.179 3.60802C10.5852 3.71765 11.1573 3.88303 11.8108 4.10343ZM11.4911 5.05098C12.3125 5.32803 13.1931 5.67091 14 6.06712V9.05556C14 10.1218 13.5127 11.5123 12.4996 12.8637C11.7797 13.824 10.9001 14.6203 9.99997 15.1684C9.09983 14.6203 8.22031 13.824 7.50037 12.8637C6.4873 11.5124 6.00005 10.1218 6.00005 9.0555L6 6.06711C6.8069 5.6709 7.68744 5.32803 8.5088 5.05098C9.09628 4.85282 9.61577 4.70075 9.99997 4.59565C10.3842 4.70075 10.9037 4.85282 11.4911 5.05098Z"
      />

      {/*<path*/}
      {/*  fillRule="evenodd"*/}
      {/*  clipRule="evenodd"*/}
      {/*  d="M3 4.33334C5.8 2.44444 10 1.5 10 1.5C10 1.5 14.2 2.44444 17 4.33334V9.05556C17 12.8333 13.7333 17.0833 10 18.5C6.26667 17.0833 3.00008 12.8333 3.00008 9.05556L3 4.33334ZM9.11958 10.71L12.8965 6.91998L14 8.03433L9.11958 12.9309L6.3038 10.1065L7.40728 9L9.11958 10.71Z"*/}
      {/*  fill={colors[props.color!]}*/}
      {/*/>*/}
    </SVGIcon>
  );
}

IconShield.defaultProps = { color: 'base' };

export default React.memo(IconShield);
