import React from 'react';
import SVGIcon from './index';

import Tooltip from '../../Tooltip';

function IconTurnedIn(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <Tooltip content="График отслеживается">
      <SVGIcon {...props} viewBox="0 0 30 30">
        <path d="M20 6v18l-7-3-7 3V6h14z" fillRule="evenodd" />
      </SVGIcon>
    </Tooltip>
  );
}

IconTurnedIn.defaultProps = {
  color: 'base',
};

export default React.memo(IconTurnedIn);
