import http from './config/webConfig';
import { TExpeditedPayment } from '../hooks/useExpeditedPayment';

/**
 * Отправить компанию на проверку
 * и подтверждение доступа
 */
export async function sendToApprove(companyId): Promise<any> {
  const url: string = `company/${companyId}/send_to_review`;
  return http.post(url, {}).then((response) => response.data);
}

/**
 * Обновить
 * @param params
 */
export async function updateCompany(params: any): Promise<any> {
  const { companyId, ...rest } = params;
  const url: string = `company/${companyId}`;
  const { data } = await http.put(url, { ...rest });
  return data;
}

/**
 * Получить информацию о компании по идентификатору
 * @param companyId {String}
 */
export async function getCompanyInfoById(companyId: string): Promise<any> {
  const url: string = `company/${companyId}`;
  return http.get(url).then((response) => response.data);
}

/**
 * По списку ид компаний получить краткую информацию
 * Вернет информацию только если это компания-партнёр
 * @param companies_ids
 */
export async function searchCompanyByIds(companies_ids: string[] = []): Promise<any> {
  const url: string = '/company/search_by_ids';

  return http
    .get(url, {
      params: { companies_ids },
      cache: false,
      requestId: url,
    })
    .then((response) => response.data);
}

export async function setCompanyLogo(logoBase64: string | null) {
  const url: string = '/company/logo';
  const { data } = await http.post(url, { logo: logoBase64 }, { cache: false, requestId: url });
  return data;
}

/**
 * Получение информации об ускоренной оплате
 */
export async function getExpeditedPaymentInfo(): Promise<TExpeditedPayment> {
  const url: string = '/company/expedited_payments';
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Обновление информации об ускоренной оплате
 */
export async function updateExpeditedPaymentInfo(data: Partial<TExpeditedPayment>): Promise<TExpeditedPayment> {
  const url: string = '/company/expedited_payments';
  return http.put(url, data, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получить настройки компании
 */
export async function getCompanySettings(): Promise<any> {
  const url: string = 'company/settings';
  return http.get(url).then((response) => response.data);
}

/**
 * Обновить настройки компании
 * @param params
 */
export async function updateCompanySettings(params: any): Promise<any> {
  const url: string = 'company/settings';
  const { data } = await http.put(url, { ...params });
  return data;
}

/**
 * Принять оферту ДЛ-транса
 * @param companyId
 * @param params
 */
export async function confirmDltOffer(companyId, params): Promise<any> {
  const url: string = `company/${companyId}/dlt_offer`;
  const { data } = await http.post(url, { ...params });
  return data;
}

/**
 * Принять оферту ДЛ
 * @param companyId
 * @param params
 */
export async function confirmDlOffer(companyId, params): Promise<any> {
  const url: string = `company/${companyId}/dl_offer`;
  const { data } = await http.post(url, { ...params });
  return data;
}

/**
 * Проверить по ИНН наличие водителя в файле РСВ
 * @param inn
 */
export async function checkDriverContractByINN(inn: string): Promise<any> {
  const url: string = 'company/employee_verification';
  const params = { inn };
  const { data } = await http.get(url, { params, cache: false });
  return data;
}

/**
 * Проверить, закончился ли парсинг файла РСВ
 * @param fileId {String}
 */
export async function getIsWorkRelationsFileReady(fileId: string): Promise<any> {
  const url: string = 'company/employee_verification/file_ready';
  const params = { file_id: fileId };
  const { data } = await http.get(url, { params, cache: false });
  return !!data?.file_ready ? Promise.resolve() : Promise.reject();
}
