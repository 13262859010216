import React from 'react';
import classNames from 'classnames';

const listItemClasses = 'fs-12 leading-3 color-dark';
// const THEMES = {
//   DEFAULT: 'default',
//   SUCCESS: 'success',
//   DANGER: 'danger',
// };
enum THEMES {
  DEFAULT = 'default',
  SUCCESS = 'success',
  DANGER = 'danger',
}
const THEMES_MAP = {
  [THEMES.DEFAULT]: 'color-dark',
  [THEMES.SUCCESS]: 'color-success',
  [THEMES.DANGER]: 'color-danger',
};
interface IListItem {
  label: string;
  value?: any;
  bold?: boolean;
  theme?: THEMES;
  children?: any;
}
const ListItem = ({ label, value = null, bold = true, theme = THEMES.DEFAULT, children = null }: IListItem) => {
  if (children) return <span className={listItemClasses}>{children}</span>;

  const themeClasses = THEMES_MAP[theme] || THEMES_MAP[THEMES.DEFAULT];
  return (
    <span className={listItemClasses}>
      {label} {bold ? <b className={themeClasses}>{value}</b> : <span className={themeClasses}>{value}</span>}
    </span>
  );
};

const InlineList = ({ className = '', children }) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <div className={classNames('flex wrap', className)}>
      {childrenArray.map((child, index) => {
        return (
          <div className="flex" key={index}>
            {!!index && <span className="color-light ml-1 mr-1">&#8226;</span>}
            <span>{child}</span>
          </div>
        );
      })}
    </div>
  );
};

InlineList.Item = ListItem;
InlineList.themes = THEMES;

export default InlineList;
