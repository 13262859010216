import React from 'react';
import SVGIcon from './index';

const IconDragNDropV2 = (props: React.SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <SVGIcon {...props} viewBox="0 0 98 95">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9995 10H9.99927V22.0002H15.9994V16.0001H21.9995V10ZM36.9998 10H27.9996V16.0001H36.9998V10ZM42.9998 10H52V16.0001H42.9998V10ZM70.0005 10H58.0003V16.0001H64.0004V22.0002H70.0005V10ZM9.99927 27.9987H15.9994V39.9989H9.99927V27.9987ZM70.0004 27.9987H64.0002C64.0052 29.498 64.0027 30.9974 64.0002 32.4967V32.499C63.9977 33.999 63.9952 35.4989 64.0002 36.9988C60.007 36.9988 56.0138 36.9977 52.0206 36.9966L52 36.9966C43.9999 36.9944 35.9997 36.9922 27.9996 36.9988V84.9997H88.0007V36.9988C85.0006 36.9938 82.0006 36.9963 79.0005 36.9988C76.0005 37.0013 73.0004 37.0038 70.0004 36.9988C70.0054 35.4995 70.0029 34.0001 70.0004 32.5007V32.4984C69.9979 30.9985 69.9954 29.4986 70.0004 27.9987ZM33.9997 78.9996V42.9989H82.0006V78.9996H33.9997ZM9.99927 45.9992H15.9994V51.9993H21.9995V57.9994H9.99927V45.9992ZM63.5183 61.2597C59.6939 57.1691 55.8717 53.0809 52.0201 49.0221C51.9901 56.6898 52.0001 64.3474 52.0101 72.0151L52.0101 72.0225C52.671 71.3895 53.3335 70.758 53.9963 70.1263C54.9983 69.1712 56.0011 68.2155 57.0002 67.2525C57.4554 68.2079 57.9106 69.1658 58.3657 70.1237C58.8206 71.0808 59.2754 72.0379 59.7302 72.9926C60.1252 72.7526 60.72 72.3952 61.3149 72.0377C61.9101 71.6802 62.5052 71.3226 62.9003 71.0825C62.1503 69.3225 61.4103 67.5725 60.6702 65.8124C61.7255 65.6724 62.7807 65.5349 63.8359 65.3974C64.8907 65.2599 65.9455 65.1224 67.0004 64.9824C65.8389 63.7418 64.6785 62.5006 63.5183 61.2597Z"
      />
    </SVGIcon>
  );
};

IconDragNDropV2.defaultProps = {
  color: 'base',
};

export default IconDragNDropV2;
