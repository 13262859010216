import React from 'react';
import * as counterAgentsFormFields from '../store/counterAgents/formFields';
import * as userFormFields from '../containers/users/form/formFields';

export const errorsMapping = new Map()
  .set('unprocessable_geo_coordinates', {
    message: 'В настоящий момент организация перевозки по указанному маршруту не осуществляется',
  })
  .set('shipping_offer_expired', {
    message: 'invalidOffer',
  })
  .set('promo_code_missing_in_params', {
    message: 'В параметрах отсутствует промокод',
  })
  .set('promo_code_not_found', {
    message: 'Промокод не найден',
  })
  .set('promo_code_canceled', {
    message: 'Промокод отозван',
  })
  .set('promo_code_not_started', {
    message: 'Период действия промокода не начался',
  })
  .set('promo_code_ended', {
    message: 'Период действия промокода окончен',
  })
  .set('bik_invalid', {
    title: 'Введён неверный БИК',
    message: 'Скорректируйте БИК и сохраните информацию повторно.',
  })
  .set('claim_shipping_does_not_belong_to_tc', {
    message: 'Вы не являетесь исполнителем указанной заявки',
  })
  .set('claim_exists', {
    message: 'По указанной заявке уже создана претензия',
  })
  .set('claim_shipping_not_completed', {
    message: 'Заявка ещё не завершена',
  })
  .set('claim_shipping_expired', {
    message: 'Истёк срок давности для претензии',
  })
  .set('claim_shipping_completion_date_blank', {
    message: 'По заявке не завершено оформление рейса',
  })
  .set('claim_for_delivery_forbidden', {
    message: (
      <>
        Для заявок автодоставки нельзя создать претензию
        <br />
        Свяжитесь с менеджером грузовладельца
      </>
    ),
  })
  .set('claim_for_mkt_forbidden', {
    message: (
      <>
        Для заявок автодоставки нельзя создать претензию
        <br />
        Свяжитесь с менеджером грузовладельца
      </>
    ),
  })
  .set('email_taken', {
    fieldKey: userFormFields.EMAIL,
    message: 'Email уже зарегистрирован',
  })
  .set('username_taken', {
    fieldKey: userFormFields.LOGIN,
    message: 'Логин уже занят',
  })
  .set('company_not_approved', {
    fieldKey: userFormFields.FULL_NAME,
    message: 'Компания не допущена к платформе',
  })
  .set('invalid_role_for_company', {
    fieldKey: userFormFields.ROLE,
    message: 'Невалидная роль',
  })
  .set('invalid_file', {
    header: 'Файлы данного типа не поддерживаются',
    text: 'Пожалуйста, приложите файл с расширением xls или xlsx.',
  })
  .set('no_rows', {
    header: 'Загруженный шаблон не содержит данных',
    text: 'Пожалуйста, заполните шаблон и загрузите повторно.',
  })
  .set('too_many_rows', {
    header: 'Количество строк превышает 1 000',
    text: 'Пожалуйста, скорректируйте количество строк и отправьте файл на проверку повторно.',
  })
  .set('file_too_large', {
    header: 'Файл превышает 10 мб',
    text: 'Пожалуйста, загрузите файл нужного размера.',
  })
  .set('inn_taken', {
    fieldKey: counterAgentsFormFields.LEGAL_ENTITY_INN,
    message: 'Такой ИНН уже добавлен',
  })
  .set('kpp_taken', {
    fieldKey: counterAgentsFormFields.LEGAL_ENTITY_KPP,
    message: 'Такой КПП уже добавлен',
  })
  .set('inn_and_kpp_taken', {
    fieldKey: counterAgentsFormFields.LEGAL_ENTITY_INN,
    message: 'Такой ИНН и КПП уже добавлены',
  })
  .set('passport_number_taken', {
    fieldKey: counterAgentsFormFields.PASSPORT_NUMBER,
    message: 'Такой номер паспорта уже добавлен',
  })
  .set('no_current_bids', {
    title: 'Не удалось досрочно выбрать победителя, так как ставки были отменены',
    message: null,
  })
  .set('bid_not_current', {
    title: 'Не удалось досрочно выбрать победителя, так как ставка была отменена',
    message: null,
  })
  .set('unsuitable_order', {
    title: 'Заказ находится в неподходящем статусе',
    message: null,
  })
  .set('driver_license_error', {
    title: 'У водителя истёк срок действия ВУ',
    message: null,
  })
  .set('body_type_tonnage_mismatched', {
    title: 'Тип кузова не соответствует выбранному Типу транспорта',
    message: null,
  })

  /**
   * Терминальный окна
   */
  .set('date_validation_error', {
    title: 'Дата и время прибытия не могут быть в прошлом',
    message: null,
  })
  .set('duplicate_schedule_error', {
    title: 'На этот день уже есть расписание',
    message: 'Выберите другую дату',
  })
  .set('night_shift_is_banning', {
    title: 'Временной интервал недоступен',
    message: 'Выберите время в рамках одного дня',
  })
  .set('selected_interval_busy', {
    title: 'Временной интервал недоступен',
    message: 'Выберите другое время',
  })
  .set('invalid file format', {
    title: 'Неверный формат файла',
    message: null,
  })

  /**
   * Загрузка файла РСВ
   */
  .set('invalid rsv file format', {
    title: 'Неверный формат РСВ файла',
    message: 'Проверьте, что прикрепляете нужный файл .xml и попробуйте загрузить заново',
  })
  .set('could not find data about an accountable company', {
    title: 'Не найдены данные о компании',
    message: 'Проверьте РСВ файл на наличие информации и попробуйте загрузить заново',
  })
  .set('reporting company inn is not specified in file', {
    title: 'Не найден ИНН компании',
    message: 'Проверьте РСВ файл на наличие информации и попробуйте загрузить заново',
  })
  .set('file inn does not match with company inn', {
    title: 'ИНН в файле не соответствует ИНН вашей компании',
    message: 'Проверьте, что прикрепляете нужный файл .xml и попробуйте загрузить заново',
  })
  .set('could not find the section «document»', {
    title: 'Не удалось найти раздел «Документ» в загруженном файле',
    message: 'Проверьте РСВ файл на наличие информации и попробуйте загрузить заново',
  })
  .set('could not to determine «period» or «reporting year»', {
    title: 'Не удалось определить период или отчётный год',
    message: 'Проверьте РСВ файл на наличие информации и попробуйте загрузить заново',
  })

  .set('invalid file format', {
    title: 'Неверный формат файла',
    message: null,
  })

  .set('not_in_auction', {
    title: 'Заявка изъята из аукциона',
    message: null,
  });
export default errorsMapping;
