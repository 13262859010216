import React from 'react';
import SVGIcon from './index';

function IconSuggestions(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.125 2C11.125 2 11.68 3.86357 11.68 5.37553C11.68 6.82419 10.6675 7.99859 9.1225 7.99859C7.57 7.99859 6.4 6.82419 6.4 5.37553L6.4225 5.12236C4.9075 6.81013 4 8.99719 4 11.3741C4 14.4824 6.685 17 10 17C13.315 17 16 14.4824 16 11.3741C16 7.58369 14.0575 4.20113 11.125 2ZM9.78243 14.8903C8.44743 14.8903 7.36743 13.9058 7.36743 12.6821C7.36743 11.5429 8.15493 10.7412 9.47493 10.488C10.8024 10.2349 12.1749 9.63713 12.9399 8.6737C13.2324 9.58087 13.3824 10.5373 13.3824 11.5148C13.3824 13.3783 11.7699 14.8903 9.78243 14.8903Z"
      />
    </SVGIcon>
  );
}

IconSuggestions.defaultProps = { color: 'white' };

export default React.memo(IconSuggestions);
