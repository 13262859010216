import React, { useCallback } from 'react';
import { FieldProps } from 'formik';
import _isEqual from 'lodash/isEqual';

import InputHOC from '../inputHOC';
import { SimpleDropdown } from '../../DropdownCollection';
import { useFormikContextSelector } from '../FormikContext';

interface IOption {
  value: string;
  label: string;
}

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  options: Array<IOption>;
  label?: string;
  isMulti?: boolean;
  /* Возвращать только значение, а не опцию целиком */
  cleanValue?: boolean;
}

const FormDropdown = (props: IProps & FieldProps) => {
  const { form, field, cleanValue, options, isMulti, onChange, ...rest } = props;
  const { name, value } = field;
  const setFieldTouched = useFormikContextSelector((c) => c.setFieldTouched);
  const validateField = useFormikContextSelector((c) => c.validateField);

  const getFieldValue = useCallback(() => {
    if (cleanValue) {
      if (isMulti) {
        return value?.map((v) => options.find((item) => _isEqual(v, item.value)))?.filter(Boolean) ?? null;
      }
      return options.find((item) => _isEqual(value, item.value)) ?? null;
    }
    return value ?? null;
  }, [cleanValue, isMulti, options, value]);

  const handleChange = useCallback(
    (event) => {
      let fieldValue = event;
      if (cleanValue) {
        if (isMulti) {
          fieldValue = event.map((f) => f.value);
        } else {
          fieldValue = event.value;
        }
      }

      // @ts-ignore
      // TODO если есть нормализация поля - из за хока она не доходит, поэтому "эмулируем" эвент
      onChange?.({ target: { value: fieldValue, name } });

      // Без таймаута в валидацию попадают предыдущие значения
      setTimeout(() => {
        // TODO: добавить проверку на наличие валидации поля, если есть - то вызывать валидацию
        try {
          return validateField(name);
        } catch {
          console.warn(`Field "${name}" has no validation!`);
        }
      }, 0);
    },
    [cleanValue, onChange, name, isMulti, validateField],
  );

  const handleBlur = useCallback((e) => setFieldTouched(name, e, true), [setFieldTouched, name]);

  const acceptedProps = {
    ...rest,
    ...field,
    options,
    isMulti,
    value: getFieldValue(),
    onChange: cleanValue ? () => {} : handleChange,
    onBlur: handleBlur,
    onSelect: handleChange,
  };

  return <SimpleDropdown {...acceptedProps} />;
};

FormDropdown.defaultValue = {
  // Возвращать только value (для обратной совместимости форм заказа)
  cleanValue: false,
};

export default InputHOC(FormDropdown);
