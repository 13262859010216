import styles from './SVGIcons/styles.module.scss';

const THEMES = {
  baseTheme: {
    mainFill: styles.base,
    mainStroke: styles['stroke-base'],
    altFill: styles.white,
    altStroke: styles['stroke-white'],
  },
  primaryTheme: {
    mainFill: styles.primary,
    mainStroke: styles['stroke-primary'],
    altFill: styles.white,
    altStroke: styles['stroke-white'],
  },
  successTheme: {
    mainFill: styles.success,
    mainStroke: styles['stroke-success'],
    altFill: styles.white,
    altStroke: styles['stroke-white'],
  },
  warningTheme: {
    mainFill: styles.warning,
    mainStroke: styles['stroke-warning'],
    altFill: styles.white,
    altStroke: styles['stroke-white'],
  },
  dangerTheme: {
    mainFill: styles.danger,
    mainStroke: styles['stroke-danger'],
    altFill: styles.white,
    altStroke: styles['stroke-white'],
  },
};

export default function useMultiColorsIcons(theme) {
  return THEMES[theme] || THEMES.baseTheme;
}
