import http from '../../../services/config/webConfig';

/**
 *  Получить список полисов по ID перевозки
 *  @return {Promise<*>}
 */
export async function getPolicyByShippingId(shippingId) {
  const url = 'insurance/policy';
  const params = { shipping_id: shippingId };
  return http.get(url, { params, cache: false, requestId: url }).then((response) => response.data);
}

/**
 *  Отменить приобретение полиса
 *  @return {Promise<*>}
 */
export async function cancelPolicy(policyId) {
  const url = `/insurance/policy/${policyId}`;
  return http.delete(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 *  Отметить что есть своя страховка
 *  @return {Promise<*>}
 */

export async function setOwnPolicy(shippingId, hasPolicy = true) {
  const url = `/shippings/${shippingId}/external_insurance`;
  return http.post(url, { external_insurance: hasPolicy }, { cache: false, requestId: url });
}
