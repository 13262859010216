import React, { lazy, Suspense } from 'react';

import { CONTRACT_BY_COUNT, FORM_TYPE, FULL, ORDER_TYPE, SHIPPING_REQUEST, SIMPLE } from '../OrderNFormTypeBlock';

import { useFieldValue } from '../../../../components/FormikFieldsV3/hooks';
import ContentLoader from '../../../../components/ContentLoader';
import { useOrderContext } from '../../index';
import { getHashString } from '../../helpers/getHashString';

export const TONNAGE_VOLUME = 'tonnage_volume';
export const TRANSPORT_BODY_TYPES = 'transport_body_types';
export const RESOURCE_REQUIREMENTS = 'resource_requirements';
export const CMR_DOCUMENT = 'cmr_document';
export const COVERALLS = 'coveralls';
export const IS_DISINFECTED = 'is_disinfected';
export const MEDICAL_BOOK = 'medical_book';
export const BELTS = 'belts';
export const NUMBER_OF_BELTS = 'number_of_belts';
export const ORIGINAL_SHIPPING_POWER_OF_ATTORNEY = 'original_shipping_power_of_attorney';
export const OWN_TRANSPORT_ONLY = 'own_transport_only';
export const REMOVABLE_SIDE_RACKS = 'removable_side_racks';
export const REMOVABLE_UPPER_BEAM = 'removable_upper_beam';
export const RIGID_BOARD = 'rigid_board';
export const RUSSIAN_CITIZENSHIP = 'russian_citizenship';
export const TEMPERATURE_CHECK = 'temperature_check';
export const TEMPERATURE_CONDITION = 'temperature_condition';
export const TEMPERATURE_CONDITION_MIN = 'temperature_condition_min';
export const TEMPERATURE_CONDITION_MAX = 'temperature_condition_max';
export const TIMBER_BUNKS = 'timber_bunks';
export const TIR_DOCUMENT = 'tir_document';
export const TWO_SIDED = 'two_sided';
export const DISABLE_AUTO_CHANGES = 'disable_auto_changes';

/* Технические поля */
export const SHOW_RESOURCE_REQUIREMENTS_BLOCK = 'show_resource_requirements_block';

const ShippingRequestTransportBlock = lazy(() => import('./ShippingRequestTransportBlock'));

function getBlockComponent(hashString) {
  switch (hashString) {
    // спот
    case getHashString({ isOrderBasedOnContract: false, orderType: SHIPPING_REQUEST, formType: FULL }):
    case getHashString({ isOrderBasedOnContract: false, orderType: SHIPPING_REQUEST, formType: SIMPLE }):
      return ShippingRequestTransportBlock;
    // контракт
    case getHashString({ isOrderBasedOnContract: false, orderType: CONTRACT_BY_COUNT, formType: FULL }):
    case getHashString({ isOrderBasedOnContract: false, orderType: CONTRACT_BY_COUNT, formType: SIMPLE }):
      return ShippingRequestTransportBlock;
    // заявка по контракту + полная форма
    case getHashString({ isOrderBasedOnContract: true, orderType: SHIPPING_REQUEST, formType: FULL }):
      return ShippingRequestTransportBlock;
    default:
      return null;
  }
}

/**
 * Блок: Транспорт и требования
 */
export function TransportBlock() {
  const [formType] = useFieldValue(FORM_TYPE);
  const [orderType] = useFieldValue(ORDER_TYPE);
  const { isOrderBasedOnContract } = useOrderContext();

  const hashString = getHashString({ isOrderBasedOnContract, orderType, formType });
  const Component = getBlockComponent(hashString);
  return <Suspense fallback={<ContentLoader withBackground />}>{Component ? <Component /> : null}</Suspense>;
}
