import classNames from 'classnames';
import styles from '../../components/DataListCollections/styles.module.scss';
import React from 'react';
import { CONTRACT_BY_COUNT, CONTRACT_WITH_QUOTES } from '../../store/order/routes/formKeys';

// костыли в торге:
function isAdditionalAuctionInfo(auctionStage) {
  // проверка, что данный этап торга это настройки доп. этапа
  // торга заявок квотного контракта
  return ((auctionStage?.added_stage || auctionStage?.addedStage) && !auctionStage?.quota) || false;
}
export function getAdditionalAuctionStage(auctionStages) {
  // поиск настроек доп. этапа торга
  return auctionStages.find(isAdditionalAuctionInfo);
}
export function contractAuctionStagesFilter(auctionStage) {
  // фильтруем служебные этапы торга
  // visibility === 'quotas' - этапы торга контракта на нескольких исполнителей
  return !(isAdditionalAuctionInfo(auctionStage) || auctionStage.visibility === 'quotas');
}

// бейджи по типам котрактов:
const BADGE_THEMES = {
  light: {
    additionalClass: 'color-dark bg-color-lighter',
  },
  dark: {
    additionalClass: 'color-lightest bg-color-base',
  },
};
const Badge = (params) => {
  const { theme = {}, text, className } = params;

  const themeAttributes = Object.assign({}, theme);
  delete themeAttributes.additionalClass;

  return (
    <div className={classNames(styles.badge, theme.additionalClass, className)} {...themeAttributes}>
      <div>{text}</div>
    </div>
  );
};
export const getContractBadge = (contractType, params = {}) => {
  if (contractType === CONTRACT_WITH_QUOTES) return <Badge theme={BADGE_THEMES.light} text="Квота" {...params} />;
  if (contractType === CONTRACT_BY_COUNT) return <Badge theme={BADGE_THEMES.dark} text="Гарантия" {...params} />;
  return null;
};
