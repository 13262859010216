import React from 'react';
import SVGIcon from './index';
import colors from '../../../styles/colors.scss';

function IconArrowUp(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6.70154L14.2075 10L15.5 8.66802L10 3L4.5 8.66802L5.7925 10L9 6.70154L9 17L11 17L11 6.70154Z"
        fill={colors[props.color!]}
      />
    </SVGIcon>
  );
}

IconArrowUp.defaultProps = {
  color: 'primary',
};

export default IconArrowUp;
