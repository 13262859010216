import React, { ChangeEvent, useCallback } from 'react';
import classNames from 'classnames';
import { FieldProps } from 'formik';

import styles from '../styles.module.scss';

import Popover from '../../Popover';
import Checkbox from '../../Checkbox';
import { IconHelpOutline } from '../../Icons';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  labelHelp?: string | React.ReactNode;
  labelHelpClassName?: string;
  labelClassName?: string;
  onChangeCallback?: (event: ChangeEvent<HTMLInputElement>) => void;
}

function FormCheckbox(props: IProps & FieldProps) {
  const { field, label, labelHelp, labelClassName, labelHelpClassName, onChangeCallback, ...acceptProps } = props;

  const onChangeHandler = useCallback(
    (e) => {
      field.onChange && field.onChange(e);
      onChangeCallback && onChangeCallback(e);
    },
    [field, onChangeCallback],
  );

  return (
    <label className={classNames(styles.label, styles.labelCheckbox, labelClassName)}>
      <Checkbox
        {...field}
        {...acceptProps}
        value={field.value ?? false}
        checked={field.value ?? false}
        onChange={onChangeHandler}
      />
      {!!label && <span className="pl-2 mr-1 cursor-pointer">{label}</span>}
      {!!labelHelp && (
        <Popover
          trigger="hover"
          placement="top"
          content={labelHelp}
          className={classNames(styles.labelPopover, labelHelpClassName)}
          usePortal
        >
          <IconHelpOutline className={classNames(styles.labelIcon, styles.checkBoxIcon)} />
        </Popover>
      )}
    </label>
  );
}

export default FormCheckbox;
