import * as types from './actionTypes';
// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';
import { TNoticesStore } from './models';

const initialState: TNoticesStore = {
  items: [],
  itemsHeader: [],
  totalUnread: 0,
};

const reducer = produce((draft, action = {}) => {
  switch (action.type) {
    case types.SET_ITEMS:
      draft.items = action.payload.content;
      break;
    case types.LOAD_MORE_ITEMS:
      draft.items = [...draft.items, ...action.payload.content];
      break;
    case types.SET_HEADER_ITEMS:
      draft.itemsHeader = action.payload.content;
      draft.totalUnread = action.payload.totalElements;
      break;
    case types.MARK_ITEM_AS_READ:
      draft.items = draft.items.map((obj) => {
        if (obj.id === action.payload.id) {
          return action.payload;
        }
        return obj;
      });
      draft.itemsHeader = draft.itemsHeader.map((obj) => {
        if (obj.id === action.payload.id) {
          return action.payload;
        }
        return obj;
      });
      break;
    // Не сделать запрос из-за infinite scroll (к моменту нажатия на кнопку может быть загружено несколько страниц)
    case types.MARK_ALL_ITEMS_AS_READ:
      draft.items = draft.items.map((obj) => {
        return { ...obj, opened_time: obj.opened_time ? obj.opened_time : new Date().toISOString() };
      });
      draft.itemsHeader = draft.itemsHeader.map((obj) => {
        return { ...obj, opened_time: obj.opened_time ? obj.opened_time : new Date().toISOString() };
      });
  }
}, initialState);

export default reducer;
