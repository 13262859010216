import React from 'react';
import SVGIcon from './index';

function IconCircleRouble(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9.00203 5.50335C8.59288 5.50615 8.18378 5.50895 7.7749 5.50389C7.77308 6.29469 7.77334 7.08549 7.7736 7.87629C7.77379 8.46936 7.77399 9.06243 7.77331 9.6555H6.50209C6.49891 10.0914 6.49891 10.5256 6.50527 10.9598C6.92689 10.9581 7.3501 10.9581 7.77331 10.9598V11.7313H6.5005V12.5962H7.77171C7.77353 12.959 7.77327 13.3212 7.77301 13.6832C7.77282 13.9547 7.77262 14.226 7.77331 14.4973C8.27925 14.5008 8.7836 14.5025 9.28795 14.4921C9.28476 13.8607 9.28317 13.2294 9.28635 12.5962C9.52501 12.5955 9.76337 12.5957 10.0017 12.5959C10.3194 12.5962 10.6371 12.5965 10.9553 12.5945V11.733C10.6371 11.7311 10.319 11.7313 10.0008 11.7316C9.7621 11.7318 9.52343 11.7321 9.28476 11.7313C9.28635 11.4736 9.28635 11.2177 9.28635 10.96V10.9598C9.53187 10.9495 9.78344 10.9531 10.0369 10.9568C10.8854 10.9691 11.7548 10.9818 12.4875 10.4703C13.4993 9.78351 13.7094 8.24568 13.31 7.10226C12.9791 6.14047 12.0468 5.57135 11.1319 5.52984C10.4223 5.49362 9.7121 5.49848 9.00203 5.50335ZM9.79862 9.6548C9.62561 9.65144 9.45267 9.64809 9.27999 9.64858C9.28418 9.14965 9.28396 8.65119 9.28374 8.15296C9.28354 7.70458 9.28334 7.25638 9.28635 6.80819C9.50153 6.81373 9.71732 6.81007 9.93313 6.8064C10.3237 6.79978 10.7143 6.79315 11.1017 6.84105C12.3236 7.06247 12.3331 9.43581 11.0858 9.62091C10.6582 9.67147 10.2282 9.66313 9.79862 9.6548Z"
        fill="#98A3B3"
      />
    </SVGIcon>
  );
}

IconCircleRouble.defaultProps = {
  color: 'base',
};

export default React.memo(IconCircleRouble);
