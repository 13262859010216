import React from 'react';
import SVGIcon from './index';

export default function IconPin(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 8C4 4.904 6 2 10 2C14 2 16 4.904 16 8C16 13.4 10 18 10 18C10 18 4 13.4 4 8ZM8 8C8 9.104 8.896 10 10 10C11.104 10 12 9.104 12 8C12 6.896 11.104 6 10 6C8.896 6 8 6.896 8 8Z"
      />
    </SVGIcon>
  );
}

IconPin.defaultProps = {
  color: 'base',
};
