import moment, { Moment } from 'moment';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import {
  DATE_FORMAT,
  DATE_FORMAT_MONTH_NAME,
  LONG_DATE_TIME_FORMAT,
  SHORT_DATE_FORMAT,
  SHORT_DATE_TIME_FORMAT,
} from '../constants';

/**
 * Подмена часового пояса
 * @param dateTime {Object} - moment объект дата время
 * @param timeZone {Number} - часовой пояс
 * @return {Object} - moment объект с новым часовым поясом
 */
export function replaceTimeZone(dateTime: moment.Moment, timeZone: number): moment.Moment {
  return dateTime.clone().utcOffset(0, true).utcOffset(timeZone, true);
}

/**
 * Создание объекта дата время по дате, времени и часовому поясу
 * @param date {String} - строка с датой
 * @param time {String} - строка с временем
 * @param timeZone {Number} - часовой пояс
 * @param formatDate {String} - строка с форматом даты
 * @param formatTime  - строка с форматом времени
 * @return {Object} - moment объект
 */
export function createDateTime(
  date: string,
  time: string,
  timeZone: number,
  formatDate: string,
  formatTime: string,
): moment.Moment {
  return replaceTimeZone(moment(`${date} ${time}`, `${formatDate} ${formatTime}`), timeZone);
}

/**
 * Конвертация строки даты (дата времени) в короткий формат даты
 * @param date {String} - строка дата (дата время)
 * @return {string} - строка с датой в коротком формате
 */
export function convertToShortDate(date: string): string {
  return moment(date).format(SHORT_DATE_FORMAT);
}

/**
 * Конвертация строки даты (дата времени) в короткий формат даты, но c полным годом
 * @param date {String} - строка дата (дата время)
 * @return {string} - строка с датой в коротком формате
 */
export function convertToShortDateWithFullYear(date: string): string {
  return moment(date).format(DATE_FORMAT);
}
/**
 * Конвертация строки utc даты (дата времени) с заданным часовым поясом в короткий формат даты, но c полным годом
 * @param date {String} - строка дата (дата время) в utc
 * @param timezone {number} - offset часого пояса в часах или минутах
 * @return {string} - строка с датой в коротком формате
 */
export function convertUtcToShortDateWithFullYear(date: string, timezone: number): string {
  return moment.utc(date).utcOffset(timezone).format(DATE_FORMAT);
}

/**
 * Конвертация строки даты (дата времени) в длинный формат даты и времени с секундами
 * @param date {String} - строка дата (дата время)
 * @return {string} - строка с датой в коротком формате
 */
export function convertToLongDateWithSeconds(date: string): string {
  return moment(date).format(LONG_DATE_TIME_FORMAT);
}

/**
 * Конвертация строки даты (дата времени) в короткий формат даты времени
 * @param date {String} - строка дата (дата время)
 * @return {string} - строка с датой в коротком формате
 */
export function convertToShortDateTime(date: string): string {
  return moment(date).format(SHORT_DATE_TIME_FORMAT);
}
/**
 * Конвертация строки utc даты (дата времени) в короткий формат даты времени в локальном часовом поясе
 * @param date {String} - строка дата (дата время) в utc
 * @return {string} - строка с датой в коротком формате
 */
export function convertUtcToLocalShortDateTime(date: string): string {
  return moment.utc(date).local().format(SHORT_DATE_TIME_FORMAT);
}
/**
 * Конвертация строки utc даты (дата времени) в короткий формат даты времени с заданным часовым поясом
 * @param date {String} - строка дата (дата время) в utc
 * @param timezone {number} - offset часого пояса в часах или минутах
 * @return {string} - строка с датой в коротком формате
 */
export function convertUtcToShortDateTime(date: string, timezone: number): string {
  return moment.utc(date).utcOffset(timezone).format(SHORT_DATE_TIME_FORMAT);
}

/**
 * Конвертация строки даты (дата времени) в другой формат
 * @param date {String} - строка дата (дата время)
 * @param dateFormat {String} - формат
 * @return {string} - строка с датой в коротком формате
 */
export function convertDateStringToAnotherFormat(date: string, fromDateFormat: string, toDateFormat: string): string {
  return format(parse(date, fromDateFormat, new Date()), toDateFormat);
}

/**
 * Конвертация строки даты (дата времени) в формат даты времени c названием месяца прописью
 * @param date {String} - строка дата (дата время)
 * @return {string} - строка с датой c названием месяца
 */
export function convertToDateTimeWithMonthName(date) {
  return moment(date).format(DATE_FORMAT_MONTH_NAME);
}

/**
 * Получение объекта moment по строке
 * @param dateString {String} - строка дата/время
 * @param dateFormat {String} - формат строки даты/время
 * @return {moment.Moment|null}
 */
export function dateByString(dateString: string, dateFormat: string): moment.Moment | null {
  const date = moment(dateString, dateFormat, true);
  if (date.isValid()) {
    return date;
  }
  return null;
}

/**
 * Получение значения минут из строки времени
 * @param timeString {String} - строка время вида "HH:mm"
 * @param dateFormat {String} - формат строки даты/время
 * @return {number} - значение количества минут
 */
export function minutesValueFromTimeStr(timeString?: string | Moment, dateFormat?: string): number {
  const m = moment.utc(timeString, dateFormat);
  return m.hours() * 60 + m.minutes();
}

/**
 * Конвертация DD.MM.YY в DD.MM.YYYY
 */
export function convertLongToShortDate(date: string) {
  return moment(date, `DD.MM.YYYY`).format(SHORT_DATE_FORMAT);
}
