import { useEffect, useRef } from 'react';

import { useFormikContextSelector } from '../../../components/FormikFieldsV3/FormikContext';
import { IS_ROUTE_BLOCK_OPENED, ROUTE_POINTS } from '../blocks/RoutesBlock';
import { getIn } from 'formik';

function useExpandBlocksOnError() {
  const submitCount = useFormikContextSelector((c) => c.submitCount);
  const refSubmit = useRef<number>(submitCount);
  const errors = useFormikContextSelector((c) => c.errors);
  const setFieldValue = useFormikContextSelector((c) => c.setFieldValue);

  const routePointsErrors = getIn(errors, ROUTE_POINTS);

  // Для блока маршрута
  useEffect(() => {
    if (submitCount !== refSubmit.current) {
      refSubmit.current = submitCount;

      routePointsErrors?.forEach((routePointError, index) => {
        if (routePointError) setFieldValue(`${ROUTE_POINTS}[${index}].${IS_ROUTE_BLOCK_OPENED}`, true);
      });
    }
  }, [routePointsErrors, setFieldValue, submitCount]);

  // На будущее: для блока аукциона
  useEffect(() => {}, []);
}

export { useExpandBlocksOnError };
