import { useState } from 'react';

export function useKeys(keyItemNames) {
  if (!Array.isArray(keyItemNames)) throw new Error('keyItemNames shoud be an array!');

  const ANY = '*';
  const SEPARATOR = '_';
  const [typesMap, setTypesMap] = useState({});

  const getKey = (...attrs) => {
    const keysNumber = Array.isArray(attrs) ? attrs.length : Object.keys(attrs).length;
    if (keysNumber !== keyItemNames.length) throw new Error('Invalid number of key items!');

    const items = Array.isArray(attrs) ? attrs : keyItemNames.map((key) => attrs[key]);
    const map = {};
    const key = items.reduce((acc, item, i) => {
      map[keyItemNames[i]] = typeof item;
      return (acc += (!i ? '' : SEPARATOR) + item);
    }, '');
    setTypesMap(map);
    return key;
  };
  const isKeyMatched = (keyForCheck, targetKey) => {
    const checkingKeyItems = keyForCheck.split(SEPARATOR);
    const targetKeyItems = targetKey.split(SEPARATOR);

    if (checkingKeyItems.length !== keyItemNames.length) throw new Error('Invalid number of checked key items!');
    if (targetKeyItems.length !== keyItemNames.length) throw new Error('Invalid number of target key items!');

    return !targetKeyItems.find((keyItem, index) => {
      if (keyItem === ANY) return false;
      return keyItem !== checkingKeyItems[index];
    });
  };
  const getKeyItemValue = (key, keyItemName) => {
    const items = key.split(SEPARATOR);
    if (items.length !== keyItemNames.length) throw new Error('Invalid number of key items!');

    const index = keyItemNames.findIndex((name) => name === keyItemName);
    if (index === -1) throw new Error('No such key item name!');

    switch (typesMap[keyItemName]) {
      case 'string':
        return items[index];
      case 'undefined':
        return items[index];
      default:
        return JSON.parse(items[index]);
    }
  };

  const methods = {
    getKey,
    isKeyMatched,
    getKeyItemValue,
  };

  return {
    anyKeyItem: ANY,
    methods,
  };
}
