import http from '../config/webConfig';
import pageListMapper from '../../helpers/mappers/pageListMapper';

/**
 * Работа с черновиками:
 * Создать черновик заказа
 */
export async function createDraft(params) {
  const url = `/shipping_orders/draft`;
  const { data } = await http.post(url, { ...params });
  return data;
}
/**
 * Обновить черновик заказа
 */
export async function updateDraft(id, params) {
  const url = `/shipping_orders/${id}`;
  const { data } = await http.put(url, { ...params });
  return data;
}
/**
 * Опубликовать черновик заказа
 * @param id
 * @returns {Promise<*>}
 */
export async function publishDraft(id) {
  const url = `/shipping_orders/${id}/publish`;
  const { data } = await http.post(url, null);
  return data;
}
/**
 * Удалить черновик заказа
 * @param orderId
 * @return {*}
 */
export async function deleteOrderDraft(orderId) {
  const url = `/shipping_orders/${orderId}`;
  return http.delete(url);
}

/**
 * Создание заказа
 * @param params
 * @returns {Promise<*>}
 */
export async function saveOrder(params) {
  const url = '/shipping_orders';
  const { data } = await http.post(url, { ...params });
  return data;
}

/**
 * Отправка изменений требований по заявке
 */

export async function changeOrder(id, params) {
  const url = `/shippings/${id}/change_requests`;
  return http.post(url, { ...params }).then(({ data }) => data);
}

/**
 * Получение заказа по идентификатору
 * @param orderId
 * @return {Promise<*>}
 */
export async function getOrder(orderId) {
  const url = `/shipping_orders/${orderId}`;
  const { data } = await http.get(url, { cache: false, requestId: url });
  return data;
}

export async function getOrderList(params = {}, config = {}) {
  const url = '/shipping_orders';
  return http.get(url, { params, cache: false, requestId: url, ...config }).then(pageListMapper);
}

export async function getOrderListXls(params = {}) {
  const url = '/shipping_orders/report';
  return http
    .get(url, { params, responseType: 'blob', cache: false, requestId: url })
    .then((response) => response.data);
}

/**
 * Отмена заявки
 * @param orderId
 * @param params
 * @return {*}
 */
export async function cancelOrder(orderId, params = {}) {
  const url = `/shipping_orders/${orderId}/cancel`;
  return http.post(url, { ...params }).then((response) => response.data);
}

/**
 * Перенос заявки а архив
 * @param orderId
 * @param params
 * @return {*}
 */
export async function moveToArchive(orderId, params = {}) {
  const url = `/shipping_orders/${orderId}/archive`;
  return http.post(url, { ...params }).then((response) => response.data);
}

/**
 * Подтверждение перевозки грузовладельцем
 * @param shippingId
 * @param completed_at
 * @returns {Promise<*>}
 */
export async function confirmShipping(shippingId, completed_at) {
  const url = `/shippings/${shippingId}/done`;
  const { data } = await http.post(url, completed_at, { cache: false });
  return data;
}

/**
 * Дополнительные услуги в точке маршрута
 * @returns {Promise<*>}
 */
export async function getAdditionalServices() {
  const url = `/shipping_orders/additional_services`;
  const { data } = await http.get(url);
  return data;
}

/**
 * Получаем значения прикрепленных ресурсов
 * @param shippingId
 * @returns {Promise<*>}
 */
export async function getAssignedResourcesByShippingId(shippingId) {
  const url = `/shippings/${shippingId}/assigned_resources`;
  const { data } = await http.get(url, { cache: false });
  return data;
}

/**
 * Получить список ставок на контракт (ГВ)
 * @param contractId
 * @returns {Promise<*>}
 */
export async function getContractBids(contractId) {
  const url = `/shipping_orders/${contractId}/bids`;
  const { data } = await http.get(url, { cache: false });
  return data;
}

/**
 * Указать победителя контракта
 * @param contractId
 * @param bidId
 * @returns {Promise<*>}
 */
export async function setContractWinner(contractId, bidId) {
  const url = `/shipping_orders/${contractId}/winners`;
  const params = { bid_id: bidId };
  const { data } = await http.post(url, params, { cache: false });
  return data;
}

/**
 * Указать победителя контракта с квотами
 * @param bidId
 * @param params
 * @returns {Promise<*>}
 */
export async function setQuotesContractWinner(bidId, params) {
  const url = `/contracts/quotas/${bidId}/winners`;
  const { data } = await http.post(url, params, { cache: false });
  return data;
}

/**
 * Получить информацию по заявкам квотного контракта
 * @param contractId
 * @returns {Promise<*>}
 */
export async function getQuotaContractOrdersInfo(contractId) {
  const url = `/contracts/quotas/${contractId}/info`;
  const { data } = await http.get(url, { cache: false });
  return data;
}

/**
 * Получить список исполнителей контракта
 * @param orderId
 * @param params
 * @returns {Promise<*>}
 */
export async function getContractExecutors(orderId, params = {}) {
  const url = `/shipping_orders/${orderId}/executors`;
  const { data } = await http.get(url, { params, cache: false });
  return data;
}

/**
 * Досрочно выбрать победителя аукциона
 * @param orderId
 * @returns {Promise<*>}
 */
export async function setEarlyWinner(orderId) {
  const url = `/shipping_orders/${orderId}/winners`;
  const { data } = await http.post(url, null);
  return data;
}

/**
 * Создание целевого предложения
 * @param params
 * @returns {Promise<*>}
 */
export async function createPersonalOffer(params) {
  const url = '/order_offers';
  const { data } = await http.post(url, { ...params });
  return data;
}

/**
 * Получение ссылки на комнату в чате
 * @param shippingId
 * @returns {Promise<*>}
 */
export async function getChatRoom(shippingId) {
  const url = '/chats';
  const params = {
    entity_id: shippingId,
  };
  try {
    const { data } = await http.post(url, null, { params, cache: false });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Ответить на массовую рассылку
 * @param id
 * @returns {Promise<*>}
 */
export async function replyOnBroadcast(id) {
  const url = `/chats/broadcast/${id}/reply`;
  try {
    const { data } = await http.post(url, null, { cache: false });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Обновление флага разрешения автосогласования для заявки
 * @param id
 * @returns {Promise<*>}
 */
export async function updateResourcesAutoConfirm(id, value) {
  const url = `/shipping_orders/${id}/disable_auto_changes`;
  const { data } = await http.post(url, { disable_auto_changes: value });
  return data;
}

/**
 * Получение списка ВСЕХ водителей по ВСЕМ заказам данного ГВ
 * @returns {Promise<*>}
 */
export async function getOrdersDrivers() {
  const url = `/shipping_orders/drivers_for_all_shipping`;
  return http.get(url).then((response) => response.data);
}

/**
 * Получение списка ВСЕХ исполнителей по ВСЕМ заказам данного ГВ
 * @returns {Promise<*>}
 */
export async function getOrdersExecutors() {
  const url = `/shipping_orders/all_executors`;
  return http.get(url).then((response) => response.data);
}

/**
 * перевести shipping_order в черновики
 * @param id
 * @return {*}
 */
export function mutateOrderToDraft(id) {
  return http.put(`shipping_orders/${id}/draft `);
}
