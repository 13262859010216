import _some from 'lodash/some';

export function getCargoInfo(item) {
  const additionalInfo = item.additionalInfo;

  if (!_some(additionalInfo, Boolean)) return null;

  return {
    tonnage: additionalInfo.cargoTonnage,
    volume: additionalInfo.cargoVolume,
    description: additionalInfo.cargoDescription,
    cost: additionalInfo.cargoCost,
    dimensions: additionalInfo.dimensions,
    packing: additionalInfo?.cargoPacking?.label,
    places: additionalInfo.cargoPlaces,
    hazardClass: additionalInfo?.cargoAdr,
  };
}
