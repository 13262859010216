import React from 'react';
import SVGIcon from './index';

function IconUnlockOutline(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1444 2.00291C9.1728 1.96672 8.19412 2.2691 7.40526 2.84624C6.48705 3.50863 5.8285 4.52815 5.5932 5.6472C5.89955 5.71344 6.20553 5.78156 6.51149 5.84967C6.69378 5.89025 6.87606 5.93083 7.05841 5.97101C7.29184 4.82577 8.20255 3.84577 9.33591 3.60005C10.181 3.4072 11.1105 3.58434 11.8103 4.10958C12.3779 4.53005 12.7772 5.17005 12.9244 5.86624C13.0068 6.22617 13.0034 6.5949 13 6.96265C12.9989 7.08631 12.9978 7.20985 12.9999 7.33291C11.9937 7.33367 10.9874 7.33337 9.98124 7.33306C8.4719 7.33261 6.96263 7.33215 5.45352 7.33529C4.69139 7.34434 4.01456 8.0291 4.00284 8.80339C3.99944 10.3425 3.99999 11.8816 4.00053 13.4207C4.00089 14.4244 4.00124 15.4282 4.0005 16.432C3.98878 16.8796 4.1833 17.3258 4.51703 17.6181C4.79732 17.872 5.17323 18.0086 5.54821 17.9996H14.4538C14.7951 18.0067 15.1377 17.8953 15.4072 17.682C15.7719 17.4005 15.9997 16.9443 15.9987 16.4781C16.0002 14.8551 16 13.232 15.9998 11.609C15.9996 10.7069 15.9995 9.80488 15.9997 8.90291C16.0076 8.52243 15.8736 8.14148 15.6242 7.85672C15.3458 7.52815 14.9258 7.33672 14.4998 7.33053C14.4982 7.20746 14.4989 7.08417 14.4997 6.96082C14.5018 6.6074 14.504 6.25356 14.4515 5.90339C14.3029 4.83815 13.7653 3.83624 12.9671 3.12958C12.1932 2.43529 11.1761 2.03101 10.1444 2.00291ZM5.5004 16.4758V8.85672H14.4998V16.4758H5.5004ZM11.5087 12.5228C11.4539 11.7023 10.6484 11.0385 9.84197 11.1565C9.07533 11.2323 8.45339 11.9827 8.52317 12.7611C8.55643 13.5675 9.31792 14.2449 10.1127 14.1734C10.9266 14.1412 11.6047 13.3424 11.5087 12.5228Z"
      />
    </SVGIcon>
  );
}

IconUnlockOutline.defaultProps = {
  color: 'base',
};

export default React.memo(IconUnlockOutline);
