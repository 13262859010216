import React from 'react';
import SVGIcon from './index';

function IconDocument(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7.37494L11.7693 3H5V17H16V7.37494ZM14 13H7V14H14V13ZM7 11H14V12H7V11ZM13 6L11 4V8H15L13 6Z"
      />
    </SVGIcon>
  );
}

IconDocument.defaultProps = { color: 'base' };

export default React.memo(IconDocument);
