import http from './config/webConfig';

import pageListMapper from '../helpers/mappers/pageListMapper';
import { IPartnershipContract, IPartnershipSubcontract } from '../containers/partners/pages/contracts/models';
import { TExpeditedPaymentStatuses } from '../containers/partners/models';

/**
 * Получение списка партнеров
 * @param params
 * @param cache
 * @return {Promise<*>}
 */
export async function getPartnersList(params: any = {}, cache = false): Promise<any> {
  const url: string = '/partnerships';
  return http.get(url, { params, cache, requestId: url }).then(pageListMapper);
}

/**
 * Получение одного партнера
 * @param partnershipId
 * @return {Promise<*>}
 */
export async function getPartner(partnershipId: string): Promise<any> {
  const url: string = `/partnerships/${partnershipId}`;
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Создание запроса на партнерские отношения
 * @param companyId
 * @return {Promise<*>}
 */
export async function createPartnership(companyId: string): Promise<any> {
  const url: string = `/partnerships`;
  const params = { company_id: companyId };
  return http.post(url, params, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Принять запрос на партнерские отношения
 * @param partnershipId
 * @return {Promise<*>}
 */
export async function acceptPartnership(partnershipId: string): Promise<any> {
  const url: string = `/partnerships/${partnershipId}/accept`;
  return http.post(url, {}, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Отклонить запрос на партнерские отношения
 * @param partnershipId
 * @return {Promise<*>}
 */
export async function rejectPartnership(partnershipId: string): Promise<any> {
  const url: string = `/partnerships/${partnershipId}/reject`;
  return http.post(url, {}, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Отменить запрос на партнерские отношения
 * @param partnershipId
 * @return {Promise<*>}
 */
export async function cancelPartnership(partnershipId: string): Promise<any> {
  const url: string = `/partnerships/${partnershipId}/cancel`;
  return http.post(url, {}, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Поиск компаний для заключения партнёрства
 * @param searchString
 * @return {Promise<*>}
 */
export async function searchPartners(searchString: string): Promise<any> {
  const url: string = `/partnerships/companies`;
  const params = { q: searchString };
  return http.get(url, { params, cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получение списка групп партнеров
 * @return {Promise<*>}
 */
export async function getPartnersGroupsList(params: any = {}, cache = false): Promise<any> {
  const url: string = `/partnerships/groups`;
  return http.get(url, { params, cache, requestId: url }).then((response) => response.data);
}

/**
 * Получение группы по ид
 * @param groupId
 * @return {Promise<*>}
 */
export async function getPartnersGroup(groupId: string): Promise<any> {
  const url: string = `/partnerships/groups/${groupId}`;
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 *  Создание парнерской группы
 */
export async function createPartnersGroups(payload: { title: string; partnerships_ids: string[] }): Promise<any> {
  const url = `/partnerships/groups`;
  return http.post(url, payload, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Обновление группы по ид
 * @param groupId
 * @param data
 * @return {Promise<*>}
 */
export async function updatePartnersGroup(
  groupId: string,
  data: { title: string; partnerships_ids: string[] },
): Promise<any> {
  const url = `/partnerships/groups/${groupId}`;
  return http.put(url, data, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Удаление группы по ид
 * @param groupId
 * @return {Promise<*>}
 */
export async function removePartnersGroup(groupId: string): Promise<any> {
  const url: string = `/partnerships/groups/${groupId}`;
  return http.delete(url, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Добавить компанию в чёрный список
 * @param companyId
 * @return {Promise<*>}
 */
export async function addToBlackList(companyId: string): Promise<any> {
  const url: string = `/partnerships/blacklist`;
  const params = { company_id: companyId };
  return http.post(url, params, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Убрать компанию из чёрного списка
 * @param companyId
 * @return {Promise<*>}
 */
export async function removeFromBlackList(companyId: string): Promise<any> {
  // cancel вместо unblacklist
  const url: string = `/partnerships/${companyId}/cancel`;
  return http.post(url, {}, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Задать группы партнерству
 * @param partnershipId
 * @param groups
 * @return {Promise<*>}
 */
export async function setPartnerGroups(partnershipId: string, groups: string[]): Promise<any> {
  const url: string = `/partnerships/${partnershipId}`;
  return http.put(url, { groups_ids: groups }, { cache: false, requestId: url }).then((response) => response.data);
}

export async function searchGroups(searchString: string): Promise<any> {
  const url = `/partnerships/groups`;
  const params = { q: searchString };
  return http.get(url, { params, cache: false, requestId: url }).then((response) => response.data);
}

export async function getCompaniesAndGroupsByIds(companies_ids, groups_ids): Promise<any> {
  const url = `/partnerships/search_by_ids`;
  const params = { companies_ids, groups_ids };
  const { data } = await http.post(url, params, { cache: false, requestId: url });
  return data;
}

/* Партнерские договоры */
export async function getContractList(params): Promise<{ items: IPartnershipContract[] }> {
  const url = `/partnership_contracts`;
  return http.get(url, { params, cache: false, requestId: url }).then((response) => response.data);
}

export async function createContract(data): Promise<IPartnershipContract> {
  const url = `/partnership_contracts`;
  return http.post(url, data, { cache: false, requestId: url }).then((response) => response.data);
}

export async function getContract(id: string): Promise<IPartnershipContract> {
  const url = `/partnership_contracts/${id}`;
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}

export async function updateContract(id: string, data): Promise<IPartnershipContract> {
  const url = `/partnership_contracts/${id}`;
  return http.put(url, data, { cache: false, requestId: url }).then((response) => response.data);
}

export async function createSubcontract(contractId, data): Promise<IPartnershipSubcontract> {
  const url = `/partnership_contracts/${contractId}/subcontracts`;
  return http.post(url, data, { cache: false, requestId: url }).then((response) => response.data);
}

export async function updateSubcontract(subcontractId, data): Promise<IPartnershipSubcontract> {
  const url = `/subcontracts/${subcontractId}`;
  return http.put(url, data, { cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Обновление данных по отношениям (ускоренная оплата)
 * @param companyId
 * @param data
 */
export async function updateRelations(companyId, data): Promise<any> {
  const url = `/relations/${companyId}`;
  return http.put(url, data, { cache: false, requestId: url }).then((response) => response.data);
}
/**
 * Получение флага автосогласования изменения ресурсов для компаний
 * @param companyIds
 */
export async function getAutoAcceptChangesStatuses(companyIds): Promise<any> {
  const url = `/auto_accept_changes_status`;
  return http
    .post(url, { company_ids: companyIds }, { cache: false, requestId: url })
    .then((response) => response.data);
}

/**
 * Получение данных по статусам ускоренных оплат для компаний
 * @param company_ids
 */
export async function getExpeditedPaymentStatuses(company_ids: string[]): Promise<TExpeditedPaymentStatuses[]> {
  const url = `/expedite_payment_statuses`;
  return http.post(url, { company_ids }, { cache: false, requestId: url }).then((response) => response.data);
}

/* Тарифы */
export async function getTariffList(params) {
  const url = `/partner_prices`;
  return http.get(url, { params, cache: false, requestId: url }).then((response) => response.data);
}

export async function createTariff(data) {
  const url = `/partner_prices`;
  return http.post(url, data, { cache: false, requestId: url }).then((response) => response.data);
}

export async function updateTariff(id: string, data) {
  const url = `/partner_prices/${id}`;
  return http.put(url, data, { cache: false, requestId: url }).then((response) => response.data);
}

export async function deleteTariff(id: string): Promise<IPartnershipContract> {
  const url = `/partner_prices/${id}`;
  return http.delete(url, { cache: false, requestId: url }).then((response) => response.data);
}

export async function getMatchingTariff(ids: string[]): Promise<any> {
  const params = { shipping_request_ids: ids };
  const url = '/partner_prices/matching';
  return http.get(url, { params, cache: false, requestId: url }).then((response) => response.data);
}

/**
 * Получение списка перевозчиков
 * @return {Promise<*>}
 */
export async function getPartnersCarriersList(params: any = {}, cache = false): Promise<any> {
  const url: string = '/transport_companies';
  return http.get(url, { params, cache, requestId: url }).then(pageListMapper);
}

/**
 * Известить перевозчиков о компании
 * @return {Promise<*>}
 */
export async function notifyCarriers(data: any = {}, cache = false): Promise<any> {
  const url: string = '/transport_companies/notify';
  return http.post(url, data, { cache, requestId: url }).then((response) => response.data);
}

/**
 * Получить информацию об оповещении перевозчиков о компании
 * @return {Promise<*>}
 */
export async function getCarriersNotificationInfo(cache = false): Promise<any> {
  const url: string = '/transport_companies/notify';
  return http.get(url, { cache, requestId: url }).then((response) => response.data);
}

/**
 * Получить необходимые для партнерства типы документов
 */
export function getRequiredDocsForPartnership(params = {}) {
  return http
    .get(`/document_types`, {
      params: {
        entity: 'partnership',
        ...params,
      },
    })
    .then((response) => response.data?.items);
}

/**
 * Получить прикрепленные к партнерству документы и файлы
 * @param partnershipId
 */
export function getPartnershipDocuments(partnershipId) {
  return http
    .get(`/partnerships/request_cargo_company/${partnershipId}/documents`)
    .then((response) => response.data?.items);
}

/**
 * Отправить документ
 * @param partnershipId
 * @param document_type_id
 * @param file
 */
export function sendRequiredForPartnershipDoc(partnershipId, document_type_id, file) {
  const formData = new FormData();
  // formData.append('document_type_id', document_type_id);
  formData.append('file', file);
  return http.post(`/partnerships/${partnershipId}/documents/${document_type_id}/file`, formData, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });
}

export function loadPartnershipFile(partnershipId, documentTypeId, fileId) {
  return http.get(`/partnerships/${partnershipId}/documents/${documentTypeId}/file/${fileId}/download`);
}

export function deletePartnershipFile(partnershipId, documentTypeId, fileId) {
  return http.delete(`/partnerships/${partnershipId}/documents/${documentTypeId}/file/${fileId}`);
}

/**
 * Получить список оферт для партнерств
 */
export async function getOfferList(): Promise<any> {
  const url = `/partnerships/offers`;
  return http.get(url, { cache: false, requestId: url }).then((response) => response.data);
}
