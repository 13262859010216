import React from 'react';
import SVGIcon from './index';

function IconLifebouy(props: React.SVGAttributes<HTMLOrSVGElement>) {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM6.53513 12C6.88641 12.6073 7.39274 13.1136 8 13.4649L8 16.1865C6.0188 15.5465 4.45352 13.9812 3.8135 12H6.53513ZM8 6.53513C7.39274 6.88641 6.88641 7.39274 6.53513 8H3.8135C4.45352 6.0188 6.0188 4.45352 8 3.8135L8 6.53513ZM13.4649 8C13.1136 7.39274 12.6073 6.88641 12 6.53513V3.8135C13.9812 4.45352 15.5465 6.0188 16.1865 8H13.4649ZM12 13.4649C12.6073 13.1136 13.1136 12.6073 13.4649 12H16.1865C15.5465 13.9812 13.9812 15.5465 12 16.1865V13.4649ZM12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10Z"
      />
    </SVGIcon>
  );
}

IconLifebouy.defaultProps = {
  color: 'base',
};

export default React.memo(IconLifebouy);
