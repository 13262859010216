import React from 'react';
import styles from './styles.module.scss';
import moment from 'moment';

import { DATE_FORMAT } from '../../../constants';

export default function Driver(suggestion) {
  return (
    <div className={styles.suggestion}>
      <div className={styles.title}>{suggestion.value.fullName}</div>
      <div className={styles.subtitle}>{moment(suggestion.value.birthDate).format(DATE_FORMAT)}</div>
    </div>
  );
}
