import { TGeoLocationLevel } from '../../counterAgents/models';

/* Значение нас.пункта для routes */
export function getCityValue(route) {
  const location = route?.location ?? {};

  const label = [
    ...new Set([
      location?.locality,
      location?.locality_with_type,
      location?.settlement_with_type,
      location?.city_with_type,
      location?.area_with_type,
      location?.region_with_type,
      location?.country,
    ]),
  ]
    .filter(Boolean)
    .join(', ');

  return {
    label,
    value: {
      ...location,
      level: TGeoLocationLevel.City,
      fias_id: location?.settlement_fias_id || location?.city_fias_id || location?.fias_id,
      value: label,
    },
  };
}
