export const FORM_ONE_NAME = 'registrationStepOne';
export const FORM_TWO_NAME = 'registrationStepTwo';

export const COUNTRY = 'country';
export const ORGANIZATION_TYPE = 'organizationType';
export const INN = 'inn';
export const COMPANY = 'company';
export const LOGIN = 'login';
export const PASSWORD = 'password';
export const EMAIL = 'email';
export const FIO = 'fio';
export const NAME = 'name';
export const PHONE = 'phone';
export const CODE = 'code';
export const CONFIRM = 'confirm';
export const PROMOCODE = 'promocode';
export const CONFIRM_PERSONAL_DATA = 'confirm_personal_data';
export const CONFIRM_PRIVACY_POLICY = 'confirm_privacy_policy';

/* Виды деятельности компании: */
export const KINDS = 'kinds';
/* Транспортная компания */
export const TRANSPORT_COMPANY = 'TransportCompany';
/* Грузовладелец */
export const CARGO_OWNING_COMPANY = 'CargoOwningCompany';
/*  Экспедитор (в будущих выпусках) */
export const FORWARDING_COMPANY = 'ForwardingCompany';
