import React from 'react';

import Tooltip from '../../Tooltip';

import { getDateString } from '../../../helpers/getDateString';
import { DF_SHORT_DATE_FORMAT } from '../../../constants';

export function getPaymentStatus(item) {
  const hasPayment = !!item?.actualPaymentDate || !!item?.plannedPaymentDate;
  if (hasPayment) {
    return (
      <Tooltip content={!!item.actualPaymentDate ? 'Фактическая дата оплаты' : 'Плановая дата оплаты'}>
        {getDateString(item.actualPaymentDate || item.plannedPaymentDate, false, DF_SHORT_DATE_FORMAT)}
      </Tooltip>
    );
  }
  return <>&mdash;</>;
}
